import React, { useEffect, useMemo, useState } from "react";
import {
    makeStyles,
    Grid,
    Card,
    CardContent,
    Typography,
    Button,
    Box,
} from "@material-ui/core";
import useAdviceTypes from "../hooks/queries/useAdviceTypes";
import useAccountProducts from "../hooks/queries/useAccountProducts";
import { shortTableDateTime } from "../utils/dateTimeFormatters";


const useStyles = makeStyles((theme) => ({
    contract: {
        padding: theme.spacing(4),
        border: '1px solid #e4e3eb',
        marginTop: '5px',
        borderRadius: '3px',
        fontSize:'0.8em',
    },
    subscription: {
        padding: theme.spacing(2),
        backgroundColor: '#f2edff',
        marginTop: '5px'
    }
}));

const PurchasedProducts = ({ accountId }) => {

    const classes = useStyles();
    const { data: adviceTypes } = useAdviceTypes();
    const { data: contracts } = useAccountProducts(accountId);
    const [selectedAdviceType, setSelectedAdviceType] = useState();

    useEffect(() => {
        console.log({ adviceTypes });
    }, [adviceTypes]);

    const adviceTypeName = (id => {
        if (!adviceTypes)
            return "";

        console.log({id});

        if (!id)
            return "Other";

        return adviceTypes.find(x => x.id === id).name;
    });

    const availableAdviceTypeIds = useMemo(() => {
        if (!contracts)
            return [];

        let ids = [
            ...new Set(contracts.map((c) => c.adviceTypeId || null)),
        ];

        console.log({ids});

        return ids.sort();

    }, [contracts]);    

    const setAdviceType = id => {
        if (id === selectedAdviceType)
            setSelectedAdviceType();
        else
            setSelectedAdviceType(id);
    }

    const filteredContracts = useMemo(() => {
        if (!contracts)
            return [];

        return selectedAdviceType === undefined
            ? contracts
            // eslint-disable-next-line eqeqeq
            : contracts.filter(c => c.adviceTypeId == selectedAdviceType);
            
    }, [contracts, selectedAdviceType]);

    return (
        <Grid container spacing={6}>
            <Grid item xs={12} md={3}>
                <Card>
                    <CardContent>
                        <Typography variant="h6">Advice Type</Typography>
                        <Box display="flex" flexDirection="column">
                            {availableAdviceTypeIds.map(id =>
                                <Button
                                    key={id}
                                    color="primary"
                                    variant={id === selectedAdviceType ? "contained" : "outlined"}
                                    onClick={() => setAdviceType(id)}
                                    style={{ marginTop: '10px' }}
                                >
                                    {adviceTypeName(id)}
                                </Button>
                            )}
                        </Box>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} md={9}>
                <Card>
                    <CardContent>
                        <Typography variant="h6">Contracts</Typography>
                        <Box display="flex" flexDirection="column">
                            {filteredContracts.map(c =>
                                <Grid key={c.contractId} container display="flex" className={classes.contract}>
                                    <Grid xs={7} item>
                                        <Box display="flex" flexDirection="column" gridGap="5px">
                                            <strong>{c.contractNumber}</strong>
                                            <strong>Details</strong>
                                            <Grid container spacing={1}>
                                                <Grid item xs={3}><strong>Core Service:</strong></Grid>
                                                <Grid item xs={3}>{c.coreService}</Grid>
                                                <Grid item xs={3}><strong>Start Date</strong></Grid>
                                                <Grid item xs={3}>{shortTableDateTime(c.startDate)}</Grid>
                                                <Grid item xs={3}><strong>Service Type</strong></Grid>
                                                <Grid item xs={3}>{c.serviceType}</Grid>
                                                <Grid item xs={3}><strong>End Date:</strong></Grid>
                                                <Grid item xs={3}>{c.endDate && shortTableDateTime(c.endDate)}</Grid>
                                                <Grid item xs={3}><strong>ACV:</strong></Grid>
                                                <Grid item xs={3}>{c.annualContractValue && `£${c.annualContractValue.toFixed(2)}`}</Grid>
                                                <Grid item xs={3}><strong>Advice Type:</strong></Grid>
                                                <Grid item xs={3}>{adviceTypeName(c.adviceTypeId)}</Grid>
                                                {   c.summary &&
                                                    <>
                                                        <Grid item xs={3}><strong>Summary:</strong></Grid>
                                                        <Grid item xs={9}>{c.summary}</Grid>
                                                    </>
                                                }
                                                
                                            </Grid>
                                        </Box>
                                       
                                    </Grid>
                                    <Grid xs={5} item>
                                        <Box>
                                            <strong>Active Subscriptions</strong>
                                        </Box>
                                        {c.subscriptions.map(s =>
                                            <Grid container className={classes.subscription} key={s.id}>
                                                <Grid item xs={3}><strong>Product:</strong></Grid>
                                                <Grid item xs={9}>{s.productName}</Grid>
                                                <Grid item xs={3}><strong>QL ACV:</strong></Grid>
                                                <Grid item xs={3}>{s.qlacv != null && `£${s.qlacv.toFixed(2)}`}</Grid>
                                                {   s.hourlyRate &&
                                                    <>
                                                        <Grid item xs={3}><strong>Hourly Rate</strong></Grid>
                                                        <Grid item xs={3}>£{s.hourlyRate.toFixed(2)}</Grid>    
                                                    </>
                                                }                                                                                           
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                            )}
                        </Box>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
}

export default PurchasedProducts;
