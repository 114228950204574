import React, { useEffect, useRef } from 'react';
import Timer from 'react-compound-timer';
import { useSelector, useDispatch } from 'react-redux';
import timerStatus from '../constants/timerStatus';
import { formatTimeDigital } from '../helpers/formatTime';
import { setCaseMinutes, setAdjustCaseMinutes } from '../redux/actions/caseActions';

const withTimer = timerProps => WrappedComponent => wrappedComponentProps => (
    <Timer {...timerProps}>
        {timerRenderProps => (
            <WrappedComponent
                {...wrappedComponentProps}
                timer={timerRenderProps}
            />
        )}
    </Timer>
);

const ActivityTimer = ({ timer }) => {
    const { status: caseTimerStatus } = useSelector(
        state => state.caseTimerReducer
    );
    const { currentCaseId, cases } = useSelector(state => state.caseReducer);
    const adjustMinutes = cases[currentCaseId] ? cases[currentCaseId].adjustMinutes : 0;
    const dispatch = useDispatch();
    const prevCaseIdRef = useRef();

    useEffect(() => {
        prevCaseIdRef.current = currentCaseId;
    }, [currentCaseId]);

    const prevCaseId = prevCaseIdRef.current;

    useEffect(() => {
        if (caseTimerStatus === timerStatus.RUNNING) {
            timer.start();
        } else timer.pause();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [caseTimerStatus, currentCaseId]);

    useEffect(() => {
        if (prevCaseId && currentCaseId && currentCaseId !== prevCaseId) {
            const updatedMinutes = timer.getTime() / 60000;
            dispatch(setCaseMinutes(prevCaseId, updatedMinutes));
        }

        if (currentCaseId) {
            timer.setTime(
                cases[currentCaseId].minutes * 60000
            )
        }

        else timer.setTime(0);

        return () => {
            const updatedMinutes = timer.getTime() / 60000;
            if (currentCaseId) dispatch(setCaseMinutes(currentCaseId, updatedMinutes));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentCaseId]);

    useEffect(() => {
        if (adjustMinutes && adjustMinutes !== 0) {
            const currentMinutes = timer.getTime() / 60000;
            timer.setTime((currentMinutes + adjustMinutes) * 60000);
            dispatch(setAdjustCaseMinutes(currentCaseId, 0));
        }
    }, [adjustMinutes]);

    return <Timer.Seconds />;
};

const CaseActivityTimer = withTimer({
    formatValue: value => formatTimeDigital(value),
    lastUnit: 's',
})(ActivityTimer);

export default CaseActivityTimer;
