const formatTimeDigital = s => {
    let hours = Math.floor(s / 3600);
    let minutes = Math.floor((s - (hours * 3600)) / 60);
    let seconds = Math.floor(s - (hours * 3600) - (minutes * 60));

    if (hours < 10) { hours = "0" + hours; }
    if (minutes < 10) { minutes = "0" + minutes; }
    if (seconds < 10) { seconds = "0" + seconds; }
    return hours + ':' + minutes + ":" + seconds;
};

const formatTimeHms = s => {
    let timeString = "";
    let hours = Math.floor(s / 3600);
    let minutes = Math.floor((s - (hours * 3600)) / 60);
    let seconds = Math.floor(s - (hours * 3600) - (minutes * 60));

    timeString = hours ? timeString + hours + "h " : timeString;
    timeString = minutes ? timeString + minutes + "m " : timeString;
    timeString = seconds ? timeString + seconds + "s" : timeString;

    return timeString;
};

export { formatTimeDigital, formatTimeHms };