import React, { useMemo, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Card,
    CardContent,
    Typography,
    Button,
    Box,
    CircularProgress,
    makeStyles,
    Menu,
    MenuItem,
    IconButton,
    Grid,
    TextField
} from "@material-ui/core";
import {
    Replay as UndoIcon,
    Videocam as VideoIcon,
    SpeakerNotes as TranscriptIcon
} from "@material-ui/icons";
import { resetMeeting, setMeetingCardMeeting, setMeetingField } from "../redux/actions/caseActions";
import caseActivityService from "../services/caseActivityService";
import { isValid, format, isSameDay } from "date-fns";
import { isValidHoursMinutes } from "../helpers/dateHelpers";
import { setSnackAction } from "../redux/actions/snackActions";
import { grey } from "@material-ui/core/colors";
import reactQueryClient from "../reactQueryClient";
import queryKeys from "../constants/queryKeys";
import axios from "../plugins/axios";
import { MAX_ACTIVITY_DESCRIPTION } from "../constants/lengthConstants";
import { KeyboardTimePicker, KeyboardDatePicker } from "@material-ui/pickers";

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

const useStyles = makeStyles((theme) => ({
    iconButton: {
        padding: "2px",
        marginTop: "-2px",
        marginLeft: "2px",
        minWidth: 0,
        color: grey[700],
    },
    card: {
        marginBottom: theme.spacing(6),
    },
    saveButton: {
        marginLeft: theme.spacing(2),
    },
    badge: {
        right: -3,
        top: 13,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
    },
    formItem: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    datePicker: {
        width: "100%",
    }
}));

const MeetingCard = () => {
    const dispatch = useDispatch();
    const classes = useStyles();

    const { currentCaseId: caseId, cases } = useSelector(state => state.caseReducer);
    
    const c = cases[caseId];   

    const [meetings, setMeetings] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);    
    
    const contractId = c?.caseSummary?.contractId;

    const [saving, setSaving] = useState(false);

    const [meetingNotes, setMeetingNotes] = useState(c.meeting.notes);    
    
    useEffect(() => {

        const controller = new AbortController();

        const getMeetings = async () => {
            try {
                const response = await axios.get(`${apiUrl}/meeting/getrecent`, { signal: controller.signal });
                setMeetings(response.data);
            } catch (e) {
                console.error(e);
            }
        }

        if (!c.meeting.meeting)
            getMeetings();        

        return () => {
            controller.abort();
        }
    }, [c.meeting.meeting]);

    const save = async () => {
        if (
            !isValidHoursMinutes(c.meeting.hours, c.meeting.minutes) ||
            !isValid(new Date(c.meeting.date)) ||
            !isValid(new Date(c.meeting.startTime))
        ) {
            dispatch(setSnackAction("Invalid date or time entered", "error"));
            return;
        }

        setSaving(true);

        try {
            await caseActivityService.saveMeeting(caseId);            
            handleReset();
            reactQueryClient.invalidateQueries([queryKeys.caseTime, caseId]);
            reactQueryClient.invalidateQueries([queryKeys.contractSummary, contractId]);
        } catch (error) {
            console.log(error);
        }

        setSaving(false);
    };

    const handleReset = () => {       
        dispatch(resetMeeting(caseId));
    };

    const setMeeting = meeting => {
        setAnchorEl(null);
        dispatch(setMeetingCardMeeting(caseId, meeting));
    }

    const isSaveDisabled = useMemo(() => {

        return (
            !c.meeting.description ||
            !c.meeting.date ||
            !c.meeting.notes ||
            !c.meeting.startTime ||
            !(parseInt(c.meeting.hours) || parseInt(c.meeting.minutes))
        );
    }, [c.meeting.date, c.meeting.description, c.meeting.hours, c.meeting.minutes, c.meeting.notes, c.meeting.startTime]);

    const setField = (name, value) => {
        dispatch(setMeetingField(caseId, name, value));
    };

    // fix cursor jumping issue
    const updateMeetingNotes = (val) => {
        setMeetingNotes(val);
        setField('notes', val);
    }

    const MeetingInfo = ({ meeting }) => {

        let startDate = new Date(meeting.startTime);

        return (
            <Box display="flex" flexDirection="column" style={{ maxWidth: '400px' }}>
                <Box display="flex">
                    {
                        meeting.recordingCount > 0 &&
                        <VideoIcon />
                    }
                    {
                        meeting.transcriptCount > 0 &&
                        <TranscriptIcon />
                    }
                    <Typography variant="h4">
                        {isSameDay(startDate, new Date()) ? "Today" : format(startDate, "dd/MM")} {format(startDate, "HH:mm")}
                    </Typography>
                </Box>
                <Typography style={{ width: '100%', whiteSpace: 'pre-line' }}>{meeting.subject}</Typography>
            </Box>      
        );
    }

    return (
        <Card className={classes.card}>
            <CardContent>
                <Box display="flex">
                    <Box flexGrow={1}>
                        <Typography variant="h6" gutterBottom>
                            Meeting
                        </Typography>
                    </Box>
                    <Box>
                        <IconButton
                            className={classes.iconButton}
                            onClick={handleReset}
                        >
                            <UndoIcon />
                        </IconButton>
                    </Box>
                </Box>
                {
                    !c.meeting.meeting &&
                    <Button
                        variant="contained"
                        disabled={meetings == null}
                        color="primary"
                        onClick={e => setAnchorEl(e.currentTarget.parentNode)}
                    >
                        {meetings == null ? "searching ..." : "Choose Meeting"}
                    </Button>
                }
                <Menu
                    className={classes.callMenu}
                    anchorEl={anchorEl}
                    keepMounted
                    open={!!anchorEl}
                    onClose={() => setAnchorEl(null)}
                >
                    {meetings?.length === 0 && <MenuItem>No meetings found</MenuItem>}
                    {
                        meetings &&
                        meetings.map(m => (
                            <MenuItem
                                key={m.meetingId}
                                onClick={() => setMeeting(m)}
                            >
                                <MeetingInfo meeting={m} />
                            </MenuItem>
                        ))
                    }
                </Menu>                 
                {
                    c.meeting.meeting &&
                    <>
                        <MeetingInfo meeting={c.meeting.meeting} />
                        <KeyboardDatePicker
                            className={`${classes.datePicker} ${classes.formItem}`}
                            name="date"
                            format="dd/MM/yyyy"
                            label="Date"
                            value={c.meeting.date}
                            onChange={date => setField('date', date)}
                            inputVariant="outlined"
                        />
                        <KeyboardTimePicker
                            className={`${classes.datePicker} ${classes.formItem}`}
                            id="time-picker"
                            label="Start Time"
                            value={c.meeting.startTime}
                            onChange={date => setField('startTime', date)}
                            KeyboardButtonProps={{ "aria-label": "change time" }}
                            ampm={false}
                            inputVariant="outlined"
                        />
                        <Grid container spacing={4}>
                            <Grid item xs={6}>
                                <TextField
                                    className={classes.formItem}
                                    fullWidth
                                    type="number"
                                    variant="outlined"
                                    value={c.meeting.hours || ""}
                                    onChange={e => setField('hours', e.target.value)}
                                    inputProps={{ min: 0 }}
                                    label="Hours"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    className={classes.formItem}
                                    fullWidth
                                    type="number"
                                    variant="outlined"
                                    value={c.meeting.minutes || ""}
                                    onChange={e => setField('minutes', e.target.value)}
                                    inputProps={{ min: 0 }}
                                    label="Minutes"
                                />
                            </Grid>
                        </Grid>
                        <TextField
                            className={classes.formItem}
                            fullWidth
                            value={c.meeting.description}
                            onChange={e => setField('description', e.target.value)}
                            label="Description"
                            multiline
                            variant="outlined"
                            inputProps={{ maxLength: MAX_ACTIVITY_DESCRIPTION }}
                        />
                        <TextField
                            className={classes.formItem}
                            fullWidth
                            value={meetingNotes}
                            onChange={e => updateMeetingNotes(e.target.value)}
                            label="Notes"
                            multiline
                            minRows={4}
                            variant="outlined"
                        />
                    </>
                }                               
                <Box display="flex" width="100%" justifyContent="flex-end">
                    {
                        saving
                            ? <CircularProgress />
                            : <>
                                <Button onClick={handleReset}>Cancel</Button>
                                <Button
                                    className={classes.saveButton}
                                    color="primary"
                                    variant="contained"
                                    disabled={isSaveDisabled}
                                    onClick={save}
                                >
                                    Save
                                </Button>
                            </>
                    }
                </Box>
            </CardContent>            
        </Card>
    );
};

export default MeetingCard;
