import caseTab from "./caseTab";

const activityTypes = {
    DETAILS: "Details",
    REPLY: "Reply",
    PRECEDENT: "Precedent",
    FILE: "File",
    FLEX: "Flex",
    BLUR_CALL: "BlurCall",
    CALL_NOTE: "CallNote",
    INTERNAL_NOTE: "InternalNote",
    RESUME_CASE_ACTIVITY: "ResumeCaseActivity",
    DOCUMENT_LIBRARY: "DocumentLibrary",
};

const tabActivityTypesMap = {
    [caseTab.DETAILS]: activityTypes.DETAILS,
    [caseTab.FILES]: activityTypes.DOCUMENT_LIBRARY,
    [caseTab.DRAFT]: activityTypes.DETAILS,
    [caseTab.HISTORY]: activityTypes.DETAILS,
    [caseTab.ET]: activityTypes.DETAILS,
    [caseTab.REPLY]: activityTypes.REPLY,
    [caseTab.AI]: activityTypes.REPLY,
};

export function getTabActivityType(tab) {
    if (tab.startsWith('P-'))
        return activityTypes.PRECEDENT;

    return tabActivityTypesMap[tab];
}

export default activityTypes;
