import axios from "../plugins/axios";

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

const add = ({ userId }) => axios.post(`${apiUrl}/usertimeevent/${userId}`);

const complete = ({ userId }) =>
    axios.patch(`${apiUrl}/usertimeevent/complete/${userId}`);

const remove = ({ userId }) =>
    axios.delete(`${apiUrl}/usertimeevent/${userId}`);

const getUnassignedStats = ({ userId }) =>
    axios.get(`${apiUrl}/usertimeevent/unassignedstats/${userId}`);

const userTimeEventService = {
    add,
    complete,
    remove,
    getUnassignedStats,
};

export default userTimeEventService;
