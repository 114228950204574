import axios from "../plugins/axios";

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

const getUserRoles = (userId) =>
    axios.get(`${apiUrl}/getuserroles/${userId}`);

const updateUserRoles = (dto) => axios.post(`${apiUrl}/updateuserroles`, dto);

const userRoleService = {
    getUserRoles,
    updateUserRoles,
};

export default userRoleService;
