import React from "react";
import { grey } from "@material-ui/core/colors";
import { caseFileTypes } from "../../constants/caseFiles";
import useIsMobile from "../../hooks/useIsMobile";
import {
    IconButton,
    Typography,
    Grid,
    makeStyles,
    Checkbox,
} from "@material-ui/core";
import {
    Folder as FolderIcon,
    MoreVert as MoreVertIcon,
} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
    tableRow: {
        padding: "8px 0",
        borderBottom: "1px solid rgba(224, 224, 224, 1)",
    },
    highlightRow: {
        backgroundColor: "#f2edff",
    },
    disabled: {
        "& div": {
            opacity: "0.3"
        }
    },
    fileFolder: {
        "& svg": {
            color: grey[700],
        }
    },
    maxGutter: {
        maxWidth: "45px",
    },
    moreMenu: {
        textAlign: "right",
        [theme.breakpoints.up("sm")]: {
            flexGrow: 1,
            maxWidth: "none",
        },
    },
    link: {
        cursor: "pointer"
    },
}));

const Folder = ({
    folder,
    handleOpenContextMenu,
    isMove,
    handleAttachmentChecked,
    caseFilesForDownload,
    displayDownloadButton,
    navigateToFolder,
    selectedItem,
}) => {

    const classes = useStyles();
    const isMobile = useIsMobile();
    const folderItems = folder.children?.length;
    const disabled = isMove && folder.folderId === selectedItem?.folderId;
    const isChecked = caseFilesForDownload.find(f => f.folderId == folder.folderId) ? true : false;

    return (
        <Grid key={folder.folderId} container spacing={0} className={`${classes.tableRow} ${disabled ? classes.disabled : ''} ${isChecked && classes.highlightRow}`}>
            {
                !isMove && ((isMobile && displayDownloadButton) || !isMobile) &&
                <Grid item xs={1} className={classes.maxGutter}>
                    <Checkbox
                        checked={isChecked}
                        onChange={e => handleAttachmentChecked(folder, e.target.checked)}
                    />
                </Grid>
            }
            <Grid item xs={1} className={`${classes.fileFolder} ${classes.maxGutter}`}>
                {
                    disabled
                        ? <FolderIcon />
                        : <FolderIcon className={classes.link} onClick={() => navigateToFolder(folder)} />
                }
            </Grid>
            <Grid item xs={isMove ? 10 : (isMobile && displayDownloadButton ? 8 : 9)}>
                <Typography variant="subtitle2">{folder.title}</Typography>
                <Typography variant="caption"> {folderItems} items</Typography>
            </Grid>
            <Grid item xs={1} className={classes.moreMenu}>
                {
                    !isMove &&
                    <IconButton onClick={ev => handleOpenContextMenu(folder, ev, caseFileTypes.FOLDER)}>
                        <MoreVertIcon />
                    </IconButton>
                }
            </Grid>
        </Grid>
    );
};

export default Folder;