import { useMutation } from "react-query";
import queryKeys from "../../constants/queryKeys";
import caseService from "../../services/caseService";
import reactQueryClient from "../../reactQueryClient";

const useDeleteETClaim = () =>
    useMutation(({ etClaim }) => caseService.deleteETClaim(etClaim.caseId, etClaim.claimId), {
        onMutate: async ({ etClaim }) => {
            const queryKey = [queryKeys.eTClaims, etClaim.etCaseId];
            reactQueryClient.cancelQueries(queryKey);
            const previousDetails = reactQueryClient.getQueryData(queryKey);
            reactQueryClient.setQueryData(queryKey, (prev) => ([
                ...(prev.filter(x => x.claimId !== etClaim.claimId) || [])
            ]));
            return { previousDetails, queryKey };
        },
        onError: (err, newDetails, context) => {
            reactQueryClient.setQueryData(
                context.queryKey,
                context.previousDetails
            );
        },
        onSettled: (data, err, newDetails, context) => {
            reactQueryClient.invalidateQueries(context.queryKey);
        },
    });

export default useDeleteETClaim;
