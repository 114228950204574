import { useQuery } from 'react-query'
import queryKeys from '../../constants/queryKeys'
import caseService from '../../services/caseService'

const useETCaseDetails = (caseId) => useQuery(
    [queryKeys.eTCaseDetails, caseId],
    () => caseService.getETCase(caseId), 
    {
        enabled: !!caseId
    })

export default useETCaseDetails;