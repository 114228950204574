import React, {useCallback, useState} from "react";
import {useSelector} from "react-redux";
import {
  Box, Button,
  CircularProgress,
  Dialog, DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment, Table, TableBody, TableCell, TableHead, TableRow,
  TextField,
  makeStyles,
} from "@material-ui/core";
import useYouManageEmployees from "../../hooks/queries/useYouManageEmployees";
import {Search} from "@material-ui/icons";
import {Alert} from "@material-ui/lab";
import {format} from "date-fns";

const useStyles = makeStyles(() => ({
  clickableRow: {
    cursor: "pointer",
  },
}));

const YouManageEmployeeSearchDialog = ({ open, onClose, handleAddExistingEmployee, handleAddNewEmployee }) => {
  const classes = useStyles();
  const [text, setText] = useState("");
  const [query, setQuery] = useState("");
  const [employeeId, setEmployeeId] = useState(null);
  const { currentCaseId: caseId, cases } = useSelector(state => state.caseReducer);
  const employees = cases[caseId].employees;
  const { accountId } = cases[caseId].account;
  const { data, isLoading, isError, error } = useYouManageEmployees({ accountId, query });
  
  const handleCancel = () => {
    setText("");
    setQuery("");
    setEmployeeId(null);
    onClose();
  }
  
  const handleClickAdd = () => {
    handleCancel();
    handleAddExistingEmployee(employeeId);
  }

  const handleClickAddNew = () => {
    handleCancel();
    handleAddNewEmployee();
  }
  
  const filteredResults = useCallback(() => {
    if (!data)
      return null;
    return data.filter(x => !employees.map(e => e.youManageEmployee?.id).includes(x.id));
  }, [data, employees]);
   
  return (
    <Dialog open={open} maxWidth="sm" fullWidth onClose={handleCancel}>
      <DialogTitle>Search for a YouManage Employee</DialogTitle>
      <DialogContent>
        <TextField
          label="Search"
          value={text}
          onChange={e => setText(e.target.value)}
          fullWidth
          variant="outlined"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setQuery(text)}
                >
                  <Search />
                </IconButton>
              </InputAdornment>
            )
          }}
        />
        {isLoading && <Box display="flex" justifyContent="center" width="100%"><CircularProgress /></Box>}
        {isError && <Box display="flex" justifyContent="center" width="100%">
          <Alert variant="outlined" severity="error">
            {error?.message || "Unable to load employees"}
          </Alert>
        </Box>}
        {data && <Table>
          <TableHead>
            <TableRow>
              <TableCell>First Name</TableCell>
              <TableCell>Last Name</TableCell>
              <TableCell>Start Date</TableCell>
              <TableCell>Position</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!filteredResults().length && <TableRow><TableCell align="center" colSpan={4}>No results found</TableCell></TableRow>}
            {filteredResults().map(x => <TableRow 
              key={x.id}
              hover
              selected={employeeId == x.id}
              onClick={() => setEmployeeId(x.id)}
              className={classes.clickableRow}
            >
              <TableCell>{x.firstName}</TableCell>
              <TableCell>{x.lastName}</TableCell>
              <TableCell>{x.startDate ? format(new Date(x.startDate), "dd/MM/yyyy") : ""}</TableCell>
              <TableCell>{x.position}</TableCell>
            </TableRow>)}
          </TableBody>
        </Table>}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleClickAddNew}
        >
          Add New Employee
        </Button>
        <Button 
          variant="contained" 
          color="primary" 
          disabled={!employeeId} 
          onClick={() => handleClickAdd(employeeId)}
        >
          Add Employee to Case
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default YouManageEmployeeSearchDialog;