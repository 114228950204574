import React from "react";
import styled from "styled-components";

import {
    List,
    ListItem,
    Input,
    InputAdornment,
    CircularProgress,
    IconButton as MuiIconButton,
    makeStyles,
} from "@material-ui/core";
import {
    Add as AddIcon,
    Clear
} from "@material-ui/icons";
import { blue } from "@material-ui/core/colors";

const useStyles = makeStyles(theme => ({
  listItem: {
    display: "flex",
    justifyContent: "center",
    borderRadius: "5px",
    border: "1px solid black",
    margin: "8px 0px",
    "&.Mui-selected": {
      backgroundColor: theme.palette.secondary.main,
      color: "white",
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
      }
    }
  }
}));

const IconButton = styled(MuiIconButton)`
    padding: 2px;
    margin-top: -2px;
    margin-left: 2px;
    min-width: 0;
`;

const AdminList = ({ items, setItems, itemId, mode, saving, selectedItem, setSelectedItem, newItem, setNewItem, handleAdd, maxLength }) => {
    const classes = useStyles();

    const handleChange = value => {
        setItems((prevItems) => prevItems.map(i => i[itemId] === selectedItem ? { ...i, name: value, edited: true } : i))
    }

    return (
        <React.Fragment>
            <List>
                {items.filter(item => !item.disabled && !item.toDelete).map(item =>
                    item.toEdit ?
                        
                        <Input
                            key={item[itemId]}
                            style={{ width: "100%" }}
                            inputProps={{ maxLength:  maxLength , style: { textAlign: "center" } }}
                            type="text"
                            value={item.name}
                            onChange={e => handleChange(e.target.value)}
                        /> :
                        <ListItem
                            className={classes.listItem}
                            key={item[itemId]}
                            selected={item[itemId] === selectedItem}
                            button
                            disabled={mode === "edit" || mode === "add"}
                            onClick={() => setSelectedItem(item[itemId])}
                        >
                            {item.name}
                        </ListItem>
                )}
            </List>
            {mode === "add" &&
                <Input
                    style={{ margin: "8px" }}
                    inputProps={{ style: { textAlign: "center", maxLength: maxLength } }}
                    type="text"
                    value={newItem}
                    onChange={e => setNewItem(e.target.value)}
                    endAdornment={
                        <InputAdornment position="end">
                            {saving ? <CircularProgress size="1em" /> : <IconButton onClick={handleAdd}>{newItem ? <AddIcon /> : <Clear />}</IconButton>}
                        </InputAdornment>
                    }
                />
            }
        </React.Fragment>
    );
}

export default AdminList;