import React from "react";

import {
    Typography,
    Link,
    makeStyles,
} from "@material-ui/core";
import {
    ArrowBack as ArrowBackIcon,
} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
    breadcrumb: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    link: {
        cursor: "pointer"
    },
}));

const MoveBackNav = ({
    path,
    navigateToParentFolder,
}) => {
    const classes = useStyles();
    return (
        <div
            className={classes.breadcrumb}
            aria-label="Breadcrumb"
        >
            {path.length === 0 && (
                <Typography
                    color="textPrimary"
                    variant="h3"
                >
                    Case Files
                </Typography>
            )}
            {path.map((folder, index) => (
                <span key={index}>
                    {index === path.length - 1 && (
                        <Link
                            color="textPrimary"
                            variant="h3"
                            underline="hover"
                            className={classes.link}
                            onClick={() => navigateToParentFolder(index - 1)}
                        >
                            <ArrowBackIcon /> {folder.title}
                        </Link>
                    )}
                </span>
            ))}
        </div>
    );
};

export default MoveBackNav;