import React, { useState, useEffect } from "react";
import axios from "../plugins/axios";
import StatsCard from "../cards/StatsCard";
import { green } from "@material-ui/core/colors";

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

const UserOpenCasesCard = ({ userProfile, isMyData }) => {
    const [openCaseCount, setOpenCaseCount] = useState("-");
    const [yesterdaysOpenCaseCount, setYesterdaysOpenCaseCount] = useState("-");

    const [userFirstNameText, setUserFirstNameText] = useState("");

    const updateCaseCount = async () => {
        if (userProfile.userId) {
            var response = await axios.get(
                `${apiUrl}/getusercasecount/${userProfile.userId}`
            );
            setOpenCaseCount(response.data.openCaseCount);
            setYesterdaysOpenCaseCount(response.data.yesterdaysOpenCaseCount);

            if (isMyData == "true") {
                setUserFirstNameText("My Open Cases");
            } else {
                setUserFirstNameText(
                    userProfile.name.split(" ").slice(0, -1).join(" ") +
                        "'s Open Cases"
                );
            }
        }
    };

    useEffect(() => {
        //update case count when page is hit
        updateCaseCount();
    }, [userProfile.userId]);

    function relDiff(a, b) {
        var resp = 100 * Math.abs((a - b) / ((a + b) / 2));
        if (isNaN(parseFloat(resp))) return "";
        resp = (Math.round(resp) * 100 + Number.EPSILON) / 100;
        return resp;
    }

    return (
        <StatsCard
            title={userFirstNameText}
            amount={openCaseCount}
            chip="Today"
            percentageText={
                openCaseCount === yesterdaysOpenCaseCount
                    ? "No Change"
                    : openCaseCount > yesterdaysOpenCaseCount
                    ? "+"
                    : "-" +
                      relDiff(openCaseCount, yesterdaysOpenCaseCount) +
                      "%"
            }
            percentagecolor={green[500]}
            comparison="Since yesterday"
        />
    );
};

export default UserOpenCasesCard;
