import { useQuery } from "react-query";
import queryKeys from "../../constants/queryKeys";
import caseActionService from "../../services/caseActionService";

const useCaseActionCategories = ({ dialogOpen }) =>
    useQuery(
        queryKeys.caseActionCategories,
        () => caseActionService.getCaseActionCategories(),
        {
            enabled: !!dialogOpen,
            staleTime: Infinity
        }
    )

export default useCaseActionCategories;