import React, { useState, useEffect, useRef } from 'react';
import {
    makeStyles,
    Button,
    Box,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { setSnackAction } from "../redux/actions/snackActions";
import { red, grey } from "@material-ui/core/colors";
import { formatTimeDigital } from "../helpers/formatTime";
import useDiscardCaseTimeEventGroups from '../hooks/mutations/useDiscardCaseTimeEventGroups';

const modes = {
    SAVING: "saving",
    VIEW: "view",
};

const useStyles = makeStyles((theme) => ({
    table: {
        background: grey[100],
    },
    tableCell: {
        border: 'none',
    },
    firstColumn: {
        width: '25%',
    },
    deleteButton: {
      color: red[700],
      borderColor: red[700],
    },
    label: {
        fontWeight: theme.typography.fontWeightBold,
    },
    progressCircle: {
        marginLeft: theme.spacing(2),
    }
}));

const HeldTimeDeleteDialog = ({ heldTime, open, onCancel, onClose, onDeleted }) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const discardCaseTimeEventGroups = useDiscardCaseTimeEventGroups();

    const {userProfile: {userId}} = useSelector(state => state.userReducer)
    const [mode, setMode] = useState(modes.VIEW);

    const onDelete = () => {
        setMode(modes.SAVING);

        discardCaseTimeEventGroups.mutate({ 
            caseTimeEventGroupIds: [heldTime.group.id],
            userId,
            accountId: heldTime.accountId,
        }, {
            onSuccess: () => {
                onDeleted();
            },
            onError: e =>
                dispatch(setSnackAction(e?.message || "There was an error discarding held time", "error")),
        });
        
        setMode(modes.VIEW);
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby='held-time-delete-dialog-title'
            aria-describedby='held-time-delete-dialog-description'>
            <DialogTitle id='held-time-delete-dialog-title'>
                Delete Held Time
            </DialogTitle>
            <DialogContent>
                <Table className={classes.table}>
                    <TableBody>
                        <TableRow className={classes.tableRow}>
                            <TableCell className={`${classes.tableCell} ${classes.firstColumn}`}>
                                <span className={classes.label}>Case:</span>
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                                {heldTime?.group.caseId}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={classes.tableCell}>
                                <Typography>
                                <span className={classes.label}>Description:</span>
                                </Typography>
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                                {heldTime?.group.description || "-"}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={classes.tableCell}>
                                <Typography>
                                <span className={classes.label}>Duration:</span>
                                </Typography>
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                                {heldTime?.group?.duration ? formatTimeDigital(heldTime.group.duration) : ''}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                <DialogContentText id='held-time-delete-dialog-description'>
                    <p>
                        Are you sure you want to delete held time? This action cannot be undone.
                    </p>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Box mr={5}>
                    {mode !== modes.SAVING && (
                        <>
                            <Button variant='text' onClick={onCancel} color='primary'>
                                Cancel
                            </Button>
                            <Button variant='text' onClick={onDelete} className={classes.deleteButton}>
                                Delete
                            </Button>
                        </>
                    )}
                    {mode === modes.SAVING && (
                        <CircularProgress
                            size={21}
                            className={classes.progressCircle}
                        />
                    )}
                </Box>
            </DialogActions>
        </Dialog>
    );
};

export default HeldTimeDeleteDialog;