import React from "react";
import styled from "styled-components";
import {
    Card as MuiCard,
    Typography,
    Accordion as MuiAccordion,
    AccordionDetails,
    AccordionSummary as MuiAccordionSummary,
    withStyles,
    Box
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import {
    ExpandMore as ExpandMoreIcon,
} from "@material-ui/icons";
import useCaseSummary from "../hooks/queries/useCaseSummary";
import { format } from "date-fns";
import PerfectScrollbar from "react-perfect-scrollbar";
import "../vendor/perfect-scrollbar.css";
import Markdown from "markdown-to-jsx"

const Accordion = withStyles({
    root: {
        padding: '16px',
        boxShadow: 'none',
        '&$expanded': {
            margin: '0 auto',
        },
        '&:before': {
            display: 'none',
        },
        width: '100%'
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        padding: 0,
        minHeight: 0,
        '&$expanded': {
            minHeight: 0,
        },
    },
    content: {
        margin: '1px 0',
        '&$expanded': {
            margin: '1px 0',
        },
    },
    expanded: {},
    expandIcon: {
        paddingTop: 0,
        paddingBottom: 0
    },
})(MuiAccordionSummary);


const Card = styled(MuiCard)(spacing);


const CaseSummaryCard = ({ caseId }) => {
    const { data: caseSummary } = useCaseSummary(caseId);    

    if (!caseSummary?.summary)
        return null;

    return (
        <Card mb={6}>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                    <Box display="flex" flexGrow={1}>
                        <Typography variant="h6" gutterBottom>Case Summary</Typography>
                    </Box>
                    {format(new Date(caseSummary.createdOn), "dd/MM/yy HH:mm")}
                </AccordionSummary>
                <AccordionDetails>
                    <PerfectScrollbar style={{maxHeight: '250px'}}>
                        <Markdown>{caseSummary.summary}</Markdown>
                    </PerfectScrollbar>                    
                </AccordionDetails>
            </Accordion>
        </Card>
    );
};

export default CaseSummaryCard;
