import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    Box,
    Button,
    FormControl,
    Input,
    InputLabel,
    CircularProgress,
    DialogContentText
} from '@material-ui/core';

import {
    setCaseResearchOpen,
    setCaseResearchSessionId,
    setExistingCaseResearchSession,
    setCaseResearchStart
} from '../redux/actions/caseActions';
import { setSnackAction } from '../redux/actions/snackActions';
import { formatTimeDigital } from '../helpers/formatTime';
import { endCaseResearch } from '../services/caseResearchService';

const CaseResearchDialog = () => {
    const { caseResearch } = useSelector(state => state.caseReducer);
    const { minimumResearchSeconds: minSeconds } = useSelector(state => state.configReducer);
    const { open: caseResearchOpen, existingSession, start } = caseResearch;

    const dispatch = useDispatch();

    const [minutes, setMinutes] = useState("");
    const [hours, setHours] = useState("");
    const [saving, setSaving] = useState(false);
    const [now, setNow] = useState(new Date());

    useEffect(() => {
        let timer;
        if (caseResearchOpen)
            setInterval(() => {
                setNow(new Date());
            }, 1000);

        return () => {
            clearInterval(timer);
        }
    }, [caseResearchOpen]);

    const handleClose = (event, reason) => {
        if (reason === 'backdropClick') {
            return;
        }      
        setHours("");
        setMinutes("");
        dispatch(setCaseResearchStart(null));
        dispatch(setCaseResearchSessionId(null));
        dispatch(setCaseResearchOpen(false));
        dispatch(setExistingCaseResearchSession(false));
    };

    const handleBackToCase = async () => {
        if (!start) {
            handleClose();
            return;
        }
        const seconds = (now.getTime() - new Date(start).getTime()) / 1000;

        if (seconds === 0) {
            handleClose();
            return;
        }
        if (seconds <= minSeconds) {
            setSaving(true);
            try {
                await endCaseResearch(null, null);
                handleClose();
                dispatch(setSnackAction(`${formatTimeDigital(seconds)} time logged whilst researching`, "warning"));
            } catch (error) {
                console.error(error);
            }
            setSaving(false);
            return;
        }

        setHours(Math.floor(seconds / (60 * 60)));
        setMinutes(Math.round((seconds / 60) % 60));
    }

    const handleConfirmTime = async () => {
        const parsedMinutes = typeof(minutes) == "number" ? minutes : parseInt(minutes);
        const parsedHours = typeof(hours) == "number" ? hours : parseInt(hours);
        if (!Number.isInteger(parsedHours) || parsedHours < 0 || !Number.isInteger(parsedMinutes) || parsedMinutes < 0 || (parsedHours == 0 && parsedMinutes == 0)) {
            dispatch(setSnackAction("Invalid time", "error"));
            return;
        }
        setSaving(true);
        try {
            await endCaseResearch(hours, minutes);
            handleClose();
            dispatch(setSnackAction(`${formatTimeDigital(((hours * 60 * 60) + (minutes * 60)))} time logged whilst researching`, "warning"));
        } catch (error) {
            console.error(error);
        }
        setSaving(false);
    }

    return (
        <Dialog
            open={caseResearchOpen}
            onClose={handleClose}            
        >
            {minutes === "" && hours === "" && <React.Fragment>
                <DialogTitle>Logging research time</DialogTitle>
                <DialogContent>
                    <Box display="flex" flexDirection="column" alignItems="center">
                        {existingSession && <div style={{ textAlign: 'center' }}>Looks like you closed this case whilst still researching. You can modify the research time by clicking 'Back to case'</div>}
                        <Box my={3}>{(start === null || new Date(start) >= now) ? "" : formatTimeDigital((now.getTime() - new Date(start).getTime()) / 1000)}</Box>
                        {saving ? <CircularProgress /> : <Button color="primary" onClick={handleBackToCase} variant="contained">Back to case</Button>}
                    </Box>
                </DialogContent>
            </React.Fragment>}
            {(minutes !== "" || hours !== "") && <React.Fragment>
                <DialogTitle>Time logged whilst researching</DialogTitle>
                <DialogContent>
                    <DialogContentText align="center">Please confirm or update time</DialogContentText>
                    <Box display="flex" flexDirection="column" alignItems="center">
                        <Box display="flex" justifyContent="space-around">
                            <FormControl style={{ width: '70px' }}>
                                <InputLabel>Hours</InputLabel>
                                <Input
                                    type="number"
                                    value={hours}
                                    onChange={e => setHours(e.target.value)}
                                    inputProps={{ min: 0 }}
                                />
                            </FormControl>
                            <FormControl style={{ width: '70px', marginLeft: '12px' }}>
                                <InputLabel>Minutes</InputLabel>
                                <Input
                                    type="number"
                                    value={minutes}
                                    onChange={e => setMinutes(e.target.value)}
                                    inputProps={{ min: 0, max: 59 }}
                                />
                            </FormControl>
                        </Box>
                    </Box>
                    <Box display="flex" justifyContent="flex-end" width="100%" mt={4}>
                        {saving ? <CircularProgress /> : <Button color="primary" onClick={handleConfirmTime} variant="contained">Save</Button>}
                    </Box>
                </DialogContent>
            </React.Fragment>}
        </Dialog>
    );
}

export default CaseResearchDialog;