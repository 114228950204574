import React, {useMemo} from "react";
import {CircularProgress, Dialog, DialogContent, makeStyles, Typography} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {setRaiseCaseRequestId, setSeekAdviceMiscActivityId} from "../../redux/actions/userActions";
import useSeekAdviceActivity from "../../hooks/queries/useSeekAdviceActivity";
import {Alert} from "@material-ui/lab";
import {format} from "date-fns";
import useRaiseCaseRequest from "../../hooks/queries/useRaiseCaseRequest";

const useStyles = makeStyles((theme) => ({
    headerLine: {
        display: "flex",
        alignItems: "flex-end",
    },
    headerSubtitle: {
        marginRight: theme.spacing(1),
    },
    messageContainer: {
        marginTop: theme.spacing(3),
    },
    loadingContainer: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
    },
}));

const SeekAdvicePreviewDialog = () => {
    const { seekAdviceMiscActivityId, raiseCaseRequestId } = useSelector(state => state.userReducer);
    const dispatch = useDispatch();
    const classes = useStyles();
    
    const { data: seekAdviceData, isLoading: seekAdviceIsLoading, error: seekAdviceError, isError: seekAdviceIsError } = useSeekAdviceActivity(seekAdviceMiscActivityId);
    const { data: raiseCaseRequestData, isLoading: raiseCaseRequestIsLoading, error: raiseCaseRequestError, isError: raiseCaseRequestIsError } = useRaiseCaseRequest(raiseCaseRequestId);
    
    const data = useMemo(() => 
        raiseCaseRequestId ? raiseCaseRequestData : seekAdviceData, 
        [raiseCaseRequestData, seekAdviceData]);
    const isLoading = useMemo(() => 
        raiseCaseRequestId ? raiseCaseRequestIsLoading : seekAdviceIsLoading, 
        [raiseCaseRequestIsLoading, seekAdviceIsLoading]);
    const isError = useMemo(() => 
        raiseCaseRequestId ? raiseCaseRequestIsError : seekAdviceIsError, 
        [raiseCaseRequestIsError, seekAdviceIsError]);
    const error = useMemo(() => 
        raiseCaseRequestId ? raiseCaseRequestError : seekAdviceError, 
        [raiseCaseRequestError, seekAdviceError]);
    
    const onClose = () => {
        dispatch(setSeekAdviceMiscActivityId(null));
        dispatch(setRaiseCaseRequestId(null));
    }
    
    const Content = () => {
        if (isLoading)
            return <div className={classes.loadingContainer}><CircularProgress /></div>
        
        if (isError)
            return <Alert variant="outlined" severity="error">{error?.message || "Cannot preview Seek Advice query at this time"}</Alert>
        
        if (!data)
            return null;
        
        return <>
            <div className={classes.headerLine}>
                <Typography className={classes.headerSubtitle} variant="subtitle2">From:</Typography>
                <div>{data.contactName}</div>
            </div>
            <div className={classes.headerLine}>
                <Typography className={classes.headerSubtitle} variant="subtitle2">Date:</Typography>
                <div>{format(new Date(data.date), "E dd/MM/yyyy HH:mm")}</div>
            </div>
            <div className={classes.headerLine}>
                <Typography className={classes.headerSubtitle} variant="subtitle2">Subject:</Typography>
                <div>{data.subject}</div>
            </div>
            {data.employee ? <div className={classes.headerLine}>
                <Typography className={classes.headerSubtitle} variant="subtitle2">Employee:</Typography>
                <div>{data.employee}</div>
            </div> : null}
            <div className={classes.messageContainer}>{data.message}</div>
        </>
    }
    
    return (
      <Dialog open={!!seekAdviceMiscActivityId || !!raiseCaseRequestId}  onClose={onClose} maxWidth="sm" fullWidth>
          <DialogContent>
              <Content />
          </DialogContent>
      </Dialog>
    );
}

export default SeekAdvicePreviewDialog;