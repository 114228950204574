import * as types from '../constants';

const initialState = {
    activity: '',
    inCall: false,
    unallocatedCalls: [],
    pendingEmail: null,
    currentCallId: null,
    pendingRaiseCaseRequest: null,
};

const reducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case types.SET_FLEX_ACTIVITY: {
            return {
                ...state,
                activity: action.activity,
            };
        }

        case types.DIAL: {
            console.log({action});
            var n = '';

            if (action.number.startsWith('00')) {
                n = action.number.replace(/\d\d/, '');
            } else if (action.number.startsWith('0')) {
                n = '44' + action.number.replace(/\d/, '');
            } else if (action.number.startsWith('+')) {
                n = action.number;
            } else {
                n = '44' + action.number;
            }

            n = '+' + n.replace(/\D/g, '');

            window.frames[0].postMessage({
                event: "dial",
                number: n,
                contactId: action.contactId,
                accountId: action.accountId,
                outboundNumber: action.outboundNumber,
                recordingDisabled: action.recordingDisabled
            }, "*");            

            return {
                ...state,
                inCall: true,
            };
        }

        case types.SET_IN_CALL: {
            return {
                ...state,
                inCall: action.payload.inCall,
                currentCallId: action.payload.inCall ? action.payload.callId : null,
            };
        }

        case types.SET_CALL_START: {
            return {
                ...state,
                unallocatedCalls: [
                    ...state.unallocatedCalls,
                    { 
                        start: new Date(),
                        callId: action.payload.callId,
                    },
                ],
            };
        }

        case types.SET_CALL_END: {
            let calls = state.unallocatedCalls;
            return {
                ...state,
                unallocatedCalls: calls.map((c, index) =>
                    index === calls.length - 1 ? { ...c, end: new Date() } : c
                ),
            };
        }

        case types.SET_PENDING_EMAIL: {
            return {
                ...state,
                pendingEmail: action.taskSid,
            };
        }

        case types.CLEAR_UNALLOCATED_CALLS: {
            return {
                ...state,
                unallocatedCalls: [],
            };
        }
        
        case types.SET_PENDING_RAISE_CASE_REQUEST: {
            return {
                ...state,
                pendingRaiseCaseRequest: action.taskSid,
            };
        }

        default:
            return state;
    }
};

export default reducer;
