import React, { useState, useMemo } from "react";
import Helmet from "react-helmet";
import styled from "styled-components";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import {
    Avatar,
    Box,
    CardContent,
    Card as MuiCard,
    FormControl,
    Input,
    InputAdornment,
    InputLabel,
    Typography,
    Divider as MuiDivider,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Button as MuiIconButton,
    Grid,
    Select,
    MenuItem,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { makeStyles } from "@material-ui/core/styles";
import {
    Search as SearchIcon,
    Edit as EditIcon,
    Replay as UndoIcon,
} from "@material-ui/icons";
import axios from "../plugins/axios";
import _ from "lodash";
import avatarStyles from "../theme/avatars";
import { updateUserManager } from "../redux/actions/userActions";
import { setSnackAction } from "../redux/actions/snackActions";
import useFilteredAdvisers from "../hooks/useFilteredAdvisers";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const formStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(3),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const IconButton = styled(MuiIconButton)`
    padding: 2px;
    margin-top: -2px;
    margin-left: 2px;
    min-width: 0;
`;

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

const Advisers = ({ userState, dispatch }) => {
    const formClasses = formStyles();
    const avatarClasses = avatarStyles();
    const adviceTypeFilteredAdvisers = useFilteredAdvisers();
    const [adviser, setAdviser] = useState("");
    const [mode, setMode] = useState("view");

    const history = useHistory();

    const toggleMode = () => setMode(mode === "edit" ? "view" : "edit");

    const handleClick = (userId) => {
        if (mode === "view") history.push(`/adviser/${userId}`);
    };

    const handleManagerChange = async (managerId, userId) => {
        let response = await axios.get(`${apiUrl}/updateusermanager/${userId}/${managerId}`);

        if (response.status === 200) {
            dispatch(updateUserManager(userId, managerId));
            dispatch(setSnackAction("Saved!", "success"));
            setMode("view");
        }
    };

    const ViewManager = ({ u }) =>
        u.managerId ? (
            <Box display="flex" alignItems="center" justifyContent="flex-start">
                <Avatar
                    style={{ marginRight: "8px" }}
                    alt={userState.users[u.managerId].name}
                    src={userState.users[u.managerId].photo}
                />
                <Typography variant="body2">
                    {userState.users[u.managerId].name}
                </Typography>
            </Box>
        ) : (
            <div>-</div>
        );

    const SelectManager = ({ u }) => (
        <Grid container direction="column" alignItems="flex-start">
            <FormControl m={2} variant="outlined">
                <Select
                    value={managerIds[u.userId].managerId || ""}
                    onChange={(e) => handleManagerChange(e.target.value, u.userId)}
                >
                    {activeAdvisers
                        .sort((a, b) => a.name > b.name ? 1 : b.name > a.name ? -1 : 0)
                        .map((u) => (
                            <MenuItem key={u.userId} value={u.userId}>
                                <Box display="flex" alignItems="center">
                                    <Avatar
                                        style={{ marginRight: "8px" }}
                                        className={avatarClasses.small}
                                        alt={u.name}
                                        src={u.photo}
                                    />
                                    {u.name}
                                </Box>
                            </MenuItem>
                        ))}
                </Select>
            </FormControl>
        </Grid>
    );

    const activeAdvisers = useMemo(() => {
        return adviceTypeFilteredAdvisers.filter((u) => !u.isDisabled);
    }, [adviceTypeFilteredAdvisers]);

    const filteredAdvisers = useMemo(() => {
        return activeAdvisers.filter((u) =>
            u.name.toLowerCase().includes(adviser.toLowerCase())
        );
    }, [activeAdvisers, adviser]);

    const managerIds = useMemo(() => {
        return _.keyBy(
            activeAdvisers.map((u) => ({
                userId: u.userId,
                managerId: u.managerId || null,
            })),
            (u) => u.userId
        );
    }, [activeAdvisers]);

    return (
        <React.Fragment>
            <Helmet title="Advisers" />
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
            >
                <Typography variant="h3" gutterBottom display="inline">
                    Advisers
                </Typography>
                <Box display="flex" alignItems="center">
                    <FormControl
                        style={{ paddingBottom: "8px" }}
                        className={formClasses.formControl}
                    >
                        <InputLabel>Adviser</InputLabel>
                        <Input
                            value={adviser}
                            onChange={(e) => setAdviser(e.target.value)}
                            type="text"
                            startAdornment={
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                </Box>
            </Box>
            <Divider my={6} />
            <Card mb={6}>
                <CardContent>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Position</TableCell>
                                <TableCell>
                                    Manager
                                    <IconButton onClick={toggleMode}>
                                        {mode === "view" ? (
                                            <EditIcon />
                                        ) : (
                                            <UndoIcon />
                                        )}
                                    </IconButton>
                                </TableCell>
                                <TableCell>Is Active</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredAdvisers
                                .sort((a, b) =>
                                    a.name > b.name
                                        ? 1
                                        : b.name > a.name
                                        ? -1
                                        : 0
                                )
                                .map((u) => (
                                    <TableRow
                                        style={{
                                            cursor:
                                                mode === "view"
                                                    ? "pointer"
                                                    : "default",
                                        }}
                                        key={u.userId}
                                        hover={mode === "view"}
                                        onClick={() => handleClick(u.userId)}
                                    >
                                        <TableCell>
                                            <Box
                                                display="flex"
                                                alignItems="center"
                                                justifyContent="flex-start"
                                            >
                                                <Avatar
                                                    style={{
                                                        marginRight: "8px",
                                                    }}
                                                    alt={u.name}
                                                    src={u.photo}
                                                />
                                                <Typography variant="body2">
                                                    {u.name}
                                                </Typography>
                                            </Box>
                                        </TableCell>
                                        <TableCell>{u.position}</TableCell>
                                        <TableCell>
                                            {mode === "view" ? (
                                                <ViewManager u={u} />
                                            ) : (
                                                <SelectManager u={u} />
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {u.isActive ? "Yes" : "No"}
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </CardContent>
            </Card>
        </React.Fragment>
    );
};

export default connect((state) => ({ userState: state.userReducer }))(Advisers);
