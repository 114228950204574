import React, { useMemo } from 'react';
import {
    Box,
    Typography,
    FormControl,
    InputLabel,
    ListSubheader,
    Tooltip,
    MenuItem,
    Select,
    makeStyles,
} from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import { grey } from "@material-ui/core/colors";
import { formatShortMonthDate } from "../utils/dateTimeFormatters";
import SubscriptionBox from "./SubscriptionBox";

const useStyles = makeStyles(() => ({
    infoIcon: {
        fontSize: "1em",
        cursor: "pointer",
        color: grey[700],
    },
    minWidth: {
        minWidth: '150px'
    },
    chaargeable: {
        borderRadius: '5px',
        backgroundColor: '#defaf7',
        padding: '1px 5px',
        fontSize: '0.8em'
    },
}))

const ContractSelect = ({ contractId, setContractId, contracts, adviceTypeId }) => {
    const classes = useStyles();   

    const partnerContracts = useMemo(() => {
        if (!contracts)
            return [];

        let availableContracts = contracts;

        if (adviceTypeId)
            availableContracts = availableContracts.filter(x => x.adviceTypeId === adviceTypeId || x.additionalAdviceTypeId === adviceTypeId);                   

        return availableContracts.filter((c) => c.isPartnership);

    }, [adviceTypeId, contracts]);

    const accountContracts = useMemo(() => {
        if (!contracts)
            return [];

        let availableContracts = contracts;

        if (adviceTypeId)
            availableContracts = availableContracts.filter(x => x.adviceTypeId === adviceTypeId || x.additionalAdviceTypeId === adviceTypeId);        

        return availableContracts.filter((c) => !c.isPartnership);
    }, [adviceTypeId, contracts]);    

    const ContractPanel = ({ c }) => (
        <Box display="flex" flexDirection="column" style={{ width: '100%' }}>
            <Typography variant="subtitle2">
                {c.coreService || "Not set"}
                {c.summary && (
                    <Tooltip title={c.summary}>
                        <InfoIcon className={classes.infoIcon} />
                    </Tooltip>
                )}
            </Typography>
            <Box display="flex">
                <Box flexGrow={1}>
                    <Typography variant="caption">
                        {`${formatShortMonthDate(c.startDate)} - ${formatShortMonthDate(c.endDate)}`}
                    </Typography>
                </Box>
                {c.isChargeable && <Typography className={classes.chaargeable}>Chargeable Contract</Typography>}
            </Box>
            <SubscriptionBox subscriptions={c.subscriptions} />            
        </Box>
    );

    return (
        <FormControl className={classes.minWidth}>
            <InputLabel>Contract</InputLabel>
            <Select value={contractId} onChange={(e) => setContractId(e.target.value)} data-cy="contract_search">
                {!contracts?.length && <MenuItem>No contracts found</MenuItem>}
                {partnerContracts.length > 0 && <ListSubheader disableSticky>Partnership Contracts</ListSubheader>}
                {partnerContracts.map((c) =>
                    <MenuItem key={c.contractId} value={c.contractId}>
                        <ContractPanel c={c} />
                    </MenuItem>
                )}
                {accountContracts.length > 0 && <ListSubheader disableSticky>Account Contracts</ListSubheader>}
                {accountContracts.map((c) =>
                    <MenuItem key={c.contractId} value={c.contractId}>
                        <ContractPanel c={c} />
                    </MenuItem>
                )}
            </Select>
        </FormControl>
    );
};

export default ContractSelect;