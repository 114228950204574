import {useQuery} from "react-query";
import queryKeys from "../../constants/queryKeys";
import caseActivityService from "../../services/caseActivityService";

const useSeekAdviceActivity = (caseActivityMiscId) =>
    useQuery({
        queryKey: [queryKeys.seekAdviceActivity, caseActivityMiscId],
        queryFn: () => caseActivityService.getSeekAdviceActivity(caseActivityMiscId),
        enabled: !!caseActivityMiscId,
    });

export default  useSeekAdviceActivity;