import React, { useState } from "react";
import { connect } from "react-redux";
import images from "../plugins/images";
import Helmet from "react-helmet";
import PrecedentFolders from '../components/dragndrop/PrecedentFolders';
import PrecedentEditor from "../components/PrecedentEditor";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import precedentService from "../services/precedentService";
import documentService from "../services/documentService";
import {
    setPrecedentfolder as setPrecedentfolderAction,
    setPrecedentFolders as setPrecedentFoldersAction,
    setPrecedentDisabledFlag as setPrecedentDisabledFlagAction,
    setPrecedentFolderDisabledFlag as setPrecedentFolderDisabledFlagAction,
    setPrecedent as setPrecedentAction,
    setPrecedents as setPrecedentsAction,
    setPrecedentIsAdvisor as setPrecedentIsAdvisorAction,
    setPrecedentResourceLicences as setPrecedentResourceLicencesAction,
} from "../redux/actions/configActions";
import { openFileViewer } from "../redux/actions/userActions";
import { useAuth } from "../contexts/authContext";
import userRoles from "../constants/userRoles";
import { grey } from "@material-ui/core/colors";
import {
    Breadcrumbs,
    Button,
    Box,
    CardContent,
    Chip,
    CardMedia,
    Card,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    DialogContentText,
    Divider,
    Grid,
    Link,
    TextField,
    Typography,
    Menu,
    MenuItem,
    IconButton,
    makeStyles,
    Select,
    FormGroup,
    FormControlLabel,
    Checkbox,
} from "@material-ui/core";
import {
    VisibilityTwoTone as PreviewIcon,
    Edit as EditIcon,
    PostAddRounded as AddDocIcon,
    CreateNewFolderOutlined as AddFolderIcon,
    FolderOpenRounded as FolderIcon,
    CloudUpload,
    Delete as TrashIcon,
    MoreVert as MoreVertIcon,
    SettingsOutlined as SettingsOutlinedIcon,
    DeleteOutlined as DeleteOutlinedIcon,
    TrendingFlatOutlined as TrendingFlatOutlinedIcon,
} from "@material-ui/icons";
import { isStringEmptyOrWhitespaceOnly } from "../helpers/isStringEmptyOrWhitespaceOnly";
import { Alert } from "@material-ui/lab";
import { setSnackAction } from "../redux/actions/snackActions";
import { format } from "date-fns";

const useStyles = makeStyles((theme) => ({
    breadcrumb: {
        padding: theme.spacing(2),
    },
    card: {
        padding: theme.spacing(1),
        marginBottom: "6px",
    },
    cardMedia: {
        height: "200px",
        cursor: "pointer",
    },
    cursorPointer: {
        cursor: "pointer",
        marginRight: "1px",
    },
    custDetail: {
        padding: theme.spacing(6),
    },
    iconButton: {
        padding: "5px",
        marginRight: "0px",
        marginLeft: "10px",
        display: "flex",
        flexDirection: "column",
        color: "red",
    },
    documentTitle: {
        wordBreak: "break-all",
    },
    title: {
        fontSize: 18,
        fontWeight: 500,
    },
    content: {
        width: "100%",
        [theme.breakpoints.up("sm")]: {
            width: 400,
        },
        [theme.breakpoints.up("xl")]: {
            width: 500,
        },
    },
    filenameWrapper: {
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "space-between",
        margin: theme.spacing(5, 0, 1, 0),
    },
    filename: {
        wordBreak: "break-all",
        width: "75%",
        fontWeight: 500,
    },
    trashIcon: {
        color: theme.palette.error.main,
    },
    errorAlert: {
        marginTop: theme.spacing(3),
    },
    uploadIcon: {
        marginLeft: theme.spacing(2),
    },
    menuIcon: {
        color: grey[700],
        marginRight: theme.spacing(2),
    },
    licenceTag: {
        color: "#2a1f5f",
        border: "1px solid #9c97b5",
        backgroundColor: "white",
        fontWeight: 500,
        borderRadius: "6px",
        margin: "auto 4px",
    },
}));

const PRECEDENT_FILENAME_LIMIT = 256;

const Precedents = ({
    configState,
    openFileViewer,
    setPrecedentDisabledFlag,
    setPrecedentfolder,
    setPrecedentFolderDisabledFlag,
    setPrecedent,
    setPrecedentFolders,
    setPrecedents,
    setPrecedentIsAdvisor,
    setPrecedentResourceLicences,
    setSnack,
}) => {
    const classes = useStyles();
    const [sector, setSector] = useState(null);
    const [caseType, setCaseType] = useState(null);
    const [precedentFolder, setPrecedentFolder] = useState(null);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [precedentRec, setPrecedentRec] = useState(null);
    const [precedentToMove, setPrecedentToMove] = useState(null);
    const [precedentToRemove, setPrecedentToRemove] = useState(null);
    const [confirmRemovalDialogOpen, setConfirmRemovalDialogOpen] = useState(false);
    const [licenceDialogOpen, setLicenceDialogOpen] = useState(false);
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState("");
    const [newFolderDialogOpen, setNewFolderDialogOpen] = React.useState(false);
    const [newFolderName, setNewFolderName] = useState("");

    // context menu
    const [contextAnchorEl, setContextAnchorEl] = useState(null);
    const [contextEvent, setContextEvent] = useState(null);

    //user, application, licences
    const { hasRole } = useAuth();
    const { resourceLicences } = configState;
    const canAdmin = hasRole(userRoles.SUPER_USER) || hasRole(userRoles.LEGAL_MANAGER);
    const isSuperUser = hasRole(userRoles.SUPER_USER);

    const [filterLicence, setFilterLicence] = useState("");
    const [currentPrecedentLicences, setCurrentPrecedentLicences] = useState(null);

    const [isLicenceCheck, setIsLicenceCheck] = useState([]);
    const [isAdvisorCheck, setIsAdvisorCheck] = useState(false);

    const sectorPrecedents = () => {
        if (sector === null)
            return [];

        return Object.values(configState.precedents)
            .filter(p => isSuperUser || p.azureFileId)
            .filter((p) => filterLicence !== "" ? (typeof filterLicence === 'number' ? p.licences.find(l => l.resourceLicenceId === filterLicence) : p.isAdvisor === true) && p.sectorId === sector.sectorId : p.sectorId === sector.sectorId)
            .sort((s1, s2) => s1.sortIndex - s2.sortIndex);
    };

    const orderedCaseTypes = Object.values(configState.caseTypes).sort((a, b) =>
        a.name.toLowerCase() > b.name.toLowerCase()
            ? 1
            : b.name.toLowerCase() > a.name.toLowerCase()
                ? -1
                : 0
    );

    const caseTypePrecedents = (ct) => {
        return sectorPrecedents()
            .filter((p) => p.caseTypeId === ct.caseTypeId && !p.precedentFolderId && !p.disabled)
            .sort((s1, s2) => s1.sortIndex - s2.sortIndex);
    };

    const caseTypePrecedentCount = (ct) =>
        sectorPrecedents().filter((p) => p.caseTypeId === ct.caseTypeId && !p.disabled).length;

    const folderPrecedents = (f) =>
        sectorPrecedents()
            .filter((p) => p.precedentFolderId == f.precedentFolderId && !p.disabled)
            .sort((s1, s2) => s1.sortIndex - s2.sortIndex);

    const uniqueFolders = (ct) =>
        Object.values(configState.precedentFolders).filter(
            (p) =>
                p.caseTypeId === ct.caseTypeId &&
                p.sectorId === sector.sectorId &&
                p.disabled == false
        );

    const onSave = (p) => {
        setPrecedentRec(p);
    };

    const handleRemoveLetterClick = async () => {
        if (precedentToRemove.precedentId) {
            try {
                await precedentService.removePrecedent({
                    precedentId: precedentToRemove.precedentId,
                });
                setPrecedentDisabledFlag(precedentToRemove.precedentId, true);
                setConfirmRemovalDialogOpen(false);
                setPrecedentToRemove(null);
            } catch (error) {
                console.error(error);
            }
        } else {
            try {
                await precedentService.removePrecedentFolder({
                    precedentFolderId: precedentToRemove.precedentFolderId,
                });
                setPrecedentFolderDisabledFlag(precedentToRemove.precedentFolderId, true);
                setConfirmRemovalDialogOpen(false);
                setPrecedentToRemove(null);
            } catch (error) {
                console.error(error);
            }
        }
    };

    const handleMoveLetterClick = async (pFolderId) => {
        try {
            await precedentService.savePrecedentFolder({
                precedentId: precedentToMove.precedentId,
                precedentFolderId: pFolderId,
            });
            setPrecedentfolder(precedentToMove.precedentId, pFolderId);
            setConfirmDialogOpen(false);
            setPrecedentToMove(null);
        } catch (error) {
            console.error(error);
        }
    };

    const handleUpdateLicences = async () => {
        const licenceIds = isLicenceCheck.map(a => a.resourceLicenceId);
        try {
            await precedentService.updatePrecedentResourceLicences({
                precedentId: currentPrecedentLicences.precedentId,
                isAdvisor: isAdvisorCheck,
                licences: licenceIds
            });
            setPrecedentIsAdvisor(currentPrecedentLicences.precedentId, isAdvisorCheck);
            setPrecedentResourceLicences(currentPrecedentLicences.precedentId, isLicenceCheck);
            setLicenceDialogOpen(false);
        } catch (error) {
            console.error(error);
        }
    };

    const handleCaseTypeClick = (ct) => {
        //when the casetype is selected remove any previous folder selection
        setPrecedentFolder(null);
        setCaseType(ct);
    };

    const isValidFilenameLength = fileName.length < PRECEDENT_FILENAME_LIMIT;

    const handleResetStaticFile = () => {
        setFile(null);
        setFileName("");
    };

    const saveFile = (e) => {
        setFile(e.target.files[0]);
        setFileName(e.target.files[0].name);
    };

    const uploadFile = async (e) => {
        //if a file has been selected then upload, otherwise do nothing
        if (file) {
            const formData = new FormData();
            formData.append("file", file);
            formData.append("fileName", fileName);

            try {
                const response = await precedentService.uploadPrecedentDocument(formData);
                saveStaticDoc(response, fileName);
            } catch (error) {
                console.error(error);
            }
        }
    };

    const saveStaticDoc = async (azureFileId, fileName) => {
        var newStaticPrecedentInfo = {
            currentVersion: 0,
            name: fileName,
            caseTypeId: caseType.caseTypeId,
            sectorId: sector.sectorId,
            precedentFolderId: precedentFolder !== null ? precedentFolder.precedentFolderId : null,
            azureFileId: azureFileId,
            partnershipId: null,
            azureFileContentType: "",
            azureFileReference: null,
        };
        var saveData = { ...newStaticPrecedentInfo };
        try {
            const response = await precedentService.savePrecedent(saveData);
            saveData.precedentId = response.precedentId;
            saveData.azureFileContentType = response.azureFileContentType;
            saveData.azureFileReference = response.azureFileReference;
            saveData.isAdvisor = false;
            saveData.licences = [];
            saveData.currentVersion += 1;
            setPrecedent(saveData);
            setNewFileDialogOpen(false);
        } catch (error) {
            console.error(error);
        } finally {
            handleResetStaticFile();
        }
    };

    const handleCreateNewFolder = async () => {
        try {
            await precedentService.createPrecedentFolder({
                caseTypeId: caseType.caseTypeId,
                sectorId: sector.sectorId,
                name: newFolderName,
            });
            var folderResponse = await precedentService.getPrecedentFolders();
            setPrecedentFolders(folderResponse);
            setConfirmDialogOpen(false);
            setNewFolderDialogOpen(false);
        } catch (error) {
            console.error(error);
        }
    };

    const openDoc = async (attachment) => {
        try {
            const response = await documentService.openDocument(attachment.reference);

            if (response === null) {
                setSnack("File is password protected and cannot be previewed.", "warning");
                return;
            }

            let blob = new Blob([response], { type: attachment.contentType });
            let parsedFileName = attachment.filename.split(".");
            openFileViewer(
                {
                    type: parsedFileName[parsedFileName.length - 1],
                    path: window.URL.createObjectURL(blob),
                    name: attachment.filename,
                },
                null,
                attachment
            );
        } catch (error) {
            console.error(error);
        }
    };

    const handleRemovalConfirmationDialog = (p) => {
        setPrecedentToRemove(p);
        setConfirmRemovalDialogOpen(true);
        setContextAnchorEl(null);
    };

    const handleLicenceDialog = (p) => {
        setCurrentPrecedentLicences(p);
        setIsLicenceCheck(p.licences);
        setIsAdvisorCheck(p.isAdvisor);
        setLicenceDialogOpen(true);
        setContextAnchorEl(null);
    }

    const handleCloseLicenceDialog = () => {
        setLicenceDialogOpen(false);
    };

    const selectAdvisorCheckbox = e => {
        const { checked } = e.target;
        if (!checked) {
            setIsAdvisorCheck(false);
        } else {
            setIsAdvisorCheck(true);
        }
    };

    const selectLicenceCheckbox = e => {
        let { value, checked, name } = e.target;
        value = parseInt(value);
        if (!checked) {
            setIsLicenceCheck(isLicenceCheck.filter(item => item.resourceLicenceId !== value));
        } else {
            setIsLicenceCheck([...isLicenceCheck, { "resourceLicenceId": value, "name": name }]);
        }
    };

    const handleOpenConfirmationDialog = (p) => {
        setPrecedentToMove(p);
        setConfirmDialogOpen(true);
        setContextAnchorEl(null);
    };

    const handleCloseConfirmationDialog = () => {
        setConfirmDialogOpen(false);
    };

    const handleCloseRemovalConfirmationDialog = (event, reason) => {
        if (reason !== 'backdropClick') {
            setConfirmRemovalDialogOpen(false);
            setPrecedentToRemove(null);
        }
    };

    const handleNewFolderDialogClose = () => {
        setNewFolderDialogOpen(false);
    };

    const [newFileDialogOpen, setNewFileDialogOpen] = React.useState(false);

    const handleNewFileDialogClose = () => {
        handleResetStaticFile();
        setNewFileDialogOpen(false);
    };

    const triggerOpenDocument = async (staticFileToPreview) => {
        var staticFileAzureDetails = {
            reference: staticFileToPreview.azureFileReference,
            filename: staticFileToPreview.name,
            contentType: staticFileToPreview.azureFileContentType,
        };
        var previewRequest = { ...staticFileAzureDetails };
        openDoc(previewRequest);
    };

    const handleOnSubDragEnd = async (result) => {
        await handleOnDragEndCall(result, folderPrecedents(precedentFolder));
    };

    const handleOnDragEnd = async (result) => {
        await handleOnDragEndCall(result, caseTypePrecedents(caseType));
    };

    const handleOnDragEndCall = async (result, existingItems) => {
        if (!result.destination) return;
        const items = Array.from(existingItems);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        const updatedOrder = items.map((item, index) => {
            return {
                ...item,
                sortIndex: index,
            };
        });
        try {
            const precedents = await precedentService.savePrecedents(updatedOrder);
            setPrecedents(precedents);
        } catch (error) {
            console.error(error);
        }
    };

    const handleOpenContextMenu = (e, event) => {
        setContextEvent(e);
        setContextAnchorEl(event.currentTarget);
    };

    const handleCloseContextMenu = () => {
        setContextAnchorEl(null);
    }

    //precedentRows is used when displaying precedent docs in a list at bother base and sub folder level
    const precedentRows = (handleOnDragEnd, droppableId, precedents) => {
        return (
            <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId={droppableId}>
                    {(provided) => (
                        <Grid
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                        >
                            {precedents.map((p, index) => {
                                return (
                                    <Draggable
                                        isDragDisabled={!isSuperUser}
                                        key={p.precedentId}
                                        draggableId={p.name}
                                        index={index}
                                    >
                                        {(provided) => (
                                            <Card
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                ref={provided.innerRef}
                                            >
                                                <CardContent>
                                                    <Box
                                                        display="flex"
                                                        flexGrow={1}
                                                    >
                                                        <Box
                                                            className={classes.cursorPointer}
                                                            onClick={() => p.azureFileId ? triggerOpenDocument(p) : setPrecedentRec(p)}
                                                        >
                                                            {p.azureFileId ? <PreviewIcon /> : <EditIcon />}
                                                        </Box>
                                                        <Box>
                                                            <Typography
                                                                variant="h5"
                                                                align="left"
                                                                className={classes.documentTitle}
                                                            >
                                                                {p.name}
                                                            </Typography>
                                                            {isSuperUser && p.addedOn && <div style={{ fontSize: '0.7em' }}>Uploaded: {format(new Date(p.addedOn), "dd/MM/yyyy")}</div>}
                                                        </Box>
                                                        <Box
                                                            display="flex"
                                                            ml={2}
                                                            pt={1}
                                                            flexGrow={1}
                                                        />
                                                        {
                                                            p.isAdvisor &&
                                                            <Chip
                                                                color="secondary"
                                                                label="Advisor"
                                                                className={classes.licenceTag}
                                                            />
                                                        }

                                                        {p.licences?.map((licence, index) => {
                                                            return (
                                                                <Chip
                                                                    key={index}
                                                                    color="secondary"
                                                                    label={licence.name}
                                                                    className={classes.licenceTag}
                                                                />
                                                            )
                                                        })}
                                                        {
                                                            isSuperUser &&
                                                            <Box
                                                                display="flex"
                                                                justifyContent="flex-end"
                                                            >
                                                                <IconButton onClick={ev => handleOpenContextMenu(p, ev)}>
                                                                    <MoreVertIcon />
                                                                </IconButton>
                                                            </Box>
                                                        }
                                                    </Box>
                                                </CardContent>
                                            </Card>
                                        )}
                                    </Draggable>
                                );
                            })}
                            {provided.placeholder}
                            <Menu
                                anchorEl={contextAnchorEl}
                                keepMounted
                                open={!!contextAnchorEl}
                                onClose={handleCloseContextMenu}
                                getContentAnchorEl={null}
                            >
                                {canAdmin && (
                                    <MenuItem
                                        onClick={() => handleLicenceDialog(contextEvent)}
                                    >
                                        <SettingsOutlinedIcon className={classes.menuIcon} />
                                        Manage Licence Tiers
                                    </MenuItem>
                                )}
                                {uniqueFolders(
                                    caseType
                                ).length > 0 && (
                                        <MenuItem
                                            onClick={() => handleOpenConfirmationDialog(contextEvent)}
                                        >
                                            <TrendingFlatOutlinedIcon className={classes.menuIcon} />
                                            Move to
                                        </MenuItem>
                                    )}
                                <MenuItem
                                    onClick={() => handleRemovalConfirmationDialog(contextEvent)}
                                >
                                    <DeleteOutlinedIcon className={classes.menuIcon} />
                                    Delete
                                </MenuItem>
                            </Menu>
                        </Grid>
                    )}
                </Droppable>
            </DragDropContext>
        );
    };

    return (
        <React.Fragment>
            <Helmet title="Precedents" />
            <Box display="flex">
                <Box flexGrow={1}>
                    <Breadcrumbs
                        className={classes.breadcrumb}
                        aria-label="Breadcrumb"
                    >
                        <Link
                            color="textPrimary"
                            variant="h3"
                            underline={sector ? "hover" : "none"}
                            onClick={() => {
                                setSector(null);
                                setCaseType(null);
                                setPrecedentRec(null);
                                setPrecedentFolder(null);
                            }}
                        >
                            Precedent Manager
                        </Link>
                        {sector && (
                            <Link
                                underline={caseType ? "hover" : "none"}
                                onClick={() => {
                                    setCaseType(null);
                                    setPrecedentRec(null);
                                    setPrecedentFolder(null);
                                }}
                            >
                                {sector.name}
                            </Link>
                        )}
                        {caseType && (
                            <Link
                                underline={
                                    precedentRec || precedentFolder
                                        ? "hover"
                                        : "none"
                                }
                                onClick={() => {
                                    setPrecedentRec(null);
                                    setPrecedentFolder(null);
                                }}
                            >
                                {caseType.name}
                            </Link>
                        )}
                        {precedentFolder && (
                            <Link
                                underline={precedentRec ? "hover" : "none"}
                                onClick={() => {
                                    setPrecedentRec(null);
                                }}
                            >
                                {precedentFolder.name}
                            </Link>
                        )}
                        {precedentRec && (
                            <Typography>{precedentRec.name}</Typography>
                        )}
                    </Breadcrumbs>
                </Box>
                <Box mr={2}>
                    {isSuperUser && caseType && !precedentRec && (
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => setNewFileDialogOpen(true)}
                        >
                            New Precedent &nbsp;
                            <AddDocIcon />
                        </Button>
                    )}
                </Box>
                <Box mr={2}>
                    {sector && !caseType && canAdmin && (
                        <Select
                            value={filterLicence || ""}
                            onChange={(e) =>
                                setFilterLicence(e.target.value)
                            }
                            displayEmpty
                        >
                            <MenuItem value="">
                                Filter
                            </MenuItem>
                            <MenuItem value="advisor">
                                Advisor
                            </MenuItem>
                            {resourceLicences?.map((licence) => (
                                <MenuItem key={licence.resourceLicenceId} value={licence.resourceLicenceId}>
                                    {licence.name}
                                </MenuItem>
                            ))}
                        </Select>
                    )}
                    {isSuperUser && caseType && !precedentRec && !precedentFolder && (
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => setNewFolderDialogOpen(true)}
                        >
                            New Subfolder &nbsp;
                            <AddFolderIcon />
                        </Button>
                    )}
                </Box>
            </Box>

            <Divider />

            {!sector && (
                <Grid container spacing={6}>
                    {Object.values(configState.sectors).map((s) => s.isPrecedentCategory && (
                        <Grid key={s.sectorId} item xs={12} lg={6} xl={3}>
                            <Card className={classes.card}>
                                <CardMedia
                                    className={classes.cardMedia}
                                    image={images[s.image] || images.general}
                                    title={s.name}
                                    onClick={() => setSector(s)}
                                />
                                <CardContent>
                                    <Typography variant="h5">
                                        {s.name}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    )
                    )}
                </Grid>
            )}
            {sector && !caseType && (
                <Grid container spacing={6}>
                    {Object.values(orderedCaseTypes).map((ct) => (
                        <Grid key={ct.caseTypeId} item xs={12} lg={6} xl={3}>
                            <Card className={classes.card}>
                                <CardContent
                                    className={classes.cursorPointer}
                                    onClick={() => handleCaseTypeClick(ct)}
                                >
                                    <Box display="flex">
                                        <Box flexGrow={1}>
                                            <Typography
                                                variant="h5"
                                                align="left"
                                            >
                                                {ct.name}
                                            </Typography>
                                        </Box>
                                        {caseTypePrecedentCount(ct) > 0 && (
                                            <Box>
                                                <Chip
                                                    className={
                                                        classes.cursorPointer
                                                    }
                                                    size="small"
                                                    color="secondary"
                                                    label={caseTypePrecedentCount(
                                                        ct
                                                    )}
                                                    onClick={() =>
                                                        handleCaseTypeClick(ct)
                                                    }
                                                />
                                            </Box>
                                        )}
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            )}
            {caseType && !precedentRec && !precedentFolder && (
                <>
                    <Grid container spacing={6}>
                        <Grid item xs={12} md={9}>
                            {precedentRows(handleOnDragEnd, "precedents", caseTypePrecedents(caseType))}
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <PrecedentFolders
                                isSuperUser={isSuperUser}
                                configState={configState}
                                caseType={caseType}
                                precedentRec={precedentRec}
                                precedentFolder={precedentFolder}
                                sector={sector}
                                setPrecedentToRemove={setPrecedentToRemove}
                                setConfirmRemovalDialogOpen={setConfirmRemovalDialogOpen}
                                setPrecedentFolder={setPrecedentFolder}
                                setPrecedentFolders={setPrecedentFolders}
                            />
                        </Grid>
                    </Grid>
                </>
            )}

            {caseType && precedentFolder && !precedentRec && (
                <Grid item xs={12} md={12}>
                    {precedentRows(handleOnSubDragEnd, "subprecedents", folderPrecedents(precedentFolder))}
                </Grid>
            )}

            {precedentToMove && (
                <Dialog
                    open={confirmDialogOpen}
                    onClose={handleCloseConfirmationDialog}
                    disableBackdropClick={true}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Move " + precedentToMove.name}
                    </DialogTitle>
                    {precedentToMove.precedentFolderId && caseType && (
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Please confirm that you wish to move the above precedent to its containing folder ({caseType.name}) ?
                            </DialogContentText>
                        </DialogContent>
                    )}
                    {!precedentToMove.precedentFolderId && (
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Please confirm which sub folder you wish to move
                                the above precedent into but clicking on one of
                                the following folders
                            </DialogContentText>
                        </DialogContent>
                    )}

                    <Grid container>
                        {!precedentToMove.precedentFolderId &&
                            uniqueFolders(caseType).map((pf) => (
                                <Grid
                                    key={pf.precedentFolderId}
                                    item
                                    xs={12}
                                    lg={6}
                                    xl={3}
                                >
                                    <Card className={classes.card}>
                                        <CardContent>
                                            <Button
                                                onClick={() => handleMoveLetterClick(pf.precedentFolderId)}
                                                color="primary"
                                            >
                                                <FolderIcon />
                                                {pf.name}
                                            </Button>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))}
                    </Grid>
                    <DialogActions>
                        <Button
                            onClick={handleCloseConfirmationDialog}
                            color="primary"
                        >
                            Cancel
                        </Button>
                        {precedentToMove.precedentFolderId && (
                            <Button
                                onClick={() => handleMoveLetterClick(null)}
                                color="primary"
                            >
                                Confirm
                            </Button>
                        )}
                    </DialogActions>
                </Dialog>
            )}

            {currentPrecedentLicences && (
                <Dialog
                    open={licenceDialogOpen}
                    onClose={handleCloseLicenceDialog}
                    disableBackdropClick={true}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth="sm"
                    fullWidth
                >
                    <DialogTitle id="alert-dialog-title">Set Licences</DialogTitle>
                    <DialogContent>
                        <DialogContentText>Please choose the licences that can see the precedent file</DialogContentText>
                        <FormGroup >
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onChange={selectAdvisorCheckbox}
                                        checked={isAdvisorCheck}
                                        color="primary"
                                    />
                                }
                                label={
                                    <Typography color="primary">Advisor</Typography>
                                }
                            />
                            {resourceLicences?.map((licence) => (
                                <FormControlLabel key={licence.resourceLicenceId}
                                    control={
                                        <Checkbox
                                            name={licence.name}
                                            value={licence.resourceLicenceId}
                                            onChange={selectLicenceCheckbox}
                                            checked={isLicenceCheck.find(l => l.resourceLicenceId === licence.resourceLicenceId) ? true : false}
                                            color="primary"
                                        />
                                    }
                                    label={
                                        <Typography color="primary">
                                            {licence.name}
                                        </Typography>
                                    }
                                />
                            ))}
                        </FormGroup>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={handleCloseLicenceDialog}
                            color="primary"
                        >
                            Cancel
                        </Button>

                        <Button
                            onClick={() => handleUpdateLicences()}
                            color="primary"
                        >
                            Confirm
                        </Button>

                    </DialogActions>
                </Dialog>
            )}

            {precedentToRemove && (
                <Dialog
                    open={confirmRemovalDialogOpen}
                    onClose={handleCloseRemovalConfirmationDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Confirm Removal of {precedentToRemove.name}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Please confirm that you wish to remove '{precedentToRemove.name}' from the precedent bank?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={handleCloseRemovalConfirmationDialog}
                            color="primary"
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={handleRemoveLetterClick}
                            color="primary"
                        >
                            Confirm Removal from Precedent Bank
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
            <Dialog
                open={newFolderDialogOpen}
                onClose={handleNewFolderDialogClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">
                    Create a document sub folder
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please enter the details of the folder you wish to
                        create
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        onChange={(event) => {
                            setNewFolderName(event.target.value);
                        }}
                        id="name"
                        label="Folder name"
                        type="text"
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleNewFolderDialogClose}
                        color="primary"
                    >
                        Cancel
                    </Button>
                    <Button onClick={handleCreateNewFolder} color="primary">
                        Create
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={newFileDialogOpen}
                onClose={handleNewFileDialogClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">
                    <Typography
                        className={classes.title}
                        color="primary"
                        variant="body1"
                    >
                        Add a static file to the precedent bank
                    </Typography>
                </DialogTitle>
                <DialogContent className={classes.content}>
                    <DialogContentText>
                        Please enter the details of file you wish to add
                    </DialogContentText>
                    <Button
                        mr={2}
                        variant="contained"
                        color="default"
                        component="label"
                        onChange={saveFile}
                        disabled={!!file && !!fileName}
                    >
                        <Typography color="default" variant="button">
                            Add file
                        </Typography>
                        <input type="file" hidden />
                        <CloudUpload className={classes.uploadIcon} />
                    </Button>
                    {fileName && (
                        <div className={classes.filenameWrapper}>
                            <Typography
                                color="default"
                                variant="subtitle1"
                                className={classes.filename}
                            >
                                {fileName}
                            </Typography>
                            <Button onClick={handleResetStaticFile}>
                                <TrashIcon className={classes.trashIcon} />
                            </Button>
                        </div>
                    )}
                    {!isValidFilenameLength && (
                        <Alert severity="error" className={classes.errorAlert}>
                            Filename should not exceed 256 characters. Please
                            rename the file before reuploading
                        </Alert>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleNewFileDialogClose}
                        color="secondary"
                        variant="text"
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={uploadFile}
                        disabled={
                            !file ||
                            isStringEmptyOrWhitespaceOnly(fileName) ||
                            !isValidFilenameLength
                        }
                        color="secondary"
                        variant="text"
                    >
                        Upload
                    </Button>
                </DialogActions>
            </Dialog>
            {precedentRec &&  <PrecedentEditor precedent={precedentRec} onSave={onSave} />}
        </React.Fragment>
    );
};

const mapStateToProps = (state) => ({ configState: state.configReducer });

const mapDispatchToProps = (dispatch) => ({
    openFileViewer: (file, caseId, attachment) => dispatch(openFileViewer(file, caseId, attachment)),
    setPrecedentDisabledFlag: (precedentId, remove) => dispatch(setPrecedentDisabledFlagAction(precedentId, remove)),
    setPrecedentFolderDisabledFlag: (precedentFolderId, remove) => dispatch(setPrecedentFolderDisabledFlagAction(precedentFolderId, remove)),
    setPrecedentfolder: (precedentId, pFolderId) => dispatch(setPrecedentfolderAction(precedentId, pFolderId)),
    setPrecedent: (saveData) => dispatch(setPrecedentAction(saveData)),
    setPrecedents: (saveData) => dispatch(setPrecedentsAction(saveData)),
    setPrecedentFolders: (saveData) => dispatch(setPrecedentFoldersAction(saveData)),
    setPrecedentIsAdvisor: (precedentId, isAdvisor) => dispatch(setPrecedentIsAdvisorAction(precedentId, isAdvisor)),
    setPrecedentResourceLicences: (precedentId, licences) => dispatch(setPrecedentResourceLicencesAction(precedentId, licences)),
    setSnack: (message, severity) => dispatch(setSnackAction(message, severity)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Precedents);
