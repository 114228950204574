import React from 'react';
import { Box, Divider, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
    divider: {
        flexGrow: 1,
    },
    caption: {
        color:  theme.palette.text.primary,
        margin: theme.spacing(0, 2),
    }
}))

const DividerWithCaption = ({ caption }) => {
    const classes = useStyles();
    return (
        <Box
            display="flex"
            alignItems="center"
        >
            <Divider className={classes.divider} />
            <Typography
                variant="caption"
                component="span"
                className={classes.caption}>
                {caption}
            </Typography>
            <Divider className={classes.divider} />
        </Box>
    );
};

export default DividerWithCaption;