import { combineReducers } from 'redux';

import themeReducer from './themeReducers';
import userReducer from './userReducer';
import caseReducer from './caseReducer';
import snackReducer from './snackReducer';
import configReducer from './configReducer';
import flexReducer from './flexReducer';
import accountReducer from './accountReducer';
import createCaseReducer from './createCaseReducer';
import createContactReducer from './createContactReducer';
import caseTimerReducer from './caseTimerReducer';
import knowledgeReducer from './knowledgeReducer';
import { connectRouter } from 'connected-react-router';

const createRootReducer = history =>
    combineReducers({
        router: connectRouter(history),
        themeReducer,
        userReducer,
        caseReducer,
        snackReducer,
        configReducer,
        flexReducer,
        accountReducer,
        createCaseReducer,
        createContactReducer,
        caseTimerReducer,
        knowledgeReducer
    });

export default createRootReducer;
