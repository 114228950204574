import React, { useCallback, useState, useEffect } from "react";
import axios from "../plugins/axios";
import styled from "styled-components";
import Moment from "react-moment";
import moment from "moment";
import { connect, useDispatch, useSelector } from "react-redux";

import {
    Typography as MuiTypography,
    Card as MuiCard,
    CardContent,
    Box,
    Menu,
    MenuItem,
    Button as MuiButton,
    IconButton as MuiIconButton,
    CircularProgress,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { grey } from "@material-ui/core/colors";
import {
    Replay as UndoIcon,
    Email as EmailIcon,
    Fullscreen,
} from "@material-ui/icons";
import {
    setEmail,
    setEmails,
} from "../redux/actions/caseActions";
import { setActiveFlexEmailTaskSid } from "../redux/actions/userActions";
import { setSnackAction } from "../redux/actions/snackActions";
import { emptyEmail } from "../constants/emptyActivities";

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

const CustomMenu = styled(Menu)`
    border-radius: 0px;
    ul {
        padding: 0px;
    }
`;

const IconButton = styled(MuiIconButton)`
    padding: 2px;
    margin-top: -2px;
    margin-left: 2px;
    min-width: 0;
    color: ${grey[700]};
`;

const Card = styled(MuiCard)(spacing);
const Typography = styled(MuiTypography)(spacing);
const Button = styled(MuiButton)(spacing);

const Email = ({ email, activeEmail = false, handleFullScreenEmail }) => (
    <Box
        display="flex"
        my={activeEmail ? 3 : 0}
        p={activeEmail ? 3 : 0}
        style={{
            backgroundColor: activeEmail ? grey[100] : "none",
            width: "100%",
        }}
    >
        <Box display="flex" flexGrow={1} style={{ width: "100%" }}>
            <Box display="flex" flexGrow={1} justifyContent="flex-start">
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    mr={3}
                >
                    <EmailIcon style={{ color: grey[700] }} fontSize="large" />
                </Box>
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    mr={!activeEmail ? 6 : 0}
                >
                    <Box display="flex">
                        <IconButton
                            onClick={() => handleFullScreenEmail(email.taskSid)}
                        >
                            <Fullscreen />
                        </IconButton>
                        <Typography variant="body1">
                            {"Subject: " + email.subject}
                        </Typography>
                    </Box>
                    <Typography variant="body2">{email.fromAddress}</Typography>
                </Box>
            </Box>
            <Box display="flex" alignItems="center">
                <Typography variant="h4">
                    {!moment(email.createdOn).isSame(moment(), "day") && (
                        <Moment format="DD/MM">{email.createdOn}</Moment>
                    )}{" "}
                    {<Moment format="HH:mm">{email.createdOn}</Moment>}
                </Typography>
            </Box>
        </Box>
    </Box>
);

const EmailCard = ({
    setEmail,
    setEmailOpen,
    setFullScreenEmail,
    addCaseEvent,
    setActiveFlexEmailTaskSid,
    incrementEventCount,
    setCaseActivityIds,
}) => {
    const { currentCaseId: caseId, cases } = useSelector(
        (state) => state.caseReducer
    );
    const { userProfile, activeFlexEmailTaskSid, acceptedEmailTaskSids } =
        useSelector((state) => state.userReducer);

    const c = cases[caseId];
    const accountId = c.account.accountId;
    const activeEmail = c.email.email || emptyEmail;
    const caseEvents = c.events || [];
    const caseActivityIds = c.caseActivityIds;
    const emails = c.emails;
    const contractId = c.caseSummary.contractId;
    const userId = userProfile.userId;

    const dispatch = useDispatch();

    const [anchorEl, setAnchorEl] = useState(null);
    const [saving, setSaving] = useState(false);

    const getEmails = useCallback(async () => {
        let response = await axios.post(
            `${apiUrl}/getemails/${accountId}/${caseId}`,
            { acceptedEmailTaskSids }
        );
        if (response.status === 200) dispatch(setEmails(response.data, caseId));
    }, [
        userId,
        accountId,
        activeFlexEmailTaskSid,
        acceptedEmailTaskSids,
        caseId,
        dispatch,
    ]);

    const handleChangeEmail = (email) => {
        setEmail(emails.filter((e) => e.taskSid === email.taskSid)[0], caseId);
        setAnchorEl(null);
    };
    

    const handleSave = async () => {
        setSaving(true);
        let response = await axios.get(
            `${apiUrl}/getemail/${activeEmail.taskSid}`
        );
        if (!response.data) {
            dispatch(
                setSnackAction(
                    "Couldn't retrieve the email. Please try again. If problem persists contact a member of IT support",
                    "error"
                )
            );
            setSaving(false);
            return;
        }
        let response2 = await axios.get(
            `${apiUrl}/assignemail/${caseId}/${activeEmail.taskSid}?contractId=${contractId}`
        );
        if (response2.data.caseActivityId) {
            window.frames[0].postMessage(
                {
                    event: "emailAssigned",
                    taskSid: activeEmail.taskSid,
                    caseId,
                },
                "*"
            );
            if (
                caseEvents.length < 5 ||
                new Date(response2.data.emailCreated) >
                    new Date(caseEvents[caseEvents.length - 1].eventTime)
            )
                addCaseEvent(caseId, {
                    caseActivityId: response2.data.caseActivityId,
                    adviserId: userId,
                    itemType: "Email",
                    eventTime: response2.data.emailCreated,
                    direction: 0,
                    person: response.data.person,
                    content: response.data.content,
                    toAddress: response.data.toAddress,
                    attachments: response.data.attachments,
                });
            incrementEventCount(caseId);
            dispatch(
                setEmails(
                    emails.map((e) =>
                        e.taskSid === activeEmail.taskSid
                            ? {
                                  ...e,
                                  caseActivityIds: [
                                      ...e.caseActivityIds,
                                      response2.data.caseActivityId,
                                  ],
                              }
                            : e
                    ),
                    caseId
                )
            );
            setCaseActivityIds(
                [...caseActivityIds, response2.data.caseActivityId],
                caseId
            );
        }
        
        setSaving(false);
        handleReset();
    };

    const handleReset = () => {
        setEmailOpen(false, caseId);
        setEmail(emptyEmail, caseId);
        setActiveFlexEmailTaskSid(null);
    };

    const handleFullScreenEmail = async (taskSid) => {
        const response = await axios.get(`${apiUrl}/getemail/${taskSid}`);

        if (!response.data) {
            dispatch(
                setSnackAction(
                    "Couldn't retrieve the email. Please try again. If problem persists contact a member of IT support",
                    "error"
                )
            );
            return;
        }

        setFullScreenEmail(response.data);
    };

    useEffect(() => {
        if (
            activeFlexEmailTaskSid &&
            emails.length &&
            !activeEmail.taskSid &&
            !activeEmail.caseActivityIds.some((id) =>
                caseActivityIds.includes(id)
            )
        )
            setEmail(
                emails.filter((e) => e.taskSid === activeFlexEmailTaskSid)[0],
                caseId
            );
    }, [activeFlexEmailTaskSid, caseId, emails, activeEmail]);

    useEffect(() => {
        getEmails();
    }, [getEmails]);

    return (
        <Card mb={6}>
            <CardContent>
                <Box display="flex">
                    <Box flexGrow={1}>
                        <Typography variant="h6" gutterBottom>
                            Email
                        </Typography>
                    </Box>
                    <Box>
                        <IconButton onClick={handleReset}>
                            <UndoIcon />
                        </IconButton>
                    </Box>
                </Box>
                {activeEmail.taskSid ? (
                    <Box display="flex" flexDirection="column">
                        <Email
                            email={activeEmail}
                            activeEmail
                            handleFullScreenEmail={handleFullScreenEmail}
                        />
                        <Box mt={3}>
                            <Typography variant="caption">
                                Not the right email?
                            </Typography>
                        </Box>
                        <Box
                            my={3}
                            display="flex"
                            justifyContent="space-between"
                        >
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={(e) =>
                                    setAnchorEl(e.currentTarget.parentNode)
                                }
                            >
                                Change Email
                            </Button>
                            {saving ? (
                                <CircularProgress />
                            ) : (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleSave}
                                >
                                    Log Email
                                </Button>
                            )}
                        </Box>
                    </Box>
                ) : (
                    <Box>
                        <Box my={3}>
                            <Typography variant="caption">
                                Looks like you haven't selected an email
                            </Typography>
                        </Box>
                        <Box my={3} display="flex" justifyContent="flex-start">
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={(e) =>
                                    setAnchorEl(e.currentTarget.parentNode)
                                }
                            >
                                Choose Email
                            </Button>
                        </Box>
                    </Box>
                )}
                <CustomMenu
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={() => setAnchorEl(null)}
                >
                    {!emails.filter(
                        (e) =>
                            e.taskSid !== activeEmail.taskSid &&
                            !e.caseActivityIds.some((id) =>
                                caseActivityIds.includes(id)
                            )
                    ).length && <MenuItem>No emails found</MenuItem>}
                    {emails
                        .filter(
                            (e) =>
                                e.taskSid !== activeEmail.taskSid &&
                                !e.caseActivityIds.some((id) =>
                                    caseActivityIds.includes(id)
                                )
                        )
                        .sort(
                            (a, b) =>
                                new Date(b.createdOn) - new Date(a.createdOn)
                        )
                        .map((e) => (
                            <MenuItem
                                key={e.taskSid}
                                onClick={() => handleChangeEmail(e)}
                            >
                                <Email
                                    email={e}
                                    handleFullScreenEmail={
                                        handleFullScreenEmail
                                    }
                                />
                            </MenuItem>
                        ))}
                </CustomMenu>
            </CardContent>
        </Card>
    );
};

const mapDispatchToProps = (dispatch) => ({
    setEmail: (email, caseId) => dispatch(setEmail(email, caseId)),
    setActiveFlexEmailTaskSid: (taskSid) =>
        dispatch(setActiveFlexEmailTaskSid(taskSid)),
});

export default connect(null, mapDispatchToProps)(EmailCard);
