import { useQuery } from "react-query";
import queryKeys from "../../constants/queryKeys";
import twilioService from "../../services/twilioService";

const useTeams = ({ dialogOpen }) =>
    useQuery(
        queryKeys.teams,
        () => twilioService.getTeams(),
        {
            enabled: !!dialogOpen,
            staleTime: Infinity
        }
    )

export default useTeams;