import {useQuery} from "react-query";
import queryKeys from "../../constants/queryKeys";
import raiseCaseRequestService from "../../services/raiseCaseRequestService";

const useRaiseCaseRequest = (raiseCaseRequestId) =>
    useQuery({
        queryKey: [queryKeys.raiseCaseRequest, raiseCaseRequestId],
        queryFn: () => raiseCaseRequestService.getRaiseCaseRequest(raiseCaseRequestId),
        enabled: !!raiseCaseRequestId,
    });

export default useRaiseCaseRequest;