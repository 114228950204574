import React from "react";
import { Box, Container, LinearProgress, makeStyles } from "@material-ui/core";
import caseNestLogo from "../images/CaseNest_Purple_Positive_RGB.svg";
import { workNestPalette } from "../theme/constants";

const useStyles = makeStyles((theme) => ({
  logoWrapper: {
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: theme.spacing(4),
    maxWidth: "300px",
  },
  logo: {
    width: "100%",
  },
  progressBar: {
    backgroundColor: workNestPalette.primary,
  },
  progressBackground: {
    backgroundColor: workNestPalette.secondary,
  },
}));

const LoginLoader = () => {
  const classes = useStyles();
  return (
    <Box height="100vh" display="flex" alignItems="center">
      <Container maxWidth="sm">
        <div className={classes.logoWrapper}>
          <img className={classes.logo} src={caseNestLogo} alt="CaseNest Logo" />
        </div>
        <LinearProgress classes={{
          barColorPrimary: classes.progressBar,
          colorPrimary: classes.progressBackground,
        }} />
      </Container>
    </Box>
  );
};

export default LoginLoader;
