import React from 'react';

import {
	Card as MuiCard,
	CardContent,
	Typography as MuiTypography,
    Box,
    Avatar
} from "@material-ui/core";
import styled from "styled-components";
import { spacing } from "@material-ui/system";

import avatarStyles from "../../theme/avatars";


const Card = styled(MuiCard)(spacing);
const Typography = styled(MuiTypography)(spacing);

const ELTeamCard = ({ elTeam }) => {
	const avatarClasses = avatarStyles();

	return (
		<Card mb={3} style={{ height: '100%' }}>
			<CardContent>
				<Typography variant="h6" mb={6}>Employment Law Team</Typography>
				<Box display="flex" justifyContent="space-around" flexWrap="wrap" style={{ height: '100%' }}>
					{elTeam.map(u =>
						<Box display="flex" flexDirection="column" alignItems="center" key={u.userId} mt={3} mb={3} ml={6} mr={6}>
							<Avatar className={avatarClasses.xlarge} src={u.photo} alt={u.name}/>
							<Typography variant="subtitle2">{u.name}</Typography>
							<Typography variant="caption">{u.position}</Typography>
						</Box>
					)}
				</Box>
			</CardContent>
		</Card>
	)
}

export default ELTeamCard;