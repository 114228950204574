import React from 'react';
import {
    Menu,
    MenuItem,
} from '@material-ui/core';
import {invoiceStatus} from "../constants/invoiceStatus";

const InvoiceSearchTableMenu = ({
    anchorEl,
    onDownloadPdf,
    onDownloadCsv,
    onDiscard,
    onClose,
    selectedInvoiceSearch,
}) => {

    return (
        <Menu
            id="invoice-search-table-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={onClose}
        >
            <MenuItem
             onClick={onDownloadPdf}>
                Download PDF
            </MenuItem>
            <MenuItem
             onClick={onDownloadCsv}>
                Download CSV
            </MenuItem>
            {selectedInvoiceSearch?.status !== invoiceStatus.Invoiced ? <MenuItem
                onClick={onDiscard}
            >
                Discard
            </MenuItem> : null}
        </Menu>
    );
}

export default InvoiceSearchTableMenu;