import React from "react";
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Typography,
    FormControl,
    Input,
    InputLabel,
    makeStyles,
    DialogTitle,
} from "@material-ui/core";
import { format } from "date-fns";
import { formatTimeHms } from "../../helpers/formatTime";

const useStyles = makeStyles(() => ({
    title: {
        fontSize: 18,
        fontWeight: 500,
    },
    inputContainer: {
        display: "flex",
        justifyContent: "space-around",
    },
    loader: {
        display: "flex",
        justifyContent: "flex-end",
        width: "90%",
    },
    actAdjustCtrl: {
        width: 70,
    },
}));

const AdjustActivityTimeDialog = ({
    adjustOpen,
    adjustHours,
    adjustMinutes,
    adjustEvent,
    adjustSaving,
    dialogStep,
    handleAdjustHours,
    handleAdjustMinutes,
    handleCloseAdjustDialog,
    handleClickConfirmAdjust,
}) => {
    const classes = useStyles();

    const adjustEventDescription =
        adjustEvent && adjustEvent.itemType.toLowerCase().includes("note")
            ? "note"
            : adjustEvent
            ? adjustEvent.itemType
            : "";

    return (
        <Dialog open={adjustOpen} onClose={handleCloseAdjustDialog}>
            <DialogTitle>
                <Typography
                    className={classes.title}
                    color="primary"
                    variant="body1"
                >
                    Adjust activity duration
                </Typography>
            </DialogTitle>

            <DialogContent>
                {dialogStep === 0 && (
                    <>
                        <DialogContentText>
                            Adjust duration for {adjustEventDescription} written{" "}
                            {adjustEvent &&
                                format(
                                    new Date(adjustEvent.eventTime),
                                    "dd/MM/yyyy"
                                )}
                        </DialogContentText>
                        <div className={classes.inputContainer}>
                            <FormControl className={classes.actAdjustCtrl}>
                                <InputLabel>Hours</InputLabel>
                                <Input
                                    type="number"
                                    value={adjustHours}
                                    onChange={(e) =>
                                        handleAdjustHours(e.target.value)
                                    }
                                    inputProps={{ min: 0 }}
                                />
                            </FormControl>
                            <FormControl className={classes.actAdjustCtrl}>
                                <InputLabel>Minutes</InputLabel>
                                <Input
                                    type="number"
                                    value={adjustMinutes}
                                    onChange={(e) =>
                                        handleAdjustMinutes(e.target.value)
                                    }
                                    inputProps={{ min: 0, max: 59 }}
                                />
                            </FormControl>
                        </div>
                    </>
                )}
                {dialogStep === 1 && (
                    <>
                        <DialogContentText>
                            Are you sure you want to adjust activity duration
                            from {formatTimeHms(adjustEvent.duration)} to{" "}
                            {adjustHours}h {adjustMinutes}m?
                        </DialogContentText>
                    </>
                )}
            </DialogContent>
            <DialogActions>
                {adjustSaving ? (
                    <div className={classes.loader}>
                        <CircularProgress />
                    </div>
                ) : (
                    <>
                        <Button
                            onClick={handleCloseAdjustDialog}
                            color="secondary"
                            variant="text"
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={handleClickConfirmAdjust}
                            color="secondary"
                            variant="text"
                        >
                            {dialogStep === 0 ? "Next" : "Confirm"}
                        </Button>
                    </>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default AdjustActivityTimeDialog;
