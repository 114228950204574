import { useQuery } from "react-query";
import queryKeys from "../../constants/queryKeys";
import axios from "../../plugins/axios";

const useCaseSummary = caseId =>
    useQuery([queryKeys.casesummary, caseId], async () => {
        let response = await axios.get(`${process.env.REACT_APP_CASENEST_API_URL}/aithread/casesummary/${caseId}`);
        return response.data;
    }, {
        staleTime: 600000,
        cacheTime: 900000
    });

export default useCaseSummary;
