import { useInfiniteQuery } from "react-query";
import queryKeys from "../../constants/queryKeys";
import accountService from "../../services/accountService";

const PAGE_SIZE = 50;

const useAccountsSlim = ({ query }) => {

    return useInfiniteQuery(
        [queryKeys.accountsSlim, query],
        async ({ pageParam = 0 }) => {
            const response = await accountService.getAccountsSlim(query, pageParam, PAGE_SIZE);
            return response;
        },
        {
            getNextPageParam: (lastPage, pages) => (lastPage.hasMore ? lastPage.nextPage : undefined),
            debounce: 500,
        }
    );

}

export default useAccountsSlim;
