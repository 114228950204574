import React, { useState, useEffect, useCallback, useMemo } from "react";
import axios from "../../plugins/axios";
import styled from "styled-components";
import { connect } from "react-redux";
import { spacing } from "@material-ui/system";
import { grey } from "@material-ui/core/colors";
import { upsertCaseTypesAction } from "../../redux/actions/configActions";

import {
    Box,
    Button as MuiIconButton,
    CardContent,
    Card as MuiCard,
    CircularProgress,
    Tooltip,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    FormControl,
    FormGroup,
    Input,
    InputAdornment,
    Menu,
    MenuItem,
    InputLabel,
    makeStyles,
} from "@material-ui/core";

import {
    Add as AddIcon,
    Delete as DeleteIcon,
    Edit as EditIcon,
    Save as SaveIcon,
    Replay as UndoIcon,
    Search as SearchIcon,
} from "@material-ui/icons";

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

const Card = styled(MuiCard)(spacing);

const IconButton = styled(MuiIconButton)`
    padding: 2px;
    margin-top: -2px;
    margin-left: 2px;
    min-width: 0;
    color: ${grey[700]};
`;

const CustomMenu = styled(Menu)`
    border-radius: 0px;
    ul {
        padding: 0px;
    }
`;

const useStyles = makeStyles((theme) => ({
    addCaseTypeButton: {
        backgroundColor: theme.palette.secondary.main,
        color: "white",
        textAlign: "center",
        "&:hover, &:focus": {
            backgroundColor: theme.palette.primary.main,
        },
    },
}));

const FormDialog = ({
    open,
    submitCaseType,
    handleFormClose,
    formCaseType,
}) => {
    const [caseType, setCaseType] = useState({});

    useEffect(() => {
        setCaseType(formCaseType || {});
    }, [formCaseType, setCaseType]);

    const handleChange = (e) => {
        setCaseType({
            ...caseType,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        submitCaseType(caseType);

        handleFormClose();
    };

    return (
        <Dialog open={open} onClose={handleFormClose} maxWidth="xs" fullWidth>
            <DialogTitle>
                {formCaseType && formCaseType.caseTypeId ? "Add" : "Edit"} Case
                Type
            </DialogTitle>
            <form onSubmit={handleSubmit}>
                <DialogContent>
                    <DialogContentText>
                        Please enter Case Type
                    </DialogContentText>
                    <FormGroup>
                        <TextField
                            name="name"
                            required
                            value={caseType.name || ""}
                            onChange={handleChange}
                            label="Case Type Name"
                        />
                        <TextField
                            name="expectedDurationDays"
                            required
                            value={caseType.expectedDurationDays || ""}
                            onChange={handleChange}
                            label="Expected Duration (days)"
                            style={{ marginTop: "8px" }}
                        />
                    </FormGroup>
                </DialogContent>
                <DialogActions>
                    <MuiIconButton onClick={handleFormClose}>
                        Cancel
                    </MuiIconButton>
                    <MuiIconButton type="submit">
                        {formCaseType && formCaseType.caseTypeId
                            ? "Done"
                            : "Submit"}
                    </MuiIconButton>
                </DialogActions>
            </form>
        </Dialog>
    );
};

const AccountCaseTypesCard = ({ configState, account, upsertCaseTypes }) => {
    const classes = useStyles();

    const [anchorCaseTypes, setAnchorCaseTypes] = useState(null);
    const [mode, setMode] = useState("view");
    const [accountCaseTypesLocal, setAccountCaseTypesLocal] = useState([]);
    const [query, setQuery] = useState("");
    const [formCaseType, setFormCaseType] = useState(null);

    const accountSpecificCaseTypes = useMemo(() => {
        return Object.values(configState.caseTypes).filter(
            (ct) => ct.isAccountSpecific
        );
    }, [configState.caseTypes]);

    const caseTypesForAccount = useMemo(() => {
        return accountSpecificCaseTypes
            .filter(
                (ct) =>
                    ct.accounts.filter((a) => a.accountId === account.accountId)
                        .length > 0
            )
            .map((c) => ({
                ...c,
                toDelete: false,
                isNew: false,
                isNewExisting: false,
                edited: false,
            }));
    }, [accountSpecificCaseTypes, account]);

    const accountSpecificCaseTypesNotAlreadyOnAccount = useMemo(() => {
        return accountSpecificCaseTypes
            .filter(
                (ct) =>
                    ct.accounts.filter(
                        (a) => a.accountId === account.accountId && !a.disabled
                    ).length === 0
            )
            .map((c) => ({
                ...c,
                toDelete: false,
                isNew: false,
                isNewExisting: false,
                edited: false,
            }));
    }, [accountSpecificCaseTypes, account.accountId]);

    const filteredAccountSpecificCaseTypes = useMemo(() => {
        if (query === "") return [];

        let matched = accountSpecificCaseTypesNotAlreadyOnAccount.filter((ct) =>
            ct.name.toLowerCase().includes(query.toLowerCase())
        );

        return matched.slice(0, 5);
    }, [accountSpecificCaseTypesNotAlreadyOnAccount, query]);

    useEffect(() => {
        setAccountCaseTypesLocal(caseTypesForAccount);
    }, [caseTypesForAccount, setAccountCaseTypesLocal]);

    const resetLocalCaseTypes = useCallback(() => {
        setAccountCaseTypesLocal(caseTypesForAccount);
        setMode("view");
    }, [caseTypesForAccount, setAccountCaseTypesLocal, setMode]);

    const toggleDelete = (id) => {
        setAccountCaseTypesLocal(
            accountCaseTypesLocal
                .filter((ct) => !(id === ct.caseTypeId && ct.isNew))
                .map((ct) =>
                    id === ct.caseTypeId
                        ? { ...ct, toDelete: !ct.toDelete }
                        : ct
                )
        );
    };

    const submitCaseType = (caseType) => {
        if (!caseType.caseTypeId) {
            setAccountCaseTypesLocal((prevCaseTypes) => [
                ...prevCaseTypes,
                {
                    ...caseType,
                    caseTypeId: prevCaseTypes.length
                        ? Math.max.apply(
                              Math,
                              prevCaseTypes.map((ct) => ct.caseTypeId)
                          ) + 1
                        : 1,
                    isNew: true,
                },
            ]);
        } else {
            setAccountCaseTypesLocal((prevCaseTypes) =>
                prevCaseTypes.map((ct) =>
                    ct.caseTypeId === caseType.caseTypeId
                        ? { ...caseType, edited: true }
                        : ct
                )
            );
        }
    };

    const handleSave = async () => {
        setMode("saving");

        let isNewExisting = accountCaseTypesLocal.filter(
            (e) => e.isNewExisting
        );
        if (isNewExisting.length) {
            let response = await axios.post(
                `${apiUrl}/addexistingcasetypes/${account.accountId}`,
                isNewExisting.map((ct) => ct.caseTypeId)
            );

            if (response.status === 200) {
                upsertCaseTypes(isNewExisting);
            }
        }

        let toAdd = accountCaseTypesLocal.filter((e) => e.isNew);
        if (toAdd.length) {
            let response = await axios.post(
                `${apiUrl}/addcasetypes/${account.accountId}`,
                toAdd
            );
            if (response.status === 200 && response.data) {
                upsertCaseTypes(response.data);
            }
        }

        let edited = accountCaseTypesLocal.filter(
            (e) => e.edited && !e.isNew && !e.toDelete
        );
        if (edited.length) {
            await axios.post(`${apiUrl}/editcasetypes`, edited);
            upsertCaseTypes(edited);
        }

        let toDelete = accountCaseTypesLocal.filter((e) => e.toDelete);
        if (toDelete.length) {
            let response = await axios.post(
                `${apiUrl}/deleteaccountcasetypes/${account.accountId}`,
                toDelete.map((e) => e.caseTypeId)
            );
            if (response.status === 200) {
                toDelete.forEach((ct) => {
                    ct.accounts = ct.accounts.filter(
                        (a) => a.accountId !== account.accountId
                    );
                });
                upsertCaseTypes(toDelete);
            }
        }

        setMode("view");
    };

    const handleAddAccountCaseType = async (target) => {
        setAnchorCaseTypes(target);
    };

    const stopPropagation = (e) => {
        switch (e.key) {
            case "ArrowDown":
            case "ArrowUp":
            case "Home":
            case "End":
                break;
            default:
                e.stopPropagation();
        }
    };

    const handleAddExistingCaseType = async (caseTypeId) => {
        if (
            accountCaseTypesLocal.filter(
                (ct) => ct.caseTypeId === caseTypeId && ct.toDelete
            ).length
        ) {
            setAccountCaseTypesLocal((prevCaseTypes) =>
                prevCaseTypes.map((ct) =>
                    ct.caseTypeId === caseTypeId
                        ? { ...ct, toDelete: false }
                        : ct
                )
            );
        } else {
            setAccountCaseTypesLocal((prevCaseTypes) => [
                ...prevCaseTypes,
                ...accountSpecificCaseTypes
                    .filter((ct) => ct.caseTypeId === caseTypeId)
                    .map((ct) => ({
                        ...ct,
                        isNewExisting: true,
                        isNew: false,
                        edited: false,
                        accounts: [...ct.accounts, account],
                    })),
            ]);
        }
        setAnchorCaseTypes(null);
    };

    const handleAddNewCaseType = () => {
        setAnchorCaseTypes(null);

        setFormCaseType({
            caseTypeId: null,
            name: "",
            expectedDurationDays: 0,
            isAccountSpecific: true,
        });
    };

    const handleCaseTypeSearchClose = () => {
        setAnchorCaseTypes(null);
        setQuery("");
    };

    const handleFormClose = () => {
        setFormCaseType(null);
    };

    const handleReset = () => {
        setMode("view");
        resetLocalCaseTypes();
    };

    const MapAccountCaseType = (ct) => (
        <Box
            key={ct.caseTypeId}
            display="flex"
            flexDirection="column"
            alignItems="left"
            mt={5}
            mb={2}
            ml={2}
            mr={2}
            style={{ textAlign: "left" }}
        >
            <Box>
                <Typography variant="subtitle2">{ct.name}</Typography>
                {mode === "edit" && (
                    <Tooltip title="Edit CaseType">
                        <IconButton
                            onClick={() => setFormCaseType(ct)}
                            disabled={ct.toDelete}
                        >
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                )}
                {mode === "edit" && !ct.toDelete && (
                    <Tooltip title="Delete CaseType">
                        <IconButton onClick={() => toggleDelete(ct.caseTypeId)}>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                )}
            </Box>
        </Box>
    );

    return (
        <Card mb={6}>
            <CardContent>
                <Box display="flex">
                    <Box flexGrow={1}>
                        <Typography variant="h6" gutterBottom>
                            Account Case Types
                        </Typography>
                    </Box>
                    <Box display="flex" alignItems="center">
                        {mode === "saving" && <CircularProgress size="1em" />}
                        {mode === "view" && (
                            <IconButton onClick={() => setMode("edit")}>
                                <EditIcon />
                            </IconButton>
                        )}
                        {mode === "edit" && (
                            <IconButton
                                onClick={(e) =>
                                    handleAddAccountCaseType(
                                        e.currentTarget.parentNode
                                    )
                                }
                            >
                                <AddIcon />
                            </IconButton>
                        )}
                        {mode === "edit" && (
                            <Box>
                                <IconButton
                                    disabled={
                                        !accountCaseTypesLocal.filter(
                                            (e) =>
                                                e.isNew ||
                                                e.isNewExisting ||
                                                e.edited ||
                                                e.toDelete
                                        ).length
                                    }
                                    onClick={handleSave}
                                >
                                    <SaveIcon />
                                </IconButton>
                            </Box>
                        )}
                        {mode === "edit" && (
                            <IconButton onClick={handleReset}>
                                <UndoIcon />
                            </IconButton>
                        )}
                    </Box>
                </Box>
                <CustomMenu
                    anchorEl={anchorCaseTypes}
                    keepMounted
                    open={Boolean(anchorCaseTypes)}
                    onClose={handleCaseTypeSearchClose}
                >
                    {accountSpecificCaseTypesNotAlreadyOnAccount.length > 0 && (
                        <div style={{ padding: "8px", margin: "8px" }}>
                            <FormControl style={{ width: "100%" }}>
                                <InputLabel>Search</InputLabel>
                                <Input
                                    autoFocus
                                    type="text"
                                    value={query}
                                    onKeyDown={stopPropagation}
                                    onChange={(e) => setQuery(e.target.value)}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <SearchIcon />
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </div>
                    )}
                    {filteredAccountSpecificCaseTypes.map((ct) => (
                        <MenuItem
                            onClick={() =>
                                handleAddExistingCaseType(ct.caseTypeId)
                            }
                            key={ct.caseTypeId}
                        >
                            <Typography variant="body2">{ct.name}</Typography>
                        </MenuItem>
                    ))}
                    <MenuItem
                        onClick={handleAddNewCaseType}
                        className={classes.addCaseTypeButton}
                    >
                        <Typography style={{ width: "100%" }} variant="body2">
                            Add Account Case Type
                        </Typography>
                    </MenuItem>
                </CustomMenu>
                <Box>
                    {accountCaseTypesLocal
                        .filter((ct) => !ct.toDelete)
                        .map((ct) => MapAccountCaseType(ct))}
                </Box>
            </CardContent>
            <FormDialog
                open={!!formCaseType}
                submitCaseType={submitCaseType}
                formCaseType={formCaseType}
                handleFormClose={handleFormClose}
                setMode={setMode}
            />
        </Card>
    );
};

const mapStateToProps = (state) => ({
    configState: state.configReducer,
    userState: state.userReducer,
});

const mapDispatchToProps = (dispatch) => {
    return {
        upsertCaseTypes: (caseTypes) =>
            dispatch(upsertCaseTypesAction(caseTypes)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AccountCaseTypesCard);
