import axios from "../plugins/axios";

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

const fetchForUser = async (userId) => {
    try {
        const response = await axios.get(`${apiUrl}/UserAutomaticEmailReply/${userId}`);
        return response.data;
    }
    catch (e) {
        console.error(e);
        return Promise.reject(e?.message || "Could not fetch automatic email reply for user");
    }
};

const updateForUser =  (reply) => 
    axios.put(`${apiUrl}/UserAutomaticEmailReply`, { ...reply });

const userAutomaticEmailReplyService = {
    fetchForUser,
    updateForUser,
};

export default userAutomaticEmailReplyService;

