import React, { useState, useMemo, useEffect } from 'react';
import { connect } from 'react-redux';
import { DatePicker } from "@material-ui/pickers";
import styled from "styled-components";
import { grey, red, green } from "@material-ui/core/colors";

import {
    Typography,
    Card as MuiCard,
    CardContent as MuiCardContent,
    Select,
    MenuItem,
    TextField,
    Box,
    Button as MuiIconButton,
    Chip,    
} from '@material-ui/core';

import Icons from "../ckeditor/MentionIcons";

const MaterialIcon = props => {

    return React.createElement(Icons[props.icon] || Icons.Edit, props);
}

const Card = styled(MuiCard)`
    border: 1px solid #1b569d;
    margin: 8px;
`;

const CardContent = styled(MuiCardContent)`
    padding: 8px !important;
`;

const IconButton = styled(MuiIconButton)`
    padding: 2px;
    margin-top: -2px;
    margin-left: 2px;
    min-width: 0;
    color: ${grey[700]};
`;

const MentionCard = ({ mention, dbMentions, setValue }) => {

    const [entry, setEntry] = useState("");

    const [options, setOptions] = useState([]);

    useEffect(() => {
        setValue(options.length === 0 ? "" : JSON.stringify(options));

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options]);

    useEffect(() => {
        if (mention.type === "list" && mention.value) {
            setOptions(JSON.parse(mention.value));
        }       
    }, [mention]);

    const addEntry = () => {
        const list = [...options];
        list.push(entry);
        setEntry("");
        setOptions(list);
    };

    const deleteEntry = index => {
        const list = [...options];
        list.splice(index, 1);
        setOptions(list);
    };

    const dbMention = useMemo(() => {
        let result = {};
        if (mention.mentionId) {
            var matched = dbMentions.filter(x => x.mentionId === mention.mentionId);
            if (matched.length > 0)
                result = matched[0];
        }

        return { ...mention, ...result };
    }, [dbMentions, mention]);      

    const mentionSelected = () => {        
        document.querySelector(`[data-key="${mention.key}"]`).scrollIntoView();
        //setActiveMention(mention);
    };

    return (
        <Card onClick={mentionSelected}>
            <CardContent className="ew-back">
                <Box display="flex">
                    <MaterialIcon fontSize="small" icon={dbMention.icon} />&nbsp;
                    <Typography>{dbMention.name}</Typography>
                </Box>
            </CardContent>
            <CardContent>
                <Box display="flex" flexDirection="column">
                    {
                        dbMention.guidance && <Box><i>{dbMention.guidance}</i></Box>
                    }
                    {
                        dbMention.type === "list" &&
                        <Box>
                            <Box>
                                {options.map((entry, index) => (
                                    <Box key={`${index}-${entry}`} display="flex">
                                        <Box flexGrow={1}>{entry}</Box>
                                        <Box>
                                            <IconButton edge="end" onClick={() => deleteEntry(index)}>
                                                <Icons.Delete />
                                            </IconButton>
                                        </Box>
                                    </Box>
                                ))}
                                <Box key="#ENTRY#" display="flex">
                                    <Box flexGrow={1}>
                                        <TextField
                                            fullWidth
                                            value={entry}
                                            onChange={e => setEntry(e.target.value)}
                                        />
                                    </Box>
                                    <Box>
                                        <IconButton edge="end" onClick={addEntry}>
                                            <Icons.Add />
                                        </IconButton>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    }
                    {
                        dbMention.type === "date" &&
                        <DatePicker
                            margin="normal"
                            format="dd/MM/yyyy"
                            value={mention.value ? new Date(mention.value) : null}
                            onChange={(val) => setValue(val.toString())}
                        />
                    }
                    {
                        dbMention.type === "option" &&
                        <Select value={mention.value || ""} onChange={e => setValue(e.target.value)} displayEmpty>
                            <MenuItem value="">{dbMention.name}</MenuItem>
                            {
                                dbMention.options.map(o => <MenuItem key={o} value={o}>{o}</MenuItem>)
                            }
                        </Select>
                    }
                    {
                        dbMention.type === "textarea" &&
                        <TextField
                            multiline
                            fullWidth
                            minRows={4}
                            value={mention.value || ""}
                            onChange={e => setValue(e.target.value)}
                            m={2}
                        />
                    }
                    {
                        dbMention.type === "text" &&
                        <TextField
                            fullWidth
                            value={mention.value || ""}
                            onChange={e => setValue(e.target.value)}
                            m={2}
                        />
                    }
                    <Box display="flex" justifyContent="flex-end" className="ew" style={{ marginTop: '8px' }}>
                        <Chip size="small" style={{ color: 'white', backgroundColor: mention.value ? green[500] : red.A700 }} label={mention.value ? "Done" : "Pending"} />
                    </Box>
                </Box>
            </CardContent>
        </Card>


    );
};

const mapStateToProps = state => ({ dbMentions: state.configReducer.mentions });
export default connect(mapStateToProps)(MentionCard);

