import {useQuery} from "react-query";
import queryKeys from "../../constants/queryKeys";
import timesheetService from "../../services/timesheetService";

const useAdviserTimesheet = ({adviserId, startDate, endDate}) => 
    useQuery(
        [queryKeys.adviserTimesheet, adviserId, startDate, endDate],
        () => timesheetService.getForAdviser({adviserId, startDate, endDate}),
        {
            enabled: !!adviserId 
                && !!startDate
                && !!endDate
        })

export default useAdviserTimesheet;

