import React, { useState } from "react";
import styled from "styled-components";
import CaseTable from "../components/CaseTable";
import { Card as MuiCard, CardHeader, CardContent } from "@material-ui/core";

import { spacing } from "@material-ui/system";

const Card = styled(MuiCard)(spacing);

const MyRecentCasesCard = ({ adviserId, adviserName }) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    return (
        <Card mb={6}>
            <CardHeader title={adviserName + "'s Recent Cases"} />
            <CardContent>
                <CaseTable
                    myRecent
                    adviserId={adviserId}
                    page={page}
                    setPage={setPage}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                />
            </CardContent>
        </Card>
    );
};

export default MyRecentCasesCard;
