import React, { useState, useEffect } from "react";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import { Person } from "@material-ui/icons";
import {
    makeStyles,
    TextField,
    CircularProgress,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import useCaseAccountEmployees from "../hooks/queries/useCaseAccountEmployees";
import useIsMobile from "../hooks/useIsMobile";

const filter = createFilterOptions();

const useStyles = makeStyles((theme) => ({
  selectIcon: {
    marginRight: theme.spacing(1),
    },
    selectLabel: {
        color: "rgba(0, 0, 0, 0.54)",
        padding: "0",
        fontSize: "11px",
        fontWeight: "400",
        lineHeight: "1",
        marginTop: theme.spacing(6),
    },
    newManagerLabel: {
        "& input": {
            color: "rgba(76, 175, 80, 1)",
        }
    }
}));

const ManagerSelect = ({
    size = "medium",
    variant = "outlined",
    employee,
    accountId,
    setEmployee,
    className,
}) => {
    const classes = useStyles();
    const isMobile = useIsMobile();
    const [value, setValue] = useState(null);
    const [excludedEmployeeIds, setExcludedEmployeesIds] = useState(null);
    const [getEmployees, setGetEmployees] = useState(null);
    const limit = null;

    const { data, isError, error, isLoading } = useCaseAccountEmployees({
        accountId,
        excludedEmployeeIds,
        getEmployees,
        limit,
    });

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    };

    const handleManagerChange = (newValue) => {
        let newEmployee = { ...employee }
        if (typeof newValue === 'string' || newValue === null) {
            newEmployee.manager = {
                "managerId": null,
                "name": newValue
            }
        } else {
            newEmployee.manager = {
                "managerId": newValue.managerId ? newValue.managerId : newValue.employeeId,
                "name": newValue.name
            }
        }
        setEmployee(newEmployee)
    };

    useEffect(() => {
        setExcludedEmployeesIds([0]);
        //If editing existing employee
        if (employee.employeeId) {
            setExcludedEmployeesIds([employee.employeeId]);
            //Change dropdown select to employee manager
            if (employee.manager) setValue(employee.manager.name);
        }
        setGetEmployees(true);
    }, [data, employee])

    if (isLoading) return (<CircularProgress />);
    if (isError)
        return (
            <Alert variant="outlined" severity="error">
                {error?.message || "Could not load Line Manager"}
            </Alert>
        );
    if (!data || data.accountEmployees.length === 0) return null
    return (
        <Autocomplete
            size={size}
            value={value}
            onChange={(event, newValue) => {
                if (typeof newValue === "string") {
                    // New manager by pressing enter
                    // Timeout to avoid instant validation of the dialog's form.
                    setTimeout(() => {
                        setValue(newValue);
                        handleManagerChange(newValue);
                    });
                } else if (newValue && newValue.inputValue) {
                    //New manager by clicking "Add"
                    setValue(newValue.inputValue);
                    handleManagerChange(newValue.inputValue);
                } else {
                    //Either empty or existing manager
                    setValue(newValue);
                    handleManagerChange(newValue);
                }
            }}
            onKeyPress={handleKeyPress}
            filterOptions={(options, params) => {
                createFilterOptions({
                    limit: 2
                });
                const filtered = filter(options, params);
                if (params.inputValue !== "") {
                    filtered.push({
                        inputValue: params.inputValue,
                        name: `Add "${params.inputValue}"`
                    });
                }
                return filtered;
            }}
            className={classes.selectLabel + " " + className}
            options={data.accountEmployees}
            getOptionLabel={(option) => {
                // e.g value selected with enter, right from the input
                if (typeof option === "string") {
                    if (option === undefined) option = '';
                    return option;
                }
                if (option.inputValue) {
                    if (option.inputValue === undefined) option.inputValue = '';
                    return option.inputValue;
                }
                if (option.name === undefined) option.name = '';
                return option.name;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            renderOption={(option) =>
                <>
                    <Person className={classes.selectIcon} /> {option.name}
                </>
            }
            freeSolo
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Line Manager"
                    variant={variant}
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                    SelectProps={{
                        native: isMobile,
                    }}
                />
            )}
        />
  );
};

export default React.memo(ManagerSelect);
