import React, { useEffect } from "react";

import { connect } from 'react-redux';
import {
	Card as MuiCard,
	CardContent,
	Divider as MuiDivider,
	Typography as MuiTypography,
	Box
} from "@material-ui/core";
import styled from "styled-components";
import { spacing} from "@material-ui/system";

import { bindActionCreators } from 'redux';
import { actionCreators } from '../../redux/reducers/accountReducer';
import thermometerImg from "../../images/thermometer.png";

const Card = styled(MuiCard)(spacing);
const Typography = styled(MuiTypography)(spacing);

const Divider = styled(MuiDivider)(spacing);

const AccountThermometerCard = ({ account }) => {

	function setStarRatings(ratingCount, redAmt, yellowAmt, greenAmt, goldAmt) {
		if (ratingCount > 0) {
			document.getElementById("redstop1").setAttribute("offset", redAmt); document.getElementById("redstop2").setAttribute("offset", redAmt);
			document.getElementById("yellowstop1").setAttribute("offset", yellowAmt); document.getElementById("yellowstop2").setAttribute("offset", yellowAmt);
			document.getElementById("greenstop1").setAttribute("offset", greenAmt); document.getElementById("greenstop2").setAttribute("offset", greenAmt);
			document.getElementById("goldstop1").setAttribute("offset", goldAmt); document.getElementById("goldstop2").setAttribute("offset", goldAmt);
		}
	}

	useEffect(() => {
		setStarRatings(
			account.accountThermometer.ratingCount,
			account.accountThermometer.redRatingCount,
			account.accountThermometer.yellowRatingCount,
			account.accountThermometer.greenRatingCount,
			account.accountThermometer.goldRatingCount);
	}, [account.accountThermometer]);

	return (
		<React.Fragment>
			<Card mb={3}>
				{account.accountThermometer.ratingCount > 0 &&
					<CardContent>
						<Box display="flex">
							<Box flexGrow={1} alignItems="center">
								<Typography variant="h6" mb={6}>Customer Feedback Thermometer</Typography>
							</Box>
							<div style={{ display: "flex", justifyContent: "flex-end" }}>
								<img src={thermometerImg} height="42px" width="42px" alt="thermostat" />
							</div>
						</Box>
						<Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
							<Typography variant="h6">{"Average : " + account.accountThermometer.averageScore}</Typography>
							<Typography variant="body1">{"(from " + account.accountThermometer.ratingCount + " votes)"}</Typography>
						</Box>
						<Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
							<div>

								<svg width="4em" height="4em" viewBox="0 0 16 19">
									<defs>
										<linearGradient id="redprogress" x1="0" y1="1" x2="0" y2="0">
											<stop id="redstop1" offset="0" stopColor="#E31B17" />
											<stop id="redstop2" offset="0" stopColor="#E2BFBE" />
										</linearGradient>
									</defs>
									<path fill="url(#redprogress)" id="redstar" d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
									<text x="1.4494117" y="21.364706" style={{ fontFamily: "sans-serif", fontSize: "3.2px", lineHeight: "1.25" }}>
										<tspan x="2.2494117" y="21.364706" dx="1.279999" dy="-3.0117679">1 Star</tspan>
									</text>
								</svg>
								<svg width="4em" height="4em" viewBox="0 0 16 19">
									<defs>
										<linearGradient id="yellowprogress" x1="0" y1="1" x2="0" y2="0">
											<stop id="yellowstop1" offset="0" stopColor="#F9F810" />
											<stop id="yellowstop2" offset="0" stopColor="#FEFEF0" />
										</linearGradient>
									</defs>
									<path fill="url(#yellowprogress)" id="yellowstar" d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
									<text x="1.4494117" y="21.364706" style={{ fontFamily: "sans-serif", fontSize: "3.2px", lineHeight: "1.25" }}>
										<tspan x="2.2494117" y="21.364706" dx="1.279999" dy="-3.0117679">2 Star</tspan>
									</text>
								</svg>
								<svg width="4em" height="4em" viewBox="0 0 16 19">
									<defs>
										<linearGradient id="greenprogress" x1="0" y1="1" x2="0" y2="0">
											<stop id="greenstop1" offset="0" stopColor="#45AB34" />
											<stop id="greenstop2" offset="0" stopColor="#F3F9F2" />
										</linearGradient>
									</defs>
									<path fill="url(#greenprogress)" id="greenstar" d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
									<text x="1.4494117" y="21.364706" style={{ fontFamily: "sans-serif", fontSize: "3.2px", lineHeight: "1.25" }}>
										<tspan x="2.2494117" y="21.364706" dx="1.279999" dy="-3.0117679">3 Star</tspan>
									</text>
								</svg>
								<svg width="4em" height="4em" viewBox="0 0 16 19">
									<defs>
										<linearGradient id="goldprogress" x1="0" y1="1" x2="0" y2="0">
											<stop id="goldstop1" offset="0" stopColor="#DEB24A" />
											<stop id="goldstop2" offset="0" stopColor="#FBF7EC" />
										</linearGradient>
									</defs>
									<path fill="url(#goldprogress)" id="goldstar" d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
									<text x="1.4494117" y="21.364706" style={{ fontFamily: "sans-serif", fontSize: "3.2px", lineHeight: "1.25" }}>
										<tspan x="2.2494117" y="21.364706" dx="1.279999" dy="-3.0117679">4 Star</tspan>
									</text>
								</svg>
							</div>
						</Box>

						<Divider my={3} />
						<Box display="flex" flexDirection="column" justifyContent="left" alignItems="left">
						<Typography variant="subtitle2">{"Last comment (" + account.accountThermometer.lastCommentScore + "-star review)"}</Typography>
							{account.accountThermometer.lastCommentText !== "" &&
								<Typography variant="h6">{"\"" + account.accountThermometer.lastCommentText + "\""}</Typography>
							}
							{account.accountThermometer.lastCommentText === "" &&
								<Typography variant="body1">{"No comment was left with rating."}</Typography>
							}
						</Box>
						<Box display="flex" justifyContent="flex-end" pl={2}>
							<Typography variant="body1">{"- " + account.accountThermometer.lastCommentRecipient + " (" + account.accountThermometer.lastCommentDate + ")"}</Typography>
						</Box>

					</CardContent>

				}
				{account.accountThermometer.ratingCount === 0 &&

					<CardContent>
						<Box display="flex">
							<Box flexGrow={1} alignItems="center">
								<Typography variant="h6" mb={6}>Customer Feedback Thermometer</Typography>
							</Box>
							<div style={{ display: "flex", justifyContent: "flex-end" }}>
								<img src={thermometerImg} height="42px" width="42px" alt="thermostat" />
							</div>
						</Box>
						<Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
							<Typography variant="body1">As yet no client feedback has been posted.</Typography>
						</Box>
					</CardContent>
				}
			</Card>
		</React.Fragment>
	)
}

const mapDispatchToProps = dispatch => {
	return {
		...bindActionCreators(actionCreators, dispatch)
	}
}

export default connect(null, mapDispatchToProps)(AccountThermometerCard);