import { useQuery } from "react-query";
import queryKeys from "../../constants/queryKeys";
import SharepointService from "../../services/sharepointService";

const useDraftFiles = ({ caseId }) =>
    useQuery(
        [queryKeys.draftFiles, caseId],
        () => SharepointService.fetchDraftFiles({ caseId }),
        {
            enabled: !!caseId,
        }
    );

export default useDraftFiles;
