import React from "react";
import {
    alpha,
    Box,
    LinearProgress,
    makeStyles,
    withStyles,
    Typography,
} from "@material-ui/core";
import { red } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
    title: {
        color: theme.palette.primary.main,
        marginBottom: theme.spacing(5),
    },
    progressBar: {
        marginBottom: theme.spacing(3),
    },
    regularLabel: {
        fontWeight: "bold",
    },
    warningLabel: {
        color: red[800],
        fontWeight: "bold",
    },
}));

const ChunkyLinearProgress = withStyles((theme) => ({
    root: {
        height: 10,
        borderRadius: 5,
    },
    colorPrimary: {
        backgroundColor: props => props.showWarningState ? alpha(red[800], 0.3) : theme.palette.secondary.light,
    },
    bar: {
        borderRadius: 5,
        backgroundColor: props => props.showWarningState ? red[800] : theme.palette.secondary.main,
    },
}))(LinearProgress);

const CappedContractProgress = ({used, total, progress, billingType, showBillingType, showWarningState}) => {

    const classes = useStyles({ showWarningState });

    return(
        <>
        <Typography variant="h6" className={classes.title}>
            Capped Contract
        </Typography>
            <ChunkyLinearProgress variant="determinate" value={progress > 100 ? 100 : progress} className={classes.progressBar} showWarningState={showWarningState} />
            <Box display="flex" justifyContent="space-between">
                <Typography variant="caption" color="primary">
                    {`${used} of ${total} ${showBillingType ? billingType.toLowerCase() + " " : ""}used`}  
                </Typography>
                <Typography variant="body1" color="secondary" className={showWarningState ? classes.warningLabel : classes.regularLabel}>
                    {`${progress}%`}
                </Typography>
            </Box>
        </>
    );
};

export default CappedContractProgress;