import { useQuery } from "react-query";
import queryKeys from "../../constants/queryKeys";
import caseExternalContactService from "../../services/caseExternalContactService";

const useCaseExternalContacts = ({ caseId, externalContactSearchText }) =>
    useQuery(
        [queryKeys.caseExternalContacts, caseId, externalContactSearchText],
        () => caseExternalContactService.getExternalContacts(caseId, externalContactSearchText),
        {
            enabled: !!caseId ||
                     !!externalContactSearchText,
        }
    )

export default useCaseExternalContacts;