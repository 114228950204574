import { useQuery } from "react-query";
import queryKeys from "../../constants/queryKeys";
import adviceTypeService from "../../services/adviceTypeService";

const useAdviceTypes = () =>
    useQuery(queryKeys.adviceTypes, () => adviceTypeService.fetchAll(), {
        staleTime: Infinity,
    });

export default useAdviceTypes;
