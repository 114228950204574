import React, {useEffect, useState} from "react";
import {
  Typography,
  makeStyles, 
  Button,
  Popover, 
  Divider,
} from "@material-ui/core";
import {Add, Check, PlayArrow, Replay, Stop, Timer} from "@material-ui/icons";
import {formatTimeDigital} from "../../helpers/formatTime";
import {useDispatch, useSelector} from "react-redux";
import {setUserTimerRunning} from "../../redux/actions/userActions";
import userTimeEventService from "../../services/userTimeEventService";
import {setSnackAction} from "../../redux/actions/snackActions";
import ConfirmResetDialog from "./ConfirmResetDialog";
import ConfirmSubmitDialog from "./ConfirmSubmitDialog";
import AddBlockDialog from "./AddBlockDialog";
import UserTimeEventService from "../../services/userTimeEventService";

const useStyles = makeStyles((theme) => ({
  popoverContent: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(3),
    minWidth: "353px",
  },
  timer: {
    width: "100%",
    display: "flex",
    justifyContent: "center"
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end"
  },
  button: {
    margin: theme.spacing(0, 1)
  },
  divider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.secondary.main,
  },
  popover: props => ({
    "& .MuiPopover-paper": {
      border: props.getTimerState() === timerState.PLAYING ? `2px solid ${theme.palette.secondary.main}` : "1px solid #A09BB7",
      marginTop: theme.spacing(1)
    }
  })
}))

const timerState = {
  PLAYING: "PLAYING"
}

const UserTimer = ({start, stop, reset, getTimerState, getTime, setTime}) => {
  const classes = useStyles({ getTimerState });
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [confirmResetOpen, setConfirmResetOpen] = useState(false);
  const [confirmSubmitOpen, setConfirmSubmitOpen] = useState(false);
  const [addBlockOpen, setAddBlockOpen] = useState(false);
  const { userProfile, isUserTimerRunning } = useSelector(state => state.userReducer);
  const { userId } = userProfile;

  const handleStart = async () => {
    try {
      await userTimeEventService.add({ userId });
      dispatch(setUserTimerRunning(true));
      start();
    }
    catch (e) {
      console.error(e);
      dispatch(setSnackAction(e?.message || "There was an error starting the timer. Please reset the timer and try again.", "error"));
    }
  }

  const handleStop = async () => {
    try {
      await userTimeEventService.complete({ userId });
      stop();
      dispatch(setUserTimerRunning(false));
    }
    catch (e) {
      console.error(e);
      dispatch(setSnackAction(e?.message || "There was an error stopping the timer. Please reset the timer and try again.", "error"));
    }
  }
  
  const handleReset = () => {
    reset();
    stop();
    setTime(0);
    dispatch(setUserTimerRunning(false));
  }

  const handleClickReset = () => {
    if (!getTime() && getTimerState() !== timerState.PLAYING) {
      setAnchorEl(null);
      return;
    }
      
    setAnchorEl(null);
    setConfirmResetOpen(true);
  }

  const handleClickConfirmReset = async () => {
    try {
      await userTimeEventService.remove({ userId });
      handleReset();
      setConfirmResetOpen(false);
    }
    catch (e) {
      console.error(e);
      dispatch(setSnackAction(e?.message || "There was an error resetting the timer. Please try again.", "error"));
    }
  }
  
  const handleClickSubmit = () => {
    setConfirmSubmitOpen(true);
    setAnchorEl(null);
  }

  const handleClickAddBlock = () => {
    setAddBlockOpen(true);
    setAnchorEl(null);
  }
  
  useEffect( () => {
    const getUnassignedStats = async () => {
      try {
        const response = await UserTimeEventService.getUnassignedStats({ userId });
        const { duration, isRunning } = response.data;
        if (duration)
          setTime(duration * 1000);
        if (isRunning) {
          dispatch(setUserTimerRunning(true));
          start();
        }
      }
      catch (e) {
        console.error(e);
        dispatch(setSnackAction(e?.message || "There was an error fetching internal time.", "error"));
      }
    }
    getUnassignedStats(); 
  }, []);
  
  useEffect(() => {
    if (!isUserTimerRunning && getTimerState() === timerState.PLAYING)
      handleStop();
  }, [isUserTimerRunning]);
  
  return (
    <React.Fragment>
      <Button 
        variant={getTimerState() === timerState.PLAYING ? "contained" : "outlined"}
        color="secondary" 
        startIcon={<Timer />}
        onClick={e => setAnchorEl(e.currentTarget)}
      >
        {(getTimerState() === timerState.PLAYING || !!getTime()) ? formatTimeDigital(getTime() / 1000) : "Internal Timer"}
      </Button>
      <Popover
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        className={classes.popover}
      >
        <div className={classes.popoverContent}>
          <Typography color="primary" variant="subtitle2">Internal Timer</Typography>
          <div className={classes.timer}>
            <Typography variant="h6" color="secondary">{formatTimeDigital(getTime() / 1000)}</Typography>
          </div>
          <Divider className={classes.divider} />
          <div className={classes.buttonContainer}>
            {getTime() === 0 && getTimerState() !== timerState.PLAYING &&
              <>
                <Button variant="outlined" color="secondary" startIcon={<Add />} className={classes.button} onClick={handleClickAddBlock}>
                  Add Block
                </Button>
                <Button variant="outlined" color="secondary" startIcon={<PlayArrow />} className={classes.button} onClick={handleStart}>
                  Start
                </Button>
              </>
            }
            {getTimerState() === timerState.PLAYING &&
              <Button variant="outlined" color="secondary" startIcon={<Stop />} className={classes.button} onClick={handleStop}>
                Stop
              </Button>
            }
            {getTime() !== 0 && getTimerState() !== timerState.PLAYING &&
              <>
                <Button variant="outlined" color="secondary" startIcon={<Replay />} className={classes.button} onClick={handleClickReset}>
                  Reset
                </Button>
                <Button variant="outlined" color="secondary" startIcon={<Check />} className={classes.button} onClick={handleClickSubmit}>
                  Submit
                </Button>
                <Button variant="outlined" color="secondary" startIcon={<PlayArrow />} className={classes.button} onClick={handleStart}>
                  Resume
                </Button>
              </>
            }
          </div>
        </div>
      </Popover>
      <ConfirmResetDialog
        open={confirmResetOpen}
        handleClose={() => setConfirmResetOpen(false)}
        handleConfirm={handleClickConfirmReset}
      />
      <ConfirmSubmitDialog 
        open={confirmSubmitOpen}
        handleClose={() => setConfirmSubmitOpen(false)}
        userId={userId}
        handleReset={handleReset}
      />
      <AddBlockDialog 
        open={addBlockOpen}
        handleClose={() => setAddBlockOpen(false)}
        userId={userId}
      />
    </React.Fragment>
  )
}

export default UserTimer;