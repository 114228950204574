import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Papa from "papaparse";
import { setSnackAction } from "../redux/actions/snackActions";
import { setCaseChargeable } from "../redux/actions/caseActions";
import caseService from "../services/caseService";
import caseActivityService from "../services/caseActivityService";
import CaseTimeComponent from "../components/CaseTimeComponent";
import SetBudgetDialog from "../components/dialogs/SetBudgetDialog";
import ConfirmRemoveBudgetDialog from "../components/dialogs/ConfirmRemoveBudgetDialog";
import ConfirmCaseNonChargeableDialog from "../components/dialogs/ConfirmCaseNonChargeableDialog";
import useCaseTime from "../hooks/queries/useCaseTime";
import reactQueryClient from "../reactQueryClient";
import queryKeys from "../constants/queryKeys";

function TimeCard() {
    const { currentCaseId: caseId, cases } = useSelector(
        (state) => state.caseReducer
    );

    const { data: caseTimeData } = useCaseTime({ caseId });
    const {
        budgetedSeconds = 0,
        budgetedMinutes = 0,
        chargeableTime = 0,
        nonChargeableTime = 0,
        totalActivityTime = 0,
        chargeableTimeUsed,
    } = caseTimeData || {};
    const c = cases[caseId];

    const isChargeable = c.caseSummary.isChargeable;
    const isUnitised = c.caseSummary.isUnitisedTime;

    const accountName = c.account.name;
    const contractId = c?.caseSummary?.contractId;

    const dispatch = useDispatch();
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [downloading, setDownloading] = useState(false);
    const [newHours, setNewHours] = useState(0);

    const [openConfirmCaseNonChargeableDialog, setOpenConfirmCaseNonChargeableDialog] = useState(false);
    const [openSetBudgetDialog, setOpenSetBudgetDialog] = useState(false);
    const [openConfirmRemoveBudgetDialog, setOpenConfirmRemoveBudgetDialog] = useState(false);

    useEffect(() => {
        const hours = Math.floor(budgetedMinutes / 60);
        setHours(hours);
        setNewHours(hours);
        setMinutes(budgetedMinutes % 60);
    }, [budgetedMinutes]);

    const handleSaveBudget = async () => {
        try {
            let budget = newHours ? newHours * 60 + minutes : minutes;
            await caseService.updateBudgetedMinutes({
                caseId,
                budgetedMinutes: budget,
            });
            reactQueryClient.invalidateQueries([queryKeys.caseTime, caseId]);
            reactQueryClient.invalidateQueries([queryKeys.contractSummary, contractId]);

            dispatch(setSnackAction("Budget saved!", "success"));
            setHours(newHours);
        } catch (e) {
            console.error(e);
        } finally {
            setOpenSetBudgetDialog(false);
        }
    };

    const handleChargeableFlagChange = async (isChargeable) => {
        try {
            let budget = isChargeable
                ? hours
                    ? hours * 60 + minutes
                    : minutes
                : 0;

            const { data: responseIsChargeable } =
                await caseService.setChargeable({
                    caseId,
                    budgetedMinutes: budget,
                    isChargeable,
                });

            dispatch(
                setCaseChargeable({
                    caseId,
                    isChargeable: responseIsChargeable,
                })
            );
            setOpenConfirmCaseNonChargeableDialog(false);
            dispatch(
                setSnackAction(
                    responseIsChargeable
                        ? "Case is now chargeable."
                        : "Case is now non-chargeable",
                    "success"
                )
            );
            reactQueryClient.invalidateQueries([queryKeys.caseTime, caseId]);
            reactQueryClient.invalidateQueries([queryKeys.contractSummary, contractId]);
            if (!responseIsChargeable) {
                setMinutes(0);
                setHours(0);
                setNewHours(0);
            }
        } catch (e) {
            console.error(e);
        }
    };

    const handleDownloadTimesheet = async () => {
        try {
            setDownloading(true);
            const {
                rows,
                totalMinutes,
                totalChargeableMinutes,
                totalUnitisedMinutes,
                totalUnits,
            } = await caseActivityService.getTimesheet();
            const filename = `Case_${caseId}_timesheet.csv`;

            const title = `Case timesheet - ${caseId} - ${c.description ? c.description + " - " : ""}${accountName} \r\n\r\n`;
            const header = "Activity Date,Activity Type,Lawyer,Activity Description,Total Unitised Minutes,Units,Total Minutes,Chargeable Y/N,Total Chargeable Minutes\r\n";
            const data = Papa.unparse(rows, { header: false });

            if (data === null)
                return;

            const totals = `\r\n,,,,${totalUnitisedMinutes},${totalUnits},${totalMinutes},,${totalChargeableMinutes}`;

            const csv = title + header + data + totals;

            var blob = new Blob([csv]);

            var a = window.document.createElement("a");
            a.href = window.URL.createObjectURL(blob, { type: "text/plain" });
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        } catch (error) {
            console.error(error);
        } finally {
            setDownloading(false);
        }
    };

    const handleRemoveBudget = async () => {
        try {
            await caseService.updateBudgetedMinutes({
                caseId,
                budgetedMinutes: 0,
            });
            reactQueryClient.invalidateQueries([queryKeys.caseTime, caseId]);
            reactQueryClient.invalidateQueries([queryKeys.contractSummary, contractId]);
            dispatch(setSnackAction("Budget has been removed", "success"));
            setHours(0);
            setMinutes(0);
            setNewHours(0);
        } catch (e) {
            console.error(e);
        } finally {
            setOpenConfirmRemoveBudgetDialog(false);
        }
    };

    return (
        <>
            <CaseTimeComponent
                isChargeable={isChargeable}
                isUnitised={isUnitised}
                hasBudgetSet={!!budgetedSeconds}
                downloading={downloading}
                totalTime={totalActivityTime}
                nonChargeableTime={nonChargeableTime}
                chargeableTime={chargeableTime}
                budgetMinutes={minutes}
                budgetHours={hours}
                budgetTime={budgetedSeconds}
                chargeableTimeUsedPercentage={chargeableTimeUsed}
                onDownloadTimesheet={handleDownloadTimesheet}
                onSetBudgetClick={setOpenSetBudgetDialog}
                onRemoveBudgetClick={setOpenConfirmRemoveBudgetDialog}
                onMakeChargeableClick={handleChargeableFlagChange}
                onMakeNonChargeableClick={setOpenConfirmCaseNonChargeableDialog}
            />
            <ConfirmCaseNonChargeableDialog
                open={openConfirmCaseNonChargeableDialog}
                onClose={setOpenConfirmCaseNonChargeableDialog}
                onMakeNonChargeable={handleChargeableFlagChange}
            />
            <ConfirmRemoveBudgetDialog
                open={openConfirmRemoveBudgetDialog}
                onClose={setOpenConfirmRemoveBudgetDialog}
                onRemove={handleRemoveBudget}
            />
            <SetBudgetDialog
                open={openSetBudgetDialog}
                hours={newHours}
                onClose={setOpenSetBudgetDialog}
                onChange={setNewHours}
                onSave={handleSaveBudget}
            />
        </>
    );
}

export default TimeCard;
