import axios from "../plugins/axios";
import { toIsoString } from "../utils/dateTimeFormatters";

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

const getForAdviser = async ({ adviserId, startDate, endDate }) => {
    const formattedStartDate = toIsoString(startDate);
    const formattedEndDate = toIsoString(endDate);

    try {
        const response = await axios.get(
            `${apiUrl}/timesheet/getforadviser/${adviserId}/${formattedStartDate}/${formattedEndDate}`
        );
        return response.data;
    } catch (e) {
        return Promise.reject(e);
    }
};

const timesheetService = {
    getForAdviser,
};

export default timesheetService;
