import React from "react";
import {
    Dialog,
    DialogContent,
    DialogContentText,
    Tooltip,
    Typography,
    Link,
    makeStyles,
} from "@material-ui/core";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import useMiscActivityType from "../../hooks/useMiscActivityType";
import caseActivityTypes from "../../constants/caseActivityTypes";

const useStyles = makeStyles((theme) => ({
    dialogText: {
        color: "black",
    },
    cursorStylePointer: {
        cursor: "pointer",
    },
    searchResultWrapper: {
        marginLeft: theme.spacing(2),
        whiteSpace: "pre-line",
    },
}));

const { NOTE, MISC, INTERNAL_NOTE, CALL_WITH_NOTE } = caseActivityTypes;

const NotePreviewDialog = ({
    open,
    caseActivity,
    handleFullScreenNoteClose,
    openDocument,
}) => {
    const classes = useStyles();
    const miscActivityType = useMiscActivityType(caseActivity?.miscActivityTypeId);

    if (!open || !caseActivity)
        return null;

    const noteText =
        (caseActivity.itemType === NOTE || caseActivity.itemType === CALL_WITH_NOTE) && !caseActivity.htmlNote
            ? `<div>
                    <div style="font-weight: bold;">${caseActivity.issue && "Issue"}</div>
                    <div>${caseActivity.issue}</div>
                    <div style="font-weight: bold;">${caseActivity.advice && "Advice"}</div>
                    <div>${caseActivity.advice}</div>
                    <div style="font-weight: bold;">${caseActivity.action && "Action"}</div>
                    <div>${caseActivity.action}</div>
                </div>`
            : caseActivity.htmlNote
                ? caseActivity.htmlNote
                : caseActivity.itemType === INTERNAL_NOTE
                    ? caseActivity.text
                    : caseActivity.itemType === MISC
                        ? `<div>
                                <div style="font-weight: bold;">${miscActivityType.description}</div>
                                <div>${caseActivity.content}</div>
                            </div>`
                        : caseActivity.content;

    return (
        <Dialog
            onClose={handleFullScreenNoteClose}
            open={open}
            fullWidth
            maxWidth={"md"}
        >
            <DialogContent>
                <DialogContentText className={classes.dialogText}>
                    {caseActivity.attachments &&
                        caseActivity.attachments.map((a) => (
                            <Typography key={a.reference} component={"span"}>
                                <AttachFileIcon fontSize="small" />
                                <Tooltip title={a.filename}>
                                    <Link
                                        className={classes.cursorStylePointer}
                                        underline="hover"
                                        onClick={() => openDocument(a)}
                                    >
                                        {a.filename}
                                    </Link>
                                </Tooltip>
                            </Typography>
                        ))}

                    <Typography
                        className={classes.searchResultWrapper}
                        component={"span"}
                        dangerouslySetInnerHTML={{
                            __html: noteText,
                        }}
                    ></Typography>
                </DialogContentText>
            </DialogContent>
        </Dialog>
    );
};

export default NotePreviewDialog;
