import {
    dateFormatStringWithOffset,
    dateFormatStringWithoutOffset,
} from "../constants/dateConstants";
import {format} from "date-fns";

// https://stackoverflow.com/questions/11887934/how-to-check-if-dst-daylight-saving-time-is-in-effect-and-if-so-the-offset
Date.prototype.stdTimezoneOffset = function () {
    const jan = new Date(this.getFullYear(), 0, 1);
    const jul = new Date(this.getFullYear(), 6, 1);
    return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
};

Date.prototype.isDstObserved = function () {
    return this.getTimezoneOffset() < this.stdTimezoneOffset();
};

const getStartAndEndDate = (date, startTime, hours, minutes) => {
    let startDate = new Date(date);
    startTime = new Date(startTime);

    const durationMs = hours * 60 * 60 * 1000 + minutes * 60 * 1000;

    startDate.setHours(startTime.getHours());
    startDate.setMinutes(startTime.getMinutes());
    startDate.setSeconds(0);
    startDate.setMilliseconds(0);

    const endTime = startDate.getTime();
    let endDate = new Date();
    endDate.setTime(endTime + durationMs);

    return { startDate, endDate };
};

const isValidHoursMinutes = (hours, minutes) =>
    !(hours < 0 || minutes < 0 || isNaN(hours) || isNaN(minutes));

const getDstCorrectedDateString = (date) => {
    const today = new Date();
    return format(date, today.isDstObserved() ? dateFormatStringWithoutOffset : dateFormatStringWithOffset);
};

export { getStartAndEndDate, isValidHoursMinutes, getDstCorrectedDateString };
