import axios from "../plugins/axios";
import { toIsoString } from "../utils/dateTimeFormatters";

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

const getTimesheetByActivity = async ({
    fromDate,
    toDate,
    accountId,
    coreServices,
    serviceTypes,
    isChargeable,
    excludeInvoiced,
}) => {
    try {
        const response = await axios.post(
            `${apiUrl}/gettimesheetbyactivity`,
            { 
                fromDate: toIsoString(fromDate),
                toDate: toIsoString(toDate),
                accountId,
                coreServices: coreServices.map(cs => cs.name),
                serviceTypes: serviceTypes.map(st => st.name),
                isChargeable,
                excludeInvoiced,
            }
        );
        return response.data;
    } catch (e) {
        console.error(e);
        return Promise.reject(
            e?.message || "Could not fetch timesheet by activity date"
        )
    }
}

const getTimesheetByAccount = async ({
    fromDate,
    toDate,
    accountId,
    coreServices,
    serviceTypes,
    isChargeable,
}) => {
    try {
        const response = await axios.post(
            `${apiUrl}/gettimesheetbyaccount`,
            {
                fromDate: toIsoString(fromDate),
                toDate: toIsoString(toDate),
                accountId,
                coreServices: coreServices.map(cs => cs.name),
                serviceTypes: serviceTypes.map(st => st.name),
                isChargeable,
            },
            {
                responseType: "arraybuffer"
            }
        );
        return response.data;
    } catch (e) {
        console.error(e);
        return Promise.reject(
            e?.message || "Could not fetch client timesheet data."
        );
    }
}

const clientTimesheetService = {
    getTimesheetByActivity,
    getTimesheetByAccount,
};

export default clientTimesheetService;
