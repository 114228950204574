import React from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    makeStyles,
    TextField,
    Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        [theme.breakpoints.up("sm")]: {
            width: "initial",
        },
    },
    title: { fontSize: 18, fontWeight: 600 },
    content: {
        minWidth: 300,
    },
    actionBtns: {
        display: "flex",
        justifyContent: "space-between",
        padding: theme.spacing(2, 6),
    },
    divider: {
        width: "100%",
        margin: theme.spacing(2, 0),
    },
}));

const SetBudgetDialog = ({ open, hours, onClose, onChange, onSave }) => {
    const classes = useStyles();
    return (
        <Dialog
            className={classes.root}
            onClose={() => onClose(false)}
            open={open}
        >
            <DialogTitle onClose={() => onClose(false)}>
                <Typography
                    variant="body1"
                    color="primary"
                    className={classes.title}
                >
                    Set Budget
                </Typography>
            </DialogTitle>
            <DialogContent className={classes.content}>
                <TextField
                    value={hours}
                    onChange={(e) => onChange(parseInt(e.target.value))}
                    fullWidth
                    variant="outlined"
                    color="secondary"
                    type="number"
                    label="Budget"
                    helperText="Hours"
                />
            </DialogContent>
            <DialogActions className={classes.actionBtns}>
                <Button
                    color="secondary"
                    variant="text"
                    onClick={() => onClose(false)}
                >
                    Cancel
                </Button>
                <Button
                    autoFocus
                    onClick={onSave}
                    color="secondary"
                    variant="text"
                    disabled={!hours}
                >
                    Set Budget
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default SetBudgetDialog;
