import React from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    makeStyles
} from "@material-ui/core";
import {red} from "@material-ui/core/colors";

const useStyles = makeStyles(() => ({
    discardButton: {
        color: red[900],
    }
}));

const DiscardInvoiceSearchDialog = ({open, onClose, onConfirm}) => {
    const classes = useStyles();
    
    return (
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
          <DialogTitle>Discard</DialogTitle>
          <DialogContent>
              <DialogContentText>The status of the selected activities will be reset, enabling the users to adjust and edit them once again.</DialogContentText>
          </DialogContent>
          <DialogActions>
              <Button color="secondary" onClick={onClose}>Cancel</Button>
              <Button className={classes.discardButton} onClick={onConfirm}>Discard</Button>
          </DialogActions>
      </Dialog> 
    );
}

export default DiscardInvoiceSearchDialog;