import React from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogContent,
    Grid,
    Typography,
} from '@material-ui/core';
import { formatShortMonthDate } from '../../utils/dateTimeFormatters';

const InvoiceSearchDialog = ({
    open,
    onClose,
    fromDate,
    toDate,
    selectedCoreServices,
    selectedServiceTypes,
    onDownloadCsvClick,
    onSetForInvoicingClick,
}) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            maxWidth="sm"
        >
            <DialogContent>
                <Grid
                    container
                    spacing={3}
                >
                    <Grid
                        item
                        xs={6}
                    >
                        <Typography variant="subtitle2">
                            Date Range:
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={6}
                    >
                        {`${formatShortMonthDate(fromDate)} - ${formatShortMonthDate(toDate)}`}
                    </Grid>
                    <Grid
                        item
                        xs={6}
                    >
                        <Typography variant="subtitle2">
                            Core Service:
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={6}
                    >
                        {selectedCoreServices.map((coreService) => coreService.name).join(", ")}
                    </Grid>
                    <Grid
                        item
                        xs={6}
                    >
                        <Typography variant="subtitle2">
                            Service Type:
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={6}
                    >
                        {selectedServiceTypes.map((serviceType) => serviceType.name).join(", ")}
                    </Grid>
                    <Grid
                        item
                        xs={12}
                    >
                        <Box
                            display="flex"
                            justifyContent="space-between"
                        >
                            <Button
                                variant="outlined"
                                color="secondary"
                                onClick={onDownloadCsvClick}
                            >
                                Download CSV
                            </Button>
                            <div>
                                <Button
                                    onClick={onClose}
                                    variant="text"
                                    color="secondary"
                                >
                                    Cancel
                                </Button>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={onSetForInvoicingClick}
                                >
                                    Set for Invoicing
                                </Button>
                            </div>
                        </Box>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default InvoiceSearchDialog;