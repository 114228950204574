import React from 'react';
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';

const InvoiceSearchConfirmationDialog = ({
    open,
    onClose,
    onConfirmation,
    isLoading,
}) => {
    return (
        <Dialog
            open={open}
            onClose={onclose}
            aria-labelledby="invoice-search-confirmation-dialog-title"
            aria-describedby="invoice-search-confirmation-dialog-description"
        >
            <DialogTitle id="invoice-search-confirmation-dialog-title">
                Set for Invoicing
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="invoice-search-confirmation-dialog-description">
                    The selected activities will be marked as 'Ready for Invoicing'.
                    Users will no longer be able to adjust or edit these activities.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="text"
                    color="secondary"
                    onClick={onClose}
                >
                    Cancel
                </Button>
                <Button
                    variant="text"
                    color="secondary"
                    onClick={onConfirmation}
                >
                    Set for Invoicing
                    { isLoading && <CircularProgress /> }
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default InvoiceSearchConfirmationDialog;