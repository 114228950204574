import { useQuery } from "react-query";
import queryKeys from "../../constants/queryKeys";
import productService from "../../services/productService";

const useProductRecommendations = (accountId, adviceTypeId, caseId) =>
    useQuery([queryKeys.productRecommendations, accountId, adviceTypeId, caseId],
        () => productService.getProductRecommendations(accountId, adviceTypeId, caseId),
        {
            enabled: !!accountId,
            staleTime: 600000,
            cacheTime: 900000
        }
    );

export default useProductRecommendations;

