export const statuses = {
    547840000: "Draft",
    547840001: "Published",
    547840002: "Review",
    547840003: "Expired"
};

export const primaryCategories = {
    547840000: "Keeping You Informed",
    547840001: "Webinar Library",
    547840002: "Compliance",
    547840003: "Pre-Employment",
    547840003: "During Employment",
    547840003: "Ending Employment"
};

