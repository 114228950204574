import { useRef, useEffect } from "react";

function useInterval({ callback, delay, isActive = true }) {
    const savedCallback = useRef(callback);
    const intervalRef = useRef(null)
    
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    const cancelInterval = () => clearInterval(intervalRef.current);
    
    const resetInterval = () => {
        cancelInterval();
        intervalRef.current = setInterval(savedCallback.current, delay);
    }
    
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        
        if (delay !== null) {
            if (isActive)
                intervalRef.current = setInterval(tick, delay);
            else
                clearInterval(intervalRef.current);
        }
        
        return () => clearInterval(intervalRef.current);
    }, [delay, isActive]);
    
    return [cancelInterval, resetInterval]
}

export default useInterval;
