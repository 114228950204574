import React from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    dialogTitle: {
        color: theme.palette.primary.main,
    },
    dialogContent: {
        minWidth: 300,
    },
}));

const ConfirmCaseNonChargeableDialog = ({
    open,
    onClose,
    onMakeNonChargeable,
}) => {
    const classes = useStyles();
    return (
        <Dialog open={open} onClose={() => onClose(false)}>
            <DialogTitle className={classes.dialogTitle}>
                Confirm that this case is now 'non-chargeable'?
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <DialogContentText>
                    Please confirm that you no longer require time associated to
                    this case to be classed as chargeable
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    color="secondary"
                    variant="text"
                    onClick={() => onClose(false)}
                >
                    Cancel
                </Button>
                <Button
                    color="secondary"
                    variant="text"
                    onClick={() => onMakeNonChargeable(false)}
                >
                    Mark as non-chargeable
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmCaseNonChargeableDialog;
