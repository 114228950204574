import React from "react";
import {useSelector} from "react-redux";
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  CircularProgress,
  Checkbox,
  makeStyles, Tooltip, Typography, Box,
} from "@material-ui/core";
import useCaseTimeEventGroupsForUserAndAccount from "../hooks/queries/useCaseTimeEventGroupsForUserAndAccount";
import {Alert} from "@material-ui/lab";
import {formatTimeHms} from "../helpers/formatTime";
import { InfoOutlined } from "@material-ui/icons";
import {format} from "date-fns";

const tableHeadings = [
  "Case",
  "Case description",
  "Duration",
  <InfoOutlined />
]

const useStyles = makeStyles(() => ({
  fullWidthCell: {
    textAlign: "center",
  },
  contactContainer: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
}));

const CaseTimeEventGroupTable = ({ selected, setSelected }) => {
  const classes = useStyles();
  
  const { currentCaseId: caseId, cases } = useSelector(state => state.caseReducer);
  const { userProfile } = useSelector(state => state.userReducer);
  const c = cases[caseId];
  const accountId = c?.account.accountId;
  const { userId } = userProfile;
  
  const { data, isLoading, isError, error } = useCaseTimeEventGroupsForUserAndAccount({ userId, accountId });
  
  const handleSelectAll = (_event) => {
    if (!data?.length) 
      return;
    if (!selected.length) {
      setSelected(data.map(x => x.id));
      return;
    }
    setSelected([]);
  }
  
  const handleSelect = (event, id) => {
    if (event.target.checked) {
      setSelected(prev => [...prev, id]);
      return;
    }
    setSelected(prev => prev.filter(x => x !== id));
  }
  
  if (!data?.length)
    return <Box display="flex" justifyContent="center"><Typography>You have no held time for this account</Typography></Box>
  
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              onChange={handleSelectAll}
              checked={data?.length > 0 && (selected.length === data?.length)}
              indeterminate={selected.length > 0 && (selected.length < data?.length)}
            />
          </TableCell>
          {tableHeadings.map((h, index) => <TableCell key={index}>
            {h}
          </TableCell>)}
        </TableRow>
      </TableHead>
      <TableBody>
        {isLoading && <TableRow>
          <TableCell colSpan={tableHeadings.length} className={classes.fullWidthCell}>
            <CircularProgress />
          </TableCell>
        </TableRow>}
        {isError && <TableRow>
          <TableCell colSpan={tableHeadings.length} className={classes.fullWidthCell}>
            <Alert variant="outlined" severity="error">
              {error?.message || "Unable to load held time"}
            </Alert>
          </TableCell>
        </TableRow>}
        {data?.length > 0 && data.map(group => <TableRow key={group.id}>
          <TableCell padding="checkbox">
            <Checkbox
              onChange={e => handleSelect(e, group.id)}
              checked={selected.includes(group.id)}
            />
          </TableCell>
          <TableCell>{group.caseId}</TableCell>
          <TableCell>{group.caseDescription}</TableCell>
          <TableCell>{formatTimeHms(group.duration)}</TableCell>
          <TableCell>
            <Tooltip arrow interactive title={<>
              <Typography>Description: {group.description}</Typography>
              <Typography>Start time: {format(new Date(group.start), "dd/MM/yyyy HH:mm")}</Typography>
            </>}>
              <InfoOutlined />
            </Tooltip>
          </TableCell>
        </TableRow>)}
      </TableBody>
    </Table>
  );
}

export default CaseTimeEventGroupTable;