import { Button, Card, CardContent, makeStyles, Table, TableBody, TableCell, TableHead, TableRow, Typography, Box, IconButton } from '@material-ui/core'
import { Delete, Edit } from '@material-ui/icons';
import { Skeleton, Alert } from "@material-ui/lab";
import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import AddClaimDialog from '../../components/dialogs/AddClaimDialog'
import useETClaims from '../../hooks/queries/useETClaims'
import useDeleteETClaim from '../../hooks/mutations/useDeleteETClaim'
import { setSnackAction } from '../../redux/actions/snackActions'

const useStyles = makeStyles((theme) => ({
    accordionDetails: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        paddingTop: theme.spacing(3)
    },
    rightAligned: {
        marginLeft: 'auto'
    },
    centerAligned: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: 'max-content'
    },
    space: {
        display: 'flex',
        columnGap: theme.spacing(4)
    },
    button: {
        width: 'max-content'
    },
    skeletonCointainer: {
        width: '100%', 
        display: 'flex', 
        flexDirection: 'column', 
        gap: theme.spacing(3)
    },
    skeletonFields: {
        display: 'flex', 
        gap: theme.spacing(3), 
        marginLeft: 'auto'
    },
    fullWidth: {
        width: '100%'
    },
    noPadding: {
        padding: '0',
    },
    cellFlex : {
        display: 'flex',
        gap: theme.spacing(2)
    },
    fullGrid: {
        borderBottom: `solid 2px ${theme.palette.secondary.main}`,
        gridColumn: '1 / span 4',
        display: 'flex',
        alignItems: 'center',
        height: '50px'
    }
}))

export default function PercentageOfSuccessClaimTypeCard({ caseId, etCaseId }) {
    const classes = useStyles()
    const { data, isLoading, isError, error } = useETClaims(etCaseId)
    const deleteETClaim = useDeleteETClaim()
    const dispatch = useDispatch()
    const configState = useSelector(state => state.configReducer);
    const [modalOpen, setModalOpen] = useState(false)
    const [claimToEdit, setClaimToEdit] = useState(undefined)
    
    const successFrom = Math.min.apply(Math, data?.map(function(claim) { return claim.successPercentage }))
    const successTo = Math.max.apply(Math, data?.map(function(claim) { return claim.successPercentage }))

    function handleEdit(e, claim) {
        e.stopPropagation()

        setClaimToEdit({
            percentage: claim.successPercentage,
            claimTypeId: claim.claimTypeId,
            isCovered: claim.isInsured,
            claimId: claim.claimId
        })

        setModalOpen(true)
    }

    function handleDelete(e, claim) {
        e.stopPropagation()

        const etClaim = {
            caseId,
            etCaseId,
            claimId: claim.claimId
        }

        deleteETClaim.mutate({ etClaim }, {
            onError: (e) =>
                dispatch(
                    setSnackAction(
                        e?.message ||
                            "There was an error removing ET claim",
                        "error"
                    )
                ),
            onSuccess: () => {
                dispatch(
                    setSnackAction("ET claim removed", "success")
                );
            },
        })       
    }

    function handleClaimDialog() {        
        setModalOpen(true)
    }

    return (
        <>
            <AddClaimDialog 
                open={modalOpen} 
                setOpen={setModalOpen} 
                claimTypes={configState.claimTypes} 
                caseId={caseId}
                etCaseId={etCaseId}
                edit={claimToEdit}
            />
            <Card>
                <CardContent>
                    <div className={classes.fullGrid}>
                        <Typography variant='h6'>
                            Prospects of Success and Claim Type
                        </Typography>
                    </div>
                    {isLoading &&
                        <div className={classes.skeletonCointainer}>
                            <div className={classes.skeletonFields}>
                                <Skeleton height={40} width={200} variant='rect' />
                                <Skeleton height={40} width={200} variant='rect' />
                            </div>
                            <div>
                                <Skeleton height={40} width={200} variant='rect' />
                            </div>
                            <div className={classes.fullWidth}>
                                <Skeleton height={40} variant='rect' />
                            </div>
                            <div className={classes.fullWidth}>
                                <Skeleton height={40} variant='rect' />
                            </div>
                        </div>
                    }
                    {isError &&
                        <Box display="flex" justifyContent="center" width="100%">
                            <Alert variant="outlined" severity="error">
                                {error?.message || "Unable to load ET case details"}
                            </Alert>
                        </Box>
                    }
                    {!isLoading &&   
                        <div className={classes.accordionDetails}>
                            <div className={classes.rightAligned}>
                                <div className={classes.centerAligned}>
                                    <Typography variant="subtitle2">Overall Prospects of Success</Typography>
                                    <div className={classes.space}>
                                        <Typography>From: {isFinite(successFrom) ? successFrom : '...'}%</Typography>
                                        <Typography>To: {isFinite(successTo) ? successTo : '...'}%</Typography>
                                    </div>
                                </div>
                            </div>
                            <Button 
                                className={classes.button} 
                                color='primary' 
                                variant='contained'
                                onClick={handleClaimDialog}
                            >
                                Add Claim Type
                            </Button>
                            <Table>
                                <TableHead>
                                    <TableCell>Claim Type</TableCell>
                                    <TableCell>Percentage</TableCell>
                                    <TableCell>Covered by Insurance</TableCell>
                                    <TableCell></TableCell>
                                </TableHead>
                                <TableBody>
                                    {data && data?.map(claim => (
                                        <TableRow key={claim.id}>
                                            <TableCell>{configState.claimTypes[claim.claimTypeId].name}</TableCell>
                                            <TableCell>{claim.successPercentage}%</TableCell>
                                            <TableCell>{claim.isInsured ? 'Yes' : 'No'}</TableCell>
                                            <TableCell className={classes.cellFlex} align='right'>
                                                <IconButton 
                                                    className={classes.noPadding} 
                                                    onClick={e => handleDelete(e, claim)}
                                                >
                                                    <Delete />
                                                </IconButton>
                                                <IconButton 
                                                    className={classes.noPadding}
                                                    onClick={e => handleEdit(e, claim)}
                                                >
                                                    <Edit />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                    {(!data || data.length === 0) && 
                                        <TableRow>
                                            <TableCell colSpan={3}>No Claim Data</TableCell>
                                        </TableRow>
                                    }
                                </TableBody>
                            </Table>
                        </div>
                    }
                </CardContent>
            </Card>
        </>
    )
}