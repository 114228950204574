import axios from "../plugins/axios";

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

const add = ({ userId, userActivityTypeId, description }) =>
    axios.post(`${apiUrl}/useractivity`, {
        userId,
        userActivityTypeId,
        description,
    });

const addBlock = ({ userId, description, userActivityTypeId, start, finish }) =>
    axios.post(`${apiUrl}/useractivity/block`, {
        userId,
        description,
        userActivityTypeId,
        start,
        finish,
    });

const userActivityService = {
    add,
    addBlock,
};

export default userActivityService;
