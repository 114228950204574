import axios from "../plugins/axios";

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

const discardUnallocatedCaseTime = ({ caseId, userId }) => {
    return axios.get(
        `${apiUrl}/discardunallocatedcasetime/${caseId}/${userId}`
    );
};

const assignUnallocatedCaseTime = ({
    caseId,
    userActivityTypeId,
    caseTimeEventIds,
    userId,
    description,
}) => {
    return axios.post(`${apiUrl}/assignunallocatedcasetime/${caseId}`, {
        userActivityTypeId,
        caseTimeEventIds,
        userId,
        description,
    });
};

const getUnallocatedCaseTime = ({ caseId, userId }) => {
    return axios.get(`${apiUrl}/getunallocatedcasetime/${caseId}/${userId}`);
};

const unallocatedCaseTimeService = {
    discardUnallocatedCaseTime,
    assignUnallocatedCaseTime,
    getUnallocatedCaseTime,
};

export default unallocatedCaseTimeService;
