import general from "../images/sectors/general.jpg";
import charity from "../images/sectors/charity.jpg";
import care from "../images/sectors/care.jpg";
import agriculture from "../images/sectors/agriculture.jpg";
import construction from "../images/sectors/construction.jpg";
import education from "../images/sectors/education.jpg";
import government from "../images/sectors/government.jpg";
import health from "../images/sectors/health.jpg";
import hospitality from "../images/sectors/hospitality.jpg";
import logistics from "../images/sectors/logistics.jpg";
import manufacture from "../images/sectors/manufacture.jpg";
import property from "../images/sectors/property.jpg";
import retail from "../images/sectors/retail.jpg";
import societies from "../images/sectors/societies.jpg";

const images = {
    general: general,
    charity: charity,
    care: care,
    agriculture: agriculture,
    construction: construction,
    education: education,
    government: government,
    health: health,
    hospitality: hospitality,
    logistics: logistics,
    manufacture: manufacture,
    property: property,
    retail: retail,
    societies: societies
};

export default images;