import React from "react";
import { alpha, Button, makeStyles } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import FilterActivityCount from "./FilterActivityCount";

const useStyles = makeStyles((theme) => ({
    btn: {
        border: `1px solid ${alpha(theme.palette.primary.main, 0.3)}`,
        borderRadius: 8,
        margin: theme.spacing(0, 3, 2, 0),
        fontWeight: 400,
    },
    selectedBtn: {
        borderRadius: 8,
        margin: theme.spacing(0, 2, 2, 0),
        background: theme.palette.secondary.light,
        fontWeight: 400,
    },
    checkIcon: {
        marginRight: theme.spacing(1),
        fontSize: 20,
    },
}));

const TuilePill = ({
    name,
    count,
    callbackValue,
    ariaLabel = null,
    isSelected = false,
    onClick,
}) => {
    const classes = useStyles();
    return (
        <Button
            variant="text"
            color="primary"
            className={isSelected ? classes.selectedBtn : classes.btn}
            onClick={() => onClick(callbackValue)}
            aria-label={ariaLabel}
        >
            {isSelected ? (
                <CheckIcon
                    className={classes.checkIcon}
                    titleAccess="check icon"
                />
            ) : null}
            {name}
            <FilterActivityCount count={count} />
        </Button>
    );
};

export default TuilePill;
