import { useMutation } from 'react-query';
import invoiceSearchService from '../../services/invoiceSearchService';
import queryKeys from "../../constants/queryKeys";
import reactQueryClient from '../../reactQueryClient';
import {useDispatch} from "react-redux";
import {setSnackAction} from "../../redux/actions/snackActions";

const useAddInvoiceSearch = () => {
    const dispatch = useDispatch();
    
    return useMutation({
        mutationFn: (invoiceSearch) => invoiceSearchService.addInvoiceSearch(invoiceSearch),
        onSettled: () => {
            reactQueryClient.invalidateQueries([queryKeys.invoiceSearchesPaged]);
        },
        onError: () => dispatch(setSnackAction("Failed to add Invoice", "error"))
    });
}
    

export default useAddInvoiceSearch;
