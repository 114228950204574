import React from "react";

import {
    Breadcrumbs,
    Typography,
    Link,
    makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    breadcrumb: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    link: {
        cursor: "pointer"
    },
}));

const BreadcrumbsNav = ({
    path,
    navigateToParentFolder,
}) => {
    const classes = useStyles();
    return (
        <Breadcrumbs
            className={classes.breadcrumb}
            aria-label="Breadcrumb"
        >
            {path.length === 0 ? (
                <Typography
                    color="textPrimary"
                    variant="h3"
                >
                    Case Files
                </Typography>
            ) : (
                <Link
                    color="textPrimary"
                    variant="h3"
                    underline="hover"
                    className={classes.link}
                    onClick={() => navigateToParentFolder(-1)}
                >
                    Case Files
                </Link>
            )}
            {path.map((folder, index) => (
                <span key={index}>
                    {index === path.length - 1 ? (
                        <Typography>{folder.title}</Typography>
                    ) : (
                        <Link
                            underline="hover"
                            className={classes.link}
                            onClick={() => navigateToParentFolder(index)}
                        >
                            {folder.title}
                        </Link>
                    )}
                </span>
            ))}
        </Breadcrumbs>
    );
};

export default BreadcrumbsNav;