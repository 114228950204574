import React, { useEffect, useState } from "react";
import {
    MenuItem,
    Card,
    CardContent,
    FormControl,
    InputLabel,
    makeStyles,
    Select,
    TextField,
    Box,
    IconButton,
    Typography,
    CircularProgress,
} from "@material-ui/core";
import axios from "../../plugins/axios";
import { viewModeStates } from "../../constants/viewModeStates";
import { Alert, Skeleton } from "@material-ui/lab";
import {
    Edit as EditIcon,
    Save as SaveIcon,
    Replay as UndoIcon,
} from "@material-ui/icons";

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

const useStyles = makeStyles((theme) => ({
    accordionDetails: {
        width: "100%",
        display: "grid",
        gridTemplateColumns: "repeat(4, 1fr)",
        gap: theme.spacing(3),
    },
    textField: {
        width: "100%",
        marginRight: theme.spacing(3),
    },
    colSpan: {
        gridColumn: "1 / span 4",
    },
    multilineTypography: {
        whiteSpace: "pre-line",
    },
    rightHalf: {
        display: "flex",
        justifyContent: "end",
        width: "100%",
    },
    leftHalf: {
        minWidth: "max-content",
    },
    fullGrid: {
        borderBottom: `solid 2px ${theme.palette.secondary.main}`,
        gridColumn: "1 / span 4",
        display: "flex",
        alignItems: "center",
    },
}));

export default function InsuranceDetailsCard({
    onSave,
    data = {},
    isLoading,
    isError,
    error,
}) {
    const classes = useStyles();
    const [insurers, setInsurers] = useState([]);
    const [insurersForDropdown, setInsurersForDropdown] = useState([
        { insurerId: 0, name: "Please select..." },
    ]);
    const [viewMode, setViewMode] = useState(viewModeStates.VIEWING);
    const [insuranceReference, setInsuranceReference] = useState();
    const [hoursAuthorised, setHoursAuthorised] = useState();
    const [insurerId, setInsurerId] = useState();
    const [isCaseCovered, setIsCaseCovered] = useState();
    const [notes, setNotes] = useState();

    useEffect(() => {
        setInsuranceReference(data?.insuranceReference || "");
        setHoursAuthorised(data?.hoursAuthorised || 0);
        setInsurerId(data?.insurerId || 0);
        setIsCaseCovered(
            (data?.isCaseCovered
                ? 1
                : data?.isCaseCovered === null
                ? 0
                : data?.isCaseCovered === false
                ? 2
                : 0) || 0
        );
        setNotes(data?.notes || "");
    }, [data]);

    const insurersObj = insurers?.reduce(
        (prev, curr) => ({ ...prev, [curr.insurerId]: curr }),
        {}
    );

    useEffect(() => {
        async function getInsurers() {
            let response = await axios.get(`${apiUrl}/getinsurers`);
            if (response.status === 200) {
                setInsurers(response.data);
                setInsurersForDropdown(
                    insurersForDropdown.concat(response.data)
                );
            }
        }

        getInsurers();
    }, []);

    function handleSave() {
        const changes = {
            hoursAuthorised: hoursAuthorised
                ? hoursAuthorised
                : data?.hoursAuthorised ?? null,
            insuranceReference: insuranceReference
                ? insuranceReference
                : data?.insuranceReference ?? "",
            isCaseCovered: isCaseCovered
                ? isCaseCovered === 1
                    ? true
                    : isCaseCovered === 2
                    ? false
                    : null
                : data?.isCaseCovered ?? null,
            insurerId: insurerId ? insurerId : data?.insurerId ?? null,
            notes: notes ? notes : data?.notes ?? "",
        };

        onSave(changes);
        setViewMode(viewModeStates.VIEWING);
    }

    const rightComponent = (
        <>
            {viewMode === viewModeStates.VIEWING && (
                <IconButton
                    onClick={(e) => {
                        e.stopPropagation();
                        setViewMode(viewModeStates.EDITING);
                    }}
                >
                    <EditIcon />
                </IconButton>
            )}
            {viewMode === viewModeStates.EDITING && (
                <>
                    <IconButton
                        onClick={(e) => {
                            e.stopPropagation();
                            setViewMode(viewModeStates.SAVING);
                            handleSave();
                        }}
                    >
                        <SaveIcon />
                    </IconButton>
                    <IconButton
                        onClick={(e) => {
                            e.stopPropagation();
                            setViewMode(viewModeStates.VIEWING);
                        }}
                    >
                        <UndoIcon />
                    </IconButton>
                </>
            )}
            {viewMode === viewModeStates.SAVING && (
                <CircularProgress size={20} />
            )}
        </>
    );

    return (
        <Card>
            <CardContent>
                <div className={classes.accordionDetails}>
                    <div className={classes.fullGrid}>
                        <div className={classes.leftHalf}>
                            <Typography variant="h6">
                                Insurance Details
                            </Typography>
                        </div>
                        <div className={classes.rightHalf}>
                            {rightComponent}
                        </div>
                    </div>
                    {(isLoading || viewMode === viewModeStates.SAVING) && (
                        <>
                            <Skeleton height={40} variant="rect" />
                            <Skeleton height={40} variant="rect" />
                            <Skeleton height={40} variant="rect" />
                            <Skeleton height={40} variant="rect" />
                            <Skeleton
                                height={80}
                                variant="rect"
                                className={classes.colSpan}
                            />
                        </>
                    )}
                    {isError && (
                        <Box
                            display="flex"
                            justifyContent="center"
                            width="100%"
                        >
                            <Alert variant="outlined" severity="error">
                                {error?.message ||
                                    "Unable to load ET case details"}
                            </Alert>
                        </Box>
                    )}
                    <>
                        {viewMode === viewModeStates.VIEWING && (
                            <>
                                <div>
                                    {insurers?.length ===
                                        Object.keys(insurersObj).length && (
                                        <>
                                            <Typography variant="subtitle2">
                                                Insurer
                                            </Typography>
                                            <Typography>
                                                {insurersObj[data?.insurerId]
                                                    ?.name ?? "-"}
                                            </Typography>
                                        </>
                                    )}
                                </div>
                                <div>
                                    <Typography variant="subtitle2">
                                        Case Covered
                                    </Typography>
                                    <Typography>
                                        {data.isCaseCovered
                                            ? "Yes"
                                            : data.isCaseCovered === false
                                            ? "No"
                                            : "-"}
                                    </Typography>
                                </div>
                                <div>
                                    <Typography variant="subtitle2">
                                        Insurance Reference
                                    </Typography>
                                    <Typography>
                                        {data.insuranceReference
                                            ? data.insuranceReference
                                            : "-"}
                                    </Typography>
                                </div>
                                <div>
                                    <Typography variant="subtitle2">
                                        Hours Authorised
                                    </Typography>
                                    <Typography>
                                        {data.hoursAuthorised
                                            ? data.hoursAuthorised
                                            : "0"}
                                    </Typography>
                                </div>
                                <div className={classes.colSpan}>
                                    <Typography variant="subtitle2">
                                        Notes
                                    </Typography>
                                    <Typography
                                        className={classes.multilineTypography}
                                    >
                                        {data.notes ? data.notes : "-"}
                                    </Typography>
                                </div>
                            </>
                        )}
                        {viewMode === viewModeStates.EDITING && (
                            <>
                                <FormControl variant="outlined">
                                    <InputLabel>Insurer</InputLabel>
                                    <Select
                                        MenuProps={{
                                            getContentAnchorEl: null,
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "left",
                                            },
                                        }}
                                        onChange={(e) =>
                                            setInsurerId(e.target.value)
                                        }
                                        label="Insurer"
                                        labelWidth="Insurer"
                                        value={
                                            insurerId
                                                ? insurerId
                                                : data.insurerId
                                                ? data.insurerId
                                                : 0
                                        }
                                    >
                                        {insurersForDropdown &&
                                            insurersForDropdown.map(
                                                (insurer) => (
                                                    <MenuItem
                                                        key={insurer.insurerId}
                                                        value={
                                                            insurer.insurerId
                                                        }
                                                    >
                                                        {insurer.name}
                                                    </MenuItem>
                                                )
                                            )}
                                    </Select>
                                </FormControl>
                                <FormControl variant="outlined">
                                    <InputLabel>Case Covered</InputLabel>
                                    <Select
                                        MenuProps={{
                                            getContentAnchorEl: null,
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "left",
                                            },
                                        }}
                                        onChange={(e) =>
                                            setIsCaseCovered(e.target.value)
                                        }
                                        value={
                                            isCaseCovered
                                                ? isCaseCovered
                                                : data.isCaseCovered
                                                ? 1
                                                : data.isCaseCovered === null
                                                ? 0
                                                : data?.isCaseCovered === false
                                                ? 2
                                                : 0
                                        }
                                        label="Case Covered"
                                        labelWidth="Case Covered"
                                    >
                                        <MenuItem value={0}>
                                            Please select..
                                        </MenuItem>
                                        <MenuItem value={1}>Yes</MenuItem>
                                        <MenuItem value={2}>No</MenuItem>
                                    </Select>
                                </FormControl>
                                <TextField
                                    className={classes.textField}
                                    label="Insurance Reference"
                                    placeholder="Insurance Reference"
                                    variant="outlined"
                                    onChange={(e) =>
                                        setInsuranceReference(e.target.value)
                                    }
                                    type="text"
                                    value={
                                        insuranceReference
                                            ? insuranceReference
                                            : ""
                                    }
                                />
                                <TextField
                                    className={classes.textField}
                                    label="Hours Authorised"
                                    placeholder="Hours Authorised"
                                    variant="outlined"
                                    onChange={(e) =>
                                        setHoursAuthorised(e.target.value)
                                    }
                                    type="number"
                                    inputProps={{
                                        step: "0.01",
                                    }}
                                    value={
                                        hoursAuthorised ? hoursAuthorised : "0"
                                    }
                                />
                                <TextField
                                    multiline
                                    className={classes.colSpan}
                                    label="Notes"
                                    placeholder="Notes"
                                    variant="outlined"
                                    onChange={(e) => setNotes(e.target.value)}
                                    type="text"
                                    value={notes ? notes : ""}
                                />
                            </>
                        )}
                    </>
                </div>
            </CardContent>
        </Card>
    );
}
