import { useQuery } from "react-query";
import queryKeys from "../../constants/queryKeys";
import caseActivityService from "../../services/caseActivityService";

const useEmailByCaseActivityId = ({ caseActivityId, onSuccess }) =>
    useQuery(
        [queryKeys.emailPreview, caseActivityId],
        () => caseActivityService.getEmailByCaseActivityId(caseActivityId),
        {
            enabled: !!caseActivityId,
            onSuccess,
        }
    );

export default useEmailByCaseActivityId;
