import {useMutation} from "react-query";
import voicemailService from "../../services/voicemailService";
import queryKeys from "../../constants/queryKeys";
import reactQueryClient from "../../reactQueryClient";

const useUpdateVoicemailForUser = () =>
    useMutation(( { formData, userId } ) => voicemailService.updateForUser({ formData, userId }), {
        onSuccess: (_data, { userId }) => {
            const queryKey = [queryKeys.voicemail, userId];
            reactQueryClient.invalidateQueries(queryKey);
        }
    });

export default useUpdateVoicemailForUser;