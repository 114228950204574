import React, { useMemo, useState } from "react";
import {
    Paper,
    Typography,
    alpha,
    makeStyles,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    IconButton,
    Collapse,
    Button,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import useCaseTimeEventGroupsForUser from "../hooks/queries/useCaseTimeEventGroupsForUser";
import { Redirect, useHistory } from "react-router-dom";
import { formatTimeDigital } from "../helpers/formatTime";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { format } from "date-fns";
import { grey } from "@material-ui/core/colors";
import { actionCreators } from "../redux/reducers/accountReducer";
import HeldTimeDeleteDialog from "../components/HeldTimeDeleteDialog";

const useStyles = makeStyles((theme) => ({
    timeStamp: {
        backgroundColor: alpha(theme.palette.secondary.main, 0.1),
        padding: theme.spacing(1),
        borderRadius: "4px",
        marginLeft: theme.spacing(2),
    },
    header: {
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(4),
    },
    subTableLabel: {
        width: "140px",
        paddingLeft: 0,
    },
    subTableCell: {
        paddingTop: 0,
        paddingBottom: 0,
    },
    noBorder: {
        borderBottom: "none",
    },
    fill: {
        backgroundColor: alpha(theme.palette.secondary.main, 0.1),
    },
    paper: {
        marginTop: theme.spacing(4),
        border: `1px solid ${alpha(grey[500], 0.5)}`,
    },
    caseIdCell: {
        width: "10%",
    },
    caseDescriptionCell: {
        width: "60%",
    },
    durationCell: {
        width: "20%",
    },
    expandButtonCell: {
        width: "10%",
    },
}));

const Row = ({ group, accountId, onDelete }) => {
    const [open, setOpen] = useState(false);
    const classes = useStyles();

    return (
        <>
            <TableRow className={open ? classes.fill : undefined}>
                <TableCell className={classes.noBorder}>
                    {group.caseId}
                </TableCell>
                <TableCell className={classes.noBorder}>
                    {group.caseDescription || "-"}
                </TableCell>
                <TableCell className={classes.noBorder}>
                    {formatTimeDigital(group.duration)}
                </TableCell>
                <TableCell className={classes.noBorder}>
                    <IconButton onClick={() => setOpen((prev) => !prev)}>
                        {open ? (
                            <KeyboardArrowUpIcon />
                        ) : (
                            <KeyboardArrowDownIcon />
                        )}
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow className={open ? classes.fill : undefined}>
                <TableCell colSpan={6} className={classes.subTableCell}>
                    <Collapse in={open}>
                        <Table size="small">
                            <TableBody>
                                <TableRow>
                                    <TableCell
                                        className={`${classes.subTableLabel} ${classes.noBorder}`}
                                    >
                                        <Typography variant="subtitle2">
                                            Start Date/Time:
                                        </Typography>
                                    </TableCell>
                                    <TableCell className={classes.noBorder}>
                                        {format(
                                            new Date(group.start),
                                            "dd/MM/yyyy hh:mm"
                                        )}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell
                                        className={`${classes.subTableLabel} ${classes.noBorder}`}
                                    >
                                        <Typography variant="subtitle2">
                                            Description:
                                        </Typography>
                                    </TableCell>
                                    <TableCell className={classes.noBorder}>
                                        <Typography>
                                            {group.description}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={2} align='right'>
                                        <Button variant='text' color='primary' onClick={() => onDelete({group, accountId})}>
                                            Delete
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

const HeldTime = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const { userProfile } = useSelector((state) => state.userReducer);
    const userId = userProfile.userId;
    const heldTime = useCaseTimeEventGroupsForUser({ userId });
    const [heldTimeToDelete, setHeldTimeToDelete] = useState(null);
    const [heldTimeToDeleteOpen, setHeldTimeToDeleteOpen] = useState(false);

    const totalDuration = useMemo(() => {
        if (!heldTime?.data?.length) return 0;

        return heldTime.data.reduce((sum, x) => sum + x.totalDuration, 0);
    }, [heldTime.data]);

    const handleClickAccount = async (accountId) =>
        await dispatch(actionCreators.getAccount(accountId, history));

    const onDeleteHeldTimeClick = (context) => {
        setHeldTimeToDelete(context);
        setHeldTimeToDeleteOpen(true);
    }

    const hideHeldTimeDeleteDialog = () => setHeldTimeToDeleteOpen(false);

    if (heldTime.isSuccess && !heldTime?.data?.length)
        return <Redirect to="/" />;

    return (
        <>
            <div className={classes.header}>
                <Typography variant="h3">Held Time</Typography>
                <div className={classes.timeStamp}>
                    <Typography variant="body1">
                        {formatTimeDigital(totalDuration)}
                    </Typography>
                </div>
            </div>
            <HeldTimeDeleteDialog 
                heldTime={heldTimeToDelete}
                open={heldTimeToDeleteOpen}
                onCancel={hideHeldTimeDeleteDialog}
                onClose={hideHeldTimeDeleteDialog}
                onDeleted={hideHeldTimeDeleteDialog}/>
            {heldTime.data?.map((account) => (
                <Paper key={account.accountId} className={classes.paper}>
                    <div className={classes.header}>
                        <Button
                            variant="text"
                            size="large"
                            onClick={() =>
                                handleClickAccount(account.accountId)
                            }
                        >
                            {account.accountName}
                        </Button>
                        <div className={classes.timeStamp}>
                            <Typography variant="body1">
                                {formatTimeDigital(account.totalDuration)}
                            </Typography>
                        </div>
                    </div>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.caseIdCell}>
                                    Case
                                </TableCell>
                                <TableCell
                                    className={classes.caseDescriptionCell}
                                >
                                    Case Description
                                </TableCell>
                                <TableCell className={classes.durationCell}>
                                    Duration
                                </TableCell>
                                <TableCell
                                    className={classes.expandButtonCell}
                                ></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {account.groups.map((group, index) => (
                                <Row key={index} group={group} accountId={account.accountId} onDelete={onDeleteHeldTimeClick} />
                            ))}
                        </TableBody>
                    </Table>
                </Paper>
            ))}
        </>
    );
};

export default HeldTime;
