import { useAuth } from "../contexts/authContext";
import { useSelector } from "react-redux";
import { useMemo } from "react";
import { sortAlphabetically } from "../helpers/nameHelpers";
import useTeams from "./queries/useTeams";

const sortKey = "name";

const useFilteredTeams = ({ dialogOpen }) => {
    const {
        user: { details: userDetails },
    } = useAuth();
    const userState = useSelector((state) => state.userReducer);
    const { data: teams, ...rest } = useTeams({ dialogOpen });

    const filteredTeams = useMemo(() => {
        return teams
            ? sortAlphabetically(
                  teams.filter((team) => {
                      const teamAdvisers = team.userIds.map(
                          (userId) => userState.users[userId]
                      );
                      return teamAdvisers.some((user) =>
                          userDetails.adviceTypes?.some((x) =>
                              user.adviceTypes?.includes(x)
                          )
                      );
                  }),
                  sortKey
              )
            : [];
    }, [userState.users, userDetails.adviceTypes, teams]);

    return { filteredTeams, ...rest };
};

export default useFilteredTeams;
