import React, { useCallback, useMemo, useState } from "react";
import {
    Avatar,
    Box,
    Checkbox,
    CircularProgress,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    Typography,
} from "@material-ui/core";
import { tableSortingOrder } from "../constants/tableConstants";
import { getComparator, stableSort } from "../utils/tableUtils";
import { format } from "date-fns";
import { useSelector } from "react-redux";

const tableHeadCells = [
    {
        id: "name",
        label: "Name",
        isSortable: true,
    },
    {
        id: "dateAdded",
        label: "Date Added",
        isSortable: true,
    },
    {
        id: "createdBy",
        label: "Created By",
    },
    {
        id: "fileType",
        label: "File Type",
    },
];

const useStyles = makeStyles((theme) => ({
    profilePhoto: {
        width: theme.spacing(7),
        height: theme.spacing(7),
        marginRight: theme.spacing(2),
    },
    table: {
        marginBottom: "-1px",
    },
}));

const CaseFilesTable = ({
    data,
    isLoading,
    isError,
    error,
    attachmentsSelected,
    handleAttachmentChecked,
    handleCheckAll,
}) => {
    const classes = useStyles();
    const [order, setOrder] = useState(tableSortingOrder.ASC);
    const [orderBy, setOrderBy] = useState("dateAdded");
    const { users: internalUsers } = useSelector((state) => state.userReducer);

    const onSortClick = (property) => {
        const isAsc = orderBy === property && order === tableSortingOrder.ASC;
        setOrder(isAsc ? tableSortingOrder.DESC : tableSortingOrder.ASC);
        setOrderBy(property);
    };

    const getInternalUserPhoto = (userId) => {
        const user = internalUsers[userId];
        return user?.photo;
    };

    const allChecked = useMemo(() => {
        if (!attachmentsSelected.length) return false;
        return data?.length === attachmentsSelected.length;
    }, [data, attachmentsSelected]);

    const checked = useCallback(
        (file) => {
            let id, propertyFieldName;
            if (file.azureFileId) {
                id = file.azureFileId;
                propertyFieldName = "azureFileId";
            } else {
                id = file.id;
                propertyFieldName = "id";
            }
            return attachmentsSelected
                .map((x) => x[propertyFieldName])
                .includes(id);
        },
        [attachmentsSelected]
    );

    const tableContent = () => {
        if (isLoading)
            return (
                <TableRow>
                    <TableCell colSpan={5}>
                        <Box display="flex" justifyContent="center">
                            <CircularProgress size={24} />
                        </Box>
                    </TableCell>
                </TableRow>
            );

        if (isError)
            return (
                <TableRow>
                    <TableCell colSpan={5}>
                        <Box display="flex" justifyContent="center">
                            <Typography>
                                {error?.message ||
                                    "Could not fetch document files for case."}
                            </Typography>
                        </Box>
                    </TableCell>
                </TableRow>
            );

        if (data && data.length > 0)
            return stableSort(data, getComparator(order, orderBy)).map(
                (file) => (
                    <TableRow key={file.azureFileId || file.id}>
                        <TableCell>
                            <Checkbox
                                onChange={(event) =>
                                    handleAttachmentChecked(
                                        file,
                                        event.target.checked
                                    )
                                }
                                checked={checked(file)}
                            />
                        </TableCell>
                        <TableCell>
                            {file?.customName
                                ? file.customName + "." + file.extension
                                : file.name}
                        </TableCell>
                        <TableCell>
                            {format(
                                new Date(file.dateAdded),
                                "dd/MM/yyyy HH:mm"
                            )}
                        </TableCell>
                        <TableCell>
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="flex-start"
                            >
                                <Avatar
                                    className={classes.profilePhoto}
                                    alt={file.createdBy.name}
                                    src={
                                        file.createdBy.isExternal
                                            ? null
                                            : getInternalUserPhoto(
                                                  file.createdBy.id
                                              )
                                    }
                                />
                                <Typography variant="body2">
                                    {file.createdBy.name}
                                </Typography>
                            </Box>
                        </TableCell>
                        <TableCell>{file.extension || ".docx"}</TableCell>
                    </TableRow>
                )
            );

        return (
            <TableRow>
                <TableCell colSpan={5}>
                    <Box display="flex" justifyContent="center">
                        <Typography>No associated documents found.</Typography>
                    </Box>
                </TableCell>
            </TableRow>
        );
    };

    return (
        <Table className={classes.table}>
            <TableHead>
                <TableRow>
                    <TableCell>
                        <Checkbox
                            onChange={(e) => handleCheckAll(e.target.checked)}
                            checked={allChecked}
                        />
                    </TableCell>
                    {tableHeadCells.map((headCell) => (
                        <TableCell
                            key={headCell.id}
                            sortDirection={
                                headCell.isSortable && orderBy === headCell.id
                                    ? order
                                    : false
                            }
                        >
                            {headCell.isSortable ? (
                                <TableSortLabel
                                    active={orderBy === headCell.id}
                                    direction={
                                        orderBy === headCell.id
                                            ? order
                                            : tableSortingOrder.ASC
                                    }
                                    onClick={() => onSortClick(headCell.id)}
                                >
                                    {headCell.label}
                                </TableSortLabel>
                            ) : (
                                headCell.label
                            )}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>{tableContent()}</TableBody>
        </Table>
    );
};

export default CaseFilesTable;
