import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import useAdviceTypes from "../hooks/queries/useAdviceTypes";
import {
    Checkbox,
    CircularProgress,
    FormControlLabel,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
} from "@material-ui/core";
import useAdviceTypesForUser from "../hooks/queries/useAdviceTypesForUser";
import useUpdateUserAdviceType from "../hooks/mutations/useUpdateUserAdviceType";
import { useDispatch } from "react-redux";
import { setSnackAction } from "../redux/actions/snackActions";
import { setUserAdviceTypes } from "../redux/actions/userActions";

const AdviserAdviceTypes = ({ userId }) => {
    const dispatch = useDispatch();
    const adviceTypes = useAdviceTypes();
    const userAdviceTypes = useAdviceTypesForUser(userId);
    const updateUserAdviceType = useUpdateUserAdviceType();


    const { userProfile } = useSelector((state) => state.userReducer);    
    const { data: loggedInUserAdviceTypes } = useAdviceTypesForUser(userProfile.userId);

    const availableAdviceTypes = useMemo(() => {
        if (!adviceTypes?.data || !loggedInUserAdviceTypes)
            return [];

        let sraId = adviceTypes.data.find(x => x.name === "SRA")?.id;

        let data = adviceTypes.data;

        if (!loggedInUserAdviceTypes.includes(sraId))
            data = data.filter(x => x.id !== sraId);
            
        return data;
    }, [adviceTypes, loggedInUserAdviceTypes]);


    const handleToggleAdviceType = (adviceTypeId, isEnabled) => {
        updateUserAdviceType.mutate(
            { userId, adviceTypeId, isEnabled },
            {
                onSuccess: (data) => dispatch(setUserAdviceTypes({ userId, adviceTypes: data })),
                onError: (e) => dispatch(setSnackAction(e?.message || "There was an error updating advice type for user.", "error")),
            }
        );
    };

    const adviceTypeIsChecked = (adviceTypeId) => userAdviceTypes.data?.includes(adviceTypeId) ?? false;

    return (
        <>
            <Typography variant="h6" mb={6}>Advice Types</Typography>
            {
                adviceTypes.isLoading
                    ? <CircularProgress size={24} />
                    : adviceTypes.isError || userAdviceTypes.isError
                        ? <p>Could not load advice types for user.</p>
                        : <List>
                            {
                                availableAdviceTypes.map((adviceType) => (
                                    <ListItem key={adviceType.id} button>
                                        <ListItemIcon>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        onChange={(e) => handleToggleAdviceType(adviceType.id, e.target.checked)}
                                                        checked={adviceTypeIsChecked(adviceType.id)}
                                                    />
                                                }
                                                labelPlacement="start"
                                                color="primary"
                                            />
                                        </ListItemIcon>
                                        <ListItemText primary={adviceType.name} />
                                    </ListItem>
                                ))}
                        </List>
            }
        </>
    );
};

export default AdviserAdviceTypes;
