import axios from "../plugins/axios";

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

const getContractSummary = async ({
    contractId,
}) => {
    try {
        const response = await axios.get(
            `${apiUrl}/contract/summary/${contractId}`
        );
        return response.data;
    } catch (e) {
        console.error(e);
        return Promise.reject(
            e?.message || "Could not get Contract summary."
        );
    }
};

const getCoreServices = async () => {
    try {
        const response = await axios.get(
            `${apiUrl}/contract/core-services`
        );
        return response.data;
    } catch(e) {
        console.error(e);
        return Promise.reject(
            e?.message || "Could not get core services."
        );
    }
}

const getServiceTypes = async (coreServices) => {
    try{
        const params = new URLSearchParams();
        coreServices.forEach((coreService, index) => {
            params.append(`coreServices[${index}].Name`, coreService.name);
        });

        const queryString = params.toString();

        const response = await axios.get(
            `${apiUrl}/contract/core-services/service-types?${queryString}`,
            coreServices
        );
        return response.data;
    } catch(e) {
        console.error(e);
        return Promise.reject(
            e?.message || "Could not get service types"
        )
    }
}

const contractService = {
    getContractSummary,
    getCoreServices,
    getServiceTypes,
};

export default contractService;
