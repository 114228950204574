const caseChargeableStatus = {
    CHARGEABLE: {
        label: "Chargeable",
        isChargeable: true,
    },
    NON_CHARGEABLE: {
        label: "Non-Chargeable",
        isChargeable: false,
    },
};

export { caseChargeableStatus };
