import { useQuery } from "react-query";
import queryKeys from "../../constants/queryKeys";
import contractService from "../../services/contractService";

const useServiceTypes = ({ coreServices }) =>
    useQuery(
        [queryKeys.serviceTypes, coreServices],
        () => contractService.getServiceTypes(coreServices),
        {
            staleTime: Infinity,
        }
    );

export default useServiceTypes;