import { useQuery } from "react-query";
import queryKeys from "../../constants/queryKeys";
import caseService from "../../services/caseService";

const useCaseActivities = ({
    caseId,
    valuesPerPage,
    pageNumber,
    dateFrom,
    dateTo,
    activityTypes,
    miscActivityTypes,
    isAction,
    isOutcome,
    chargeables,
    directions,
    userIds,
}) =>
    useQuery(
        [
            queryKeys.caseActivities,
            caseId,
            valuesPerPage,
            pageNumber,
            dateFrom,
            dateTo,
            activityTypes,
            miscActivityTypes,
            isAction,
            isOutcome,
            chargeables,
            directions,
            userIds,
        ],
        () =>
            caseService.getAllCaseActivities(
                caseId,
                valuesPerPage,
                pageNumber,
                dateFrom,
                dateTo,
                activityTypes,
                miscActivityTypes,
                isAction,
                isOutcome,
                chargeables,
                directions,
                userIds,
            ),
        {
            enabled: !!caseId && !!valuesPerPage,
        }
    );

export default useCaseActivities;
