import React, { useState } from "react";
import { Button, makeStyles } from "@material-ui/core";
import caseActivityTypes from "../../constants/caseActivityTypes";
import HistoryActivityFilterItem from "./HistoryActivityFilterItem";
import FilterWrapper from "./FilterWrapper";
import { mergeSelectedFiltersWithFetchedFilters } from "../../helpers/mergeSelectedFiltersWithFetchedFilters";

const useStyles = makeStyles((theme) => ({
    viewMore: {
        fontSize: 14,
        fontWeight: 500,
        padding: 0,
        marginTop: theme.spacing(3),
        alignSelf: "start",
    },
}));

const HistoryActivityFilter = ({
    activities,
    activitiesChecked = {},
    onActivityClick,
    isLoading,
}) => {
    const classes = useStyles();
    const [viewMore, setViewMore] = useState(false);
    let numberOfActivities = 0;

    const shapeActivities = () => {
        let shapedActivities = [];
        if (activities?.actionFilters?.count > 0) {
            const { count, label } = activities?.actionFilters;
            shapedActivities.push({
                name: label,
                count: count,
                activityType: caseActivityTypes.ACTION,
                activityTypeId: null,
            });
        }
        if (activities?.outcomeFilters?.count > 0) {
            const { count, label } = activities?.outcomeFilters;
            shapedActivities.push({
                name: label,
                count: count,
                activityType: caseActivityTypes.OUTCOME,
                activityTypeId: null,
            });
        }
        if (activities?.miscActivityTypeFilters?.length > 0) {
            activities.miscActivityTypeFilters.forEach((activity) => {
                const { count, label, miscActivityTypeId } = activity;
                shapedActivities.push({
                    name: label,
                    count: count,
                    activityType: caseActivityTypes.MISC,
                    activityTypeId: miscActivityTypeId,
                });
            });
        }
        if (activities?.activityTypeFilters?.length > 0) {
            activities.activityTypeFilters.forEach((activity) => {
                const { count, label, activityType } = activity;
                shapedActivities.push({
                    name: label,
                    count: count,
                    activityType: label,
                    activityTypeId: activityType,
                });
            });
        }

        return shapedActivities;
    };

    const sortActivities = () => {
        const shapedActivities = shapeActivities();

        const mergedFilters = mergeSelectedFiltersWithFetchedFilters(
            activitiesChecked,
            shapedActivities,
            "name"
        );

        if (!mergedFilters?.length) return [];

        numberOfActivities = mergedFilters.length;
        const sort = mergedFilters.sort((a, b) => a.name.localeCompare(b.name));
        if (mergedFilters.length > 3 && !viewMore) {
            return sort.slice(0, 3);
        }
        return sort;
    };

    const sortedActivities = sortActivities();

    return (
        <FilterWrapper
            filterName="Activity"
            isLoading={isLoading}
            testId="activity-filter-container"
        >
            {sortedActivities.length > 0
                ? sortedActivities.map((activity) => (
                    <HistoryActivityFilterItem
                        key={activity.name}
                        name={activity.name}
                        count={activity.count}
                        activity={activity}
                        isActivityChecked={activitiesChecked.hasOwnProperty(activity.name)}
                        onActivityClick={onActivityClick}
                    />
                ))
                : null}
            {numberOfActivities > 3 && (
                <Button
                    variant="text"
                    color="secondary"
                    className={classes.viewMore}
                    onClick={() => setViewMore(!viewMore)}
                >
                    View {!viewMore ? "More" : "Less"}
                </Button>
            )}
        </FilterWrapper>
    );
};
export default HistoryActivityFilter;