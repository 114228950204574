import React, { useEffect, useState, useMemo } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import avatarStyles from "../theme/avatars";
import axios from "../plugins/axios";
import { setCaseContacts } from "../redux/actions/caseActions";
import { setSnackAction } from "../redux/actions/snackActions";
import phoneImage from "../images/phone.svg";
import { dial } from "../redux/actions/flexActions";
import CreateContact from "../pages/CreateContact";
import { Controls } from "../helpers/Controls";
import { bindActionCreators } from "redux";
import { actionCreators } from "../redux/reducers/accountReducer";
import {
    Avatar,
    Badge,
    Box,
    CardContent,
    Card as MuiCard,
    CircularProgress,
    Button as MuiIconButton,
    Menu,
    MenuItem,
    Tooltip,
    Typography,
    makeStyles,
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import {
    Add as AddIcon,
    Edit as EditIcon,
    Star as PrimaryIcon,
    Delete as DeleteIcon,
    MoreHoriz,
} from "@material-ui/icons";
import { spacing } from "@material-ui/system";
import { useAuth } from "../contexts/authContext";
import userRoles from "../constants/userRoles";
import caseContactService from "../services/caseContactService";
import ContactPermissionSummary from "../components/ContactPermissionSummary";
import salesforceRoles from "../constants/salesforceRoles";
import AccountContactMenuItem from "../components/AccountContactMenuItem";
import useAdviceTypes from "../hooks/queries/useAdviceTypes";
import AdviceTypesChips from "../components/AdviceTypesChips";

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

const Card = styled(MuiCard)(spacing);

const IconButton = styled(MuiIconButton)`
    padding: 2px;
    margin-top: -2px;
    margin-left: 2px;
    min-width: 0;
    color: ${grey[700]};
`;

const CustomMenu = styled(Menu)`
    border-radius: 0px;
    ul {
        padding: 0px;
    }
`;

const useStyles = makeStyles((theme) => ({
    addContactButton: {
        backgroundColor: theme.palette.secondary.main,
        color: "white",
        textAlign: "center",
        "&:hover, &:focus": {
            backgroundColor: theme.palette.primary.main,
        },
    },
    contactContainer: {
        display: "flex",
        flexDirection: "column",
        position: "relative",
    },
    receiveAdvice: {
        color: theme.palette.secondary.main,
    }
}));

const modes = {
    SAVING: "saving",
    VIEW: "view",
};

const ContactsCard = ({
    setReplyContacts,
    setCaseContacts,
    setSnack,
    dial,
    caseState,
    caseTypes,
    userState,
}) => {
    const { currentCaseId: caseId, cases } = caseState;
    const { userProfile } = userState;
    const userId = userProfile.userId;
    const c = cases[caseId];
    const contacts = c.contacts;
    const accountId = c.account.accountId;
    const contracts = c.account.contracts;
    const contractId = c.caseSummary.contractId;
    const accountExternalId = c.account.externalId;

    const avatarClasses = avatarStyles();
    const classes = useStyles();

    const [mode, setMode] = useState(modes.VIEW);
    const [accountContacts, setAccountContacts] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [openPopup, setOpenPopup] = useState(false);

    // context menu
    const [contextAnchorEl, setContextAnchorEl] = useState(null);
    const [contextContact, setContextContact] = useState(null);

    const caseTypeId = c.caseSummary.caseTypeId;
    const adviceTypeId = useMemo(() => caseTypes[caseTypeId]?.adviceTypeId, [caseTypeId, caseTypes]);
    const adviceTypes = useAdviceTypes();
    const recordingDisabled = useMemo(
        () => adviceTypes.data?.find((at => at.id === adviceTypeId))?.recordingDisabled ?? false,
        [adviceTypeId, adviceTypes.data]
    );

    const getAccountContacts = async () => {
        var response = await axios.get(`${apiUrl}/getaccountcontacts/${accountId}`);
        setAccountContacts(response.data);
    };

    const { hasRole } = useAuth();  
    
    useEffect(() => {
        async function fetchAccountContacts() {
            const response = await axios.get(`${apiUrl}/getaccountcontacts/${accountId}`);
            setAccountContacts(response.data);
        }

        fetchAccountContacts();
    }, [accountId]);
    
    const unusedContacts = useMemo(() => {
        return accountContacts
            ? accountContacts.filter(ac => !contacts.map((c) => c.contactId).includes(ac.contactId))
            : [];
    }, [accountContacts, contacts]);
   
    const handlePrimary = async (contactId) => {
        try {
            handleCloseContextMenu();

            if (mode === modes.SAVING)
                return;

            setMode(modes.SAVING);
            await caseContactService.setPrimaryCaseContact(caseId, contactId, userId);
            setCaseContacts(
                caseId,
                contacts.map((c) => ({ ...c, isPrimary: c.contactId === contactId }))
            );
            setSnack("Saved!", "success");
        } catch (e) {
            setSnack("There was an error setting the primary contact", "error");
            console.error(e);
        } finally {
            setMode(modes.VIEW);
        }
    };

    const handleAdd = async (contactId) => {
        try {
            setMode(modes.SAVING);
            await caseContactService.addExistingContactToCase(caseId, contactId, userId);
            setCaseContacts(caseId, [
                ...contacts,
                ...accountContacts.filter((ac) => ac.contactId === contactId),
            ]);
            setSnack("Saved!", "success");
        } catch (e) {
            setSnack("There was an error adding the contact", "error");
            console.error(e);
        } finally {
            setMode(modes.VIEW);
            setAnchorEl(null);
        }
    };

    const handleDelete = async (contactId) => {
        try {
            handleCloseContextMenu();

            if (mode === modes.SAVING)
                return;

            setMode(modes.SAVING);
            await caseContactService.removeContactFromCase(caseId, contactId, userId);
            setCaseContacts(
                caseId,
                contacts.filter((c) => c.contactId !== contactId)
            );
            setSnack("Saved!", "success");
        } catch (e) {
            setSnack("There was an error removing the contact", "error");
            console.error(e);
        } finally {
            setMode(modes.VIEW);
        }
    };

    const dialNumber = (number, contactId) => {
        if (!hasRole(userRoles.LEGAL_ADVISOR))
            return;

        let outboundNumber = contracts.filter(c => c.contractId === contractId)[0].outboundNumber;

        if (outboundNumber) {
            // strip whitespace
            outboundNumber = outboundNumber.replace(/\s+/g, "");

            // replace leading '0' with '+44'
            if (outboundNumber.startsWith("0"))
                outboundNumber = "+44" + outboundNumber.substring(1);
        }

        dial(number, contactId, accountId, outboundNumber, recordingDisabled);
    };

    const [recordForEdit, setRecordForEdit] = useState(null);
    const [popupTitleText, setPopupTitleText] = useState("Add New Contact");

    const handleEditContact = (contact) => {
        handleCloseContextMenu();
        if (contact) {
            setRecordForEdit({ ...contact, id: contact.contactId });
            setPopupTitleText("Edit Existing Contact");
        } else {
            setRecordForEdit();
            setPopupTitleText("Add New Contact");
        }

        setOpenPopup(true);
    };

    const DisplayContacts = contacts.map((contact) => {

        const accountContact = accountContacts?.find(ac => ac.contactId === contact.contactId);

        return (
            <Box
                key={contact.externalId}
                display="flex"
                flexDirection="column"
                alignItems="center"
                mt={3}
                mb={2}
                ml={2}
                mr={2}
                style={{ textAlign: "center" }}
            >
                {
                    accountContact &&
                    <Box alignSelf="flex-end">
                        <IconButton onClick={e => handleOpenContextMenu(contact, e)}>
                            <MoreHoriz />
                        </IconButton>
                    </Box>
                }
                <Box className={classes.contactContainer}>
                    <Tooltip title={contact.isPrimary ? "Primary Contact" : ""}>
                        {contact.isPrimary ? (
                            <Badge
                                overlap="rectangular"
                                variant="dot"
                                color="primary"
                                anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "left",
                                }}
                            >
                                <Avatar
                                    alt={contact.name}
                                    className={avatarClasses.large}
                                />
                            </Badge>
                        ) : (
                            <Avatar
                                alt={contact.name}
                                className={avatarClasses.large}
                            />
                        )}
                    </Tooltip>
                    <ContactPermissionSummary
                        permissions={accountContact?.clientRoles}
                        adviceTypeIds={accountContact?.adviceTypeIds}
                        isContactsCard
                    />
                </Box>
                <Box>
                    <Typography variant="subtitle2">{contact.name}</Typography>
                </Box>
                <AdviceTypesChips hasAdviceRole={contact.clientRoles?.includes(salesforceRoles.CASENEST_RECEIVE_ADVICE)} adviceTypeIds={contact.adviceTypeIds} />
                <Box>
                    <Typography variant="body2">{contact.position}</Typography>
                    {!accountContact &&  <Typography variant="body2">**Contact Removed**</Typography>}
                </Box>
                {contact.phone && (
                    <Box>
                        <Typography
                            variant="caption"
                            style={{
                                cursor: hasRole(userRoles.LEGAL_ADVISOR)
                                    ? `url(${phoneImage}) 12 12, auto`
                                    : "",
                            }}
                            onClick={() =>
                                dialNumber(contact.phone, contact.contactId)
                            }
                        >
                            {contact.phone}
                        </Typography>
                    </Box>
                )}
            </Box>
        );
    });

    const handleAddNewContact = () => {
        setPopupTitleText("Add Contact");
        setRecordForEdit();
        setOpenPopup(true);
    };    

    const createLink = async (contactId) => {
        let response = await axios.post(`${apiUrl}/createaccountcontactlink`, {
            contactId: contactId,
            accountId: accountId,
        });
        if (response.status !== 200) {
            setSnack("Sorry, there was an error!", "error");
        } else {
            window.frames[0].postMessage({ event: "refresh" }, "*");
            setOpenPopup(false);
            getAccountContacts();
        }
    };    

    const contactAdded = (contact) => {
        if (contact.externalId) {
            window.frames[0].postMessage({ event: "refresh" }, "*");
            setOpenPopup(false);
            getAccountContacts();
        }
    }

    const contactEdited = async (contact) => {

        await axios.post(`${apiUrl}/editexistingaccountcontact?caseId=${caseId}`, contact);

        setCaseContacts(
            caseId,
            contacts.map((c) =>
                c.contactId === contact.id
                    ? {
                        ...c,
                        ...contact
                    }
                    : c
            )
        );

        setReplyContacts(
            caseId,
            c.reply.contacts.map(rc =>
                rc.contactId === contact.contactId
                    ? {
                        ...contact,
                        name: `${contact.firstName} ${contact.lastName}`,
                    }
                    : rc
            )
        );

        window.frames[0].postMessage({ event: "refresh" }, "*");
        setOpenPopup(false);

        getAccountContacts();
    }  

    const handleOpenContextMenu = (contact, event) => {
        setContextContact(contact);
        setContextAnchorEl(event.currentTarget);
    };

    const handleCloseContextMenu = () => {
        setContextAnchorEl(null);
        setContextContact(null);
    };

    return (
        <React.Fragment>
            <Card mb={6}>
                <CardContent>
                    <Box display="flex" alignItems="center" flexWrap="wrap">
                        <Box flexGrow={1}>
                            <Typography variant="h6" gutterBottom>Contacts</Typography>
                        </Box>
                        <Box>
                            {
                                mode !== modes.SAVING &&
                                <IconButton onClick={e => setAnchorEl(e.currentTarget.parentNode)}>
                                    <AddIcon />
                                </IconButton>
                            }
                            {
                                mode === modes.SAVING &&
                                <CircularProgress
                                    size={21}
                                    style={{ marginLeft: "9px" }}
                                />
                            }
                        </Box>
                    </Box>
                    <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Box
                            display="flex"
                            justifyContent="space-around"
                            flexWrap="wrap"
                            width="100%"
                            m={-2}
                        >
                            {DisplayContacts}
                        </Box>
                    </Box>
                    <CustomMenu
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={() => setAnchorEl(null)}
                    >
                        {
                            unusedContacts
                                .sort((a, b) => a.name > b.name ? 1 : b.name > a.name ? -1 : 0)
                                .map(c => <AccountContactMenuItem key={c.contactId} dto={c} handleAdd={() => handleAdd(c.contactId)} />)
                        }
                        <MenuItem
                            className={classes.addContactButton}
                            onClick={handleAddNewContact}
                        >
                            <Typography style={{ width: "100%" }} variant="body2">Add New Contact</Typography>
                        </MenuItem>
                    </CustomMenu>
                </CardContent>
            </Card>
            <Controls.PopupControl
                title={popupTitleText}
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
            >
                <CreateContact
                    createLink={createLink}
                    recordForEdit={recordForEdit}
                    accountId={accountId}
                    accountExternalId={accountExternalId}
                    contactAdded={contactAdded}
                    contactEdited={contactEdited}
                />
            </Controls.PopupControl>

            <Menu
                anchorEl={contextAnchorEl}
                keepMounted
                open={Boolean(contextAnchorEl)}
                onClose={handleCloseContextMenu}
            >
                <MenuItem onClick={() => handleEditContact(contextContact)}>
                    <EditIcon style={{ marginRight: "6px", color: grey[700] }} />
                    {" Edit"}
                </MenuItem>
                <MenuItem
                    disabled={contextContact && contextContact.isPrimary}
                    onClick={() => handlePrimary(contextContact.contactId)}
                >
                    <PrimaryIcon style={{ marginRight: "6px", color: grey[700] }} />
                    {" Make primary"}
                </MenuItem>
                <MenuItem
                    disabled={contextContact && contextContact.isPrimary}
                    onClick={() => handleDelete(contextContact.contactId)}
                >
                    <DeleteIcon style={{ marginRight: "6px", color: grey[700] }} />
                    {" Delete"}
                </MenuItem>
            </Menu>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => ({
    caseState: state.caseReducer,
    userState: state.userReducer,
    caseTypes: state.configReducer.caseTypes,
});

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(actionCreators, dispatch),
        setSnack: (message, severity) => dispatch(setSnackAction(message, severity)),
        setCaseContacts: (caseId, contacts) => dispatch(setCaseContacts(caseId, contacts)),
        dial: (number, contactId, accountId, outboundNumber, recordingDiabled) => dispatch(dial(number, contactId, accountId, outboundNumber, recordingDiabled)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactsCard);
