export const insertPrecedentLink = (editor, precedentId, precedentName) => {
    const selection = editor.model.document.selection;
    editor.model.change(writer => {

        const documentLink = writer.createElement('documentlink', {
            ...Object.fromEntries(selection.getAttributes()),
            precedentId,
            precedentName
        });

        editor.model.insertContent(documentLink);

        //writer.setSelection(documentLink, 'on');
    });
};

export const insertEvidenceLink = (editor, employees, caseGuid, ldmUrl) => {
    const selection = editor.model.document.selection;
    editor.model.change(writer => {

        let url = `${ldmUrl}/evidenceuploader/${caseGuid}`;
        let employeeQuery = ''
        employees.forEach((e) => employeeQuery += `${employeeQuery ? '&' : '?'}employee=${e.name}`)

        url += employeeQuery;
        const encodedUrl = encodeURI(url);

        const evidenceLink = writer.createElement('evidencelink', {
            ...Object.fromEntries(selection.getAttributes()),
            url: encodedUrl
        });

        editor.model.insertContent(evidenceLink);

        // Put the selection on the inserted element.
        //writer.setSelection(evidenceLink, 'on');
    });
};

export const insertTemplateHtml = (editor, html) => {
    const viewFragment = editor.data.processor.toView(html);
    const modelFragment = editor.data.toModel(viewFragment);

    console.log('frag', viewFragment, modelFragment)
    editor.model.insertContent(modelFragment);
};

export const getNodesByType = function (writer, type, root) {
    const nodes = [];
    const range = writer.createRangeIn(root);

    for (const value of range.getWalker({ ignoreElementEnd: true })) {
        const node = value.item;

        if (Array.isArray(type)) {
            if (type.includes(node.name)) {
                nodes.push(node);
            }
        }
        else {
            if (node.name === type) {
                nodes.push(node);
            }
        }
    }
   
    return nodes;
};

export const setMentionValue = (editor, key, value) => {

    editor.model.change(writer => {

        const root = editor.model.document.getRoot();
        const elements = getNodesByType(writer, ['blockplaceholder', 'inlineplaceholder'], root);
        for (const element of elements) {
            const mention = element.getAttribute('mention');

            if (mention.key === key) {
                mention.value = value;
                writer.setAttribute('value', value, element);
            }
        }
    });
};

export const removePrecedentById = (editor, precedentId) => {
    editor.model.change(writer => {
        const documentLinks = getNodesByType(writer, 'documentlink', editor.model.document.getRoot())
        for (const link of documentLinks) {
            if (link.getAttribute('precedentId') == precedentId)
                writer.remove(link);
        }
    });
};

export const appendEditorContent = (editor, content) => {
    const existing = editor.getData();
    const newData = existing + content;
    editor.setData(newData)
    //const viewFragment = editor.data.processor.toView(content);
    //const modelFragment = editor.data.toModel(viewFragment);

    //editor.model.insertContent(modelFragment);
}
