import React from "react";
import { useQuery } from "react-query";
import queryKeys from "../../constants/queryKeys";
import contractService from "../../services/contractService";

const useContractSummary = ({
    contractId,
}) =>
    useQuery(
        [
            queryKeys.contractSummary,
            contractId,
        ],
        () =>
            contractService.getContractSummary({
                contractId,
            }),
        {
            enabled: !!contractId,
        }
    );

export default useContractSummary;
