import {useQuery} from "react-query";
import queryKeys from '../../constants/queryKeys'
import accountService from "../../services/accountService";

const useAccountFiles = (accountId, fileType, documentType

) => useQuery(
    [queryKeys.accountFiles, fileType, documentType, accountId],
    () => accountService.fetchAccountFiles(accountId, fileType, documentType), 
    {
        enabled: !!accountId ||
            !!fileType
    })

export default useAccountFiles;