import { routerMiddleware } from 'connected-react-router';
import { createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import history from '../history';
import caseActivitySetter from '../middleware/caseActivitySetter';
import caseActivityTracker from '../middleware/caseActivityTracker';
import createRootReducer from '../reducers/index';

const middleware = [
    thunk,
    caseActivitySetter,
    caseActivityTracker,
    routerMiddleware(history),
];

const enhancers = [];
const isDevelopment = process.env.NODE_ENV === 'development';
if (
    isDevelopment &&
    typeof window !== 'undefined' &&
    window.__REDUX_DEVTOOLS_EXTENSION__
) {
    enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__());
}

export const configureStore = preloadedState => {
    const createdStore = createStore(
        createRootReducer(history),
        preloadedState,
        compose(applyMiddleware(...middleware), ...enhancers)
    );

    return createdStore;
};

const store = configureStore();

export default store;
