import { useQuery } from "react-query";
import queryKeys from "../../constants/queryKeys";
import caseEmployeeService from "../../services/caseEmployeeService";

const useEmployeeLinkedCases = ({ employeeId }) =>
    useQuery(
        [queryKeys.employeeLinkedCases, employeeId],
        () => caseEmployeeService.getEmployeeLinkedCases(employeeId),
        {
            enabled: !!employeeId,
        }
    );

export default useEmployeeLinkedCases;