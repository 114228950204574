import React, { useState } from "react";
import styled from "styled-components";
import TimesheetTable from "../components/TimesheetTable";
import Helmet from "react-helmet";
import {
    Card as MuiCard,
    CardHeader,
    CardContent,
    Divider,
    IconButton

} from "@material-ui/core";

import { spacing } from "@material-ui/system";
import { makeStyles } from "@material-ui/core/styles";
import { MoreVertical as MoreVerticalIcon } from "react-feather";
import { useAuth } from "../contexts/authContext";
import userRoles from "../constants/userRoles"

const Card = styled(MuiCard)(spacing);

const formStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(3),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    }
}))

const TimesheetCard = ({ adviserId, adviserName, displayTimesheet }) => {

    const [chartContent, setChartContent] = useState("All Time");

    const [timesheetPeriod, setTimesheetPeriod] = useState("Weekly");

    const { hasRole } = useAuth();

    const hasLegalManagementRole = hasRole(userRoles.LEGAL_MANAGER);

    const handleTimesheetPeriodChange = e => {
        setTimesheetPeriod(e.target.value);
    };

    const handleSwitchViews = () => {
        //alter the chart which data to include
        setChartContent(chartContent == "All Time" ? "Advice Time" : chartContent == "Advice Time" ? "Non-Advice Time" : "All Time")
    }

    return (
        <React.Fragment>

            <Helmet title="Timesheet" />

            <Divider my={6} />

            {(hasLegalManagementRole || displayTimesheet) &&
                <Card mb={6}>
                    <CardHeader
                        action={
                            <IconButton aria-label="settings" onClick={() => handleSwitchViews()}>
                                <MoreVerticalIcon />
                            </IconButton>
                        }
                        title={"Time Allocation  (" + chartContent + ")"}
                    />

                    <CardContent>
                        <TimesheetTable
                            adviserId={adviserId}
                            timesheetPeriod={timesheetPeriod}
                            adviserName={adviserName}
                            hasLegalManagementRole={hasLegalManagementRole}
                            chartContent={chartContent}
                        />
                    </CardContent>
                </Card>
            }

        </React.Fragment>

    );
}
export default TimesheetCard;
