import React, { useEffect, useState } from "react";
import axios from "../../plugins/axios";
import styled from "styled-components";
import { spacing } from "@material-ui/system";
import KnowledgeDocumentsTable from "./KnowledgeDocumentsTable";
import { grey } from "@material-ui/core/colors";

import {
    Typography,
    Card as MuiCard,
    CardContent,
    Button as MuiIconButton,
    Box,
    Dialog,
    DialogContent,
    CircularProgress
} from "@material-ui/core";
import {
    Add as AddIcon
} from "@material-ui/icons";

const IconButton = styled(MuiIconButton)`
    padding: 2px;
    margin-top: -2px;
    margin-left: 2px;
    min-width: 0;
    color: ${grey[700]};
`;

const Card = styled(MuiCard)(spacing);

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

const LinkedDocumentsCard = ({ article, setArticle }) => {    

    const [openDialog, setOpenDialog] = useState(false); 

    const [saving, setSaving] = useState(false);

    const addDocument = async id => {
        setOpenDialog(false);
        setSaving(true);
        let newArticle = { ...article, childDocumentIds: [...article.childDocumentIds, id] };
        await axios.post(`${apiUrl}/knowledge/updatearticle`, { id: article.id, childDocumentIds: newArticle.childDocumentIds });
        setArticle(newArticle); 
        setSaving(false);
    };

    const removeLink = async id => {
        setSaving(true);
        console.log('delete', id);
        let newArticle = { ...article, childDocumentIds: article.childDocumentIds.filter(x => x !== id) };
        console.log('newArticle', newArticle);
        await axios.post(`${apiUrl}/knowledge/updatearticle`, { id: article.id, childDocumentIds: newArticle.childDocumentIds });
        setArticle(newArticle);
        setSaving(false);
    }

    useEffect(() => {
        setSaving(false);
    }, [article.childArticleIds, setSaving]);

    return (
        <Card mb={6}>            
            <CardContent>                
                <Box display="flex">
                    <Box flexGrow={1} alignItems="center">
                        <Typography variant="h6" gutterBottom>Linked Documents</Typography>
                    </Box>
                    <Box>
                        {
                            saving
                            ? <CircularProgress
                                size={21}
                                style={{ marginLeft: "9px" }}
                            />
                                : <IconButton onClick={() => setOpenDialog(true)}>
                                <AddIcon />
                            </IconButton>
                        }
                    </Box>
                </Box>                
                <KnowledgeDocumentsTable documentIds={article.childDocumentIds} onDelete={removeLink} />
                <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                    <DialogContent>
                        <KnowledgeDocumentsTable
                            onRowClick={id => addDocument(id)}
                            withoutDocumentIds={article.childDocumentIds}
                        />
                    </DialogContent>
                </Dialog>
            </CardContent>
        </Card>
    );
};

export default LinkedDocumentsCard
