import { useQuery } from "react-query";
import queryKeys from "../../constants/queryKeys";
import caseActivityService from "../../services/caseActivityService";

const useCases = (criteria) =>
    useQuery([queryKeys.caseActivitiesForUser, ...Object.values(criteria)], () =>
        caseActivityService.getActivtiesForUser(criteria)
    );

export default useCases;
