import { useQuery } from "react-query";
import queryKeys from "../../constants/queryKeys";
import emailDraftService from "../../services/emailDraftService";

const useCaseDraftEmailsSummary = (caseId) =>
    useQuery(
        [queryKeys.draftEmailsSummary, caseId],
        () => emailDraftService.getSummary(caseId),
        {
            enabled: !!caseId
        }
    )

export default useCaseDraftEmailsSummary;