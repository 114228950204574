import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { bindActionCreators } from "redux";
import { actionCreators } from "../redux/reducers/caseReducer";
import { defaultRowsPerPage } from "../constants/tableConstants";

import {
    setCriteriaAdviserId,
    setCriteriaCaseTypeId,
    setCriteriaPartnershipId,
    setCriteriaEmployee,
    setCriteriaModifiedDateFrom,
    setCriteriaModifiedDateTo,
    setCriteriaCreatedDateFrom,
    setCriteriaCreatedDateTo,
    setCriteriaPartnerNumber,
    setCriteriaAccountName,
    setDisplayCaseSearchInTab,
    setDisplayClosedCases,
    setCriteriaPrimaryContact,
    setCriteriaCaseDescription,
} from "../redux/actions/caseActions";

import { setCurrentAccountAction } from "../redux/actions/accountActions";

import {
    Button as MuiIconButton,
    Box,
    CardContent,
    Card as MuiCard,
    FormControl,
    Grid,
    Input,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    Tooltip,
    Typography,
    Divider as MuiDivider,
    FormControlLabel,
    Switch,
} from "@material-ui/core";
import { Search as SearchIcon, FilterList as FilterIcon, Clear as ClearIcon, AddBox as AddIcon } from "@material-ui/icons";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { spacing } from "@material-ui/system";
import { makeStyles } from "@material-ui/core/styles";

import CaseTable from "../components/CaseTable";
import useFilteredAdvisers from "../hooks/useFilteredAdvisers";
import { useAuth } from "../contexts/authContext";
import userRoles from "../constants/userRoles";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const IconButton = styled(MuiIconButton)`
    padding: 2px;
    margin-top: -2px;
    margin-left: 2px;
    min-width: 0;
`;

const formStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(3),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const Cases = ({
    configState,
    userState,
    caseState,
    viewtype,
    setCriteriaModifiedDateTo,
    setCriteriaModifiedDateFrom,
    setCriteriaCreatedDateTo,
    setCriteriaCreatedDateFrom,
    setCriteriaCaseTypeId,
    setCriteriaAdviserId,
    setCriteriaPartnershipId,
    setCriteriaEmployee,
    setCriteriaPartnerNumber,
    setCriteriaAccountName,
    setCriteriaPrimaryContact,
    setDisplayClosedCases,
    setCriteriaCaseDescription,
}) => {
    const formClasses = formStyles();
    const { hasRole } = useAuth();
    const history = useHistory();
    const {
        createdDateFrom,
        createdDateTo,
        modifiedDateFrom,
        modifiedDateTo,
        adviserId,
        caseTypeId,
        employeeName,
        accountName,
        partnershipId,
        partnerNumber,
        caseDescription,
        primaryContact,
        showClosed,
    } = caseState.searchCriteria;

    const [employeeQuery, setEmployeeQuery] = useState(caseState.searchCriteria.employeeName);
    const [accountQuery, setAccountQuery] = useState(caseState.searchCriteria.accountName);
    const [partnerNumberQuery, setPartnerNumberQuery] = useState(caseState.searchCriteria.partnerNumber);
    const [caseDescriptionQuery, setCaseDescriptionQuery] = useState(caseState.searchCriteria.caseDescription);
    const [primaryContactQuery, setPrimaryContactQuery] = useState(caseState.searchCriteria.primaryContact);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);
    const filteredAdvisers = useFilteredAdvisers();

    useEffect(() => {
        setPage(0);
        if (viewtype === "Advisor") {
            setCriteriaAdviserId(userState.userProfile.userId);
            return;
        }
        setCriteriaAdviserId("");
    }, [viewtype]);

    const handleCreatedFromDateChange = (date) => {
        if (date) {
            setPage(0);
            let fromDate = date;
            fromDate.setHours(0);
            fromDate.setMinutes(0);
            setCriteriaCreatedDateFrom(fromDate);
        }
    };

    const handleCreatedToDateChange = (date) => {
        if (date) {
            setPage(0);
            let toDate = date;
            toDate.setHours(23);
            toDate.setMinutes(59);
            toDate.setSeconds(59);
            setCriteriaCreatedDateTo(toDate);
        }
    };

    const handleModifiedFromDateChange = (date) => {
        if (date) {
            setPage(0);
            let fromDate = date;
            fromDate.setHours(0);
            fromDate.setMinutes(0);
            setCriteriaModifiedDateFrom(fromDate);
        }
    };

    const handleModifiedToDateChange = (date) => {
        if (date) {
            setPage(0);
            let toDate = date;
            toDate.setHours(23);
            toDate.setMinutes(59);
            toDate.setSeconds(59);
            setCriteriaModifiedDateTo(toDate);
        }
    };

    const handleCaseTypeChange = (e) => {
        setPage(0);
        setCriteriaCaseTypeId(e.target.value);
    };

    const handleAdviserChange = (e) => {
        setPage(0);
        setCriteriaAdviserId(e.target.value);
    };

    const handlePartnershipChange = (e) => {
        setPage(0);
        setCriteriaPartnershipId(e.target.value);
    };

    const handleEnterKeyPressEmployee = (e) => {
        if (e.key === "Enter") {
            setPage(0);
            setCriteriaEmployee(e.target.value);
        }
    };

    const handleEnterKeyPressAccount = (e) => {
        if (e.key === "Enter") {
            setPage(0);
            setCriteriaAccountName(e.target.value);
        }
    };

    const handleEnterKeyPressPartnerNumber = (e) => {
        if (e.key === "Enter") {
            setPage(0);
            setCriteriaPartnerNumber(e.target.value);
        }
    };

    const handleEnterKeyPressCaseDescription = (e) => {
        if (e.key === "Enter") {
            setPage(0);
            setCriteriaCaseDescription(e.target.value);
        }
    };

    const handleEnterKeyPressPrimaryContact = (e) => {
        if (e.key === "Enter") {
            setPage(0);
            setCriteriaPrimaryContact(e.target.value);
        }
    };

    const clearFilters = () => {
        setCriteriaCreatedDateFrom(null);
        setCriteriaCreatedDateTo(null);
        setCriteriaModifiedDateFrom(null);
        setCriteriaModifiedDateTo(null);
        setCriteriaAdviserId("");
        setCriteriaCaseTypeId("");
        setCriteriaEmployee("");
        setEmployeeQuery("");
        setCriteriaPartnershipId("");
        setCriteriaAccountName("");
        setAccountQuery("");
        setCriteriaPartnerNumber("");
        setPartnerNumberQuery("");
        setCriteriaPrimaryContact("");
        setPrimaryContactQuery("");
        setCriteriaCaseDescription("");
        setCaseDescriptionQuery("");
        setDisplayClosedCases(false);
    };

    return (
        <React.Fragment>
            <Helmet title={viewtype === "Advisor" ? "My Cases" : "Cases"} />
            <Grid container justifyContent="space-between">
                <Grid item mb={3}>
                    <Typography variant="h3" gutterBottom display="inline" data-cy="cases_h3">
                        {viewtype === "Advisor" ? "My Cases" : "Cases"}
                    </Typography>
                </Grid>
                <Grid
                    item
                    style={{
                        display: "flex",
                        alignItems: "flex-end",
                        flexWrap: "wrap",
                    }}
                >
                    <FormControl style={{ paddingBottom: "8px" }} className={formClasses.formControl}>
                        <InputLabel>Account Name</InputLabel>
                        <Input
                            type="text"
                            value={accountQuery}
                            onChange={(e) => setAccountQuery(e.target.value)}
                            onKeyPress={handleEnterKeyPressAccount}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton onClick={() => setCriteriaAccountName(accountQuery)}>
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                    <FormControl style={{ paddingBottom: "8px" }} className={formClasses.formControl}>
                        <InputLabel>Partner Number</InputLabel>
                        <Input
                            type="text"
                            value={partnerNumberQuery}
                            onChange={(e) => setPartnerNumberQuery(e.target.value)}
                            onKeyPress={handleEnterKeyPressPartnerNumber}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton onClick={() => setCriteriaPartnerNumber(partnerNumberQuery)}>
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>

                    <FormControl style={{ paddingBottom: "8px" }} className={formClasses.formControl}>
                        <InputLabel>Partner</InputLabel>
                        <Select value={partnershipId || ""} onChange={handlePartnershipChange}>
                            <MenuItem key="0" value="">
                                Any
                            </MenuItem>
                            {Object.values(configState.partnerships)
                                .sort((a, b) => (a.title > b.title ? 1 : b.title > a.title ? -1 : 0))
                                .map((p) => (
                                    <MenuItem key={p.id} value={p.id}>
                                        {p.title}
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                    <FormControl className={formClasses.formControl}>
                        <KeyboardDatePicker
                            format="dd/MM/yyyy"
                            margin="normal"
                            label="Modified Date from"
                            value={modifiedDateFrom}
                            onChange={handleModifiedFromDateChange}
                            maxDate={new Date()}
                        />
                    </FormControl>
                    <FormControl className={formClasses.formControl}>
                        <KeyboardDatePicker
                            format="dd/MM/yyyy"
                            margin="normal"
                            label="Modified Date to"
                            value={modifiedDateTo}
                            onChange={handleModifiedToDateChange}
                            maxDate={new Date()}
                        />
                    </FormControl>

                    <FormControl className={formClasses.formControl}>
                        <KeyboardDatePicker
                            format="dd/MM/yyyy"
                            margin="normal"
                            label="Created Date from"
                            value={createdDateFrom}
                            onChange={handleCreatedFromDateChange}
                            maxDate={new Date()}
                        />
                    </FormControl>
                    <FormControl className={formClasses.formControl}>
                        <KeyboardDatePicker
                            format="dd/MM/yyyy"
                            margin="normal"
                            label="Created Date to"
                            value={createdDateTo}
                            onChange={handleCreatedToDateChange}
                            maxDate={new Date()}
                        />
                    </FormControl>

                    <FormControl style={{ paddingBottom: "8px" }} className={formClasses.formControl}>
                        <InputLabel>Adviser</InputLabel>
                        <Select value={adviserId || ""} onChange={handleAdviserChange} disabled={viewtype === "Advisor" && hasRole(userRoles.HRC_ASSOCIATE)}>
                            <MenuItem key="0" value="">
                                Any
                            </MenuItem>
                            {filteredAdvisers
                                .filter((x) => x.isActive || x.userId === userState.userProfile.userId)
                                .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
                                .map((u) => (
                                    <MenuItem key={u.userId} value={u.userId}>
                                        {u.name}
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>

                    <FormControl style={{ paddingBottom: "8px" }} className={formClasses.formControl}>
                        <InputLabel>Case Type</InputLabel>
                        <Select value={caseTypeId || ""} onChange={handleCaseTypeChange}>
                            <MenuItem key="0" value="">
                                Any
                            </MenuItem>
                            {Object.values(configState.caseTypes)
                                .filter((x) => x.isActive)
                                .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
                                .map((ct) => (
                                    <MenuItem key={ct.caseTypeId} value={ct.caseTypeId}>
                                        {ct.name}
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                    <FormControl style={{ paddingBottom: "8px" }} className={formClasses.formControl}>
                        <InputLabel>Employee</InputLabel>
                        <Input
                            type="text"
                            value={employeeQuery}
                            onChange={(e) => setEmployeeQuery(e.target.value)}
                            onKeyPress={handleEnterKeyPressEmployee}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton onClick={() => setCriteriaEmployee(employeeQuery)}>
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                    <FormControl style={{ paddingBottom: "8px" }} className={formClasses.formControl}>
                        <InputLabel>Case Description</InputLabel>
                        <Input
                            type="text"
                            value={caseDescriptionQuery}
                            onChange={(e) => setCaseDescriptionQuery(e.target.value)}
                            onKeyPress={handleEnterKeyPressCaseDescription}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton onClick={() => setCriteriaCaseDescription(caseDescriptionQuery)}>
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                    <FormControl style={{ paddingBottom: "8px" }} className={formClasses.formControl}>
                        <InputLabel>Primary Contact</InputLabel>
                        <Input
                            type="text"
                            value={primaryContactQuery}
                            onChange={(e) => setPrimaryContactQuery(e.target.value)}
                            onKeyPress={handleEnterKeyPressPrimaryContact}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton onClick={() => setCriteriaPrimaryContact(primaryContactQuery)}>
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                </Grid>
            </Grid>
            <Box display="flex" width="100%" justifyContent="space-between" my={6}>
                <Box display="flex">
                    {(viewtype !== "Advisor" || !hasRole(userRoles.HRC_ASSOCIATE)) && (
                        <IconButton style={{ width: "90px" }} color="primary" onClick={() => history.push("/createcase")} data-cy="create_case">
                            <AddIcon /> Create
                        </IconButton>
                    )}
                    <FormControlLabel
                        control={
                            <Switch checked={showClosed} onChange={(e) => setDisplayClosedCases(e.target.checked)} name="showClosedCases" color="primary" />
                        }
                        label="Show Closed Cases"
                    />
                </Box>
                <Tooltip title="Clear filters">
                    <IconButton style={{ width: "70px" }} onClick={clearFilters}>
                        <ClearIcon />
                        <FilterIcon />
                    </IconButton>
                </Tooltip>
            </Box>
            <Divider my={6} />
            <Card mb={6}>
                <CardContent>
                    <CaseTable
                        adviserId={adviserId || ""}
                        caseTypeId={caseTypeId || ""}
                        modifiedFromDate={modifiedDateFrom}
                        modifiedToDate={modifiedDateTo}
                        createdFromDate={createdDateFrom}
                        createdToDate={createdDateTo}
                        showClosed={showClosed}
                        clickable
                        employee={employeeName}
                        account={accountName}
                        partnershipId={partnershipId}
                        partnerNumber={partnerNumber}
                        caseDescription={caseDescription}
                        primaryContact={primaryContact}
                        page={page}
                        setPage={setPage}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                        hideRowsPerPageOptions={true}
                    />
                </CardContent>
            </Card>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => ({
    configState: state.configReducer,
    userState: state.userReducer,
    caseState: state.caseReducer,
});

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(actionCreators, dispatch),
        setCriteriaCreatedDateFrom: (createdFromDate) => dispatch(setCriteriaCreatedDateFrom(createdFromDate)),
        setCriteriaCreatedDateTo: (createdToDate) => dispatch(setCriteriaCreatedDateTo(createdToDate)),
        setCriteriaModifiedDateFrom: (modifiedFromDate) => dispatch(setCriteriaModifiedDateFrom(modifiedFromDate)),
        setCriteriaModifiedDateTo: (modifiedToDate) => dispatch(setCriteriaModifiedDateTo(modifiedToDate)),
        setCriteriaEmployee: (employee) => dispatch(setCriteriaEmployee(employee)),
        setCriteriaCaseTypeId: (caseTypeId) => dispatch(setCriteriaCaseTypeId(caseTypeId)),
        setCriteriaAdviserId: (adviserId) => dispatch(setCriteriaAdviserId(adviserId)),
        setCriteriaPartnershipId: (partnershipId) => dispatch(setCriteriaPartnershipId(partnershipId)),
        setCriteriaPartnerNumber: (partnerNumber) => dispatch(setCriteriaPartnerNumber(partnerNumber)),
        setCriteriaAccountName: (accountName) => dispatch(setCriteriaAccountName(accountName)),
        setCriteriaPrimaryContact: (primaryContact) => dispatch(setCriteriaPrimaryContact(primaryContact)),
        setCriteriaCaseDescription: (caseDescription) => dispatch(setCriteriaCaseDescription(caseDescription)),
        setDisplayCaseSearchInTab: (display) => dispatch(setDisplayCaseSearchInTab(display)),
        setCurrentAccountAction: (accountId) => dispatch(setCurrentAccountAction(accountId)),
        setDisplayClosedCases: (displayClosed) => dispatch(setDisplayClosedCases(displayClosed)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Cases);
