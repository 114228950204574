import React from "react";
import { Divider, makeStyles } from "@material-ui/core";
import CaseTimeDurationTableItem from "./CaseTimeDurationTableItem";

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        marginTop: theme.spacing(3),
    },
    divider: {
        width: "100%",
        margin: theme.spacing(1, 0),
    },
}));

const CaseTimeDurationTable = ({
    isChargeable = false,
    isUnitised = false,
    hasBudgetSet = false,
    totalTime,
    chargeableTime,
    nonChargeableTime,
    budgetTime,
}) => {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <CaseTimeDurationTableItem
                title="Total"
                time={totalTime}
                isTotal
                isUnitised={isUnitised}
            />
            <Divider className={classes.divider} />
            <CaseTimeDurationTableItem
                title="Non-Chargeable"
                time={nonChargeableTime}
                isChargeable={!isChargeable}
                isUnitised={isUnitised}
            />
            <CaseTimeDurationTableItem
                title="Chargeable"
                time={chargeableTime}
                isChargeable={isChargeable}
                isUnitised={isUnitised}
            />
            {isChargeable && hasBudgetSet && (
                <CaseTimeDurationTableItem
                    title="Budget"
                    time={budgetTime}
                    isUnitised={isUnitised}
                />
            )}
        </div>
    );
};

export default CaseTimeDurationTable;
