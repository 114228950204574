import React, { useCallback, useState, useRef, useMemo } from 'react';
import { CSVLink } from 'react-csv';
import { makeStyles } from '@material-ui/core/styles';
import { format } from "date-fns";

const useStyles = makeStyles((theme) => ({
    csvLink: {
        visibility: "hidden",
    }
}));

const useTimesheetCSVExport = () => {

    const classes = useStyles();
    const [csvExportData, setCsvExportData] = useState([]);
    const csvExportLinkRef = useRef(null);

    const exportToCsv = useCallback((data) => {

        const formattedTimesheetData = data.map((timesheet) => ({
            caseActivityId: timesheet?.caseActivityId,
            date: format(new Date(timesheet?.date), "dd/MM/yyyy HH:mm") || "N/A",
            caseId: timesheet?.caseId || "N/A",
            accountName: timesheet?.accountName || "N/A",
            adviser: timesheet?.adviser || "N/A",
            caseDescription: timesheet?.caseDescription || "N/A",
            employees: timesheet?.employees || "N/A",
            caseType: timesheet?.caseType || "N/A",
            activityType: timesheet?.activityType || "N/A",
            miscActivityType: timesheet.miscActivityType || "N/A",
            activityDescription: timesheet?.activityDescription || "N/A",
            isChargeable: timesheet?.isChargeable ? "Yes" : "No" || "N/A",
            contractNumber: timesheet?.contractNumber || "N/A",
            minutes: timesheet?.isContractUnitised ? "" : timesheet?.minutes || "0",
            units: timesheet?.isContractUnitised ? timesheet?.units || "0" : "",
            unitisedMinutes: timesheet?.isContractUnitised ? timesheet?.unitisedMinutes || "0" : "",
            hourlyRate: timesheet?.hourlyRate || "N/A",
            coreService: timesheet?.coreService || "N/A",
            serviceType: timesheet?.serviceType || "N/A",
            jobCode: timesheet?.jobCode || "N/A",
            invoiceStatus: timesheet?.invoiceStatus || "N/A",
            invoiceStatusUpdateDate: timesheet?.invoiceStatusUpdateDate ? format(new Date(timesheet?.invoiceStatusUpdateDate), "dd/MM/yyyy HH:mm") : "N/A",
            invoiceNumber: timesheet?.invoiceNumber || "",
            invoiceDate: timesheet?.invoiceDate ? format(new Date(timesheet?.invoiceDate), "dd/MM/yyyy HH:mm") : "",
            isPrivate: timesheet?.isPrivate ? "Private" : "",
            caseCovered: timesheet.caseCovered ? "YES" : timesheet.caseCovered === false ? "NO" : "",
            hoursAuthorised: timesheet.hoursAuthorised,
        }));

        setCsvExportData(formattedTimesheetData);
        setTimeout(() => {
            csvExportLinkRef.current.link.click();
        });

    }, []);

    const csvExportHeaders = useMemo(
        () => [
            { label: "Case Activity Id", key: "caseActivityId" },
            { label: "Date", key: "date" },
            { label: "Case ID", key: "caseId" },
            { label: "Account Name", key: "accountName" },
            { label: "Adviser", key: "adviser" },
            { label: "Case Description", key: "caseDescription" },
            { label: "Employees", key: "employees" },
            { label: "Case Type", key: "caseType" },
            { label: "Activity Type", key: "activityType" },
            { label: "Misc Activity Type", key: "miscActivityType" },
            { label: "Activity Description", key: "activityDescription" },
            { label: "Billable?", key: "isChargeable" },
            { label: "Private?", key: "isPrivate" },
            { label: "Contract Number", key: "contractNumber" },
            { label: "Minutes", key: "minutes" },
            { label: "Units", key: "units" },
            { label: "Unitised Minutes", key: "unitisedMinutes" },
            { label: "Rate", key: "hourlyRate" },
            { label: "Core Service", key: "coreService" },
            { label: "Service Type", key: "serviceType" },
            { label: "Job Code", key: "jobCode" },
            { label: "Invoice Status", key: "invoiceStatus" },
            { label: "Invoice Status Update Date", key: "invoiceStatusUpdateDate" },
            { label: "Invoice Number", key: "invoiceNumber" },
            { label: "Invoice Date", key: "invoiceDate" },
            { label: "Case Covered", key: "caseCovered" },
            { label: "Hours Authorised", key: "hoursAuthorised" },
        ],
        []
    );

    const CsvLinkComponent = () => (
        <CSVLink
            className={classes.csvLink}
            ref={csvExportLinkRef}
            headers={csvExportHeaders}
            data={csvExportData}
            filename={`CaseActivityInvoiceTimesheet${format(
                new Date(),
                "yyyyMMddhhmmss"
            )}.csv`}
        />
    );

    return { CsvLinkComponent, exportToCsv }
};

export default useTimesheetCSVExport;