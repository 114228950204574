import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import { toWidget, viewToModelPositionOutsideModelElement } from '@ckeditor/ckeditor5-widget/src/utils';
import Widget from '@ckeditor/ckeditor5-widget/src/widget';

export default class BlockPlaceholder extends Plugin {

    static get requires() {
        return [Widget];
    }

    init() {        

        this._defineSchema();
        this._defineConverters();

        this.editor.editing.mapper.on(
            'viewToModelPosition',
            viewToModelPositionOutsideModelElement(this.editor.model, viewElement => viewElement.hasClass('placeholder'))
        );
    }

    _defineSchema() {
        const schema = this.editor.model.schema;

        schema.register('blockplaceholder', {
            allowWhere: '$block',
            isObject: true,
            allowAttributes: ['mention', 'value']
        });      
    }

    _defineConverters() {
        const conversion = this.editor.conversion;

        conversion.for('upcast')
            .add(dispatcher => dispatcher.on('element:ul', ulUpcastConverter));

        function ulUpcastConverter (event, data, conversionApi) {
            const viewElement = data.viewItem;

            //console.log('ul', viewElement);
            if (!viewElement.hasClass('placeholder')) {
                return;
            }

            const mention = {
                guidance: viewElement.getAttribute('title') || "",
                id: viewElement.getAttribute('data-id'),
                mentionId: parseInt(viewElement.getAttribute('data-mention-id')),
                name: viewElement.getAttribute('data-name'),
                type: viewElement.getAttribute('data-type'),
                key: viewElement.getAttribute('data-key')
            };

            const value = viewElement.getAttribute('data-value') || "";

            const modelElement = conversionApi.writer.createElement('blockplaceholder', { mention, value });

            if (!conversionApi.safeInsert(modelElement, data.modelCursor)) {
                return;
            }

            conversionApi.consumable.consume(modelElement, { name: true });

            for (const child of modelElement.getChildren()) {
                console.log('child', child);
                conversionApi.consumable.consume(child, { name: true });
            }

            conversionApi.updateConversionResult(modelElement, data);

        }

        const divplaceholderupcaster = (viewElement, { writer }) => {
            const mention = {
                guidance: viewElement.getAttribute('title') || "",
                id: viewElement.getAttribute('data-id'),
                mentionId: parseInt(viewElement.getAttribute('data-mention-id')),
                name: viewElement.getAttribute('data-name'),
                type: viewElement.getAttribute('data-type'),
                key: viewElement.getAttribute('data-key')
            };

            const value = viewElement.getAttribute('data-value') || "";

            //console.log('upcast', mention);

            return writer.createElement('blockplaceholder', { mention, value });
        }

        conversion.for('upcast').elementToElement({
            view: {
                name: 'div',
                classes: ['placeholder']
            },
            model: divplaceholderupcaster,
            converterPriority: 'high'
        });             
      
        conversion.for('editingDowncast').elementToElement({
            model: 'blockplaceholder',
            view: (modelItem, { writer }) => {
                const widgetElement = createPlaceholderView(modelItem, writer);

                // Enable widget handling on a placeholder element inside the editing view.
                return toWidget(widgetElement, writer);
            },
            triggerBy: {
                attributes: ['value']
            }
        });

        conversion.for('dataDowncast').elementToElement({
            model: 'blockplaceholder',
            view: (modelItem, { writer }) => createPlaceholderView(modelItem, writer)
        });

        function createPlaceholderView(modelItem, writer) {
            const mention = modelItem.getAttribute('mention');
            const value = modelItem.getAttribute('value');
            //console.log('downcast', mention, value);

            const placeholderView = writer.createContainerElement(mention.type === "list" ? "ul" : "div", {
                class: 'placeholder',
                'data-id': mention.id,
                'data-mention-id': mention.mentionId,
                'data-type': mention.type,
                'data-name': mention.name,
                'data-key': mention.key,
                'data-value': value || "",
                title: mention.guidance || "",
                style: value ? "white-space:pre;" : "border-radius: 2px; background-color: #F0F8FF; height:50px;white-space:pre;"
            },
            {
                isAllowedInsideAttributeElement: false
            });            

            if (mention.type === "textarea") {
                const innerText = writer.createText(value || mention.name);
                writer.insert(writer.createPositionAt(placeholderView, 0), innerText);
            }

            if (mention.type == "list") {               
                let entries;
                if (value && value.startsWith("[")) {
                    entries = JSON.parse(value);
                }

                if (!entries || entries.length === 0)
                    entries = ["entry 1", "entry 2"];

                entries.forEach(e => {
                    const el = writer.createRawElement('li', { class: 'placeholder' }, function (domElement) { domElement.innerText = e });
                    writer.insert(writer.createPositionAt(placeholderView, 'end'), el);
                });                
            }

            return placeholderView;
        }
    }
}