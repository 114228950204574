import {format, isValid} from "date-fns";

export const tableDateTime = (dateTimeString) => format(new Date(dateTimeString), "dd/MM/yyyy HH:mm");
export const toIsoString = (dateTimeString) => format(new Date(dateTimeString), "yyyy-MM-dd'T'HH:mm:ss");
export const shortTableDateTime = (dateTimeString) => format(new Date(dateTimeString), "dd/MM/yyyy");
export const formatShortMonthDate = (dateString) => {
    if (!isValid(new Date(dateString)))
        return "N/A";
    return format(new Date(dateString), "dd MMM yyyy");
}
export const abbreviatedDateTime = (dateTimeString) =>
  format(new Date(dateTimeString), "d MMM yyyy")
