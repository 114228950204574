import appConfigurationClient from "../appConfigurationClient";
import axios from "../plugins/axios";

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;
//const isProductionEnvironment = process.env.REACT_APP_ENV === "production" || process.env.REACT_APP_ENV === "preprod";

const getFeatureFlag = async (featureName, isApi) => {
    //if (!isProductionEnvironment) return true;
    if (!featureName || !featureName.trim().length) {
        console.error("Feature flag name is required");
        return false;
    }
    try {

        if (isApi) {
            const result = await axios.get(`${apiUrl}/feature/${featureName}`);

            return result.data;
        }
        else {
            const result = await appConfigurationClient.getConfigurationSetting({ key: `.appconfig.featureflag/${featureName}`, label: "ui" });

            if (result && typeof result === "object") {
                return JSON.parse(result.value).enabled;
            }
        }

        return false;
    } catch (e) {
        console.error(e);
        return false;
    }
};

const featureFlagService = {
    getFeatureFlag,
};

export default featureFlagService;
