import React, {createContext, useContext, useMemo, useState} from "react";
import {uploadStatus} from "../constants/uploadStatus";
import {useSelector} from "react-redux";

const FileUploadContext = createContext({});

const FileUploadProvider = ({ children }) => {
    const { cases, currentCaseId: caseId } = useSelector(state => state.caseReducer);
    const { reply, miscActivity } = cases[caseId] || {};
    
    const replyUploadInProgress = useMemo(() => {
        return reply?.attachments?.some(x => x.status === uploadStatus.IN_PROGRESS || x.status === uploadStatus.QUEUED)
    }, [reply?.attachments]);

    const miscActivityUploadInProgress = useMemo(() => {
        return miscActivity?.attachments?.some(x => x.status === uploadStatus.IN_PROGRESS || x.status === uploadStatus.QUEUED)
    }, [miscActivity?.attachments]);
    
    const uploadInProgress = useMemo(() => replyUploadInProgress || miscActivityUploadInProgress, [replyUploadInProgress, miscActivityUploadInProgress]);
    
    
    const value = {
        uploadInProgress,
    }
    
    return <FileUploadContext.Provider value={value} children={children}></FileUploadContext.Provider>
}

const useFileUpload = () => useContext(FileUploadContext);

export { FileUploadProvider, useFileUpload }