import Plugin from '@ckeditor/ckeditor5-core/src/plugin';

import { toWidget, viewToModelPositionOutsideModelElement } from '@ckeditor/ckeditor5-widget/src/utils';
import Widget from '@ckeditor/ckeditor5-widget/src/widget';

export default class EvidenceLink extends Plugin {

    static get requires() {
        return [Widget];
    }

    init() {
        //console.log('EvidenceLink#init() got called');

        this._defineSchema();
        this._defineConverters();        

        this.editor.editing.mapper.on(
            'viewToModelPosition',
            viewToModelPositionOutsideModelElement(this.editor.model, viewElement => viewElement.hasClass('evidencelink'))
        );        
    }

    _defineSchema() {
        const schema = this.editor.model.schema;

        schema.register('evidencelink', {
            // Allow wherever text is allowed:
            allowWhere: '$text',

            // The placeholder will act as an inline node:
            isInline: true,

            // The inline widget is self-contained so it cannot be split by the caret and can be selected:
            isObject: true,

            // The inline widget can have the same attributes as text (for example linkHref, bold).
            allowAttributesOf: '$text',

            // The placeholder can have many types, like date, name, surname, etc:
            allowAttributes: ['url']
        });
    }

    _defineConverters() {
        const conversion = this.editor.conversion;

        const converter = (viewElement, { writer: modelWriter }) => {
            
            const url = viewElement.getAttribute('href');

            //console.log('upcast', url);

            return modelWriter.createElement('evidencelink', { url });
        }

        conversion.for('upcast').elementToElement({
            view: {
                name: 'a',
                classes: ['evidencelink']
            },
            model: converter
        });        

        conversion.for('editingDowncast').elementToElement({
            model: 'evidencelink',
            view: (modelItem, { writer: viewWriter }) => {
                const widgetElement = createEvidenceLinkView(modelItem, viewWriter, true);

                // Enable widget handling on a placeholder element inside the editing view.
                return toWidget(widgetElement, viewWriter);
            }
        });

        conversion.for('dataDowncast').elementToElement({
            model: 'evidencelink',
            view: (modelItem, { writer: viewWriter }) => createEvidenceLinkView(modelItem, viewWriter, false)
        });

        function createEvidenceLinkView(modelItem, viewWriter, editing) {
            const url = modelItem.getAttribute('url');
            //console.log('downcast', url);

            const evidenceLinkView = viewWriter.createContainerElement('a', {
                class: 'evidencelink',
                target: "_blank",
                style: "padding: 4px 8px;margin-bottom: 4px;margin-right: 4px;background: #467fd7;border-radius: 2px;border-color: #d0d0d0; color:white;",
                href: editing ? null : url
            }, {
                isAllowedInsideAttributeElement: false
            });
            
            const innerText = viewWriter.createText("Capture Evidence");
            viewWriter.insert(viewWriter.createPositionAt(evidenceLinkView, 0), innerText);

            return evidenceLinkView;
        }
    }
}