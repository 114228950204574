import React from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, makeStyles} from "@material-ui/core";
import { red } from "@material-ui/core/colors";

const useStyles = makeStyles(() => ({
  deleteButton: {
    color: red[400],
  },
}));

const DeleteCaseTimeEventGroupsDialog = ({ open, onClose, onConfirm }) => {
  const classes = useStyles();
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Discard held time?</DialogTitle>
      <DialogContent>
        <DialogContentText>This action cannot be undone</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button className={classes.deleteButton} onClick={onConfirm}>Delete</Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteCaseTimeEventGroupsDialog;