import React, { useMemo } from "react";
import {
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    Button, CircularProgress,
} from "@material-ui/core";
import { miscActivityGroups } from "../../constants/miscActivityGroupConstants";
import MiscActivityEditor from "../MiscActivityEditor";
import { useSelector } from "react-redux";

const CurrentMiscActivityDialog = ({ open, onClose, onConfirm }) => {
    const { cases, currentCaseId: caseId } = useSelector(state => state.caseReducer);
    const c = cases[caseId];
    const activity = c?.miscActivity?.current;
    const saving = activity?.saving;

    const isSaveDisabled = useMemo(() => {
        return !activity.miscActivityTypeId || !activity.description || !activity.notes;
    }, [activity.description, activity.miscActivityTypeId, activity.notes]);

    const handleCloseDialog = async (event, reason) => {
        if (reason === "backdropClick") {
            return;
        }
        onClose();
    };

    return (
        <Dialog open={open} onClose={handleCloseDialog}>
            <DialogTitle>Create Misc Activity</DialogTitle>
            <DialogContent>
                <MiscActivityEditor group={miscActivityGroups.CURRENT} />
            </DialogContent>
            <DialogActions>
                {saving ? <CircularProgress /> : <>
                    <Button onClick={onClose}>Cancel</Button>
                    <Button color="primary" variant="contained" onClick={onConfirm} disabled={isSaveDisabled}>Save</Button>
                </>}
            </DialogActions>
        </Dialog>
    );
}

export default CurrentMiscActivityDialog;