import React, { useState } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import Helmet from 'react-helmet';
import TemplateEditor from "../components/TemplateEditor";

import {
    CardContent,
    Grid,
    Breadcrumbs as MuiBreadcrumbs,
    Card as MuiCard,
    Divider as MuiDivider,
    Typography,
    Link,
    Button,
    Box
} from "@material-ui/core";

import { spacing } from "@material-ui/system";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const MyTemplates = ({ userState }) => {

    const [template, setTemplate] = useState(null);

    const newTemplate = () => {
        let t = {
            name: '**New Template**',
            htmlContent: ''
        };

        setTemplate(t);
    };

    const onSave = p => {
        setTemplate(p);
    }

    if (!userState.userProfile.templates)
        return null;

    return (       

        <React.Fragment>
            <Helmet title="My Templates" />
            <Box display="flex">
                <Box flexGrow={1}><Breadcrumbs aria-label="Breadcrumb" mt={2}>
                    <Link color="textPrimary" variant="h3" underline={template ? "hover" : "none"} onClick={() => { setTemplate(null); }}>Template Manager</Link>                  
                    {template && <Typography>{template.name}</Typography>}
                </Breadcrumbs></Box>
                <Box>{!template && <Button color="primary" variant="contained" onClick={newTemplate}>New Template</Button>}</Box>        
                
            </Box>         
            
            <Divider my={6} />

            {!template &&
                <Grid container spacing={6}>
                {Object.values(userState.userProfile.templates).map(t =>
                        <Grid key={t.templateId} item xs={12} lg={6} xl={3}>
                            <Card mb={6}>
                            <CardContent onClick={() => setTemplate(t)} style={{ cursor: 'pointer' }}>
                                    <Typography variant="h5">{t.name}</Typography>
                                </CardContent>
                            </Card>
                        </Grid>)}
                </Grid>
            }
            {template && <TemplateEditor template={template} onSave={onSave}/>}

        </React.Fragment>
    );
}

export default connect(state => ({ userState: state.userReducer }))(MyTemplates);
