const formatEmailSubject = (subject, caseId, isForward=false) => {
    if (!subject)
        return `Confidential - Case Number ${caseId}`;

    let newSubject = "";

    if (!subject.toLowerCase().includes("confidential"))
        newSubject = "Confidential - ";

    if (!subject.toLowerCase().includes(isForward ? "fw:" : "re:"))
        newSubject += isForward ? "FW: " : "RE: ";

    return newSubject += subject;
}

export default formatEmailSubject;