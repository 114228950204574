import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    DialogContentText,
    Typography,
    Grid,
    makeStyles,
    CircularProgress,
    Select,
    MenuItem,
    FormControl,
    IconButton,
    Button,
    TextField,
    Box,
} from "@material-ui/core";
import { Clear } from "@material-ui/icons";
import { emailIsValid } from "../utils/validators";
import useAddCaseExternalContact from "../hooks/mutations/useAddCaseExternalContact";
import useUpdateCaseExternalContact from "../hooks/mutations/useUpdateCaseExternalContact";
import useCaseExternalContactTypes from "../hooks/queries/useCaseExternalContactTypes";
import { setSnackAction } from "../redux/actions/snackActions";
import anonymousNumber from "../constants/anonymousNumber";

const useStyles = makeStyles((theme) => ({
    title: {
        textAlign: "center",
    },
    fullWidthField: {
        width: "100% ",
        paddingRight: theme.spacing(2),
        marginLeft: theme.spacing(3),
        marginBottom: theme.spacing(2),
    },
    notesField: {
        width: "100%",
        marginLeft: theme.spacing(3),
        marginBottom: theme.spacing(4),
        marginTop: theme.spacing(1),
    },
    avatar: {
        marginRight: theme.spacing(3),
    },
    saveButton: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    diaTitle: {
        flexGrow: 1,
    },
}));

const ExternalContactDialog = ({
    open,
    setOpen,
    caseId,
    activeContact,
    setActiveContact,
    existingContacts,
}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [confirmOpen, setConfirmOpen] = useState(false);

    const {
        data: contactTypes,
        isLoading: isContactTypesLoading,
        isError: isContactTypesError,
        error: contactTypesError,
    } = useCaseExternalContactTypes();

    const {
        mutate: addCaseExternalContact,
        isLoading: isAddCaseExternalContactLoading,
    } = useAddCaseExternalContact();
    const {
        mutate: updateCaseExternalContact,
        isLoading: isUpdateCaseExternalContactLoading,
    } = useUpdateCaseExternalContact();

    const handleClose = () => {
        setActiveContact({});
        setOpen(false);
    };

    const handleSave = (e) => {
        e.preventDefault();

        if (
            existingContacts
                .filter((e) => e.externalContactId !== activeContact.externalContactId)
                .some((c) => c.email === activeContact.email) ||
            (activeContact.phone !== undefined &&
                existingContacts
                    .filter((e) => e.externalContactId !== activeContact.externalContactId)
                    .some((c) => c.phone === activeContact.phone))
        ) {
            setConfirmOpen(true);
        } else {
            handleSubmit();
        }
    };

    const handleSubmit = () => {
        setConfirmOpen(false);
        let contactToSave = {
            ...activeContact,
            caseId,
            name: activeContact.firstName + " " + activeContact.lastName,
        };

        if (activeContact.externalContactId) {
            updateCaseExternalContact(
                { caseExternalContact: contactToSave },
                {
                    onSuccess: () => {
                        handleClose();
                        dispatch(setSnackAction("External contact details updated", "success"));
                    },
                }
            );
        } else {
            contactToSave.isEnabled = true;
            addCaseExternalContact(
                { caseExternalContact: contactToSave },
                {
                    onSuccess: () => {
                        handleClose();
                        dispatch(setSnackAction("External contact created", "success"));
                    },
                }
            );
        }
    };

    const handleCloseDialog = () => {
        setConfirmOpen(false);
    };

    const handlePhoneInputChange = async (e) => {
        const { name, value } = e.target;
        var validText = true;
        //only allow numbers, spaces or empty strings
        validText = /^[+]*(?=.*\d)[\d ]+$/.test(value);

        if (value === anonymousNumber)
            return false;

        if (validText || value === "") {
            setActiveContact({
                ...activeContact,
                [name]: value,
            });
        }
    };

    return (
        <>
            <Dialog open={open} onClose={handleClose} fullWidth>
                <DialogTitle>
                    <Box display="flex" alignItems="center">
                        <Typography
                            variant="h6"
                            component="div"
                            className={classes.diaTitle}
                        >
                            External Contact Detail
                        </Typography>
                        <IconButton
                            variant="contained"
                            onClick={() => handleClose()}
                        >
                            <Clear />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <form onSubmit={handleSave}>
                        <Grid container>
                            <Grid item xs={6}>
                                <TextField
                                    label="First Name"
                                    required
                                    variant="outlined"
                                    value={activeContact.firstName || ""}
                                    onChange={(e) =>
                                        setActiveContact({
                                            ...activeContact,
                                            firstName: e.target.value,
                                        })
                                    }
                                    className={classes.fullWidthField}
                                />
                                <TextField
                                    required
                                    label="Last Name"
                                    variant="outlined"
                                    value={activeContact.lastName || ""}
                                    onChange={(e) =>
                                        setActiveContact({
                                            ...activeContact,
                                            lastName: e.target.value,
                                        })
                                    }
                                    className={classes.fullWidthField}
                                />
                                <TextField
                                    variant="outlined"
                                    label="Organisation"
                                    value={activeContact.organisation || ""}
                                    onChange={(e) =>
                                        setActiveContact({
                                            ...activeContact,
                                            organisation: e.target.value,
                                        })
                                    }
                                    className={classes.fullWidthField}
                                />

                                {isContactTypesLoading && <CircularProgress />}
                                {isContactTypesError && (
                                    <Typography>
                                        {contactTypesError?.message ||
                                            "Failed to load contact types"}
                                    </Typography>
                                )}
                                <FormControl
                                    required
                                    className={classes.fullWidthField}
                                    variant="outlined"
                                >
                                    <Select
                                        value={activeContact.externalContactTypeId || "" }
                                        onChange={(e) =>
                                            setActiveContact({
                                                ...activeContact,
                                                externalContactTypeId: e.target.value,
                                            })
                                        }
                                        displayEmpty
                                        label="Contact Type"
                                    >
                                        <MenuItem value="" disabled>
                                            Contact Type
                                        </MenuItem>
                                        {contactTypes &&
                                            contactTypes.map((t) => (
                                                <MenuItem
                                                    value={t.id}
                                                    key={t.id}
                                                >
                                                    {t.name}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    onChange={handlePhoneInputChange}
                                    name="phone"
                                    variant="outlined"
                                    label="Phone"
                                    value={activeContact.phone || ""}
                                    className={classes.fullWidthField}
                                />
                                <TextField
                                    onChange={handlePhoneInputChange}
                                    name="mobile"
                                    variant="outlined"
                                    label="Mobile"
                                    value={activeContact.mobile || ""}
                                    className={classes.fullWidthField}
                                />
                                <TextField
                                    required
                                    error={
                                        activeContact.email &&
                                        !emailIsValid(activeContact.email)
                                    }
                                    helperText={
                                        activeContact.email &&
                                        !emailIsValid(activeContact.email)
                                            ? "Invalid email address"
                                            : ""
                                    }
                                    variant="outlined"
                                    label="Email"
                                    value={activeContact.email || ""}
                                    onChange={(e) =>
                                        setActiveContact({
                                            ...activeContact,
                                            email: e.target.value,
                                        })
                                    }
                                    className={classes.fullWidthField}
                                />
                                <TextField
                                    variant="outlined"
                                    label="Address"
                                    value={activeContact.address || ""}
                                    onChange={(e) =>
                                        setActiveContact({
                                            ...activeContact,
                                            address: e.target.value,
                                        })
                                    }
                                    className={classes.fullWidthField}
                                />
                            </Grid>
                            <TextField
                                multiline
                                variant="outlined"
                                label="Notes"
                                value={activeContact.notes || ""}
                                onChange={(e) =>
                                    setActiveContact({
                                        ...activeContact,
                                        notes: e.target.value,
                                    })
                                }
                                className={classes.notesField}
                            />

                            <Grid item xs={12} className={classes.saveButton}>
                                <Box display="flex" justifyContent="flex-end">
                                    {isAddCaseExternalContactLoading ||
                                    isUpdateCaseExternalContactLoading ? (
                                        <CircularProgress />
                                    ) : (
                                        <Button
                                            type="submit"
                                            color="primary"
                                            variant="contained"
                                        >
                                            Save External Contact
                                        </Button>
                                    )}
                                </Box>
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
            </Dialog>
            <Dialog
                open={confirmOpen}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Potential duplicate contact
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Please confirm that you wish to create this contact? A
                        contact with the same email/phone number already exists
                        against this case.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ExternalContactDialog;
