import { useMutation } from "react-query";
import queryKeys from "../../constants/queryKeys";
import caseActionService from "../../services/caseActionService";
import reactQueryClient from "../../reactQueryClient";

const useUpdateCaseAction = () =>
    useMutation(
        ({ caseAction }) => 
            caseActionService.updateCaseAction(caseAction),
        {
            onMutate: async ({ caseAction }) => {
                const queryKey = [queryKeys.caseActions, caseAction.caseId];

                await reactQueryClient.cancelQueries(queryKey);
                
                const previousCaseActions = reactQueryClient.getQueryData(queryKey);

                reactQueryClient.setQueryData(queryKey, oldActions => oldActions && oldActions.map(a => a.id == caseAction.id ? caseAction : a));

                return { queryKey, previousCaseActions }
            },
            onError: (_err, _newAction, context) => {
                reactQueryClient.setQueryData(context.queryKey, context.previousCaseActions);
            },
            onSettled: (_newAction, _err, _variables, context) => {
                reactQueryClient.invalidateQueries(context.queryKey);
                reactQueryClient.refetchQueries([queryKeys.caseActionsDashboard], { stale: true });
            }
        }
    );

export default useUpdateCaseAction;