import React, { useState, useCallback, useEffect } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import Helmet from "react-helmet";
import axios from "../plugins/axios";
import {
    Typography,
    Tabs,
    Tab as MuiTab,
    Box,
    Divider as MuiDivider,
    Card as MuiCard,
    CardContent,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";

import AdminList from "../components/AdminList";
import AdminToolbar from "../components/AdminToolbar";

import { setClaimTypes, setVenues } from "../redux/actions/configActions";
import { setSnackAction } from "../redux/actions/snackActions";
import TabPanel from "../components/TabPanel";

const Divider = styled(MuiDivider)(spacing);

const Card = styled(MuiCard)(spacing);

const Tab = styled(MuiTab)`
    min-width: 0;
`;

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

const ETAdmin = ({ dispatch, configState }) => {
    const [mode, setMode] = useState("view");
    const [saving, setSaving] = useState(false);
    const [currentTab, setCurrentTab] = useState(0);

    const [claimTypes, setClaimTypesLocal] = useState([]);
    const [venues, setVenuesLocal] = useState([]);

    const [claimTypesToSave, setClaimTypesToSave] = useState([]);
    const [venuesToSave, setVenuesToSave] = useState([]);

    const [selectedClaimType, setSelectedClaimType] = useState(null);
    const [selectedVenue, setSelectedVenue] = useState(null);

    const [newClaimType, setNewClaimType] = useState("");
    const [newVenue, setNewVenue] = useState("");

    const getClaimTypes = useCallback(() => {
        setClaimTypesLocal(
            Object.values(configState.claimTypes).map((ct) => ({
                ...ct,
                toEdit: false,
                edited: false,
                toDelete: false,
                isNew: false,
            }))
        );
    }, [configState.claimTypes]);

    const getVenues = useCallback(() => {
        setVenuesLocal(
            Object.values(configState.venues).map((v) => ({
                ...v,
                toEdit: false,
                edited: false,
                toDelete: false,
                isNew: false,
            }))
        );
    }, [configState.venues]);

    const handleSetAdd = (setSelectedItem) => {
        setMode("add");
        setSelectedItem("");
    };

    const handleAdd = (setItems, newItem, itemId, setNewItem) => {
        if (newItem === "") {
            setMode("view");
            return;
        }
        setItems((prevItems) => [
            ...prevItems,
            {
                [itemId]: prevItems.length
                    ? Math.max.apply(
                          Math,
                          prevItems.map((i) => i[itemId])
                      ) + 1
                    : 1,
                name: newItem,
                disabled: false,
                toEdit: false,
                edited: false,
                toDelete: false,
                isNew: true,
            },
        ]);
        setMode("view");
        setNewItem("");
    };

    const handleSetEdit = (items, itemId, setItems, selectedItem) => {
        setMode("edit");
        setItems((prevItems) =>
            prevItems.map((i) =>
                i[itemId] === selectedItem ? { ...i, toEdit: true } : i
            )
        );
    };

    const handleDelete = (setItems, itemId, selectedItem, setSelectedItem) => {
        setItems((prevItems) =>
            prevItems
                .filter((i) => !(i[itemId] === selectedItem && i.isNew))
                .map((i) =>
                    i[itemId] === selectedItem ? { ...i, toDelete: true } : i
                )
        );
        setSelectedItem(null);
    };

    const handleSave = async (
        items,
        setItemsToSave,
        itemId,
        addRoute,
        updateRoute,
        deleteRoute
    ) => {
        setSaving(true);
        let newItems = items;

        if (items.filter((i) => i.isNew).length) {
            let response = await axios.post(
                `${apiUrl}${addRoute}`,
                items.filter((i) => i.isNew).map((i) => i.name)
            );
            if (response.status === 200 && response.data) {
                newItems = [
                    ...newItems.filter((i) => !i.isNew),
                    ...response.data,
                ];
            }
        }

        if (items.filter((i) => i.edited && !i.isNew).length) {
            await axios.post(
                `${apiUrl}${updateRoute}`,
                items
                    .filter((i) => i.edited && !i.isNew)
                    .map((i) => ({ [itemId]: i[itemId], name: i.name }))
            );
        }

        if (items.filter((i) => i.toDelete).length) {
            let response = await axios.post(
                `${apiUrl}${deleteRoute}`,
                items.filter((i) => i.toDelete).map((i) => i[itemId])
            );
            if (response.status === 200) {
                if (response.data.length) {
                    newItems = newItems
                        .filter(
                            (i) =>
                                !(
                                    i.toDelete &&
                                    !response.data.includes(i[itemId])
                                )
                        )
                        .map((i) =>
                            response.data.includes(i[itemId])
                                ? { ...i, disabled: true }
                                : i
                        );
                } else {
                    newItems = newItems.filter((i) => !i.toDelete);
                }
            }
        }

        setItemsToSave(newItems);

        setSaving(false);
        setMode("view");
        setSelectedClaimType("");
        setSelectedVenue("");
        setNewClaimType("");
        setNewVenue("");
    };

    const handleTabChange = (e, newValue) => {
        setMode("view");
        setCurrentTab(newValue);
        setNewClaimType("");
        setNewVenue("");
        setClaimTypesLocal((prevClaims) =>
            prevClaims.map((ct) => ({ ...ct, toEdit: false }))
        );
        setVenuesLocal((prevVenues) =>
            prevVenues.map((v) => ({ ...v, toEdit: false }))
        );
    };

    const handleReset = (getItems, setSelectedItem) => {
        getItems();
        setSelectedItem(null);
        setMode("view");
    };

    useEffect(() => {
        if (claimTypesToSave.length) {
            dispatch(
                setClaimTypes(
                    claimTypesToSave.map((i) => ({
                        claimTypeId: i.claimTypeId,
                        name: i.name,
                        disabled: i.disabled,
                    }))
                )
            );
            dispatch(setSnackAction("Saved!", "success"));
        }
    }, [claimTypesToSave, dispatch]);

    useEffect(() => {
        if (venuesToSave.length) {
            dispatch(
                setVenues(
                    venuesToSave.map((i) => ({
                        venueId: i.venueId,
                        name: i.name,
                        disabled: i.disabled,
                    }))
                )
            );
            dispatch(setSnackAction("Saved!", "success"));
        }
    }, [venuesToSave, dispatch]);

    useEffect(() => {
        getClaimTypes();
    }, [getClaimTypes]);

    useEffect(() => {
        getVenues();
    }, [getVenues]);

    return (
        <React.Fragment>
            <Helmet title="Claim Types" />
            <Box display="flex" flexDirection="column">
                <Typography variant="h3" style={{ paddingBottom: "16px" }}>
                    Employment Tribunal
                </Typography>
                <Tabs value={currentTab} onChange={handleTabChange}>
                    <Tab label="Claim Types" />
                    <Tab label="Venues" />
                </Tabs>
            </Box>
            <Divider my={6} />
            <Card mb={6}>
                <CardContent
                    style={{ display: "flex", justifyContent: "center" }}
                >
                    <TabPanel value={currentTab} index={0}>
                        <Box
                            display="flex"
                            justifyContent="center"
                            flexDirection="column"
                            style={{ width: "300px" }}
                        >
                            <AdminList
                                items={claimTypes}
                                setItems={setClaimTypesLocal}
                                itemId="claimTypeId"
                                mode={mode}
                                saving={saving}
                                selectedItem={selectedClaimType}
                                setSelectedItem={setSelectedClaimType}
                                newItem={newClaimType}
                                setNewItem={setNewClaimType}
                                handleAdd={() =>
                                    handleAdd(
                                        setClaimTypesLocal,
                                        newClaimType,
                                        "claimTypeId",
                                        setNewClaimType
                                    )
                                }
                            />
                            <AdminToolbar
                                items={claimTypes}
                                itemId="claimTypeId"
                                handleSetAdd={() =>
                                    handleSetAdd(setSelectedClaimType)
                                }
                                handleSetEdit={() =>
                                    handleSetEdit(
                                        claimTypes,
                                        "claimTypeId",
                                        setClaimTypesLocal,
                                        selectedClaimType
                                    )
                                }
                                handleDelete={() =>
                                    handleDelete(
                                        setClaimTypesLocal,
                                        "claimTypeId",
                                        selectedClaimType,
                                        setSelectedClaimType
                                    )
                                }
                                handleSave={() =>
                                    handleSave(
                                        claimTypes,
                                        setClaimTypesToSave,
                                        "claimTypeId",
                                        `/addclaimtypes`,
                                        "/updateclaimtypes",
                                        "/deleteclaimtypes"
                                    )
                                }
                                selectedItem={selectedClaimType}
                                handleReset={() =>
                                    handleReset(
                                        getClaimTypes,
                                        setSelectedClaimType
                                    )
                                }
                                changed={
                                    mode !== "view" ||
                                    claimTypes.filter(
                                        (ct) => ct.isNew || ct.toDelete
                                    ).length
                                }
                            />
                        </Box>
                    </TabPanel>
                    <TabPanel value={currentTab} index={1}>
                        <Box
                            display="flex"
                            justifyContent="center"
                            flexDirection="column"
                            style={{ width: "300px" }}
                        >
                            <AdminList
                                items={venues}
                                setItems={setVenuesLocal}
                                itemId="venueId"
                                mode={mode}
                                saving={saving}
                                selectedItem={selectedVenue}
                                setSelectedItem={setSelectedVenue}
                                newItem={newVenue}
                                setNewItem={setNewVenue}
                                handleAdd={() =>
                                    handleAdd(
                                        setVenuesLocal,
                                        newVenue,
                                        "venueId",
                                        setNewVenue
                                    )
                                }
                            />
                            <AdminToolbar
                                items={venues}
                                itemId="venueId"
                                handleSetAdd={() =>
                                    handleSetAdd(setSelectedVenue)
                                }
                                handleSetEdit={() =>
                                    handleSetEdit(
                                        venues,
                                        "venueId",
                                        setVenuesLocal,
                                        selectedVenue
                                    )
                                }
                                handleDelete={() =>
                                    handleDelete(
                                        setVenuesLocal,
                                        "venueId",
                                        selectedVenue,
                                        setSelectedVenue
                                    )
                                }
                                handleSave={() =>
                                    handleSave(
                                        venues,
                                        setVenuesToSave,
                                        "venueId",
                                        "/addvenues",
                                        "/updatevenues",
                                        "/deletevenues"
                                    )
                                }
                                selectedItem={selectedVenue}
                                handleReset={() =>
                                    handleReset(getVenues, setSelectedVenue)
                                }
                                changed={
                                    mode !== "view" ||
                                    venues.filter((v) => v.isNew || v.toDelete)
                                        .length
                                }
                            />
                        </Box>
                    </TabPanel>
                </CardContent>
            </Card>
        </React.Fragment>
    );
};

export default connect((state) => ({ configState: state.configReducer }))(
    ETAdmin
);
