import React from 'react'
import { Select, FormControl, MenuItem, InputLabel, FormHelperText } from '@material-ui/core';

export default function DDList(props) {


    const { name, label, value, error=null, onChange, options, disabled } = props;

    return (

        <FormControl
            variant="outlined"
            {...(error && { error: true })}>
            <InputLabel>{label}</InputLabel>
            <Select
                label={label}
                name={name}
                value={value}
                disabled={disabled}
                onChange={onChange}>
                <MenuItem value="">None</MenuItem>

                {
                    options.map(
                        item => (<MenuItem key={item.externalId} value={item.externalId}>{item.title}</MenuItem>)
                    )
                }
            </Select>
            {error && <FormHelperText>{error}</FormHelperText>}

        </FormControl>

        )
}