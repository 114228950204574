import * as types from '../constants';

export function setSnackAction(message, severity) {
  return {
    type: types.SET_SNACK,
      snack: {
          message: message,
          severity: severity
      }
  }
}

export function removeSnack() {
    return {
        type: types.REMOVE_SNACK       
    }
}


