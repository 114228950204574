import { useQuery } from "react-query";
import queryKeys from "../../constants/queryKeys";
import productService from "../../services/productService";

const useRecommendationPickLists = () =>
    useQuery([queryKeys.recommendationPickLists],
        () => productService.getRecommendationPickLists(),
        {
            staleTime: Infinity,
        }
    );

export default useRecommendationPickLists;

