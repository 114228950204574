import axios from "../plugins/axios";

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

const getProductRecommendations = async (accountId, adviceTypeId, caseId) => {
    try {
        const response = await axios.get(`${apiUrl}/product/recommendations/${accountId}/${adviceTypeId}/${caseId}`);
        return response.data
    } catch (e) {
        return Promise.reject(e?.message || "Could not fetch product recommendations.");
    }
};

const getRecommendationPickLists = async () => {
    try {
        const response = await axios.get(`${apiUrl}/product/outcomepicklists`);
        return response.data
    } catch (e) {
        return Promise.reject(e?.message || "Could not fetch product recommendations.");
    }
};

const saveRecommendation = async (recommendation) => {
    try {
        const response = await axios.post(`${apiUrl}/product/saverecommendation`, recommendation);
        return response.data
    } catch (e) {
        return Promise.reject(e?.message || "Could not save product recommendation.");
    }
}

const productService = {
    getProductRecommendations,
    getRecommendationPickLists,
    saveRecommendation,
};

export default productService;
