import { CircularProgress, Dialog, DialogContent, DialogTitle, Typography, makeStyles } from "@material-ui/core";
import { useEmailDraft } from "../../contexts/emailDraftContext";
import React from "react";
import { getInitials } from "../../helpers/nameHelpers";
import { AccountCircle } from "@material-ui/icons";
import { formatRelative } from "date-fns";
import { enGB } from 'date-fns/esm/locale';
import avatarStyles from "../../theme/avatars";

const useStyles = makeStyles((theme) => ({
    row: {
        display: "flex",
        columnGap: theme.spacing(2),
        "&:hover": {
            cursor: "pointer",
        },
    },
    detailRow: {
        display: "flex",
        alignItems: "center",
        columnGap: theme.spacing(4),
    },
    details: {
        width: "100%",
        overflow: "hidden",
    },
    dateDetail: {
        marginLeft: "auto",
        minWidth: "fit-content",
        fontSize: "0.8rem",
    },
    initials: {
        width: theme.spacing(9),
        height: theme.spacing(9),
        borderRadius: "50%",
        backgroundColor: theme.palette.secondary.main,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: theme.palette.primary.contrastText,
    },
    bodyDetail: {
        color: theme.palette.text.secondary,
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
    nameDetailElipsisOverflow: {
        fontWeight: "semibold",
        fontSize: "1rem",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
    list: {
        display: "flex",
        flexDirection: "column",
        rowGap: theme.spacing(6),
    },
    spinner: {
        marginLeft: theme.spacing(2),
    },
}));

export function DraftEmailsSummaryDialog({ open, onClose }) {
    const classes = useStyles();
    const avatarClasses = avatarStyles();

    const { draftEmailsSummary, fetchDraftEmail } = useEmailDraft();

    const dialogContent = () => {
        if (draftEmailsSummary.isLoading) {
            return <CircularProgress />;
        }

        return (
            <>
                <DialogTitle>Draft Emails ({draftEmailsSummary?.data?.data?.length})</DialogTitle>
                <DialogContent dividers className={classes.list}>
                    {draftEmailsSummary?.data?.data &&
                        draftEmailsSummary?.data?.data?.map((draftEmail) => (
                            <div className={classes.row} key={draftEmail.id} onClick={() => fetchDraftEmail(draftEmail.id)} data-cy="draft_email_row">
                                <div>
                                    {draftEmail.recipients && draftEmail.recipients.length > 0 ? (
                                        <div className={classes.initials}>{getInitials(draftEmail.recipients[0])}</div>
                                    ) : (
                                        <AccountCircle color="primary" className={avatarClasses.medium} />
                                    )}
                                </div>
                                <div className={classes.details}>
                                    <div className={classes.detailRow}>
                                        <Typography variant="body2" className={classes.nameDetailElipsisOverflow}>
                                            {draftEmail.recipients && draftEmail.recipients.length > 0
                                                ? draftEmail.recipients.map((recipient) => recipient).join(", ")
                                                : "(no recipient)"}
                                        </Typography>
                                        <Typography className={classes.dateDetail} variant="body2">
                                            {formatRelative(new Date(draftEmail.lastModified), new Date(), { locale:  enGB })}
                                        </Typography>
                                    </div>
                                    <Typography variant="body2">
                                        {draftEmail.subject && draftEmail.subject.trim().length > 0 ? draftEmail.subject : "(no subject)"}
                                    </Typography>
                                    <Typography variant="body2" className={classes.bodyDetail} data-cy="draft_email_preview_body">
                                        {draftEmail.bodyPreview && draftEmail.bodyPreview.trim().length > 0 ? draftEmail.bodyPreview : "..."}
                                    </Typography>
                                </div>
                            </div>
                        ))}
                </DialogContent>
            </>
        );
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
            {dialogContent()}
        </Dialog>
    );
}
