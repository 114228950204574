import React from "react";
import { Grid, IconButton, ListItemText, makeStyles } from "@material-ui/core";
import { Edit, Fullscreen } from "@material-ui/icons";
import { format } from "date-fns";
import { grey } from "@material-ui/core/colors";

const useStyles = makeStyles(() => ({
    container: (props) => ({
        backgroundColor: props.index % 2 === 0 ? grey[200] : "",
    }),
}));

const CaseAction = ({ action, index, handleClickEdit }) => {
    const classes = useStyles({ index });

    return (
        <Grid container className={classes.container} spacing={3} alignItems="center" justifyContent="space-between">
            <Grid item xs={8}>
                <ListItemText
                    primary={action.type + " - " + action.summary}
                    secondary={format(new Date(action.due), "dd/MM/yyyy HH:mm") + " - " + action.assignedTo}
                />
            </Grid>
            <Grid item xs={4}>
                <IconButton onClick={() => handleClickEdit(action)} data-cy="edit_case_action">
                    <Edit />
                </IconButton>
                <IconButton onClick={() => handleClickEdit(action)}>
                    <Fullscreen />
                </IconButton>
            </Grid>
        </Grid>
    );
};

export default CaseAction;
