import { useQuery } from "react-query";
import queryKeys from "../../constants/queryKeys";
import caseActionService from "../../services/caseActionService";

const useCaseActions = ({ caseId }) =>
    useQuery(
        [queryKeys.caseActions, caseId],
        () => caseActionService.getOpenAndETCaseActions(caseId),
        {
            enabled: !!caseId
        }
    )

export default useCaseActions;