import React, { useState, useEffect, useRef } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from "react-dnd-html5-backend";

import precedentService from "../../services/precedentService"

import {
    Box,
    CardContent,
    Typography,
    Tooltip,
    makeStyles,
} from "@material-ui/core";

import {
    HighlightOff as DeleteIcon,
    FolderOpenRounded as FolderIcon,
} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
    folderBackground: {
        backgroundColor: "#ffffed",
        marginBottom: "10px",
        cursor: "pointer",
    },
    folderText: {
        backgroundColor: "#ffffed",
    },
    cursorPointer: {
        cursor: "pointer",
        marginRight: "1px",
    },
}));

const ItemType = 'folder';

const Item = ({
    id,
    index,
    moveItem,
    pf,
    sector,
    configState,
    setPrecedentToRemove,
    setConfirmRemovalDialogOpen,
    setPrecedentFolder,
    isSuperUser
}) => {
    const [{ isDragging }, ref] = useDrag({
        type: ItemType,
        item: { id, index },
        canDrag: isSuperUser,
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const [, drop] = useDrop({
        accept: ItemType,
        hover(item) {
            if (item.index !== index) {
                moveItem(item.index, index);
                item.index = index;
            }
        },
    });
    const opacity = isDragging ? 0 : 1

    const classes = useStyles();
    const handleFolderClick = (f) => {
        setPrecedentFolder(f);
    };
    const handleRemovalConfirmationDialog = (p) => {
        setPrecedentToRemove(p);
        setConfirmRemovalDialogOpen(true);
    };
    const sectorPrecedents = () => {
        if (sector === null) return [];
        return Object.values(configState.precedents)
            .filter((p) => p.sectorId === sector.sectorId)
            .sort((s1, s2) => s1.sortIndex - s2.sortIndex);
    };
    const folderPrecedents = (f) =>
        sectorPrecedents()
            .filter(
                (p) => p.precedentFolderId == f.precedentFolderId && !p.disabled
            )
            .sort((s1, s2) => s1.sortIndex - s2.sortIndex);

    return (
        <Box
            ref={(node) => ref(drop(node))}
            style={{ opacity }}
            className={classes.folderBackground}
            display="flex"
            flexGrow={1}
        >
        <CardContent >
            <FolderIcon onClick={() => handleFolderClick(pf)} />
            {isSuperUser && folderPrecedents(pf).length === 0 && (
                <Tooltip
                    title="Remove Empty Folder"
                    aria-label="add"
                >
                    <DeleteIcon
                        className={classes.cursorPointer}
                        onClick={() => handleRemovalConfirmationDialog(pf)}
                    />
                </Tooltip>
            )}
            <Typography
                className={classes.folderText}
                onClick={() => handleFolderClick(pf)}
                variant="h5"
            >
                {folderPrecedents(pf).length > 0 ? pf.name + " (" + folderPrecedents(pf).length + ")" : pf.name}
            </Typography>
            </CardContent>
        </Box>
    );
};

const PrecedentFolders = ({
    configState,
    caseType,
    precedentRec,
    precedentFolder,
    sector,
    setPrecedentToRemove,
    setConfirmRemovalDialogOpen,
    setPrecedentFolder,
    setPrecedentFolders,
    isSuperUser,
}) => {
    const [items, setItems] = useState([])

    const timerRef = useRef(null)
    const reordered = useRef(false);

    const uniqueFolders = (ct) =>
        Object.values(configState.precedentFolders).filter(
            (p) =>
                p.caseTypeId === ct.caseTypeId &&
                p.sectorId === sector.sectorId &&
                p.disabled == false
        )

    const moveItem = async (fromIndex, toIndex) => {
        const updatedItems = [...items]
        const [movedItem] = updatedItems.splice(fromIndex, 1)
        updatedItems.splice(toIndex, 0, movedItem)
        setItems(updatedItems)
        
        clearTimeout(timerRef.current);
        timerRef.current = setTimeout(() => {
            updateItems(updatedItems)
            reordered.current = true
        }, 1000)
    }

    const updateItems = async (updatedItems) => {
        try {
            await precedentService.updatePrecedentFolderOrder(updatedItems)
        } catch (error) {
            console.error(error)
        }
        finally {
            fetchItems()
        }
    }

    const fetchItems = async () => {
        try {
            const folderResponse = await precedentService.getPrecedentFolders()
            setPrecedentFolders(folderResponse)
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        if (caseType && !precedentRec && !precedentFolder && !reordered.current) {
            setItems(uniqueFolders(caseType).map((folder) => folder))
        }
        if (reordered.current)
            reordered.current = false
    }, [configState.precedentFolders])

    return (
        <DndProvider backend={HTML5Backend}>
            {items.map((item, index) => (
                <Item
                    isSuperUser={isSuperUser}
                    key={item.precedentFolderId}
                    index={index}
                    id={item.precedentFolderId}
                    pf={item}
                    sector={sector}
                    configState={configState}
                    setPrecedentToRemove={setPrecedentToRemove}
                    setConfirmRemovalDialogOpen={setConfirmRemovalDialogOpen}
                    setPrecedentFolder={setPrecedentFolder}
                    moveItem={moveItem}
                />
            ))}
        </DndProvider>
    );
};

export default PrecedentFolders;