import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import axios from "../plugins/axios";
import {
    Box,
    Typography,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Divider as MuiDivider,
    Card,
    CardContent,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { spacing } from "@material-ui/system";
import styled from "styled-components";

import AdminList from "../components/AdminList";
import AdminToolbar from "../components/AdminToolbar";

import { setStageTasks } from "../redux/actions/configActions";
import { setSnackAction } from "../redux/actions/snackActions";

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

const formStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(3),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const Divider = styled(MuiDivider)(spacing);

const StageTasks = ({ configState, dispatch }) => {
    const formClasses = formStyles();

    const [caseTypeId, setCaseTypeId] = useState("");
    const [caseStageId, setCaseStageId] = useState("");
    const [stageTasks, setStageTasksLocal] = useState(null);
    const [selectedTask, setSelectedTask] = useState("");
    const [newTask, setNewTask] = useState("");
    const [mode, setMode] = useState("view");
    const [saving, setSaving] = useState(false);
    const [tasksToSave, setTasksToSave] = useState(null);

    const getStageTasks = useCallback(() => {
        caseTypeId &&
            caseStageId &&
            setStageTasksLocal(
                configState.caseTypes[caseTypeId].stages
                    .filter((cs) => cs.caseStageId === caseStageId)[0]
                    .stageTasks.map((t) => ({
                        ...t,
                        name: t.description,
                        toDelete: false,
                        edited: false,
                    }))
            );
    }, [caseTypeId, caseStageId, configState.caseTypes]);

    const handleSetAdd = () => {
        setMode("add");
        setSelectedTask("");
    };

    const handleSetEdit = () => {
        setMode("edit");
        setStageTasksLocal((prevTasks) =>
            prevTasks.map((t) =>
                t.stageTaskId === selectedTask ? { ...t, toEdit: true } : t
            )
        );
    };

    const handleDelete = () => {
        setStageTasksLocal((prevTasks) =>
            prevTasks
                .filter((t) => !(t.stageTaskId === selectedTask && t.isNew))
                .map((t) =>
                    t.stageTaskId === selectedTask
                        ? { ...t, toDelete: true }
                        : t
                )
        );
    };

    const handleAdd = () => {
        if (newTask === "") {
            setMode("view");
            return;
        }
        setStageTasksLocal((prevTasks) => [
            ...prevTasks,
            {
                stageTaskId: stageTasks.length
                    ? Math.max.apply(
                          Math,
                          stageTasks.map((t) => t.stageTaskId)
                      ) + 1
                    : 1,
                name: newTask,
                description: newTask,
                disabled: false,
                isNew: true,
                edited: false,
                toDelete: false,
            },
        ]);
        setMode("view");
        setNewTask("");
    };

    const handleCaseTypeChange = (value) => {
        if (caseStageId) {
            setCaseStageId("");
        }
        setCaseTypeId(value);
    };

    const handleSave = async () => {
        setSaving(true);
        let newTasks = stageTasks;

        if (stageTasks.filter((t) => t.isNew).length) {
            let response = await axios.post(
                `${apiUrl}/addstagetasks/${caseStageId}`,
                stageTasks.filter((t) => t.isNew).map((t) => t.description)
            );
            if (response.status === 200 && response.data) {
                newTasks = [
                    ...newTasks.filter((t) => !t.isNew),
                    ...response.data.map((t) => ({
                        ...t,
                        name: t.description,
                    })),
                ];
            }
        }

        if (stageTasks.filter((t) => t.edited && !t.isNew).length) {
            await axios.post(
                `${apiUrl}/updatestagetasks`,
                stageTasks
                    .filter((t) => t.edited && !t.isNew)
                    .map((t) => ({
                        stageTaskId: t.stageTaskId,
                        description: t.name,
                    }))
            );
        }

        if (stageTasks.filter((t) => t.toDelete).length) {
            let response = await axios.post(
                `${apiUrl}/deletestagetasks`,
                stageTasks.filter((t) => t.toDelete).map((t) => t.stageTaskId)
            );
            if (response.status === 200) {
                newTasks = newTasks.map((t) =>
                    t.toDelete ? { ...t, disabled: true } : t
                );
            }
        }

        setSaving(false);
        setTasksToSave(newTasks);
        setSelectedTask("");
        setMode("view");
    };

    const handleReset = () => {
        getStageTasks();
        setNewTask("");
        setSelectedTask("");
        setMode("view");
    };

    useEffect(() => {
        getStageTasks();
    }, [getStageTasks]);

    useEffect(() => {
        if (tasksToSave) {
            dispatch(
                setStageTasks(
                    caseTypeId,
                    caseStageId,
                    tasksToSave.map((t) => ({
                        stageTaskId: t.stageTaskId,
                        description: t.name,
                        disabled: t.disabled,
                    }))
                )
            );
            dispatch(setSnackAction("Saved!", "success"));
            setTasksToSave(null);
        }
    }, [caseTypeId, tasksToSave, configState.caseTypes, caseStageId, dispatch]);

    return (
        <React.Fragment>
            <Helmet title="Stage Tasks" />
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
            >
                <Typography variant="h3">Stage Tasks</Typography>
                <Box display="flex" justifyContent="flex-end">
                    <FormControl
                        style={{ paddingBottom: "8px" }}
                        className={formClasses.formControl}
                    >
                        <InputLabel>Case Type</InputLabel>
                        <Select
                            value={caseTypeId}
                            onChange={(e) =>
                                handleCaseTypeChange(e.target.value)
                            }
                        >
                            {Object.values(configState.caseTypes).map((ct) => (
                                <MenuItem
                                    value={ct.caseTypeId}
                                    key={ct.caseTypeId}
                                >
                                    {ct.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl
                        style={{ paddingBottom: "8px" }}
                        className={formClasses.formControl}
                    >
                        <InputLabel>Case Stage</InputLabel>
                        <Select
                            value={caseStageId}
                            onChange={(e) => setCaseStageId(e.target.value)}
                        >
                            {caseTypeId &&
                                configState.caseTypes[caseTypeId].stages.map(
                                    (cs) => (
                                        <MenuItem
                                            value={cs.caseStageId}
                                            key={cs.caseStageId}
                                        >
                                            {cs.name}
                                        </MenuItem>
                                    )
                                )}
                        </Select>
                    </FormControl>
                </Box>
            </Box>
            <Divider my={6} />
            <Card mb={6}>
                <CardContent
                    style={{ display: "flex", justifyContent: "center" }}
                >
                    {stageTasks ? (
                        <Box
                            display="flex"
                            justifyContent="center"
                            flexDirection="column"
                            style={{ width: "300px" }}
                        >
                            <AdminList
                                items={stageTasks}
                                setItems={setStageTasksLocal}
                                itemId="stageTaskId"
                                mode={mode}
                                saving={saving}
                                selectedItem={selectedTask}
                                setSelectedItem={setSelectedTask}
                                newItem={newTask}
                                setNewItem={setNewTask}
                                handleAdd={handleAdd}
                            />
                            <AdminToolbar
                                items={stageTasks}
                                itemId="stageTaskId"
                                handleSetAdd={handleSetAdd}
                                handleSetEdit={handleSetEdit}
                                handleDelete={handleDelete}
                                handleSave={handleSave}
                                handleReset={handleReset}
                                changed={
                                    mode !== "view" ||
                                    stageTasks.filter(
                                        (t) => t.isNew || t.toDelete || t.edited
                                    ).length
                                }
                                selectedItem={selectedTask}
                            />
                        </Box>
                    ) : (
                        <div>Please select a Case Type and Stage</div>
                    )}
                </CardContent>
            </Card>
        </React.Fragment>
    );
};

export default connect((state) => ({ configState: state.configReducer }))(
    StageTasks
);
