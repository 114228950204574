import React from "react";
import { useQuery } from "react-query";
import queryKeys from "../../constants/queryKeys";
import caseEmployeeService from "../../services/caseEmployeeService";

const useCaseAccountEmployees = ({
    accountId,
    excludedEmployeeIds,
    getEmployees,
    limit,
    query
}) =>
    useQuery(
        [
            queryKeys.caseAccountEmployees,
            accountId,
            excludedEmployeeIds,
            limit,
            query
        ],
        () =>
            caseEmployeeService.getAccountEmployees({
                accountId,
                excludedEmployeeIds,
                limit,
                query
            }),
        {
            enabled: !!accountId && !!excludedEmployeeIds && !!getEmployees
        }
    );

export default useCaseAccountEmployees;
