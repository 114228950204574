import React, { useState, useEffect } from "react";
import {
    Box,
    Card,
    CardContent,
    CircularProgress,
    IconButton,
    makeStyles,
    Typography,
    TextField,
} from "@material-ui/core";
import {
    Edit as EditIcon,
    Save as SaveIcon,
    Replay as UndoIcon,
} from "@material-ui/icons";
import { Alert, Skeleton } from "@material-ui/lab";
import { viewModeStates } from "../../constants/viewModeStates";
import { formatPriceGBP, validateInputPrice } from "../../helpers/formatPrice";

const useStyles = makeStyles((theme) => ({
    boxSpacing: {
        display: "grid",
        gridTemplateColumns: "repeat(3, 1fr)",
        gap: theme.spacing(3),
    },
    rightHalf: {
        display: "flex",
        justifyContent: "end",
        width: "100%",
    },
    fullGrid: {
        gridColumn: "1 / span 3",
        display: "flex",
        alignItems: "center",
        borderBottom: `solid 2px ${theme.palette.secondary.main}`,
    },
    leftHalf: {
        minWidth: "max-content",
    },
    settlements: {
        marginTop: theme.spacing(3),
    },
}));

export const ETAwardsSettlementsCard = ({
    onSave,
    data = {},
    isLoading,
    isError,
    error,
}) => {
    const classes = useStyles();
    const [estimatedWorstCaseAward, setEstimatedWorstCaseAward] = useState(0);
    const [estimatedLikelyAward, setEstimatedLikelyAward] = useState(0);
    const [actualAward, setActualAward] = useState(0);
    const [recommendedSettlementRangeFrom, setRecommendedSettlementRangeFrom] =
        useState(0);
    const [recommendedSettlementRangeTo, setRecommendedSettlementRangeTo] =
        useState(0);
    const [actualSettlement, setActualSettlement] = useState(0);
    const [viewMode, setViewMode] = useState(viewModeStates.VIEWING);

    useEffect(() => {
        setEstimatedWorstCaseAward(data?.estimatedWorstCaseAward || 0);
        setEstimatedLikelyAward(data?.estimatedLikelyAward || 0);
        setActualAward(data?.actualAward || 0);
        setRecommendedSettlementRangeFrom(
            data?.recommendedSettlementRangeFrom || 0
        );
        setRecommendedSettlementRangeTo(
            data?.recommendedSettlementRangeTo || 0
        );
        setActualSettlement(data?.actualSettlement || 0);
    }, [data]);

    const handleSave = () => {
        const changes = {
            estimatedWorstCaseAward,
            estimatedLikelyAward,
            actualAward,
            recommendedSettlementRangeFrom,
            recommendedSettlementRangeTo,
            actualSettlement,
        };

        onSave(changes);
        setViewMode(viewModeStates.VIEWING);
    };

    const handleFeeChange = (price, inputValue, onFeeChange) => {
        const validatePrice = validateInputPrice(inputValue);

        if (validatePrice) onFeeChange(inputValue)
        else if (!validatePrice && price < 10) onFeeChange(0)
        else {
            onFeeChange(price)
        }
    }

    return (
        <Card>
            <CardContent>
                <div className={classes.boxSpacing}>
                    <div className={classes.fullGrid}>
                        <Typography className={classes.leftHalf} variant="h6">
                            Awards & Settlements
                        </Typography>
                        <div className={classes.rightHalf}></div>
                        {viewMode === viewModeStates.VIEWING && (
                            <IconButton
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setViewMode(viewModeStates.EDITING);
                                }}
                            >
                                <EditIcon />
                            </IconButton>
                        )}
                        {viewMode === viewModeStates.EDITING && (
                            <>
                                <IconButton
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setViewMode(viewModeStates.SAVING);
                                        handleSave();
                                    }}
                                >
                                    <SaveIcon />
                                </IconButton>
                                <IconButton
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setViewMode(viewModeStates.VIEWING);
                                    }}
                                >
                                    <UndoIcon />
                                </IconButton>
                            </>
                        )}
                        {viewMode === viewModeStates.SAVING && (
                            <CircularProgress size={20} />
                        )}
                    </div>
                    {(isLoading || viewMode === viewModeStates.SAVING) && (
                        <>
                            <Skeleton height={40} variant="rect" />
                            <Skeleton height={40} variant="rect" />
                            <Skeleton height={40} variant="rect" />
                            <Skeleton height={40} variant="rect" />
                            <Skeleton height={40} variant="rect" />
                            <Skeleton height={40} variant="rect" />
                        </>
                    )}
                    {isError && (
                        <Box
                            display="flex"
                            justifyContent="center"
                            width="100%"
                        >
                            <Alert variant="outlined" severity="error">
                                {error?.message ||
                                    "Unable to load Awards and Settlements details"}
                            </Alert>
                        </Box>
                    )}
                    <>
                        {viewMode === viewModeStates.VIEWING && (
                            <>
                                <div>
                                    <Typography variant="subtitle2">
                                        Estimated Worst Case Award
                                    </Typography>
                                    <Typography>
                                        {formatPriceGBP(
                                            data?.estimatedWorstCaseAward
                                        )}
                                    </Typography>
                                </div>
                                <div>
                                    <Typography variant="subtitle2">
                                        Estimated Likely Award
                                    </Typography>
                                    <Typography>
                                        {formatPriceGBP(
                                            data?.estimatedLikelyAward
                                        )}
                                    </Typography>
                                </div>
                                <div>
                                    <Typography variant="subtitle2">
                                        Actual Award
                                    </Typography>
                                    <Typography>
                                        {formatPriceGBP(data?.actualAward)}
                                    </Typography>
                                </div>

                                <div className={classes.settlements}>
                                    <Typography variant="subtitle2">
                                        Recommended Settlement range from
                                    </Typography>
                                    <Typography>
                                        {formatPriceGBP(
                                            data?.recommendedSettlementRangeFrom
                                        )}
                                    </Typography>
                                </div>
                                <div className={classes.settlements}>
                                    <Typography variant="subtitle2">
                                        Recommended Settlement range to
                                    </Typography>
                                    <Typography>
                                        {formatPriceGBP(
                                            data?.recommendedSettlementRangeTo
                                        )}
                                    </Typography>
                                </div>
                                <div className={classes.settlements}>
                                    <Typography variant="subtitle2">
                                        Actual Settlement
                                    </Typography>
                                    <Typography>
                                        {formatPriceGBP(data?.actualSettlement)}
                                    </Typography>
                                </div>
                            </>
                        )}
                        {viewMode === viewModeStates.EDITING && (
                            <>
                                <TextField
                                    label="Estimated Worst Case Award"
                                    onChange={(e) => handleFeeChange(estimatedWorstCaseAward, e.target.value, setEstimatedWorstCaseAward)}
                                    placeholder="Estimated Worst Case Award"
                                    type="number"
                                    value={estimatedWorstCaseAward}
                                    variant="outlined"
                                />
                                <TextField
                                    label="Estimated Likely Award"
                                    onChange={(e) => handleFeeChange(estimatedLikelyAward, e.target.value, setEstimatedLikelyAward)}
                                    placeholder="Estimated Likely Award"
                                    type="number"
                                    value={estimatedLikelyAward}
                                    variant="outlined"
                                />
                                <TextField
                                    label="Actual Award"
                                    onChange={(e) => handleFeeChange(actualAward, e.target.value, setActualAward)}
                                    placeholder="Actual Award"
                                    type="number"
                                    value={actualAward}
                                    variant="outlined"
                                />
                                <TextField
                                    label="Recommended Settlement range from"
                                    onChange={(e) => handleFeeChange(recommendedSettlementRangeFrom, e.target.value, setRecommendedSettlementRangeFrom)}
                                    placeholder="Recommended Settlement range from"
                                    type="number"
                                    value={recommendedSettlementRangeFrom}
                                    variant="outlined"
                                />
                                <TextField
                                    label="Recommended Settlement range to"
                                    onChange={(e) => handleFeeChange(recommendedSettlementRangeTo, e.target.value, setRecommendedSettlementRangeTo)}
                                    placeholder="Recommended Settlement range to"
                                    type="number"
                                    value={recommendedSettlementRangeTo}
                                    variant="outlined"
                                />
                                <TextField
                                    label="Actual Settlement"
                                    onChange={(e) => handleFeeChange(actualSettlement, e.target.value, setActualSettlement)}
                                    placeholder="Actual Settlement"
                                    type="number"
                                    value={actualSettlement}
                                    variant="outlined"
                                />
                            </>
                        )}
                    </>
                </div>
            </CardContent>
        </Card>
    );
};

export default ETAwardsSettlementsCard;
