export const baseScopes = `openid profile offline_access`;

export const silentTokenRequest = {
  scopes: [],
  forceRefresh: true,
};

export const redirectTokenRequest = {
  scopes: [],
};

export const msalConfig = {
  cache: {
    cacheLocation: "localStorage",
  },
  auth: {
    clientId: '9dd0fc26-a702-440a-aead-36690ac4b63d',
    redirectUri: window.location.origin,
    postLogoutRedirectUri: window.location.origin,
    navigateToLoginRequestUrl: false,
    authority: "https://login.microsoftonline.com/common",
    knownAuthorities: ["https://login.microsoftonline.com/common"],
  },
};
