import React, { useState, useCallback, useEffect } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import axios from "../plugins/axios";
import { setPrecedent } from "../redux/actions/configActions";
import MyCKEditor from "../ckeditor/MyCKEditor";
import PrecedentDialog from "./PrecedentDialog";

import {
    Box,
    Button as MuiIconButton,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Typography,
    TextField,
} from "@material-ui/core";

import {
    VisibilityTwoTone as PreviewIcon,
    Save as SaveIcon,
} from "@material-ui/icons";

import { grey } from "@material-ui/core/colors";
import userRoles from "../constants/userRoles";
import { useAuth } from "../contexts/authContext";

const IconButton = styled(MuiIconButton)`
    padding: 2px;
    margin-top: -2px;
    margin-left: 2px;
    min-width: 0;
    color: ${grey[700]};
`;

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

const PrecedentEditor = ({ precedent, dispatch, onSave, configState }) => {
    const { hasRole } = useAuth();
    const [precedentInfo, setPrecedentInfo] = useState(precedent);
    const [saving, setSaving] = useState(false);
    const [precedentId, setPrecedentId] = useState(null);
    const [editor, setEditor] = useState();
    const [editorHtml, setEditorHtml] = useState("");

    const getPrecedentInfo = useCallback(async () => {
        var response = await axios.get(
            `${apiUrl}/getprecedent/${precedent.precedentId}`
        );
        dispatch(setPrecedent(response.data));
        setPrecedentInfo({
            ...response.data,
            partnershipId: response.data.partnershipId,
        });
    }, [precedent.precedentId, dispatch]);

    useEffect(() => {
        let html = "";

        if (precedentInfo.htmlContent) {
            html = precedentInfo.htmlContent;
        }

        if (editor) editor.setData(html);
    }, [editor, precedentInfo.htmlContent]);

    //useEffect(() => { console.log('html change', editorHtml); }, [editorHtml])

    const setPrecedentName = (e) => {
        setPrecedentInfo({ ...precedentInfo, name: e.target.value });
    };

    const setPartnershipId = (e) => {
        setPrecedentInfo({ ...precedentInfo, partnershipId: e.target.value });
    };

    useEffect(() => {
        if (!precedentInfo.precedentId) {
            var newPrecedent = {
                currentVersion: 0,
                name: "",
                caseTypeId: precedent.caseTypeId,
                sectorId: precedent.sectorId,
                partnershipId: precedent.partnershipId,
                precedentFolderId: precedent.precedentFolderId,
            };

            setPrecedentInfo(newPrecedent);
        } else if (!precedentInfo.htmlContent) {
            getPrecedentInfo();
        }
    }, [
        getPrecedentInfo,
        precedentInfo.htmlContent,
        precedentInfo.precedentId,
        precedent.caseTypeId,
        precedent.sectorId,
    ]);

    const save = async () => {
        let htmlContent = editor.getData();

        if (
            htmlContent === precedentInfo.htmlContent &&
            precedentInfo.name === precedent.name &&
            precedentInfo.partnershipId === precedent.partnershipId
        )
            return;

        var saveData = {
            ...precedentInfo,
            htmlContent,
            partnershipId: precedentInfo.partnershipId || null,
        };

        setSaving(true);

        var response = await axios.post(`${apiUrl}/saveprecedent`, saveData);
        saveData.precedentId = response.data.precedentId;
        saveData.currentVersion += 1;
        onSave(saveData);

        dispatch(setPrecedent(saveData));
        setPrecedentInfo({
            ...saveData,
            partnershipId: saveData.partnershipId,
        });

        setSaving(false);
    };

    const handleClose = () => {
        setPrecedentId(null);
    };

    return (
        <React.Fragment>
            <Box display="flex" flexDirection="column">
                <Box
                    display="flex"
                    style={{ marginBottom: "10px" }}
                    alignItems="flex-end"
                    justifyContent="space-between"
                >
                    <Box display="flex" alignItems="flex-end">
                        <TextField
                            label="Precedent Name"
                            inputProps={{ maxLength: 80 }}
                            value={precedentInfo.name}
                            onChange={setPrecedentName}
                            m={2}
                            style={{ width: "300px" }}
                        />
                        <Typography
                            gutterBottom
                            style={{ marginLeft: "10px", marginRight: "10px" }}
                        >
                            {precedentInfo.currentVersion === 0
                                ? "Not saved"
                                : `Version ${precedentInfo.currentVersion} saved`}
                        </Typography>
                        {editor &&
                            (hasRole(userRoles.SUPER_USER) ||
                                hasRole(userRoles.LEGAL_MANAGER)) && (
                                <IconButton
                                    disabled={saving}
                                    disableRipple={false}
                                >
                                    <SaveIcon onClick={save} />
                                </IconButton>
                            )}
                        <IconButton>
                            <PreviewIcon
                                onClick={() =>
                                    setPrecedentId(precedentInfo.precedentId)
                                }
                            />
                        </IconButton>
                    </Box>
                    <FormControl>
                        <InputLabel shrink>Partnership</InputLabel>
                        <Select
                            value={precedentInfo.partnershipId || ""}
                            onChange={setPartnershipId}
                            displayEmpty
                        >
                            <MenuItem value="">None</MenuItem>
                            {Object.values(configState.partnerships).map(
                                (p) => (
                                    <MenuItem key={p.id} value={p.id}>
                                        {p.title}
                                    </MenuItem>
                                )
                            )}
                        </Select>
                    </FormControl>
                </Box>
                <PrecedentDialog
                    open={!!precedentId}
                    precedentId={precedentId}
                    onClose={handleClose}
                    showDownload
                />
                <MyCKEditor
                    initialData=""
                    setEditor={setEditor}
                    onChange={setEditorHtml}
                    onFocus={() => {}}
                    type="precedent"
                />
            </Box>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => ({ configState: state.configReducer });

export default connect(mapStateToProps)(PrecedentEditor);
