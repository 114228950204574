import axios from "../plugins/axios";

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

const addCaseTimeEventGroup = ({
    accountId,
    description,
    caseTimeEventIds,
    caseId,
    userId,
}) =>
    axios.post(`${apiUrl}/CaseTimeEventGroup`, {
        accountId,
        description,
        caseTimeEventIds,
        caseId,
        userId,
    });

const getForUserAndAccount = async ({ userId, accountId }) => {
    try {
        const response = await axios.get(
            `${apiUrl}/CaseTimeEventGroup/User/${userId}/Account/${accountId}`
        );
        return response.data;
    } catch (e) {
        console.error(e);
        return Promise.reject(
            e?.message || "Could not get CaseTimeEventGroups."
        );
    }
};

const discard = async ({ caseTimeEventGroupIds }) => {
    try {
        let queryString = "";
        caseTimeEventGroupIds.forEach((id) => (queryString += `ids=${id}&`));
        await axios.delete(`${apiUrl}/CaseTimeEventGroup?${queryString}`);
    } catch (e) {
        console.error(e);
        return Promise.reject(e?.message || "Could not discard held time.");
    }
};

const getForUser = async ({ userId }) => {
    try {
        const response = await axios.get(
            `${apiUrl}/CaseTimeEventGroup/User/${userId}`
        );
        return response.data;
    } catch (e) {
        console.error(e);
        return Promise.reject(
            e?.message || "Could not get CaseTimeEventGroups."
        );
    }
};

const caseTimeEventGroupService = {
    addCaseTimeEventGroup,
    getForUserAndAccount,
    discard,
    getForUser,
};

export default caseTimeEventGroupService;
