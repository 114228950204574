import React from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@material-ui/core";

const OpenClosedCaseDialog = ({ open, handleClose, handleConfirm, loading }) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Reopen closed case</DialogTitle>
      <DialogContent>
        <DialogContentText>Are you sure you want to reopen the case?</DialogContentText>
      </DialogContent>
      <DialogActions>
        {loading ? 
          <CircularProgress /> : 
          <>
            <Button color="primary" onClick={handleClose}>Cancel</Button>
            <Button color="secondary" onClick={handleConfirm}>Confirm</Button>
          </>}
      </DialogActions>
    </Dialog>
  );
}

export default OpenClosedCaseDialog;