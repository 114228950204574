import React from 'react';

import {
	Card as MuiCard,
	CardContent,
	Typography as MuiTypography,
	Box,
	Avatar
} from "@material-ui/core";
import styled from "styled-components";
import { spacing } from "@material-ui/system";

import avatarStyles from "../../theme/avatars";


const Card = styled(MuiCard)(spacing);
const Typography = styled(MuiTypography)(spacing);

const EWTeamCard = props => {
	const avatarClasses = avatarStyles();

	const propNames = ["cxCoordinator", "clientManager", "hsConsultant", "salesRep", "hrPrimaryAdvisor", "hrSecondaryAdvisor"];
	const titles = {
		cxCoordinator: "CX Coordinator",
		clientManager: "Client Manager",
		hsConsultant: "Health & Safety",
		salesRep: "Sales Rep",
		hrPrimaryAdvisor: "HR Consultant",
		hrSecondaryAdvisor: "HR Consultant"
    }

	return (
		<Card mb={3} style={{ height: '100%' }}>
			<CardContent>
				<Typography variant="h6" mb={6}>WorkNest Team</Typography>
				<Box display="flex" justifyContent="space-around" flexWrap="wrap">
					{propNames.filter(p => props[p]?.userId).map((p, index) =>
						<Box display="flex" flexDirection="column" alignItems="center" key={index} mt={3} mb={3} ml={6} mr={6}>
							<Avatar className={avatarClasses.xlarge} src={props[p].photo} alt={props[p].name} />
							<Typography variant="subtitle2">{titles[p]}</Typography>
							<Typography variant="caption">{props[p].name}</Typography>
						</Box>
					)}
				</Box>
			</CardContent>
		</Card>
	)
}

export default EWTeamCard;