import axios from "../plugins/axios";

const endpointUrl = `${process.env.REACT_APP_CASENEST_API_URL}/api/advicetypes`;

const fetchAll = async () => {
    try {
        const adviceTypes = await axios.get(endpointUrl);
        return adviceTypes.data;
    } catch (e) {
        return Promise.reject(e?.message || "Could not fetch advice types.");
    }
};

const adviceTypeService = {
    fetchAll,
};

export default adviceTypeService;
