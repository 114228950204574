import React, { useState, useEffect, useRef } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import moment from "moment";
import chipStyles from "../theme/chips";
import avatarStyles from "../theme/avatars";
import { grey, red } from "@material-ui/core/colors";
import { setSnackAction } from "../redux/actions/snackActions";
import { setCaseEmployeesAction } from "../redux/actions/caseActions";
import { bindActionCreators } from 'redux';
import { actionCreators } from '../redux/reducers/caseReducer';
import { useHistory } from "react-router-dom";
import {
    Avatar,
    Badge,
    Box,
    Button,
    CardContent,
    Card,
    Chip,
    CircularProgress,
    Tooltip,
    Typography,
    InputAdornment,
    Menu,
    MenuItem,
    makeStyles,
    TextField,
    IconButton,
} from "@material-ui/core";
import {
    Add as AddIcon,
    ArrowDropDown,
    Delete as DeleteIcon,
    Edit as EditIcon,
    MoreHoriz, Search
} from "@material-ui/icons";
import EmployeeFormDialog from "../components/EmployeeFormDialog";
import YouManageEmployeeSearchDialog from "../components/dialogs/YouManageEmployeeSearchDialog";
import caseEmployeeService from "../services/caseEmployeeService";
import useCaseAccountEmployees from "../hooks/queries/useCaseAccountEmployees";
import YouManageLogo from "../images/youManageLogo.svg";
import useEmployeeLinkedCases from "../hooks/queries/useEmployeeLinkedCases";

const useStyles = makeStyles(theme => ({
  addEmployeeButton: {
    backgroundColor: theme.palette.secondary.main,
    color: "white",
    textAlign: "center",
        "&:hover, &:focus": {
          backgroundColor: theme.palette.primary.main
        },
    },
    iconButton: {
        padding: "2px",
        marginTop: "-2px",
        marginLeft: "2px",
        minWidth: 0,
        color: grey[700],
    },
    badge: {
        whiteSpace: "nowrap",
        cursor: "default",
    },
    card: {
      marginBottom: theme.spacing(6),
    },
    menu: {
        "& ul": {
            padding: "0px",
      }
    },
    list: {
        margin: "0px", 
        padding: "0px",
    },
    linkedCasesText: {
        color: red[800],
    },
    menuItemAvatar: {
        marginRight: theme.spacing(1),
    },
    boldText: {
        fontWeight: "bold",
    },
    employeeSearchField: {
        padding: theme.spacing(3),
    },
    menuIcon: {
        color: grey[700],
        marginRight: theme.spacing(2), 
    },
    youManageLogo: {
        height: "60%",
        width: "60%",
    }
}));

const weeksOfService = (employmentStartDate) => {
    if (employmentStartDate == null)
        return 200;

    const today = new Date();
    const startDate = new Date(employmentStartDate);

    return (today - startDate) / (1000 * 60 * 60 * 24 * 7);
};

const formatLengthOfService = (employmentStartDate) => {

    if (!employmentStartDate)
        return "Unknown";

    const today = new Date();
    const startDate = new Date(employmentStartDate);

    const monthsOfService = moment(today, "months").diff(moment(startDate), "months");
    const years = Math.floor(monthsOfService / 12);
    const months = monthsOfService % 12;

    let str = "";

    if (years === 0) {
        if (months === 0) {
            return "< 1 month";
        }
        if (months === 1) {
            return "1 month"
        }
        return `${months} months`;
    }
    if (years > 0) {
        str = str + years + " year";
    }
    if (years > 1) {
        str += "s"
    }
    if (months > 0) {
        str = str + ", " + months + " month";
    }
    if (months > 1) {
        str += "s"
    }
    return str;
};

const EmployeeCard = ({ getCase }) => {
    const userState = useSelector(state => state.userReducer);
    const configState = useSelector(state => state.configReducer);
    const { currentCaseId: caseId, cases } = useSelector(state => state.caseReducer);
    const dispatch = useDispatch();

    const employees = cases[caseId].employees;
    const { accountId, youManageExternalId } = cases[caseId].account;

    const chipClasses = chipStyles();
    const avatarClasses = avatarStyles();
    const classes = useStyles();

    const [open, setOpen] = useState(false);
    const [anchorElCases, setAnchorElCases] = useState(null);
    const [anchorElEmployees, setAnchorElEmployees] = useState(null);
    const [existingEmployee, setExistingEmployee] = useState(null);
    const [youManageDialogOpen, setYouManageDialogOpen] = useState(false);
    const [selectedEmployeeId, setSelectedEmployeeId] = useState(null);
    const [query, setQuery] = useState(null);
    const [excludedEmployeeIds, setExcludedEmployeeIds] = useState(null);
    const [getEmployees, setGetEmployees] = useState(null);
    const [limit, setLimit] = useState(null);
    const searchEmployeesInput = useRef();
    const addEmployeeListLength = 5;

    // context menu 
    const [contextAnchorEl, setContextAnchorEl] = useState(null);
    const [contextEmployee, setContextEmployee] = useState(null);

    const history = useHistory();

    const { data, isError, error, isLoading } = useCaseAccountEmployees({
        accountId,
        excludedEmployeeIds,
        getEmployees,
        limit,
        query
    });
    if (isError) dispatch(setSnackAction(error.message || "Could not get Employees for Account", "error"));

    const {
        data: employeeLinkedCases,
        isLoading: isEmployeeLinkedCasesLoading,
        isError: isEmployeeLinkedCasesError,
        error: employeeLinkedCasesError,
    } = useEmployeeLinkedCases({ employeeId: selectedEmployeeId });

    const handleLinkedCases = (target, employeeId) => {
        setSelectedEmployeeId(employeeId);
        setAnchorElCases(target);
    }

    const handleCaseClick = async id => {
        if (id !== parseInt(caseId)) {
            await getCase(id, history);
        }
        setAnchorElCases(null);
    };

    const handleClickAddEmployee = async target => {
        // if (youManageExternalId) {
        //     setAnchorElEmployees(target);
        //     return;
        // }
        setAnchorElEmployees(target);
        setExcludedEmployeeIds(employees.map(e => e.employeeId));
        setGetEmployees(true);
        setLimit(addEmployeeListLength);
    };

    const handleSearchEmployee = async (e) => {
        setGetEmployees(true);
        setLimit(null);
        setQuery(searchEmployeesInput.current.value);
    };

    const stopPropagation = e => {
        switch (e.key) {
            case "ArrowDown":
            case "ArrowUp":
            case "Home":
            case "End":
                break;
            default:
                e.stopPropagation();
        }
    };

    const handleAddExistingEmployee = async employeeId => {
        try {
            await caseEmployeeService.addExistingEmployee({ caseId, employeeId });
            dispatch(setCaseEmployeesAction(caseId, [
                ...employees,
                ...data.accountEmployees
                    .filter(e => e.employeeId === employeeId)
                    .map(e => ({ ...e, linkedCasesCount: e.linkedCasesCount + 1 }))
            ]));
            dispatch(setSnackAction("Saved!", "success"));
            setAnchorElEmployees(null);
        }
        catch (error) {
            dispatch(setSnackAction(error.message || "Could not add Employee to Case", "error"));
        }
        finally {
            setExcludedEmployeeIds([...excludedEmployeeIds, employeeId]);
        }
    };

    const handleDeleteEmployee = async employeeId => {
        try {
            handleCloseContextMenu();
            await caseEmployeeService.deleteEmployee({ caseId, employeeId });
            dispatch(setCaseEmployeesAction(caseId, employees.filter(e => e.employeeId !== employeeId)));
            dispatch(setSnackAction("Saved!", "success"));
        }
        catch (error) {
            dispatch(setSnackAction(error.message || "Could not remove Employee from Case", "error"));
        }
        finally {
            if (excludedEmployeeIds) setExcludedEmployeeIds(excludedEmployeeIds.filter(id => id !== employeeId))
        }
    }

    const handleAddNewEmployee = () => {
        setAnchorElEmployees(null);
        setOpen(true);
    }

    const handleEmployeeSearchClose = () => {
        setAnchorElEmployees(null);
        setQuery(null);
    }

    const handleFormClose = () => {
        setOpen(false);
        setExistingEmployee(null);
    }

    const handleEditEmployee = e => {
        handleCloseContextMenu();
        setExistingEmployee(e);
        setOpen(true);
    }

    const handleOpenContextMenu = (e, event) => {
        setContextEmployee(e);
        setContextAnchorEl(event.currentTarget);
    }

    const handleCloseContextMenu = () => {
        setContextAnchorEl(null);
        setContextEmployee(null);
    }
    
    const handleClickSearchYouManageEmployee = () => {
        setYouManageDialogOpen(true);
        setAnchorElEmployees(null);
    }
    
    const handleAddYouManageEmployee = async (youManageEmployeeId) => {
        try {
            const employee = await caseEmployeeService.addYouManageEmployeeToCase({ youManageEmployeeId, caseId });
            dispatch(setCaseEmployeesAction(caseId, [...employees, employee]));
            dispatch(setSnackAction("Saved!", "success"));
        }
        catch (error) {
            dispatch(setSnackAction(error?.message || "Unable to add YouManage Employee to Case", "error"));
        }
    }

    useEffect(() => {
        if (isEmployeeLinkedCasesError)
            dispatch(
                setSnackAction(
                    `Could not get linked cases for Employee: ${employeeLinkedCasesError?.message}`,
                    "error"
                )
            );
    }, [isEmployeeLinkedCasesError]);
    
    const MapEmployee = e =>
        <Box key={e.employeeId} display="flex" flexDirection="column" alignItems="center" mt={5} mb={2} ml={2} mr={2}>
            <Box alignSelf="flex-end"><Button className={classes.iconButton} onClick={ev => handleOpenContextMenu(e, ev)}><MoreHoriz /></Button></Box>
            <Box>
                <Tooltip
                    title={e.protectedCharacteristics ?
                        <ul className={classes.list}>
                            {e.protectedCharacteristics.split(",").map((c, index) => <li key={index}>{c}</li>)}
                        </ul> :
                        ""}
                    placement="bottom"
                >
                    <Badge
                        overlap="rectangular"
                        className={classes.badge}
                        badgeContent={e.protectedCharacteristics ? "High Risk" : 0}
                        color="error"
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                    >
                        <Avatar alt={e.name} src={e.youManageEmployee ? YouManageLogo : e.photo} className={avatarClasses.large} />
                    </Badge>
                </Tooltip>
            </Box>
            <Box>
                <Typography variant="subtitle2">{`${e.youManageEmployee ? (e.youManageEmployee.firstName + " " + e.youManageEmployee.lastName) : e.name}${e.isLeaver ? ' (leaver)' : ''}`}</Typography>
            </Box>
            <Box display="flex" justifyContent="space-between">
                <Typography variant="body2">{e.position}</Typography>
            </Box>
            <Box mt={1}>
                <Tooltip title={`Length of service${e.isStartEstimate ? ' (estimate)' : ''}`}>
                    <Chip
                        className={weeksOfService(e.employmentStartDate) < 103 ? chipClasses.green : chipClasses.amber}
                        label={`${e.isStartEstimate ? '~' : ''}${formatLengthOfService(e.employmentStartDate)}`} />
                </Tooltip>
            </Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" mt={1}>
                <Box>
                    <Typography variant="body2">
                        Linked Cases: <span className={classes.linkedCasesText}>{e.linkedCasesCount}</span>
                    </Typography>
                </Box>
                {isEmployeeLinkedCasesLoading ?
                    <CircularProgress size="1em" /> :
                    <Button
                        className={classes.iconButton}
                        onClick={(event) => handleLinkedCases(event.currentTarget.parentNode, e.employeeId)}
                    >
                        <ArrowDropDown />
                    </Button>
                }
                <Menu
                    className={classes.menu}
                    anchorEl={anchorElCases}
                    open={!!anchorElCases}
                    onClose={() => setAnchorElCases(null)}
                >
                    {employeeLinkedCases && employeeLinkedCases.map(c =>
                        <MenuItem onClick={() => handleCaseClick(c.caseId)} key={c.caseId}>
                            <Avatar alt={userState.users[c.adviserId].name} className={classes.menuItemAvatar} src={userState.users[c.adviserId].photo} />
                            <Typography variant="body2"><span className={classes.boldText}>Case {c.caseId}</span> - {configState.caseTypes[c.caseTypeId].name}</Typography>
                        </MenuItem>
                    )}
                </Menu>
            </Box>
            {e.notes &&
                <Box display="flex" justifyContent="space-between">
                    <Typography variant="body2">Notes: {e.notes}</Typography>
                </Box>
            }
        </Box>

    return (
        <Card className={classes.card}>
            <CardContent>
                <Box display="flex">
                    <Box flexGrow={1}>
                        <Typography variant="h6" gutterBottom>Employees</Typography>
                    </Box>
                    <Box display="flex">
                        {isLoading ?
                            <CircularProgress size="1em" /> : 
                            <Button className={classes.iconButton} onClick={e => handleClickAddEmployee(e.currentTarget.parentNode)}><AddIcon /></Button>
                        }
                    </Box>
                </Box>
                <Menu
                    className={classes.menu}
                    anchorEl={anchorElEmployees}
                    keepMounted
                    open={Boolean(anchorElEmployees)}
                    onClose={handleEmployeeSearchClose}
                >
                    {/*{youManageExternalId ? */}
                    {/*    <MenuItem */}
                    {/*      onClick={handleClickSearchYouManageEmployee}*/}
                    {/*    >*/}
                    {/*        <Avatar src={YouManageLogo} alt="YouManage" className={classes.menuItemAvatar} />*/}
                    {/*        <Typography>Search for Employee</Typography>*/}
                    {/*    </MenuItem> : */}
                    {/*    [*/}
                    {data?.count > data?.accountEmployees.length &&
                        <TextField
                        key="employee-search-field"
                        className={classes.employeeSearchField}
                        fullWidth
                        onKeyDown={stopPropagation}
                        inputRef={searchEmployeesInput}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    {isLoading ?
                                        <CircularProgress size="1em" /> :
                                        <IconButton
                                            onClick={handleSearchEmployee}
                                        >
                                            <Search />
                                        </IconButton>
                                    }
                                </InputAdornment>
                            )
                        }}
                        />
                    }
                    {data?.count > 0 && data?.accountEmployees.length == 0 && 
                            <Box display="flex" width="100%" justifyContent="center" my={3} key="no-results-found">
                                <Typography>No results found</Typography>
                            </Box>}
                    {data?.accountEmployees.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)).map(e =>
                            <MenuItem onClick={() => handleAddExistingEmployee(e.employeeId)} key={e.employeeId}>
                                <Avatar alt={e.name} src={e.photo} className={classes.menuItemAvatar} />
                                <Typography variant="body2">{e.name} - {e.position}</Typography>
                            </MenuItem>
                          )}
                          <MenuItem className={classes.addEmployeeButton} onClick={handleAddNewEmployee} key="add-employee-button">
                              <Box display="flex" width="100%" justifyContent="center">
                                  <Typography variant="body2">Add Employee</Typography>
                              </Box>
                          </MenuItem>
                      {/*]}*/}
                </Menu>
                <Box display="flex" justifyContent="space-around" flexWrap="wrap">
                    {employees.map(e => MapEmployee(e))}
                </Box>
            </CardContent>
            <EmployeeFormDialog 
                open={open}
                existingEmployee={existingEmployee}
                handleFormClose={handleFormClose}
                setExcludedEmployeeIds={setExcludedEmployeeIds}
                excludedEmployeeIds={excludedEmployeeIds}
            />
            <Menu
                anchorEl={contextAnchorEl}
                keepMounted
                open={!!contextAnchorEl}
                onClose={handleCloseContextMenu}
            >
                <MenuItem 
                  onClick={() => handleEditEmployee(contextEmployee)}
                >
                    <EditIcon className={classes.menuIcon} />
                    Edit
                </MenuItem>
                <MenuItem 
                  onClick={() => handleDeleteEmployee(contextEmployee.employeeId)}
                >
                    <DeleteIcon className={classes.menuIcon} />
                    Delete
                </MenuItem>
            </Menu>
            <YouManageEmployeeSearchDialog 
              open={youManageDialogOpen}
              onClose={() => setYouManageDialogOpen(false)}
              handleAddExistingEmployee={handleAddYouManageEmployee}
              handleAddNewEmployee={handleAddNewEmployee}
            />
        </Card>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        ...bindActionCreators(actionCreators, dispatch),
    }
}

export default connect(null, mapDispatchToProps)(EmployeeCard);
