const caseTab = {
    DETAILS: 'Details',
    FILES: 'Files',
    DRAFT: 'Draft',
    HISTORY: 'History',
    ET: 'ET',
    REPLY: 'Reply',
    AI: 'AI'
}

export default caseTab;