import {useQuery} from "react-query";
import queryKeys from "../../constants/queryKeys";
import userService from "../../services/userService";

const useTeamUsersForManager = ({ managerId }) =>
    useQuery(
        [queryKeys.teamUsersForManager, managerId],
        () => userService.fetchTeamUsersForManager({ managerId }),
        {
            enabled: !!managerId,
        },
    );

export default useTeamUsersForManager;