import React, { useState, useEffect } from "react";
import FileViewer from "react-file-viewer";
import { connect } from "react-redux";

import {
    Dialog,
    DialogTitle,
    IconButton,
    Typography,
    Button,
    makeStyles,
} from "@material-ui/core";
import { CloudDownload } from "@material-ui/icons";

import { setSnackAction } from "../redux/actions/snackActions";
import { closeFileViewer } from "../redux/actions/userActions";

import { createSharepointFile } from "../services/caseService";
import { bindActionCreators } from "redux";
import { actionCreators } from "../redux/reducers/caseReducer";
import reactQueryClient from "../reactQueryClient";
import queryKeys from "../constants/queryKeys";
import documentService from "../services/documentService";

const useStyles = makeStyles(() => ({
    dialogTitle: {
        paddingTop: 0,
    },
    dialogTitleContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
}));

const supportedFileFormats = [
    "png",
    "jpeg",
    "jpg",
    "gif",
    "bmp",
    "pdf",
    "csv",
    "xlsx",
    "docx",
    "mp4",
    "webm",
    "mp3",
];

const downloadDocument = async (attachment, file) => {
    let response;
    if (attachment) {
        response = await documentService.retrieveDocumentPreviewPath({
            reference: attachment.reference,
            contentType: attachment.contentType,
        });
    }
    let blob;
    if (file) {
        blob = new Blob([file], { type: file.type });
    }

    let link = document.createElement("a");
    link.href = attachment ? response : window.URL.createObjectURL(blob);
    link.download = attachment ? attachment.filename : file.name;
    link.click();
};

const FileViewerModal = ({ closeFileViewer, setSnack, documentViewerState }) => {

    const { open, fileType, filePath, fileName, caseId, attachment, isEmailPreview, showCopyToLiveDocument, uploadFile } = documentViewerState;

    const classes = useStyles();
    const [preview, setPreview] = useState();
    const onError = () => {
        setSnack("There was an error loading the file", "error");
    };

    const getDraftFiles = () => {
        reactQueryClient.invalidateQueries([queryKeys.draftFiles, caseId]);
    };

    const isDocExtension = fileType.toLowerCase() === "doc";
    const isSupportedFileFormat = supportedFileFormats.includes(fileType.toLowerCase());

    useEffect(() => {
        if (isDocExtension || !uploadFile) {
            setPreview(undefined);
            return;
        }

        const objectUrl = URL.createObjectURL(uploadFile);
        setPreview(objectUrl);

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl);
    }, [fileType, isDocExtension, uploadFile]);

    const copyToSharepoint = async () => {
        const filePath = await createSharepointFile(
            attachment.reference,
            caseId,
            fileName
        );
        
        window.open(filePath, "_blank");
        getDraftFiles();
        closeFileViewer();
    };

    let caseString = "";
    if (caseId) caseString = " - Case " + caseId;

    return (
        <Dialog
            className="file-modal"
            onClose={closeFileViewer}
            open={open}
            fullWidth
            maxWidth={"lg"}
        >
            <DialogTitle className={classes.dialogTitle}>
                <div className={classes.dialogTitleContainer}>
                    <Typography variant="h6">
                        Viewing document - {fileName}
                        {caseString}
                    </Typography>
                    <div>
                        {
                            caseId != null &&
                            (fileType.toLowerCase() === "docx" || isDocExtension) &&
                            showCopyToLiveDocument &&
                            <Button
                                color="secondary"
                                variant="outlined"
                                onClick={copyToSharepoint}
                            >
                                Copy to Live Document
                            </Button>
                            }
                        {
                            (!isEmailPreview || !isSupportedFileFormat) &&
                            <IconButton
                                onClick={() => downloadDocument(attachment, uploadFile)}
                            >
                                <CloudDownload />
                            </IconButton>
                        }
                    </div>
                </div>
            </DialogTitle>
            {(preview ?? filePath) && (
                <FileViewer
                    fileType={fileType.toLowerCase()}
                    filePath={preview ?? filePath}
                    onError={onError}
                />
            )}
        </Dialog>
    );
};

const mapStateToProps = (state) => ({ documentViewerState: state.userReducer.documentViewer });

const mapDispatchToProps = (dispatch) => ({
    ...bindActionCreators(actionCreators, dispatch),
    setSnack: (message, severity) => dispatch(setSnackAction(message, severity)),
    closeFileViewer: () => dispatch(closeFileViewer()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FileViewerModal);
