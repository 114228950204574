import { useMutation } from "react-query";
import queryKeys from "../../constants/queryKeys";
import caseService from "../../services/caseService";
import reactQueryClient from "../../reactQueryClient";

const useUpdateETClaim = () =>
    useMutation(({ caseId, eTClaim }) => caseService.updateETClaim(caseId, eTClaim), {
        onMutate: async ({ eTClaim }) => {
            const queryKey = [queryKeys.eTClaims, eTClaim.eTCaseId];
            reactQueryClient.cancelQueries(queryKey);
            const previousDetails = reactQueryClient.getQueryData(queryKey);
            reactQueryClient.setQueryData(queryKey, (prev) => (
                prev.map(x => x.claimId === eTClaim.claimId ?
                  ({ ...x, claimTypeId: eTClaim.claimTypeId, isInsured: eTClaim.isInsured, successPercentage: eTClaim.successPercentage }) : x
                )
            ));
            return { previousDetails, queryKey };
        },
        onError: (err, newDetails, context) => {
            reactQueryClient.setQueryData(
                context.queryKey,
                context.previousDetails
            );
        },
        onSettled: (data, err, newDetails, context) => {
            reactQueryClient.invalidateQueries(context.queryKey);
        },
    });

export default useUpdateETClaim;
