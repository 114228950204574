import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
} from "react";
import * as msal from "@azure/msal-browser";
import {
    baseScopes,
    msalConfig,
    redirectTokenRequest,
    silentTokenRequest,
} from "../constants/authConfig";
import WorknestTokenError from "../errors/worknestTokenError";
import tokenService from "../services/tokenService";
import axios, { setAuthHeader } from "../plugins/axios";
import jwtDecode from "jwt-decode";
import LoginLoader from "../components/LoginLoader";
import { setUserProfile } from "../redux/actions/userActions";
import { useDispatch } from "react-redux";

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

const msalInstance = new msal.PublicClientApplication(msalConfig);

const MsalContext = createContext();

const workNestTenantId = process.env.REACT_APP_WORKNEST_TENANT_ID;

const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [userProfileId, setUserProfileId] = useState(null);
    const [ignoreBrowserBehaviour, setIgnoreBrowserBehaviour] = useState(false);
    const dispatch = useDispatch();

    const handleTokenResponse = useCallback(async (azureToken) => {
        try {
            const { data: worknestTokenResponse } = await fetchWorknestToken(
                azureToken
            );
            const userDetails = setUserDetailsFromTokenResponse(
                worknestTokenResponse
            );
            if (userDetails.tid !== workNestTenantId) {
                setIsLoading(false);
                return;
            }

            await fetchProfile();
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            throw error;
        }
    }, []);

    const fetchProfile = async () => {
        var response = await axios.get(`${apiUrl}/userprofile`);
        if (response.data !== "") {
            dispatch(setUserProfile(response.data));
            setUserProfileId(response.data.userId);
        } else {
            setUserProfileId(0);
        }
    };

    const setUserDetailsFromTokenResponse = (worknestToken) => {
        const decoded = jwtDecode(worknestToken.token);

        const details = {
            email: decoded.email,
            firstName: decoded.given_name,
            lastName: decoded.family_name,
            accounts: decoded.accounts,
            apps: decoded.apps,
            authMethod: decoded.authentication_method,
            userId: decoded.sub,
            roles: Array.isArray(decoded.roles) ? decoded.roles : [decoded.roles],
            tid: decoded.tid,
            adviceTypes: Array.isArray(decoded.advice_types)
                ? decoded.advice_types.map((x) => parseInt(x))
                : [parseInt(decoded.advice_types)],
        };

        const userObject = {
            details,
            token: worknestToken.token,
            expiresOn: worknestToken.expires,
        };

        setAuthHeader(worknestToken.token);
        setUser(userObject);

        return details;
    };

    const handleError = useCallback(
        async (error) => {
            setIsLoading(false);

            if (error instanceof WorknestTokenError) {
                // TODO: do something for worknest token error
                console.error(error.message);
                return;
            }

            if (error instanceof msal.InteractionRequiredAuthError) {
                try {
                    const redirectTokenResponse =
                        await msalInstance.acquireTokenRedirect(
                            redirectTokenRequest
                        );

                    await handleTokenResponse(redirectTokenResponse);
                } catch (error) {
                    console.error(error);
                }
            }
        },
        [handleTokenResponse]
    );

    const initialiseAzureSession = useCallback(async () => {
        try {
            const token = await msalInstance.handleRedirectPromise();
            if (token) {
                await handleTokenResponse(token);
                return;
            }

            // do they have an active AD session in cache?
            const accounts = msalInstance.getAllAccounts();
            if (!accounts.length) {
                setIsLoading(false);
                return;
            }

            // TODO: select from available accounts logic
            const account = accounts[0];
            const silentTokenResponse = await msalInstance.acquireTokenSilent({
                ...silentTokenRequest,
                account,
            });

            await handleTokenResponse(silentTokenResponse);
        } catch (e) {
            await handleError();
        }
    }, [handleTokenResponse, handleError]);

    useEffect(() => {
        async function startSession() {
            try {
                await initialiseAzureSession();
            } catch (e) {
                console.error(e);
            }
        }
        startSession();
    }, [initialiseAzureSession]);

    const fetchWorknestToken = async (azureToken) => {
        try {
            const worknestToken =
                await tokenService.microsoftWorkNestTokenExchange(azureToken);

            return worknestToken;
        } catch (error) {
            console.error(error);
            throw new WorknestTokenError(
                "Could not fetch Worknest token for user."
            );
        }
    };

    const loginViaMicrosoft = async () => {
        const requestObject = {
            scopes: [baseScopes],
        };

        await msalInstance.loginRedirect(requestObject);
    };

    const logout = () => {
        return msalInstance.logoutRedirect();
    };

    const hasRole = (role) => {
        return user?.details.roles && user.details.roles.includes(role);
    };

    if (isLoading) return <LoginLoader />;

    const contextValue = {
        loginViaMicrosoft,
        isLoading,
        user,
        logout,
        initialiseAzureSession,
        userProfileId,
        hasRole,
        ignoreBrowserBehaviour, 
        setIgnoreBrowserBehaviour
    };

    return (
        <MsalContext.Provider value={contextValue}>
            {children}
        </MsalContext.Provider>
    );
};

const useAuth = () => useContext(MsalContext);
export { AuthProvider, useAuth, MsalContext };
