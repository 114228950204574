import React, {useEffect, useRef, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Card,
    CardContent,
    Typography,
    TextField,
    Button,
    Box,
    CircularProgress,
    makeStyles,
} from '@material-ui/core';
import { Save as SaveIcon, Replay as UndoIcon } from '@material-ui/icons';
import { grey } from '@material-ui/core/colors';
import { saveInternalNote } from "../redux/actions/thunks";
import {
    setInternalNoteDescription,
    setInternalNoteFocus,
    setInternalNoteDescriptionFocus,
} from "../redux/actions/caseActions";
import {
    setSnackAction,
} from "../redux/actions/snackActions";
import { MAX_ACTIVITY_DESCRIPTION } from "../constants/lengthConstants"
import CaseTimeEventGroupsDialog from "../components/dialogs/CaseTimeEventGroupsDialog";
import useCaseTimeEventGroupsForUserAndAccount from "../hooks/queries/useCaseTimeEventGroupsForUserAndAccount";

const useStyles = makeStyles(theme => ({
    description: {
        width: '100%',
        marginBottom: theme.spacing(6),
        marginTop: theme.spacing(3),
    },
    note: {
        width: '100%',
    },
    card: {
        marginBottom: theme.spacing(6),
    },
    iconButton: {
        padding: '2px',
        marginTop: '-2px',
        marginLeft: '2px',
        minWidth: '0',
        color: grey[700],
    },
}));


const InternalNoteCard = ({
    setInternalNoteText,
    setInternalNoteCaseActivityId,
    setInternalNoteOpen,
}) => {
    const classes = useStyles();

    const dispatch = useDispatch();
    const { currentCaseId: caseId, cases } = useSelector(state => state.caseReducer);
    const { userProfile } = useSelector(state => state.userReducer);
    const c = cases[caseId];
    const contractId = c.caseSummary.contractId;

    const noteText = c.internalNote.text;
    const caseActivityId = c.internalNote.caseActivityId;
    const saving = c.internalNote.saving;
    const description = c.internalNote.description;

    const internalNoteRef = useRef(null);
    
    const [heldTimeOpen, setHeldTimeOpen] = useState(false);
    const { data: caseTimeEventGroups } = useCaseTimeEventGroupsForUserAndAccount({ userId: userProfile.userId, accountId: c.account.accountId });
    
    const handleSave = async (_e, selectedCaseTimeEventGroupIds=[]) => {
        if (!noteText || !description) {
            dispatch(setSnackAction("Note requires description and content", "warning"));
            return;
        }

        if (caseTimeEventGroups?.length && !heldTimeOpen && !caseActivityId) {
            setHeldTimeOpen(true);
            return;
        }
        setHeldTimeOpen(false);

        dispatch(saveInternalNote({
            caseActivityId,
            contractId,
            noteText,
            description,
            caseTimeEventGroupIds: selectedCaseTimeEventGroupIds,
        }));
    };

    const handleReset = () => {
        setInternalNoteText('', caseId);
        setInternalNoteOpen(false, caseId);
        setInternalNoteCaseActivityId(null, caseId);
        dispatch(setInternalNoteDescription({ description: '', caseId }));
    };

    useEffect(() => {
        internalNoteRef.current.focus();
    }, [])

    return (
        <Card className={classes.card}>
            <CardContent>
                <Box display='flex'>
                    <Box flexGrow={1}>
                        <Typography variant='h6' gutterBottom>
                            Internal Note
                        </Typography>
                    </Box>
                    <Box>
                        {saving ? (
                            <CircularProgress size='1em' />
                        ) : (
                                <Button
                                    className={classes.iconButton}
                                    onClick={handleSave}
                                    disabled={!(description && noteText)}
                                    data-cy="save_note"
                                >
                                    <SaveIcon />
                                </Button>
                        )}
                        <Button className={classes.iconButton} onClick={handleReset}>
                            <UndoIcon />
                        </Button>
                    </Box>
                </Box>
                <TextField
                    className={classes.description}
                    label="Description"
                    value={description}
                    onChange={e => dispatch(setInternalNoteDescription({ description: e.target.value, caseId }))}
                    onFocus={() => dispatch(setInternalNoteDescriptionFocus(true))}
                    onBlur={() => dispatch(setInternalNoteDescriptionFocus(false))}
                    inputRef={internalNoteRef}
                    inputProps={{ maxLength: MAX_ACTIVITY_DESCRIPTION }}
                    variant="outlined"
                    data-cy="note_description"
                />
                <TextField
                    className={classes.note}
                    label="Note"
                    value={noteText}
                    onChange={e => setInternalNoteText(e.target.value, caseId)}
                    multiline
                    onFocus={() => dispatch(setInternalNoteFocus(true))}
                    onBlur={() => dispatch(setInternalNoteFocus(false))}
                    variant="outlined"
                    data-cy="note_info"
                />
            </CardContent>
            <CaseTimeEventGroupsDialog
              open={heldTimeOpen}
              onClose={() => setHeldTimeOpen(false)}
              onConfirm={handleSave}
              confirmLabel="Save"
            />
        </Card>
    );
};

export default InternalNoteCard;
