import React from "react";
import { caseFileActions } from "../../../constants/caseFiles";
import documentService from "../../../services/documentService";
import reactQueryClient from "../../../reactQueryClient";
import queryKeys from "../../../constants/queryKeys";
import {
    Box,
    Button,
    Typography,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    DialogContentText,
    makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    diaTitle: {
        flexGrow: 1,
        display: "flex",
        alignItems: "center",
    },
}));

export default function FolderDeleteDialog({
    folderDeleteDialogOpen,
    setFolderDeleteDialogOpen,
    folderToRemove,  
    caseId,
}) {
    const classes = useStyles();

    const removeFolder = async () => {      

        try {
            await documentService.removeCaseFileFolder(folderToRemove.folderId);
        }
        finally {
            reactQueryClient.invalidateQueries([queryKeys.caseFilesTree, caseId]);
        }

        setFolderDeleteDialogOpen(false);
    };

    return (
        <Dialog
            open={folderDeleteDialogOpen}
            onClose={() => setFolderDeleteDialogOpen(false)}
            fullWidth
        >
            <DialogTitle>
                <Box display="flex" alignItems="center">
                    <Typography
                        variant="h6"
                        component="div"
                        className={classes.diaTitle}
                    >
                        Confirm removal of Folder
                    </Typography>
                </Box>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Please confirm that you want to remove this folder? Note
                    that once removed any files or folders contained within it will
                    be removed as well.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    onClick={() => setFolderDeleteDialogOpen(false)}
                >
                    Cancel
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={() => removeFolder()}
                >
                    Remove Folder
                </Button>
            </DialogActions>
        </Dialog>
    )
}