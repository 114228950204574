import React, { useState, } from "react";
import styled from "styled-components";
import Helmet from "react-helmet";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../redux/reducers/caseReducer";
import useCaseActivitiesForUser from "../hooks/queries/useCaseActivitiesForUser";
import Loader from "../components/Loader";
import avatarStyles from "../theme/avatars";
import { format } from "date-fns";
import caseActivityTypes from "../constants/caseActivityTypes";
import {
    Button as MuiIconButton,
    Box,
    CardContent,
    Card as MuiCard,
    FormControl,
    Grid,
    Input,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    Tooltip,
    Typography,
    Divider as MuiDivider,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Avatar,
    TablePagination,
} from "@material-ui/core";
import { Search as SearchIcon, FilterList as FilterIcon, Clear as ClearIcon } from "@material-ui/icons";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { spacing } from "@material-ui/system";
import { makeStyles } from "@material-ui/core/styles";
import useFilteredAdvisers from "../hooks/useFilteredAdvisers";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const IconButton = styled(MuiIconButton)`
    padding: 2px;
    margin-top: -2px;
    margin-left: 2px;
    min-width: 0;
`;

const formStyles = makeStyles((theme) => ({
    activityRow: {
        cursor: "pointer",
        height: "100%",
    },
    formControl: {
        margin: theme.spacing(3),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const Activities = ({
    configState,
    userState,
    getCase
}) => {
    const classes = formStyles();
    const avatarClasses = avatarStyles();

    const filteredAdvisers = useFilteredAdvisers();

    const [criteria, setCriteria] = useState({
        pageNum: 1,
        pageSize: 10,
    });
    const [accountText, setAccountText] = useState();
    const [caseId, setCaseId] = useState();

    const { data: activityData, isLoading } = useCaseActivitiesForUser(criteria);

    const getAdviser = (id) => userState.users[id] || {};
    const getCaseType = (id) => configState.caseTypes[id] || {};

    const history = useHistory();

    const handleCreatedFromDateChange = (date) => {
        if (date) {
            let fromDate = date;
            fromDate.setHours(0);
            fromDate.setMinutes(0);
            setCriteria({
                ...criteria,
                lastModifiedFromDate: fromDate,
                pageNum: 1,
                pageSize: 10
            });
        }
    };

    const handleCreatedToDateChange = (date) => {
        if (date) {
            let toDate = date;
            toDate.setHours(23);
            toDate.setMinutes(59);
            toDate.setSeconds(59);
            setCriteria({
                ...criteria,
                lastModifiedToDate: toDate,
                pageNum: 1
            });
        }
    };

    const handleEnterKeyPressAccount = (e) => {
        if (e.key === "Enter") {
            setCriteria({
                ...criteria,
                account: e.target.value,
                pageNum: 1
            });
        }
    };

    const handleEnterKeyPressCaseId = (e) => {
        if (e.key === "Enter") {
            setCriteria({
                ...criteria,
                caseId: e.target.value,
                pageNum: 1
            });
        }
    };

    const clearFilters = () => {
        setAccountText();
        setCaseId();
        setCriteria({
            pageNum: 1,
            pageSize: criteria.pageSize
        });
    };

    const setCriteriaField = (field, value) => {
        setCriteria({
            ...criteria,
            pageNum: 1,
            [field]: value
        });
    }

    const handleChangePage = (event, newPage) => {
        setCriteria({
            ...criteria,
            pageNum: newPage + 1
        });
    };

    const handleChangeRowsPerPage = (event) => {
        setCriteria({
            ...criteria,
            pageNum: 1,
            pageSize: parseInt(event.target.value)
        })
    };

    const handleClick = async (caseId) => {
        await getCase(caseId, history);
    };

    return (
        <React.Fragment>
            <Helmet title="My Case Activity" />
            <Grid container justifyContent="space-between">
                <Grid item mb={3}>
                    <Typography variant="h3" gutterBottom display="inline" data-cy="cases_h3">
                        My Case Activity
                    </Typography>
                </Grid>
                <Grid
                    item
                    style={{
                        display: "flex",
                        alignItems: "flex-end",
                        flexWrap: "wrap",
                    }}
                >
                    <FormControl style={{ paddingBottom: "8px" }} className={classes.formControl}>
                        <InputLabel>Case Id</InputLabel>
                        <Input
                            type="number"
                            value={caseId || ""}
                            onChange={(e) => setCaseId(e.target.value)}
                            onKeyPress={handleEnterKeyPressCaseId}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton onClick={() => setCriteriaField('caseId', caseId)}>
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                    <FormControl style={{ paddingBottom: "8px" }} className={classes.formControl}>
                        <InputLabel>Account Name</InputLabel>
                        <Input
                            type="text"
                            value={accountText || ""}
                            onChange={(e) => setAccountText(e.target.value)}
                            onKeyPress={handleEnterKeyPressAccount}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton onClick={() => setCriteriaField('account', accountText)}>
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <KeyboardDatePicker
                            format="dd/MM/yyyy"
                            margin="normal"
                            label="Modified Date from"
                            value={criteria.lastModifiedFromDate || null}
                            onChange={handleCreatedFromDateChange}
                            maxDate={new Date()}
                        />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <KeyboardDatePicker
                            format="dd/MM/yyyy"
                            margin="normal"
                            label="Modified Date to"
                            value={criteria.lastModifiedToDate || null}
                            onChange={handleCreatedToDateChange}
                            maxDate={new Date()}
                        />
                    </FormControl>
                    <FormControl style={{ paddingBottom: "8px" }} className={classes.formControl}>
                        <InputLabel>Activity Type</InputLabel>
                        <Select value={criteria.activityType || ""} onChange={e => setCriteriaField('activityType', e.target.value)}>
                            <MenuItem value="">Any</MenuItem>
                            {Object.values(caseActivityTypes)
                                .map((at) => (
                                    <MenuItem key={at} value={at.replaceAll(" ", "")}>
                                        {at}
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                    <FormControl style={{ paddingBottom: "8px" }} className={classes.formControl}>
                        <InputLabel>Adviser</InputLabel>
                        <Select value={criteria.adviserId || ""} onChange={(e) => setCriteriaField('adviserId', e.target.value)}>
                            <MenuItem value="">Any</MenuItem>
                            {filteredAdvisers
                                .filter((x) => x.isActive || x.userId === userState.userProfile.userId)
                                .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
                                .map((u) => (
                                    <MenuItem key={u.userId} value={u.userId}>
                                        {u.name}
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                    <FormControl style={{ paddingBottom: "8px" }} className={classes.formControl}>
                        <InputLabel>Case Type</InputLabel>
                        <Select value={criteria.caseTypeId || ""} onChange={e => setCriteriaField('caseTypeId', e.target.value)}>
                            <MenuItem value="">Any</MenuItem>
                            {Object.values(configState.caseTypes)
                                .filter((x) => x.isActive)
                                .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
                                .map((ct) => (
                                    <MenuItem key={ct.caseTypeId} value={ct.caseTypeId}>
                                        {ct.name}
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Box display="flex" width="100%" justifyContent="space-between" my={6}>
                <Tooltip title="Clear filters">
                    <IconButton style={{ width: "70px" }} onClick={clearFilters}>
                        <ClearIcon />
                        <FilterIcon />
                    </IconButton>
                </Tooltip>
            </Box>
            <Divider my={6} />
            <Card mb={6}>
                <CardContent>
                    {
                        !activityData?.activities || isLoading ? (
                            <Loader />
                        ) : (
                            <React.Fragment>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Case Id</TableCell>
                                            <TableCell>Account</TableCell>
                                            <TableCell>Date</TableCell>
                                            <TableCell>Activity Type</TableCell>
                                            <TableCell>Case Description</TableCell>
                                            <TableCell>Activity Description</TableCell>
                                            <TableCell>Case Type</TableCell>
                                            <TableCell>Adviser</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {activityData?.activities.map((a) => (
                                            <TableRow
                                                className={classes.caseRow}
                                                hover
                                                key={a.id}
                                                onClick={() => handleClick(a.caseId)}
                                                data-cy="table_rows"
                                            >
                                                <TableCell>{a.caseId}</TableCell>
                                                <TableCell>{a.accountName}</TableCell>
                                                <TableCell>{format(new Date(a.activityDate), "dd/MM/yyyy HH:mm")}</TableCell>
                                                <TableCell>{a.activityType}</TableCell>
                                                {a.caseDescription && a.caseDescription.length > 20
                                                    ? <TableCell style={{ width: "20%" }}>{a.caseDescription}</TableCell>
                                                    : <TableCell>{a.caseDescription}</TableCell>
                                                }
                                                {a.activityDescription && a.activityDescription.length > 20
                                                    ? <TableCell style={{ width: "20%" }}>{a.activityDescription}</TableCell>
                                                    : <TableCell>{a.activityDescription}</TableCell>
                                                }
                                                <TableCell>{getCaseType(a.caseTypeId).name}</TableCell>
                                                <TableCell>{a.adviserId && (
                                                    <Box display="flex" alignItems="center" justifyContent="flex-start">
                                                        <Avatar
                                                            style={{ marginRight: "8px" }}
                                                            className={avatarClasses.small}
                                                            alt={getAdviser(a.adviserId).name}
                                                            src={getAdviser(a.adviserId).photo}
                                                        />
                                                        <Typography variant="body2">{getAdviser(a.adviserId).name}</Typography>
                                                    </Box>
                                                )}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25]}
                                    component="div"
                                    count={activityData?.count}
                                    rowsPerPage={criteria.pageSize}
                                    page={criteria.pageNum - 1}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </React.Fragment>
                        )
                    }
                </CardContent>
            </Card>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => ({
    configState: state.configReducer,
    userState: state.userReducer,
    caseState: state.caseReducer,
});

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(actionCreators, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Activities);
