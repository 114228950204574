import Plugin from '@ckeditor/ckeditor5-core/src/plugin';

import Clipboard from '@ckeditor/ckeditor5-clipboard/src/clipboard';

export default class ClipboardHtml extends Plugin {

    static get requires() {
        return [Clipboard];
    }

    init() {
        //console.log('Clipboard#init() got called');

        this._defineClipboardInputOutput();         
    }

    _defineClipboardInputOutput() {
        const view = this.editor.editing.view;
        const viewDocument = view.document;

		this.listenTo(viewDocument, 'clipboardInput', (evt, data) => {
			if (data.content) {
				return;
			}

			const html = data.dataTransfer.getData('html');

			if (!html) {
				return;
            }                     

            const viewFragment = this.editor.data.processor.toView(html);
            data.content = viewFragment;			
		});
    }    
}