import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import axios from "../plugins/axios";
import FaceIcon from "@material-ui/icons/Face";

import {
    Box,
    Button as MuiIconButton,
    CardContent,
    Card as MuiCard,
    Chip,
    CardHeader,
    FormControl,
    Input,
    InputLabel,
    MenuItem,
    Select,
    Typography,
    Divider as MuiDivider,
} from "@material-ui/core";
import { spacing, color } from "@material-ui/system";
import { makeStyles } from "@material-ui/core/styles";
import {
    Edit as EditIcon,
    Save as SaveIcon,
    BusinessRounded,
} from "@material-ui/icons";

import { setSnackAction } from "../redux/actions/snackActions";
import { setCaseTypes, setCaseStages } from "../redux/actions/configActions";
import AdminList from "../components/AdminList";
import AdminToolbar from "../components/AdminToolbar";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const formStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(3),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const IconButton = styled(MuiIconButton)`
    padding: 2px;
    margin-top: -2px;
    margin-left: 2px;
    min-width: 0;
`;

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

const CaseTypes = ({ configState, dispatch }) => {
    const formClasses = formStyles();

    const [caseTypeId, setCaseTypeId] = useState("");
    const [caseStages, setCaseStagesLocal] = useState([]);
    const [caseTypeAccounts, setCaseTypeAccountsLocal] = useState([]);
    const [selectedStage, setSelectedStage] = useState("");
    const [mode, setMode] = useState("view");
    const [newStageName, setNewStageName] = useState("");
    const [saving, setSaving] = useState(false);
    const [caseTypeName, setCaseTypeName] = useState("");
    const [isAccountSpecific, setIsAccountCaseType] = useState("");
    const [caseStagesToSave, setCaseStagesToSave] = useState([]);

    const getCaseTypeName = useCallback(() => {
        setCaseTypeName(configState.caseTypes[caseTypeId].name);
    }, [configState.caseTypes, caseTypeId]);

    const getIsAccountSpecific = useCallback(() => {
        setIsAccountCaseType(
            configState.caseTypes[caseTypeId].isAccountSpecific
        );
    }, [configState.caseTypes, caseTypeId]);

    const getCaseStages = useCallback(() => {
        setCaseStagesLocal(
            configState.caseTypes[caseTypeId].stages
                .map((s) => ({
                    ...s,
                    toEdit: false,
                    isNew: false,
                    edited: false,
                    toDelete: false,
                }))
                .sort((s1, s2) => s1.stageOrder - s2.stageOrder)
        );
    }, [configState.caseTypes, caseTypeId]);

    const getCaseTypeAccounts = useCallback(() => {
        setCaseTypeAccountsLocal(
            configState.caseTypes[caseTypeId].accounts.map((s) => ({
                ...s,
                toEdit: false,
                isNew: false,
                edited: false,
                toDelete: false,
            }))
        );
        //.sort((s1, s2) => s1.stageOrder - s2.stageOrder));
    }, [configState.caseTypes, caseTypeId]);

    const handleCaseTypeChange = (e) => {
        setCaseTypeId(e.target.value);
    };

    const handleMove = (direction) => {
        let currentIndexFiltered = caseStages
            .filter((cs) => !cs.disabled)
            .map((cs) => cs.caseStageId)
            .indexOf(selectedStage);
        let currentStageOrder = caseStages.filter((cs) => !cs.disabled)[
            currentIndexFiltered
        ].stageOrder;
        let nextStageOrder = caseStages.filter((cs) => !cs.disabled)[
            currentIndexFiltered + direction
        ].stageOrder;

        setCaseStagesLocal(
            caseStages
                .map((cs, index) => {
                    if (cs.stageOrder === currentStageOrder) {
                        return {
                            ...cs,
                            stageOrder: nextStageOrder,
                            edited: true,
                        };
                    }
                    if (cs.stageOrder === nextStageOrder) {
                        return {
                            ...cs,
                            stageOrder: currentStageOrder,
                            edited: true,
                        };
                    }
                    return cs;
                })
                .slice()
                .sort((s1, s2) => s1.stageOrder - s2.stageOrder)
        );
    };

    const handleAdd = () => {
        if (newStageName === "") {
            setMode("view");
            return;
        }
        setCaseStagesLocal((prevCaseStages) => [
            ...prevCaseStages,
            {
                caseStageId: caseStages.length
                    ? Math.max.apply(
                          Math,
                          caseStages.map((cs) => cs.caseStageId)
                      ) + 1
                    : 1,
                caseTypeId: caseTypeId,
                name: newStageName,
                stageOrder:
                    Math.max.apply(
                        Math,
                        caseStages.map((cs) => cs.stageOrder)
                    ) + 1,
                disabled: false,
                isNew: true,
                edited: false,
                toDelete: false,
            },
        ]);
        setMode("view");
        setNewStageName("");
    };

    const handleDelete = () => {
        setCaseStagesLocal((prevCaseStages) =>
            prevCaseStages
                .filter((cs) => !(cs.caseStageId === selectedStage && cs.isNew))
                .map((cs) =>
                    cs.caseStageId === selectedStage
                        ? { ...cs, toDelete: true }
                        : cs
                )
        );
        setSelectedStage("");
    };

    const handleSetEdit = () => {
        setMode("edit");
        setCaseStagesLocal(
            caseStages.map((cs) =>
                cs.caseStageId === selectedStage ? { ...cs, toEdit: true } : cs
            )
        );
    };

    const handleSetAdd = () => {
        setMode("add");
        setSelectedStage("");
    };

    const handleSave = async () => {
        setSaving(true);
        let newCaseStages = caseStages;

        if (caseStages.filter((cs) => cs.isNew).length) {
            let response = await axios.post(
                `${apiUrl}/addcasestages`,
                caseStages
                    .filter((cs) => cs.isNew)
                    .map((cs) => ({
                        caseTypeId: cs.caseTypeId,
                        name: cs.name,
                        stageOrder:
                            cs.stageOrder === -Infinity ? 1 : cs.stageOrder,
                    }))
            );
            if (response.status === 200 && response.data) {
                newCaseStages = [
                    ...newCaseStages.filter((cs) => !cs.isNew),
                    ...response.data,
                ];
            }
        }

        if (caseStages.filter((cs) => cs.edited && !cs.isNew).length) {
            await axios.post(
                `${apiUrl}/updatecasestages`,
                caseStages
                    .filter((cs) => cs.edited && !cs.isNew)
                    .map((cs) => ({
                        caseStageId: cs.caseStageId,
                        name: cs.name,
                        stageOrder: cs.stageOrder,
                    }))
            );
        }

        if (caseStages.filter((cs) => cs.toDelete).length) {
            let response = await axios.post(
                `${apiUrl}/deletecasestages`,
                caseStages
                    .filter((cs) => cs.toDelete)
                    .map((cs) => cs.caseStageId)
            );
            if (response.data.length) {
                newCaseStages = newCaseStages
                    .filter(
                        (cs) =>
                            !(
                                cs.toDelete &&
                                !response.data.includes(cs.caseStageId)
                            )
                    )
                    .map((cs) =>
                        response.data.includes(cs.caseStageId)
                            ? { ...cs, disabled: true }
                            : cs
                    );
            } else {
                newCaseStages = newCaseStages.filter((i) => !i.toDelete);
            }
        }

        setSaving(false);
        setCaseStagesToSave(newCaseStages);
        setMode("view");
        setSelectedStage("");
        setNewStageName("");
    };

    const handleSaveType = async () => {
        var response = await axios.post(
            `${apiUrl}/updatecasetype/${caseTypeId}/${caseTypeName}`
        );
        if (response.status === 200) {
            dispatch(
                setCaseTypes(
                    Object.values(configState.caseTypes).map((ct) =>
                        ct.caseTypeId === caseTypeId
                            ? { ...ct, name: caseTypeName }
                            : ct
                    )
                )
            );
            dispatch(setSnackAction("Saved changes!", "success"));
        } else {
            dispatch(setSnackAction("Save failed", "error"));
        }
        setMode("view");
        setCaseTypeName("");
    };

    const handleReset = () => {
        getCaseStages();
        getCaseTypeAccounts();
        setNewStageName("");
        setSelectedStage("");
        setMode("view");
    };

    useEffect(() => {
        caseTypeId && getCaseTypeName();
    }, [caseTypeId, getCaseTypeName]);

    useEffect(() => {
        caseTypeId && getCaseTypeAccounts();
    }, [caseTypeId, getCaseTypeAccounts]);

    useEffect(() => {
        caseTypeId && getCaseStages();
    }, [caseTypeId, getCaseStages]);

    useEffect(() => {
        caseTypeId && getIsAccountSpecific();
    }, [caseTypeId, getIsAccountSpecific]);

    useEffect(() => {
        if (caseTypeId && caseStagesToSave.length) {
            dispatch(
                setCaseStages(
                    caseTypeId,
                    caseStagesToSave.map((cs) => ({
                        caseStageId: cs.caseStageId,
                        caseTypeId: cs.caseTypeId,
                        name: cs.name,
                        stageOrder: cs.stageOrder,
                        disabled: cs.disabled,
                    }))
                )
            );
            dispatch(setSnackAction("Saved!!", "success"));
        }
    }, [caseTypeId, caseStagesToSave, dispatch]);

    useEffect(() => {
        setCaseStagesToSave([]);
    }, [configState.caseTypes, caseTypeId]);

    const MapCaseAccountList = (e) => (
        <Chip
            key={e.accountId}
            icon={<BusinessRounded />}
            label={e.name}
            variant="outlined"
        ></Chip>
    );

    return (
        <React.Fragment>
            <Helmet title="Case Types" />
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
            >
                <Typography variant="h3" gutterBottom display="inline">
                    Case Types
                </Typography>
                <Box display="flex" alignItems="center">
                    {caseTypeId && mode !== "editType" && (
                        <IconButton
                            onClick={() => setMode("editType")}
                            style={{ height: "28px", marginBottom: "-6px" }}
                        >
                            <EditIcon />
                        </IconButton>
                    )}
                    {caseTypeId && mode === "editType" && (
                        <IconButton
                            onClick={handleSaveType}
                            disabled={!caseTypeName}
                            style={{ height: "28px", marginBottom: "-6px" }}
                        >
                            <SaveIcon />
                        </IconButton>
                    )}
                    {mode === "editType" ? (
                        <FormControl
                            style={{ paddingBottom: "8px" }}
                            className={formClasses.formControl}
                        >
                            <InputLabel>Case Type</InputLabel>
                            <Input
                                value={caseTypeName}
                                inputProps={{ maxLength: 80 }}
                                onChange={(e) =>
                                    setCaseTypeName(e.target.value)
                                }
                                type="text"
                            />
                        </FormControl>
                    ) : (
                        <FormControl
                            style={{ paddingBottom: "8px" }}
                            className={formClasses.formControl}
                        >
                            <InputLabel>Case Type</InputLabel>
                            <Select
                                value={caseTypeId}
                                onChange={handleCaseTypeChange}
                            >
                                {Object.values(configState.caseTypes)
                                    .sort((a, b) =>
                                        a.name > b.name
                                            ? 1
                                            : b.name > a.name
                                            ? -1
                                            : 0
                                    )
                                    .map((ct) => (
                                        <MenuItem
                                            key={ct.caseTypeId}
                                            value={ct.caseTypeId}
                                        >
                                            {ct.name}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    )}
                </Box>
            </Box>
            <Divider my={6} />
            <Card mb={6}>
                <CardContent
                    style={{ display: "flex", justifyContent: "center" }}
                >
                    <Box
                        display="flex"
                        justifyContent="center"
                        flexDirection="column"
                        style={{ width: "300px" }}
                    >
                        <AdminList
                            items={caseStages}
                            setItems={setCaseStagesLocal}
                            itemId="caseStageId"
                            mode={mode}
                            saving={saving}
                            selectedItem={selectedStage}
                            setSelectedItem={setSelectedStage}
                            newItem={newStageName}
                            setNewItem={setNewStageName}
                            handleAdd={handleAdd}
                            maxLength={80}
                        />
                        {caseTypeId ? (
                            <AdminToolbar
                                items={caseStages}
                                itemId="caseStageId"
                                handleSetAdd={handleSetAdd}
                                handleSetEdit={handleSetEdit}
                                handleDelete={handleDelete}
                                handleSave={handleSave}
                                handleMove={handleMove}
                                handleReset={handleReset}
                                changed={
                                    mode !== "view" ||
                                    caseStages.filter(
                                        (cs) =>
                                            cs.isNew || cs.toDelete || cs.edited
                                    ).length
                                }
                                selectedItem={selectedStage}
                                movable
                            />
                        ) : (
                            <Typography variant="body2">
                                Please select a Case Type
                            </Typography>
                        )}
                    </Box>
                </CardContent>
            </Card>
            {isAccountSpecific && (
                <Card>
                    <CardContent
                        style={{ display: "flex", justifyContent: "center" }}
                    >
                        <Chip
                            size="medium"
                            color="primary"
                            label="This is an Account specific Case Type configured against the following accounts :"
                        />
                    </CardContent>
                    <CardContent
                        style={{ display: "flex", justifyContent: "center" }}
                    >
                        {caseTypeAccounts.map((e) => MapCaseAccountList(e))}
                    </CardContent>
                </Card>
            )}
        </React.Fragment>
    );
};

export default connect((state) => ({ configState: state.configReducer }))(
    CaseTypes
);
