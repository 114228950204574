import React, { useState } from "react";
import Helmet from "react-helmet";
import styled from "styled-components";
import { spacing } from "@material-ui/system";
import KnowledgeArticlesTable from "./KnowledgeArticlesTable";
import KnowledgeDocumentsTable from "./KnowledgeDocumentsTable";
import TabPanel from "../TabPanel";
import { Tabs, Tab, Card as MuiCard, CardContent } from "@material-ui/core";
import useFeatureFlag from "../../hooks/useFeatureFlag";
import { Redirect } from "react-router-dom";

const Card = styled(MuiCard)(spacing);

const KnowledgeAdmin = () => {
    const [currentTab, setCurrentTab] = useState("articles");
    const { data: isEnabled } = useFeatureFlag("KnowledgeBank");

    return isEnabled ? (
        <React.Fragment>
            <Helmet title="Knowledge Base" />
            <Tabs value={currentTab} onChange={(e, x) => setCurrentTab(x)} style={{ marginBottom: "10px" }}>
                <Tab value="articles" label="Articles" />
                <Tab value="documents" label="Documents" />
            </Tabs>
            <TabPanel value={currentTab} index="articles">
                <Card mb={6}>
                    <CardContent>
                        <KnowledgeArticlesTable allColumns />
                    </CardContent>
                </Card>
            </TabPanel>
            <TabPanel value={currentTab} index="documents">
                <Card mb={6}>
                    <CardContent>
                        <KnowledgeDocumentsTable allColumns />
                    </CardContent>
                </Card>
            </TabPanel>
        </React.Fragment>
    ) : isEnabled === false ? (
        <Redirect to="/" />
    ) : (
        <div>Loading...</div>
    );
};

export default KnowledgeAdmin;
