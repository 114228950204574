import React, {useState, useMemo, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Box,
    CircularProgress,
    MenuItem,
    FormControlLabel,
    RadioGroup,
    Radio,
    TextField
} from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import { makeStyles } from '@material-ui/core/styles';
import { formatTimeHms } from '../helpers/formatTime';
import requestStatus from '../constants/requestStatus';
import MiscActivityEditor from '../components/MiscActivityEditor';
import {
    discardUnallocatedCaseTime,
    assignUnallocatedCaseTimeToUserActivity,
    assignUnallocatedCaseTimeToMiscActivity, holdUnallocatedCaseTime,
} from '../redux/actions/thunks';
import { isValid } from 'date-fns';
import { isValidHoursMinutes } from '../helpers/dateHelpers';
import { setSnackAction } from "../redux/actions/snackActions";
import closeCaseDialogStepConstants from "../constants/closeCaseDialogStepConstants";
import { MAX_ACTIVITY_DESCRIPTION } from "../constants/lengthConstants"
import {miscActivityGroups} from "../constants/miscActivityGroupConstants";

const useStyles = makeStyles((theme) => ({
    saveButton: {
        marginLeft: theme.spacing(2),
    },
    formItem: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    }
}));

const radioValues = {
    CASE: "case",
    NON_CASE: "non-case",
    HOLD: "hold",
};

const ConfirmCloseCaseDialog = ({ open, handleClose, handleConfirm, dialogStep, unallocatedSeconds, unallocatedCaseTimeEventIds, setDialogStep, caseId,
     }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [userActivityTypeId, setUserActivityTypeId] = useState("");
    const [radioValue, setRadioValue] = useState(radioValues.CASE);
    const [description, setDescription] = useState("");
    const [groupDescription, setGroupDescription] = useState("");

    const { userActivityTypes } = useSelector(state => state.configReducer);
    const { cases, processUnallocatedTimeRequestStatus } = useSelector(state => state.caseReducer);

    const c = cases[caseId];
    const activity = c?.miscActivity?.retrospective;
    const accountId = c?.account?.accountId;

    const handleDiscard = async () => {
        dispatch(discardUnallocatedCaseTime({ caseId }));
    };
    
    const handleConfirmDiscardWork = () => {
        if (!c.caseSummary?.description){
            setDialogStep(closeCaseDialogStepConstants.caseDescriptionWarning)
        } else if (!c.employees || c.employees.length === 0){
            setDialogStep(closeCaseDialogStepConstants.caseEmployeeWarning)
        } else
            handleConfirm(caseId)
    }
    
    const handleSkipDescription = () => {
        if (!c.employees || c.employees.length === 0)
            setDialogStep(closeCaseDialogStepConstants.caseEmployeeWarning)
        else {
            handleConfirm(caseId)
        }
    }
    
    const handleSkipEmployee = () => {
        handleConfirm(caseId)
    }

    const handleSkipCaseFilesTreeChanged = () => {
        handleConfirm(caseId)
    }
    
    const handleAssign = async () => {
        switch (radioValue) {
            case radioValues.NON_CASE:
                dispatch(assignUnallocatedCaseTimeToUserActivity({
                    caseId,
                    userActivityTypeId,
                    caseTimeEventIds: unallocatedCaseTimeEventIds,
                    description,
                }));
                break;
            
            case radioValues.CASE:
                if (!isValidHoursMinutes(activity.hours, activity.minutes) || !isValid(new Date(activity.date)) || !isValid(new Date(activity.startTime))) {
                    dispatch(setSnackAction("Invalid date or time entered", "error"));
                    return;
                }
                dispatch(assignUnallocatedCaseTimeToMiscActivity({
                    unallocatedCaseTimeEventIds,
                    caseId,
                }));
                break;
                
            case radioValues.HOLD:
                dispatch(holdUnallocatedCaseTime({ 
                    accountId, 
                    description: groupDescription, 
                    caseTimeEventIds: unallocatedCaseTimeEventIds,
                    caseId,
                }));
                break;
                
            default:
                break;
        }
    }

    const saveDisabled = useMemo(() => {
        if (radioValue === radioValues.HOLD) return false;
        
        if (radioValue === radioValues.CASE && activity) {
            if (!activity.miscActivityTypeId || !activity.description || !Date.parse(activity.date) || !activity.startTime
                || !(parseInt(activity.hours) || parseInt(activity.minutes)) || !activity.notes) return true;
            return false;
        }

        return !userActivityTypeId || description.length === 0;
    }, [radioValue, userActivityTypeId, description, activity?.miscActivityTypeId, activity?.description, activity?.date, activity?.startTime, activity?.hours, activity?.minutes, activity?.notes]);
    
    useEffect(() => {
        if (!open) {
            setUserActivityTypeId("");
            setRadioValue(radioValues.CASE);
            setDescription("");
            setGroupDescription("");
        }
    }, [open]);
    
    const dialogContent = () => {
        switch (dialogStep) {
            case closeCaseDialogStepConstants.discardUnfinishedWork:
                return (
                    <>
                        <DialogTitle>Close case?</DialogTitle>
                        <DialogContent>
                            <DialogContentText>It looks like you have unfinished work. Are you sure you want to close the case?</DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} color="primary">Cancel</Button>
                            <Button onClick={handleConfirmDiscardWork} color="primary">Confirm</Button>
                        </DialogActions>
                    </>
                );
            case closeCaseDialogStepConstants.handleUnallocatedTime:
                if (unallocatedSeconds > 0) return (
                    <>
                        <DialogTitle>Handle unallocated time</DialogTitle>
                        <DialogContent>
                            <DialogContentText>You have {formatTimeHms(unallocatedSeconds)} of unallocated time for this case. Please allocate time to an adviser activity, discard or log a case activity</DialogContentText>
                            <Box display="flex" width="100%" flexDirection="column">
                                <Box display="flex" justifyContent="space-around">
                                    <RadioGroup value={radioValue} onChange={e => setRadioValue(e.target.value)} row>
                                        <FormControlLabel value={radioValues.NON_CASE} control={<Radio />} label="Non case related activity" />
                                        <FormControlLabel value={radioValues.CASE} control={<Radio />} label="Case related activity" />
                                        <FormControlLabel value={radioValues.HOLD} control={<Radio />} label="Hold for another case" />
                                    </RadioGroup>
                                </Box>
                                {radioValue === radioValues.NON_CASE &&
                                    <Box display="flex" flexDirection="column" width="100%" my={3}>
                                        <TextField
                                            className={classes.formItem}
                                            fullWidth
                                            variant="outlined"
                                            label="Description"
                                            value={description}
                                            inputProps={{ maxLength: MAX_ACTIVITY_DESCRIPTION }}
                                            onChange={e => setDescription(e.target.value)}
                                        />
                                        <TextField
                                          className={classes.formItem}
                                          fullWidth
                                          select
                                          variant="outlined"
                                          label="Event description"
                                          value={userActivityTypeId}
                                          onChange={e => setUserActivityTypeId(e.target.value)}  
                                        >
                                            {Object.values(userActivityTypes).map(t =>
                                              <MenuItem value={t.userActivityTypeId} key={t.userActivityTypeId}>{t.description}</MenuItem>
                                            )}
                                        </TextField>
                                    </Box>}
                                {radioValue === radioValues.CASE && <MiscActivityEditor 
                                  unallocatedSeconds={unallocatedSeconds} 
                                  caseId={caseId} 
                                  group={miscActivityGroups.RETROSPECTIVE} 
                                />}
                                {radioValue === radioValues.HOLD && 
                                  <Box width="100%" my={3}>
                                      <TextField
                                        fullWidth
                                        value={groupDescription}
                                        onChange={e => setGroupDescription(e.target.value)}
                                        label="Description"
                                        variant="outlined"
                                      />
                                  </Box>}
                            </Box>
                        </DialogContent>
                        {processUnallocatedTimeRequestStatus === requestStatus.PENDING ? <Box display="flex" justifyContent="flex-end" width="90%" m={3}><CircularProgress /></Box> :
                            <Box display="flex" justifyContent="space-between" m={3}>
                                <Button onClick={() => setDialogStep(closeCaseDialogStepConstants.discardTime)} style={{ color: red[500] }}>Discard</Button>
                                <Box display="flex" justifyContent="flex-end">
                                    <Button onClick={handleClose}>Cancel</Button>
                                    <Button className={classes.saveButton} onClick={handleAssign} color="primary" variant="contained" disabled={saveDisabled}>
                                        Save
                                    </Button>
                                </Box>
                            </Box>
                        }
                    </>
                );
            case closeCaseDialogStepConstants.discardTime:
                return (
                    <>
                        <DialogTitle>Discard time?</DialogTitle>
                        <DialogContent>
                            <DialogContentText>Are you sure you want to discard {formatTimeHms(unallocatedSeconds)} of time?</DialogContentText>
                        </DialogContent>
                        {processUnallocatedTimeRequestStatus === requestStatus.PENDING ? <Box display="flex" justifyContent="flex-end" width="90%" m={3}><CircularProgress /></Box> :
                            <DialogActions>
                                <Button onClick={handleClose} color="primary">Cancel</Button>
                                <Button onClick={handleDiscard} color="primary">Confirm</Button>
                            </DialogActions>
                        }
                    </>
                );
            case closeCaseDialogStepConstants.caseDescriptionWarning:
                return (
                    <>
                        <DialogTitle>Case description</DialogTitle>
                        <DialogContent>
                            <DialogContentText>This case does not have a description. Do you wish to return to the case to add one?</DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} color="primary">Yes</Button>
                            <Button onClick={handleSkipDescription} color="primary">No</Button>
                        </DialogActions>
                    </>
                );
            case closeCaseDialogStepConstants.caseEmployeeWarning:
                return (
                    <>
                        <DialogTitle>Case employee</DialogTitle>
                        <DialogContent>
                            <DialogContentText>This case does not have an assigned employee. Do you wish to return to the case to add one?</DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} color="primary">Yes</Button>
                            <Button onClick={handleSkipEmployee} color="primary">No</Button>
                        </DialogActions>
                    </>
                );
            case closeCaseDialogStepConstants.caseFilesTreeChangeWarning:
                return (
                    <>
                        <DialogTitle>Case Files Structure</DialogTitle>
                        <DialogContent>
                            <DialogContentText>It looks like you have altered the structure of the case files. Do you wish to return to the case to review?</DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleSkipCaseFilesTreeChanged} color="primary">No</Button>
                            <Button onClick={handleClose} color="primary" variant="contained">Yes</Button>
                        </DialogActions>
                    </>
                );
            default:
                return null;
        }
    }

    return (
        <Dialog open={open} onClose={handleClose}>
            {dialogContent()}
        </Dialog>
    );
}

export default ConfirmCloseCaseDialog;
