import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import Loader from "../Loader";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        margin: theme.spacing(3, 0),
    },
    title: { fontSize: 15, fontWeight: 600, marginBottom: theme.spacing(4) },
    btnContainer: {
        display: "flex",
        flexWrap: "wrap",
    },
}));

const FilterWrapper = ({
    filterName,
    testId = null,
    hasBtnContainer = false,
    isLoading = false,
    children,
}) => {
    const classes = useStyles();
    return (
        <div className={classes.root} data-testid={testId}>
            <Typography
                variant="subtitle1"
                color="primary"
                className={classes.title}
            >
                {filterName}
            </Typography>
            {isLoading ? (
                <Loader />
            ) : hasBtnContainer ? (
                <div className={classes.btnContainer}>{children}</div>
            ) : (
                <>{children}</>
            )}
        </div>
    );
};

export default FilterWrapper;
