import React, {useState} from 'react';
import {
    CircularProgress,
    IconButton,
    TableContainer,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableBody,
    TablePagination, Button,
} from '@material-ui/core';
import StatusChip from './StatusChip';
import InvoiceSearchTableMenu from './InvoiceSearchTableMenu';
import { makeStyles } from '@material-ui/styles';
import { Alert } from "@material-ui/lab";
import {
    MoreVert, Publish
} from "@material-ui/icons";
import {
    formatShortMonthDate,
    tableDateTime
} from "../utils/dateTimeFormatters";
import useInvoiceSearchesPaged from '../hooks/queries/useInvoiceSearchesPaged';
import { invoiceStatus } from '../constants/invoiceStatus';
import useTimesheetCSVExport from '../hooks/useTimesheetCSVExport';
import {downloadFileFromBytes} from "../utils/fileUtils";
import {format} from "date-fns";
import invoiceSearchService from "../services/invoiceSearchService";
import {useDispatch} from "react-redux";
import {setSnackAction} from "../redux/actions/snackActions";
import {getFileExtension} from "../utils/fileNameUtils";
import reactQueryClient from "../reactQueryClient";
import queryKeys from "../constants/queryKeys";
import InvoiceUploadDialog from "./dialogs/InvoiceUploadDialog";
import {invoiceUploadStatus} from "../constants/invoiceUploadStatus";
import useDeleteInvoiceSearch from "../hooks/mutations/useDeleteInvoiceSearch";
import DiscardInvoiceSearchDialog from "./dialogs/DiscardInvoiceSearchDialog";

const useStyles = makeStyles((theme) => ({
    uploadInvoiceButton: {
        marginLeft: theme.spacing(3),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

const InvoiceSearchTable = () => {

    const classes = useStyles();
    const dispatch = useDispatch();
    const [pageNumber, setPageNumber] = useState(0);
    const [pageSize, setPageSize] = useState(5);
    const [selectedInvoiceSearch, setSelectedInvoiceSearch] = useState(null);
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
    const [uploadStatus, setUploadStatus] = useState(null);
    const [uploadMessage, setUploadMessage] = useState("");
    const [discardDialogOpen, setDiscardDialogOpen] = useState(false);

    const {
        data: invoiceSearchesData,
        isLoading: isInvoiceSearchesLoading,
        error: invoiceSearchesError,
        isError: isInvoiceSearchesError,
    } = useInvoiceSearchesPaged({
        pageNumber: pageNumber + 1,
        pageSize,
    });
    
    const deleteInvoiceSearch = useDeleteInvoiceSearch();

    const { CsvLinkComponent, exportToCsv } = useTimesheetCSVExport();

    const handlePageChange = (event, newPage) => {
        setPageNumber(newPage);
    }

    const handleRowsPerPageChange = (event) => {
        setPageSize(parseInt(event.target.value, 10));
        setPageNumber(0);
    }

    // Handlers
    const handleMenuOpenClick = (event, invoiceSearch) => {
        setSelectedInvoiceSearch(invoiceSearch);
        setMenuAnchorEl(event.currentTarget);
    }

    const handleMenuClose = () => {
        setMenuAnchorEl(null);
        setSelectedInvoiceSearch(null);
    }
    
    const handleDownloadCsv = async () => {
        try {
            const data = await invoiceSearchService.getDataForCsvExport(selectedInvoiceSearch.id);
            exportToCsv(data);
        }
        catch (e) {
            console.error(e);
            dispatch(setSnackAction(e?.message || "Failed to download CSV", "error"));
        }
        finally {
            handleMenuClose();
        }
    }
    
    const handleDownloadInvoicedCsv = async () => {
        try {
            const bytes = await invoiceSearchService.getDataForInvoicedCsvExport(selectedInvoiceSearch.id);
            downloadFileFromBytes({
                bytes,
                fileName: selectedInvoiceSearch.fileName,
                fileType: "text/csv",
            });
        }
        catch (e) {
            console.error(e);
            dispatch(setSnackAction(e?.message || "Failed to download CSV", "error"));
        }
        finally {
            handleMenuClose();
        }
    }

    const handleDownloadPdf = async () => {
        try {
            const bytes = await invoiceSearchService.getDataForPdfExport(selectedInvoiceSearch.id);
            downloadFileFromBytes({
                bytes,
                fileName: `CaseActivityTimesheet${format(new Date(), "yyyyMMddhhmmss")}.zip`,
                fileType: "application/zip",
            });
        }
        catch (e) {
            console.error(e);
            dispatch(setSnackAction(e?.message || "Failed to download PDF", "error"));
        }
        finally {
            handleMenuClose();
        }
    }
    
    const handleCloseDiscard = () => {
        setDiscardDialogOpen(false);
        handleMenuClose();
    }

    const handleDiscard = () => {
        deleteInvoiceSearch.mutate(selectedInvoiceSearch.id);
        handleCloseDiscard();
    }
    
    const handleFileUpload = async (e, id) => {
        const file = e.target.files[0];
        e.target.value = "";
        
        if (getFileExtension(file.name) !== ".csv") {
            dispatch(setSnackAction("File must be in .csv format", "warning"));
            return;
        }
        
        try {
            setUploadDialogOpen(true);
            setUploadStatus(invoiceUploadStatus.IN_PROGRESS);
            const response = await invoiceSearchService.uploadInvoice({ id, file });
            setUploadStatus(invoiceUploadStatus.SUCCESS);
            setUploadMessage(`Successfully invoiced ${response.data.activitiesInvoiced}/${response.data.totalActivities} activities. Any remaining activities have been freed up to be included in a new search.`);
            reactQueryClient.invalidateQueries([queryKeys.invoiceSearchesPaged]);
        }
        catch (e) {
            console.error(e);
            setUploadStatus(invoiceUploadStatus.FAILED);
            if (!!e?.response?.data?.length) {
                setUploadMessage(e.response.data[0].message);
                return;
            }
            setUploadMessage("An unknown error occured. Please try again or contact a member of IT support.");
        }
    }
    
    const handleCloseUploadDialog = (event, reason) => {
        if (reason === "backdropClick") {
            return;
        }
        setUploadDialogOpen(false);
        setUploadStatus(null);
        setUploadMessage("");
    }

    if (isInvoiceSearchesLoading) {
        return (
            <div>
                <CircularProgress />
            </div>
        )
    }

    if (isInvoiceSearchesError) {
        return (
            <Alert variant="outlined" severity="error">{invoiceSearchesError?.message || "Cannot retrieve invoice searches at this time"}</Alert>
        )
    }

    return (
        <>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                Date Range
                            </TableCell>
                            <TableCell>
                                Core Services
                            </TableCell>
                            <TableCell>
                                Service Types
                            </TableCell>
                            <TableCell>
                                Last Updated
                            </TableCell>
                            <TableCell>
                                Status
                            </TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {invoiceSearchesData?.items?.map((invoiceSearch) => (
                            <TableRow
                                key={invoiceSearch.id}
                            >
                                <TableCell>
                                    {`${formatShortMonthDate(invoiceSearch.startDate)} - ${formatShortMonthDate(invoiceSearch.endDate)}`}
                                </TableCell>
                                <TableCell>
                                    {invoiceSearch.coreServices}
                                </TableCell>
                                <TableCell>
                                    {invoiceSearch.serviceTypes}
                                </TableCell>
                                <TableCell>
                                    {tableDateTime(invoiceSearch.lastUpdated)}
                                </TableCell>
                                <TableCell>
                                    <StatusChip
                                        label={invoiceSearch.status === invoiceStatus.ReadyForInvoicing ? "Ready for invoicing" : "Invoiced"}
                                        variant={invoiceSearch.status === invoiceStatus.ReadyForInvoicing ? "info" : "success"}
                                    />
                                    {invoiceSearch.status === invoiceStatus.ReadyForInvoicing ? 
                                        <>
                                            <Button
                                                className={classes.uploadInvoiceButton}
                                                variant="outlined"
                                                color="secondary"
                                                startIcon={<Publish />}
                                                
                                                component="label"
                                                
                                            >
                                                <input
                                                    type="file"
                                                    hidden
                                                    onChange={(e) => handleFileUpload(e, invoiceSearch.id)}
                                                />
                                                Upload Invoice
                                            </Button>
                                            
                                        </> :
                                        null
                                    }
                                </TableCell>
                                <TableCell>
                                    <IconButton
                                        onClick={(e) => handleMenuOpenClick(e,invoiceSearch)}
                                    >
                                        <MoreVert />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={invoiceSearchesData?.totalCount || 0}
                rowsPerPage={pageSize}
                page={pageNumber}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
            />
            {selectedInvoiceSearch !== null ? <InvoiceSearchTableMenu
                anchorEl={menuAnchorEl}
                onClose={handleMenuClose}
                onDownloadCsv={selectedInvoiceSearch?.status === invoiceStatus.ReadyForInvoicing ? handleDownloadCsv : handleDownloadInvoicedCsv}
                onDownloadPdf={handleDownloadPdf}
                onDiscard={() => setDiscardDialogOpen(true)}
                selectedInvoiceSearch={selectedInvoiceSearch}
            /> : null}
            <CsvLinkComponent />
            <InvoiceUploadDialog 
                open={uploadDialogOpen}
                onClose={handleCloseUploadDialog}
                status={uploadStatus}
                content={uploadMessage}
            />
            <DiscardInvoiceSearchDialog
                open={discardDialogOpen}
                onClose={handleCloseDiscard}
                onConfirm={handleDiscard}
            />
        </>
    )
}

export default InvoiceSearchTable;