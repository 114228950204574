import { useQuery } from "react-query";
import queryKeys from "../../constants/queryKeys";
import caseEmployeeService from "../../services/caseEmployeeService";

const useYouManageEmployees = ({ accountId, query }) =>
  useQuery(
    [queryKeys.youManageEmployees, accountId, query],
    () => caseEmployeeService.getYouManageEmployees({ accountId, query }),
    {
      enabled: !!accountId && !!query,
    },
  );

export default useYouManageEmployees;