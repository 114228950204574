import React, { useEffect, useState } from "react";
import axios from "../plugins/axios";
import { makeStyles } from "@material-ui/core/styles";
import MyCKEditor from "../ckeditor/MyCKEditor";
import Moment from "react-moment";

import {
    Dialog,
    DialogContent,
    DialogTitle,
    Box,
    Chip,
    Button,
} from "@material-ui/core";

import { GetApp as DownloadIcon } from "@material-ui/icons";

const useStyles = makeStyles({
    dialog: {
        height: "calc(100% - 64px)",
    },
});

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

const DocumentDialog = ({ open, onClose, documentGuid }) => {
    const classes = useStyles();
    const [versions, setVersions] = useState([]);
    const [current, setCurrent] = useState(null);
    const [editor, setEditor] = useState();

    useEffect(() => {
        const getVersions = async () => {
            const result = await axios.get(
                `${apiUrl}/document/versions/${documentGuid}`
            );
            setVersions(result.data);
            setCurrent(result.data[result.data.length - 1]);
        };
        if (documentGuid) {
            getVersions();
        }

        return () => {
            setCurrent(null);
            setVersions([]);
        };
    }, [documentGuid]);

    useEffect(() => {
        if (!current || !editor) return;

        const html = current.htmlContent || "";
        editor.setData(html);
    }, [current, editor]);

    const downloadPdf = async () => {
        var response = await axios.get(
            `${apiUrl}/document/retrieve/${current.file.reference}`,
            { responseType: "arraybuffer" }
        );
        let blob = new Blob([response.data], {
            type: current.file.contentType,
        });
        let link = window.document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = current.file.filename;
        link.click();
    };

    return (
        <Dialog
            disableEnforceFocus
            open={open}
            onClose={onClose}
            fullWidth
            maxWidth="lg"
            classes={{ paper: classes.dialog }}
        >
            <Box
                display="flex"
                flexDirection="column"
                style={{ height: "100%" }}
            >
                <Box display="flex" justifyContent="space-between">
                    <DialogTitle>{current && current.name}</DialogTitle>
                    {current && current.file && (
                        <Button onClick={downloadPdf}>
                            <DownloadIcon fontSize="large" />
                        </Button>
                    )}
                </Box>
                <Box flexGrow={1} style={{ height: 0 }}>
                    <DialogContent style={{ height: "100%" }}>
                        <Box
                            display="flex"
                            flexDirection="column"
                            style={{ height: "100%", overflow: "hidden" }}
                        >
                            {current && (
                                <Box>
                                    {current.createdBy} -{" "}
                                    <Moment format="DD/MM/YY HH:mm">
                                        {current.createdOn}
                                    </Moment>
                                </Box>
                            )}
                            <Box
                                display="flex"
                                flexWrap="wrap"
                                alignItems="center"
                            >
                                <Box m={2}>Versions</Box>
                                {versions.map((v) => (
                                    <Box m={1} key={v.currentVersion}>
                                        <Chip
                                            label={v.currentVersion}
                                            m={1}
                                            onClick={() => setCurrent(v)}
                                            color={
                                                v === current
                                                    ? "primary"
                                                    : "default"
                                            }
                                            variant={
                                                v.file ? "default" : "outlined"
                                            }
                                        />
                                    </Box>
                                ))}
                            </Box>
                            <Box
                                flexGrow={1}
                                style={{ height: 0 }}
                                display="flex"
                            >
                                {open && current && (
                                    <MyCKEditor
                                        initialData=""
                                        editor={editor}
                                        setEditor={setEditor}
                                        type="readonly"
                                        contained
                                    />
                                )}
                            </Box>
                        </Box>
                    </DialogContent>
                </Box>
            </Box>
        </Dialog>
    );
};

export default DocumentDialog;
