import React, { useState } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@material-ui/core";
import caseService from "../../services/caseService";
import { useDispatch } from "react-redux";
import { setSnackAction } from "../../redux/actions/snackActions";

const CaseHistoryExportDialog = ({ open, onClose, caseId, caseActivityIds, exportType }) => {
    const [step, setStep] = useState(0);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const steps = [
        `Are you sure you want to export case ${(exportType === "zip" ? "activties" : "history")}? This process may take some time, depending on the size of the case.`,
        "Your export has been initiated. You'll receive an email once it's ready to be downloaded.",
    ];

    const handleClose = () => {
        onClose();
        setStep(0);
    };

    const handleExport = async () => {
        try {
            setLoading(true);
            await caseService.initiateCaseHistoryExport({ caseId, caseActivityIds, exportType  });
            setStep(1);
        } catch (e) {
            console.error(e);
            dispatch(
                setSnackAction(
                    e?.message || "Could not initiate case history export",
                    "error"
                )
            );
        } finally {
            setLoading(false);
        }
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Export</DialogTitle>
            <DialogContent>
                <DialogContentText>{steps[step]}</DialogContentText>
            </DialogContent>
            <DialogActions>
                {step === 0 && (
                    <>
                        <Button color="secondary" onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button
                            color="secondary"
                            onClick={handleExport}
                            disabled={loading}
                        >
                            Export
                        </Button>
                    </>
                )}
                {step === 1 && (
                    <Button color="secondary" onClick={handleClose}>
                        Dismiss
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default CaseHistoryExportDialog;
