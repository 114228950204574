import React, { useState,  useEffect } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import axios from "../plugins/axios";
import { setTemplate } from "../redux/actions/userActions";
import { setSnackAction } from "../redux/actions/snackActions";
import MyCKEditor from '../ckeditor/MyCKEditor';

import {    
    TextField,
    Box,
    Button as MuiIconButton
} from "@material-ui/core";

import {   
    Save as SaveIcon
} from "@material-ui/icons";

import { grey } from "@material-ui/core/colors";

const IconButton = styled(MuiIconButton)`
    padding: 2px;
    margin-top: -2px;
    margin-left: 2px;
    min-width: 0;
    color: ${grey[700]};
`;

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

const TemplateEditor = ({ template, dispatch, onSave }) => {

    const [editor, setEditor] = useState(null);
    const [templateInfo, setTemplateInfo] = useState(template);
    const [saving, setSaving] = useState(false);   
    
    const setTemplateName = e => {
        setTemplateInfo({ ...templateInfo, name: e.target.value })
    };
      
    useEffect(() => {
        
        if (!templateInfo.templateId) {

            var newTemplate = {
                htmlContent: '',
                name: ''
            };           
           
            setTemplateInfo(newTemplate)
        }  
    }, [templateInfo.templateId]);

    const save = async () => {
        
        let html = editor.getData();

        if (html === templateInfo.htmlContent && templateInfo.name === template.name)
            return;

        var saveData = { ...templateInfo, htmlContent: html };

        setSaving(true);

        var response = await axios.post(`${apiUrl}/savetemplate`, saveData);
        saveData.templateId = response.data;
     
        onSave(saveData);

        dispatch(setTemplate(saveData));
        dispatch(setSnackAction("Template saved!", "success"));
        setTemplateInfo(saveData)

        setSaving(false);
    };
    

    return (
        <React.Fragment>           
            <Box display="flex" style={{marginBottom:'10px'}} alignItems="flex-end">
                <TextField
                    label="Template Name"
                    value={templateInfo.name}
                    onChange={setTemplateName}
                    m={2}
                    style={{width:'300px'}}
                />                
                {editor && <IconButton disabled={saving} disableRipple={false}><SaveIcon onClick={save} /></IconButton>}
            </Box>        
            <MyCKEditor
                initialData={templateInfo.htmlContent}
                setEditor={setEditor}
                onChange={() => {}}
                onFocus={() => { }}
                type="plain"
            />
        </React.Fragment>
    );

};


export default connect()(TemplateEditor);
