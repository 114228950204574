import axios from "../plugins/axios";
import { toIsoString } from "../utils/dateTimeFormatters";

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

const getDataForCsvExport = async (id) => {
    try {
        const response = await axios.get(`${apiUrl}/invoicesearch/${id}/export/csv`);
        return response.data;
    }
    catch(e) {
        console.error(e);
        return Promise.reject(e?.message || "Failed to fetch CSV export data")
    }
}

const getDataForInvoicedCsvExport = async (id) => {
    try {
        const response = await axios.get(`${apiUrl}/invoicesearch/invoiced/${id}/export/csv`, {
            responseType: "arraybuffer"
        });
        return response.data;
    }
    catch(e) {
        console.error(e);
        return Promise.reject(e?.message || "Failed to fetch CSV export data")
    }
}

const getInvoiceSearchesPaged = async ({ pageNumber, pageSize }) => {
    try {
        const response = await axios.get(`${apiUrl}/invoicesearch/paged?pageSize=${pageSize}&pageNumber=${pageNumber}`);
        return response.data;
    }
    catch(e) {
        console.error(e);
        return Promise.reject(e?.message || "Failed to fetch paginated invoice searches");
    }
}

const uploadInvoice = async ({ id, file }) => {
    let formData = new FormData();
    formData.append("file", file);
    return await axios.post(`${apiUrl}/invoicesearch/${id}/upload`, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
}

const addInvoiceSearch = async ({
    startDate,
    endDate,
    coreServices,
    serviceTypes
}) => {
    try {
        const response = await axios.post(
            `${apiUrl}/invoicesearch`,
            {
                startDate: toIsoString(startDate),
                endDate: toIsoString(endDate),
                coreServices: coreServices.map(cs => cs.name),
                serviceTypes: serviceTypes.map(st => st.name),
            }
        );
        return response.data;
    }
    catch (e) {
        console.error(e);
        return Promise.reject(e?.message || "Failed to add invoice search")
    }
}

const getDataForPdfExport = async (id) => {
    try {
        const response = await axios.get(`${apiUrl}/invoicesearch/${id}/export/pdf`, {
            responseType: "arraybuffer"
        });
        return response.data;
    }
    catch (e) {
        console.error(e);
        return Promise.reject(e?.message || "Failed to fetch PDF export data");
    }
}

const deleteInvoiceSearch = (id) => axios.delete(`${apiUrl}/invoicesearch/${id}`);

const invoiceSearchService = {
    addInvoiceSearch,
    getInvoiceSearchesPaged,
    getDataForCsvExport,
    getDataForInvoicedCsvExport,
    getDataForPdfExport,
    uploadInvoice,
    deleteInvoiceSearch,
};

export default invoiceSearchService;