export const downloadFileFromBytes = ({
    bytes,
    fileName,
    fileType = "application/octet-stream",
}) => {
    let blob = new Blob([bytes], { type: fileType });
    let link = window.document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
};
