import React from 'react'
import { FormControl, FormLabel, FormControlLabel, RadioGroup, Radio } from '@material-ui/core';

export default function RadioGroupControl(props) {


    const { name, label, value, onChange, items } = props;

    return (

        <FormControl>
            <FormLabel>{label}</FormLabel>
            <RadioGroup row
                name={name}
                values={value}
                onChange={onChange}>
                {
                    items.map(
                        (item, index) => (
                            <FormControlLabel key={item.id} value={item.id} control={<Radio />} label={item.title} />
                        )
                    )
                }
            </RadioGroup>

        </FormControl>
        )
}