import React, { useState, useCallback, useEffect } from "react";
import Helmet from "react-helmet";
import styled from "styled-components";
import axios from "../plugins/axios";
import { connect } from "react-redux";
import {
    Box,
    Button as MuiIconButton,
    Divider as MuiDivider,
    Input,
    Typography,
    Card,
    CardContent,
    Table,
    TableHead,
    TableCell,
    TableBody,
    TableRow,
    TablePagination,
    Select,
    MenuItem,
    Dialog,
    DialogTitle,
    DialogContentText,
    DialogActions,
    DialogContent,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import {
    Clear as CancelIcon,
    DeleteOutline as DeleteIcon,
    Save as SaveIcon,
} from "@material-ui/icons";

import { red } from "@material-ui/core/colors";

import Loader from "../components/Loader";
import { setSnackAction } from "../redux/actions/snackActions";

import Icons from "../ckeditor/MentionIcons";

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

const Divider = styled(MuiDivider)(spacing);

const IconButton = styled(MuiIconButton)`
    padding: 2px;
    margin-top: -2px;
    margin-left: 2px;
    min-width: 0;
`;

const DeleteButton = styled(MuiIconButton)`
    color: ${red[500]};
    &:hover {
        background: ${red[50]};
    }
`;

const MaterialIcon = (props) => {
    return React.createElement(Icons[props.icon] || Icons.Edit, props);
};

const DisplayIcon = (props) => {
    return React.createElement(Icons[props.icon] || Icons.Error, props);
};

const validateIcon = (icon) => {
    return Icons[icon];
};

const EditingRow = ({
    editedMention,
    handleCancel,
    handleSave,
    handleChange,
    mentionTypes,
    getMentionType,
}) => (
    <React.Fragment>
        <TableCell>
            <Box display="flex">
                <IconButton
                    disabled={
                        !editedMention.type ||
                        !editedMention.name ||
                        !validateIcon(editedMention.icon)
                    }
                    onClick={handleSave}
                >
                    <SaveIcon />
                </IconButton>
                <IconButton onClick={handleCancel}>
                    <CancelIcon />
                </IconButton>
            </Box>
        </TableCell>
        <TableCell>{editedMention.mentionId || null}</TableCell>
        <TableCell>
            <Select
                value={editedMention.type}
                onChange={(e) => handleChange(e.target.value, "type")}
                error={!editedMention.type}
            >
                {mentionTypes.map((i) => (
                    <MenuItem value={i.name} key={i.name}>
                        {getMentionType(i.name)}
                    </MenuItem>
                ))}
            </Select>
        </TableCell>
        <TableCell>
            <Input
                type="text"
                value={editedMention.name}
                inputProps={{ maxLength: 50 }}
                onChange={(e) => handleChange(e.target.value, "name")}
                error={!editedMention.name}
            />
        </TableCell>
        <TableCell>
            <Input
                type="text"
                value={editedMention.guidance}
                inputProps={{ maxLength: 500 }}
                onChange={(e) => handleChange(e.target.value, "guidance")}
                error={!editedMention.guidance}
            />
        </TableCell>

        <TableCell>
            <Input
                type="text"
                value={editedMention.icon}
                inputProps={{ maxLength: 50 }}
                onChange={(e) => handleChange(e.target.value, "icon")}
                error={!editedMention.icon}
            />
            <Box display="flex">
                <DisplayIcon fontSize="small" icon={editedMention.icon} />
            </Box>
        </TableCell>

        {editedMention.type === "option" && (
            <TableCell>
                <Input
                    type="text"
                    value={
                        editedMention.options === null
                            ? ""
                            : editedMention.options
                    }
                    inputProps={{ maxLength: 500 }}
                    onChange={(e) =>
                        handleChange(
                            e.target.value === null
                                ? ""
                                : e.target.value.split(","),
                            "options"
                        )
                    }
                    error={!editedMention.options}
                />
            </TableCell>
        )}
    </React.Fragment>
);

const Mentions = ({ dispatch }) => {
    const [mentions, setMentions] = useState([]);
    const [count, setCount] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [editedMention, setEditedMention] = useState(null);
    const [mentionTypes, setMentionTypes] = useState(null);
    const [open, setOpen] = useState(false);
    const [selectedMention, setSelectedMention] = useState(null);
    const [mode, setMode] = useState("view");

    const getMentions = useCallback(async () => {
        setLoading(true);
        let criteria = {
            pageSize: rowsPerPage,
            pageNum: page + 1,
        };
        let response = await axios.post(`${apiUrl}/getmentionstable`, criteria);
        if (response.status === 200) {
            setMentions(
                response.data.mentions
                    .sort((a, b) =>
                        a.name > b.name ? 1 : b.name > a.name ? -1 : 0
                    )
                    .map((p) => ({ ...p, toEdit: false }))
            );
            setCount(response.data.count);
        }
        setLoading(false);
    }, [page, rowsPerPage]);

    const getMentionTypes = useCallback(async () => {
        let response = await axios.get(`${apiUrl}/getmentiontypes`);
        if (response.status === 200) {
            setMentionTypes(response.data);
        }
    }, []);

    const getMentionType = (id) =>
        mentionTypes && mentionTypes.filter((i) => i.name === id)[0].name;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const handleEdit = (mentionId) => {
        setMode("edit");
        setMentions((prevMentions) =>
            prevMentions
                .sort((a, b) =>
                    a.name > b.name ? 1 : b.name > a.name ? -1 : 0
                )
                .map((p) =>
                    p.mentionId === mentionId
                        ? { ...p, toEdit: true }
                        : { ...p, toEdit: false }
                )
        );
        setEditedMention(mentions.filter((p) => p.mentionId === mentionId)[0]);
    };

    const handleChange = (value, field) => {
        setEditedMention((prevMention) => ({
            ...prevMention,
            [field]: value,
        }));
    };

    const handleCancel = () => {
        setMentions((prevMentions) =>
            prevMentions
                .sort((a, b) =>
                    a.name > b.name ? 1 : b.name > a.name ? -1 : 0
                )
                .map((p) => ({ ...p, toEdit: false }))
        );
        setEditedMention(null);
        setMode("view");
    };

    const handleSave = async () => {
        if (editedMention.mentionId) {
            let response = await axios.post(
                `${apiUrl}/updatemention`,
                editedMention
            );
            if (response && response.status === 200) {
                setMentions((prevMentions) =>
                    prevMentions
                        .sort((a, b) =>
                            a.name > b.name ? 1 : b.name > a.name ? -1 : 0
                        )
                        .map((p) =>
                            p.mentionId === editedMention.mentionId
                                ? editedMention
                                : p
                        )
                );
                dispatch(setSnackAction("Saved!", "success"));
            }
        } else {
            let response = await axios.post(
                `${apiUrl}/addmention`,
                editedMention
            );
            if (response && response.status === 200 && response.data) {
                setMentions((prevMentions) => [
                    ...prevMentions,
                    {
                        ...editedMention,
                        mentionId: response.data,
                    },
                ]);
                dispatch(setSnackAction("Saved!", "success"));
            }
        }
        handleCancel();
    };

    const handleOpen = (id) => {
        setSelectedMention(mentions.filter((p) => p.mentionId === id)[0]);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedMention(null);
    };

    const handleDelete = async () => {
        let response = await axios.delete(
            `${apiUrl}/deletemention/${selectedMention.mentionId}`
        );
        if (response.status === 200) {
            setMentions((prevMentions) =>
                prevMentions
                    .sort((a, b) =>
                        a.name > b.name ? 1 : b.name > a.name ? -1 : 0
                    )
                    .map((p) =>
                        p.mentionId === selectedMention.mentionId
                            ? { ...p, disabled: true }
                            : p
                    )
            );
            dispatch(setSnackAction("Removed!", "success"));
        }
        handleClose();
    };

    const handleAdd = () => {
        setMode("add");
        setEditedMention({
            type: "",
            name: "",
            guidance: "",
            icon: "",
            options: "",
        });
    };

    useEffect(() => {
        getMentions();
    }, [getMentions]);

    useEffect(() => {
        getMentionTypes();
    }, [getMentionTypes]);

    return (
        <React.Fragment>
            <Helmet title="Mentions" />
            <Box display="flex" justifyContent="space-between">
                <Typography variant="h3" gutterBottom display="inline">
                    Mentions
                </Typography>
                <IconButton
                    color="primary"
                    disabled={mode !== "view"}
                    onClick={handleAdd}
                >
                    <Icons.Add />
                </IconButton>
            </Box>
            <Divider my={6} />
            <Card mb={6}>
                <CardContent>
                    {loading ? (
                        <Loader />
                    ) : (
                        <React.Fragment>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Actions</TableCell>

                                        <TableCell>Id</TableCell>
                                        <TableCell>MentionType</TableCell>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Guidance</TableCell>
                                        <TableCell>Icon</TableCell>
                                        <TableCell>Options</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {mentions
                                        .filter((p) => !p.disabled)
                                        .map((p) => (
                                            <TableRow
                                                style={{
                                                    opacity:
                                                        mode === "add" ||
                                                        (mode === "edit" &&
                                                            !p.toEdit)
                                                            ? "0.6"
                                                            : "1",
                                                }}
                                                key={p.mentionId}
                                            >
                                                {p.toEdit && editedMention ? (
                                                    <EditingRow
                                                        editedMention={
                                                            editedMention
                                                        }
                                                        mentionTypes={
                                                            mentionTypes
                                                        }
                                                        handleCancel={
                                                            handleCancel
                                                        }
                                                        handleChange={
                                                            handleChange
                                                        }
                                                        handleSave={handleSave}
                                                        getMentionType={
                                                            getMentionType
                                                        }
                                                    />
                                                ) : (
                                                    <React.Fragment>
                                                        <TableCell>
                                                            <Box display="flex">
                                                                <IconButton
                                                                    disabled={
                                                                        mode ===
                                                                            "add" ||
                                                                        mode ===
                                                                            "edit"
                                                                    }
                                                                    onClick={() =>
                                                                        handleEdit(
                                                                            p.mentionId
                                                                        )
                                                                    }
                                                                >
                                                                    <Icons.Edit />
                                                                </IconButton>
                                                                <IconButton
                                                                    disabled={
                                                                        mode ===
                                                                            "add" ||
                                                                        mode ===
                                                                            "edit"
                                                                    }
                                                                    onClick={() =>
                                                                        handleOpen(
                                                                            p.mentionId
                                                                        )
                                                                    }
                                                                >
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            </Box>
                                                        </TableCell>
                                                        <TableCell>
                                                            {p.mentionId}
                                                        </TableCell>
                                                        <TableCell>
                                                            {getMentionType(
                                                                p.type
                                                            )}
                                                        </TableCell>
                                                        <TableCell>
                                                            {p.name}
                                                        </TableCell>
                                                        <TableCell>
                                                            {p.guidance}
                                                        </TableCell>
                                                        <TableCell>
                                                            {p.icon}
                                                            <Box display="flex">
                                                                <MaterialIcon
                                                                    fontSize="small"
                                                                    icon={
                                                                        p.icon
                                                                    }
                                                                />
                                                            </Box>
                                                        </TableCell>
                                                        <TableCell>
                                                            {p.options}
                                                        </TableCell>
                                                    </React.Fragment>
                                                )}
                                            </TableRow>
                                        ))}
                                    {mode === "add" && editedMention && (
                                        <TableRow>
                                            <EditingRow
                                                editedMention={editedMention}
                                                mentionTypes={mentionTypes}
                                                handleCancel={handleCancel}
                                                handleChange={handleChange}
                                                handleSave={handleSave}
                                                getMentionType={getMentionType}
                                            />
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={count}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </React.Fragment>
                    )}
                </CardContent>
            </Card>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>
                    Delete mention {selectedMention && selectedMention.name}?
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        This action cannot be undone
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <MuiIconButton onClick={handleClose} color="primary">
                        Cancel
                    </MuiIconButton>
                    <DeleteButton onClick={handleDelete} color="primary">
                        Delete
                    </DeleteButton>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

export default connect()(Mentions);
