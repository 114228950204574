import React from 'react';
import {
	Card,
	CardContent,
	Box,
	Divider as MuiDivider,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Typography,
	Icon as MuiIcon,
	Tooltip,
	Avatar,
	Chip as MuiChip,
	CardHeader
} from '@material-ui/core';
import { spacing } from "@material-ui/system";
import styled from "styled-components";
import { grey } from "@material-ui/core/colors";
import avatarStyles from "../theme/avatars";
import _ from 'lodash';

import {
	Phone,
	Email,
	Description,
	Create,
	Help,
	Person,
	PermPhoneMsg
} from '@material-ui/icons';

const Divider = styled(MuiDivider)(spacing);

const Icon = styled(MuiIcon)`
	color: ${grey[700]}
`;

const Chip = styled(MuiChip)`
  height: 20px;
  padding: 4px 0;
  font-size: 85%;
  background-color: ${props => props.theme.palette.secondary.main};
  color: ${props => props.theme.palette.common.white};
  span {
    padding-left: ${props => props.theme.spacing(2)}px;
    padding-right: ${props => props.theme.spacing(2)}px;
  }
`;

const activityMap = {
	"Email": {
		label: "Emails",
		icon: <Email />
	},
	"Note": {
		label: "Call Notes",
		icon: <PermPhoneMsg />
	},
	"InternalNote": {
		label: "Internal Notes",
		icon: <Create />
	},
	"Call": {
		label: "Calls",
		icon: <Phone />
	},
	"Misc": {
		label: "Misc Activities",
		icon: <Help />
	},
	"Document": {
		label: "Documents",
		icon: <Description />
	},
	"Adviser": {
		label: "Adviser Activities",
		icon: <Person />
	}
}

const UserStatsCard = ({
	u,
	activities,
	totalActivities,
	totalActivityMinutes,
	isDashboard,
	fromDate,
	toDate
}) => {
	const avatarClasses = avatarStyles();

	return (
		<Card>
			{isDashboard && <CardHeader
				action={
					<Chip label={!(toDate && fromDate) ? "Last week" : "Custom date range"} />
				}
			/>}
			<CardContent>
				<Box display="flex" flexDirection="column" alignItems="center" mt={3} mb={3} ml={6} mr={6}>
					<Avatar className={avatarClasses.xlarge} src={u.photo} alt={u.name} />
					<Typography variant="subtitle2">{u.position || ""}</Typography>
					<Typography variant="caption">{u.name}</Typography>
				</Box>
				<Divider my={6} />
				<Table>
					<TableHead>
						<TableRow>
							<TableCell></TableCell>
							<TableCell>Number</TableCell>
							<TableCell>Minutes</TableCell>
							<TableCell>Average</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{activities.map(a =>
							<TableRow key={a.activityType}>
								<TableCell><Tooltip title={activityMap[a.activityType].label}><Icon>{activityMap[a.activityType].icon}</Icon></Tooltip></TableCell>
								<TableCell>{a.totalActivities}</TableCell>
								<TableCell>{_.round(a.totalDuration / 60, 1)}</TableCell>
								<TableCell>{a.totalActivities === 0 ? 0 : _.round((a.totalDuration / 60) / a.totalActivities, 1)}</TableCell>
							</TableRow>
						)}
						<TableRow>
							<TableCell>Total</TableCell>
							<TableCell>{totalActivities}</TableCell>
							<TableCell>{_.round(totalActivityMinutes, 1)}</TableCell>
							<TableCell>{totalActivities === 0 ? 0 : _.round(totalActivityMinutes / totalActivities, 1)}</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</CardContent>
		</Card>
	);
}
	

export default UserStatsCard;