import { useMutation } from "react-query";
import queryKeys from "../../constants/queryKeys";
import reactQueryClient from "../../reactQueryClient";
import userAutomaticEmailReplyService from "../../services/userAutomaticEmailReplyService";

const useUpdateUserAutomaticEmailReply = () =>
    useMutation(
        reply => userAutomaticEmailReplyService.updateForUser(reply),
        {
            onSuccess: (result) => {
                const queryKey = [queryKeys.userAutomaticEmailReply, result.data.userId];
                reactQueryClient.setQueryData(queryKey, () => ({ fromDate: null, toDate: null, ...result.data}));
            },
        }
    );

export default useUpdateUserAutomaticEmailReply;