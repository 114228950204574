import {useMutation} from "react-query";
import caseTimeEventGroupService from "../../services/caseTimeEventGroupService";
import queryKeys from "../../constants/queryKeys";
import reactQueryClient from "../../reactQueryClient";

const useDiscardCaseTimeEventGroups = () =>
  useMutation(
    ({ caseTimeEventGroupIds }) => caseTimeEventGroupService.discard({ caseTimeEventGroupIds }), {
      onMutate: async ({ caseTimeEventGroupIds, userId, accountId }) => {
        const queryKey = [queryKeys.caseTimeEventGroups, userId, accountId];
        const queryKeyToInvalidate = [queryKeys.caseTimeEventGroups, userId];
        reactQueryClient.cancelQueries(queryKey);
        const previousGroups = reactQueryClient.getQueryData(queryKey);
        reactQueryClient.setQueryData(queryKey, (prev) => ([
          ...((prev|| []).filter(x => !caseTimeEventGroupIds.includes(x.id)) || [])
        ]));
        return { previousGroups, queryKey, queryKeyToInvalidate };
      },
      onError: (err, _newGroups, context) => {
        reactQueryClient.setQueryData(
          context.queryKey,
          context.previousGroups
        );
      },
      onSettled: (data, err, _newGroups, context) => {
        reactQueryClient.invalidateQueries(context.queryKeyToInvalidate);
      },
    });

export default useDiscardCaseTimeEventGroups;