import { useQuery } from "react-query";
import queryKeys from "../../constants/queryKeys";
import caseTimeEventGroupService from "../../services/caseTimeEventGroupService";

const useCaseTimeEventGroupsForUser = ({ userId }) =>
    useQuery(
        [queryKeys.caseTimeEventGroups, userId],
        () => caseTimeEventGroupService.getForUser({ userId }),
        {
            enabled: !!userId,
        }
    );

export default useCaseTimeEventGroupsForUser;
