import React, { useState } from 'react';
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    content: {
        padding: theme.spacing(3),
    },
}))

export const RenamePrecedentDialog = ({ open, value, onClose, onSubmit }) => {
  const classes = useStyles()
  const [title, setTitle] = useState(value)
  const handleChange = (e) => setTitle(e.target.value)
  return (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle >Rename precedent title</DialogTitle>
        <DialogContent className={classes.dialog} dividers>
          <TextField
            autoFocus
            value={title}
            onChange={handleChange}
            margin="dense"
            id="name"
            label="Title"
            type="text"
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="default" onClick={onClose}>Cancel</Button>
          <Button variant="contained" color="primary" onClick={() => onSubmit(title)}>Submit</Button>
        </DialogActions>
      </Dialog>
  );
}

export default RenamePrecedentDialog