import { useQuery } from "react-query";
import queryKeys from "../../constants/queryKeys";
import caseActionService from "../../services/caseActionService";

const useCaseActionTypes = ({ dialogOpen, caseActionCategoryId }) =>
    useQuery(
        [queryKeys.caseActionTypes, caseActionCategoryId],
        () => caseActionService.getCaseActionTypes(caseActionCategoryId),
        {
            enabled: !!dialogOpen && !!caseActionCategoryId,
            staleTime: Infinity
        }
    )

export default useCaseActionTypes;