import React, { useState, useEffect } from "react";
import axios from "../plugins/axios";
import { connect } from "react-redux";
import Loader from "../components/Loader";
import { Line as LineChart } from "react-chartjs-2";
import { bindActionCreators } from "redux";
import { actionCreators } from "../redux/reducers/caseReducer";
import styled from "styled-components";

import {
    Box,
    Button as MuiIconButton,
    Card as MuiCard,
    CardContent,
    CardHeader,
    Collapse,
    Table,
    TableCell,
    TableContainer,
    TableBody,
    TableHead,
    TableRow,
    Typography,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";

import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

import {
    AccountCircle as MyActivityIcon,
    SupervisedUserCircle as TeamActivityIcon,
} from "@material-ui/icons";

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

const Card = styled(MuiCard)(spacing);

const ChartWrapper = styled.div`
    height: 378px;
    position: relative;
`;

const IconButton = styled(MuiIconButton)`
    padding: 0px;
    margin-top: 2px;
    margin-left: 0px;
    min-width: 0;
`;

const TimesheetTable = ({
    configState,
    handleClick,
    adviserId,
    timesheetPeriod,
    caseTypeId,
    fromDate,
    toDate,
    employee,
    account,
    partnershipId,
    partnerNumber,
    clickable,
    adviserName,
    hasLegalManagementRole,
    chartContent,
}) => {
    const [footerText, setFooterText] = useState(adviserName);
    const [results, setResults] = useState(null);
    const [dbData, setDbData] = useState(null);
    const [haveDataToDisplay, setHaveDataToDisplay] = useState(false);

    const [displayTimeDetails, setDisplayTimeDetails] = React.useState(false);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [loading, setLoading] = useState(false);
    const [detailsHeadingText, setDetailsHeadingText] = useState("");

    const getCaseType = (id) => configState.caseTypes[id] || {};

    const updateTimesheetDetail = async (
        dateSelected,
        adviserId,
        adviserName
    ) => {
        var date = new Date();
        date.toJSON(); // this is the JavaScript date as a c# DateTime

        let response = await axios.post(
            `${apiUrl}/gettimesheetdetailtabledata`,
            {
                adviserId: adviserId,
                dateSelected: dateSelected,
                chartContent: chartContent,
            }
        );

        if (response.status === 200) {
            setResults(response.data.timesheetDetailData);

            setDetailsHeadingText(
                adviserName + " time recorded for " + dateSelected
            );
            setDisplayTimeDetails(true);
        }
    };

    useEffect(() => {
        //update case count when page is hit
        setDisplayTimeDetails(false);
        updateCases(footerText == "All Users" ? 0 : adviserId);
    }, [chartContent]);

    const updateCases = async (advId) => {
        setLoading(true);
        var criteria = {
            pageSize: rowsPerPage,
            pageNum: page + 1,
            adviserId: advId,
            timesheetPeriod: timesheetPeriod,
            chartContent: chartContent,
        };

        var response = await axios.post(
            `${apiUrl}/gettimesheettabledata`,
            criteria
        );
        console.log("jawa_dbData", response);
        if (response.data.dashboardChart.datasets.length > 0) {
            setHaveDataToDisplay(true);
            setDbData(response.data.dashboardChart);
        }

        var date = new Date();
        date.setDate(date.getDate() - 7);

        var now = new Date();
        var daysOfYear = [];
        for (var d = date; d <= now; d.setDate(d.getDate() + 1)) {
            daysOfYear.push(new Date(d));
        }

        setLoading(false);
    };

    const data = (canvas) => {
        return {
            labels: dbData && dbData.labels,
            datasets: dbData && dbData.datasets,
        };
    };

    const options = {
        maintainAspectRatio: false,
        onClick: function (evt) {
            var element = this.getElementAtEvent(evt);
            if (element.length > 0) {
                var clickedDatasetIndex = element[0]._datasetIndex;
                var clickedElementIndex = element[0]._index;
                var clickedDatasetPoint = dbData.datasets[clickedDatasetIndex];

                var labelDate = dbData.labels[clickedElementIndex];
                var label = clickedDatasetPoint.label;
                var adviserId = clickedDatasetPoint.adviserId;
                updateTimesheetDetail(labelDate, adviserId, label);
            }
        },
        //coverting seconds to hh:mm:ss is covered in the following github page
        //https://github.com/chartjs/Chart.js/issues/2791
        tooltips: {
            callbacks: {
                label: function (tooltipItem, data) {
                    return (
                        data.datasets[tooltipItem.datasetIndex].label +
                        ": " +
                        epoch_to_hh_mm_ss(tooltipItem.yLabel) +
                        "(hh:mm:ss)"
                    );
                },
            },
        },
        hover: {
            intersect: true,
        },
        plugins: {
            filler: {
                propagate: false,
            },
        },
        scales: {
            xAxes: [
                {
                    reverse: true,
                    gridLines: {
                        color: "rgba(0,0,0,0.0)",
                    },
                },
            ],
            yAxes: [
                {
                    ticks: {
                        userCallback: function (v) {
                            return epoch_to_hh_mm_ss(v);
                        },
                        stepSize: 30 * 60,
                    },
                    display: true,
                    borderDash: [5, 5],
                    gridLines: {
                        color: "rgba(0,0,0,0.0375)",
                        fontColor: "#fff",
                    },
                },
            ],
        },
    };

    function epoch_to_hh_mm_ss(epoch) {
        //if (epoch > 100)
        //{
        return new Date(epoch * 1000).toISOString().match("T(.*).000Z")?.[1];
        //}
        //else return epoch;
    }

    const handleSwitchIncludeViews = () => {
        //only allow a switch to 'All Users' if the user has the role 'LegalManagement' assigned to them
        if (hasLegalManagementRole) {
            setDisplayTimeDetails(false);
            setFooterText(
                footerText == "All Users" ? adviserName : "All Users"
            );
            updateCases(footerText == "All Users" ? adviserId : 0);
        }
    };

    useEffect(() => {
        //page === 0 ? updateCases(adviserId) : setPage(0);
    }, [
        caseTypeId,
        adviserId,
        fromDate,
        toDate,
        employee,
        account,
        partnershipId,
        partnerNumber,
    ]);

    function Row(results) {
        const { row } = results;
        const [open, setOpen] = React.useState(false);

        return (
            <React.Fragment>
                <TableRow
                    key={row.caseId}
                    style={{
                        cursor: clickable ? "pointer" : "default",
                        height: "100%",
                    }}
                    hover={clickable ? true : false}
                    onClick={() => clickable && setOpen(!open)}
                >
                    <TableCell>
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpen(!open)}
                        >
                            {open ? (
                                <KeyboardArrowUpIcon />
                            ) : (
                                <KeyboardArrowDownIcon />
                            )}
                        </IconButton>
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {row.caseId}
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {row.accountName}
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {getCaseType(row.caseTypeId).name}
                    </TableCell>
                    <TableCell>
                        {epoch_to_hh_mm_ss(row.durationSeconds)}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell
                        style={{
                            paddingBottom: 0,
                            paddingTop: 0,
                            backgroundColor: "#f9f9f9",
                        }}
                        colSpan={3}
                    >
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box margin={1}>
                                <Typography
                                    variant="h6"
                                    gutterBottom
                                    component="div"
                                >
                                    Case Time Breakdown
                                </Typography>
                                <Table size="small" aria-label="purchases">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                Time Event Type
                                            </TableCell>
                                            <TableCell>
                                                Duration (hh:mm:ss)
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {row.details.map((detailRow) => (
                                            <TableRow
                                                key={detailRow.timeEventType}
                                                style={{
                                                    cursor: clickable
                                                        ? "pointer"
                                                        : "default",
                                                    height: "100%",
                                                }}
                                                hover={clickable ? true : false}
                                                onClick={() =>
                                                    clickable &&
                                                    handleClick(detailRow)
                                                }
                                            >
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                >
                                                    {detailRow.timeEventTypexx}
                                                </TableCell>
                                                <TableCell>
                                                    {epoch_to_hh_mm_ss(
                                                        detailRow.durationSeconds
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </React.Fragment>
        );
    }

    {
        if (!haveDataToDisplay) {
            return (
                <Typography variant="h6" gutterBottom component="div">
                    No time allocated against adviser
                </Typography>
            );
        } else {
            return !dbData || loading ? (
                <Loader />
            ) : (
                <React.Fragment>
                    <Card>
                        <CardContent>
                            <ChartWrapper>
                                <LineChart data={data} options={options} />
                            </ChartWrapper>
                        </CardContent>
                        <CardHeader
                            action={
                                <IconButton
                                    aria-label="settings"
                                    onClick={() => handleSwitchIncludeViews()}
                                >
                                    {footerText == "User" ? (
                                        <MyActivityIcon />
                                    ) : (
                                        <TeamActivityIcon />
                                    )}
                                </IconButton>
                            }
                            title={
                                <Box
                                    display="flex"
                                    justifyContent="flex-end"
                                    mt={-2}
                                >
                                    {footerText}
                                </Box>
                            }
                        />
                    </Card>

                    {results && displayTimeDetails && (
                        <TableContainer>
                            <Typography variant="h3">
                                {detailsHeadingText}
                            </Typography>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell>Case Number</TableCell>
                                        <TableCell>Account</TableCell>
                                        <TableCell>Case Type</TableCell>
                                        <TableCell>
                                            Duration (hh:mm:ss)
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {results
                                        .sort(
                                            (a, b) =>
                                                b.durationSeconds -
                                                a.durationSeconds
                                        )
                                        .map((row) => (
                                            <Row key={row.name} row={row} />
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                </React.Fragment>
            );
        }
    }
};

const mapStateToProps = (state) => ({
    configState: state.configReducer,
    userState: state.userReducer,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(actionCreators, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TimesheetTable);
