import { useQuery } from 'react-query'
import queryKeys from '../../constants/queryKeys'
import caseService from '../../services/caseService'

const useETClaims = (etCaseId) => useQuery(
    [queryKeys.eTClaims, etCaseId],
    () => caseService.getETClaims(etCaseId), 
    {
        enabled: !!etCaseId
    })

export default useETClaims;