import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  DialogActions,
  Button,
  Box, 
  DialogContentText,
  makeStyles,
} from "@material-ui/core";
import {formatTimeHms} from "../helpers/formatTime";

const useStyles = makeStyles(theme => ({
  yesButton: {
    marginLeft: theme.spacing(2),
  }
}));

const AllocatedCallTimeDialog = ({ allocatedCallTime, handleClose, caseId, handleConfirm }) => {
  const classes = useStyles();
  return (
    <Dialog 
      open={!!allocatedCallTime}
      onClose={handleClose}
    >
      <DialogTitle>Assign previously allocated call time?</DialogTitle>
      <DialogContent>
        <DialogContentText>Time from this call has already been assigned against (but not logged against an activity) the following cases:</DialogContentText>
        <List>
          {allocatedCallTime?.map(x =>
            <ListItem key={x.caseId}>
              <DialogContentText>Case {x.caseId} ({formatTimeHms(x.duration)})</DialogContentText>
            </ListItem>
          )}
        </List>
        <DialogContentText>Do you want to assign the time to Case {caseId} instead?</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Box display="flex" justifyContent="space-between" width="100%">
          <Button onClick={handleClose}>Cancel</Button>
          <div>
            <Button 
              variant="contained" 
              color="primary" 
              onClick={() => handleConfirm(false)}
            >
              No (Save call)
            </Button>
            <Button 
              className={classes.yesButton} 
              variant="contained" 
              color="primary" 
              onClick={() => handleConfirm(true)}
            >
              Yes (Save call)
            </Button>
          </div>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

export default AllocatedCallTimeDialog;