import React from "react";
import { Button, Badge, Typography } from "@material-ui/core";
import { useEmailDraft } from "../contexts/emailDraftContext";

export default function EmailDrafts() {
    const { setDraftEmailSummaryDialogOpen, draftEmailsSummary } = useEmailDraft();

    return (
        <Button onClick={() => setDraftEmailSummaryDialogOpen(true)} color="primary" data-cy="draft_emails">
            <Badge overlap="rectangular" badgeContent={draftEmailsSummary.data.data.length} color="error">
                <Typography variant="subtitle2">Draft Emails</Typography>
            </Badge>
        </Button>
    );
}
