import React, { useEffect, useState, useCallback, useMemo } from "react";
import { connect } from "react-redux";
import Helmet from "react-helmet";

import {
    Box,
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Avatar,
    Divider as MuiDivider,
    Grid,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import { spacing } from "@material-ui/system";
import styled from "styled-components";
import axios from "../plugins/axios";
import { KeyboardDatePicker } from "@material-ui/pickers";
import UserStatsCard from "../cards/UserStatsCard";
import avatarStyles from "../theme/avatars";
import useFilteredAdvisers from "../hooks/useFilteredAdvisers";

const formStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(3),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const Divider = styled(MuiDivider)(spacing);

const dateRanges = ["day", "week", "month", "all"];
const dateLabels = {
    day: "Past 24 hours",
    week: "Past week",
    month: "Past month",
    all: "All time",
};

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

const TeamOverview = ({ userState }) => {
    const formClasses = formStyles();
    const avatarClasses = avatarStyles();
    const filteredAdvisers = useFilteredAdvisers();
    const [managerId, setManagerId] = useState("");
    const [teamStats, setTeamStats] = useState(null);
    const [dateRange, setDateRange] = useState("week");
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);

    const managerIds = useMemo(
        () =>
            Object.values(userState.users)
                .filter((u) => u.managerId)
                .map((u) => u.managerId),
        [userState.users]
    );

    const getTeamInfo = useCallback(async () => {
        if ((!fromDate && toDate) || (fromDate && !toDate) || !managerId)
            return;
        if (fromDate && toDate && dateRange) return;
        if (!(fromDate && toDate) && !dateRange) return;
        if (managerId) {
            let response = await axios.post(`${apiUrl}/getteaminfo`, {
                managerId,
                dateRange,
                fromDate,
                toDate,
            });

            const activeUserIds = Object.values(userState.users)
                .filter((x) => x.isActive && !x.isDisabled)
                .map((x) => x.userId);

            const activeTeamMembers = response.data.filter((x) =>
                activeUserIds.includes(x.userId)
            );

            setTeamStats(activeTeamMembers);
        }
    }, [managerId, dateRange, fromDate, toDate]);

    const handleChangeFromDate = (date) => {
        setDateRange("");
        setFromDate(date);
    };

    const handleChangeToDate = (date) => {
        setDateRange("");
        setToDate(date);
    };

    const handleChangeDateRange = (e) => {
        setToDate(null);
        setFromDate(null);
        setDateRange(e.target.value);
    };

    useEffect(() => {
        getTeamInfo();
    }, [getTeamInfo]);

    const isManager = (adviser) => managerIds.includes(adviser.userId);

    return (
        <React.Fragment>
            <Helmet title="Team Overview" />
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="flex-end"
            >
                <Typography variant="h3">Team Overview</Typography>
                <Box
                    display="flex"
                    justifyContent="flex-end"
                    flexDirection="column"
                >
                    <Box
                        display="flex"
                        justifyContent="flex-end"
                        alignItems="flex-end"
                    >
                        <FormControl
                            style={{ paddingBottom: "8px" }}
                            className={formClasses.formControl}
                        >
                            <InputLabel>Manager</InputLabel>
                            <Select
                                value={managerId}
                                onChange={(e) => setManagerId(e.target.value)}
                            >
                                {filteredAdvisers
                                    .filter(isManager)
                                    .map((manager) => (
                                        <MenuItem
                                            key={manager.userId}
                                            value={manager.userId}
                                        >
                                            <Box
                                                display="flex"
                                                alignItems="center"
                                            >
                                                <Avatar
                                                    style={{
                                                        marginRight: "8px",
                                                    }}
                                                    className={
                                                        avatarClasses.small
                                                    }
                                                    alt={manager.name}
                                                    src={manager.photo}
                                                />
                                                {manager.name}
                                            </Box>
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                        <FormControl
                            style={{ paddingBottom: "8px" }}
                            className={formClasses.formControl}
                        >
                            <InputLabel>Stats from</InputLabel>
                            <Select
                                value={dateRange}
                                onChange={handleChangeDateRange}
                            >
                                {dateRanges.map((d) => (
                                    <MenuItem key={d} value={d}>
                                        {dateLabels[d]}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    <Box
                        display="flex"
                        justifyContent="flex-end"
                        alignItems="flex-end"
                    >
                        <Typography variant="caption">
                            Or select date range...
                        </Typography>
                        <FormControl
                            style={{ marginLeft: "12px", width: "140px" }}
                        >
                            <KeyboardDatePicker
                                value={fromDate}
                                onChange={(date) => handleChangeFromDate(date)}
                                label="From date"
                                format="dd/MM/yyyy"
                                maxDate={toDate}
                            />
                        </FormControl>
                        <FormControl
                            style={{ marginLeft: "8px", width: "140px" }}
                        >
                            <KeyboardDatePicker
                                value={toDate}
                                onChange={(date) => handleChangeToDate(date)}
                                label="To date"
                                format="dd/MM/yyyy"
                                minDate={fromDate}
                            />
                        </FormControl>
                    </Box>
                </Box>
            </Box>
            <Divider my={6} />
            <Grid
                container
                spacing={2}
                direction="row"
                justify="center"
                alignItems="flex-start"
            >
                {teamStats ? (
                    <React.Fragment>
                        {teamStats.map((user) => (
                            <Grid item xs={12} sm={6} md={4} key={user.userId}>
                                <UserStatsCard
                                    u={userState.users[user.userId]}
                                    activities={user.activities}
                                    totalActivities={user.totalActivities || 0}
                                    totalActivityMinutes={
                                        user.totalActivityDuration
                                            ? user.totalActivityDuration / 60
                                            : 0
                                    }
                                />
                            </Grid>
                        ))}
                    </React.Fragment>
                ) : (
                    <div>Please select a manager and date range</div>
                )}
            </Grid>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => ({ userState: state.userReducer });

export default connect(mapStateToProps)(TeamOverview);
