import axios from "../plugins/axios";

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

const sendEmail = ({
    caseId,
    contractId,
    event,
    files,
    draftAttachmentIds,
    caseTimeEventGroupIds,
    liveCaseDocumentIds,
    azureFileIds,
    sizeExcludingLiveDocs,
}) => {
    let formData = new FormData();

    formData.append("eventJson", JSON.stringify(event));
    for (let i = 0; i < caseTimeEventGroupIds.length; i++)
        formData.append(`caseTimeEventGroupIds[${i}]`, caseTimeEventGroupIds[i]);

    formData.append("stringifiedFiles", JSON.stringify(files.map(file => ({ reference: file.id, name: file.file.name, type: file.file.type, size: file.file.size, azureFileId: file.azureFileId }))));

    for (const draftAttachmentId of draftAttachmentIds) {
        formData.append("draftAttachmentIds", draftAttachmentId);
    }

    for (const liveCaseDocumentId of liveCaseDocumentIds) {
        formData.append("liveCaseDocumentIds", liveCaseDocumentId);
    }

    for (const azureFileId of azureFileIds) {
        formData.append("azureFileIds", azureFileId);
    }

    formData.append("sizeExcludingLiveDocs", sizeExcludingLiveDocs);

    return axios.post(`${apiUrl}/sendreply/${caseId}/${contractId}`, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
};

const emailService = {
    sendEmail,
};

export default emailService;
