import { useQuery } from "react-query";
import queryKeys from "../../constants/queryKeys";
import caseActionService from "../../services/caseActionService";

const useCaseActionsDashboard = ({
    pageSize,
    pageNum,
    fromDate,
    toDate,
    adviserId,
    actionTypeId,
    actionCategoryId,
    teamId,
    includeCompleted,
    isPaged
}) =>
    useQuery(
        [queryKeys.caseActionsDashboard, pageSize, pageNum, fromDate, toDate, adviserId, actionTypeId, actionCategoryId, teamId,includeCompleted, isPaged],
        () => caseActionService.getCaseActionsDashboard(pageSize, pageNum, fromDate, toDate, adviserId, actionTypeId, actionCategoryId, teamId, includeCompleted, isPaged),
        {
            enabled: 
                !!pageSize ||
                !!pageNum ||
                !!fromDate ||
                !!toDate ||
                !!adviserId ||
                !!actionTypeId ||
                !!actionCategoryId ||
                !!teamId ||
                !!includeCompleted ||
                !!isPaged
        }
    )


export default useCaseActionsDashboard;