import axios from "../plugins/axios";

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

const fetchUrlForUser = async ({ userId }) => {
    try {
        const response = await axios.get(`${apiUrl}/voicemail/${userId}`);
        return response.data;
    }
    catch (e) {
        console.error(e);
        return Promise.reject(e?.message || "Could not fetch voicemail for user");
    }
}

const updateForUser = async ({ formData, userId }) => {
    try {
        const response = await axios.put(`${apiUrl}/voicemail/${userId}`, formData, {
            headers: { "Content-Type": "multipart/form-data" }
        });
        return response.data;
    }
    catch (e) {
        console.error(e);
        return Promise.reject(e?.message || "Could not update voicemail for user");
    }
}

const deleteForUser = ({ userId }) =>
    axios.delete(`${apiUrl}/voicemail/${userId}`);
    

const voicemailService = {
    fetchUrlForUser,
    updateForUser,
    deleteForUser,
}

export default voicemailService;