import * as types from '../constants';

export function setServerValues(values) {
    return {
        type: types.SET_SERVER_VALUES,
        serverValues: values
    }
}


export function setCaseTypes(caseTypes) {
    return {
        type: types.SET_CASE_TYPES,
        caseTypes: caseTypes
    }
}

export function setPrecedentFolders(precedentFolders) {
    return {
        type: types.SET_PRECEDENT_FOLDERS,
        precedentFolders: precedentFolders
    }
}

export function upsertCaseTypesAction(caseTypes) {
    return {
        type: types.UPSERT_CASE_TYPES,
        caseTypes: caseTypes
    }
}

export function setConfigInfo(info) {
    return {
        type: types.SET_CONFIG_INFO,
        info: info
    }
}

export function setPrecedent(precedent) {
    return {
        type: types.SET_PRECEDENT_INFO,
        precedent: precedent
    }
}

export function setPrecedentfolder(precedentId, precedentFolderId) {
    return {
        type: types.SET_PRECEDENT_FOLDER,
        precedentFolderId: precedentFolderId,
        precedentId: precedentId
    }
}

export function setPrecedentDisabledFlag(precedentId, isDisabled) {
    return {
        type: types.SET_PRECEDENT_DISABLED_FLAG,
        precedentId: precedentId,
        disabled: isDisabled
        
    }
}

export function setPrecedentFolderDisabledFlag(precedentFolderId, isDisabled) {
    return {
        type: types.SET_PRECEDENT_FOLDER_DISABLED_FLAG,
        precedentFolderId: precedentFolderId,
        disabled: isDisabled

    }
}

export function setPrecedentIsAdvisor(precedentId, isAdvisor) {
    return {
        type: types.SET_PRECEDENT_IS_ADVISOR,
        precedentId: precedentId,
        isAdvisor: isAdvisor
    }
}

export function setPrecedentResourceLicences(precedentId, licences) {
    return {
        type: types.SET_PRECEDENT_RESOURCE_LICENCES,
        precedentId: precedentId,
        licences
    }
}

export function setClaimTypes(claimTypes) {
    return {
        type: types.SET_CLAIM_TYPES,
        claimTypes
    }
}

export function setPrecedents(precedents) {
    return {
        type: types.SET_PRECEDENTS,
        precedents
    }
}


export function setVenues(venues) {
    return {
        type: types.SET_VENUES,
        venues
    }
}

export function setCaseStages(caseTypeId, stages) {
    return {
        type: types.SET_CASE_STAGES,
        caseTypeId,
        stages
    }
}

//export function setAccountCaseTypes(caseTypeId, accountCaseTypes) {
//    return {
//        type: types.SET_ACCOUNT_CASE_TYPES,
//        caseTypeId,
//        accountCaseTypes
//    }
//}

export function setStageTasks(caseTypeId, caseStageId, stageTasks) {
    return {
        type: types.SET_STAGE_TASKS,
        caseTypeId,
        caseStageId,
        stageTasks
    }
}

export function setMentionsAction(mentions) {
    return {
        type: types.SET_MENTIONS,
        mentions
    }
}

export function setPartnershipsAction(partnerships) {
    return {
        type: types.SET_PARTNERSHIPS,
        partnerships
    }
}

export function setEmailExceptionFilterAction(emailExceptionFilters) {
    return {
        type: types.SET_EMAIL_EXCEPTION_FILTERS,
        emailExceptionFilters
    }
}
