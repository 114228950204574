import { useQuery } from "react-query";
import queryKeys from "../../constants/queryKeys";
import accountService from "../../services/accountService";

const useAccountContractSummary = ({
    accountId,
    pageSize,
    pageNum,
    includeExpired,
}) =>
    useQuery(
        [
            queryKeys.accountContractSummary,
            accountId,
            pageSize,
            pageNum,
            includeExpired,
        ],
        () =>
            accountService.getAccountContractSummary({
                accountId,
                pageSize,
                pageNum,
                includeExpired,
            }),
        {
            enabled: !!accountId && !!pageSize,
            staleTime: 60000,
            cacheTime: 90000,
        }
    );

export default useAccountContractSummary;
