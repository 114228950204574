import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import useProductRecommendations from "../hooks/queries/useProductRecommendations";
import queryKeys from "../constants/queryKeys";
import reactQueryClient from "../reactQueryClient";
import {
    CardContent,
    Card,
    Typography,
    Grid,
} from "@material-ui/core";
import ProductRecommendationsPanel from "./ProductRecommendationPanel";

const useStyles = makeStyles((theme) => ({
    card: {
        marginTop: theme.spacing(6),
    },    
}));

const ProductRecommendationsCard = ({ account, adviceTypeId, caseId }) => {    

    const { data: recommendations } = useProductRecommendations(account.accountId, adviceTypeId, caseId);
    
    const classes = useStyles();

    if (!recommendations || recommendations.length === 0)
        return null;    

    const refresh = () => {
        reactQueryClient.invalidateQueries([queryKeys.productRecommendations, account.accountId, adviceTypeId, caseId]);
    }    

    const isSingle = recommendations.length === 1; 

    return (
        <Card className={classes.card}>
            <CardContent>
                <Typography variant="h6" gutterBottom>
                    Product Recommendations
                </Typography>
                <Typography>Recommendations are based on case history data.  Record the client's response using the options provided.</Typography>
                <Grid container spacing={3}>
                    {recommendations.map(product =>
                        <ProductRecommendationsPanel key={product.id} product={product} refresh={refresh} isSingle={isSingle} account={account} id={product.id} />                     
                    )}
                </Grid>                
            </CardContent>            
        </Card>
    );
};

export default ProductRecommendationsCard;
