import {
    Add,
    Alarm,
    Assistant,
    Business,
    CalendarToday,
    CheckBox,
    ContactMail,
    DateRange,
    Delete,
    Description,
    Details,
    Edit,
    Email,
    Error,
    Event,
    Face,
    FormatListNumbered,
    Help,
    Info,
    List,
    LocationCity,
    MeetingRoom,
    Money,
    Nature,
    People,
    Person,
    PersonAdd,
    PersonOutline,
    Phone,
    QuestionAnswer,
    Timelapse,
    Timeline,
    Title,
    Today
} from '@material-ui/icons';

const Icons = {
    Add,
    Alarm,
    Assistant,
    Business,
    CalendarToday,
    CheckBox,
    ContactMail,
    DateRange,
    Delete,
    Description,
    Details,
    Edit,
    Email,
    Error,
    Event,
    Face,
    FormatListNumbered,
    Help,
    Info,
    List,
    LocationCity,
    MeetingRoom,
    Money,
    Nature,
    People,
    Person,
    PersonAdd,
    PersonOutline,
    Phone,
    QuestionAnswer,
    Timelapse,
    Timeline,
    Title,
    Today
};

export default Icons;