import React, { useState, useMemo } from "react";
import styled, { withTheme } from "styled-components";
import { connect } from "react-redux";
import { darken } from "polished";
import { useHistory } from "react-router-dom";
import { bindActionCreators } from "redux";
import { actionCreators } from "../redux/reducers/caseReducer";
import UserTimerHOC from "./userTimer/UserTimerHOC";
import { useAuth } from "../contexts/authContext";
import { setIsSearching } from "../redux/actions/caseActions";
import caseNestLogo from "../images/CaseNest_Purple_Positive_RGB (1).svg";
import {
    Grid,
    Button,
    InputBase,
    Menu,
    MenuItem,
    AppBar as MuiAppBar,
    IconButton as MuiIconButton,
    Toolbar,
    InputAdornment,
    CircularProgress,
    Typography,
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import {
    Menu as MenuIcon,
    ChevronLeft as HideMenuIcon
} from "@material-ui/icons";
import { Search as SearchIcon, Power, ArrowRightCircle } from "react-feather";
import CaseActivityTimer from "./CaseActivityTimer";
import HeldTimeButton from "./HeldTimeButton";
import useUserAutomaticEmailReply from "../hooks/queries/useUserAutomaticEmailReply";
import useUpdateUserAutomaticEmailReply from "../hooks/mutations/useUpdateUserAutomaticEmailReply";

const AppBar = styled(MuiAppBar)`
    background: ${(props) => props.theme.header.background};
    color: ${(props) => props.theme.header.color};
    box-shadow: ${(props) => props.theme.shadows[1]};
`;

const IconButton = styled(MuiIconButton)`
    svg {
        width: 22px;
        height: 22px;
    }
`;

const Search = styled.div`
    border-radius: 2px;
    background-color: ${(props) => props.theme.header.background};
    display: none;
    position: relative;
    width: 100%;

    &:hover {
        background-color: ${(props) => darken(0.05, props.theme.header.background)};
    }

    ${(props) => props.theme.breakpoints.up("lg")} {
        display: block;
    }
`;

const SearchIconWrapper = styled.div`
    width: 50px;
    height: 100%;
    position: absolute;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        width: 22px;
        height: 22px;
    }
`;

const Input = styled(InputBase)`
    color: inherit;
    width: 100%;

    > input {
        color: ${(props) => props.theme.header.search.color};
        padding-top: ${(props) => props.theme.spacing(2.5)}px;
        padding-right: ${(props) => props.theme.spacing(2.5)}px;
        padding-bottom: ${(props) => props.theme.spacing(2.5)}px;
        padding-left: ${(props) => props.theme.spacing(12)}px;
        width: 160px;
    }
`;

const UserMenu = () => {
    const { logout } = useAuth();

    const [anchorMenu, setAnchorMenu] = useState(null);

    const toggleMenu = (event) => {
        setAnchorMenu(event.currentTarget);
    };

    const closeMenu = () => {
        setAnchorMenu(null);
    };

    const open = !!anchorMenu;

    return (
        <React.Fragment>
            <IconButton aria-owns={open ? "menu-appbar" : undefined} aria-haspopup="true" onClick={toggleMenu} color="inherit">
                <Power />
            </IconButton>
            <Menu id="menu-appbar" anchorEl={anchorMenu} open={open} onClose={closeMenu}>
                <MenuItem onClick={logout}>Logout</MenuItem>
            </Menu>
        </React.Fragment>
    );
};

const Header = ({ onDrawerToggle, getCase, caseState, setIsSearching, drawerOpen, userId, isMinimal = false }) => {
    const [caseNum, setCaseNum] = useState("");

    const history = useHistory();

    const { data: reply } = useUserAutomaticEmailReply({ userId });
    const updateReply = useUpdateUserAutomaticEmailReply();

    const [updatingReply, setUpdatingReply] = useState();

    const turnOffReplies = () => {
        setUpdatingReply(true);
        updateReply.mutate(
            {
                userId,
                content: reply.content,
                fromDate: reply.fromDate,
                toDate: reply.toDate,
                isActive: false,
            },
            {
                onError: () => setUpdatingReply(false),
                onSuccess: () => setUpdatingReply(false),
            }
        );
    }    

    const searchCase = async () => {
        if (caseNum === "")
            return;

        setIsSearching(true);

        await getCase(caseNum, history);
        setIsSearching(false);
    };

    const handleEnterKeyPress = (e) => {
        if (e.key === "Enter") {
            searchCase();
        }
    };

    const showReplyBanner = useMemo(() => {        

        if (isMinimal || !reply?.isActive)
            return false;

        if (!reply.fromDate || !reply.toDate)
            return true;

        let now = new Date();
        let from = new Date(reply.fromDate);
        let to = new Date(reply.toDate);        

        return now > from && now < to;        

    }, [isMinimal, reply]);    

    return (
        <React.Fragment>
            <AppBar position="sticky" elevation={0}>
                {
                    showReplyBanner &&
                    <Alert severity="warning"
                        action={
                            <React.Fragment>
                                <Button
                                    variant="outlined"
                                    style={{ backgroundColor: 'white', marginRight: '10px' }}
                                    onClick={() => history.push("/admin/automaticreplies")}
                                >
                                    Automatic Replies
                                </Button>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={turnOffReplies}
                                    disabled={updatingReply}
                                >
                                    {updatingReply ? "Updating .." : "Turn Off"}
                                </Button>
                            </React.Fragment>
                        }
                    >
                        <AlertTitle>Automatic replies are on.</AlertTitle>
                    </Alert>
                }               
                <Toolbar>                    
                    <Grid container alignItems="center" spacing={2}>                       

                        {isMinimal && (
                            <>
                                <Grid item>
                                    <img src={caseNestLogo} alt="CaseNest Logo" style={{ height: "48px" }} />
                                </Grid>
                                <Grid item xs />
                            </>
                        )}
                        {!isMinimal && (
                            <>
                                <Grid item xs={12}>
                                    
                                </Grid>
                                <Grid item>
                                    <IconButton color="inherit" aria-label="Open drawer" onClick={onDrawerToggle}>
                                        {drawerOpen ? <MenuIcon /> : <HideMenuIcon />}
                                    </IconButton>
                                </Grid>                                
                                <Grid item>
                                    
                                    <Search>
                                        <SearchIconWrapper>
                                            <SearchIcon />
                                        </SearchIconWrapper>
                                        <Input
                                            data-cy="search_cases"
                                            value={caseNum}
                                            onChange={(e) => setCaseNum(e.target.value)}
                                            onKeyPress={handleEnterKeyPress}
                                            placeholder="Search case"
                                            type="number"
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton onClick={searchCase}>
                                                        <ArrowRightCircle />
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </Search>
                                </Grid>
                                {caseState.isSearching && (
                                    <Grid item>
                                        <CircularProgress size="1.5em" />
                                    </Grid>
                                )}
                                <Grid item>
                                    <UserTimerHOC />
                                </Grid>
                                <Grid item>
                                    <HeldTimeButton />
                                </Grid>
                                <Grid item xs />
                                <Grid item style={{ marginRight: "16px" }}>
                                    <Typography variant="h6">{caseState && caseState.currentCaseId ? "Case " + caseState.currentCaseId : ""}</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="h6">
                                        <CaseActivityTimer />
                                    </Typography>
                                </Grid>
                            </>
                        )}
                        <Grid item>
                            <UserMenu />
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => ({
    caseState: state.caseReducer,
    userId: state.userReducer.userProfile?.userId
});

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(actionCreators, dispatch),
        setIsSearching: (isSearching) => dispatch(setIsSearching(isSearching)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(Header));
