import React from "react";
import { makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "400px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
    },
    iconContainer: {
        padding: theme.spacing(3, 0),
    },
    text: {
        color: theme.palette.primary.main,
        textAlign: "center",
        margin: theme.spacing(3, 0),
    },
}));

const CaseHistoryExportBody = ({
    icon,
    body,
    heading = null,
    subHeading = null,
}) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <div className={classes.iconContainer}>{icon}</div>
            {heading !== null && (
                <Typography variant="h3" className={classes.text}>
                    {heading}
                </Typography>
            )}
            {subHeading !== null && (
                <Typography variant="subtitle2" className={classes.text}>
                    {subHeading}
                </Typography>
            )}
            <Typography variant="body2" className={classes.text}>
                {body}
            </Typography>
        </div>
    );
};

export default CaseHistoryExportBody;
