import React, { useState } from "react";
import { alpha, Button, makeStyles, Typography } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import FilterListIcon from "@material-ui/icons/FilterList";
import CaseHistoryItem from "../cards/CaseHistoryItem";
import useIsMobile from "../hooks/useIsMobile";
import CaseHistoryFilterDialog from "./dialogs/CaseHistoryFilterDialog";
import CaseHistoryFilter from "./filters/CaseHistoryFilter";
import Loader from "./Loader";

const useStyles = makeStyles((theme) => ({
    filtersContainer: {
        marginRight: theme.spacing(3),
    },
    container: {
        maxWidth: 900,
        flexGrow: 1,
    },
    infoContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: theme.spacing(3),
    },
    itemsContainer: {
        display: "flex",
        alignItems: "center",
    },
    itemsTitle: {
        fontSize: 15,
        color: alpha(theme.palette.primary.main, 0.6),

        fontWeight: 600,
    },
    itemsNumber: {
        padding: theme.spacing(0, 0.5),
        borderRadius: 4,
        display: "flex",
        alignItems: "center",
        background: theme.palette.secondary.light,
        marginLeft: theme.spacing(2),
        fontWeight: 600,
    },
    number: {
        fontWeight: 600,
    },
    pagination: {
        display: "flex",
        justifyContent: "end",
        color: theme.palette.primary.main,
    },
}));

const CaseHistoryComponent = ({
    caseId,
    items,
    pagedResults,
    dateFrom,
    dateTo,
    isLoading,
    internalUsers,
    miscActivityTypes,
    valuesPerPage,
    pageNumber,
    numberOfPages,
    onPageChange,
    onClearAllClick,
    onFromDateChange,
    onToDateChange,
    handleOpenContextMenu,
    handleFullScreenOpen,
    activitiesChecked,
    onActivityClick,
    chargeables,
    onChargeableClick,
    filters,
    directions,
    onDirectionClick,
    selectedUsers,
    onUserClick,
    isUnitisedTime,
}) => {
    const classes = useStyles();
    const isMobile = useIsMobile();

    const [displayFilterDialog, setDisplayFilterDialog] = useState(false);

    const handleFilterButtonClick = () => {
        setDisplayFilterDialog(true);
    };

    return (
        <>
            {!isMobile && (
                <div className={classes.filtersContainer}>
                    <CaseHistoryFilter
                        caseId={caseId}
                        dateFrom={dateFrom}
                        dateTo={dateTo}
                        activitiesChecked={activitiesChecked}
                        isLoading={isLoading}
                        onClearAllClick={onClearAllClick}
                        onFromDateChange={onFromDateChange}
                        onToDateChange={onToDateChange}
                        onActivityClick={onActivityClick}
                        chargeables={chargeables}
                        onChargeableClick={onChargeableClick}
                        filters={filters}
                        directions={directions}
                        onDirectionClick={onDirectionClick}
                        selectedUsers={selectedUsers}
                        onUserClick={onUserClick}
                    />
                </div>
            )}
            <div className={classes.container}>
                <div className={classes.infoContainer}>
                    <div
                        className={classes.itemsContainer}
                        data-testid="items-container"
                    >
                        <Typography
                            className={classes.itemsTitle}
                            color="primary"
                            variant="subtitle2"
                        >
                            Items
                        </Typography>
                        <div className={classes.itemsNumber}>
                            <Typography
                                className={classes.number}
                                color="primary"
                                variant="body2"
                            >
                                {items ?? 0}{" "}
                            </Typography>
                        </div>
                    </div>
                    {isMobile && (
                        <Button
                            variant="outlined"
                            color="secondary"
                            startIcon={<FilterListIcon />}
                            onClick={handleFilterButtonClick}
                        >
                            Filter
                        </Button>
                    )}
                </div>
                {isLoading && <Loader />}
                {items > 0 &&
                    pagedResults.map((e, index) => (
                        <CaseHistoryItem
                            caseEvent={e}
                            key={index}
                            miscActivityTypes={miscActivityTypes}
                            internalUsers={internalUsers}
                            handleMenuClick={handleOpenContextMenu}
                            handlePreviewClick={handleFullScreenOpen}
                            isUnitisedTime={isUnitisedTime}
                        />
                    ))}
                {items > valuesPerPage && (
                    <Pagination
                        count={numberOfPages}
                        page={pageNumber}
                        onChange={onPageChange}
                        className={classes.pagination}
                        color="secondary"
                    />
                )}
            </div>
            <CaseHistoryFilterDialog
                open={displayFilterDialog && isMobile}
                results={items}
                activitiesChecked={activitiesChecked}
                dateFrom={dateFrom}
                dateTo={dateTo}
                isLoading={isLoading}
                handleClose={setDisplayFilterDialog}
                onClearAllClick={onClearAllClick}
                onFromDateChange={onFromDateChange}
                onToDateChange={onToDateChange}
                onActivityClick={onActivityClick}
                chargeables={chargeables}
                onChargeableClick={onChargeableClick}
                filters={filters}
                directions={directions}
                onDirectionClick={onDirectionClick}
                selectedUsers={selectedUsers}
                onUserClick={onUserClick}
            />
        </>
    );
};

export default CaseHistoryComponent;
