import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import {
    Card,
    CardContent,
    Chip,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    IconButton,
    Typography,
    Box,
    CardHeader,
    makeStyles,
    Tooltip,
} from "@material-ui/core";
import { TreeItem, TreeView } from "@material-ui/lab";
import chipStyles from "../../theme/chips";
import {
    HomeWork,
    Edit as EditIcon,
    BusinessCenter,
    Business,
    Person,
    ExpandMore as ExpandMoreIcon,
    ChevronRight as ChevronRightIcon,
} from "@material-ui/icons";
import { Controls } from "../../helpers/Controls";
import CreatePartnership from "../../pages/CreatePartnership";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../redux/reducers/accountReducer";
import accountService from "../../services/accountService";
import clsx from "clsx";
import { format } from "date-fns";

const useStyles = makeStyles((theme) => ({
    card: {
        padding: theme.spacing(4),
    },
    cardHeader: {
        backgroundColor: theme.palette.secondary.main,
        color: "white",
    },
    cardContent: {
        marginBottom: "6px",
    },
    custDetail: {
        padding: theme.spacing(6),
        marginBottom: "6px",
    },
    treeLabel: {
        backgroundColor: theme.palette.secondary.main,
        color: "white",
    },
    treeLabelNormal: {
        backgroundColor: "white",
        color: "black",
    },
    accAdd: {
        whiteSpace: "pre-wrap",
        marginLeft: "3px",
    },
    addPointer: {
        cursor: "pointer",
        marginTop: "3px",
        marginLeft: "6px",
    },
    empCounter: {
        padding: "1px",
        cursor: "default",
        marginTop: "3px",
    },
    iconButton: {
        padding: "2px",
        marginTop: "-2px",
        marginLeft: "2px",
        minWidth: "0",
    },
    expiredPartners: {
        color: "white",
        width: "100%",
    },
    italic: {
        fontStyle: "italic",
    },
}));

const AccountDetailsCard = ({ account, sectors, getAccount, setSnack }) => {
    const chipClasses = chipStyles();
    const classes = useStyles();

    const [recordForEdit, setRecordForEdit] = useState(null);
    const [openPopup, setOpenPopup] = useState(false);
    const history = useHistory();
    const [openReEnableDialog, setOpenReEnableDialog] = useState(false);
    const [treeIsOpen, setTreeIsOpen] = useState(false);
    const [expanded, setExpanded] = useState([]);
    const status = {
        ACTIVE: "Active",
        CLIENT: "Client",
        PENDING: "Pending",
        NA: "N/A",
    };

    const openInPopup = (account) => {
        let acc = {};
        acc.accountId = account.accountId;
        acc.externalId = account.externalId;
        acc.name = account.name;
        acc.addressStreet = account.addressStreet;
        acc.addressCity = account.addressCity;
        acc.addressCounty = account.addressCounty;
        acc.addressPostCode = account.postCode;
        acc.partnershipNumber = account.partnershipNumber;
        acc.partnershipId = account.partnershipId;
        acc.accountStatus = account.status;

        setRecordForEdit({
            ...acc,
        });

        setOpenPopup(true);
    };

    const addOrEdit = async (account, resetForm) => {
        //send the data to salesforce
        try {
            await accountService.editExistingAccount(account);
            //clear the form and close the modal popup
            resetForm();
            setOpenPopup(false);
            getAccount(account.accountId, history);
        } catch (error) {
            setSnack("Update to partner member failed", "error");
            console.error(error);
        }
    };

    const handleReEnablePartnerMember = async () => {
        //update the status then pass the uodate
        try {
            account.status = "UpdateToPartnerMember";
            await accountService.reEnablePartnerMember(account);

            //clear the form and close the modal popup
            setOpenReEnableDialog(false);
            getAccount(account.accountId, history);
        } catch (error) {
            setSnack("Update to re-enable partner member failed", "error");
            console.error(error);
        }
    };

    const handleReEnableClick = () => {
        setOpenReEnableDialog(true);
    };

    const handleCloseReEnableDialog = () => {
        setOpenReEnableDialog(false);
    };

    const handleOpenAccount = async (accountId) => {
        try {
            handleSetTreeIsOpen(true);
            await getAccount(accountId, history);
            handleSetTreeIsOpen(false);
        } catch (error) {
            console.error(error);
        }
    };

    const handleSetTreeIsOpen = (treeIsOpen) => {
        setExpanded(account.accountStructure.accountIds.map(String));
        setTreeIsOpen(!treeIsOpen);
    };

    return (
        <React.Fragment>
            <Card className={classes.cardContent}>
                {account.partnerships.length ? (
                    <CardHeader
                        className={classes.cardHeader}
                        disableTypography
                        title={
                            <Box display="flex" flexDirection="column" alignItems="center">
                                {account.partnerships.filter((x) => !x.endDate).length
                                    ? account.partnerships
                                          .filter((x) => !x.endDate)
                                          .map((p) => (
                                              <Typography key={p.partnershipNumber} variant="subtitle1">
                                                  {p.partnershipName + " (" + p.partnershipNumber + ")"}
                                              </Typography>
                                          ))
                                    : account.partnerships
                                          .filter((x) => x.endDate)
                                          .map((p) => (
                                              <Box
                                                  key={p.partnershipNumber}
                                                  className={classes.expiredPartners}
                                                  display="flex"
                                                  flexDirection="column"
                                                  alignItems="center"
                                              >
                                                  <Typography className={classes.italic} variant="subtitle1">
                                                      {"Expired - " + p.partnershipName + " (" + p.partnershipNumber + ")"}{" "}
                                                  </Typography>
                                                  <Typography className={classes.italic} variant="subtitle1">
                                                      {"Contract End Date : " + format(new Date(p.endDate), "dd/MM/yyyy")}
                                                  </Typography>
                                              </Box>
                                          ))}
                            </Box>
                        }
                    />
                ) : (
                    <div></div>
                )}
                <CardContent>
                    <Box display="flex">
                        <Box flexGrow={1} alignItems="center">
                            <Typography variant="h6" mb={6}>
                                Account Details
                            </Typography>
                        </Box>
                        <Box>
                            {(account.partnershipId || account.status === status.PENDING) && (
                                <IconButton
                                    className={classes.iconButton}
                                    onClick={() => {
                                        openInPopup(account);
                                    }}
                                >
                                    <EditIcon />
                                </IconButton>
                            )}
                        </Box>
                        <Box>
                            <Chip
                                className={
                                    account.status === status.ACTIVE
                                        ? clsx(chipClasses.base, chipClasses.green)
                                        : account.status === status.PENDING
                                            ? clsx(chipClasses.base, chipClasses.amber)
                                            : clsx(chipClasses.base, chipClasses.root)
                                }
                                label={account.status || status.NA}
                            />
                        </Box>
                    </Box>
                    <Box display="flex" justifyContent="center">
                        <Typography className={classes.custDetail} variant="h6">
                            {account.name}
                        </Typography>
                    </Box>
                    <Box display="flex" alignItems="flex-start">
                        <Box>
                            <HomeWork fontSize="large" />
                        </Box>
                        <Box className={classes.accAdd} flexGrow={1}>
                            <Typography variant="body1">{account.address}</Typography>
                        </Box>
                    </Box>
                    <Box display="flex" alignItems="center" mt={3}>
                        <Box>
                            <BusinessCenter fontSize="large" />
                        </Box>
                        <Box flexGrow={1} ml={3}>
                            <Typography variant="body1">{account.sectorId ? sectors[account.sectorId].name : "Unknown Sector"}</Typography>
                        </Box>
                    </Box>

                    {(account.partnershipId || account.status === status.PENDING) &&
                        (account.partnershipCustom1Value || account.partnershipCustom1Label) && (
                            <Box display="flex" alignItems="center" mt={3}>
                                <Box display="flex">
                                    <Typography variant="body1" mb={6}>
                                        {account.partnershipCustom1Label ? account.partnershipCustom1Label : "Custom1"}
                                    </Typography>
                                </Box>
                                <Box flexGrow={1} ml={3}>
                                    <Typography variant="body2" mb={6}>
                                        {account.partnershipCustom1Value && account.partnershipCustom1Value}
                                    </Typography>
                                </Box>
                            </Box>
                        )}
                    <Box display="flex">
                        <Box display="flex" className={classes.empCounter} alignItems="center">
                            <Box>
                                <Person fontSize="large" />
                            </Box>
                            <Box flexGrow={1} ml={3} mr={6}>
                                {account.numberOfEmployees ? (
                                    <Tooltip
                                        title={"Employee count on salesforce (last updated - " + format(new Date(account.lastUpdated), "dd/MM/yyyy") + ")"}
                                    >
                                        <Typography variant="body1">{account.numberOfEmployees}</Typography>
                                    </Tooltip>
                                ) : (
                                    <Typography variant="body1">N/A</Typography>
                                )}
                            </Box>
                        </Box>
                        {account.numberOfSites > 1 ? (
                            <Box className={classes.addPointer} display="flex" alignItems="center" onClick={() => handleSetTreeIsOpen(treeIsOpen)}>
                                <Box>
                                    <Business fontSize="large" />
                                </Box>
                                <Box flexGrow={1} ml={3}>
                                    <Typography variant="body1">{account.numberOfSites}</Typography>
                                </Box>
                            </Box>
                        ) : (
                            <Box display="flex" alignItems="center" mt={3} ml={6}>
                                <Box>
                                    <Business fontSize="large" />
                                </Box>
                                <Box flexGrow={1} ml={3}>
                                    <Typography variant="body1">{account.numberOfSites}</Typography>
                                </Box>
                            </Box>
                        )}
                    </Box>
                    {treeIsOpen && (
                        <Box display="flex">
                            <TreeView defaultCollapseIcon={<ExpandMoreIcon />} defaultExpandIcon={<ChevronRightIcon />} expanded={expanded}>
                                <TreeItem
                                    className={account.accountStructure.account.accountId == account.accountId ? classes.treeLabel : classes.treeLabelNormal}
                                    nodeId={account.accountStructure.account.accountId.toString()}
                                    label={account.accountStructure.account.name}
                                    onLabelClick={() => handleOpenAccount(account.accountStructure.account.accountId)}
                                >
                                    {account.accountStructure.childAccounts.map((p, index) => {
                                        return (
                                            <TreeItem
                                                className={p.accountId == account.accountId ? classes.treeLabel : classes.treeLabelNormal}
                                                key={p.accountId}
                                                nodeId={p.accountId.toString()}
                                                label={p.name}
                                                onLabelClick={() => handleOpenAccount(p.accountId)}
                                            >
                                                {p.childAccounts.map((ca, index) => {
                                                    return (
                                                        <TreeItem
                                                            className={ca.accountId == account.accountId ? classes.treeLabel : classes.treeLabelNormal}
                                                            key={ca.accountId}
                                                            nodeId={ca.accountId.toString()}
                                                            label={ca.name}
                                                            onLabelClick={() => handleOpenAccount(ca.accountId)}
                                                        ></TreeItem>
                                                    );
                                                })}
                                            </TreeItem>
                                        );
                                    })}
                                </TreeItem>
                            </TreeView>
                        </Box>
                    )}
                    {
                        account.accountType === "Partner Member" && account.status === "Former" &&
                        <Box display="flex" justifyContent="center">
                            <Controls.ButtonControl text="Re-Enable Partner Member" size="small" color="primary" onClick={handleReEnableClick} />
                        </Box>
                    }
                    <Dialog
                        open={openReEnableDialog}
                        onClose={handleCloseReEnableDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">Re-Enable Partner Member</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">Please confirm that you wish to re-enable this partner member.</DialogContentText>
                            <DialogContentText id="alert-dialog-description">
                                If configured a confirmation email will be sent to the partnership informing them that we are now treating the member as
                                'Active'.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Controls.ButtonControl variant="outlined" text="Cancel" size="small" color="primary" onClick={() => handleCloseReEnableDialog()} />
                            <Controls.ButtonControl
                                variant="outlined"
                                text="Confirm"
                                size="small"
                                color="primary"
                                onClick={() => handleReEnablePartnerMember()}
                            />
                        </DialogActions>
                    </Dialog>
                </CardContent>
            </Card>
            <Controls.PopupControl title="EditAccount" openPopup={openPopup} setOpenPopup={setOpenPopup}>
                <CreatePartnership recordForEdit={recordForEdit} hideContactFields={true} addOrEdit={addOrEdit} />
            </Controls.PopupControl>
        </React.Fragment>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(actionCreators, dispatch),
    };
};

export default connect(null, mapDispatchToProps)(AccountDetailsCard);
