import React, { useState } from "react";
import { Dialog, DialogTitle, makeStyles, Tabs, Tab, DialogActions, Button, Box, Typography, DialogContent, DialogContentText } from "@material-ui/core";
import PerfectScrollbar from "react-perfect-scrollbar";
import TabPanel from "./TabPanel";
import CaseFilesTable from "./CaseFilesTable";
import useCaseFiles from "../hooks/queries/useCaseFiles";
import useDraftFiles from "../hooks/queries/useDraftFiles";
import DocumentUploader from "./DocumentUploader";
import { useDispatch } from "react-redux";
import {
    clearNonPrecedentAttachments,
    setCaseAttachmentsSelected,
    setDraftAttachmentsSelected,
    setMiscActivityAttachmentsAction,
    setMiscActivityDraftAttachmentsSelected,
    setMiscActivityUploadStatus,
    setReplyAttachmentsAction,
    setReplyUploadStatus,
} from "../redux/actions/caseActions";
import attachmentTypes from "../constants/attachmentTypes";
import { red } from "@material-ui/core/colors";
import storageService from "../services/storageService";
import { useFileUpload } from "../contexts/fileUploadContext";
import emailDraftService from "../services/emailDraftService";
import { setSnackAction } from "../redux/actions/snackActions";
import { uploadStatus } from "../constants/uploadStatus";

const useStyles = makeStyles(() => ({
    attachNewFileInput: {
        display: "none",
    },
    attachFileButton: {
        marginLeft: "4px",
    },
    perfScroll: {
        maxHeight: "calc(100vh - 300px)",
    },
    tabs: {
        borderBottom: "2px solid #C6C3D4",
    },
    dialogActions: {
        borderTop: "2px solid #C6C3D4",
    },
    discardButton: {
        color: red[900],
    },
}));

const AttachFileModal = ({
    emailDraftId,
    open,
    closeAttachFileModal,
    caseId,
    caseAttachmentsSelected = [],
    draftAttachmentsSelected = [],
    attachments = [],
    handleDeleteAttachment,
    isMiscActivity,
    precedentAttachments = [],
}) => {
    const classes = useStyles();
    const [tabValue, setTabValue] = useState(!isMiscActivity ? 0 : 1);
    const [discardChangesOpen, setDiscardChangesOpen] = useState(false);
    const [failedUploads, setFailedUploads] = useState([]);
    const { uploadInProgress } = useFileUpload();

    const { data: caseFilesData, isLoading: caseFilesIsLoading, isError: caseFilesIsError, error: caseFilesError } = useCaseFiles(caseId);
    const { data: draftFilesData, isLoading: draftFilesIsLoading, isError: draftFilesIsError, error: draftFilesError } = useDraftFiles({ caseId });
    const dispatch = useDispatch();

    const handleDispatchDraftAttachmentsSelected = (caseId, files = []) =>
        !isMiscActivity ? dispatch(setDraftAttachmentsSelected(files, caseId)) : dispatch(setMiscActivityDraftAttachmentsSelected(caseId, files));

    const handleCaseAttachmentChecked = (caseAttachment, checked) => {
        if (!checked) {
            handleDeleteAttachment({
                attachment: caseAttachment,
                attachmentType: attachmentTypes.CASE,
            });
            return;
        }
        dispatch(setCaseAttachmentsSelected([...caseAttachmentsSelected, caseAttachment], caseId));
    };

    const handleCaseAttachmentCheckAll = (checked) => {
        if (!checked) {
            dispatch(setCaseAttachmentsSelected([], caseId));
            return;
        }
        dispatch(setCaseAttachmentsSelected(caseFilesData, caseId));
    };

    const handleDraftAttachmentChecked = (draftAttachment, checked) => {
        if (!checked) {
            handleDispatchDraftAttachmentsSelected(
                caseId,
                draftAttachmentsSelected.filter((a) => a.id !== draftAttachment.id)
            );
            return;
        }
        handleDispatchDraftAttachmentsSelected(caseId, [...draftAttachmentsSelected, draftAttachment]);
    };

    const handleDraftAttachmentCheckAll = (checked) => {
        if (!checked) {
            handleDispatchDraftAttachmentsSelected(caseId, []);
            return;
        }
        handleDispatchDraftAttachmentsSelected(caseId, draftFilesData);
    };

    const handleClickCancel = () => {
        if (caseAttachmentsSelected.length || draftAttachmentsSelected.length || attachments.length) {
            setDiscardChangesOpen(true);
            return;
        }

        handleCloseModal();
    };

    const handleCloseModal = async (event, reason) => {
        if (reason === "backdropClick")
            return;

        for (const attachment of attachments) {            

            if (!attachment.azureFileId) {
                if (attachment.status === uploadStatus.COMPLETE) {
                    try {
                        await storageService.deleteBlob(attachment.id);
                    } catch (e) {
                        console.error(e);
                    }
                }
            } else {
                await removeDraftAttachment(attachment.azureFileId);
            }
        }

        dispatch(setMiscActivityAttachmentsAction(caseId, []));
        dispatch(setCaseAttachmentsSelected([], caseId));
        dispatch(clearNonPrecedentAttachments({ caseId }));
        handleDispatchDraftAttachmentsSelected(caseId, []);
        setDiscardChangesOpen(false);
        closeAttachFileModal();
    };

    const removeDraftAttachment = async (azureFileId) => {
        try {
            await emailDraftService.removeDraftAttachment(azureFileId, emailDraftId);
        } catch (e) {
            console.error(e);
            dispatch(setSnackAction("Could not remove attachment from draft email.", "error"));
        }
    };

    return (
        <Dialog
            className="file-modal"
            onClose={handleCloseModal}
            open={open}
            fullWidth={!discardChangesOpen}
            maxWidth={discardChangesOpen ? "sm" : "lg"}
            disableEscapeKeyDown
        >
            {discardChangesOpen ? (
                <>
                    <DialogTitle>Discard Selections</DialogTitle>
                    <DialogContent>
                        <DialogContentText>Any selected files will not be attached.</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setDiscardChangesOpen(false)} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={handleCloseModal} className={classes.discardButton}>
                            Discard
                        </Button>
                    </DialogActions>
                </>
            ) : (
                <>
                    <DialogTitle>Attach Files</DialogTitle>
                    <Tabs className={classes.tabs} value={tabValue} onChange={(_e, newValue) => setTabValue(newValue)}>
                        {" "}
                        {!isMiscActivity && (
                            <Tab label={`Case Files${caseAttachmentsSelected.length ? ` (${caseAttachmentsSelected.length})` : ""}`} value={0} />
                        )}
                        <Tab label={`Draft Files${draftAttachmentsSelected.length ? ` (${draftAttachmentsSelected.length})` : ""}`} value={1} />
                        <Tab label={`Upload Files${attachments.length ? ` (${attachments.length})` : ""}`} value={2} data-cy="upload_files_tab" />
                    </Tabs>
                    {!isMiscActivity && (
                        <TabPanel value={tabValue} index={0}>
                            <PerfectScrollbar className={classes.perfScroll}>
                                <CaseFilesTable
                                    data={caseFilesData}
                                    isLoading={caseFilesIsLoading}
                                    isError={caseFilesIsError}
                                    error={caseFilesError}
                                    attachmentsSelected={caseAttachmentsSelected}
                                    handleAttachmentChecked={handleCaseAttachmentChecked}
                                    handleCheckAll={handleCaseAttachmentCheckAll}
                                />
                            </PerfectScrollbar>
                        </TabPanel>
                    )}
                    <TabPanel value={tabValue} index={1}>
                        <PerfectScrollbar className={classes.perfScroll}>
                            <CaseFilesTable
                                data={draftFilesData}
                                isLoading={draftFilesIsLoading}
                                isError={draftFilesIsError}
                                error={draftFilesError}
                                attachmentsSelected={draftAttachmentsSelected}
                                handleAttachmentChecked={handleDraftAttachmentChecked}
                                handleCheckAll={handleDraftAttachmentCheckAll}
                            />
                        </PerfectScrollbar>
                    </TabPanel>
                    <TabPanel value={tabValue} index={2}>
                        <DocumentUploader
                            attachments={attachments}
                            setAttachments={
                                isMiscActivity
                                    ? (x) => dispatch(setMiscActivityAttachmentsAction(caseId, x))
                                    : (x) => dispatch(setReplyAttachmentsAction(caseId, [...precedentAttachments, ...x]))
                            }
                            setUploadStatus={
                                isMiscActivity
                                    ? ({ id, status, reference }) => dispatch(setMiscActivityUploadStatus({ caseId, id, status, reference }))
                                    : ({ id, status, reference }) => dispatch(setReplyUploadStatus({ caseId, id, status, reference }))
                            }
                            failedUploads={failedUploads}
                            setFailedUploads={setFailedUploads}
                            removeDraftAttachment={removeDraftAttachment}
                        />
                    </TabPanel>
                    <DialogActions className={classes.dialogActions}>
                        <Box display="flex" justifyContent="space-between" alignItems="center" width="100%" ml={3}>
                            <Typography variant="subtitle2" color="primary">
                                {caseAttachmentsSelected.length + draftAttachmentsSelected.length + attachments.length} selected
                            </Typography>
                            <div>
                                <Button color="secondary" onClick={handleClickCancel}>
                                    Cancel
                                </Button>
                                <Button color="secondary" onClick={closeAttachFileModal} disabled={uploadInProgress} data-cy="attach_button">
                                    Attach
                                </Button>
                            </div>
                        </Box>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

export default AttachFileModal;
