import * as types from "../constants";
import axios from "../../plugins/axios";

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

const initialState = {
    contact: {
        phone: "",
        email: "",
    },
    account: {
        accountId: null,
        name: "",
        postCode: "",
        contacts: [],
    },
    activeStep: 0,
};

export const createContactActionCreators = {
    setCreateContactAccount:
        (accountId, moveActiveStep) => async (dispatch, getState) => {
            let response = await axios.get(`${apiUrl}/getaccount/${accountId}`);
            if (response.status === 200) {
                const a = response.data;
                dispatch({
                    type: types.SET_CREATE_CONTACT_ACCOUNT,
                    account: a,
                });
                if (moveActiveStep)
                    dispatch({
                        type: types.SET_CREATE_CONTACT_ACTIVE_STEP,
                        step: 1,
                    });
            }
        },
};

const reducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case types.SET_CREATE_CONTACT_PHONE: {
            return {
                ...state,
                contact: {
                    ...state.contact,
                    phone: action.phone,
                },
            };
        }

        case types.SET_CREATE_CONTACT_EMAIL: {
            return {
                ...state,
                contact: {
                    ...state.contact,
                    email: action.email,
                },
            };
        }

        case types.SET_CREATE_CONTACT_ACCOUNT: {
            return {
                ...state,
                account: action.account,
            };
        }

        case types.SET_CREATE_CONTACT_ACTIVE_STEP: {
            return {
                ...state,
                activeStep: action.step,
            };
        }

        case types.RESET_CREATE_CONTACT: {
            return initialState;
        }

        default:
            return state;
    }
};

export default reducer;
