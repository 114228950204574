
import InputControl from "../helpers/inputControl"
import RadioGroupControl from "../helpers/radioGroupControl"
import DDListControl from "../helpers/ddListControl"
import ButtonControl from "../helpers/buttonControl"
import PopupControl from "../helpers/popupControl"
import SwitchControl from "../helpers/switchControl"

export const Controls = {
    InputControl,
    RadioGroupControl,
    DDListControl,
    ButtonControl,
    PopupControl,
    SwitchControl
}
