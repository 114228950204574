import React from "react";
import TuilePill from "./TuilePill";
import FilterWrapper from "./FilterWrapper";
import { mergeSelectedFiltersWithFetchedFilters } from "../../helpers/mergeSelectedFiltersWithFetchedFilters";

const btnLabel = (isChargeable) => `${!isChargeable ? "non-" : ""}chargeable btn`;

const HistoryChargeableFilter = ({ chargeableFilters = [], chargeables = {}, onChargeableClick, isLoading }) => {

    const mergedFilters = mergeSelectedFiltersWithFetchedFilters(chargeables, chargeableFilters, "label");

    return (
        <FilterWrapper filterName="Non-Chargeable / Chargeable" isLoading={isLoading} hasBtnContainer>
            {mergedFilters?.length > 0
                ? mergedFilters.map((filter) => (
                    <TuilePill
                        key={filter.label}
                        name={filter.label}
                        count={filter.count}
                        callbackValue={filter}
                        ariaLabel={btnLabel(filter.isChargeable)}
                        isSelected={chargeables.hasOwnProperty(filter.label)}
                        onClick={onChargeableClick}
                    />
                ))
                : null}
        </FilterWrapper>
    );
};

export default HistoryChargeableFilter;
