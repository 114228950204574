import React from "react";
import { makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    activityNumber: {
        padding: theme.spacing(0, 0.5),
        marginLeft: theme.spacing(2),
        borderRadius: 4,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: theme.palette.secondary.light,
        fontWeight: 500,
        minWidth: 15,
    },
    number: {
        fontWeight: 500,
    },
}));

const FilterActivityCount = ({ count }) => {
    const classes = useStyles();
    return (
        <div className={classes.activityNumber}>
            <Typography
                className={classes.number}
                color="primary"
                variant="body2"
            >
                {count}
            </Typography>
        </div>
    );
};

export default FilterActivityCount;
