import axios from "../plugins/axios";

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

const getExternalContacts = async (caseId, externalContactSearchText) => {
    try {
        let queryString = `?caseId=${caseId}`;
        if (externalContactSearchText)
            queryString += `&externalContactSearchText=${externalContactSearchText}`;

        const response = await axios.get(
            `${apiUrl}/getexternalcontacts${queryString}`
        );
        return response.data;
    } catch (e) {
        console.error(e);
        return Promise.reject(
            e?.message || "Could not fetch external case contacts."
        );
    }
};

const getExternalContactTypes = async () => {
    try {
        const response = await axios.get(`${apiUrl}/getexternalcontacttypes`);
        return response.data;
    } catch (e) {
        console.error(e);
        return Promise.reject(
            e?.message || "Could not fetch external contact types"
        );
    }
};

const addCaseExternalContact = async (caseExternalContact) => {
    try {
        const response = await axios.post(
            `${apiUrl}/addcaseexternalcontact`,
            caseExternalContact
        );
        return response.data;
    } catch (e) {
        console.error(e);
        return Promise.reject(e?.message || "Couldnt add external contact");
    }
};

const updateCaseExternalContact = async (caseExternalContact) => {
    try {
        const response = await axios.post(
            `${apiUrl}/updatecaseexternalcontact`,
            caseExternalContact
        );
        return response.data;
    } catch (e) {
        console.error(e);
        return Promise.reject(
            e?.message || "Could not update case external contact."
        );
    }
};

const caseExternalContactService = {
    addCaseExternalContact,
    updateCaseExternalContact,
    getExternalContacts,
    getExternalContactTypes,
};

export default caseExternalContactService;
