import { useQuery } from "react-query";
import queryKeys from "../../constants/queryKeys";
import accountService from "../../services/accountService";

const useAccountContracts = (accountId, includeExpired = false) =>
    useQuery([queryKeys.accountContracts, accountId, includeExpired],
        () => accountService.getAccountContracts(accountId, includeExpired),
        {
            enabled: !!accountId,
            staleTime: 3600000
        }
    );

export default useAccountContracts;

