import userService from "../../services/userService";
import { useMutation } from "react-query";
import queryKeys from "../../constants/queryKeys";
import reactQueryClient from "../../reactQueryClient";

const useUpdateUserAdviceType = () =>
    useMutation(
        ({ userId, adviceTypeId, isEnabled }) =>
            userService.setAdviceType({ userId, adviceTypeId, isEnabled }),
        {
            onMutate: async ({ userId, adviceTypeId, isEnabled }) => {
                const queryKey = [queryKeys.adviceTypes, userId];
                reactQueryClient.cancelQueries(queryKey);
                const userAdviceTypesBeforeMutation =
                    reactQueryClient.getQueryData(queryKey);
                reactQueryClient.setQueryData(queryKey, (prev) =>
                    isEnabled
                        ? [...prev, adviceTypeId]
                        : prev.filter((id) => id !== adviceTypeId)
                );
                return { userAdviceTypesBeforeMutation, queryKey };
            },
            onError: (err, newDetails, context) => {
                reactQueryClient.setQueryData(
                    context.queryKey,
                    context.userAdviceTypesBeforeMutation
                );
            },
            onSettled: (data, err, newDetails, context) => {
                reactQueryClient.invalidateQueries(context.queryKey);
            },
        }
    );

export default useUpdateUserAdviceType;
