import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Card,
    CardContent,
    Typography,
    Button,
    Box,
    CircularProgress,
    Tabs,
    Tab,
    makeStyles,
} from "@material-ui/core";
import {
    setMiscActivityTab,
    resetMiscActivity,
    resetCurrentMiscActivity,
    setCurrentMiscActivityOpen,
} from "../redux/actions/caseActions";
import MiscActivityEditor from "../components/MiscActivityEditor";
import caseActivityService from "../services/caseActivityService";
import { isValid } from "date-fns";
import { isValidHoursMinutes } from "../helpers/dateHelpers";
import { setSnackAction } from "../redux/actions/snackActions";
import TabPanel from "../components/TabPanel";
import { grey } from "@material-ui/core/colors";
import CaseTimeEventGroupTable from "../components/CaseTimeEventGroupTable";
import { miscActivityGroups } from "../constants/miscActivityGroupConstants";
import DeleteCaseTimeEventGroupsDialog from "../components/dialogs/DeleteCaseTimeEventGroupsDialog";
import CurrentMiscActivityDialog from "../components/dialogs/CurrentMiscActivityDialog";
import useDiscardCaseTimeEventGroups from "../hooks/mutations/useDiscardCaseTimeEventGroups";
import { saveCurrentMiscActivity } from "../redux/actions/thunks";
import reactQueryClient from "../reactQueryClient";
import queryKeys from "../constants/queryKeys";

const useStyles = makeStyles((theme) => ({
    title: {
        flexGrow: 1,
    },
    iconButton: {
        padding: "2px",
        marginTop: "-2px",
        marginLeft: "2px",
        minWidth: 0,
        color: grey[700],
    },
    tabs: {
        marginLeft: theme.spacing(-4),
        marginRight: theme.spacing(-4),
        boxShadow: "0 2px 2px 0px gray",
    },
    card: {
        marginBottom: theme.spacing(6),
    },
    rowCount: {
        color: grey[500],
    },
    headerItem: {
        margin: theme.spacing(1),
    },
    saveButton: {
        marginLeft: theme.spacing(2),
    },
    tabPanel: {
        marginTop: theme.spacing(3),
    },
}));

const MiscActivityCard = () => {
    const dispatch = useDispatch();
    const classes = useStyles();

    const { currentCaseId: caseId, cases } = useSelector(state => state.caseReducer);
    const { userProfile: { userId } } = useSelector(state => state.userReducer);
    const c = cases[caseId];
    const { tab: currentTab } = c.miscActivity;

    const activity =
        currentTab === miscActivityGroups.CURRENT
            ? c.miscActivity.current
            : c.miscActivity.retrospective;

    const currentMiscOpen = currentTab === miscActivityGroups.CURRENT && activity.open;
    const accountId = c?.account?.accountId;
    const contractId = c?.caseSummary?.contractId;

    const [saving, setSaving] = useState(false);
    const [selectedTimeEventGroups, setSelectedTimeEventGroups] = useState([]);
    const [deleteGroupsOpen, setDeleteGroupsOpen] = useState(false);

    const discardCaseTimeEventGroups = useDiscardCaseTimeEventGroups();    

    const handleSaveRetrospective = async () => {
        if (
            !isValidHoursMinutes(activity.hours, activity.minutes) ||
            !isValid(new Date(activity.date)) ||
            !isValid(new Date(activity.startTime))
        ) {
            dispatch(setSnackAction("Invalid date or time entered", "error"));
            return;
        }

        setSaving(true);

        try {
            await caseActivityService.saveRetrospectiveMiscActivity([], caseId);
            handleReset();
            reactQueryClient.invalidateQueries([queryKeys.caseTime, caseId]);
            reactQueryClient.invalidateQueries([queryKeys.contractSummary, contractId]);
        } catch (error) {
            console.log(error);
        }

        setSaving(false);
    };

    const handleReset = () => {
        setSelectedTimeEventGroups([]);
        dispatch(resetMiscActivity({ caseId }));
    };

    const isSaveDisabled = useMemo(() => {
        return (
            !activity.miscActivityTypeId ||
            !activity.description ||
            !activity.date ||
            !activity.notes ||
            !activity.startTime ||
            !(parseInt(activity.hours) || parseInt(activity.minutes))
        );
    }, [activity.description, activity.miscActivityTypeId, activity.date, activity.notes, activity.hours, activity.minutes, activity.startTime]);

    const handleDeleteGroups = async () => {
        setDeleteGroupsOpen(false);
        discardCaseTimeEventGroups.mutate(
            {
                caseTimeEventGroupIds: selectedTimeEventGroups,
                userId,
                accountId,
            },
            {
                onSuccess: () => {
                    dispatch(setSnackAction("Successfully discarded held time", "success"));
                    setSelectedTimeEventGroups([]);
                },
                onError: (e) => dispatch(setSnackAction(e?.message || "There was an error discarding held time", "error")),
            }
        );
    };

    const handleSaveCurrent = async () => {
        dispatch(saveCurrentMiscActivity({ caseTimeEventGroupIds: selectedTimeEventGroups }));
    };

    return (
        <Card className={classes.card}>
            <CardContent>
                <Box display="flex" width="100%" alignItems="center">
                    <Typography
                        className={classes.title}
                        variant="h6"
                        gutterBottom
                    >
                        Misc Activity
                    </Typography>
                    {currentTab === miscActivityGroups.CURRENT && (
                        <>
                            {selectedTimeEventGroups.length > 0 && (
                                <Typography
                                    variant="subtitle2"
                                    className={`${classes.rowCount} ${classes.headerItem}`}
                                >
                                    {selectedTimeEventGroups.length} selected
                                </Typography>
                            )}
                            <Button
                                className={classes.headerItem}
                                onClick={handleReset}
                            >
                                Cancel
                            </Button>
                            <Button
                                className={classes.headerItem}
                                variant="outlined"
                                color="primary"
                                disabled={!selectedTimeEventGroups.length}
                                onClick={() => setDeleteGroupsOpen(true)}
                            >
                                Delete
                            </Button>
                            <Button
                                className={classes.headerItem}
                                variant="contained"
                                color="primary"
                                disabled={!selectedTimeEventGroups.length}
                                onClick={() =>
                                    dispatch(
                                        setCurrentMiscActivityOpen({
                                            isOpen: true,
                                        })
                                    )
                                }
                            >
                                Assign
                            </Button>
                        </>
                    )}
                </Box>
                <Tabs
                    variant="fullWidth"
                    className={classes.tabs}
                    value={currentTab}
                    onChange={(e, value) => dispatch(setMiscActivityTab({ caseId, tab: value }))}
                >
                    <Tab label="Log held time" />
                    <Tab label="Log activity" />
                </Tabs>
                <TabPanel
                    value={currentTab}
                    index={0}
                    className={classes.tabPanel}
                >
                    <CaseTimeEventGroupTable
                        selected={selectedTimeEventGroups}
                        setSelected={setSelectedTimeEventGroups}
                    />
                </TabPanel>
                <TabPanel
                    value={currentTab}
                    index={1}
                    className={classes.tabPanel}
                >
                    <MiscActivityEditor
                        group={miscActivityGroups.RETROSPECTIVE}
                    />
                    <Box display="flex" width="100%" justifyContent="flex-end">
                        {saving ? (
                            <CircularProgress />
                        ) : (
                            <>
                                <Button onClick={handleReset}>Cancel</Button>
                                <Button
                                    className={classes.saveButton}
                                    color="primary"
                                    variant="contained"
                                    disabled={isSaveDisabled}
                                    onClick={handleSaveRetrospective}
                                >
                                    Save
                                </Button>
                            </>
                        )}
                    </Box>
                </TabPanel>
            </CardContent>
            <DeleteCaseTimeEventGroupsDialog
                open={deleteGroupsOpen}
                onClose={() => setDeleteGroupsOpen(false)}
                onConfirm={handleDeleteGroups}
            />
            <CurrentMiscActivityDialog
                open={currentMiscOpen}
                onClose={() => dispatch(resetCurrentMiscActivity())}
                onConfirm={handleSaveCurrent}
            />
        </Card>
    );
};

export default MiscActivityCard;
