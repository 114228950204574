import React from "react";
import { useDispatch } from "react-redux";
import { setSnackAction } from "../../../redux/actions/snackActions";
import TreeContent from "../../../components/casefiles/TreeContent";
import { caseFileActions } from "../../../constants/caseFiles";
import documentService from "../../../services/documentService";
import reactQueryClient from "../../../reactQueryClient";
import queryKeys from "../../../constants/queryKeys";
import {
    Box,
    makeStyles,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    Link,
} from "@material-ui/core";
import {
    Clear as ClearIcon,
} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
    diaTitle: {
        flexGrow: 1,
        display: "flex",
        alignItems: "center",
    },
    moveToHeader: {
        borderBottom: "1px solid rgba(224, 224, 224, 1)",
    },
    link: {
        cursor: "pointer"
    },
    linkColour: {
        color: "#5f48c7"
    },
    moveToClear: {
        marginRight: "6px"
    },
}));

export default function MoveDialog({
    updateTreeData,
    treeData,
    setTreeData,
    caseId,
    moveDialogOpen,
    setMoveDialogOpen,
    selectedItem,
    setSelectedItem,
    moveParent,
    setMoveParent,
    newParent,
    setNewParent,
    breadcrumbs,
    setBreadcrumbs,
    handleOpenContextMenu,
    setFolderDialogOpen,
    sortBy,
    setSortBy,
    sortName,
    setSortName,
}) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const currentParent = !selectedItem ? newParent : moveParent;

    const handleMoveItem = async () => {
        if (selectedItem) {           

            try {
                await documentService.setCaseFileFolder({
                    folderId: selectedItem.folderId,
                    caseFileFolderId: currentParent?.folderId,
                    id: selectedItem.id
                });

                //Set snack message
                let folderName = '';
                if (currentParent == null) {
                    folderName = 'Root Level';
                } else {
                    folderName = currentParent.title;
                }

                dispatch(setSnackAction(`'${selectedItem.customName ? selectedItem.customName : selectedItem.title}' moved to '${folderName}'`, "success"));
            }
            finally {
                reactQueryClient.invalidateQueries([queryKeys.caseFilesTree, caseId]);
            }

            setSelectedItem(null);
            setMoveParent(null);
            handleMoveDialogClose();           
        }
    };

    const handleMoveDialogClose = () => {
        setMoveDialogOpen(false);
    }

    return (
        <Dialog
            open={moveDialogOpen}
            onClose={handleMoveDialogClose}
            fullWidth
        >
            <DialogTitle className={classes.moveToHeader}>
                <Box display="flex">
                    <Box flexGrow={1} alignItems="center">
                        <Typography
                            variant="h6"
                            component="div"
                            className={classes.diaTitle}
                        >
                            <ClearIcon onClick={handleMoveDialogClose} className={`${classes.link} ${classes.moveToClear}`} />
                            Move to
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="subtitle2">
                            {moveParent?.folderId === newParent?.folderId ? (
                                <>
                                    Current
                                </>
                            ) : (
                                <Link
                                    underline="hover"
                                    className={`${classes.link} ${classes.linkColour}`}
                                    onClick={handleMoveItem}
                                >
                                    Move Here
                                </Link>
                            )}
                        </Typography>
                    </Box>
                </Box>
            </DialogTitle>
            <DialogContent>
                <TreeContent
                    treeData={treeData}
                    setTreeData={setTreeData}
                    classes={classes}
                    handleOpenContextMenu={handleOpenContextMenu}
                    isMove={true}
                    setFolderDialogOpen={setFolderDialogOpen}
                    newParent={newParent}
                    setNewParent={setNewParent}
                    breadcrumbs={breadcrumbs}
                    setBreadcrumbs={setBreadcrumbs}
                    moveParent={moveParent}
                    setMoveParent={setMoveParent}
                    sortBy={sortBy}
                    setSortBy={setSortBy}
                    sortName={sortName}
                    setSortName={setSortName}
                    selectedItem={selectedItem}
                />
            </DialogContent>
        </Dialog>
    )
}