import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import axios from "../plugins/axios";
import {
    Card as MuiCard,
    CardContent as MuiCardContent,
    Typography as MuiTypography,
    Box,
    Chip as MuiChip,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

const Typography = styled(MuiTypography)(spacing);

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)`
    position: relative;

    &:last-child {
        padding-bottom: ${(props) => props.theme.spacing(4)}px;
    }
`;

const Chip = styled(MuiChip)`
    position: absolute;
    top: 16px;
    right: 16px;
    height: 20px;
    padding: 4px 0;
    font-size: 85%;
    background-color: ${(props) => props.theme.palette.secondary.main};
    color: ${(props) => props.theme.palette.common.white};
    margin-bottom: ${(props) => props.theme.spacing(4)}px;

    span {
        padding-left: ${(props) => props.theme.spacing(2)}px;
        padding-right: ${(props) => props.theme.spacing(2)}px;
    }
`;

const UserSLACard = ({ userProfile, fromDate, toDate }) => {
    const [sla, setSla] = useState("-");

    const getSla = useCallback(async () => {
        if (
            (!fromDate && toDate) ||
            (fromDate && !toDate) ||
            !userProfile.userId
        )
            return;
        const response = await axios.post(`${apiUrl}/getusersla`, {
            userId: userProfile.userId,
            fromDate,
            toDate,
        });
        if (response.status === 200 && !isNaN(response.data)) {
            setSla(Math.round(response.data * 100) + "%");
            return;
        }
        setSla("N/A");
    }, [userProfile.userId, fromDate, toDate]);

    useEffect(() => {
        getSla();
    }, [getSla]);

    return (
        <Card mb={3} style={{ height: "100%" }}>
            <CardContent>
                <Typography variant="h6" mb={6}>
                    My SLA
                </Typography>
                <Typography variant="h2" mb={6}>
                    <Box
                        display="flex"
                        justifyContent="center"
                        fontWeight="fontWeightRegular"
                    >
                        {sla}
                    </Box>
                </Typography>
                <Chip
                    label={!(toDate && fromDate) ? "All" : "Custom date range"}
                />
            </CardContent>
        </Card>
    );
};

export default UserSLACard;
