import React from 'react';
import './ckeditor.css';
import { connect } from "react-redux";
import "../vendor/perfect-scrollbar.css";
import { v4 as uuidv4 } from 'uuid';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';

//import CKEditorInspector from '@ckeditor/ckeditor5-inspector';

import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment.js';
import Autoformat from '@ckeditor/ckeditor5-autoformat/src/autoformat.js';
import Base64UploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter';
import BlockQuote from '@ckeditor/ckeditor5-block-quote/src/blockquote.js';
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold.js';
import CloudServices from '@ckeditor/ckeditor5-cloud-services/src/cloudservices.js';
import Comments from '@ckeditor/ckeditor5-comments/src/comments.js';
import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials.js';
import FontBackgroundColor from '@ckeditor/ckeditor5-font/src/fontbackgroundcolor.js';
import FontColor from '@ckeditor/ckeditor5-font/src/fontcolor.js';
import FontFamily from '@ckeditor/ckeditor5-font/src/fontfamily.js';
import FontSize from '@ckeditor/ckeditor5-font/src/fontsize.js';
import GeneralHtmlSupport from "@ckeditor/ckeditor5-html-support/src/generalhtmlsupport";
import Heading from '@ckeditor/ckeditor5-heading/src/heading.js';
import HorizontalLine from '@ckeditor/ckeditor5-horizontal-line/src/horizontalline.js';
import Image from '@ckeditor/ckeditor5-image/src/image.js';
import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption.js';
import ImageResize from '@ckeditor/ckeditor5-image/src/imageresize.js';
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle.js';
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar.js';
import ImageUpload from '@ckeditor/ckeditor5-image/src/imageupload.js';
import Indent from '@ckeditor/ckeditor5-indent/src/indent.js';
import IndentBlock from '@ckeditor/ckeditor5-indent/src/indentblock.js';
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic.js';
import Link from '@ckeditor/ckeditor5-link/src/link.js';
import List from '@ckeditor/ckeditor5-list/src/list.js';
import ListProperties from '@ckeditor/ckeditor5-list/src/listproperties';
import MediaEmbed from '@ckeditor/ckeditor5-media-embed/src/mediaembed';
import Mention from '@ckeditor/ckeditor5-mention/src/mention.js';
import PageBreak from '@ckeditor/ckeditor5-page-break/src/pagebreak.js';
import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph.js';
import PasteFromOffice from '@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice.js';
import RemoveFormat from '@ckeditor/ckeditor5-remove-format/src/removeformat.js';
import SourceEditing from '@ckeditor/ckeditor5-source-editing/src/sourceediting';
import Strikethrough from '@ckeditor/ckeditor5-basic-styles/src/strikethrough.js';
import Subscript from '@ckeditor/ckeditor5-basic-styles/src/subscript.js';
import Superscript from '@ckeditor/ckeditor5-basic-styles/src/superscript.js';
import Table from '@ckeditor/ckeditor5-table/src/table.js';
import TableCellProperties from '@ckeditor/ckeditor5-table/src/tablecellproperties';
import TableProperties from '@ckeditor/ckeditor5-table/src/tableproperties';
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar.js';
import TextTransformation from '@ckeditor/ckeditor5-typing/src/texttransformation.js';
import TodoList from '@ckeditor/ckeditor5-list/src/todolist';
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline.js';
import WordCount from '@ckeditor/ckeditor5-word-count/src/wordcount.js';

import DocumentLink from './DocumentLink';
import EvidenceLink from './EvidenceLink';
import ClipboardHtml from './ClipboardHtml';
import Placeholder from './Placeholder';
import CommentsIntegration from './CommentsIntegration';
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles(() => ({
	editorContainer: {
		color: props => props.disabled && "darkgrey",
	}
}));

const commonPlugins = [
	Alignment,
	Autoformat,
	Base64UploadAdapter,
	BlockQuote,
	Bold,
	CloudServices,
	Essentials,
	FontBackgroundColor,
	FontColor,
	FontFamily,
	FontSize,
	Heading,
	HorizontalLine,
	Image,
	ImageCaption,
	ImageResize,
	ImageStyle,
	ImageToolbar,
	ImageUpload,
	Indent,
	IndentBlock,
	Italic,
	Link,
	List,
	ListProperties,
	MediaEmbed,
	PageBreak,
	Paragraph,
	PasteFromOffice,
	RemoveFormat,
	Strikethrough,
	Subscript,
	Superscript,
	Table,
	TableCellProperties,
	TableProperties,
	TableToolbar,
	TextTransformation,
	TodoList,
	Underline,
	WordCount
];

const documentPlugins = [
	Placeholder,
	Comments,
	CommentsIntegration,
	Mention
];

const precedentPlugins = [
	Placeholder,
	Mention
];

const emailPlugins = [
	DocumentLink,
	EvidenceLink,
	ClipboardHtml
];

const articlePlugins = [
	GeneralHtmlSupport,
	MediaEmbed,
	SourceEditing
];

const toolbar = [
	'sourceEditing',
	'comment',
	'heading',
	'|',
	'fontSize',
	'fontFamily',
	'|',
	'fontColor',
	'fontBackgroundColor',
	'|',
	'bold',
	'italic',
	'underline',
	'strikethrough',
	'|',
	'alignment',
	'|',
	'numberedList',
	'bulletedList',
	'|',
	'outdent',
	'indent',
	'|',
	'todoList',
	'link',
	'blockQuote',
	'imageUpload',
	'insertTable',
	'|',
	'undo',
	'redo',
	'horizontalLine',
	'pageBreak',
	'superscript',
	'subscript',
	'removeFormat'
];

const MyCKEditor = ({ editor, setEditor, onChange, onFocus, initialData, mentions, type, contained, disabled=false }) => {	
	const classes = useStyles({ disabled });
	const getFeedItems = query => {

		let found = mentions.filter(m => m.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
		return new Promise(resolve => {
			resolve(found);
		});
	}

	const colors = [
		{ color: '#000000', label: 'Black' },
		{ color: '#4D4D4D', label: 'Dim grey' },
		{ color: '#999999', label: 'Grey' },
		{ color: '#E6E6E6', label: 'Light grey' },
		{ color: '#FFFFFF', label: 'White' },
		{ color: '#E64C4C', label: 'Red' },
		{ color: '#E6994C', label: 'Orange' },
		{ color: '#E6E64C', label: 'Yellow' },
		{ color: '#99E64C', label: 'Light Green' },
		{ color: '#4CE64C', label: 'Green' },
		{ color: '#4CE699', label: 'Aquamarine' },
		{ color: '#4CE6E6', label: 'Turquoise' },
		{ color: '#4C99E6', label: 'Light blue' },
		{ color: '#4C4CE6', label: 'Blue' },
		{ color: '#994CE6', label: 'Purple' }
	];

	const mentionRenderer = mention => {
		//console.log('renderer', mention);
		const html = `
			<div style="display:flex;flex-direction:column;justify-content:space-between;width:400px;">
				<div style="display:flex;justify-content:space-between">
					<span>${mention.name}</span>
					<span class="mention-chip">${mention.type}</span>
				</div>
				<div style="display:flex;justify-content:space-between;color:#444444;font-size:0.8em;">
					<span>${mention.guidance || ""}</span>
				</div>
			</div>`;

		var div = document.createElement('div');
		div.innerHTML = html.trim();

		// Change this to div.childNodes to support multiple top-level nodes.
		return div.firstChild;
	}

	const plugins = [...commonPlugins];

	switch (type) {
		case "email":
			plugins.push(...emailPlugins);
			break;
		case "readonly":
		case "document":		
			plugins.push(...documentPlugins);
			break;
		case "precedent":
			plugins.push(...precedentPlugins);
			break;
		case "article":
			plugins.push(...articlePlugins);
			break;
		default:
			break;
	}

	const editorConfiguration = {
		plugins: plugins,
		licenseKey: 'upOSgYiWLBkSU4Z8M9ev18F4TZhOll1FK412R6zkEkdYRmFtfxuEM0sZP2wenv6FxqcwDQc+UfTGQQ==',
		toolbar: toolbar,
		table: {
			contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells', 'tableProperties', 'tableCellProperties']
		},
		comments: {
			editorConfig: {}
		},
		mention: {
			feeds: [
				{
					marker: '@',
					feed: getFeedItems,
					minimumCharacters: 0,
					itemRenderer: mentionRenderer
				}
			]
		},
		fontColor: { colors },
		fontBackgroundColor: { colors },
		list: {
			properties: {
				styles: true,
				startIndex: true,
				reversed: true
			}
		},
		mediaEmbed: {
			previewsInData: true
		},
		htmlSupport: {
			allow: [
				{
					name: 'iframe',
					attributes: true,
					classes: true,
					styles: true
				}
			]
		},
	};	

	return (
		<div className={classes.editorContainer}>
			<CKEditor
				disabled={disabled}
				editor={ClassicEditor}
				config={editorConfiguration}
				data={initialData}
				onReady={editor => {
					if (contained) {
						editor.editing.view.change((writer) => {

							writer.setStyle(
								"height",
								"0",
								editor.editing.view.document.getRoot()
							);
						});
					}

					setEditor(editor);

					if (type === "document" || type === "precedent") {
						const mentionCommand = editor.commands.get('mention');

						mentionCommand.on('execute', (evt, args) => {
							evt.stop();
							//console.log('evt', evt.stop);

							//console.log('args', args);
							let mention = args[0].mention;
							const range = args[0].range;

							// Insert the placeholder.    
							editor.model.change(writer => {

								mention = { ...mention, key: uuidv4() };

								if (mention.type === "list" || mention.type === "textarea") {
									const placeholder = writer.createElement('blockplaceholder', { mention });
									editor.model.insertContent(placeholder, range);

									writer.insertElement('paragraph', placeholder, 'after');
									writer.setSelection(writer.createPositionAt(placeholder, 'after'));

								}
								else {
									const placeholder = writer.createElement('inlineplaceholder', { mention });
									editor.model.insertContent(placeholder, range);
								}
							});
						}, { priority: 'high' });
					}

					if (type === "readonly") {
						const toolbarElement = editor.ui.view.toolbar.element;
						toolbarElement.style.display = 'none';

						editor.plugins.get('CommentsOnly').isEnabled = true;
					}

					//CKEditorInspector.attach('editor', editor);
				}}
				onBlur={(event, editor) => {

					let commentThreads;
					if (type === "document") {
						const commentsRepository = editor.plugins.get('CommentsRepository');

						commentThreads = commentsRepository.getCommentThreads({
							skipNotAttached: true,
							skipEmpty: true,
							toJSON: true
						});
					}

					const data = editor.getData();
					onChange && onChange(data, commentThreads);
				}}
				onFocus={onFocus}
				onError={error => {
					var propertyNames = Object.getOwnPropertyNames(error);
					var descriptor;
					for (var property, i = 0, len = propertyNames.length; i < len; ++i) {
						property = propertyNames[i];
						descriptor = Object.getOwnPropertyDescriptor(error, property);
						console.log('error', property, descriptor);
					}
				}}
			/>
		</div>
		

	);
}

const mapStateToProps = state => ({ mentions: state.configReducer.mentions });

export default connect(mapStateToProps)(MyCKEditor);
