import { nanoid } from 'nanoid';
import activityTypes from '../../constants/activityTypes';
import timerStatus from '../../constants/timerStatus';
import requestStatus from '../../constants/requestStatus';
import {
    STOP_CASE_TIMER,
    START_TIMING_CASE_ACTIVITY,
    PAUSE_CASE_TIMER,
    RESUME_CASE_TIMER,
    SET_CASE_WINDOW_STATUS,
    SET_FLEX_WINDOW_STATUS,
    ADD_UNALLOCATED_CASE_ACTIVITIES,
    FILTER_SAVED_CASE_ACTIVITIES,
    SET_REQUEST_STATUS,
} from '../constants';

const initialState = {
    status: timerStatus.STOPPED,
    activities: [],
    currentActivity: null,
    caseWindowStatus: 'blur',
    flexWindowStatus: 'blur',
    saveRequestStatus: requestStatus.IDLE,
    caseActive: true
};

const caseTimerReducer = (state = initialState, action) => {
    switch (action.type) {
        case PAUSE_CASE_TIMER:
            return {
                ...state,
                status: timerStatus.PAUSED,
            };
        case RESUME_CASE_TIMER:
            return {
                ...state,
                status: timerStatus.RUNNING,
            };
        case STOP_CASE_TIMER:
            return {
                ...state,
                activities: state.currentActivity
                    ? [
                          ...state.activities,
                          { ...state.currentActivity, finish: new Date() },
                      ]
                    : state.activities,
                currentActivity: null,
                status: timerStatus.STOPPED,
            };
        case START_TIMING_CASE_ACTIVITY:

            return {
                ...state,
                activities: state.currentActivity
                    ? [
                          ...state.activities,
                          { ...state.currentActivity, finish: new Date() },
                      ]
                    : state.activities,
                currentActivity: action.payload,
                status: timerStatus.RUNNING,
            };
        case SET_CASE_WINDOW_STATUS:
            return {
                ...state,
                caseWindowStatus: action.status,
            };
            
        case SET_FLEX_WINDOW_STATUS:
            return {
                ...state,
                flexWindowStatus: action.status,
            };
            
        case ADD_UNALLOCATED_CASE_ACTIVITIES:
            return {
                ...state,
                activities: [
                    ...state.activities,
                    ...action.payload.unallocatedActivities.map(a => ({
                        id: nanoid(),
                        timeEventType: activityTypes.BLUR_CALL,
                        start: a.start,
                        caseId: action.payload.caseId,
                        finish: a.end || new Date(),
                        callId: a.callId,
                    })),
                ],
            };
        case FILTER_SAVED_CASE_ACTIVITIES:
            return {
                ...state,
                activities: [
                    ...state.activities.filter(
                        x => !action.payload.includes(x.id)
                    ),
                ],
                currentActivity: null,
                status: timerStatus.STOPPED,
            };
        case SET_REQUEST_STATUS:
            return {
                ...state,
                saveRequestStatus: action.payload,
            };
        default:
            return state;
    }
};

export default caseTimerReducer;
