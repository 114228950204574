import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import { Provider } from 'react-redux';
import store from './redux/store';
import { ConnectedRouter } from 'connected-react-router';
import history from './redux/history';
import {AuthProvider} from "./contexts/authContext";
import {QueryClientProvider} from "react-query";
import reactQueryClient from "./reactQueryClient";
import {ReactQueryDevtools} from "react-query/devtools";


// Suppress 'list items must have keys warning' in prod
// This is caused by FileViewer component
// Source: https://github.com/danneu/react-template-render/commit/8ae09d53a2ae879adcb4994689fed23086b8e254
(function () {
    if (process.env.NODE_ENV === 'production') {
        console.error = (() => {
            const _error = console.error;
            const re = /^Warning: Each child in a list should have a unique "key" prop/;
            return (...args) => {
                const line = args[0];
                if (re.test(line)) {
                    // Ignore key warnings
                } else {
                    _error(...args);
                }
            };
        })();
    }
})();

if(process.env.NODE_ENV !== "development")
    console.log(`Build ${process.env.REACT_APP_BUILD_NUMBER} (${process.env.REACT_APP_COMMIT_HASH})`)

ReactDOM.render(
    <QueryClientProvider client={reactQueryClient}>
        <Provider store={store}>
            <AuthProvider>
                <ConnectedRouter history={history}>
                    <App />
                </ConnectedRouter>
            </AuthProvider>
        </Provider>
        <ReactQueryDevtools />
    </QueryClientProvider>,
    document.getElementById('root')
);
