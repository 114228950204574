import {
    Box,
    makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles(() => ({
    Unauthorised: {
        backgroundColor: '#fdeaee',
        color: '#d33d4f'
    },
    "Non-SRA": {
        backgroundColor: '#f2edff',
        color: '#6751cb'
    },
    SRA: {
        backgroundColor: '#f2edff',
        color: '#2c2062'
    },
    HRC: {
        backgroundColor: '#c7d7fe',
        color: '#363f72'
    },
    HRS: {
        backgroundColor: '#defaf7',
        color: '#30b0b6'
    },
    'H&S': {
        backgroundColor: '#ebf1ff',
        color: '#0061c1'
    },
    all: {
        borderRadius: '5px',
        padding: '2px 5px',
        marginRight: '5px',
        fontWeight: 700,
        fontSize: '0.9em'
    },
    button: {
        cursor: 'pointer',
        padding: '7px 10px'
    },
    notSelected: {
        backgroundColor: 'white',
        color: '#ccc',
        border: '1px solid #ccc',
        padding: '6px 9px'
    }
}));

const AdviceTypeChip = ({ adviceType, type, notSelected, onClick }) => {

    const classes = useStyles();

    return (
        <Box onClick={onClick} className={`${classes[adviceType]} ${classes.all} ${classes[type]} ${notSelected && classes.notSelected}`}>
            {adviceType}
        </Box>
    )
};

export default AdviceTypeChip;