import * as types from '../constants';

export function setCreateContactPhone(phone) {
    return {
        type: types.SET_CREATE_CONTACT_PHONE,
        phone
    }
}

export function setCreateContactEmail(email) {
    return {
        type: types.SET_CREATE_CONTACT_EMAIL,
        email
    }
}

export function setCreateContactActiveStep(step) {
    return {
        type: types.SET_CREATE_CONTACT_ACTIVE_STEP,
        step
    }
}

export function setCreateContactAccount(account, moveActiveStep) {

    return {
        type: types.SET_CREATE_CONTACT_ACCOUNT,
        account,
        moveActiveStep
    }
}

export function resetCreateContact() {
    return {
        type: types.RESET_CREATE_CONTACT
    }
}