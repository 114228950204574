import {
    Checkbox,
    FormControlLabel,
    makeStyles,
    Typography,
} from "@material-ui/core";
import React from "react";
import FilterActivityCount from "./FilterActivityCount";

const useStyles = makeStyles((theme) => ({
    activityItem: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        marginBottom: theme.spacing(2),
    },
    formControlLabel: {
        marginLeft: 0,
    },
    checkbox: {
        padding: 0,
        marginRight: theme.spacing(3),
    },
    checkboxChecked: {
        color: theme.palette.secondary.main,
    },
    label: {
        fontSize: 14,
        fontWeight: 400,
    },
}));

const HistoryActivityFilterItem = ({
    name,
    count,
    activity,
    isActivityChecked,
    onActivityClick,
}) => {
    const classes = useStyles();

    return (
        <div className={classes.activityItem}>
            <FormControlLabel
                className={classes.formControlLabel}
                control={
                    <Checkbox
                        size="small"
                        classes={{
                            checked: classes.checkboxChecked,
                        }}
                        className={classes.checkbox}
                        checked={isActivityChecked}
                        onChange={() => onActivityClick(activity)}
                    />
                }
                label={
                    <Typography
                        color="primary"
                        variant="body2"
                        className={classes.label}
                    >
                        {name}
                    </Typography>
                }
            />
            <FilterActivityCount count={count} />
        </div>
    );
};

export default HistoryActivityFilterItem;
