import React, { useState } from "react";
import {
    Button as MuiIconButton,
    Typography as MuiTypography,
    Paper,
    makeStyles,
    FormControlLabel,
    FormGroup,
    Checkbox,
} from "@material-ui/core";
import styled from "styled-components";
import { spacing } from "@material-ui/system";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { bindActionCreators } from "redux";
import CaseTable from "../../components/CaseTable";
import { createCaseActionCreators } from "../../redux/reducers/createCaseReducer";
import { AddBox as AddIcon } from "@material-ui/icons";

const Typography = styled(MuiTypography)(spacing);
const useStyles = makeStyles((theme) => ({
    card: {
        padding: theme.spacing(2, 3),
        height: "100%",
    },
    checkbox: {
        display: "flex",
        alignItems: "center",
    },
    titleContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexWrap: "wrap",
    },
    buttonContainer: {
        display: "flex",
        alignItems: "center",
    },
    tableContainer: {
        overflow: "auto",
    },
    addCaseIcon: {
        width: "200px",
    },
    addCaseIconText: {
        marginLeft: theme.spacing(2),
    },
}));

const IconButton = styled(MuiIconButton)`
    padding: 0px;
    margin-top: 0px;
    margin-left: 0px;
    min-width: 0;
`;

const RecentCasesCard = ({ accountId, setCreateCaseAccount }) => {
    const history = useHistory();
    const classes = useStyles();
    const [showClosed, setShowClosed] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const handleCreateNewCaseClick = async (ev) => {
        setCreateCaseAccount(accountId);
        history.push("/createcase");
    };

    const handleShowClosedClick = (e) => {
        setPage(0);
        setShowClosed(e.target.checked);
    };

    return (
        <Paper className={classes.card}>
            <div className={classes.titleContainer}>
                <Typography variant="h6">Cases</Typography>
                <div className={classes.buttonContainer}>
                    <IconButton
                        className={classes.addCaseIcon}
                        color="primary"
                        onClick={handleCreateNewCaseClick}
                    >
                        <AddIcon fontSize="small" />
                        <Typography className={classes.addCaseIconText}>
                            Create New Case
                        </Typography>
                    </IconButton>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={showClosed}
                                    onChange={handleShowClosedClick}
                                    color="primary"
                                    className={classes.checkbox}
                                />
                            }
                            label={
                                <Typography color="primary">
                                    Show Closed Cases
                                </Typography>
                            }
                        />
                    </FormGroup>
                </div>
            </div>
            <div className={classes.tableContainer}>
                <CaseTable
                    accountId={accountId}
                    forAccountPage
                    showClosed={showClosed}
                    page={page}
                    setPage={setPage}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                />
            </div>
        </Paper>
    );
};

const mapStateToProps = (state) => ({ userState: state.userReducer });

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(createCaseActionCreators, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RecentCasesCard);
