import React from "react";
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    makeStyles, Typography
} from "@material-ui/core";
import {invoiceUploadStatus} from "../../constants/invoiceUploadStatus";

const useStyles = makeStyles((theme) => ({
    loadingContainer: {
        display: "flex",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
    },
    loadingSpinner: {
        marginBottom: theme.spacing(3),
    },
}));

const mapStatusToContent = {
    [invoiceUploadStatus.IN_PROGRESS]: "Uploading Invoice",
    [invoiceUploadStatus.SUCCESS]: "Invoice Uploaded",
    [invoiceUploadStatus.FAILED]: "Upload Failed",
};

const InvoiceUploadDialog = ({ open, content, onClose, status }) => {
    const classes = useStyles();
    
    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>{mapStatusToContent[status]}</DialogTitle>
            <DialogContent>
                {status === invoiceUploadStatus.IN_PROGRESS ?
                    <div className={classes.loadingContainer}>
                        <CircularProgress size={60} className={classes.loadingSpinner} />
                        <Typography variant="subtitle2">Uploading invoice</Typography>
                    </div> : 
                    <div>{content}</div>
                }
            </DialogContent>
            {status !== invoiceUploadStatus.IN_PROGRESS ? 
                <DialogActions>
                    <Button color="secondary" onClick={onClose}>Ok</Button>
                </DialogActions> :
                null
            }
        </Dialog>
    )
}

export default InvoiceUploadDialog;