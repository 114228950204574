import React, { useState } from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    Typography,
    makeStyles,
} from "@material-ui/core";
import CaseTimeEventGroupTable from "../CaseTimeEventGroupTable";
import { grey, red } from "@material-ui/core/colors";
import useDiscardCaseTimeEventGroups from "../../hooks/mutations/useDiscardCaseTimeEventGroups";
import { setSnackAction } from "../../redux/actions/snackActions";
import { useSelector, useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
    rowCount: {
        color: grey[500],
    },
    deleteButton: {
        color: red[400],
    },
}));

const steps = {
    ALLOCATE: 0,
    DELETE: 1,
}

const CaseTimeEventGroupsDialog = ({ open, onClose, onConfirm, confirmLabel }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [selected, setSelected] = useState([]);
    const [step, setStep] = useState(steps.ALLOCATE);

    const discardCaseTimeEventGroups = useDiscardCaseTimeEventGroups();

    const userState = useSelector(state => state.userReducer);
    const caseState = useSelector(state => state.caseReducer);
    const { userId } = userState.userProfile;
    const { currentCaseId } = caseState;
    const accountId = caseState.cases[currentCaseId]?.account?.accountId;


    const handleClose = async (event, reason) => {
        if (reason === "backdropClick") {
            return;
        }
        setSelected([]);
        onClose();
    };

    const handleConfirm = () => {
        onConfirm(null, selected);
        handleClose();
    }

    const handleDelete = () => {
        setStep(steps.ALLOCATE);
        discardCaseTimeEventGroups.mutate({ caseTimeEventGroupIds: selected, userId, accountId }, {
            onSuccess: () => {
                dispatch(setSnackAction("Successfully discarded held time", "success"));
                setSelected([]);
            },
            onError: e =>
                dispatch(setSnackAction(e?.message || "There was an error discarding held time", "error")),
        });
    }

    return (
        <Dialog open={open} onClose={handleClose}>
            {step === steps.DELETE && <>
                <DialogTitle>Discard held time?</DialogTitle>
                <DialogContent>
                    <DialogContentText>This action cannot be undone</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setStep(steps.ALLOCATE)}>Cancel</Button>
                    <Button className={classes.deleteButton} onClick={handleDelete}>Delete</Button>
                </DialogActions>
            </>}

            {step === steps.ALLOCATE && <>
                <DialogTitle>Handle unallocated time</DialogTitle>
                <DialogContent>
                    <DialogContentText>You have the following unallocated time for this account. Select any you wish to allocate to this activity</DialogContentText>
                    <CaseTimeEventGroupTable selected={selected} setSelected={setSelected} />
                </DialogContent>
                <DialogActions>
                    {selected.length > 0 &&
                        <Typography variant="subtitle2" className={`${classes.rowCount} ${classes.headerItem}`}>
                            {selected.length} selected
                        </Typography>}
                    <Button
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="outlined"
                        color="primary"
                        disabled={!selected.length}
                        onClick={() => setStep(steps.DELETE)}
                    >
                        Delete
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleConfirm}
                    >
                        {confirmLabel}
                    </Button>
                </DialogActions>
            </>}
        </Dialog>
    )
}

export default CaseTimeEventGroupsDialog;