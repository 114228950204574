import React from "react";
import {
    alpha,
    Box,
    makeStyles,
    Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    container: {
        border: `1px solid ${alpha(theme.palette.primary.main, 0.3)}`,
        borderRadius: 4,
        padding: theme.spacing(4),
    },
    heading: {
        marginRight: theme.spacing(2),
    }
}));


const ContractTimeCard = ({
    title,
    time,
    timeInMinutes,
    billingType,
    showBillingType,
    showMinutesAddendum,
}) => {

    const classes = useStyles();

    return (
        <div className={classes.container}>
            <Typography variant="caption" color="primary">
                {title}
            </Typography>
            <Box display="flex" alignItems="baseline">
                <Typography variant="h1" color="primary" className={classes.heading}>
                    {time + (showBillingType ? " " + billingType.toLowerCase() : "")}
                </Typography>
                { showMinutesAddendum ? <Typography variant="caption">{`(${timeInMinutes})`}</Typography> : ""}
            </Box>
        </div>
    )
};

export default ContractTimeCard;