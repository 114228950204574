import { useState, useCallback } from "react";
import useInterval from "./useInterval";
import { MediaRecorder } from "extendable-media-recorder";

// Source: modified from useAudioRecorder.js in package https://github.com/samhirtarif/react-audio-recorder
const useAudioRecorder = () => {
    const [isRecording, setIsRecording] = useState(false);
    const [isPaused, setIsPaused] = useState(false);
    const [recordingTime, setRecordingTime] = useState(0);
    const [mediaRecorder, setMediaRecorder] = useState(null);
    const [timerInterval, setTimerInterval] = useState(false);
    const [recordingBlob, setRecordingBlob] = useState(null);

    const [cancelInterval] = useInterval({
        callback: () => setRecordingTime((time) => time + 1),
        delay: 1000,
        isActive: timerInterval,
    });

    const _startTimer = () => {
        setTimerInterval(true);
    };

    const _stopTimer = () => {
        setTimerInterval(false);
        timerInterval && cancelInterval();
        setTimerInterval(false);
    };

    const startRecording = useCallback(() => {
        if (timerInterval) return;

        navigator.mediaDevices
            .getUserMedia({ audio: true })
            .then((stream) => {
                setIsRecording(true);
                const recorder = new MediaRecorder(stream, {
                    mimeType: "audio/wav",
                });
                setMediaRecorder(recorder);
                recorder.start();
                _startTimer();

                recorder.addEventListener("dataavailable", (event) => {
                    setRecordingBlob(event.data);
                    stream.getTracks().forEach((t) => t.stop());
                    setMediaRecorder(null);
                });
            })
            .catch((err) => console.log(err));
    }, [timerInterval]);

    const stopRecording = () => {
        mediaRecorder?.stop();
        _stopTimer();
        setRecordingTime(0);
        setIsRecording(false);
        setIsPaused(false);
    };

    const togglePauseResume = () => {
        if (isPaused) {
            setIsPaused(false);
            mediaRecorder?.resume();
            _startTimer();
        } else {
            setIsPaused(true);
            _stopTimer();
            mediaRecorder?.pause();
        }
    };

    return {
        startRecording,
        stopRecording,
        togglePauseResume,
        recordingBlob,
        isRecording,
        isPaused,
        recordingTime,
    };
};

export default useAudioRecorder;
