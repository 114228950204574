import * as types from "../constants";
import _ from "lodash";

const initialState = {
    user: null,
    token: null,
    userProfile: {},
    users: {},
    userTime: 0,
    isUserTimerRunning: false,
    lastUserEventStart: null,
    currentUserActivityId: null,
    userActivityTypeId: "",
    fullScreenEmail: {
        email: null,
        isOpen: false,
        replyEnabled: false,
    },
    acceptedEmailTaskSids: [],
    activeFlexEmailTaskSid: null,
    documentViewer: {
        open: false,
        fileType: "",
        filePath: "",
        fileName: "",
        caseId: null,
        attachment: null,
        isEmailPreview: false,
        showCopyToLiveDocument: false,
        uploadFile: null,
    },
    checkingUserSession: true,
    seekAdviceMiscActivityId: null,
    raiseCaseRequestId: null,
};

export const actionCreators = {
    setUser: (user) => async (dispatch, getState) => {
        dispatch({ type: types.SET_USER, user });
    },
    setUserProfile: (profile) => async (dispatch, getState) => {
        dispatch({ type: types.SET_USER_PROFILE, profile });
    },
    setUsers: (users) => async (dispatch, getState) => {
        dispatch({ type: types.SET_USERS, users });
    },
    setUserTimerRunning: (isRunning) => async (dispatch, getState) => {
        dispatch({ type: types.SET_USER_TIMER_RUNNING, isRunning });
    },
    setCheckingUserSession: (isChecking) => (dispatch) => {
        dispatch({ type: types.SET_CHECKING_USER_SESSION, isChecking });
    },
};

const reducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case types.SET_USER: {
            return {
                ...state,
                user: action.user,
                token: action.token,
                checkingUserSession: false,
            };
        }

        case types.SET_USER_PROFILE: {
            return {
                ...state,
                userProfile: {
                    ...action.profile,
                    templates: _.keyBy(
                        action.profile.templates,
                        (t) => t.templateId
                    ),
                },
            };
        }

        case types.SET_USERS: {
            return {
                ...state,
                users: _.keyBy(action.users, (u) => u.userId),
            };
        }

        case types.SET_USER_ADVICE_TYPES: {
            return {
                ...state,
                users: {
                    ...state.users,
                    [action.payload.userId]: {
                        ...state.users[action.payload.userId],
                        adviceTypes: action.payload.adviceTypes,
                    },
                },
            };
        }

        case types.SET_CHECKING_USER_SESSION: {
            return {
                ...state,
                checkingUserSession: action.isChecking,
            };
        }

        case types.SET_TEMPLATE: {
            return {
                ...state,
                userProfile: {
                    ...state.userProfile,
                    templates: {
                        ...state.userProfile.templates,
                        [action.template.templateId]: action.template,
                    },
                },
            };
        }

        case types.SET_USER_TIMER_RUNNING: {
            return {
                ...state,
                isUserTimerRunning: action.isRunning,
            };
        }

        case types.UPDATE_USER_MANAGER: {
            return {
                ...state,
                users: {
                    ...state.users,
                    [action.userId]: {
                        ...state.users[action.userId],
                        managerId: action.managerId,
                    },
                },
            };
        }

        case types.SET_FULL_SCREEN_EMAIL: {
            return {
                ...state,
                fullScreenEmail: {
                    email: action.e,
                    isOpen: action.e ? true : false,
                    replyEnabled: action.replyEnabled,
                    addBarButtons: action.addBarButtons,
                },
            };
        }

        case types.ADD_ACCEPTED_EMAIL_TASK_SID: {
            return {
                ...state,
                acceptedEmailTaskSids: [
                    ...state.acceptedEmailTaskSids.filter(
                        (sid) => sid !== action.taskSid
                    ),
                    action.taskSid,
                ],
            };
        }

        case types.REMOVE_ACCEPTED_EMAIL_TASK_SID: {
            return {
                ...state,
                acceptedEmailTaskSids: state.acceptedEmailTaskSids.filter(
                    (sid) => sid !== action.taskSid
                ),
            };
        }

        case types.SET_ACTIVE_FLEX_EMAIL_TASK_SID: {
            return {
                ...state,
                activeFlexEmailTaskSid: action.taskSid,
            };
        }

        case types.OPEN_FILE_VIEWER: {
            return {
                ...state,
                documentViewer: {
                    open: true,
                    fileType: action.file.type,
                    filePath: action.file.path,
                    fileName: action.file.name,
                    caseId: action.caseId,
                    attachment: action.attachment,
                    isEmailPreview: action.isEmailPreview,
                    showCopyToLiveDocument: action.showCopyToLiveDocument,
                    uploadFile: action.uploadFile,
                },
            };
        }

        case types.CLOSE_FILE_VIEWER: {
            return {
                ...state,
                documentViewer: {
                    open: false,
                    fileType: "",
                    filePath: "",
                    fileName: "",
                    caseId: null,
                    attachment: null,
                    isEmailPreview: false,
                    showCopyToLiveDocument: false,
                    uploadFile: null,
                },
            };
        }
        
        case types.SET_SEEK_ADVICE_MISC_ACTIVITY_ID: {
            return {
                ...state,
                seekAdviceMiscActivityId: action.id,
            }
        }
        
        case types.SET_RAISE_CASE_REQUEST_ID: {
            return {
                ...state,
                raiseCaseRequestId: action.id,
            }
        }

        default:
            return state;
    }
};

export default reducer;
