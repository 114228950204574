import React, { useState } from "react";
import {
    Box,
    Card,
    CardContent,
    Checkbox,
    CircularProgress,
    FormControlLabel,
    FormGroup,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
} from "@material-ui/core";
import useAccountLeiDetails from "../../hooks/queries/useAccountLeiDetails";
import { Alert } from "@material-ui/lab";
import { red } from "@material-ui/core/colors";
import { formatShortMonthDate } from "../../utils/dateTimeFormatters";
import { formatPriceGBP } from "../../helpers/formatPrice";

const useStyles = makeStyles((theme) => ({
    card: {
        marginBottom: theme.spacing(3),
    },
    expiredDateRange: {
        color: red[800],
    },
    totalDuration: {
        fontWeight: 600,
    },
    totalUnits: {
        fontWeight: 500,
    },
    summaryRow: {
        height: "100%",
    }
}));

const LeiEdiDetailsCard = ({ accountId }) => {
    const classes = useStyles();
    const [pageNum, setPageNum] = useState(0);
    const [pageSize, setPageSize] = useState(5);
    const [includeExpired, setIncludeExpired] = useState(false);

    const { data, isError, error, isLoading } = useAccountLeiDetails({
        accountId,
        pageSize,
        pageNum,
        includeExpired,
    });

    const handleChangePage = (event, newPage) => {
        setPageNum(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPageSize(parseInt(event.target.value, 10));
        setPageNum(0);
    };

    if (isLoading)
        return (
            <Box display="flex" width="100%" justifyContent="center">
                <CircularProgress />
            </Box>
        );

    if (isError)
        return (
            <Box display="flex" width="100%" justifyContent="center">
                <Alert variant="outlined" severity="error">
                    {error?.message || "Could not load Account Contracts"}
                </Alert>
            </Box>
        );
    return (
        <>
            <Card className={classes.card}>
                <CardContent>
                    <Box display="flex" justifyContent="space-between">
                        <Typography variant="h6" gutterBottom display="inline">
                            LEI/EDI Details
                        </Typography>
                        <FormGroup>
                            <FormControlLabel
                                control={<Checkbox checked={includeExpired} onChange={(e) => setIncludeExpired(e.target.checked)} />}
                                label="Show Expired Insurers"
                            />
                        </FormGroup>
                    </Box>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Policy Start Date</TableCell>
                                    <TableCell>Policy End Date</TableCell>
                                    <TableCell>LEI Type</TableCell>
                                    <TableCell>LEI/EDI Insurer</TableCell>
                                    <TableCell>Heads</TableCell>
                                    <TableCell>Rate (£)</TableCell>
                                    <TableCell>Premium (£ pa)</TableCell>
                                    <TableCell>Excess (£)</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                           
                                {data?.results?.map((lei) => (
                                    <TableRow key={lei.externalId} className={classes.summaryRow}>
                                        <TableCell>
                                            <Typography
                                                variant="body2"
                                                className={
                                                    new Date(lei.endDate).getTime() < new Date() ? classes.expiredDateRange : ""
                                                }
                                            >
                                                {formatShortMonthDate(lei.startDate)}
                                            </Typography>
                                        </TableCell>
                                        <TableCell><Typography
                                            variant="body2"
                                            className={
                                                new Date(lei.endDate).getTime() < new Date() ? classes.expiredDateRange : classes.validDateRange
                                            }
                                        >
                                            {formatShortMonthDate(lei.endDate)}
                                        </Typography></TableCell>
                                        <TableCell>
                                            <Typography variant="body2">{lei.leiType}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body2">{lei.leiEdiInsurer}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body2">{lei.numEmployees}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body2">
                                                {formatPriceGBP(lei.elRate)}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body2">
                                                {formatPriceGBP(lei.totalPremium)}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body2">
                                                {formatPriceGBP(lei.excess)}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        component="div"
                        onPageChange={handleChangePage}
                        count={data?.count || 0}
                        rowsPerPage={pageSize}
                        page={pageNum}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        rowsPerPageOptions={[5, 10, 25]}
                    />
                </CardContent>
            </Card>
        </>
    );
};

export default LeiEdiDetailsCard;
