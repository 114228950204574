import { useQuery } from "react-query";
import queryKeys from "../../constants/queryKeys";
import voicemailService from "../../services/voicemailService";

const useVoicemailForUser = ({ userId, onSuccess }) =>
    useQuery(
        [queryKeys.voicemail, userId],
        () => voicemailService.fetchUrlForUser({ userId }),
        {
            enabled: !!userId,
            onSuccess,
        }
    );

export default useVoicemailForUser;
