import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import Loader from "../components/Loader";
import { bindActionCreators } from "redux";
import { actionCreators } from "../redux/reducers/caseReducer";
import { Avatar, Box, Button, Table, TableCell, TableBody, TableHead, TableRow, TablePagination, Typography, Chip, makeStyles } from "@material-ui/core";
import avatarStyles from "../theme/avatars";
import chipStyles from "../theme/chips";
import {
    ArrowDropDown,
    ArrowDropUp,
    PersonAdd as GrantAccessIcon,
    PersonAddDisabled as RestrictAccessIcon,
    StarBorder as StarBorderIcon,
    Star as StarIcon,
} from "@material-ui/icons";
import styled from "styled-components";
import { setDisplayCaseSearchInTab } from "../redux/actions/caseActions";
import { setCurrentAccountAction } from "../redux/actions/accountActions";
import useCases from "../hooks/queries/useCases";
import { setSnackAction } from "../redux/actions/snackActions";
import caseService from "../services/caseService";
import queryKeys from "../constants/queryKeys";
import reactQueryClient from "../reactQueryClient";
import { format } from "date-fns";

const useStyles = makeStyles((theme) => ({
    caseRow: {
        cursor: "pointer",
        height: "100%",
        "& $pin": {
            visibility: "hidden",
        },
        "&:hover $pin": {
            visibility: "visible",
        },
    },
    pinnedRow: {
        cursor: "pointer",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.04)",
        "&:hover": {
            backgroundColor: "#f4f2fe !important",
        },
        "& $pin": {
            fill: "#5c45c7",
        },
    },
    pin: {},
}));

const PinnedButton = styled(Button)`
    display: inline-block;
    border-radius: 50%;
    min-width: 20px;
    min-height: 20px;
    padding: 5px;
    text-align: center;
    line-height: 1;
    box-sizing: content-box;
    white-space: nowrap;
    &:before {
        content: "";
        display: inline-block;
        vertical-align: middle;
        padding-top: 100%;
        height: 0;
    }
    & span {
        display: inline-block;
        vertical-align: middle;
    }
}
`;

const CaseTable = ({
    configState,
    userState,
    getCase,
    adviserId,
    caseTypeId,
    modifiedFromDate,
    modifiedToDate,
    createdFromDate,
    createdToDate,
    employee,
    myRecent,
    accountId,
    account,
    partnershipId,
    partnerNumber,
    primaryContact,
    caseDescription,
    setCurrentAccountAction,
    setDisplayCaseSearchInTab,
    forAccountPage = false,
    showClosed,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    hideRowsPerPageOptions,
}) => {
    const dispatch = useDispatch();
    const classes = useStyles();

    const [tableHeadings, setTableHeadings] = useState([
        { name: "Number", sortBy: null, display: true, allowSort: true },
        { name: "Description", sortBy: null, display: true, allowSort: true },
        {
            name: "Account",
            sortBy: null,
            display: !forAccountPage,
            allowSort: true,
        },
        { name: "Modified", sortBy: 1, display: true, allowSort: true },
        { name: "Created", sortBy: null, display: true, allowSort: true },
        {
            name: "Primary Adviser",
            sortBy: null,
            display: !myRecent,
            allowSort: true,
        },
        {
            name: "Primary Contact",
            sortBy: null,
            display: true,
            allowSort: false,
        },
        { name: "Case Type", sortBy: null, display: true, allowSort: true },
        { name: "Employees", sortBy: null, display: true, allowSort: true },
        { name: "Status", sortBy: null, display: true, allowSort: true },        
        { name: "", sortBy: null, display: true, allowSort: false },
    ]);

    const avatarClasses = avatarStyles();
    const chipClasses = chipStyles();

    const history = useHistory();

    const { data, isLoading } = useCases({
        pageSize: rowsPerPage,
        pageNum: page + 1,
        adviserId: adviserId,
        modifiedFromDate: modifiedFromDate,
        modifiedToDate: modifiedToDate,
        createdFromDate: createdFromDate,
        createdToDate: createdToDate,
        caseTypeId: caseTypeId,
        employee: employee,
        myRecent: myRecent,
        sortBy: tableHeadings.filter((th) => th.sortBy !== null)[0].name,
        sortOrder: tableHeadings.filter((th) => th.sortBy != null)[0].sortBy,
        accountId: accountId,
        account: account,
        partnershipId: partnershipId,
        partnerNumber: partnerNumber,
        primaryContact: primaryContact,
        description: caseDescription,
        showClosed: showClosed,
    });

    const getCaseType = (id) => configState.caseTypes[id] || {};
    const getAdviser = (id) => userState.users[id] || {};

    const handleAddPin = async (e, id) => {
        e.stopPropagation();
        try {
            await caseService.addPinnedCase(id);
            reactQueryClient.invalidateQueries([queryKeys.cases]);
        } catch (e) {
            dispatch(setSnackAction("Pin add failed", "error"));
        }
    };

    const handleDeletePin = async (e, id) => {
        e.stopPropagation();
        try {
            await caseService.deletePinnedCase(id);
            reactQueryClient.invalidateQueries([queryKeys.cases]);
        } catch (e) {
            dispatch(setSnackAction("Pin remove failed", "error"));
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const handleClick = async (caseId) => {
        if (caseId === "") return;

        if (
            account ||
            partnerNumber ||
            employee ||
            partnershipId ||
            adviserId ||
            caseTypeId ||
            modifiedFromDate ||
            modifiedToDate ||
            createdFromDate ||
            createdToDate ||
            caseDescription ||
            primaryContact
        ) {
            //save the fact that a case has been clicked and that the search tab should be displayed
            //setCriteriaAccountName(account);
            setDisplayCaseSearchInTab(true);
        }
        //when a case is loaded then we set the currentAccountId empty so that the tabstip can work out which tab should be set to active
        setCurrentAccountAction(null);
        await getCase(caseId, history);
    };

    const toggleSortBy = (name) => {
        setTableHeadings((prevTableHeadings) =>
            prevTableHeadings.map((th) => {
                if (th.name === name && th.sortBy === null) {
                    return { ...th, sortBy: 0 };
                }
                if (th.name === name && th.sortBy === 1) {
                    return { ...th, sortBy: 0 };
                }
                if (th.name === name && th.sortBy === 0) {
                    return { ...th, sortBy: 1 };
                }
                return { ...th, sortBy: null };
            })
        );
    };   

    return !data?.cases || isLoading ? (
        <Loader />
    ) : (
        <React.Fragment>
            <Table>
                <TableHead>
                    <TableRow>
                        {tableHeadings
                            .filter((th) => th.display)
                            .map((th, index) => (
                                <TableCell key={index}>
                                    <Button
                                        style={{
                                            paddingLeft: "20px",
                                            paddingRight: "20px",
                                            marginLeft: "-20px",
                                            marginRight: "-20px",
                                        }}
                                        onClick={() => th.allowSort && toggleSortBy(th.name)}
                                    >
                                        {th.name} {th.sortBy === 0 && <ArrowDropDown />}
                                        {th.sortBy === 1 && <ArrowDropUp />}
                                    </Button>
                                </TableCell>
                            ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data?.cases.map((c) => (
                        <TableRow
                            className={c.isPinned ? classes.pinnedRow : classes.caseRow}
                            hover
                            key={c.caseId}
                            onClick={() => handleClick(c.caseId)}
                            data-cy="table_rows"
                        >
                            <TableCell data-cy={c.caseId}>
                                <Box display="flex" alignItems="center" justifyContent="flex-start">
                                    <Typography variant="body2">{c.caseId}</Typography>
                                    &nbsp;
                                    {c.grantAccessPermission && <GrantAccessIcon fontSize="small" />}
                                    {c.restrictAccessPermission && <RestrictAccessIcon fontSize="small" />}
                                </Box>
                            </TableCell>
                            {c.description && c.description.length > 20 ? (
                                <TableCell style={{ width: "20%" }}>{c.description}</TableCell>
                            ) : (
                                <TableCell>{c.description}</TableCell>
                            )}
                            {!forAccountPage && (
                                <TableCell onClick={() => handleClick(c.caseId)} data-cy="case_table_account">
                                    {c.accountIsDeleted ? c.account + " *deleted account*" : c.account}
                                </TableCell>
                            )}
                            <TableCell>{format(new Date(c.lastModifiedDate), "dd/MM/yyyy")}</TableCell>
                            <TableCell>{format(new Date(c.dateCreated), "dd/MM/yyyy")}</TableCell>                            
                            {!myRecent && (
                                <TableCell>
                                    <Box display="flex" alignItems="center" justifyContent="flex-start">
                                        <Avatar
                                            style={{ marginRight: "8px" }}
                                            className={avatarClasses.small}
                                            alt={getAdviser(c.currentAdviserId).name}
                                            src={getAdviser(c.currentAdviserId).photo}
                                        />
                                        <Typography variant="body2">{getAdviser(c.currentAdviserId).name}</Typography>
                                    </Box>
                                </TableCell>
                            )}
                            <TableCell>{c.primaryContactName}</TableCell>
                            <TableCell>{getCaseType(c.caseTypeId).name}</TableCell>
                            <TableCell>
                                <Box display="flex" flexDirection="column" justifyContent="space-between" style={{ margin: "-8px 0" }}>
                                    {c.employees.map((e) => (
                                        <React.Fragment key={e.employeeId}>
                                            <Box display="flex" alignItems="center" justifyContent="flex-start" style={{ margin: "8px 0" }}>
                                                <Avatar
                                                    style={{
                                                        marginRight: "8px",
                                                    }}
                                                    className={avatarClasses.small}
                                                    alt={e.name}
                                                    src={e.photo}
                                                />
                                                <Typography variant="body2">{e.youManageEmployee ? `${e.firstName} ${e.lastName}` : e.name}</Typography>
                                            </Box>
                                        </React.Fragment>
                                    ))}
                                </Box>
                            </TableCell>
                            <TableCell>
                                <Chip size="small" className={c.dateClosed ? chipClasses.red : chipClasses.green} label={c.dateClosed ? "Closed" : "Open"} />
                            </TableCell>                            
                            <TableCell>
                                {c.isPinned ? (
                                    <PinnedButton onClick={(e) => handleDeletePin(e, c.caseId)}>
                                        <StarIcon className={classes.pin} />
                                    </PinnedButton>
                                ) : (
                                    <PinnedButton onClick={(e) => handleAddPin(e, c.caseId)}>
                                        <StarBorderIcon className={classes.pin} />
                                    </PinnedButton>
                                )}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={hideRowsPerPageOptions ? [] : [5, 10, 25]}
                component="div"
                count={data?.count}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </React.Fragment>
    );
};

const mapStateToProps = (state) => ({
    configState: state.configReducer,
    userState: state.userReducer,
});

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(actionCreators, dispatch),
        setDisplayCaseSearchInTab: (display) => dispatch(setDisplayCaseSearchInTab(display)),
        setCurrentAccountAction: (accountId) => dispatch(setCurrentAccountAction(accountId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CaseTable);
