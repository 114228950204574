import React from "react";
import { grey } from "@material-ui/core/colors";
import { caseFileTypes } from "../../constants/caseFiles";
import Moment from "react-moment";
import avatarStyles from "../../theme/avatars";
import useIsMobile from "../../hooks/useIsMobile";

import {
    IconButton,
    Typography,
    Grid,
    Avatar,
    makeStyles,
    Checkbox,
} from "@material-ui/core";
import {
    MoreVert as MoreVertIcon,
    Description as TextIcon,
    Image as ImageIcon,
    PictureAsPdf as PdfIcon,
    LibraryMusic as MusicIcon,
    LocalMovies as VideoIcon,
    AllInbox as ZipIcon,
    Slideshow as PresentationIcon,
    BorderAll as SpreadsheetIcon,
    Email as EmailIcon,
} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
    tableRow: {
        padding: "8px 0",
        borderBottom: "1px solid rgba(224, 224, 224, 1)",
        "&:last-child": {
            borderBottom: "0",
        }
    },
    highlightRow: {
        backgroundColor: "#f2edff",
    },
    disabled: {
        "& div": {
            opacity: "0.3"
        }
    },
    fileFolder: {
        "& svg": {
            color: grey[700],
        }
    },
    maxGutter: {
        maxWidth: "45px",
    },
    spacer: {
        padding: "0 6px"
    },
    moreMenu: {
        textAlign: "right",
        [theme.breakpoints.up("sm")]: {
            flexGrow: 1,
            maxWidth: "none",
        },
    },
}));

const File = ({
    file,
    handleOpenContextMenu,
    isMove,
    handleAttachmentChecked,
    caseFilesForDownload,
    displayDownloadButton,
}) => {
    const classes = useStyles();
    const avatarClasses = avatarStyles();
    const isMobile = useIsMobile();
    const fileId = file.azureFileReference;
    const isChecked = caseFilesForDownload.find(f => f.azureFileReference === fileId) ? true : false;
    return (
        <Grid key={fileId} container className={`${classes.tableRow} ${isMove && classes.disabled} ${isChecked && classes.highlightRow}`}>
            {!isMove && ((isMobile && displayDownloadButton) || !isMobile) &&
                <Grid item xs={1} className={classes.maxGutter}>
                    <Checkbox
                        checked={isChecked}
                        onChange={(event) =>
                            handleAttachmentChecked(file, event.target.checked)
                        }
                    />
                </Grid>
            }
            <Grid item xs={1} className={`${classes.fileFolder} ${classes.maxGutter}`}>
                {(file.extension === "TXT" || file.extension === "DOC" || file.extension === "DOCX") && <TextIcon />}
                {file.extension === "PDF" && <PdfIcon />}
                {(file.extension === "JPEG" || file.extension === "GIF" || file.extension === "JPG" || file.extension === "PNG") && <ImageIcon />}
                {(file.extension === "WAV" || file.extension === "MP3" || file.extension === "M43") && <MusicIcon />}
                {(file.extension === "WEBM" || file.extension === "OGG" || file.extension === "MOV") && <VideoIcon />}
                {(file.extension === "PPT" || file.extension === "PPTX") && <PresentationIcon />}
                {(file.extension === "XLS" || file.extension === "XLSX" || file.extension === "CSV" || file.extension === "XLSB") && <SpreadsheetIcon />}
                {(file.extension === "MSG" || file.extension === "EML") && <EmailIcon />}                
                {file.extension === "ZIP" && <ZipIcon />}
            </Grid>
            <Grid item container xs={isMove ? 10 : (isMobile && displayDownloadButton ? 8 : 9)}>
                <Grid item s={12} md={8}>
                    <Typography variant="subtitle2">
                        {file.customName?.length > 0
                            ? file.customName
                            : file.title
                        }
                    </Typography>
                    {!isMobile && !isMove &&
                        <Typography variant="caption"> {file.extension}</Typography>
                    }
                </Grid>
                <Grid item container s={12} md={4}>
                    <Grid item s={12} lg={2} className={classes.maxGutter}>
                        {isMobile || isMove ?
                            <></>
                            :
                            <Avatar
                                style={{
                                    marginRight: "8px",
                                }}
                                className={avatarClasses.small}
                                alt={file.addedBy}
                                src={file.photo}
                            />
                        }
                    </Grid>
                    <Grid item s={12} lg={10}>
                        {isMobile || isMove ?
                            <>
                                <Typography variant="caption">
                                    <Moment format="D MMM YYYY, HH:mm">{file.dateAdded}</Moment>
                                </Typography>
                                <span className={classes.spacer}>•</span>
                            </>
                            :
                            <Typography variant="subtitle2">
                                <Moment format="D MMM YYYY, HH:mm">{file.dateAdded}</Moment>
                            </Typography>
                        }
                        <Typography variant="caption">{file.addedBy}</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={1} className={classes.moreMenu}>
                {!isMove &&
                    <IconButton onClick={ev => handleOpenContextMenu(file, ev, caseFileTypes.FILE)}>
                        <MoreVertIcon />
                    </IconButton>
                }
            </Grid>
        </Grid>
    );
};

export default File;