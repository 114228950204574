import React, { useState } from "react";
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    makeStyles,
    MenuItem,
    TextField,
} from "@material-ui/core";
import { KeyboardDatePicker, KeyboardTimePicker } from "@material-ui/pickers";
import { useDispatch, useSelector } from "react-redux";
import {
    getDstCorrectedDateString,
    getStartAndEndDate,
    isValidHoursMinutes,
} from "../../helpers/dateHelpers";
import { isValid } from "date-fns";
import { setSnackAction } from "../../redux/actions/snackActions";
import userActivityService from "../../services/userActivityService";

const useStyles = makeStyles((theme) => ({
    gridContainer: {
        marginTop: theme.spacing(-5),
    },
    datePicker: {
        width: "100%",
        paddingBottom: theme.spacing(2),
    },
}));

const AddBlockDialog = ({ open, handleClose, userId }) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [description, setDescription] = useState("");
    const [userActivityTypeId, setUserActivityTypeId] = useState("");
    const [date, setDate] = useState(new Date());
    const [startTime, setStartTime] = useState(null);
    const [hours, setHours] = useState(null);
    const [minutes, setMinutes] = useState(null);
    const [saving, setSaving] = useState(false);
    const { userActivityTypes } = useSelector((state) => state.configReducer);

    const handleSubmit = async () => {
        if (!isValidHoursMinutes(hours, minutes) || !isValid(new Date(date))) {
            dispatch(setSnackAction("Invalid date or time entered", "error"));
            return;
        }

        const { startDate, endDate } = getStartAndEndDate(
            date,
            startTime,
            hours,
            minutes
        );

        try {
            setSaving(true);
            await userActivityService.addBlock({
                userId,
                description,
                userActivityTypeId,
                start: getDstCorrectedDateString(startDate),
                finish: getDstCorrectedDateString(endDate),
            });
            dispatch(setSnackAction("Time successfully submitted", "success"));
            handleClickCancel();
        } catch (e) {
            console.error(e);
            dispatch(
                setSnackAction(
                    e?.message || "There was an error submitting the time",
                    "error"
                )
            );
        } finally {
            setSaving(false);
        }
    };

    const handleClickCancel = () => {
        setDescription("");
        setUserActivityTypeId("");
        setDate(new Date());
        setStartTime(null);
        setHours(null);
        setMinutes(null);
        handleClose();
    };

    return (
        <Dialog open={open} onClose={handleClickCancel} fullWidth>
            <DialogTitle>Add Block</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            select
                            value={userActivityTypeId}
                            onChange={(e) =>
                                setUserActivityTypeId(e.target.value)
                            }
                            label="Type"
                            variant="outlined"
                        >
                            {Object.values(userActivityTypes).map((t) => (
                                <MenuItem
                                    value={t.userActivityTypeId}
                                    key={t.userActivityTypeId}
                                >
                                    {t.description}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <KeyboardDatePicker
                            className={classes.datePicker}
                            format="dd/MM/yyyy"
                            margin="normal"
                            label="Date"
                            value={date}
                            onChange={(d) => setDate(d)}
                            inputVariant="outlined"
                        />
                    </Grid>
                </Grid>
                <Grid
                    container
                    spacing={2}
                    className={classes.gridContainer}
                    alignItems="center"
                >
                    <Grid item xs={12} sm={6}>
                        <KeyboardTimePicker
                            className={classes.datePicker}
                            margin="normal"
                            label="Start Time"
                            value={startTime}
                            onChange={(t) => setStartTime(t)}
                            ampm={false}
                            inputVariant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="number"
                                    value={hours}
                                    onChange={(e) => setHours(e.target.value)}
                                    inputProps={{ min: 0 }}
                                    label="Hours"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="number"
                                    value={minutes}
                                    onChange={(e) => setMinutes(e.target.value)}
                                    inputProps={{ min: 0, max: 59 }}
                                    label="Minutes"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                {saving ? (
                    <CircularProgress />
                ) : (
                    <>
                        <Button onClick={handleClickCancel} color="primary">
                            Cancel
                        </Button>
                        <Button
                            onClick={handleSubmit}
                            color="secondary"
                            disabled={
                                !userActivityTypeId ||
                                !date ||
                                !startTime ||
                                !(parseInt(hours) || parseInt(minutes)) ||
                                description.length === 0
                            }
                        >
                            Submit
                        </Button>
                    </>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default AddBlockDialog;
