import React, { useState } from "react";
import {
    Card, 
    CardContent,
    Table,
    TableRow,
    TableBody,
    TableCell,
    TableHead,
    CircularProgress,
    Box,
    makeStyles,
    IconButton,
    Button,
    Chip,
    Typography,
} from "@material-ui/core";
import useCaseActions from "../../hooks/queries/useCaseActions";
import { useSelector } from "react-redux";
import { Alert } from "@material-ui/lab";
import { format } from "date-fns";
import { Edit } from "@material-ui/icons";
import CaseActionDialog from "../../components/CaseActionDialog";
import {caseActionStatus, getCaseActionStatusClassName, getCaseActionStatusLabel} from "../../constants/caseActionStatus";
import { green, red, amber } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
    blankCell: {
        textAlign: "center"
    },
    table: {
        width: "100%"
    },
    statusChip: {
        color: "white",
    },
    [caseActionStatus.COMPLETE.className]: {
        backgroundColor: green[500]
    },
    [caseActionStatus.OPEN.className]: {
        backgroundColor: red[600]
    },
    [caseActionStatus.PENDING.className]: {
        backgroundColor: amber[500]
    },
    fullGrid: {
        borderBottom: `solid 2px ${theme.palette.secondary.main}`,
        display: 'flex',
        alignItems: 'center',
        height: '50px'
    }
}));

const actionSortFunction = (action1, action2) => {
    if (action1.completedOn != null && action2.completedOn == null)
        return 1;

    if (action1.completedOn == null && action2.completedOn != null)
        return -1;
    
    return new Date(action1.due) - new Date(action2.due);
}

const Milestones = () => {
    const classes = useStyles();

    const [open, setOpen] = useState(false);
    const [activeAction, setActiveAction] = useState({});
    
    const { currentCaseId: caseId } = useSelector(state => state.caseReducer);
    
    const { data, isLoading, isError, error } = useCaseActions({ caseId });
    
    const handleClickEdit = action => {
        setActiveAction(action);
        setOpen(true);
    }
    
    const handleClickAdd = () => {
        setOpen(true);
    }
    
    const tableContent = () => {
        const caseActions = data?.filter(x => x.isET);
        
        if (!caseActions?.length)
            return (
                <TableRow>
                    <TableCell className={classes.blankCell} colSpan={3}>No milestones to show</TableCell>
                </TableRow>
            )
        
        return caseActions.sort(actionSortFunction).map(a => 
            <TableRow key={a.id}>
                <TableCell>{a.type}</TableCell>
                <TableCell>{format(new Date(a.due), "dd/MM/yyyy")}</TableCell>
                <TableCell>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Chip 
                            className={`${classes.statusChip} ${classes[getCaseActionStatusClassName(a)]}`}
                            label={getCaseActionStatusLabel(a)}
                        />
                        {!a.completedOn &&<IconButton onClick={() => handleClickEdit(a)}><Edit /></IconButton>}
                    </Box>  
                </TableCell>
            </TableRow>
        )
    }
    
    const cardContent = () => {
        if (isLoading)
            return (
                <Box display="flex" justifyContent="center" width="100%">
                    <CircularProgress />
                </Box>
            );
        
        if (isError)
            return (
                <Box display="flex" justifyContent="center" width="100%">
                    <Alert variant="outlined" severity="error">
                        {error?.message || "Unable to load milestones"}
                    </Alert>
                </Box>
            );
        
        return (
            <div>
                <div className={classes.fullGrid}>
                    <Typography variant='h6'>
                        Milestones
                    </Typography>
                </div>
                <Box display="flex" flexDirection="column" width="100%">
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Milestone</TableCell>
                                <TableCell>Due date</TableCell>
                                <TableCell>Status</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tableContent()}
                        </TableBody>
                    </Table>
                    <Box mt={6}>
                        <Button color="primary" variant="contained" onClick={handleClickAdd}>
                            Add Milestone
                        </Button>
                    </Box>
                </Box>
            </div>
        )
    }
    
    return (
        <>
            <Card>
                <CardContent>
                    {cardContent()}
                </CardContent>
            </Card>
            <CaseActionDialog
                open={open}
                setOpen={setOpen}
                caseId={caseId}
                action={activeAction}
                setAction={setActiveAction}
                isET
            />
        </>
    );
}

export default Milestones;