import React, { useState, useEffect } from "react";
import axios from "../plugins/axios";
import { connect, useSelector } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import { Table, TableCell, TableHead, TableRow, TableBody, TablePagination, Chip, IconButton, Tooltip, Avatar } from "@material-ui/core";

import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";

import chipStyles from "../theme/chips";
import { setSnackAction } from "../redux/actions/snackActions";
import Loader from "../components/Loader";
import { AvatarGroup } from "@material-ui/lab";

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

const useStyles1 = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
}));

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
            <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
                {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
                {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="last page">
                {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
    );
}

const AccountTable = ({
    postCode,
    accountName,
    clickable,
    dispatch,
    handleClick,
    accountPageLoading,
    partnershipNumber,
    partnershipId,
    accountStatus,
    adviserId,
    rowsPerPage,
    setRowsPerPage,
    hideRowsPerPageOptions,
}) => {
    const [results, setResults] = useState([]);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(false);

    const chipClasses = chipStyles();

    const { users } = useSelector((state) => state.userReducer);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const updateAccounts = async () => {
        setLoading(true);
        let response = await axios.post(`${apiUrl}/getaccounts`, {
            name: accountName,
            postCode: postCode,
            partnershipNumber: partnershipNumber,
            pageNum: page + 1,
            pageSize: rowsPerPage,
            partnershipId: partnershipId,
            accountStatus: accountStatus,
            adviserId,
        });
        if (response.status !== 200) {
            dispatch(setSnackAction("Sorry, there was an error!", "error"));
        } else if (response.data.count === 0) {
            dispatch(setSnackAction("No results found!", "warning"));
        } else {
            setResults(response.data.accounts);
            setCount(response.data.count);
        }
        setLoading(false);
    };

    useEffect(() => {
        page === 0 ? updateAccounts() : setPage(0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountName, postCode, partnershipNumber, partnershipId, accountStatus, adviserId]);

    useEffect(() => {
        updateAccounts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, rowsPerPage]);

    return !results || loading || accountPageLoading ? (
        <Loader />
    ) : (
        <React.Fragment>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Account</TableCell>
                        <TableCell>Address</TableCell>
                        <TableCell>Postcode</TableCell>
                        <TableCell>Telephone Number</TableCell>
                        <TableCell>PartnershipName</TableCell>
                        <TableCell>Advisers</TableCell>
                        <TableCell style={{ textAlign: "center" }}>Status</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {results.map((a) => (
                        <TableRow
                            key={a.accountId}
                            style={{
                                cursor: clickable ? "pointer" : "default",
                                height: "100%",
                            }}
                            hover={clickable ? true : false}
                            onClick={() => clickable && handleClick(a)}
                        >
                            <TableCell>{a.name || "-"}</TableCell>
                            <TableCell>{a.address || "-"}</TableCell>
                            <TableCell>{a.postCode || "-"}</TableCell>
                            <TableCell>{a.phone || "-"}</TableCell>
                            <TableCell>
                                {!a.partnershipName ? "-" : !a.partnershipNumber ? a.partnershipName : a.partnershipName + " (" + a.partnershipNumber + ")"}
                            </TableCell>
                            <TableCell>
                                {a.adviserIds.length > 0 ? (
                                    <Tooltip
                                        title={a.adviserIds.map((id) => (
                                            <div key={id}>{users[id]?.name}</div>
                                        ))}
                                    >
                                        <AvatarGroup max={4}>
                                            {a.adviserIds.map((id) => (
                                                <Avatar key={id} alt={users[id]?.name} src={users[id]?.photo} />
                                            ))}
                                        </AvatarGroup>
                                    </Tooltip>
                                ) : (
                                    "-"
                                )}
                            </TableCell>
                            <TableCell style={{ textAlign: "center" }}>
                                <Chip
                                    className={
                                        a.status === "Active"
                                            ? chipClasses.green
                                            : a.status === "Pending"
                                                ? chipClasses.amber
                                                : chipClasses.root
                                    }
                                    label={a.status || "N/A"}
                                    style={{ width: "100%" }}
                                />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={hideRowsPerPageOptions ? [] : [5, 10, 25]}
                component="div"
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
            />
        </React.Fragment>
    );
};

export default connect()(AccountTable);
