import React from "react";
import TuilePill from "./TuilePill";
import FilterWrapper from "./FilterWrapper";
import { mergeSelectedFiltersWithFetchedFilters } from "../../helpers/mergeSelectedFiltersWithFetchedFilters";

const HistoryDirectionFilter = ({ directions = {}, directionFilters = [], onDirectionClick, isLoading }) => {

    const mergedFilters = mergeSelectedFiltersWithFetchedFilters(directions, directionFilters, "direction");

    return (
        <FilterWrapper filterName="Direction" isLoading={isLoading} hasBtnContainer>
            {mergedFilters?.length > 0
                ? mergedFilters.map((filter) => (
                    <TuilePill
                        key={filter.label}
                        name={filter.label}
                        count={filter.count}
                        callbackValue={filter}
                        ariaLabel={`${filter.label} button`}
                        isSelected={directions.hasOwnProperty(filter.direction)}
                        onClick={onDirectionClick}
                    />
                ))
                : null}
        </FilterWrapper>
    );
};

export default HistoryDirectionFilter;
