import React, { useEffect, useState, useCallback } from "react";
import axios from "../plugins/axios";

import { Button } from "@material-ui/core";

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

const OOHOverride = () => {
    const [ooh, setOoh] = useState(null);

    const fetch = useCallback(async () => {
        var response = await axios.get(`${apiUrl}/getoohoverride`);
        setOoh(response.data);
    }, [setOoh]);

    useEffect(() => {
        fetch();
    }, [fetch]);

    const toggle = async () => {
        let setting = !ooh;
        setOoh(null);
        try {
            await axios.post(`${apiUrl}/oohoverride/${setting}`);
            setOoh(setting);
        } catch (e) {
            console.error(e);
        }
    };

    if (ooh == null) return <h1>Checking ....</h1>;

    return (
        <React.Fragment>
            <h1>OOH Override is {ooh ? "ON" : "OFF"}</h1>
            <Button variant="contained" onClick={toggle}>
                TURN {ooh ? "OFF" : "ON"}
            </Button>
        </React.Fragment>
    );
};

export default OOHOverride;
