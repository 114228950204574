import { useQuery } from "react-query";
import queryKeys from "../../constants/queryKeys";
import accountService from "../../services/accountService";

const useAccountProducts = (accountId) =>
    useQuery([queryKeys.accountProducts, accountId],
        () => accountService.getAccountProducts(accountId),
        {
            enabled: !!accountId,
            staleTime: 3600000
        }
    );

export default useAccountProducts;

