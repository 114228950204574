import * as types from "../constants";
import axios from "../../plugins/axios";

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

const initialState = {
    account: {
        accountId: null,
        name: "",
        postCode: "",
        partnershipNumber: null,
    },
    contact: {
        contactId: null,
        name: "",
        position: "",
    },
    activeStep: 0,
};

export const createCaseActionCreators = {
    setCreateCaseAccount: (accountId) => async (dispatch, getState) => {
        let response = await axios.get(`${apiUrl}/getaccount/${accountId}`);
        if (response.status === 200) {
            const a = response.data;
            dispatch({
                type: types.SET_CREATE_CASE_ACCOUNT,
                account: {
                    accountId: a.accountId,
                    name: a.name,
                    postCode: a.postCode,
                },
            });
            dispatch({
                type: types.SET_ACTIVE_STEP,
                step: 1,
            });
        }
    },

    setCreateCaseAccountAndContact:
        (accountId, contactId) =>
        async (dispatch) => {
            let response = await axios.get(`${apiUrl}/getaccount/${accountId}`);
            let response2 = await axios.get(
                `${apiUrl}/getcontact/${contactId}`
            );

            if (response.status === 200 && response2.status === 200) {
                const a = response.data;
                const c = response2.data;
                dispatch({
                    type: types.SET_CREATE_CASE_ACCOUNT_AND_CONTACT,
                    account: {
                        accountId: a.accountId,
                        name: a.name,
                        postCode: a.postCode,
                        externalId: a.externalId,
                    },
                    contact: {
                        contactId: c.contactId,
                        name: c.name,
                        position: c.position,
                        externalId: c.externalId,
                    },
                });
                dispatch({
                    type: types.SET_ACTIVE_STEP,
                    step: 2,
                });
            }
        },
};

const reducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case types.SET_CREATE_CASE_ACCOUNT: {
            return {
                ...state,
                account: action.account,
            };
        }

        case types.SET_CREATE_CASE_CONTACT: {
            return {
                ...state,
                contact: action.contact,
            };
        }        

        case types.SET_ACTIVE_STEP: {
            return {
                ...state,
                activeStep: action.step,
            };
        }

        case types.SET_CREATE_CASE_ACCOUNT_AND_CONTACT: {
            return {
                ...state,
                account: action.account,
                contact: action.contact,
            };
        }

        case types.RESET_CREATE_CASE: {
            return initialState;
        }

        default:
            return state;
    }
};

export default reducer;
