import axios from "../plugins/axios";

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

const getCaseOutcomes = async (isEt = false, forDropdown = false) => {
    try {
        const outcomes = await axios.get(
            `${apiUrl}/caseoutcomes/${isEt}/${forDropdown}`
        );
        return outcomes.data;
    } catch (e) {
        throw e;
    }
};

const caseOutcomeService = {
    getCaseOutcomes,
};

export default caseOutcomeService;
