import React from 'react';
import {
    IconButton,
    makeStyles,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
} from "@material-ui/core";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import documentService from '../../services/documentService';

const useStyles = makeStyles(() => ({
    dialogTitle: {
        paddingTop: 0,
    },
    dialogTitleContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
}));

export default function PasswordProtectedFileDialog({ onClose, open, fileName, caseString, attachment, caseId }) {
    const classes = useStyles();    

    const downloadDocument = async () => {        

        let response = await documentService.retrieveDocumentPreviewPath({
            reference: attachment.reference,
            contentType: attachment.contentType,
            isForFilePreview: false
        });   

        let link = document.createElement("a");
        link.href = response;
        link.download = attachment.filename;
        link.click();
    }    

    return (
        <Dialog
            className="file-modal"
            onClose={onClose}
            open={open}
            fullWidth
            maxWidth={"lg"}
        >
            <DialogTitle className={classes.dialogTitle}>
                <div className={classes.dialogTitleContainer}>
                    <Typography variant="h6">
                        Viewing document - {fileName}
                        {caseString}
                    </Typography>
                    <div>
                        <IconButton
                            onClick={downloadDocument}
                        >
                            <CloudDownloadIcon />
                        </IconButton>
                    </div>
                </div>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    This file may be password protected, download and open locally to view content.
                </DialogContentText>
            </DialogContent>
        </Dialog>
    )
}