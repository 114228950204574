import { useQuery } from "react-query";
import queryKeys from "../constants/queryKeys";
import OpenAI from "openai";

const useAIAssistants = () =>
    useQuery([queryKeys.aiAssistants], async () => {

        const openai = new OpenAI({
            apiKey: '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',
            dangerouslyAllowBrowser: true
        });

        const assistants = await openai.beta.assistants.list({
            limit: 100,
        });

        console.log({ assistants });

        return assistants.data.filter(a => a.name.startsWith('Copilot'));

    }, {
        staleTime: Infinity,
    });

export default useAIAssistants;
