import React from 'react'
import { Dialog, DialogTitle, DialogContent, Typography, IconButton, Box } from '@material-ui/core';
import { Clear } from '@material-ui/icons';


export default function PopupControl(props) {

    const { title, children, openPopup, setOpenPopup } = props;

    return (

        <Dialog open={openPopup} maxWidth="md">
            <DialogTitle>
                <Box display="flex" alignItems="center">
                    <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
                        {title}
                    </Typography>
                    <IconButton variant="contained" onClick={() => setOpenPopup(false)}><Clear /></IconButton>
                </Box>
            </DialogTitle>
            <DialogContent dividers>
                {children}
            </DialogContent>
        </Dialog>
    )
}