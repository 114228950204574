import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  TextField,
  Button,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles(() => ({
  textField: {
    width: "100%",
  },
}))

const ReassignTaskDialog = ({ open, onClose, options, handleAssignTask, teams }) => {
  const { users, userProfile } = useSelector(state => state.userReducer);
  const [message, setMessage] = useState("");

  const name = options.userId ? users[options.userId].name :
    options.teamId ? teams.filter(t => t.teamId === options.teamId)[0].name :
      options.queueName ? options.queueName :
        "routing";

  const classes = useStyles();

  const handleClose = () => {
    setMessage("");
    onClose();
  };

  const handleClickAssign = () => {
    handleAssignTask({ ...options, message, assignedBy: userProfile.name });
    handleClose();
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>Assign task</DialogTitle>
      <DialogContent>
        <DialogContentText>Add an optional message to {name} before assigning this task</DialogContentText>
        <TextField multiline value={message} onChange={e => setMessage(e.target.value)} className={classes.textField} />
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleClose}>Cancel</Button>
        <Button color="primary" onClick={handleClickAssign}>Assign</Button>
      </DialogActions>
    </Dialog>
  );
}

export default ReassignTaskDialog;
