import React from "react";
import { makeStyles } from "@material-ui/core";
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import FilterWrapper from "./FilterWrapper";

const useStyles = makeStyles((theme) => ({
    datePickersContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        marginBottom: theme.spacing(3),
        [theme.breakpoints.up("sm")]: {
            flexDirection: "row",
        },
        [theme.breakpoints.up("md")]: {
            flexDirection: "column",
        },
    },
    fromDatePicker: {
        marginBottom: theme.spacing(3),
        [theme.breakpoints.up("sm")]: {
            marginRight: theme.spacing(2),
            marginBottom: theme.spacing(0),
        },
        [theme.breakpoints.up("md")]: {
            marginRight: 0,
            marginBottom: theme.spacing(3),
        },
    },
}));

const HistoryDateFilter = ({
    dateFrom = null,
    dateTo = null,
    onFromDateChange,
    onToDateChange,
}) => {
    const classes = useStyles();

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <FilterWrapper filterName="Date Range">
                <div className={classes.datePickersContainer}>
                    <KeyboardDatePicker
                        className={classes.fromDatePicker}
                        name="date"
                        format="dd/MM/yyyy"
                        label="From"
                        value={dateFrom}
                        onChange={(date) => onFromDateChange(date)}
                        maxDate={dateTo ?? new Date()}
                        inputVariant="outlined"
                    />
                    <KeyboardDatePicker
                        name="date"
                        format="dd/MM/yyyy"
                        label="To"
                        value={dateTo}
                        onChange={(date) => onToDateChange(date)}
                        minDate={dateFrom}
                        maxDate={new Date()}
                        inputVariant="outlined"
                    />
                </div>
            </FilterWrapper>
        </MuiPickersUtilsProvider>
    );
};

export default HistoryDateFilter;
