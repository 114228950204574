import React from "react";
import { useQuery } from "react-query";
import queryKeys from "../../constants/queryKeys";
import accountService from "../../services/accountService";

const useAccountLeiDetails = ({
    accountId,
    pageSize,
    pageNum,
    includeExpired,
}) =>
    useQuery(
        [
            queryKeys.accountLeiDetails,
            accountId,
            pageSize,
            pageNum,
            includeExpired,
        ],
        () =>
            accountService.getAccountLeiDetails({
                accountId,
                pageSize,
                pageNum,
                includeExpired,
            }),
        {
            enabled: !!accountId && !!pageSize,
        }
    );

export default useAccountLeiDetails;
