import React, { useState, useEffect } from "react";
import axios from "../plugins/axios";
import { makeStyles } from "@material-ui/core/styles";
import MyCKEditor from "../ckeditor/MyCKEditor";
import MentionCard from "../ckeditor/MentionCard";
import PerfectScrollbar from "react-perfect-scrollbar";
import axios_raw from "axios";
import moment from "moment";
import { converterFileTypes } from "../constants/documentConstants";
import { setSnackAction } from "../redux/actions/snackActions";
import documentService from "../services/documentService";
import { connect } from "react-redux";

import { getNodesByType, setMentionValue } from "../ckeditor/ckEditorUtils";

import {
    Box,
    Button as MuiIconButton,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    Paper,
    Typography,
    Button,
} from "@material-ui/core";

import {
    PictureAsPdf as PdfIcon,
    Close as CloseIcon,
    GavelRounded as GavelRoundedIcon,
} from "@material-ui/icons";

import ckcss from "../ckeditor/ck-content.css";

const useStyles = makeStyles({
    dialog: {
        height: "calc(100% - 64px)",
    },
});

const styles = { targetDiv: { height: "calc(100vh - 180px)" } };

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

const PrecedentDialog = ({
    open,
    onClose,
    precedentId,
    showDownload,
    caseId,
    setSnack,
}) => {
    const setExpandedCheck = (val) =>
        setExpanded(val === expanded ? false : val);
    const [expanded, setExpanded] = useState(false);

    const classes = useStyles();
    const [current, setCurrent] = useState(null);
    const [editor, setEditor] = useState();
    const [editorHtml, setEditorHtml] = useState();
    const [mentions, setMentions] = useState([]);

    useEffect(() => {
        const getDocument = async () => {
            var response = await axios.get(
                `${apiUrl}/getprecedent/${precedentId}`
            );
            setCurrent(response.data);
        };
        if (precedentId) {
            getDocument();
        }
    }, [precedentId]);

    useEffect(() => {
        if (!current) return;

        let html = "";

        if (current.htmlContent) {
            html = current.htmlContent;
        }

        if (editor) editor.setData(html);

        setEditorHtml(html);
    }, [current, editor]);

    useEffect(() => {
        if (editor) {
            editor.model.change((writer) => {
                const root = editor.model.document.getRoot();
                const elements = getNodesByType(
                    writer,
                    ["blockplaceholder", "inlineplaceholder"],
                    root
                );

                const ckmentions = elements.map((x) =>
                    x.getAttribute("mention")
                );

                setMentions(ckmentions);
            });
        }
    }, [editor, editorHtml, setMentions]);

    const setValue = (key, value) => {
        setMentionValue(editor, key, value);
    };

    const downloadPdf = async () => {
        let converterOptions = {
            margin_top: "15mm",
            margin_bottom: "15mm",
            margin_right: "15mm",
            margin_left: "15mm",
        };

        let response = await axios_raw.post(
            "https://ckeditor-pdf.greenwave-257981d3.westeurope.azurecontainerapps.io/v1/convert",
            {
                html: `<div class="ck-content">${editor.getData()}</div>`,
                css: ckcss,
                options: converterOptions,
            },
            { responseType: "blob" }
        );

        const blob = new Blob([response.data]);

        var link = window.document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.setAttribute("target", "_blank");

        //make the filename valid an unique
        var filename = current.name.replace(/[^a-z0-9]/gi, "_");

        link.download = `${
            filename + "_" + moment().format("YYYYMMDDHH:m:s")
        }.pdf`;
        link.click();
    };

    const openInWord = async () => {
        try {
            if (!editorHtml)
                throw "Precedent has no html content";

            let converterOptions = {
                margin_right: "15mm",
                margin_left: "15mm",
            };

            const blobResponse = await documentService.exportDocument({
                html: `<div class="ck-content">${editorHtml}</div>`,
                css: ckcss,
                options: converterOptions,
                converterFileType: converterFileTypes.DOCX,
            });

            const documentResponse = await documentService.openInSharepoint({
                blob: blobResponse.data,
                caseId,
                filename: current.name + ".docx",
            });
            window.open(documentResponse.data, "_blank");
            onClose();
        } catch (e) {
            console.error(e);
            setSnack(
                e?.message || "There was an error opening the precedent",
                "error"
            );
        }
    };

    let caseString = "";
    if (caseId) caseString = " - Case " + caseId;

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            maxWidth="lg"
            classes={{ paper: classes.dialog }}
        >
            <Box
                display="flex"
                flexDirection="column"
                style={{ height: "100%" }}
            >
                <DialogTitle>
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Typography variant="h6">
                            Viewing precedent - {current && current.name}
                            {caseString}
                        </Typography>
                        {caseId != null && (
                            <Button
                                color="secondary"
                                variant="outlined"
                                onClick={openInWord}
                            >
                                Copy to Live Document
                            </Button>
                        )}
                    </Box>
                </DialogTitle>
                <Box flexGrow={1} style={{ height: 0 }}>
                    <DialogContent style={{ height: "100%" }}>
                        <Grid container spacing={3} style={styles.targetDiv}>
                            <Grid item xs={3} style={{ height: "100%" }}>
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    style={{ height: "100%" }}
                                >
                                    <Box mb={3}>
                                        <Typography variant="h5">
                                            Steps below to be filled in by the
                                            client via the email link
                                        </Typography>
                                    </Box>
                                    <Box flexGrow={1} style={{ height: 0 }}>
                                        <PerfectScrollbar>
                                            <Paper style={{ padding: "1px" }}>
                                                {mentions.map((m) => (
                                                    <MentionCard
                                                        key={m.key}
                                                        mention={m}
                                                        expanded={
                                                            m.key === expanded
                                                        }
                                                        setExpanded={
                                                            setExpandedCheck
                                                        }
                                                        setValue={(value) =>
                                                            setValue(
                                                                m.key,
                                                                value
                                                            )
                                                        }
                                                    />
                                                ))}
                                            </Paper>
                                        </PerfectScrollbar>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs style={{ height: "100%" }}>
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    style={{ height: "100%" }}
                                >
                                    <Box
                                        mb={3}
                                        display="flex"
                                        justifyContent="space-between"
                                    >
                                        <Box display="flex">
                                            <GavelRoundedIcon fontSize="small" />
                                            &nbsp;
                                            <Typography variant="h5">
                                                {current && current.name}
                                            </Typography>
                                        </Box>
                                        <Box display="flex">
                                            <Typography></Typography>
                                            <MuiIconButton
                                                style={{
                                                    padding: "1px",
                                                    minWidth: 0,
                                                }}
                                                onClick={onClose}
                                            >
                                                Close
                                                <CloseIcon />
                                            </MuiIconButton>
                                        </Box>
                                    </Box>
                                    <Box
                                        mb={2}
                                        flexGrow={1}
                                        style={{ height: 0 }}
                                        display="flex"
                                    >
                                        <MyCKEditor
                                            initialData=""
                                            setEditor={setEditor}
                                            type="readonly"
                                            contained
                                            onChange={setEditorHtml}
                                        />
                                    </Box>
                                    {showDownload && (
                                        <Box
                                            display="flex"
                                            justifyContent="flex-end"
                                            className="ew"
                                        >
                                            <Box>
                                                <MuiIconButton
                                                    onClick={downloadPdf}
                                                >
                                                    Download
                                                    <PdfIcon
                                                        className="ew grow"
                                                        fontSize="large"
                                                    />
                                                </MuiIconButton>
                                            </Box>
                                        </Box>
                                    )}
                                </Box>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Box>
            </Box>
        </Dialog>
    );
};

const mapDispatchToProps = (dispatch) => ({
    setSnack: (message, severity) =>
        dispatch(setSnackAction(message, severity)),
});

export default connect(null, mapDispatchToProps)(PrecedentDialog);
