import React from 'react'
import {
	Box,
	Modal,
	Typography
} from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";


const modalStyles = makeStyles((theme) => ({
		modal: {
			position: 'absolute',
			top: '50%',
			left: '50%',
			transform: 'translate(-50%, -50%)',
			width: 700,
			backgroundColor: 'white',
			border: '1px solid #333',
			borderRadius: "6px",
			padding: theme.spacing(8),
			flexDirection: "column"
		},
		summary: {
			display: "flex",
			flexDirection: "row",
			marginBottom: theme.spacing(4),
			gap: theme.spacing(2),
			minWidth: "100%",
		},
		title: {
			textAlign: "center",
			marginBottom: "16px"
        },
		label: {
			fontWeight: 600,
			fontSize: 14,
			width: "15%"
		},
		text: {
			fontSize: 14,
			width: "80%",
        }
}));

const TwilioSummaryModal = ({ open, onClose, summary }) => {
	const modalClasses = modalStyles();

	return	(
			<Modal
				open={open}
				onClose={() => onClose()}
			>
				<Box className={modalClasses.modal} display="flex">
					<Box flexGrow={1} alignItems="center">
						<Typography className={modalClasses.title} variant="h4" gutterBottom>{'Case #' + summary?.caseId} </Typography>
					</Box>
					<Box display="flex" alignItems="flex-start" flexDirection="column">
						<div className={modalClasses.summary}>
							<Typography className={modalClasses.label}>Case Description</Typography>
							<Typography className={modalClasses.text}>{summary?.caseDescription}</Typography>
						</div>
						<div className={modalClasses.summary}>
							<Typography className={modalClasses.label}>Action Category</Typography>
							<Typography className={modalClasses.text}>{summary?.actionCategory}</Typography>
						</div>
						<div className={modalClasses.summary}>
							<Typography className={modalClasses.label}>Action Type</Typography>
							<Typography className={modalClasses.text}>{summary?.actionType}</Typography>
						</div>
						<div className={modalClasses.summary}>
							<Typography className={modalClasses.label}>Summary</Typography>
							<Typography className={modalClasses.text}>{summary?.actionSummary}</Typography>
						</div>				
						<div className={modalClasses.summary}>
							<Typography className={modalClasses.label}>Details</Typography>
							<Typography className={modalClasses.text}>{summary?.details}</Typography>
						</div>
					</Box>
				</Box>
			</Modal>
		)
}

export default TwilioSummaryModal