import React from "react";
import { Box, Typography, makeStyles, Tooltip } from "@material-ui/core";
import { InfoOutlined as InfoIcon } from "@material-ui/icons";

const useStyles = makeStyles(() => ({
    permissionsIcon: {
        position: "relative",
        top: "3.5px",
        marginLeft: "1px",
        fontSize: 20,
    },
}));

export const ContractProductDetail = ({ productDetails }) => {
    const classes = useStyles();

    if (!productDetails) return null;

    return (
        <Tooltip
            className={classes.permissionsIcon}
            title={
                <Box display="flex" flexDirection="column" p={2}>
                    <Typography variant="subtitle1">Product Details</Typography>
                    <Typography>{productDetails}</Typography>
                </Box>
            }
            arrow
            interactive
            placement="right-start"
        >
            <InfoIcon
                
            />
        </Tooltip>
    );
};

export default ContractProductDetail;
