import { useQuery } from "react-query";
import queryKeys from "../../constants/queryKeys";
import caseService from "../../services/caseService";

const useCases = (criteria) =>
    useQuery([queryKeys.cases, ...Object.values(criteria)], () =>
        caseService.getCases(criteria)
    );

export default useCases;
