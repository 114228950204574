import React, { useCallback, useEffect, useRef, useState } from "react";
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    IconButton,
    Typography,
} from "@material-ui/core";
import { formatTimeDigital } from "../../helpers/formatTime";
import { makeStyles } from "@material-ui/styles";
import { Delete, FiberManualRecord, Stop } from "@material-ui/icons";
import { alpha } from "@material-ui/core/styles";
import { format } from "date-fns";
import { red } from "@material-ui/core/colors";
import voicemailService from "../../services/voicemailService";
import { useDispatch } from "react-redux";
import { setSnackAction } from "../../redux/actions/snackActions";
import { Alert } from "@material-ui/lab";
import useAudioRecorder from "../../hooks/useAudioRecorder";

const useStyles = makeStyles((theme) => ({
    container: {
        color: (props) => props.disabled && "darkgrey",
    },
    recordingButtons: {
        display: "flex",
        justifyContent: "center",
    },
    startStopButton: {
        marginLeft: theme.spacing(3),
    },
    recordingContainer: {
        border: `1px solid ${alpha(theme.palette.secondary.main, 0.1)}`,
        borderRadius: "4px",
        display: (props) =>
            !props.recordingBlobToSave && !props.url ? "none" : "",
        width: "400px",
        margin: theme.spacing(3, 0),
    },
    dateContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: theme.spacing(3),
    },
    playerContainer: {
        display: "flex",
        justifyContent: "center",
        width: "100%",
        padding: theme.spacing(3, 0),
        pointerEvents: (props) => (props.disabled ? "none" : "auto"),
    },
    divider: {
        padding: theme.spacing(0, 3),
    },
    deleteButton: {
        color: red[900],
    },
    newRecordingButton: {
        margin: theme.spacing(3, 0),
    },
    subtitle: {
        marginBottom: theme.spacing(3),
    },
    loadingContainer: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        margin: theme.spacing(3, 0),
    },
}));

const Voicemail = ({
    userId,
    setRecordingBlobToSave,
    recordingBlobToSave,
    disabled,
    userVoicemail,
}) => {
    const [url, setUrl] = useState(null);
    const [newRecordingOpen, setNewRecordingOpen] = useState(false);
    const [deleteRecordingOpen, setDeleteRecordingOpen] = useState(false);
    const [recordingCancelled, setRecordingCancelled] = useState(false);
    const [date, setDate] = useState(null);
    const classes = useStyles({ recordingBlobToSave, url, disabled });
    const dispatch = useDispatch();
    const playerRef = useRef(null);

    const {
        startRecording,
        stopRecording,
        recordingBlob,
        isRecording,
        recordingTime,
    } = useAudioRecorder();

    const removeAudioPlayer = useCallback(() => {
        if (playerRef?.current?.hasChildNodes())
            playerRef.current.removeChild(playerRef.current.children[0]);
    }, []);

    const addAudioPlayer = useCallback(
        (url) => {
            removeAudioPlayer();
            const audio = document.createElement("audio");
            audio.controls = true;
            audio.src = url;
            playerRef?.current?.appendChild(audio);
        },
        [removeAudioPlayer]
    );

    const handleCloseNewRecording = (event, reason) => {
        if (reason === "backdropClick" || reason === "escapeKeyDown") return;
        setNewRecordingOpen(false);
    };

    const handleConfirmDelete = async () => {
        try {
            await voicemailService.deleteForUser({ userId });
            setDeleteRecordingOpen(false);
            setUrl(null);
            setRecordingBlobToSave(null);
            removeAudioPlayer();
        } catch (e) {
            console.error(e);
            dispatch(
                setSnackAction(
                    e?.message || "There was an error deleting the voicemail",
                    "error"
                )
            );
        }
    };

    useEffect(() => {
        if (recordingCancelled) {
            if (!!recordingTime) stopRecording();
            else setNewRecordingOpen(false);
        }
    }, [recordingCancelled]);

    useEffect(() => {
        if (recordingBlob && !recordingCancelled) {
            setRecordingBlobToSave(recordingBlob);
            setDate(new Date());
            const blobUrl = URL.createObjectURL(recordingBlob);
            addAudioPlayer(blobUrl);
        }
        setNewRecordingOpen(false);
    }, [recordingBlob]);

    useEffect(() => {
        if (!newRecordingOpen) setRecordingCancelled(false);
    }, [newRecordingOpen]);

    useEffect(() => {
        if (userVoicemail?.data?.url) setUrl(userVoicemail.data.url);
        if (userVoicemail?.data?.createdOn)
            setDate(new Date(userVoicemail.data.createdOn));
        if (userVoicemail?.data === "") {
            setUrl(null);
            removeAudioPlayer();
        }
    }, [userVoicemail?.data, removeAudioPlayer]);

    useEffect(() => {
        if (url) addAudioPlayer(url);
    }, [url, addAudioPlayer]);

    return (
        <div className={classes.container}>
            <div className={classes.loadingContainer}>
                {userVoicemail.isLoading && <CircularProgress />}
                {userVoicemail.isError && (
                    <Alert variant="outlined" severity="error">
                        {userVoicemail.error}
                    </Alert>
                )}
            </div>
            {userVoicemail.isSuccess && (
                <>
                    <Typography className={classes.subtitle} variant="h5">
                        Outgoing Voicemail Message
                    </Typography>
                    {url == null && recordingBlobToSave == null && (
                        <Typography variant="caption">
                            No outgoing voicemail message available. Please
                            record a new voicemail message.
                        </Typography>
                    )}
                    <div className={classes.recordingContainer}>
                        <div className={classes.dateContainer}>
                            <Typography variant="subtitle2">
                                {!!date && format(date, "dd LLL yyyy kk:mm")}
                            </Typography>
                            <IconButton
                                disabled={disabled}
                                onClick={() => setDeleteRecordingOpen(true)}
                            >
                                <Delete />
                            </IconButton>
                        </div>
                        <div className={classes.divider}>
                            <Divider />
                        </div>
                        <div
                            ref={playerRef}
                            className={classes.playerContainer}
                        >
                            <audio controls>
                                <source src={url} type="audio/mp3" />
                            </audio>
                        </div>
                    </div>
                    <div className={classes.newRecordingButton}>
                        <Button
                            disabled={disabled}
                            color="secondary"
                            onClick={() => setNewRecordingOpen(true)}
                        >
                            Record new outgoing voicemail message
                        </Button>
                    </div>
                </>
            )}
            <Dialog open={newRecordingOpen} onClose={handleCloseNewRecording}>
                <DialogTitle>New Outgoing Voicemail Message</DialogTitle>
                <DialogContent>
                    <DialogContentText align="center">
                        {formatTimeDigital(recordingTime)}
                    </DialogContentText>
                    <div className={classes.recordingButtons}>
                        <Button
                            variant="outlined"
                            color="secondary"
                            onClick={() => setRecordingCancelled(true)}
                        >
                            Cancel
                        </Button>
                        <Button
                            startIcon={
                                isRecording ? <Stop /> : <FiberManualRecord />
                            }
                            variant="contained"
                            color="secondary"
                            className={classes.startStopButton}
                            onClick={
                                isRecording ? stopRecording : startRecording
                            }
                        >
                            {isRecording ? "Stop Recording" : "Start Recording"}
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
            <Dialog
                open={deleteRecordingOpen}
                onClose={() => setDeleteRecordingOpen(false)}
            >
                <DialogTitle>Delete Recording?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Deleting your outgoing voicemail recording will
                        permanently remove it. If you proceed, a default generic
                        message will be used.
                    </DialogContentText>
                    <DialogActions>
                        <Button
                            onClick={() => setDeleteRecordingOpen(false)}
                            color="secondary"
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={handleConfirmDelete}
                            className={classes.deleteButton}
                        >
                            Delete
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default Voicemail;
