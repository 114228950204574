import React, { useState, useEffect, useMemo, useRef } from "react";
import { useDispatch } from "react-redux";
import {
    Box,
    Chip,
    CircularProgress,
    IconButton,
    InputAdornment,
    Menu,
    MenuItem,
    TextField,
    Typography,
} from "@material-ui/core";
import {
    Search as SearchIcon,
} from "@material-ui/icons";
import useAccountsSlim from "../hooks/queries/useAccountsSlim";
import { makeStyles } from "@material-ui/core/styles";
import { setSnackAction } from "../redux/actions/snackActions";

const useStyles = makeStyles((theme) => ({
    accountsOptionIcon: {
        marginRight: theme.spacing(3),
    },
    selectedAccountChip: {
        backgroundColor: "#F4F2FE",
    },
    accountsMenuFooter: {
        padding: theme.spacing(3,4),
        position: 'sticky',
        bottom: 0,
        backgroundColor: theme.palette.background.paper,
        borderTop: '1px solid rgba(0, 0, 0, 0.12)',
        marginTop: theme.spacing(2),
    },
    accountsMenuFooterPagingCaption: {
        marginRight: theme.spacing(3),
    },
    accountsMenuPaper: {
        maxHeight: '60vh',
        overflowY: 'auto',
    }
}));

const AccountInfiniteSearch = ({selectedAccount, error, helperText, onAccountSelected, onAccountCleared }) => {

    const classes = useStyles();
    const [accountsMenuAnchorEl, setAccountsMenuAnchorEl] = useState(null);
    const accountsMenuOpen = Boolean(accountsMenuAnchorEl);
    const [accountsSearchTerm, setAccountsSearchTerm] = useState("");
    const searchButtonRef = useRef(null);
    const dispatch = useDispatch();

    const {
        data: accountsData,
        isLoading: isAccountsLoading,
        isError: isAccountsError,
        error: accountsError,
        fetchNextPage: fetchNextAccountsPage,
        hasNextPage: hasNextAccountsPage,
        isFetchingNextPage: isFetchingNextAccountsPage,
    } = useAccountsSlim({ query: accountsSearchTerm });

    useEffect(() => {
        if (isAccountsError) {
            dispatch(setSnackAction(`Unable to retrieve accounts: ${accountsError}`, "error"));
        }
    }, [isAccountsError])

    const accountAggregates = useMemo(() => {
        let visibleCount = accountsData?.pages?.reduce((acc, page) => acc + page.accounts.length, 0) || 0;
        let totalCount = accountsData?.pages?.[0]?.count || 0;

        return {
            visibleCount,
            totalCount,
        }
    }, [accountsData])

    const handleSearchClick = (event) => {
        setAccountsMenuAnchorEl(event.currentTarget);
    };

    const handleAccountSelectionClear = () => {
        onAccountCleared();
    };

    const handleAccountsMenuClose = () => {
        setAccountsMenuAnchorEl(null);
    };

    const handleAccountSelected = (account) => {
        setAccountsMenuAnchorEl(null);
        onAccountSelected(account);
    };

    const handleAccountSearchTermChange = (event) => {
        setAccountsSearchTerm(event.target.value);
    };

    const handleAccountSearchKeyPress = (event) => {
        if (event.key === 'Enter'){
            if (searchButtonRef.current){
                setAccountsMenuAnchorEl(searchButtonRef.current);
            }
        }
    };

    const handleAccountsMenuScroll = (event) => {
        const target = event.target;
        const scrollPercentage = (target.scrollTop / (target.scrollHeight - target.clientHeight)) * 100;
        const percentageAtWhichMoreAccountsLoad = 80;
        if (scrollPercentage >= percentageAtWhichMoreAccountsLoad
            && hasNextAccountsPage && !isFetchingNextAccountsPage) {
            fetchNextAccountsPage();
        }
    };

    const isAccountSelected = selectedAccount !== null;

    return (
        <>
            {isAccountSelected ?
                <Chip
                    className={classes.selectedAccountChip}
                    label={selectedAccount?.name}
                    onDelete={handleAccountSelectionClear}
                />
                :
                <TextField
                    value={accountsSearchTerm}
                    onChange={handleAccountSearchTermChange}
                    label="Search account"
                    variant="outlined"
                    fullWidth
                    error={error}
                    helperText={helperText}
                    onKeyPress={handleAccountSearchKeyPress}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment
                                position="end"
                            >
                                {isAccountsLoading ?
                                    <CircularProgress size="1em" /> :
                                    <IconButton
                                        id="accounts-search-button"
                                        ref={searchButtonRef}
                                        onClick={handleSearchClick}
                                        aria-controls={accountsMenuOpen ? 'accounts-menu' : undefined}
                                        aria-haspop="true"
                                        aria-expanded={accountsMenuOpen ? 'true' : undefined}
                                    >
                                        <SearchIcon />
                                    </IconButton>
                                }
                            </InputAdornment>
                        )
                    }}
                />
            }
            <Menu
                id="accounts-menu"
                anchorEl={accountsMenuAnchorEl}
                open={accountsMenuOpen}
                onClose={handleAccountsMenuClose}
                MenuListProps={{
                    'aria-labelledby': 'accounts-search-button'
                }}
                classes={{ paper: classes.accountsMenuPaper }}
                onScroll={handleAccountsMenuScroll}
            >
                { accountAggregates.visibleCount == 0 &&
                    <Box
                        display="flex"
                        width="100%"
                        justifyContent="center"
                        mt={1}
                        mb={3}
                        key="no-results-found"
                    >
                        <Typography>No results found</Typography>
                    </Box>
                }
                {accountsData?.pages?.map((accountsPage, i) => (
                    accountsPage?.accounts?.map((account) => (
                        <MenuItem
                            key={account.id}
                            onClick={() => handleAccountSelected(account)}
                        >
                            {account?.name}
                        </MenuItem>
                    ))
                ))}
                <Box
                    display="flex"
                    justifyContent="space-between"
                    key="accounts-menu-footer"
                    className={classes.accountsMenuFooter}
                >
                    <Typography className={classes.accountsMenuFooterPagingCaption}>
                        {`${accountAggregates.visibleCount == 0 ? '0' : '1'}-${accountAggregates.visibleCount} of ${accountAggregates.totalCount}`}
                    </Typography>
                    {isFetchingNextAccountsPage ? (
                        <CircularProgress size="1rem" />
                    ) : hasNextAccountsPage ? (
                        <Typography variant="body1">More to load</Typography>
                    ) : (
                        <Typography variant="body1">Nothing more to load</Typography>
                    )}
                </Box>
            </Menu>
        </>
    )
}

export default AccountInfiniteSearch;