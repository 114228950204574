import moment from "moment";
import {tableSortingOrder} from "../constants/tableConstants";

export const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
};

export const getComparator = (order, orderBy) => {
    return order === tableSortingOrder.DESC
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
};

const descendingComparator = (a, b, orderBy) => {
    const isDateValue = orderBy.toLowerCase().includes("date");

    let compareValueA = a[orderBy];
    let compareValueB = b[orderBy];

    if (isDateValue) return compareDates(compareValueA, compareValueB);

    if (compareValueB < compareValueA) return -1;
    if (compareValueB > compareValueA) return 1;
    return 0;
};

const compareDates = (dateStringA, dateStringB) => {
    const dateA = new Date(dateStringA);
    const dateB = new Date(dateStringB);

    if (moment(dateA).isBefore(dateB)) return -1;
    if (moment(dateB).isBefore(dateA)) return 1;
    return 0;
};
