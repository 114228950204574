import React, { useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    Avatar,
    Card as MuiCard,
    CardContent,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Menu,
    MenuItem,
    Typography as MuiTypography,
    Button as MuiIconButton,
    Box,
} from "@material-ui/core";

import {
    setCasePermissionType,
    setCasePermissionUsers,
    setCaseVisibleToContactsOnly,
} from "../redux/actions/caseActions";

import styled from "styled-components";
import { spacing } from "@material-ui/system";
import axios from "../plugins/axios";
import {
    Edit as EditIcon,
    Add as AddIcon,
    Security as SecurityIcon,
    PersonAdd as GrantAccessIcon,
    PersonAddDisabled as RestrictAccessIcon,
    Visibility as VisibilityIcon,
    VisibilityOff as VisibilityOffIcon,
} from "@material-ui/icons";

import { grey } from "@material-ui/core/colors";
import caseService from "../services/caseService";
import { Alert } from "@material-ui/lab";
import { sortAlphabetically } from "../helpers/nameHelpers";

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

const IconButton = styled(MuiIconButton)`
    padding: 2px;
    margin-top: -2px;
    margin-left: 2px;
    min-width: 0;
    color: ${grey[700]};
`;

const Card = styled(MuiCard)(spacing);
const Typography = styled(MuiTypography)(spacing);

const CustomMenu = styled(Menu)`
    border-radius: 0px;
    ul {
        padding: 0px;
    }
`;

const sortKey = "name";

const CasePermissionCard = () => {
    const dispatch = useDispatch();

    const { currentCaseId: caseId, cases } = useSelector(
        (state) => state.caseReducer
    );
    const userState = useSelector((state) => state.userReducer);

    const c = cases[caseId];

    const [permissionsDialogOpen, setPermissionsDialogOpen] = useState(false);
    const [addContactEl, setAddContactEl] = useState(null);
    const [caseAdvisors, setCaseAdvisors] = useState(null);
    const [initialPermittedUsers, setInitialPermittedUsers] = useState(null);
    const [initialPermissionTypeId, setInitialPermissionTypeId] =
        useState(null);
    const [preConfirmationUsers, setPreConfirmationUsers] = useState(null);

    const handlePermissionsClickOpen = () => {
        setPermissionsDialogOpen(true);
        setInitialPermittedUsers(c.permission.users);
        setInitialPermissionTypeId(c.permission.permissionTypeId);
    };

    const handleCloseDialog = async (event, reason) => {
        if (reason === "backdropClick") {
            return;
        }
        setPermissionsDialogOpen(false);
        //reset to initial value if cancelled before save
        dispatch(setCasePermissionType(caseId, initialPermissionTypeId));
        dispatch(setCasePermissionUsers(caseId, initialPermittedUsers));
    };

    const handleSavePermission = async (target) => {
        //get the userIds of the selected users
        let selectedUsers = c.permission.users.map((a) => a.userId);

        //pass the selected users to the axios save, and close the dialog once save complete
        var response = await axios.post(`${apiUrl}/setcasepermissions`, {
            caseId: caseId,
            permissionTypeId: c.permission.permissionTypeId,
            userIds: selectedUsers,
        });
        if (response.status === 200) {
            if (selectedUsers.length === 0) {
                //if no users selected then we reset the type to 0
                dispatch(setCasePermissionType(caseId, 0));
            }
            setConfirmDialogOpen(false);
            setPermissionsDialogOpen(false);
        }
    };

    const getCaseAdvisors = useCallback(async () => {
        setCaseAdvisors(
            sortAlphabetically(
                Object.values(userState.users).filter(
                    (u) => u.isActive && !u.isDisabled
                ),
                sortKey
            )
        );
    }, [c, userState.users]);

    const unusedAdvisors = useCallback(() => {
        if (!caseAdvisors) {
            getCaseAdvisors();
            return [];
        }
        //if we are dealing with restricted premission then do not allow the currently allocated lawywer to be restricted, remove from list returned
        if (c.permission.users && c.permission.permissionTypeId === 2) {
            return caseAdvisors.filter(
                (ac) =>
                    !c.permission.users
                        .map((c) => c.userId)
                        .includes(ac.userId) &&
                    ac.userId !== c.caseSummary.currentAdviserId
            );
        } else if (c.permission.users) {
            return caseAdvisors.filter(
                (ac) =>
                    !c.permission.users.map((c) => c.userId).includes(ac.userId)
            );
        } else return caseAdvisors;
    }, [caseAdvisors, getCaseAdvisors]);

    const handleGrantAccessSelected = () => {
        dispatch(setCasePermissionType(caseId, 1));
    };

    const handleRestrictAccessSelected = () => {
        dispatch(setCasePermissionType(caseId, 2));
    };

    const handleAddCaseAdvisor = (user) => {
        dispatch(setCasePermissionUsers(caseId, [...c.permission.users, user]));
    };

    const handleRemoveCaseAdvisor = (id) => {
        let newList = c.permission.users.filter((c) => c.userId !== id);
        dispatch(
            setCasePermissionUsers(
                caseId,
                c.permission.users.filter((c) => c.userId !== id)
            )
        );
        if (newList.length === 0) {
            dispatch(setCasePermissionType(caseId, 0));
        }
    };

    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

    const handleCloseConfirmationDialog = () => {
        dispatch(setCasePermissionUsers(caseId, preConfirmationUsers));
        setPreConfirmationUsers([]);
        setConfirmDialogOpen(false);
    };

    const handleConfirmClickOpen = () => {
        //before displaying the confirm window we need to ensure that if 'grant' access is being configured then the current lawyer allocated to the case is also included in the
        //list of permitted users

        if (c.permission.users && c.permission.users.length > 0) {
            //get the userIds of the selected users
            let selectedUsers = c.permission.users.map((a) => a.userId);
            //if we are adding the lawyer to grant permissions as it wasn't included by the user then take a copy of the pre-confirmation users.  This can then be used
            //to reset the grant list in the case that the user clicks the 'cancel' and wants to return to the previous screen
            setPreConfirmationUsers(c.permission.users);
            if (
                c.permission.permissionTypeId === 1 &&
                !selectedUsers.includes(c.caseSummary.currentAdviserId)
            ) {
                var lawyerUser =
                    userState.users[c.caseSummary.currentAdviserId];
                dispatch(
                    setCasePermissionUsers(caseId, [
                        ...c.permission.users,
                        lawyerUser,
                    ])
                );
            }
        }

        setConfirmDialogOpen(true);
    };

    const handleCaseVisibilityToggle = async () => {
        try {
            const { data } = await caseService.setCaseVisibleToContactsOnly({
                caseId,
                isVisibleToContactsOnly: !c.caseSummary.isVisibleToContactsOnly,
            });

            dispatch(
                setCaseVisibleToContactsOnly({
                    caseId,
                    isVisibleToContactsOnly: data,
                })
            );
        } catch (e) {
            console.error(e);
        } finally {
            setPermissionsDialogOpen(false);
        }
    };

    return (
        <React.Fragment>
            <Card mb={3}>
                <CardContent>
                    <Box display="flex">
                        <div
                            style={{ display: "flex", justifyContent: "flex" }}
                        >
                            <SecurityIcon />
                        </div>
                        <Box flexGrow={1} alignItems="center">
                            <Typography variant="h6" mb={6}>
                                Case Permissions
                            </Typography>
                        </Box>

                        <Box>
                            <IconButton onClick={handlePermissionsClickOpen}>
                                <EditIcon />
                            </IconButton>
                        </Box>
                    </Box>

                    {c.permission.permissionTypeId === 1 && (
                        <Box display="flex" height={50} bgcolor="#ffcdd2">
                            <Box m="auto">
                                <Typography variant="body2">
                                    Grant Access has been configured
                                </Typography>
                            </Box>
                        </Box>
                    )}
                    {c.permission.permissionTypeId === 2 && (
                        <Box display="flex" height={50} bgcolor="#ffcdd2">
                            <Box m="auto">
                                <Typography variant="subtitle2" mb={6}>
                                    Restrict Access has been configured
                                </Typography>
                            </Box>
                        </Box>
                    )}
                    {c.caseSummary.isVisibleToContactsOnly ? (
                        <Alert icon={false} severity="error">
                            Only visible to contacts on this case.
                        </Alert>
                    ) : (
                        <Alert icon={false} severity="success">
                            Visible to all contacts on this account.
                        </Alert>
                    )}
                </CardContent>
            </Card>

            <Dialog
                open={confirmDialogOpen}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {c.permission.users && c.permission.users.length === 0
                        ? "Reset Permissions"
                        : "Confirm Permissions"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {c.permission.users &&
                        c.permission.users.length === 0 ? (
                            "Please confirm that you wish to remove all of the existing permissions so the case is visible to all?"
                        ) : (
                            <Box display="flex">
                                <Box m="auto">
                                    <Typography variant="subtitle2">
                                        {c.permission.permissionTypeId === 1
                                            ? "Grant Access has been configured, ONLY the advisers listed below (plus all LegalAdmin users) will be able to view this case"
                                            : "Restrict Access has been configured, the advisers listed below will NOT be able to view the case"}
                                    </Typography>
                                </Box>
                            </Box>
                        )}
                    </DialogContentText>

                    {c.permission.users && (
                        <DialogContentText id="alert-dialog-description-users">
                            <Box display="flex" justifyContent="center">
                                <Box display="block" justifyContent="center">
                                    {c.permission.users &&
                                        c.permission.users.map((co) => (
                                            <Box mb={1} key={co.userId}>
                                                <Chip
                                                    avatar={
                                                        <Avatar
                                                            alt={co.name}
                                                            src={co.photo}
                                                        />
                                                    }
                                                    label={
                                                        co.userId ===
                                                        c.caseSummary
                                                            .currentAdviserId
                                                            ? co.name +
                                                              " (Allocated Lawyer)"
                                                            : co.name
                                                    }
                                                />
                                            </Box>
                                        ))}
                                </Box>
                            </Box>
                        </DialogContentText>
                    )}

                    <DialogContentText id="alert-dialog-description">
                        Please confirm that you wish to save any permission
                        changes to the system?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <MuiIconButton
                        onClick={handleCloseConfirmationDialog}
                        color="primary"
                    >
                        Cancel
                    </MuiIconButton>
                    <MuiIconButton
                        onClick={handleSavePermission}
                        color="primary"
                    >
                        Confirm
                    </MuiIconButton>
                </DialogActions>
            </Dialog>

            <Dialog
                open={permissionsDialogOpen}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Case Permissions
                </DialogTitle>

                <DialogContent>
                    <DialogContentText>
                        Please select the below option to make this case visible
                        to only the contacts added to this case in the Client
                        portal.
                    </DialogContentText>
                    <MuiIconButton
                        variant="outlined"
                        color="primary"
                        startIcon={
                            c.caseSummary.isVisibleToContactsOnly ? (
                                <VisibilityIcon />
                            ) : (
                                <VisibilityOffIcon />
                            )
                        }
                        onClick={handleCaseVisibilityToggle}
                    >
                        {c.caseSummary.isVisibleToContactsOnly
                            ? "Make this case visible to all account contacts"
                            : "Make this case visible to case contacts only."}
                    </MuiIconButton>
                </DialogContent>

                {c.permission.permissionTypeId === 1 && (
                    <DialogContent>
                        <MuiIconButton color="primary">
                            <GrantAccessIcon />
                            Grant Access has been selected and will be applied
                            to the following users
                        </MuiIconButton>
                    </DialogContent>
                )}

                {c.permission.permissionTypeId === 2 && (
                    <DialogContent>
                        <MuiIconButton color="primary">
                            <GrantAccessIcon />
                            Restrict Access has been selected and will be
                            applied to the following users
                        </MuiIconButton>
                    </DialogContent>
                )}
                {c.permission.permissionTypeId === 0 && (
                    <DialogContent spacing={1}>
                        <DialogContentText id="alert-dialog-description">
                            Please select the type of Permission you would like
                            to add against this case? Mutilple users can then be
                            added
                        </DialogContentText>
                        <MuiIconButton
                            variant="outlined"
                            onClick={handleGrantAccessSelected}
                            color="primary"
                        >
                            <GrantAccessIcon />
                            Grant Access to selected users
                        </MuiIconButton>{" "}
                        or&nbsp;
                        <MuiIconButton
                            variant="outlined"
                            onClick={handleRestrictAccessSelected}
                            color="primary"
                        >
                            <RestrictAccessIcon />
                            Restrict Access from selected users
                        </MuiIconButton>
                    </DialogContent>
                )}

                <Box display="flex" justifyContent="center">
                    <Box display="block" justifyContent="center">
                        {c.permission.users &&
                            c.permission.users.map((c) => (
                                <Box mb={1} key={c.userId}>
                                    <Chip
                                        avatar={
                                            <Avatar
                                                alt={c.name}
                                                src={c.photo}
                                            />
                                        }
                                        label={c.name}
                                        onDelete={() =>
                                            handleRemoveCaseAdvisor(c.userId)
                                        }
                                    />
                                </Box>
                            ))}
                    </Box>
                </Box>
                <CustomMenu
                    anchorEl={addContactEl}
                    keepMounted
                    open={Boolean(addContactEl)}
                    onClose={() => setAddContactEl(null)}
                >
                    {unusedAdvisors().map((u) => (
                        <MenuItem
                            onClick={() => handleAddCaseAdvisor(u)}
                            key={u.userId}
                        >
                            <Avatar
                                alt={u.name}
                                src={u.photo}
                                style={{ marginRight: "8px" }}
                            />
                            <Typography variant="body2">{u.name}</Typography>
                        </MenuItem>
                    ))}
                </CustomMenu>

                <DialogActions>
                    <MuiIconButton onClick={handleCloseDialog} color="primary">
                        Cancel
                    </MuiIconButton>
                    <MuiIconButton
                        onClick={handleConfirmClickOpen}
                        color="primary"
                    >
                        Save Permissions
                    </MuiIconButton>
                    <div style={{ flex: "1 0 0" }} />
                    {c.permission.permissionTypeId === 1 && (
                        <MuiIconButton
                            onClick={(e) => setAddContactEl(e.currentTarget)}
                            color="secondary"
                            variant="contained"
                        >
                            Add User Access
                            <AddIcon />
                        </MuiIconButton>
                    )}
                    {c.permission.permissionTypeId === 2 && (
                        <MuiIconButton
                            onClick={(e) => setAddContactEl(e.currentTarget)}
                            color="secondary"
                            variant="contained"
                        >
                            Restrict User
                            <AddIcon />
                        </MuiIconButton>
                    )}
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

export default CasePermissionCard;
