import { useMutation } from "react-query";
import queryKeys from "../../constants/queryKeys";
import caseExternalContactService from "../../services/caseExternalContactService";
import reactQueryClient from "../../reactQueryClient";

const useAddCaseExternalContact = () =>
    useMutation(({ caseExternalContact }) =>
        caseExternalContactService.addCaseExternalContact(caseExternalContact),
        {
            onMutate: async ({ caseExternalContact }) => {
                const queryKey = [queryKeys.caseExternalContacts, caseExternalContact.caseId];

                await reactQueryClient.cancelQueries(queryKey);

                const previousCaseExternalContacts = reactQueryClient.getQueryData(queryKey);

                reactQueryClient.setQueryData(queryKey, oldExtContacts => oldExtContacts && oldExtContacts.map(a => a.externalContactId == caseExternalContact.externalContactId ? caseExternalContact : a));

                return { queryKey, previousCaseExternalContacts }
            },
            onError: (_err, _newAction, context) => {
                reactQueryClient.setQueryData(context.queryKey, context.previousCaseExternalContacts);
            },
            onSettled: (_newAction, _err, _variables, context) => {
                reactQueryClient.invalidateQueries(context.queryKey);
            }
        }
    );

export default useAddCaseExternalContact;

