import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import {
    CardContent,
    Card as MuiCard,
    FormControl,
    Grid as MuiGrid,
    IconButton as MuiIconButton,
    Input,
    InputAdornment,
    InputLabel,
    Tooltip,
    Typography,
    Divider as MuiDivider,
    Select,
    MenuItem,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";

import {
    FilterList as FilterIcon,
    Clear as ClearIcon,
} from "@material-ui/icons";

import { Search as SearchIcon } from "react-feather";
import { useHistory } from "react-router-dom";
import AccountTable from "../components/AccountTable";
import { bindActionCreators } from "redux";
import { actionCreators } from "../redux/reducers/accountReducer";
import { Controls } from "../helpers/Controls";
import CreatePartnership from "./CreatePartnership";
import axios from "../plugins/axios";
import { makeStyles } from "@material-ui/core/styles";
import { defaultRowsPerPage } from "../constants/tableConstants";

import {
    setDisplayAccountSearchInTab,
    setAccountCriteriaAccountName,
    setAccountCriteriaPostCode,
    setAccountCriteriaPartnerNumber,
    setAccountCriteriaPartnershipId,
    setAccountCriteriaStatus,
    setAccountCriteriaAdviserId,
} from "../redux/actions/accountActions";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);
const Grid = styled(MuiGrid)(spacing);

const IconButton = styled(MuiIconButton)`
    svg {
        width: 22px;
        height: 22px;
    }
`;

const formStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(3),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

const Accounts = ({
    getAccount,
    configState,
    setDisplayAccountSearchInTab,
    setAccountCriteriaStatus,
    setAccountCriteriaPartnershipId,
    setAccountCriteriaPartnerNumber,
    setAccountCriteriaPostCode,
    setAccountCriteriaAccountName,
    accountState,
    userState,
    setAccountCriteriaAdviserId,
}) => {
    const [accountName, setAccountName] = useState(
        accountState.searchCriteria.accountName
    );
    const [postCode, setPostCode] = useState(
        accountState.searchCriteria.postCode
    );
    const [partnershipNumber, setPartnershipNumber] = useState(
        accountState.searchCriteria.partnerNumber
    );
    const [accountNameQuery, setAccountNameQuery] = useState(
        accountState.searchCriteria.accountName
    );
    const [postCodeQuery, setPostCodeQuery] = useState(
        accountState.searchCriteria.postCode
    );
    const [accountPageLoading, setAccountPageLoading] = useState(false);
    const [partnershipNumberQuery, setPartnershipNumberQuery] = useState(
        accountState.searchCriteria.partnerNumber
    );
    const [partnershipId, setPartnershipId] = useState(
        accountState.searchCriteria.partnershipId || ""
    );
    const [accountStatus, setAccountStatus] = useState(
        accountState.searchCriteria.accountStatus
    );
    const [adviserId, setAdviserId] = useState(
        accountState.searchCriteria.adviserId
    );

    const [newAccountRef, setNewAccountRef] = useState("");
    const [openPopup, setOpenPopup] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);
    const formClasses = formStyles();

    const history = useHistory();

    const handleAccountSearch = () => {
        setPostCodeQuery(postCode);
        setAccountNameQuery(accountName);
        setPartnershipNumberQuery(partnershipNumber);
    };

    const handleClick = async (account) => {
        setAccountPageLoading(true);

        if (
            accountName ||
            postCode ||
            partnershipNumber ||
            partnershipId ||
            accountStatus ||
            adviserId
        ) {
            //save the fact that an account has been clicked and that the account search tab should be displayed
            //setCriteriaAccountName(account);
            setDisplayAccountSearchInTab(true);
        }

        await getAccount(account.accountId, history);
    };

    const handleEnterKeyPress = (e) => {
        if (e.key === "Enter") {
            handleAccountSearch();
        }
    };

    const handlePartnershipChange = (e) => {
        setPartnershipId(e.target.value);
    };

    const handleAccountStatusChange = (e) => {
        setAccountStatus(e.target.value);
    };

    const handleAdviserChange = (e) => {
        setAdviserId(e.target.value);
    };

    const addOrEdit = async (partnership, resetForm) => {
        setAccountPageLoading(true);

        //set the fields at the top of the page
        setPostCode(partnership.addressPostCode);
        setAccountName(partnership.name);
        setPartnershipNumber(partnership.partnershipNumber);

        //sent the data to salesforce
        await axios.post(`${apiUrl}/createpartnershipmember`, partnership);
        window.frames[0].postMessage({ event: "refresh" }, "*");

        //clear the form and close the modal popup
        resetForm();
        setOpenPopup(false);

        setNewAccountRef(partnership.name);
        setAccountPageLoading(false);
    };

    //this ensures that when the newaccountRef is altered then we trigger a handleAccountSearch
    useEffect(() => {
        handleAccountSearch();
    }, [newAccountRef]);

    useEffect(() => {
        setAccountCriteriaAccountName(accountName);
    }, [accountName]);

    useEffect(() => {
        setAccountCriteriaPostCode(postCode);
    }, [postCode]);

    useEffect(() => {
        setAccountCriteriaPartnerNumber(partnershipNumber);
    }, [partnershipNumber]);

    useEffect(() => {
        setAccountCriteriaPartnershipId(partnershipId);
    }, [partnershipId]);

    useEffect(() => {
        setAccountCriteriaStatus(accountStatus);
    }, [accountStatus]);

    useEffect(() => {
        setAccountCriteriaAdviserId(adviserId);
    }, [partnershipId]);

    const clearFilters = () => {
        setAccountName("");
        setAccountNameQuery("");
        setPostCode("");
        setPostCodeQuery("");
        setPartnershipNumber("");
        setPartnershipNumberQuery("");
        setPartnershipId("");
        setAccountStatus("");
        setAdviserId("");
    };

    return (
        <React.Fragment>
            <Helmet title="Accounts" />
            <Grid container justifyContent="space-between">
                {!accountState.searchCriteria.displayAccountSearchInTab && (
                    <Grid item mb={3}>
                        <Typography variant="h3" gutterBottom display="inline">
                            Accounts
                        </Typography>
                    </Grid>
                )}
                <Grid
                    item
                    style={{
                        display: "flex",
                        alignItems: "flex-end",
                        flexWrap: "wrap",
                    }}
                >
                    <FormControl
                        style={{ paddingBottom: "8px" }}
                        className={formClasses.formControl}
                    >
                        <InputLabel>Adviser</InputLabel>
                        <Select
                            value={adviserId}
                            onChange={handleAdviserChange}
                        >
                            <MenuItem key="0" value="">
                                Any
                            </MenuItem>
                            {Object.values(userState.users)
                                .filter((x) => x.isActive && !x.isDisabled)
                                .sort((a, b) =>
                                    a.name > b.name
                                        ? 1
                                        : b.name > a.name
                                        ? -1
                                        : 0
                                )
                                .map((u) => (
                                    <MenuItem key={u.userId} value={u.userId}>
                                        {u.name}
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                    <FormControl
                        style={{ paddingBottom: "8px" }}
                        className={formClasses.formControl}
                    >
                        <InputLabel>Account Name</InputLabel>
                        <Input
                            type="text"
                            value={accountName}
                            onChange={(e) => setAccountName(e.target.value)}
                            onKeyPress={handleEnterKeyPress}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() =>
                                            setAccountNameQuery(accountName)
                                        }
                                    >
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                    <FormControl
                        style={{ paddingBottom: "8px", maxWidth: "10px" }}
                        className={formClasses.formControl}
                    >
                        <InputLabel>Postcode</InputLabel>
                        <Input
                            type="text"
                            value={postCode}
                            onChange={(e) => setPostCode(e.target.value)}
                            onKeyPress={handleEnterKeyPress}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() =>
                                            setPostCodeQuery(postCode)
                                        }
                                    >
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                    <FormControl
                        style={{ paddingBottom: "8px" }}
                        className={formClasses.formControl}
                    >
                        <InputLabel>Partner</InputLabel>
                        <Select
                            value={partnershipId}
                            onChange={handlePartnershipChange}
                        >
                            <MenuItem key="0" value="">
                                Any
                            </MenuItem>
                            {Object.values(configState.partnerships)
                                .sort((a, b) =>
                                    a.title > b.title
                                        ? 1
                                        : b.title > a.title
                                        ? -1
                                        : 0
                                )
                                .map((p) => (
                                    <MenuItem key={p.id} value={p.id}>
                                        {p.title}
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                    <FormControl
                        style={{ paddingBottom: "8px", maxWidth: "16px" }}
                        className={formClasses.formControl}
                    >
                        <InputLabel>Partner No.</InputLabel>
                        <Input
                            type="text"
                            value={partnershipNumber}
                            onChange={(e) =>
                                setPartnershipNumber(e.target.value)
                            }
                            onKeyPress={handleEnterKeyPress}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() =>
                                            setPartnershipNumberQuery(
                                                partnershipNumber
                                            )
                                        }
                                    >
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                    <FormControl
                        style={{ paddingBottom: "8px" }}
                        className={formClasses.formControl}
                    >
                        <InputLabel>Status</InputLabel>
                        <Select
                            value={accountStatus}
                            onChange={handleAccountStatusChange}
                        >
                            <MenuItem key="0" value="">Any</MenuItem>
                            <MenuItem key="3" value="Former Appointment">Former Appointment</MenuItem>
                            <MenuItem key="4" value="Former">Former</MenuItem>
                            <MenuItem key="6" value="Pending">Pending</MenuItem>
                            <MenuItem key="7" value="Suspended">Suspended</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl
                        style={{ paddingBottom: "8px" }}
                        className={formClasses.formControl}
                    >
                        <Controls.ButtonControl
                            variant="outlined"
                            text="Add Partner Member"
                            color="primary"
                            size="small"
                            onClick={() => setOpenPopup(true)}
                        />
                    </FormControl>
                    <IconButton
                        style={{ paddingBottom: "25px" }}
                        title="ssss"
                        onClick={clearFilters}
                    >
                        <ClearIcon />
                        <FilterIcon />
                    </IconButton>
                </Grid>
            </Grid>
            <Divider my={6} />
            <Card mb={6}>
                <CardContent>
                    <AccountTable
                        postCode={postCodeQuery}
                        accountName={accountNameQuery}
                        clickable
                        handleClick={handleClick}
                        accountPageLoading={accountPageLoading}
                        partnershipNumber={partnershipNumberQuery}
                        partnershipId={partnershipId}
                        accountStatus={accountStatus}
                        adviserId={adviserId}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                        hideRowsPerPageOptions={true}
                    />
                </CardContent>
            </Card>

            <Controls.PopupControl
                title="Add new partnership account"
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
            >
                <CreatePartnership addOrEdit={addOrEdit} />
            </Controls.PopupControl>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => ({
    configState: state.configReducer,
    accountState: state.accountReducer,
    userState: state.userReducer,
});

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(actionCreators, dispatch),
        setAccountCriteriaPartnershipId: (partnershipId) =>
            dispatch(setAccountCriteriaPartnershipId(partnershipId)),
        setAccountCriteriaStatus: (status) =>
            dispatch(setAccountCriteriaStatus(status)),
        setAccountCriteriaAccountName: (accountName) =>
            dispatch(setAccountCriteriaAccountName(accountName)),
        setAccountCriteriaPostCode: (postCode) =>
            dispatch(setAccountCriteriaPostCode(postCode)),
        setAccountCriteriaPartnerNumber: (partnerNumber) =>
            dispatch(setAccountCriteriaPartnerNumber(partnerNumber)),
        setDisplayAccountSearchInTab: (display) =>
            dispatch(setDisplayAccountSearchInTab(display)),
        setAccountCriteriaAdviserId: (adviserId) =>
            dispatch(setAccountCriteriaAdviserId(adviserId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Accounts);
