import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    setPrecedentDocumentHtml,
    updateCasePrecedentName,
    setReplyEditorHtmlAction,
} from "../redux/actions/caseActions";
import MyCKEditor from "../ckeditor/MyCKEditor";
import axios from "../plugins/axios";
import { setPrecedent } from "../redux/actions/configActions";

import {
    Lock as LockIcon,
    LockOpen as LockOpenIcon,
    Edit as EditIcon,
} from "@material-ui/icons";
import { Button, Box } from "@material-ui/core";
import RenamePrecedentDialog from "../components/RenamePrecedentDialog";

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

const PrecedentDocument = ({ precedent, handleOpenCaseResearch }) => {
    const dispatch = useDispatch();

    const { currentCaseId: caseId, cases } = useSelector(
        (state) => state.caseReducer
    );

    const [requiresApproval, setRequiresApproval] = useState(false);
    const [open, setOpen] = useState(false);
    const [editor, setEditor] = useState();
    const [initialHtml, setInitialHtml] = useState();

    const { editorHtml } = cases[caseId].reply;

    const setEditorHtml = (html) => {
        dispatch(setPrecedentDocumentHtml(caseId, precedent.precedentId, html));
    };

    const handleSubmitDialog = async (title) => {
        const updateReplyPrecedentTitle = editorHtml.replace(
            precedent.name,
            title
        );

        dispatch(updateCasePrecedentName(caseId, precedent.precedentId, title));
        dispatch(setReplyEditorHtmlAction(caseId, updateReplyPrecedentTitle));
        setOpen(false);
    };

    const handleCloseDialog = () => {
        setOpen(false);
    };

    useEffect(() => {
        const getPrecedent = async () => {
            if (precedent.htmlContent == null) {
                var response = await axios.get(
                    `${apiUrl}/getprecedent/${precedent.precedentId}`
                );
                let { htmlContent } = response.data;

                dispatch(setPrecedent(caseId, response.data));

                setInitialHtml(htmlContent);
                dispatch(
                    setPrecedentDocumentHtml(
                        caseId,
                        precedent.precedentId,
                        htmlContent
                    )
                );
            }
            // returning to tab
            else {
                setInitialHtml(precedent.htmlContent);
            }
        };
        getPrecedent();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [precedent.precedentId, caseId]);

    useEffect(() => {
        if (editor && initialHtml) {
            editor.setData(initialHtml);
        }
    }, [editor, initialHtml]);

    return (
        <>
            <Box display="flex" flexDirection="row" my={3}>
                <Button
                    onClick={() => setRequiresApproval(!requiresApproval)}
                    variant="contained"
                    color="default"
                    endIcon={requiresApproval ? <LockIcon /> : <LockOpenIcon />}
                    style={{ marginRight: "8px" }}
                >
                    Require Approval
                </Button>
                <Button
                    onClick={() => setOpen(true)}
                    variant="contained"
                    color="default"
                    endIcon={<EditIcon />}
                    style={{ marginRight: "8px" }}
                >
                    Edit Precedent Title
                </Button>
                <Button onClick={handleOpenCaseResearch} variant="contained">
                    Research
                </Button>
            </Box>
            <MyCKEditor
                initialData=""
                setEditor={setEditor}
                onChange={setEditorHtml}
                onFocus={() => {}}
                type="document"
            />
            <RenamePrecedentDialog
                open={open}
                value={precedent.name}
                onClose={handleCloseDialog}
                onSubmit={handleSubmitDialog}
            />
        </>
    );
};

export default PrecedentDocument;
