import React, { useState, useEffect, useMemo, useCallback } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";
import { makeStyles } from "@material-ui/core/styles";
import "../vendor/perfect-scrollbar.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import { actionCreators } from "../redux/reducers/caseReducer";
import { setFullScreenEmail } from "../redux/actions/userActions";
import _ from "lodash";
import {
    reassignCaseActivity,
    setAdjustCaseMinutes,
    setCaseHistoryMode,
    setCaseHistoryCheckAll,
    setDownloadCaseActivityIds,
} from "../redux/actions/caseActions";
import { setSnackAction } from "../redux/actions/snackActions";
import { bindActionCreators } from "redux";

import {
    Box,
    Button,
    IconButton,
    CardContent,
    Card,
    Input,
    InputAdornment,
    ListSubheader,
    Menu,
    MenuItem,
    Typography,
    FormControlLabel,
    Checkbox,
} from "@material-ui/core";

import { Input as InputIcon, Search as SearchIcon, GetApp, PictureAsPdf, ChevronLeft as LeftIcon, ChevronRight as RightIcon } from "@material-ui/icons";

import CaseHistorySummary from "../components/CaseHistorySummary";
import { grey } from "@material-ui/core/colors";
import caseActivityTypes from "../constants/caseActivityTypes";
import NotePreviewDialog from "../components/dialogs/NotePreviewDialog";
import useEmailByCaseActivityId from "../hooks/queries/useEmailByCaseActivityId";
import CaseActivityMenu from "../components/CaseActivityMenu";
import CaseHistoryExportDialog from "../components/dialogs/CaseHistoryExportDialog";
import EditActivityDescriptionDialog from "../components/dialogs/EditActivityDescriptionDialog";

const useStyles = makeStyles((theme) => ({
    small: {
        width: theme.spacing(5),
        height: theme.spacing(5),
    },
    cc: {
        backgroundColor: "lightcyan",
    },
    downloadHist: {
        marginRight: "8px",
    },
    searchResultWrapper: {
        marginLeft: "6px",
        whiteSpace: "pre-line",
    },
    searchResultDesc: {
        whiteSpace: "pre-line",
    },
    perfScroll: {
        marginRight: theme.spacing(-3),
        maxHeight: "calc(100vh - 200px)",
    },
    searchResultWrap: {
        cursor: "pointer",
        width: "100%",
        boxShadow: "inset 0 -2px 0 #C0C0C0",
        "&:hover": {
            boxShadow: "inset 0 -2px 0 #C0C0C0",
        },
    },
    iconButton: {
        padding: "2px",
        marginTop: "-2px",
        marginLeft: "2px",
        minWidth: "0",
        color: grey[700],
    },
    customMenu: {
        borderRadius: "0px",
        ul: {
            padding: "0px",
        },
    },
    downloadBtnsContainer: {
        display: "flex",
        marginBottom: theme.spacing(1),
    },
    selectAllCheckbox: {
        margin: theme.spacing(1, 0),
    },
}));

const { NOTE, MISC, INTERNAL_NOTE, EMAIL, CALL_WITH_NOTE } = caseActivityTypes;

const CaseHistoryCard = ({ openDocument, onReply, onForward, setFullScreenEmail, getAllCaseActivities, setSnack }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const { currentCaseId: caseId, cases } = useSelector((state) => state.caseReducer);
    const c = cases[caseId];

    // paging
    const [pageNumber, setPageNumber] = useState(1);
    const pagedEvents = useMemo(() => {
        return c.events?.slice((pageNumber - 1) * 50, pageNumber * 50);
    }, [c.events, pageNumber]);

    const pageUp = () => {
        if (pageNumber < Math.ceil(c.events.length / 50))
            setPageNumber(pageNumber + 1);
    };

    const pageDown = () => {
        if (pageNumber > 1)
            setPageNumber(pageNumber - 1);
    };

    // full screen modal
    const [open, setOpen] = useState(false);
    const [caseActivity, setCaseActivity] = useState(null);
    const [emailActivity, setEmailActivity] = useState(null);

    //Added for the the Search History piece
    const [startSearchEl, setStartSearchEl] = useState(null);
    const [searchString, setSearchString] = useState("");

    const [loadingEvents, setLoadingEvents] = useState(false);

    // context menu
    const [contextAnchorEl, setContextAnchorEl] = useState(null);
    const [contextEvent, setContextEvent] = useState(null);
    const [caseListOpen, setCaseListOpen] = useState(false);

    const [jumptoActivityId, setJumptoActivityId] = useState(null);

    // activity download
    const { mode, selectAllChecked, downloadCaseActivityIds } = cases[caseId].caseHistory;    
    
    const [exportType, setExportType] = useState();

    useEmailByCaseActivityId({
        caseActivityId: emailActivity?.caseActivityId,
        onSuccess: (email) => {
            dispatch(
                setFullScreenEmail(
                    {
                        ...email.data,
                        ...emailActivity,
                        content: email.data.content,
                        person: email.data.person,
                    },
                    true
                )
            );
            setEmailActivity(null);
        },
    });

    useEffect(() => {
        async function getAllCaseActivityDataAsync() {
            if (c.events == null) {
                setLoadingEvents(true);
                await getAllCaseActivities(caseId, true);
                setLoadingEvents(false);
            }
        }

        getAllCaseActivityDataAsync();
        setPageNumber(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [caseId]);

    const handleFullScreenOpen = (e) => {
        if (e.itemType === NOTE || e.itemType === INTERNAL_NOTE || e.itemType === MISC || e.itemType === CALL_WITH_NOTE) {
            setCaseActivity(e);
            setOpen(true);
        }

        if (e.itemType === EMAIL) {
            setEmailActivity(e);
        }
    };

    const handleFullScreenNoteClose = () => {
        setOpen(false);
        setCaseActivity(null);
    };

    const getMatchingHistoryRecords = useCallback((searchStr) => {
        if (c.events && searchStr && searchStr.length > 2) {
            var contentToReturnCalls = c.events.filter((e) =>
                e.activityDescription
                    ?.toLowerCase()
                    .replace(/&nbsp;/g, " ")
                    .replace(/<\/?[^>]+(>|$)/g, "")
                    .includes(searchStr.toLowerCase())
            );
            return contentToReturnCalls;
        }
    }, [c.events]);

    const handleSearchItemSelected = (ev) => {
        const index = c.events.findIndex((e) => {
            return e.caseActivityId === ev.caseActivityId;
        });

        setPageNumber(Math.ceil(index / 50));
        setJumptoActivityId(ev.caseActivityId);
    };

    const handleOpenContextMenu = (e, event) => {
        setContextEvent(e);
        setContextAnchorEl(event.currentTarget);
    };

    const handleCloseContextMenu = () => {
        setContextAnchorEl(null);
        setContextEvent(null);
        setCaseListOpen(false);
    };

    const handleChangeSelectAll = (e) => {
        dispatch(setCaseHistoryCheckAll(e.target.checked, caseId));
        if (e.target.checked) {
            dispatch(setDownloadCaseActivityIds(_.map(c.events, "caseActivityId"), caseId));
            return;
        }
        dispatch(setDownloadCaseActivityIds([], caseId));
    };

    const handleEventChecked = (caseActivityId, checked) => {
        if (!checked) {
            dispatch(setCaseHistoryCheckAll(false, caseId));
            dispatch(setDownloadCaseActivityIds(downloadCaseActivityIds.filter((id) => id !== caseActivityId), caseId));
            return;
        }
        dispatch(setDownloadCaseActivityIds([...downloadCaseActivityIds, caseActivityId], caseId));
    };

    const handleCancelDownload = useCallback(() => {
        dispatch(setCaseHistoryCheckAll(false, caseId));
        dispatch(setDownloadCaseActivityIds([], caseId));
        dispatch(setCaseHistoryMode("view", caseId));
    }, [caseId, dispatch]);

    useEffect(() => {
        if (!exportType || exportType === "pdf") {
            handleCancelDownload();
        }
        
    }, [caseId, dispatch, exportType, handleCancelDownload]);       

    const [isEditDescriptionDialogOpen, setIsEditDescriptionDialogOpen] = useState(false);
    const [editedActivity, setEditedActivity] = useState(null);

    const onEditDescription = (editedActivity) => {
        setIsEditDescriptionDialogOpen(true);
        setEditedActivity(editedActivity);
    };

    const handleEditDescriptionClose = () => {
        setIsEditDescriptionDialogOpen(false);
    };

    const countInfo = useMemo(() => {
        let info = c.events ? `${c.events.length} item${c.events.length === 1 ? "" : "s"}` : `${c.eventCount} activit${c.eventCount === 1 ? "y" : "ies"}`;

        return info;
    }, [c.events, c.eventCount]);

    return (
        <React.Fragment>
            <Card mb={6}>
                <CardContent>
                    <Box display="flex">
                        <Box flexGrow={1}>
                            <Typography variant="h6" gutterBottom>
                                Case History ({countInfo})
                            </Typography>
                        </Box>
                        <Box display="flex">
                            <IconButton className={classes.iconButton} onClick={() => setExportType("pdf")}>
                                <PictureAsPdf />
                            </IconButton>
                            {mode === "view" && (
                                <IconButton className={classes.iconButton} onClick={() => dispatch(setCaseHistoryMode("download", caseId))}>
                                    <GetApp />
                                </IconButton>
                            )}
                            <IconButton className={classes.iconButton} onClick={(e) => setStartSearchEl(e.currentTarget)}>
                                <SearchIcon />
                            </IconButton>
                        </Box>
                    </Box>
                    <Menu
                        className={classes.customMenu}
                        anchorEl={startSearchEl}
                        keepMounted
                        open={Boolean(startSearchEl)}
                        onClose={() => setStartSearchEl(null)}
                    >
                        <ListSubheader>
                            <Typography variant="body2">
                                <strong>Search Case History : </strong>
                            </Typography>
                        </ListSubheader>
                        <MenuItem>
                            <Input
                                value={searchString}
                                onChange={(e) => setSearchString(e.target.value)}
                                type="text"
                                onKeyDown={(e) => e.stopPropagation()}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                }
                            />
                        </MenuItem>
                        {c.events &&
                            searchString &&
                            searchString.length > 2 &&
                            getMatchingHistoryRecords(searchString).map((ev) => (
                                <MenuItem onClick={() => handleSearchItemSelected(ev)} key={ev.caseActivityId} width="50">
                                    <Box className={classes.searchResultWrap}>
                                        <Box display="flex">
                                            <InputIcon fontSize="small" />
                                            <Typography
                                                variant="caption"
                                                className={classes.searchResultWrapper}
                                                dangerouslySetInnerHTML={{
                                                    __html:
                                                        ev.toAddress && ev.toAddress > 0
                                                            ? ev.activityDescription && ev.activityDescription.length > 0
                                                                ? ev.toAddress + " (" + ev.activityDescription + ")"
                                                                : ev.toAddress
                                                            : ev.activityDescription && ev.activityDescription.length > 0
                                                            ? ev.activityDescription
                                                            : "",
                                                }}
                                            ></Typography>
                                        </Box>
                                        <Typography variant="caption"> {format(new Date(ev.eventTime), "dd/MM/yy HH:mm")}</Typography>
                                        <Typography
                                            className={classes.searchResultDesc}
                                            dangerouslySetInnerHTML={{
                                                //only set to bold the matching string records (replace used below as we do not include html when showing bold)
                                                //__html: ev.content.replace(/&nbsp;/g, ' ').replace(/<\/?[^>]+(>|$)/g, "").replace(new RegExp(searchString, 'g'), '<b>' + searchString + '</b>')
                                                __html:
                                                    ev.itemType === INTERNAL_NOTE
                                                        ? `<div>${ev.text && "Details : "}${ev.text
                                                              .replace(/&nbsp;/g, " ")
                                                              .replace(/<\/?[^>]+(>|$)/g, "")
                                                              .replace(new RegExp(searchString, "g"), "<b>" + searchString + "</b>")}</div>`
                                                        : ev.itemType === NOTE
                                                        ? `<div>${ev.issue && "Issue : "}${ev.issue
                                                              .replace(/&nbsp;/g, " ")
                                                              .replace(/<\/?[^>]+(>|$)/g, "")
                                                              .replace(new RegExp(searchString, "g"), "<b>" + searchString + "</b>")}</div>
                                                        <div>${ev.advice && "Advice : "}${ev.advice
                                                              .replace(/&nbsp;/g, " ")
                                                              .replace(/<\/?[^>]+(>|$)/g, "")
                                                              .replace(new RegExp(searchString, "g"), "<b>" + searchString + "</b>")}</div>
                                                        <div>${ev.action && "Action : "}${ev.action
                                                              .replace(/&nbsp;/g, " ")
                                                              .replace(/<\/?[^>]+(>|$)/g, "")
                                                              .replace(new RegExp(searchString, "g"), "<b>" + searchString + "</b>")}</div>`
                                                        : ev.content &&
                                                          ev.content
                                                              .replace(/&nbsp;/g, " ")
                                                              .replace(/<\/?[^>]+(>|$)/g, "")
                                                              .replace(new RegExp(searchString, "g"), "<b>" + searchString + "</b>"),
                                            }}
                                        ></Typography>
                                    </Box>
                                </MenuItem>
                            ))}
                    </Menu>
                    {c.events && c.events.length > 50 && (
                        <Box>
                            <Typography>
                                Showing items {(pageNumber - 1) * 50 + 1} to {Math.min(pageNumber * 50, c.events.length)}
                            </Typography>
                            <LeftIcon onClick={pageDown} />
                            <RightIcon onClick={pageUp} />
                        </Box>
                    )}
                    {mode === "download" && (
                        <Box display="flex" flexDirection="column">
                            <FormControlLabel
                                control={<Checkbox checked={selectAllChecked} onChange={handleChangeSelectAll} />}
                                label="Select all"
                                className={classes.selectAllCheckbox}
                            />
                            <div className={classes.downloadBtnsContainer}>
                                <Button className={classes.downloadHist} variant="contained" onClick={handleCancelDownload}>
                                    Cancel
                                </Button>
                                <Button
                                    className={classes.downloadHist}
                                    variant="contained"
                                    color="primary"
                                    onClick={() => setExportType("zip")}
                                    disabled={!downloadCaseActivityIds.length}
                                >
                                    Download
                                </Button>
                            </div>
                        </Box>
                    )}
                    <PerfectScrollbar className={classes.perfScroll}>
                        <CaseHistorySummary
                            handleFullScreenOpen={handleFullScreenOpen}
                            handleOpenContextMenu={handleOpenContextMenu}
                            events={pagedEvents}
                            jumptoActivityId={jumptoActivityId}
                            handleEventChecked={handleEventChecked}
                            downloadCaseActivityIds={downloadCaseActivityIds}
                            loadingEvents={loadingEvents}
                            onEditDescription={onEditDescription}
                        />
                    </PerfectScrollbar>
                </CardContent>
            </Card>
            <CaseActivityMenu
                caseId={caseId}
                contextEvent={contextEvent}
                contextAnchorEl={contextAnchorEl}
                caseListOpen={caseListOpen}
                handleCloseContextMenu={handleCloseContextMenu}
                handleCaseListOpen={setCaseListOpen}
                onReply={onReply}
                onForward={onForward}
                handleEditDescription={onEditDescription}
            />
            <NotePreviewDialog open={open} caseActivity={caseActivity} handleFullScreenNoteClose={handleFullScreenNoteClose} openDocument={openDocument} />
            <CaseHistoryExportDialog
                open={!!exportType}
                onClose={setExportType}
                caseId={caseId}
                caseActivityIds={downloadCaseActivityIds}
                exportType={exportType}
            />
            <EditActivityDescriptionDialog
                open={isEditDescriptionDialogOpen}
                caseId={caseId}
                activity={editedActivity}
                handleClose={handleEditDescriptionClose}
            />
        </React.Fragment>
    );
};

const mapDispatchToProps = (dispatch) => ({
    ...bindActionCreators(actionCreators, dispatch),
    setSnack: (message, severity) => dispatch(setSnackAction(message, severity)),
    setFullScreenEmail: (email, replyEnabled) => dispatch(setFullScreenEmail(email, replyEnabled)),
    reassignCaseActivity: (caseActivityId, oldCaseId, newCaseId) => dispatch(reassignCaseActivity(caseActivityId, oldCaseId, newCaseId)),
    setAdjustCaseMinutes: (caseId, duration) => dispatch(setAdjustCaseMinutes(caseId, duration)),
});

export default connect(null, mapDispatchToProps)(CaseHistoryCard);
