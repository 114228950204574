import * as types from '../constants';
import _ from 'lodash';

const initialState = {
    serverValues: {},
    caseTypes: {},
    sectors: {},
    precedents: {},
    precedentFolders: {},
    claimTypes: {},
    venues: {},
    mentions: [],
    partnerships: {},
    emailExceptionFilters: {},
    taskCompletionReasons: {},
    miscActivityTypes: {},
    userActivityTypes: {},
    minimumResearchSeconds: 1800,
    applicationRoles: {},
    resourceLicences: {},
};

export const actionCreators = {
    setServerValues: values => async (dispatch, getState) => {
        dispatch({ type: types.SET_SERVER_VALUES, values });
    },
    setConfigInfo: info => async (dispatch, getState) => {
        dispatch({ type: types.SET_CONFIG_INFO, info });
    }
};

const reducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {

        case (types.SET_CASE_TYPES): {
            return {
                ...state,
                caseTypes: _.keyBy(action.caseTypes, ct => ct.caseTypeId)
            };
        }

        case (types.UPSERT_CASE_TYPES): {
            let newobject = _.keyBy(action.caseTypes, ct => ct.caseTypeId);

            return {
                ...state,
                caseTypes: {
                    ...state.caseTypes,
                    ...newobject
                }
            };
        }

        case (types.SET_CONFIG_INFO): {
            return {
                ...state,
                caseTypes: _.keyBy(action.info.caseTypes, ct => ct.caseTypeId),
                sectors: _.keyBy(action.info.sectors, a => a.sectorId),
                precedents: _.keyBy(action.info.precedents, p => p.precedentId),
                precedentFolders: action.info.precedentFolders.sort((a, b) => a.displayOrder < b.displayOrder),
                claimTypes: _.keyBy(action.info.claimTypes, ct => ct.claimTypeId),
                venues: _.keyBy(action.info.venues, v => v.venueId),
                partnerships: _.keyBy(action.info.partnerships, p => p.id),
                emailExceptionFilters: _.keyBy(action.info.emailExceptionFilters, e => e.emailExceptionFilterId),
                taskCompletionReasons: _.keyBy(action.info.taskCompletionReasons, r => r.taskCompletionReasonId),
                miscActivityTypes: _.keyBy(action.info.miscActivityTypes, t => t.miscActivityTypeId),
                userActivityTypes: action.info.userActivityTypes,
                minimumResearchSeconds: action.info.minimumResearchSeconds,
                applicationRoles: action.info.roles,
                resourceLicences: action.info.resourceLicences,
                mentions: action.info.mentions.filter(m => !m.disabled).sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : b.name.toLowerCase() > a.name.toLowerCase() ? -1 : 0)
            };
        }

        case (types.SET_SERVER_VALUES): {
            return {
                ...state,
                serverValues: action.values
            };
        }

        case (types.SET_PRECEDENT_INFO): {
            return {
                ...state,
                precedents: { ...state.precedents, [action.precedent.precedentId]: action.precedent }
            };
        }

        case (types.SET_PRECEDENT_FOLDERS): {
            return {
                ...state,
                precedentFolders: action.precedentFolders
            };
        }

        case (types.SET_CLAIM_TYPES): {
            return {
                ...state,
                claimTypes: _.keyBy(action.claimTypes, ct => ct.claimTypeId)
            }
        }

        case (types.SET_PARTNERSHIPS): {
            return {
                ...state,
                partnerships: _.keyBy(action.partnerships, p => p.id)
            }
        }        


        case (types.SET_VENUES): {
            return {
                ...state,
                venues: _.keyBy(action.venues, v => v.venueId)
            }
        }

        case (types.SET_CASE_STAGES): {
            return {
                ...state,
                caseTypes: {
                    ...state.caseTypes,
                    [action.caseTypeId]: {
                        ...state.caseTypes[action.caseTypeId],
                        stages: action.stages
                    }
                }
            }
        }

        case (types.SET_PRECEDENT_FOLDER): {

            let p = state.precedents[action.precedentId];

            return {
                ...state,
                precedents: {
                    ...state.precedents,
                    [action.precedentId]: {
                        ...p,
                        precedentFolderId: action.precedentFolderId
                    }
                }
            };
        }

        case (types.SET_PRECEDENT_DISABLED_FLAG): {

            let p = state.precedents[action.precedentId];

            return {
                ...state,
                precedents: {
                    ...state.precedents,
                    [action.precedentId]: {
                        ...p,
                        disabled: action.disabled
                    }
                }
            };
        }

        case (types.SET_PRECEDENT_FOLDER_DISABLED_FLAG): {
            return {
                ...state,
                precedentFolders: state.precedentFolders.map(pf =>
                    pf.precedentFolderId === action.precedentFolderId ? { ...pf, disabled: action.disabled } : pf
                )
            }
        }

        case (types.SET_PRECEDENT_IS_ADVISOR): {

            let p = state.precedents[action.precedentId];

            return {
                ...state,
                precedents: {
                    ...state.precedents,
                    [action.precedentId]: {
                        ...p,
                        isAdvisor: action.isAdvisor
                    }
                }
            };
        }

        case (types.SET_PRECEDENT_RESOURCE_LICENCES): {

            let p = state.precedents[action.precedentId];

            return {
                ...state,
                precedents: {
                    ...state.precedents,
                    [action.precedentId]: {
                        ...p,
                        licences: action.licences
                    }
                }
            };
        }

        case (types.SET_STAGE_TASKS): {
            return {
                ...state,
                caseTypes: {
                    ...state.caseTypes,
                    [action.caseTypeId]: {
                        ...state.caseTypes[action.caseTypeId],
                        stages: state.caseTypes[action.caseTypeId].stages.map(cs =>
                            cs.caseStageId === action.caseStageId ? { ...cs, stageTasks: action.stageTasks } : cs
                        )
                    }
                }
            }
        }

        case (types.SET_MENTIONS): {
            return {
                ...state,
                mentions: action.mentions
            }
        }

        case (types.SET_PRECEDENTS): {
            return {
                ...state,
                precedents: _.keyBy(action.precedents, p => p.precedentId),
            }
        }

        case (types.SET_EMAIL_EXCEPTION_FILTERS): {
            return {
                ...state,
                emailExceptionFilters: action.emailExceptionFilters
            }
        }

        default:
            return state;
    }
};

export default reducer;