import React from "react";
import {
    Avatar,
    MenuItem,
    Typography,
    makeStyles,
} from "@material-ui/core";
import salesforceRoles from "../constants/salesforceRoles";
import AdviceTypesChips from "./AdviceTypesChips";

const useStyles = makeStyles((theme) => ({
    receiveAdvice: {
        color: theme.palette.secondary.main,
    },
    contactInfoContainer: {
        display: "flex",
        flexDirection: "column",
    },
}));

const AccountContactMenuItem = ({ dto, handleAdd }) => {

    const classes = useStyles();

    return (
        <MenuItem
            onClick={handleAdd}
            key={dto.contactId}
            value={dto.contactId}
        >
            <Avatar
                alt={dto.name}
                src={dto.photo}
                style={{ marginRight: "8px" }}
            />
            <div className={classes.contactInfoContainer}>
                <Typography variant="body2">{dto.name} - {dto.position}</Typography>
                <AdviceTypesChips hasAdviceRole={dto.clientRoles?.includes(salesforceRoles.CASENEST_RECEIVE_ADVICE)} adviceTypeIds={dto.adviceTypeIds} />
            </div>
        </MenuItem>
    );
}

const MuiMenuItem = React.forwardRef((props, ref) => {
    return <AccountContactMenuItem innerRef={ref} {...props} />;
});


export default MuiMenuItem;