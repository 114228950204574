import React, { useState, useCallback, useEffect } from "react";
import Helmet from "react-helmet";
import styled from "styled-components";
import axios from "../plugins/axios";
import { connect } from "react-redux";
import {
    Box,
    Button as MuiIconButton,
    Divider as MuiDivider,
    Input,
    Typography,
    Card,
    CardContent,
    Table,
    TableHead,
    TableCell,
    TableBody,
    TableRow,
    TablePagination,
    Select,
    MenuItem,
    Dialog,
    DialogTitle,
    DialogContentText,
    DialogActions,
    DialogContent,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import {
    Add as AddIcon,
    Clear as CancelIcon,
    DeleteOutline as DeleteIcon,
    Edit as EditIcon,
    Save as SaveIcon,
} from "@material-ui/icons";
import { red } from "@material-ui/core/colors";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";

import Loader from "../components/Loader";
import { setSnackAction } from "../redux/actions/snackActions";

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

const Divider = styled(MuiDivider)(spacing);

const IconButton = styled(MuiIconButton)`
    padding: 2px;
    margin-top: -2px;
    margin-left: 2px;
    min-width: 0;
`;

const DeleteButton = styled(MuiIconButton)`
    color: ${red[500]};
    &:hover {
        background: ${red[50]};
    }
`;

const validateEmail = (email) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
};

const EditingRow = ({
    editedPolicy,
    handleCancel,
    handleSave,
    handleChange,
    insurers,
    getInsurerName,
}) => (
    <React.Fragment>
        <TableCell>
            <Box display="flex">
                <IconButton
                    disabled={
                        !editedPolicy.insurerId ||
                        !editedPolicy.description ||
                        !editedPolicy.representativeRate ||
                        !editedPolicy.excess ||
                        !editedPolicy.excessPaidBy ||
                        !editedPolicy.email ||
                        !validateEmail(editedPolicy.email)
                    }
                    onClick={handleSave}
                >
                    <SaveIcon />
                </IconButton>
                <IconButton onClick={handleCancel}>
                    <CancelIcon />
                </IconButton>
            </Box>
        </TableCell>
        <TableCell>{editedPolicy.policyId || null}</TableCell>
        <TableCell>
            <Select
                value={editedPolicy.insurerId}
                onChange={(e) => handleChange(e.target.value, "insurerId")}
                error={!editedPolicy.insurerId}
            >
                {insurers.map((i) => (
                    <MenuItem value={i.insurerId} key={i.insurerId}>
                        {getInsurerName(i.insurerId)}
                    </MenuItem>
                ))}
            </Select>
        </TableCell>
        <TableCell>
            <Input
                type="text"
                value={editedPolicy.description}
                onChange={(e) => handleChange(e.target.value, "description")}
                error={!editedPolicy.description}
            />
        </TableCell>
        <TableCell>
            <CurrencyTextField
                value={editedPolicy.representativeRate}
                onChange={(event, value) =>
                    handleChange(value, "representativeRate")
                }
                currencySymbol="&pound;"
                decimalCharacter="."
                digitGroupSeparator=","
                error={!editedPolicy.representativeRate}
            />
        </TableCell>
        <TableCell>
            <CurrencyTextField
                value={editedPolicy.excess}
                onChange={(event, value) => handleChange(value, "excess")}
                currencySymbol="&pound;"
                decimalCharacter="."
                digitGroupSeparator=","
                error={!editedPolicy.excess}
            />
        </TableCell>
        <TableCell>
            <Input
                type="text"
                value={editedPolicy.excessPaidBy}
                onChange={(e) => handleChange(e.target.value, "excessPaidBy")}
                error={!editedPolicy.excessPaidBy}
            />
        </TableCell>
        <TableCell>
            <Input
                type="email"
                value={editedPolicy.email}
                onChange={(e) => handleChange(e.target.value, "email")}
                error={
                    !editedPolicy.email || !validateEmail(editedPolicy.email)
                }
            />
        </TableCell>
    </React.Fragment>
);

const Policies = ({ dispatch }) => {
    const [policies, setPolicies] = useState([]);
    const [count, setCount] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [editedPolicy, setEditedPolicy] = useState(null);
    const [insurers, setInsurers] = useState(null);
    const [open, setOpen] = useState(false);
    const [selectedPolicy, setSelectedPolicy] = useState(null);
    const [mode, setMode] = useState("view");

    const getPolicies = useCallback(async () => {
        setLoading(true);
        let criteria = {
            pageSize: rowsPerPage,
            pageNum: page + 1,
        };
        let response = await axios.post(`${apiUrl}/getpolicies`, criteria);
        if (response.status === 200) {
            setPolicies(
                response.data.policies.map((p) => ({ ...p, toEdit: false }))
            );
            setCount(response.data.count);
        }
        setLoading(false);
    }, [page, rowsPerPage]);

    const getInsurers = useCallback(async () => {
        let response = await axios.get(`${apiUrl}/getinsurers`);
        if (response.status === 200) {
            setInsurers(response.data);
        }
    }, []);

    const getInsurerName = (id) =>
        insurers && insurers.filter((i) => i.insurerId === id)[0].name;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const handleEdit = (policyId) => {
        setMode("edit");
        setPolicies((prevPolicies) =>
            prevPolicies.map((p) =>
                p.policyId === policyId
                    ? { ...p, toEdit: true }
                    : { ...p, toEdit: false }
            )
        );
        setEditedPolicy(policies.filter((p) => p.policyId === policyId)[0]);
    };

    const handleChange = (value, field) => {
        setEditedPolicy((prevPolicy) => ({
            ...prevPolicy,
            [field]: value,
        }));
    };

    const handleCancel = () => {
        setPolicies((prevPolicies) =>
            prevPolicies.map((p) => ({ ...p, toEdit: false }))
        );
        setEditedPolicy(null);
        setMode("view");
    };

    const handleSave = async () => {
        if (editedPolicy.policyId) {
            let response = await axios.post(
                `${apiUrl}/updatepolicy`,
                editedPolicy
            );
            if (response && response.status === 200) {
                setPolicies((prevPolicies) =>
                    prevPolicies.map((p) =>
                        p.policyId === editedPolicy.policyId ? editedPolicy : p
                    )
                );
                dispatch(setSnackAction("Saved!", "success"));
            }
        } else {
            let response = await axios.post(
                `${apiUrl}/addpolicy`,
                editedPolicy
            );
            if (response && response.status === 200 && response.data) {
                setPolicies((prevPolicies) => [
                    ...prevPolicies,
                    {
                        ...editedPolicy,
                        policyId: response.data,
                    },
                ]);
                dispatch(setSnackAction("Saved!", "success"));
            }
        }
        handleCancel();
    };

    const handleOpen = (id) => {
        setSelectedPolicy(policies.filter((p) => p.policyId === id)[0]);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedPolicy(null);
    };

    const handleDelete = async () => {
        let response = await axios.delete(
            `${apiUrl}/deletepolicy/${selectedPolicy.policyId}`
        );
        if (response.status === 200) {
            setPolicies((prevPolicies) =>
                prevPolicies.map((p) =>
                    p.policyId === selectedPolicy.policyId
                        ? { ...p, disabled: true }
                        : p
                )
            );
            dispatch(setSnackAction("Saved!", "success"));
        }
        handleClose();
    };

    const handleAdd = () => {
        setMode("add");
        setEditedPolicy({
            insurerId: "",
            description: "",
            representativeRate: null,
            excess: null,
            excessPaidBy: "",
            email: "",
        });
    };

    useEffect(() => {
        getPolicies();
    }, [getPolicies]);

    useEffect(() => {
        getInsurers();
    }, [getInsurers]);

    return (
        <React.Fragment>
            <Helmet title="Policies" />
            <Box display="flex" justifyContent="space-between">
                <Typography variant="h3" gutterBottom display="inline">
                    Policies
                </Typography>
                <IconButton
                    color="primary"
                    disabled={mode !== "view"}
                    onClick={handleAdd}
                >
                    <AddIcon />
                </IconButton>
            </Box>
            <Divider my={6} />
            <Card mb={6}>
                <CardContent>
                    {loading ? (
                        <Loader />
                    ) : (
                        <React.Fragment>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Actions</TableCell>
                                        <TableCell>Id</TableCell>
                                        <TableCell>Insurer</TableCell>
                                        <TableCell>Description</TableCell>
                                        <TableCell>
                                            Representative Rate
                                        </TableCell>
                                        <TableCell>Excess</TableCell>
                                        <TableCell>Excess Paid By</TableCell>
                                        <TableCell>Email</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {policies
                                        .filter((p) => !p.disabled)
                                        .map((p) => (
                                            <TableRow
                                                style={{
                                                    opacity:
                                                        mode === "add" ||
                                                        (mode === "edit" &&
                                                            !p.toEdit)
                                                            ? "0.6"
                                                            : "1",
                                                }}
                                                key={p.policyId}
                                            >
                                                {p.toEdit && editedPolicy ? (
                                                    <EditingRow
                                                        editedPolicy={
                                                            editedPolicy
                                                        }
                                                        insurers={insurers}
                                                        handleCancel={
                                                            handleCancel
                                                        }
                                                        handleChange={
                                                            handleChange
                                                        }
                                                        handleSave={handleSave}
                                                        getInsurerName={
                                                            getInsurerName
                                                        }
                                                    />
                                                ) : (
                                                    <React.Fragment>
                                                        <TableCell>
                                                            <Box display="flex">
                                                                <IconButton
                                                                    disabled={
                                                                        mode ===
                                                                            "add" ||
                                                                        mode ===
                                                                            "edit"
                                                                    }
                                                                    onClick={() =>
                                                                        handleEdit(
                                                                            p.policyId
                                                                        )
                                                                    }
                                                                >
                                                                    <EditIcon />
                                                                </IconButton>
                                                                <IconButton
                                                                    disabled={
                                                                        mode ===
                                                                            "add" ||
                                                                        mode ===
                                                                            "edit"
                                                                    }
                                                                    onClick={() =>
                                                                        handleOpen(
                                                                            p.policyId
                                                                        )
                                                                    }
                                                                >
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            </Box>
                                                        </TableCell>
                                                        <TableCell>
                                                            {p.policyId}
                                                        </TableCell>
                                                        <TableCell>
                                                            {getInsurerName(
                                                                p.insurerId
                                                            )}
                                                        </TableCell>
                                                        <TableCell>
                                                            {p.description}
                                                        </TableCell>
                                                        <TableCell>
                                                            &pound;
                                                            {
                                                                p.representativeRate
                                                            }
                                                        </TableCell>
                                                        <TableCell>
                                                            &pound;{p.excess}
                                                        </TableCell>
                                                        <TableCell>
                                                            {p.excessPaidBy}
                                                        </TableCell>
                                                        <TableCell>
                                                            {p.email}
                                                        </TableCell>
                                                    </React.Fragment>
                                                )}
                                            </TableRow>
                                        ))}
                                    {mode === "add" && editedPolicy && (
                                        <TableRow>
                                            <EditingRow
                                                editedPolicy={editedPolicy}
                                                insurers={insurers}
                                                handleCancel={handleCancel}
                                                handleChange={handleChange}
                                                handleSave={handleSave}
                                                getInsurerName={getInsurerName}
                                            />
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={count}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </React.Fragment>
                    )}
                </CardContent>
            </Card>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>
                    Delete policy {selectedPolicy && selectedPolicy.description}
                    ?
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        This action cannot be undone
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <MuiIconButton onClick={handleClose} color="primary">
                        Cancel
                    </MuiIconButton>
                    <DeleteButton onClick={handleDelete} color="primary">
                        Delete
                    </DeleteButton>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

export default connect()(Policies);
