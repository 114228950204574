import axios from "../plugins/axios";

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

const deleteBlob = async (reference) =>
    await axios.delete(`${apiUrl}/storage/${reference}`);

const upload = async (file) => {
    let formData = new FormData();
    formData.append("file", file);
    const response = await axios.put(`${apiUrl}/storage/upload`, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
    return response.data;
}

const storageService = {
    deleteBlob,
    upload,
}

export default storageService;