import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
    Dialog,
    DialogContent,
    Grid,
    DialogTitle,
    Typography as MuiTypography,
    Box,
    IconButton as MuiIconButton,
    Button,
    CircularProgress,
    Tooltip,
    makeStyles,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { spacing } from "@material-ui/system";
import styled from "styled-components";
import {
    Timer as TimerIcon,
    Replay as UndoIcon,
    Info as MuiInfoIcon
} from "@material-ui/icons";
import { KeyboardDatePicker } from "@material-ui/pickers";
import {
    getCaseContracts,
    addCaseContract,
    setCurrentCaseContract,
} from "../services/caseContractService";
import { formatTimeDigital } from "../helpers/formatTime";
import { setSnackAction } from "../redux/actions/snackActions";
import { setCaseChargeable } from "../redux/actions/caseActions";
import {
    setCaseContract,
} from "../redux/actions/caseActions";
import { grey } from "@material-ui/core/colors";
import reactQueryClient from "../reactQueryClient";
import queryKeys from "../constants/queryKeys";
import caseService from "../services/caseService";
import ContractSelect from "./ContractSelect";

const Typography = styled(MuiTypography)(spacing);

const InfoIcon = styled(MuiInfoIcon)`
    margin-top: -10px;
    font-size: 1em;
    cursor: pointer;
    color: ${grey[700]};
`;

const modes = {
    view: "VIEW",
    add: "ADD",
    edit: "EDIT",
};

const useStyles = makeStyles((theme) => ({
    spacing: {
        columnGap: theme.spacing(2),
    },
    minWidth: {
        minWidth: 120,
    },
    marginBottomRemoval: {
        marginBottom: 0,
    },
}));

const CaseContractDialog = ({
    open,
    handleClose,
    caseId,
    contractId,
    accountContracts,
    dateCreated,
    totalActivityTime,
}) => {
    const dispatch = useDispatch();
    const classes = useStyles();

    const { cases } = useSelector((state) => state.caseReducer);
    const [caseContracts, setCaseContracts] = useState([]);
    const [mode, setMode] = useState(modes.view);
    const [saving, setSaving] = useState(false);

    const [newContract, setNewContract] = useState({
        contractId: "",
        startDate: null,
        adviceTypeId: null,
        isChargeable: null
    });

    const caseSummary = useMemo(() => cases[caseId].caseSummary, [caseId, cases]);

    const currentCaseContract = useMemo(() => accountContracts.find(c => c.contractId === contractId), [accountContracts, contractId]);

    const caseContractIds = caseContracts.map((c) => c.contractId);

    const isNewContractUnitised = (newContractId = 0) => accountContracts.find((contract) => contract.contractId === newContractId)?.isUnitisedTime ?? false;

    const DisplayContract = ({ contract }) => {
        if (!contract)
            return (
                <Typography variant="subtitle2">Invalid contract</Typography>
            );

        return (
            <Box display="flex" alignItems="center" my={3}>
                <Typography variant="subtitle2" mr={2}>
                    {contract.coreService || "Not set"}
                    {contract.contractSummary && (
                        <Tooltip title={contract.contractSummary}>
                            <InfoIcon />
                        </Tooltip>
                    )}
                </Typography>
                <Typography variant="body1" mr={2}>
                    {moment(contract.startDate).format("DD-MM-YYYY")} - {contract.endDate ? moment(contract.endDate).format("DD-MM-YYYY") : "Current"}
                </Typography>
                <TimerIcon />
                <Typography variant="subtitle2">
                    {formatTimeDigital(caseContracts.length === 1 ? totalActivityTime : contract.activityDuration || 0)}
                </Typography>
            </Box>
        );
    };

    const handleClickAdd = () => {
        setNewContract({ ...newContract, startDate: new Date() });
        setMode(modes.add);
    };

    const setChargeable = async (isChargeable) => {
        try {

            const { data: responseIsChargeable } = await caseService.setChargeable({ caseId, budgetedMinutes: 0, isChargeable });

            dispatch(
                setCaseChargeable({
                    caseId,
                    isChargeable: responseIsChargeable,
                })
            );

            reactQueryClient.invalidateQueries([queryKeys.caseTime, caseId]);
            reactQueryClient.invalidateQueries([queryKeys.contractSummary, contractId]);

            return responseIsChargeable
                ? "Case is now chargeable."
                : "Case is now non-chargeable";

        } catch (e) {
            console.error(e);
        }
    };

    const handleSave = async () => {

        let successes = [];

        if (mode === modes.edit) {

            const newContractId = newContract.contractId;

            if (newContractId !== contractId) {
                setSaving(true);
                try {
                    const { caseContractId, activityDuration } = await setCurrentCaseContract(newContractId, caseId);
                    dispatch(setCaseContract(caseId, newContractId, isNewContractUnitised(newContractId)));
                    setCaseContracts(
                        caseContractId === null && activityDuration === null
                            ? caseContracts.map((c) => ({
                                ...c,
                                contractId: newContractId,
                            }))
                            : [
                                {
                                    caseContractId,
                                    contractId: newContractId,
                                    startDate: dateCreated,
                                    endDate: null,
                                    activityDuration,
                                },
                            ]
                    );
                    successes.push("Successfully updated current contract!");

                    if (currentCaseContract.isChargeable && newContract.isChargeable === false) {
                        successes.push(await setChargeable(false));
                    }

                    if (currentCaseContract.isChargeable === false && newContract.isChargeable) {
                        successes.push(await setChargeable(true));
                    }

                } catch (error) {
                    console.error(error);
                }
                setSaving(false);
            }
        }

        if (mode === modes.add) {

            const handleError = message => dispatch(setSnackAction(message, "error"));

            const accountContract = accountContracts.find((c) => c.contractId === newContract.contractId);

            if (!accountContract) {
                handleError("Whoops! Couldn't find that contract");
                return;
            }

            if (!Date.parse(newContract.startDate)) {
                handleError("Start date is invalid");
                return;
            }

            const startDate = new Date(newContract.startDate);
            startDate.setHours(0, 0, 0, 0);

            if (startDate > new Date()) {
                handleError("Start date cannot be in the future");
                return;
            }

            if (startDate < new Date(dateCreated)) {
                handleError("Start date cannot be before case was created");
                return;
            }

            if (startDate < new Date(accountContract.startDate) || startDate > new Date(accountContract.endDate)) {
                handleError("Start date is out of the contracts date range");
                return;
            }

            for (let i = 0; i < caseContracts.length; i++) {
                if (startDate <= new Date(caseContracts[i].startDate)) {
                    handleError("Start date cannot be before start date of existing contract");
                    return;
                }
            }

            setSaving(true);
            try {
                const {
                    prevContractId,
                    prevActivityDuration,
                    currentCaseContractId,
                    currentActivityDuration,                    
                } = await addCaseContract(newContract, caseId);

                // add new contract, update activity duration of prev contract
                setCaseContracts([
                    ...caseContracts.map((c) =>
                        c.contractId === prevContractId
                            ? {
                                ...c,
                                endDate: newContract.startDate,
                                activityDuration: prevActivityDuration,                               
                            }
                            : c
                    ),
                    {
                        caseContractId: currentCaseContractId,
                        contractId: newContract.contractId,
                        startDate: newContract.startDate,
                        activityDuration: currentActivityDuration,
                    },
                ]);

                dispatch(setCaseContract(caseId, newContract.contractId, isNewContractUnitised(newContract.contractId)));
                successes.push("Successfully added new contract!");
                reactQueryClient.invalidateQueries([queryKeys.caseTime, caseId]);
                reactQueryClient.invalidateQueries([queryKeys.contractSummary, newContract.contractId]);
            } catch (error) {
                console.error(error);
            }
            setSaving(false);
        }        

        if (successes.length > 0) {
            dispatch(setSnackAction(successes.join("<br/>"), "success"));

            handleReset();
        }
    };

    const handleStartDateChange = (date) => {
        const startDate = new Date(date);
        startDate.setUTCHours(0, 0, 0, 0);
        setNewContract({ ...newContract, startDate });
    };

    const handleReset = () => {
        setNewContract({
            contractId: "",
            startDate: null,
            adviceTypeId: null,
        });

        setMode(modes.view);
    };

    const handleCancel = () => {
        handleReset();
        handleClose();
    };
  
    useEffect(() => {
        const getCaseContractsAsync = async () => {
            if (open && caseId) {
                try {
                    const contractDtos = await getCaseContracts(caseId);
                    setCaseContracts(contractDtos);
                } catch (error) {
                    console.error(error);
                }
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }

        getCaseContractsAsync();

    }, [caseId, contractId, open]);

    const editCurrentContract = () => {
        setNewContractId(contractId);     
        setMode(modes.edit);
    }

    const setNewContractId = contractId => {
        let contract = accountContracts.find(x => x.contractId === contractId);
       
        setNewContract({
            ...newContract,
            contractId: contractId,
            adviceTypeId: contract?.adviceTypeId,
            isChargeable: contract?.isChargeable
        })
    }

    return (
        <Dialog open={open} onClose={handleCancel} fullWidth maxWidth="lg">
            <DialogTitle>Case Contracts</DialogTitle>
            <DialogContent>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <Typography variant="h6" mb={3}>Current Contract</Typography>
                        {
                            mode !== modes.edit && currentCaseContract &&
                            <DisplayContract contract={currentCaseContract} />
                        }
                        {mode === modes.edit && (
                            <Box
                                className={classes.spacing}
                                display="flex"
                                alignItems="center"
                            >
                                <ContractSelect
                                    contracts={accountContracts.filter(c => c.adviceTypeId === caseSummary.adviceTypeId || c.additionalAdviceTypeId === caseSummary.adviceTypeId)}
                                    contractId={newContract.contractId}
                                    setContractId={setNewContractId}
                                />                               
                                <MuiIconButton onClick={handleReset}>
                                    <UndoIcon />
                                </MuiIconButton>
                            </Box>
                        )}
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="h6" mb={3}>Contract History</Typography>
                        {caseContracts && caseContracts.map(c => <DisplayContract key={c.contractId} contract={c} />)}
                    </Grid>
                </Grid>
                {
                    mode === modes.add &&
                    <Box display="flex" flexDirection="column" my={3}>
                        <Typography variant="h6" mb={3}>
                            Add Contract
                        </Typography>
                        <Box
                            display="flex"
                            alignItems="flex-end"
                            className={classes.spacing}
                        >
                            <ContractSelect
                                contracts={accountContracts.filter((c) => !caseContractIds.includes(c.contractId) && (c.adviceTypeId === caseSummary.adviceTypeId || c.additionalAdviceTypeId === caseSummary.adviceTypeId))}
                                contractId={newContract.contractId}
                                setContractId={setNewContractId}
                            />                            
                            <KeyboardDatePicker
                                className={classes.marginBottomRemoval}
                                name="start"
                                format="dd/MM/yyyy"
                                margin="normal"
                                label="Start"
                                value={newContract.startDate}
                                onChange={handleStartDateChange}
                            />
                            <MuiIconButton onClick={handleReset}>
                                <UndoIcon />
                            </MuiIconButton>
                        </Box>
                    </Box>
                }
                {
                    mode === modes.edit &&
                    currentCaseContract.isChargeable &&
                    newContract.isChargeable === false &&
                    <Alert severity="warning">This is a change from chargeable to non-chargeable.  You will need to check if any existing activities need updating.</Alert>
                }
                {
                    mode === modes.edit &&
                    currentCaseContract.isChargeable === false &&
                    newContract.isChargeable &&
                    <Alert severity="warning">This is a change from non-chargeable to chargeable.  You will need to check if any existing activities need updating.</Alert>
                }
                <Box display="flex" justifyContent="space-between" my={3}>
                    <div>
                        {
                            mode === modes.view && Boolean(currentCaseContract)
                                ? <Button
                                    style={{ marginRight: "12px" }}
                                    color="primary"
                                    variant="contained"
                                    onClick={handleClickAdd}
                                >
                                    Add
                                </Button>
                                : <div></div>
                        }
                        {
                            mode === modes.view && caseContracts.length < 2 &&
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={editCurrentContract}
                            >
                                Edit current contract
                            </Button>
                        }
                    </div>
                    <Box
                        display="flex"
                        justifyContent="flex-end"
                        className={classes.spacing}
                    >
                        {saving ? (
                            <CircularProgress />
                        ) : (
                            <React.Fragment>
                                <Button
                                    color="default"
                                    variant="contained"
                                    onClick={handleCancel}
                                >
                                    Close
                                </Button>
                                {mode !== modes.view && (
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={handleSave}
                                        disabled={!newContract.contractId}
                                    >
                                        Save
                                    </Button>
                                )}
                            </React.Fragment>
                        )}
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default CaseContractDialog;
