const mergeSelectedFiltersWithFetchedFilters = (
    selectedFiltersObj = {},
    fetchedFilters = [],
    value
) => {
    let selectedFilters = Object.values(selectedFiltersObj);

    selectedFilters =
        selectedFilters.length > 0
            ? selectedFilters.map((ca) => ({ ...ca, count: 0 }))
            : [];

    if (!fetchedFilters.length && !selectedFilters.length) return [];

    const selectedFiltersWithZeroResults = selectedFilters?.filter(
        (selectedFilter) =>
            !fetchedFilters.some(
                (filter) => filter[value] === selectedFilter[value]
            )
    );

    return fetchedFilters.concat(selectedFiltersWithZeroResults);
};

export { mergeSelectedFiltersWithFetchedFilters };
