import {useQuery} from "react-query";
import queryKeys from "../../constants/queryKeys";
import caseTimeEventGroupService from "../../services/caseTimeEventGroupService";

const useCaseTimeEventGroupsForUserAndAccount = ({ userId, accountId }) => useQuery(
  [queryKeys.caseTimeEventGroups, userId, accountId],
  () => caseTimeEventGroupService.getForUserAndAccount({ userId, accountId }),
  {
    enabled: !!userId && !!accountId,
  }
)

export default useCaseTimeEventGroupsForUserAndAccount;