import React from 'react';
import {makeStyles} from "@material-ui/core";
import {ReactComponent as PoundSign} from '../images/poundSign.svg'
import {blue, grey} from "@material-ui/core/colors";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
    container: {
        height: '24px',
        width: '24px',
        borderRadius: '20px',
        padding: '4px',
        marginLeft: '4px',
        backgroundColor: grey[500],
        '&.active': {
            backgroundColor: blue[500]
        }
    },
    icon: {
        fill: grey[700],
        '&.icon-active': {
            fill: 'white'
        }
    },
}))

function ChargeableIcon({isChargeable}) {
    const classes = useStyles()
    const containerClasses = clsx({[classes.container]: true, 'active': isChargeable})
    const iconClasses = clsx({[classes.icon]: true, 'icon-active': isChargeable})
    
    return (
        <div className={containerClasses}>
            <PoundSign className={iconClasses} />
        </div>
    );
}

export default ChargeableIcon;