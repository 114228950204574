import axios from "../plugins/axios";

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

const copyDraftFile = async ({ file }) =>
    axios.post(`${apiUrl}/sharepoint/copysharepointdocument`, file);

const fetchDraftFiles = async ({ caseId }) => {
    try {
        const response = await axios.get(
            `${apiUrl}/sharepoint/getdraftcasefiles/${caseId}`
        );
        return response.data;
    } catch (e) {
        throw e;
    }
};

const getAccessToSharePointDocument = async (file) =>
    await axios.post(
        `${apiUrl}/sharepoint/getaccesstosharepointdocument`,
        file
    );

const sharepointService = {
    fetchDraftFiles,
    copyDraftFile,
    getAccessToSharePointDocument,
};

export default sharepointService;
