import React from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    makeStyles,
    Typography,
} from "@material-ui/core";
import HistoryDateFilter from "../filters/HistoryDateFilter";
import HistoryActivityFilter from "../filters/HistoryActivityFilter";
import Loader from "../Loader";
import HistoryChargeableFilter from "../filters/HistoryChargeableFilter";
import HistoryDirectionFilter from "../filters/HistoryDirectionFilter";
import HistoryUserFilter from "../filters/HistoryUserFilter";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        [theme.breakpoints.up("sm")]: {
            width: "initial",
        },
    },
    title: { fontSize: 18, fontWeight: 600 },
    actionBtns: {
        display: "flex",
        justifyContent: "space-between",
        padding: theme.spacing(4, 6),
    },
    divider: {
        width: "100%",
        margin: theme.spacing(2, 0),
    },
}));

const CaseHistoryFilterDialog = ({
    open,
    handleClose,
    results,
    dateFrom,
    dateTo,
    isLoading,
    onClearAllClick,
    onFromDateChange,
    onToDateChange,
    activitiesChecked,
    onActivityClick,
    chargeables,
    onChargeableClick,
    filters,
    directions,
    onDirectionClick,
    selectedUsers,
    onUserClick,
}) => {
    const classes = useStyles();
    const { chargeableFilters, directionFilters, userFilters } = filters || {};

    return (
        <Dialog
            className={classes.root}
            onClose={() => handleClose(false)}
            open={open}
        >
            <DialogTitle onClose={() => handleClose(false)}>
                <Typography
                    variant="body1"
                    color="primary"
                    className={classes.title}
                >
                    Filter
                </Typography>
            </DialogTitle>
            <DialogContent dividers>
                {isLoading ? (
                    <Loader />
                ) : (
                    <>
                        <HistoryDateFilter
                            dateFrom={dateFrom}
                            dateTo={dateTo}
                            onFromDateChange={onFromDateChange}
                            onToDateChange={onToDateChange}
                        />
                        <Divider className={classes.divider} />
                        <HistoryActivityFilter
                            activities={filters}
                            activitiesChecked={activitiesChecked}
                            onActivityClick={onActivityClick}
                            onUserClick={onUserClick}
                        />
                        <Divider className={classes.divider} />
                        <HistoryUserFilter
                            users={userFilters}
                            selectedUsers={selectedUsers}
                            onUserClick={onUserClick}
                        />
                        <Divider className={classes.divider} />
                        <HistoryChargeableFilter
                            chargeableFilters={chargeableFilters}
                            chargeables={chargeables}
                            onChargeableClick={onChargeableClick}
                        />
                        <Divider className={classes.divider} />
                        <HistoryDirectionFilter
                            directions={directions}
                            directionFilters={directionFilters}
                            onDirectionClick={onDirectionClick}
                        />
                    </>
                )}
            </DialogContent>

            <DialogActions className={classes.actionBtns}>
                <Button
                    color="secondary"
                    variant="text"
                    onClick={onClearAllClick}
                >
                    Clear All
                </Button>
                <Button
                    autoFocus
                    onClick={() => handleClose(false)}
                    color="secondary"
                    variant="contained"
                >
                    Show Results {!!results && `(${results})`}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CaseHistoryFilterDialog;
