import React from "react";
import {
    CardContent,
    Card,
    Typography,
    makeStyles,
    Button,
    CircularProgress,
    alpha,
} from "@material-ui/core";
import { GetApp as DownloadIcon } from "@material-ui/icons";
import { amber, green, grey, red } from "@material-ui/core/colors";
import clsx from "clsx";
import CaseTimeDurationTable from "./CaseTimeDurationTable";
import Loader from "./Loader";

const useStyles = makeStyles((theme) => ({
    chargeableMonitorCircle: {
        strokeLinecap: "round",
    },
    chargeableMonitorOk: {
        color: green[500],
    },
    chargeableMonitorWarning: {
        color: amber[500],
    },
    chargeableMonitorDanger: {
        color: red[500],
    },
    chargeableMonitorTop: {
        position: "absolute",
    },
    chargeableMonitorBottom: {
        color: grey[200],
    },
    chargeableMonitorTitle: {
        marginBottom: theme.spacing(4),
    },
    chargeableMonitorDescription: {
        marginTop: theme.spacing(2),
        color: theme.palette.text.secondary,
    },
    iconButton: {
        padding: theme.spacing(1),
        marginLeft: theme.spacing(1),
        minWidth: 0,
        color: grey[700],
    },
    card: {
        marginBottom: theme.spacing(6),
    },
    headerContainer: {
        display: "flex",
    },
    titleWrapper: {
        flexGrow: 1,
    },
    timeContainer: {
        display: "flex",
        flexDirection: "column",
        marginTop: theme.spacing(3),
    },
    timeItem: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: theme.spacing(2, 4),
    },
    textBold: {
        fontSize: 15,
        fontWeight: 600,
    },
    text: {
        fontSize: 15,
        fontWeight: 500,
        color: alpha(theme.palette.primary.main, 0.6),
    },
    divider: {
        width: "100%",
        margin: theme.spacing(1, 0),
    },
    budgetMonitorContainer: {
        margin: theme.spacing(2, 0),
        color: alpha(theme.palette.primary.main, 0.6),
        fontWeight: 500,
    },
    monitorContainer: {
        position: "relative",
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
    },
    monitorPercentageWrapper: {
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        position: "absolute",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    btnContainer: {
        display: "flex",
        flexDirection: "column",
        margin: theme.spacing(3, 0, 1, 0),
    },
    actionBtn: {
        margin: theme.spacing(1, 0),
    },
}));

const chargeableMonitorSize = 80;
const chargeableMonitorThickness = 4;
const chargeableMonitorOkThreshold = 65;
const chargeableMonitorWarningThreshold = 85;

const CaseTimeComponent = ({
    isChargeable,
    isUnitised,
    hasBudgetSet,
    downloading,
    totalTime,
    nonChargeableTime,
    chargeableTime,
    budgetHours,
    budgetMinutes,
    budgetTime,
    chargeableTimeUsedPercentage = 0,
    onDownloadTimesheet,
    onSetBudgetClick,
    onMakeNonChargeableClick,
    onMakeChargeableClick,
    onRemoveBudgetClick,
}) => {
    const classes = useStyles();

    const chargeableMonitorClass =
        chargeableTimeUsedPercentage < chargeableMonitorOkThreshold
            ? classes.chargeableMonitorOk
            : chargeableTimeUsedPercentage < chargeableMonitorWarningThreshold
            ? classes.chargeableMonitorWarning
            : classes.chargeableMonitorDanger;

    return (
        <Card className={classes.card}>
            <CardContent>
                <div className={classes.headerContainer}>
                    <div className={classes.titleWrapper}>
                        <Typography variant="h6" gutterBottom>
                            Time
                        </Typography>
                    </div>
                    <Button
                        className={classes.iconButton}
                        onClick={onDownloadTimesheet}
                    >
                        <DownloadIcon />
                    </Button>
                </div>
                {downloading ? (
                    <Loader />
                ) : (
                    <>
                        <div className={classes.timeContainer}>
                            <CaseTimeDurationTable
                                isChargeable={isChargeable}
                                isUnitised={isUnitised}
                                hasBudgetSet={hasBudgetSet}
                                totalTime={totalTime}
                                chargeableTime={chargeableTime}
                                nonChargeableTime={nonChargeableTime}
                                budgetTime={budgetTime}
                            />
                        </div>
                        {isChargeable && hasBudgetSet && (
                            <div className={classes.budgetMonitorContainer}>
                                <div className={classes.monitorContainer}>
                                    <CircularProgress
                                        variant="determinate"
                                        thickness={chargeableMonitorThickness}
                                        size={chargeableMonitorSize}
                                        className={
                                            classes.chargeableMonitorBottom
                                        }
                                        value={100}
                                    />
                                    <CircularProgress
                                        classes={{
                                            circle: classes.chargeableMonitorCircle,
                                        }}
                                        className={clsx(classes.chargeableMonitorTop, chargeableMonitorClass)}
                                        variant="determinate"
                                        value={chargeableTimeUsedPercentage > 100 ? 100 : chargeableTimeUsedPercentage}
                                        thickness={chargeableMonitorThickness}
                                        size={chargeableMonitorSize}
                                    />
                                    <div className={classes.monitorPercentageWrapper}>
                                        <Typography
                                            variant="caption"
                                            component="div"
                                            color="textSecondary"
                                        >{`${chargeableTimeUsedPercentage}%`}</Typography>
                                    </div>
                                </div>
                                <Typography
                                    className={
                                        classes.chargeableMonitorDescription
                                    }
                                    variant="body2"
                                >
                                    The total chargeable time on this case is{" "}
                                    {`${chargeableTimeUsedPercentage}%`} of the
                                    budgeted {budgetHours} hours
                                    {budgetMinutes
                                        ? ` and ${budgetMinutes} minutes.`
                                        : "."}
                                </Typography>
                            </div>
                        )}
                        <div className={classes.btnContainer}>
                            {isChargeable &&
                                (hasBudgetSet ? (
                                    <>
                                        <Button
                                            className={classes.actionBtn}
                                            variant="outlined"
                                            color="primary"
                                            onClick={() => onSetBudgetClick(true)}
                                        >
                                            Edit Budget
                                        </Button>
                                        <Button
                                            className={classes.actionBtn}
                                            variant="outlined"
                                            color="primary"
                                            onClick={() => onRemoveBudgetClick(true)}
                                        >
                                            Remove Budget
                                        </Button>
                                    </>
                                ) : (
                                    <Button
                                        className={classes.actionBtn}
                                        variant="outlined"
                                        color="primary"
                                        onClick={() => onSetBudgetClick(true)}
                                    >
                                        Set Budget
                                    </Button>
                                ))}

                            {isChargeable ? (
                                <Button
                                    className={classes.actionBtn}
                                    variant="contained"
                                    color="primary"
                                    onClick={() => onMakeNonChargeableClick(true)}
                                >
                                    Make Non-chargeable
                                </Button>
                            ) : (
                                <Button
                                    className={classes.actionBtn}
                                    variant="contained"
                                    color="primary"
                                    onClick={() => onMakeChargeableClick(true)}
                                >
                                    Make Chargeable
                                </Button>
                            )}
                        </div>
                    </>
                )}
            </CardContent>
        </Card>
    );
};

export default CaseTimeComponent;
