import { useMutation } from "react-query";
import queryKeys from "../../constants/queryKeys";
import accountService from "../../services/accountService";
import reactQueryClient from "../../reactQueryClient";

const useDeleteAccountFile = () =>
    useMutation(({ fileType, accountFile, accountId, documentType }) => accountService.deleteAccountFile(documentType, accountFile), {
        onMutate: async ({ fileType, accountFile, accountId, documentType }) => {

            const queryKey = [queryKeys.accountFiles, fileType, documentType, accountId];
            reactQueryClient.cancelQueries(queryKey);
            const previousDetails = reactQueryClient.getQueryData(queryKey);
            reactQueryClient.setQueryData(queryKey, (prev) => ([
                ...(prev.filter(x => x.name !== accountFile) || [])
            ]));
            return { previousDetails, queryKey };
        },
        onError: (err, newDetails, context) => {
            reactQueryClient.setQueryData(
                context.queryKey,
                context.previousDetails
            );
        },
        onSettled: (data, err, newDetails, context) => {
            reactQueryClient.invalidateQueries(context.queryKey);
        },
    });

export default useDeleteAccountFile;
