import { nanoid } from 'nanoid';
import activityTypes, {
    getTabActivityType,
} from '../../constants/activityTypes';
import { START_TIMING_CASE_ACTIVITY } from '../constants';

/**
 * when an activityType = null activity is dispatched, this middleware
 * will determine what the activity type should be based on current state
 */
const caseActivitySetter = ({ getState }) => next => action => {
    const currentState = getState();
    const { userReducer, caseReducer, caseTimerReducer, flexReducer } = currentState;
    const { documentViewer } = userReducer;
    const {
        currentCaseId,
        cases,
        callNote,
        milestoneOpen,
        internalNote,
    } = caseReducer;
    const { flexWindowStatus } = caseTimerReducer;
    const currentCase = cases[currentCaseId];
    const { currentCallId } = flexReducer;

    const getCurrentActivityType = () => {
        let activityType;        

        if (milestoneOpen || documentViewer.open)
            activityType = activityTypes.FILE;
        else if (flexWindowStatus === 'focus' && action.activityType !== activityTypes.RESUME_CASE_ACTIVITY)
            activityType = activityTypes.FLEX;
        else if (callNote.issueFocus || callNote.adviceFocus || callNote.actionFocus || callNote.descriptionFocus)
            activityType = activityTypes.CALL_NOTE;
        else if (internalNote.noteFocus || internalNote.descriptionFocus)
            activityType = activityTypes.INTERNAL_NOTE;
        else if (currentCase?.viewState.currentTab)
            activityType = getTabActivityType(currentCase.viewState.currentTab);

        return activityType;
    };

    const getCurrentActivity = () => {
        let activityType =
            action.activityType &&
            action.activityType !== activityTypes.RESUME_CASE_ACTIVITY
                ? action.activityType
                : getCurrentActivityType();
        
        if (action.shouldAppendCall)
            activityType += 'Call';

        return {
            id: nanoid(),
            timeEventType: activityType,
            start: new Date(),
            caseId: currentCaseId,
            callId: activityType.toLowerCase().endsWith("call") ? currentCallId : null,
        };
    };

    if (action.type === START_TIMING_CASE_ACTIVITY) {
        action.payload = getCurrentActivity();
        // remove previous keys
        delete action.activityType;
        delete action.shouldAppendCall;
    }

    return next(action);
};

export default caseActivitySetter;
