import Plugin from "@ckeditor/ckeditor5-core/src/plugin";
import store from "../redux/store";
import axios from "../plugins/axios";

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

class CommentsIntegration extends Plugin {
    static get requires() {
        return ["CommentsRepository"];
    }

    init() {
        const usersPlugin = this.editor.plugins.get("Users");
        const commentsRepositoryPlugin =
            this.editor.plugins.get("CommentsRepository");

        var userState = store.getState().userReducer;

        usersPlugin.addUser({
            id: "client",
            name: "Client User",
        });

        const users = Object.values(userState.users).map((u) => ({
            id: u.userId.toString(),
            name: u.name,
            avatar: u.photo,
        }));

        for (const user of users) {
            usersPlugin.addUser(user);
        }

        usersPlugin.defineMe(userState.userProfile.userId.toString());

        commentsRepositoryPlugin.adapter = {
            async addComment(data) {
                console.log("Comment added", data);

                var response = await axios.post(`${apiUrl}/comment`, data);

                return Promise.resolve({
                    createdAt: new Date(response.data),
                });
            },

            async updateComment(data) {
                console.log("Comment updated", data);

                await axios.post(`${apiUrl}/comment`, data);

                return Promise.resolve();
            },

            async removeComment(data) {
                await axios.post(`${apiUrl}/comment/remove`, data);

                return Promise.resolve();
            },

            async getCommentThread(data) {
                console.log("logx Getting comment thread", data);

                var response = await axios.get(
                    `${apiUrl}/comments/${data.threadId}`
                );

                return Promise.resolve({
                    threadId: data.threadId,
                    comments: response.data.map((x) => ({
                        commentId: x.commentId,
                        content: x.content,
                        attributes: x.attributes,
                        authorId: x.authorId,
                        createdOn: new Date(x.createdOn),
                    })),
                    isFromAdapter: true,
                });
            },
        };
    }
}

export default CommentsIntegration;
