import React, { useState } from "react";
import { connect } from "react-redux";
import {
    alpha,
    Box,
    Card,
    CardContent,
    Checkbox,
    CircularProgress,
    FormControlLabel,
    FormGroup,
    LinearProgress,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
    Avatar,
    Tooltip,
} from "@material-ui/core";
import { AvatarGroup } from "@material-ui/lab";
import useAccountContractSummary from "../../hooks/queries/useAccountContractSummary";
import { Alert } from "@material-ui/lab";
import { red } from "@material-ui/core/colors";
import ContractSummaryDialog from "../../components/dialogs/ContractSummaryDialog";
import { formatShortMonthDate } from "../../utils/dateTimeFormatters";
import ContractProductDetail from "../../components/ContractProductDetail";
import avatarStyles from "../../theme/avatars";
import SubscriptionBox from "../../components/SubscriptionBox";

const useStyles = makeStyles((theme) => ({
    card: {
        marginBottom: theme.spacing(3),
    },
    expiredDateRange: {
        color: red[800],
    },
    totalDuration: {
        fontWeight: 600,
    },
    totalUnits: {
        fontWeight: 500,
    },
    summaryRow: {
        cursor: "pointer",
        height: "100%",
    },
    linearProgress: ({ cappedWarning }) => ({
        backgroundColor: cappedWarning ? alpha(red[800], 0.3) : theme.palette.secondary.light,
        "& .MuiLinearProgress-barColorPrimary": {
            backgroundColor: cappedWarning ? red[800] : theme.palette.secondary.main,
        },
    }),
    linearProgressHeading: {
        fontSize: ".8rem",
    },
    linearProgressCaption: ({ cappedWarning }) => ({
        color: cappedWarning ? red[800] : theme.palette.secondary.main,
        fontWeight: theme.typography.fontWeightMedium,
    })
}));

function LinearProgressWithLabel({ cappedWarning, value }) {
    const classes = useStyles({ cappedWarning });
    return (
        <Box display="flex" flexDirection="column">
            <Typography variant="subtitle2" className={classes.linearProgressHeading}>
                Capped Contract
            </Typography>
            <Box display="flex" alignItems="center" height="20px">
                <Box width="100%" mr={2}>
                    <LinearProgress variant="determinate" value={value > 100 ? 100 : value} className={classes.linearProgress} />
                </Box>
                <Box minWidth={35}>
                    <Typography variant="body2" className={classes.linearProgressCaption}>{`${Math.round(value)}%`}</Typography>
                </Box>
            </Box>
        </Box>
    );
}

const ContractSummaryCard = ({ accountId, users }) => {
    const classes = useStyles();
    const [pageNum, setPageNum] = useState(0);
    const [pageSize, setPageSize] = useState(5);
    const [includeExpired, setIncludeExpired] = useState(false);
    const [isContractDialogOpen, setIsContractDialogOpen] = useState(false);
    const [contractToView, setContractToView] = useState(null);
    const avatarClasses = avatarStyles();

    const { data, isError, error, isLoading } = useAccountContractSummary({
        accountId,
        pageSize,
        pageNum,
        includeExpired,
    });

    const handleChangePage = (event, newPage) => {
        setPageNum(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPageSize(parseInt(event.target.value, 10));
        setPageNum(0);
    };

    const handleRowClick = (contract) => {
        setIsContractDialogOpen(true);
        setContractToView(contract);
    };

    const handleContractDialogClose = () => {
        setIsContractDialogOpen(false);
        setContractToView(null);
    };

    const avatar = m => {

        if (m.userId) {
            let user = users[m.userId];
            m.name = user?.name ?? "--";
            m.photo = user?.photo
        }

        return (
            <Tooltip key={m.role} title={<span style={{ whiteSpace: 'pre-line' }}>{m.role}<br/>{m.name}</span>} >
                <Avatar className={avatarClasses.medium} src={m.photo} alt={m.name} />
            </Tooltip>            
        );
    }

    if (isLoading)
        return (
            <Box display="flex" width="100%" justifyContent="center">
                <CircularProgress />
            </Box>
        );

    if (isError)
        return (
            <Box display="flex" width="100%" justifyContent="center">
                <Alert variant="outlined" severity="error">
                    {error?.message || "Could not load Account Contracts"}
                </Alert>
            </Box>
        );
    return (
        <>
            <Card className={classes.card}>
                <CardContent>
                    <Box display="flex" justifyContent="space-between">
                        <Typography variant="h6" gutterBottom display="inline">
                            Contract Summary
                        </Typography>
                        <FormGroup>
                            <FormControlLabel
                                control={<Checkbox checked={includeExpired} onChange={(e) => setIncludeExpired(e.target.checked)} />}
                                label="Show All Contracts"
                            />
                        </FormGroup>
                    </Box>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Contract</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell>Cases</TableCell>
                                    <TableCell>Start Date<br/>End Date</TableCell>                                    
                                    <TableCell>Team</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {!data?.results?.length && (
                                    <TableRow>
                                        <TableCell colSpan={6}>No results found</TableCell>
                                    </TableRow>
                                )}
                                {data?.results?.map((contract) => (
                                    <TableRow key={contract.contractId} className={classes.summaryRow} hover onClick={() => handleRowClick(contract)}>
                                        <TableCell>
                                            <Box display="flex" flexDirection="column">
                                                <Typography variant="subtitle2">{contract.coreService} <ContractProductDetail productDetails={contract.productDetails} /></Typography>
                                                <Typography variant="caption">
                                                    {contract.serviceType ? contract.serviceType + (contract.adviceType ? " | " : "") : ""}
                                                    {contract.adviceType ? contract.adviceType : ""}
                                                </Typography>
                                                <SubscriptionBox subscriptions={contract.subscriptions} />
                                            </Box>
                                        </TableCell>
                                        <TableCell>{contract.contractStatus}</TableCell>
                                        <TableCell>{contract.numberOfCases}</TableCell>
                                        <TableCell>
                                            <Typography
                                                variant="body2"
                                                className={new Date(contract.endDate).getTime() < new Date() ? classes.expiredDateRange : ""}
                                            >
                                                {formatShortMonthDate(contract.startDate)}
                                            </Typography>
                                            <br />
                                            <Typography
                                                variant="body2"
                                                className={new Date(contract.endDate).getTime() < new Date() ? classes.expiredDateRange : classes.validDateRange}
                                            >
                                                {formatShortMonthDate(contract.endDate)}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            
                                            <AvatarGroup>
                                                {contract.teamMembers.map((m) => (avatar(m)))}
                                            </AvatarGroup>                                            
                                        </TableCell>
                                        <TableCell>
                                            {contract.isCapped && (
                                                <Box display="flex" flexDirection="column">
                                                    {
                                                        <LinearProgressWithLabel
                                                            variant="determinate"
                                                            value={contract.cappedPercentage}
                                                            cappedWarning={contract.cappedWarning}
                                                        />
                                                    }
                                                </Box>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        component="div"
                        onPageChange={handleChangePage}
                        count={data?.count || 0}
                        rowsPerPage={pageSize}
                        page={pageNum}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        rowsPerPageOptions={[5, 10, 25]}
                    />
                </CardContent>
            </Card>
            <ContractSummaryDialog open={isContractDialogOpen} handleClose={handleContractDialogClose} contract={contractToView} />
        </>
    );
};

const mapStateToProps = (state) => ({ users: state.userReducer.users });

export default connect(mapStateToProps)(ContractSummaryCard);