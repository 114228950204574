import { useQuery } from "react-query";
import queryKeys from "../../constants/queryKeys";
import axios from "../../plugins/axios";

const endpointUrl = `${process.env.REACT_APP_CASENEST_API_URL}/aithread/getprompts`;

const useAIPrompts = () =>
    useQuery(queryKeys.aiPrompts, async () => {
        let response = await axios.get(endpointUrl);
        return response.data;
    }, {
        staleTime: Infinity,
    });

export default useAIPrompts;
