import axios from "../plugins/axios";

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

const getCaseContracts = async (caseId) => {
    try {
        const response = await axios.get(
            `${apiUrl}/getcasecontracts/${caseId}`
        );
        return response.data;
    } catch (error) {
        throw error;
    }
};

const addCaseContract = async (contract, caseId) => {
    try {
        const response = await axios.post(
            `${apiUrl}/addcasecontract/${caseId}`,
            contract
        );
        return response.data;
    } catch (error) {
        throw error;
    }
};

const setCurrentCaseContract = async (newContractId, caseId) => {
    try {
        const response = await axios.post(
            `${apiUrl}/setcurrentcasecontract/${newContractId}/${caseId}`
        );
        return response.data;
    } catch (error) {
        throw error;
    }
};

export { getCaseContracts, addCaseContract, setCurrentCaseContract };
