import React, { useMemo, useState } from "react";
import { useSelector, connect } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Moment from "react-moment";
import chipStyles from "../theme/chips";
import CreateCase from "../pages/CreateCase";
import {
    Button, Button as MuiIconButton,
    CardContent,
    Card as MuiCard,
    Chip,
    Grid,
    Tooltip,
    Typography,
    CardHeader,
    Box,
    makeStyles,
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";

import { spacing } from "@material-ui/system";

import { Edit as EditIcon } from "@material-ui/icons";

import { Controls } from "../helpers/Controls";
import { bindActionCreators } from "redux";
import { actionCreators } from "../redux/reducers/accountReducer";

const Card = styled(MuiCard)(spacing);

const useStyles = makeStyles((theme) => ({
    cardHeader: {
        backgroundColor: theme.palette.secondary.main,
        color: "white",
    },
}));

const IconButton = styled(MuiIconButton)`
    padding: 2px;
    margin-top: -2px;
    margin-left: 2px;
    min-width: 0;
    color: ${grey[700]};
`;

const OrganisationCard = ({ getAccount }) => {
    const { currentCaseId: caseId, cases } = useSelector((state) => state.caseReducer);
    const configState = useSelector((state) => state.configReducer);

    const c = cases[caseId];
    const account = c.account;    
    const history = useHistory(); 

    const classes = useStyles();
    const chipClasses = chipStyles();
    const [openPopup, setOpenPopup] = useState(false);
    const [popupTitleText, setPopupTitleText] = useState("Add New Contact");

    const sector = useMemo(() => {
        var sector = configState.sectors[account.sectorId];
        return sector ? sector.name : "";
    }, [configState.sectors, account.sectorId]);

    const handleAccountNameClick = async (account) => {
        await getAccount(account.accountId, history);
    };

    const tableData = {
        Client: <Button onClick={() => handleAccountNameClick(account)}>{account.name}</Button>,
        Address: account.address,
        Sector: sector,
        "Service Type": account.coreService,
        "Client Manager": account.clientManager,
    };

    const handleEditCaseAccount = (account) => {
        setPopupTitleText("Edit Existing Account Allocation");
        setOpenPopup(true);
    };

    if (account.contractEnd) {
        tableData["Contract End"] = <Moment format="DD/MM/YYYY">{account.contractEnd}</Moment>;
    }

    const table = Object.keys(tableData).map((key, index) => (
        <Grid key={index} container style={{ marginBottom: "16px", marginTop: "16px" }}>
            <Grid item style={{ paddingRight: "16px" }} xs={6} md={12} lg={6}>
                <Typography variant="body2">{key}</Typography>
            </Grid>
            <Grid item style={{ paddingRight: "16px" }} xs={6} md={12} lg={6}>
                <Typography style={{ whiteSpace: key === "Address" ? "pre-wrap" : "" }} variant="subtitle2">
                    {tableData[key]}
                </Typography>
            </Grid>
        </Grid>
    ));

    return (
        <React.Fragment>
            <Card mb={6}>
                {account.partnerships.length ? (
                    <CardHeader
                        className={classes.cardHeader}
                        disableTypography
                        title={
                            <Box display="flex" flexDirection="column" alignItems="center">
                                {account.partnerships.map((p) => (
                                    <Typography key={p.partnershipMemberId} variant="subtitle1">
                                        {p.partnershipName + (p.partnershipNumber ? " (" + p.partnershipNumber + ")" : "")}
                                    </Typography>
                                ))}
                            </Box>
                        }
                    />
                ) : (
                    <div></div>
                )}
                <CardContent>
                    <Grid container justifyContent="space-between">
                        <Grid item>
                            <Typography variant="h6" gutterBottom>
                                Account
                            </Typography>
                        </Grid>
                        {account.isDeleted && (
                            <Grid item>
                                <Typography variant="h6" gutterBottom>
                                    *Deleted*
                                </Typography>
                            </Grid>
                        )}

                        <Grid item>
                            <Chip
                                size="small"
                                className={
                                    account.status === "Active"
                                        ? chipClasses.green
                                        : account.status === "Pending"
                                            ? chipClasses.amber
                                            : chipClasses.root
                                }
                                label={account.status || "N/A"}
                            />
                        </Grid>
                        <Tooltip title="Edit Case Account">
                            <IconButton onClick={() => handleEditCaseAccount(account)} data-cy="edit_account">
                                <EditIcon />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    <Grid container>
                        <Grid item xs={7} sm={6} md={12}>
                            {table}
                        </Grid>
                    </Grid>
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <img src="/static/img/brands/salesforce.png" height="42px" width="60px" alt="salesforce" />
                    </div>
                </CardContent>
            </Card>
            <Controls.PopupControl title={popupTitleText} openPopup={openPopup} setOpenPopup={setOpenPopup}>
                <CreateCase reallocateAccountCase={true} existingCaseTypeId={c.caseSummary.caseTypeId} existingCaseId={c.caseSummary.caseId} existingAdviceTypeId={c.caseSummary.adviceTypeId} setOpenPopup={setOpenPopup} />
            </Controls.PopupControl>
        </React.Fragment>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(actionCreators, dispatch),
    };
};

export default connect(null, mapDispatchToProps)(OrganisationCard);
