import React, {useState} from "react";
import {
  Button,
  CircularProgress, Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle, makeStyles, MenuItem,
  TextField
} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import userActivityService from "../../services/userActivityService";
import {setSnackAction} from "../../redux/actions/snackActions";

const useStyles = makeStyles(theme => ({
  textField: {
    margin: theme.spacing(2, 0)
  }
}));

const ConfirmSubmitDialog = ({ open, handleClose, userId, handleReset }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [description, setDescription] = useState("");
  const [userActivityTypeId, setUserActivityTypeId] = useState("");
  const [saving, setSaving] = useState(false);
  const { userActivityTypes } = useSelector(state => state.configReducer);
  
  const handleClickClose = () => {
    setDescription("");
    setUserActivityTypeId("");
    handleClose();
  }
  
  const handleClickSubmit = async () => {
    try {
      setSaving(true);
      await userActivityService.add({ userId, userActivityTypeId, description });
      dispatch(setSnackAction("Time successfully submitted", "success"));
      handleReset();
      handleClickClose();
    }
    catch (e) {
      console.error(e);
      dispatch(setSnackAction(e?.message || "There was an error submitting the time", "error"));
    }
    finally {
      setSaving(false);
    }
  }
  
  return (
    <Dialog open={open} onClose={handleClickClose}>
      <DialogTitle>Submit Time</DialogTitle>
      <DialogContent>
        <DialogContentText>Please select a type and add a description for the activity</DialogContentText>
        <TextField
          className={classes.textField}
          fullWidth
          label="Description"
          value={description}
          onChange={e => setDescription(e.target.value)}
          variant="outlined"
        />
        <TextField
          className={classes.textField}
          fullWidth
          select
          value={userActivityTypeId}
          onChange={e => setUserActivityTypeId(e.target.value)}
          label="Type"
          variant="outlined"
        >
          {Object.values(userActivityTypes).map(t =>
            <MenuItem value={t.userActivityTypeId} key={t.userActivityTypeId}>{t.description}</MenuItem>
          )}
        </TextField>
      </DialogContent>
      <DialogActions>
        {saving ? <CircularProgress /> : <React.Fragment>
          <Button onClick={handleClose} color="primary">Cancel</Button>
          <Button
            onClick={handleClickSubmit}
            color="secondary"
            disabled={!description.length || !userActivityTypeId}
          >
            Submit Time
          </Button>
        </React.Fragment>}
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmSubmitDialog;