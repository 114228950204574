import React from 'react';
import { Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import {
    CheckCircleOutlineOutlined as SuccessIcon,
    ErrorOutlineOutlined as ErrorIcon,
    InfoOutlined as InfoIcon,
    WarningOutlined as WarningIcon,
} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
    error: {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.error.contrastText,
    },
    info: {
        borderRadius: '3px',
        backgroundColor: 'rgb(229, 246, 253)',
        padding: '6px 4px',
        color: 'rgb(1,67,97)',
        fontWeight: 500,
        fontSize: '.875rem',
    },
    warning: {
        backgroundColor: theme.palette.warning.main,
        color: theme.palette.warning.contrastText,
    },
    success: {
        borderRadius: '3px',
        backgroundColor: 'rgb(237, 247, 237)',
        padding: '6px 4px',
        color: 'rgb(30, 70, 32)',
        fontWeight: 500,
        fontSize: '.875rem',
    },
    icon: {
        fontSize: '22px',
    },
    infoIcon: {
      //color: '#0288d1',
      color: 'rgb(2, 136, 209)',
    },
    successIcon: {
      color: 'rgb(30, 70, 32)',
    }
}));

const StatusChip = ({variant, label}) => {
    
    const classes = useStyles();

    const getStatusData = () => {
        switch (variant) {
          case "error":
            return {
              className: classes.error,
              icon: <ErrorIcon className={classes.icon} />,
            };
          case "info":
            return {
              className: classes.info,
              icon: <InfoIcon className={`${classes.icon} ${classes.infoIcon}`} />
            };
          case "warning":
            return {
              className: classes.warning,
              icon: <WarningIcon className={classes.icon} />,
            };
          case "success":
            return {
              className: classes.success,
              icon: <SuccessIcon className={`${classes.icon} ${classes.successIcon}`} />,
            };
          default:
            return {
              className: "",
              icon: null,
            };
        }
      };

    const { className, icon } = getStatusData();

    return (
        <Chip
            className={className}
            icon={icon}
            label={label} 
        />
    );
};

export default StatusChip;

