import {uploadStatus} from "../constants/uploadStatus";

const buildSaveDraftEmailRequest = ({ caseId, content, reply, draftId }) => ({
    caseId,
    draftEmailId: draftId,
    content,
    replyContactsJson: reply.contacts ? JSON.stringify(reply.contacts) : null,
    replyContactsNotKnownJson: reply.contactsNotKnown
        ? JSON.stringify(reply.contactsNotKnown)
        : null,
    subject: reply.subject,
    ccContactsJson: reply.ccContacts
        ? JSON.stringify(reply.ccContacts)
        : null,
    ccNonContactsJson: reply.ccNonContacts
        ? JSON.stringify(reply.ccNonContacts)
        : null,
    ccUserIdsJson: reply.ccUserIds ? JSON.stringify(reply.ccUserIds) : null,
    description: reply.description,
    precedents: reply.precedents.map((p) => ({
        precedentId: p.precedentId,
        name: p.name,
        htmlContent: p.htmlContent,
    })),
    attachments: reply.attachments?.filter(x => x.status === uploadStatus.COMPLETE || x.status === undefined),
    azureFileIds: reply.caseAttachmentsSelected.map((x) => x.azureFileId),
    liveCaseDocumentIds: reply.draftAttachmentsSelected.map((x) => x.id),
});

export default buildSaveDraftEmailRequest;
