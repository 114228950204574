import { useQuery } from "react-query";
import queryKeys from "../../constants/queryKeys";
import caseOutcomeService from "../../services/caseOutcomeService";

const useCaseOutcomes = ({ isEt, forDropdown }) =>
    useQuery(
        [queryKeys.caseOutcomes, isEt, forDropdown],
        () => caseOutcomeService.getCaseOutcomes(isEt, forDropdown),
        {
            staleTime: Infinity,
        }
    );

export default useCaseOutcomes;
