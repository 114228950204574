import Timer from "react-compound-timer";
import React from "react";
import UserTimer from "./UserTimer";

const UserTimerHOC = () => {
  return (
    <Timer
      initialTime={0}
      startImmediately={false}
    >
      {({ start, stop, reset, getTimerState, getTime, setTime }) =>
        <UserTimer
          start={start}
          stop={stop}
          reset={reset}
          getTime={getTime}
          getTimerState={getTimerState}
          setTime={setTime}
        />
      }
    </Timer>
  );
}

export default UserTimerHOC;