import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { grey } from "@material-ui/core/colors";
import { setSnackAction } from "../redux/actions/snackActions";
import axios from "../plugins/axios";
import { format } from "date-fns";
import {
    CardContent,
    Card,
    CardActions,
    Divider,
    Grid,
    Chip,
    Button as MuiIconButton,
    Typography,
    CircularProgress,
    FormControl,
    Select,
    MenuItem,
    Tooltip,
    makeStyles,
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { Edit as EditIcon, Replay as UndoIcon, Info as MuiInfoIcon, StarBorder as StarBorderIcon, Star as StarIcon } from "@material-ui/icons";
import chipStyles from "../theme/chips";
import { setCaseTypeAndStage, setCaseClosed, addCaseEventAction, setCasePinned } from "../redux/actions/caseActions";
import CaseContractDialog from "../components/CaseContractDialog";
import useCaseOutcomes from "../hooks/queries/useCaseOutcomes";
import MarkCaseClosedDialog from "../components/dialogs/MarkCaseClosedDialog";
import caseService from "../services/caseService";
import OpenClosedCaseDialog from "../components/dialogs/OpenClosedCaseDialog";
import { getDstCorrectedDateString } from "../helpers/dateHelpers";

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

const useStyles = makeStyles((theme) => ({
    card: {
        marginBottom: theme.spacing(6),
    },
    gridContainer: {
        margin: theme.spacing(4, 4),
    },
    gridItem: {
        paddingRight: theme.spacing(4),
    },
    pin: {
        fill: "#5c45c7",
    },
    pinnedButton: {
        display: "inline-block",
        borderRadius: "50%",
        minWidth: "20px",
        minHeight: "20px",
        padding: "5px",
        textAlign: "center",
        lineHeight: 1,
        boxSizing: "content-box",
        whiteSpace: "nowrap",
        marginTop: "-5px",
        marginLeft: "6px",
        "&:before": {
            content: "",
            display: "inline-block",
            verticalAlign: "middle",
            paddingTop: "100%",
            height: "0",
        },
        "& span": {
            display: "inline-block",
            verticalAlign: "middle",
        },
    },
    iconButton: {
        padding: "2px",
        marginTop: "-2px",
        marginLeft: "2px",
        minWidth: 0,
        color: grey[700],
    },
    infoIcon: {
        marginTop: "-10px",
        fontSize: "1em",
        cursor: "pointer",
        color: grey[700],
    },
}));

function CaseDetailsCard() {
    const dispatch = useDispatch();
    const { currentCaseId: caseId, cases } = useSelector((state) => state.caseReducer);
    const configState = useSelector((state) => state.configReducer);
    const { userProfile, users } = useSelector((state) => state.userReducer);
    const adviserId = userProfile.userId;

    const c = cases[caseId].caseSummary;
 
    const account = cases[caseId].account;
    const totalActivityTime = cases[caseId].totalActivityTime;

    const outcomes = useCaseOutcomes({ isEt: c.isET });
    const [caseClosing, setCaseClosing] = useState(false);
    const [confirmCloseOpen, setConfirmCloseOpen] = useState(false);
    const [confirmOpenOpen, setConfirmOpenOpen] = useState(false);

    const chipClasses = chipStyles();
    const classes = useStyles();

    const [mode, setMode] = useState("view");
    const [caseTypeId, setCaseTypeId] = useState(c.caseTypeId);
    const [caseStageId, setCaseStageId] = useState(c.currentStageId);
    const [contractId, setContractId] = useState(c.contractId);

    // Contract dialog
    const [contractDialogOpen, setContractDialogOpen] = useState(false);

    const handleCloseContractDialog = () => {
        setContractDialogOpen(false);
    };

    const getCaseType = (id) => configState.caseTypes[id];

    const getCaseStage = (id) => getCaseType(caseTypeId).stages.filter((cs) => cs.caseStageId === id)[0] || {};

    const handleAddPin = async (e) => {
        e.stopPropagation();
        try {
            await caseService.addPinnedCase(c.caseId);
            dispatch(
                setCasePinned({
                    caseId: c.caseId,
                    isPinned: !c.isPinned,
                })
            );
        } catch (e) {
            dispatch(setSnackAction("Pin add failed", "error"));
        }
    };

    const handleDeletePin = async (e) => {
        e.stopPropagation();
        try {
            await caseService.deletePinnedCase(c.caseId);
            dispatch(
                setCasePinned({
                    caseId: c.caseId,
                    isPinned: !c.isPinned,
                })
            );
        } catch (e) {
            dispatch(setSnackAction("Pin remove failed", "error"));
        }
    };

    const handleCaseTypeChange = async (e) => {
        const caseTypeIdA = e.target.value;
        const caseStageIdA = configState.caseTypes[caseTypeIdA].stages[0].caseStageId;
        const isET = configState.caseTypes[caseTypeIdA].isET;
        let response = await axios.post(`${apiUrl}/setCaseType`, {
            caseId: c.caseId,
            caseTypeId: caseTypeIdA,
            currentStageId: caseStageIdA,
        });

        if (response.status === 200) {
            dispatch(setCaseTypeAndStage(c.caseId, caseTypeIdA, caseStageIdA, isET));
            dispatch(setSnackAction("Saved!", "success"));
            setMode("view");
        }
    };
    const handleCaseStageChange = async (e) => {
        const caseStageIdA = e.target.value;
        const isET = configState.caseTypes[c.caseTypeId].isET;

        if (!configState.caseTypes[c.caseTypeId].stages.filter((cs) => cs.caseStageId === c.currentStageId).length > 0) {
            dispatch(
                setSnackAction(
                    "Can't change to this stage at the moment. Please try changing to a new case type, changing back to this case type then trying again",
                    "error"
                )
            );
            return;
        }

        if (
            configState.caseTypes[c.caseTypeId].stages
                .filter((cs) => cs.caseStageId === c.currentStageId)[0]
                .stageTasks.filter((t) => !t.disabled)
                .filter((t) => !c.completedStageTasks.includes(t.stageTaskId)).length
        ) {
            dispatch(setSnackAction("Please complete all tasks before changing stage", "warning"));
            return;
        }

        let response = await axios.post(`${apiUrl}/setCaseType`, {
            caseId: c.caseId,
            caseTypeId: c.caseTypeId,
            currentStageId: caseStageIdA,
        });

        if (response.status === 200) {
            dispatch(setCaseTypeAndStage(c.caseId, c.caseTypeId, caseStageIdA, isET));
            dispatch(setSnackAction("Saved!", "success"));
            setMode("view");
        }
    };

    const handleReset = () => {
        setMode("view");
        setCaseTypeId(c.caseTypeId);
        setCaseStageId(c.currentStageId);
    };
    

    const contractInfo = (id) => {
        let contract = account.contracts.find((c) => c.contractId === id);

        if (!contract)
            return "";

        let coreService = contract.coreService || "Not set";

        if (contract.isPartnership)
            coreService += " (Partnership)";
        else
            coreService += " (Account)";

        return (
            <React.Fragment>
                {coreService}
                {contract.summary && (
                    <Tooltip title={contract.summary}>
                        <MuiInfoIcon className={classes.infoIcon} />
                    </Tooltip>
                )}
            </React.Fragment>
        );
    };

    const availableCaseTypes = useMemo(() => Object.values(configState.caseTypes)
        .filter(ct => ct.isActive
            && (c.adviceTypeId === ct.adviceTypeId || c.adviceTypeId === ct.additionalAdviceTypeId)
            && ct.stages.length > 0
            && (ct.isAccountSpecific ? ct.accounts.map((a) => a.accountId).includes(account.accountId) : true))
        .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0)), [account.accountId, c.adviceTypeId, configState.caseTypes]);
               
    const tableData = {
        "Case Type":
            mode === "edit" ? (
                <FormControl style={{ marginTop: "-8px" }}>
                    <Select value={caseTypeId} onChange={handleCaseTypeChange} style={{ width: "150px" }} data-cy="case_type_select">
                        {availableCaseTypes
                            
                            .map((ct) => (
                                <MenuItem key={ct.caseTypeId} value={ct.caseTypeId} data-cy={`case_type_${ct.caseTypeId}`}>
                                    {ct.name}
                                </MenuItem>
                            ))}
                    </Select>
                </FormControl>
            ) : (
                getCaseType(caseTypeId) && getCaseType(caseTypeId).name
            ),
        "Current Stage":
            mode === "edit" ? (
                <FormControl style={{ marginTop: "-8px" }} error={caseStageId === ""}>
                    <Select value={caseStageId} onChange={handleCaseStageChange} style={{ width: "150px" }} data-cy="stage_select">
                        {getCaseType(caseTypeId)
                            .stages.filter((cs) => !cs.disabled)
                            .map((cs) => (
                                <MenuItem key={cs.caseStageId} value={cs.caseStageId}>
                                    {cs.name}
                                </MenuItem>
                            ))}
                    </Select>
                </FormControl>
            ) : (
                getCaseType(caseTypeId) && getCaseStage(caseStageId).name
            ),
        Created: format(new Date(c.dateCreated), "dd/MM/yyyy"),
        "Created By": c.createdBy,
        Contract:
            mode === "edit" ? (
                <Box display="flex">
                    <div>{contractInfo(contractId)}</div>
                    <MuiIconButton onClick={() => setContractDialogOpen(true)} className={classes.iconButton} style={{ alignSelf: "flex-start" }}>
                        <EditIcon />
                    </MuiIconButton>
                </Box>
            ) : (
                contractInfo(contractId)
            ),
    };

    const table = (data) =>
        Object.keys(data).map((key, index) => (
            <Grid key={index} container className={classes.gridContainer}>
                <Grid item className={classes.gridItem} xs={6} md={12} lg={5}>
                    <Typography variant="body2">{key}</Typography>
                </Grid>
                <Grid item className={classes.gridItem} xs={6} md={12} lg={7} data-cy={key.replace(/\s/g, "_").toLowerCase()}>
                    <Typography variant="subtitle2">{data[key]}</Typography>
                </Grid>
            </Grid>
        ));

    const handleUpdateCloseCase = async ({ caseOutcomeId, closureDate }) => {
        try {
            setCaseClosing(true);
            await caseService.updateCaseClosed({
                caseId: c.caseId,
                caseOutcomeId,
                closureDate: closureDate == null ? null : getDstCorrectedDateString(closureDate),
                adviserId,
            });
            dispatch(
                setCaseClosed({
                    caseId: c.caseId,
                    caseOutcomeId,
                    closureDate,
                })
            );
            if (closureDate != null)
                dispatch(
                    addCaseEventAction(c.caseId, {
                        adviserId,
                        itemType: "Outcome",
                        eventTime: closureDate,
                        text: outcomes?.data?.find((x) => x.id === caseOutcomeId)?.description || "N/A",
                        direction: 2,
                        person: users[adviserId].name,
                    })
                );
            dispatch(setSnackAction("Saved!", "success"));
            setConfirmCloseOpen(false);
            setConfirmOpenOpen(false);
        } catch (e) {
            dispatch(setSnackAction(e?.message || `There was an error ${closureDate ? "closing" : "opening"} the case`, "error"));
        } finally {
            setCaseClosing(false);
        }
    };

    useEffect(() => {
        setMode("view");
        setCaseTypeId(c.caseTypeId);
        setCaseStageId(c.currentStageId);
        setContractId(c.contractId);
    }, [c]);

    return (
        <Card className={classes.card}>
            <CardContent data-cy="case_summary_card">
                <Box display="flex">
                    <Box flexGrow={1} alignItems="center" data-cy="case_id_title">
                        <Typography variant="h6" gutterBottom>
                            {"Case #" + c.caseId}{" "}
                        </Typography>
                    </Box>
                    <Box>
                        {/* Get open/closed status from state? */}
                        <Chip size="small" className={c.dateClosed ? chipClasses.red : chipClasses.green} label={c.dateClosed ? "Closed" : "Open"} />
                    </Box>
                    <Box>
                        {c.isPinned ? (
                            <MuiIconButton className={classes.pinnedButton} onClick={(e) => handleDeletePin(e)}>
                                <StarIcon className={classes.pin} />
                            </MuiIconButton>
                        ) : (
                            <MuiIconButton className={classes.pinnedButton} onClick={(e) => handleAddPin(e)}>
                                <StarBorderIcon />
                            </MuiIconButton>
                        )}
                    </Box>
                    <Box>
                        {mode === "view" && !c.dateClosed && (
                            <MuiIconButton className={classes.iconButton} onClick={() => setMode("edit")} data-cy="edit_case">
                                <EditIcon />
                            </MuiIconButton>
                        )}
                        {mode === "edit" && (
                            <MuiIconButton className={classes.iconButton} onClick={handleReset}>
                                <UndoIcon />
                            </MuiIconButton>
                        )}
                        {mode === "saving" && <CircularProgress size={21} style={{ marginLeft: "9px" }} />}
                    </Box>
                </Box>
                <Grid container>
                    <Grid item xs={5} sm={3} md={12}>
                        {table(tableData)}
                    </Grid>
                </Grid>
                <Divider variant="middle" />
                {!!c.dateClosed && (
                    <Grid container>
                        <Grid item xs={5} sm={3} md={12}>
                            <Grid container className={classes.gridContainer}>
                                <Grid item className={classes.gridItem} xs={6} md={12} lg={5}>
                                    <Typography variant="body2">Closed</Typography>
                                </Grid>
                                <Grid item className={classes.gridItem} xs={6} md={12} lg={7}>
                                    <Typography variant="subtitle2">{format(new Date(c.dateClosed), "dd/MM/yyyy")}</Typography>
                                </Grid>
                            </Grid>
                            <Grid container className={classes.gridContainer}>
                                <Grid item className={classes.gridItem} xs={6} md={12} lg={5}>
                                    <Typography variant="body2">Outcome</Typography>
                                </Grid>
                                <Grid item className={classes.gridItem} xs={6} md={12} lg={7}>
                                    <Typography variant="subtitle2">{outcomes?.data?.find((x) => x.id === c.caseOutcomeId)?.description || "N/A"}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
                <CardActions style={{ display: "flex", justifyContent: "space-around" }}>
                    <MuiIconButton
                        variant="contained"
                        color="primary"
                        onClick={c.dateClosed ? () => setConfirmOpenOpen(true) : () => setConfirmCloseOpen(true)}
                        style={{ margin: "10px" }}
                        data-cy="close_reopen_case"
                    >
                        {c.dateClosed ? "Reopen Case" : "Close Case"}
                    </MuiIconButton>
                    <MarkCaseClosedDialog
                        open={confirmCloseOpen}
                        isEt={c.isET}
                        eTCaseOutcomeId={c.caseOutcomeId}
                        handleClose={() => setConfirmCloseOpen(false)}
                        handleCloseCase={handleUpdateCloseCase}
                        loading={caseClosing}
                    />
                    <OpenClosedCaseDialog
                        open={confirmOpenOpen}
                        handleClose={() => setConfirmOpenOpen(false)}
                        handleConfirm={() =>
                            handleUpdateCloseCase({
                                caseOutcomeId: null,
                                closureDate: null,
                            })
                        }
                        loading={caseClosing}
                    />
                </CardActions>
            </CardContent>
            <CaseContractDialog
                open={contractDialogOpen}
                handleClose={handleCloseContractDialog}
                caseId={c.caseId}
                contractId={c.contractId}
                accountContracts={account.contracts}
                dateCreated={c.dateCreated}
                totalActivityTime={totalActivityTime}
            />
        </Card>
    );
}

export default CaseDetailsCard;
