import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import useRecommendationPickLists from "../hooks/queries/useRecommendationPickLists";
import productService from "../services/productService";
import axios from "../plugins/axios";
import {
    Button,
    Typography,
    Grid,
    Box,
    Menu,
    MenuItem,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Select,
    TextField,
    OutlinedInput,
    FormControl,
    InputLabel,
} from "@material-ui/core";
import { Controls } from "../helpers/Controls";
import AccountContactMenuItem from "../components/AccountContactMenuItem";
import {
    ThumbDownOutlined as ThumbDownIcon,
    ThumbUpOutlined as ThumbUpIcon,
    Block as BlockIcon,
} from "@material-ui/icons";
import CreateContact from "../pages/CreateContact";
const useStyles = makeStyles((theme) => ({
    recommendationPanel: {
        marginTop: theme.spacing(3),
        backgroundColor: '#e7f2ff',
        padding: theme.spacing(3),
        borderRadius: '5px',
        height: '100%',
        '& button': {
            color: '#006398'
        },
        '& h6': {
            marginBottom: '10px'
        }
    },
    addContactButton: {
        backgroundColor: theme.palette.secondary.main,
        color: "white",
        textAlign: "center",
        "&:hover, &:focus": {
            backgroundColor: theme.palette.primary.main,
        },
    },
}));

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

const ProductRecommendationsPanel = ({ product, isSingle, refresh, account, id }) => {

    const { data: recommendationPickLists } = useRecommendationPickLists();
    const [contactAnchorEl, setContactAnchorEl] = useState();
    const [status, setStatus] = useState();
    const [saving, setSaving] = useState(false);
    const [contact, setContact] = useState();
    const [accountContacts, setAccountContacts] = useState([]);
    const [outcome, setOutcome] = useState();
    const [notes, setNotes] = useState();
    const [newContactDialogOpen, setNewContactDialogOpen] = useState(false);

    const classes = useStyles();

    const save = async () => {
        setSaving(true);

        await productService.saveRecommendation({
            id,
            status,
            outcome,
            notes,
            contactId: contact?.externalId
        });

        refresh();

        closeDialog();
    }

    const choose = e => {
        setStatus(e.target.innerText);
        getAccountContacts();
    }

    const getAccountContacts = async () => {
        var response = await axios.get(`${apiUrl}/getaccountcontacts/${account.accountId}`);
        setAccountContacts(response.data);
    };

    const handleChooseContact = (contact) => {
        setContact(contact);
        setContactAnchorEl(null);        
    }    

    const closeDialog = () => {
        setStatus();
        setContact();
        setOutcome();
        setNotes();
    }

    const contactAdded = () => {
        getAccountContacts();
        setNewContactDialogOpen(false);
    }

    const createLink = async (contactId) => {
        await axios.post(`${apiUrl}/createaccountcontactlink`, {
            contactId: contactId,
            accountId: account.accountId,
        });

        window.frames[0].postMessage({ event: "refresh" }, "*");
        setNewContactDialogOpen(false);
        getAccountContacts();
    };

    return (
        <Grid item xs={isSingle ? 12 : 6}>
            <div className={classes.recommendationPanel}>
                <Box display="flex" spacing={3} style={{ minHeight: '100%', flexDirection: (isSingle ? "row" : "column") }}>
                    <Box xs={isSingle ? 6 : 12} style={{ flexGrow: 1, flexBasis: '50%' }}>
                        <Typography variant="h6">{product.productName}</Typography>
                        <div dangerouslySetInnerHTML={{ __html: product.productInfo }} />
                    </Box>
                    <Box xs={isSingle ? 6 : 12} style={{ flexBasis: '50%', minHeight: '100%' }}>
                        <Box display="flex" flexDirection="column" gridGap="5px" style={{ minHeight: '100%' }}>
                            <div style={{ flexGrow: 1 }}></div>
                            <Button
                                variant="contained"
                                disabled={saving}
                                startIcon={<ThumbUpIcon />}
                                style={{ backgroundColor: '#006398', color: '#e7f2ff' }}
                                onClick={choose}
                            >
                                Interested
                            </Button>
                            <Button
                                variant="outlined"
                                disabled={saving}
                                startIcon={<ThumbDownIcon />}
                                onClick={choose}
                            >
                                Not Interested
                            </Button>
                            <Button
                                variant="outlined"
                                disabled={saving}
                                startIcon={<BlockIcon />}
                                onClick={choose}
                            >                                
                                No Opportunity
                            </Button>
                            <div style={{ flexGrow: 1 }}></div>
                        </Box>
                    </Box>
                </Box>
            </div>
            <Dialog
                open={!!status}
                onClose={closeDialog}
            >
                <DialogTitle>
                    Select Contact
                </DialogTitle>
                <DialogContent>
                    <Box display="flex" flexDirection="column" gridGap="10px" style={{ width: '300px' }}>
                        <FormControl variant="outlined">
                            <InputLabel shrink>{status === "Interested" ? "Hot/Warm" : "Reason"}</InputLabel>
                            <Select
                                label={status === "Interested" ? "Hot/Warm" : "Reason"}
                                disabled={saving}
                                value={outcome || ""}
                                onChange={(e) => setOutcome(e.target.value)}
                                fullWidth
                                input={<OutlinedInput notched label={status === "Interested" ? "Hot/Warm" : "Reason"} />}
                            >
                                {recommendationPickLists && recommendationPickLists[status]?.map(x => <MenuItem value={x} onClick={() => setOutcome(x)} key={x}> {x}</MenuItem>)}
                            </Select>
                        </FormControl>
                        <TextField
                            variant="outlined"
                            disabled={saving}
                            InputLabelProps={{ shrink: true }}
                            label="Notes"
                            multiline
                            maxRows={4}
                            onChange={(e) => setNotes(e.target.value)}
                            value={notes || ""}
                        />

                        {status === "Interested" &&
                            <Button variant="outlined" onClick={(e) => setContactAnchorEl(e.currentTarget)}>{contact ? <AccountContactMenuItem dto={contact} /> : "Contact"}</Button>
                        }
                    </Box>
                    <Menu
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                        disabled={saving}
                        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                        getContentAnchorEl={null}
                        anchorEl={contactAnchorEl}
                        open={!!contactAnchorEl}
                        onClose={() => setContactAnchorEl()}
                    >
                        {
                            accountContacts
                                .sort((a, b) => a.name > b.name ? 1 : b.name > a.name ? -1 : 0)
                                .map(c => <AccountContactMenuItem key={c.contactId} dto={c} handleAdd={() => handleChooseContact(c)} />)
                        }
                        <MenuItem
                            className={classes.addContactButton}
                            onClick={() => setNewContactDialogOpen(true)}
                        >
                            <Typography style={{ width: "100%" }} variant="body2">Add New Contact</Typography>
                        </MenuItem>
                    </Menu>
                </DialogContent>

                <DialogActions>
                    {outcome && (notes || "").trim() &&  (status === "Interested" ? contact : true) &&
                        <Button onClick={save} color="primary">
                            Confirm
                        </Button>
                    }
                </DialogActions>
            </Dialog>
            <Controls.PopupControl
                title="Add New Contact"
                openPopup={newContactDialogOpen}
                setOpenPopup={setNewContactDialogOpen}
            >
                <CreateContact
                    createLink={createLink}
                    accountId={account.accountId}
                    accountExternalId={account.externalId}
                    contactAdded={contactAdded}                    
                />
            </Controls.PopupControl>
        </Grid>
    );
};

export default ProductRecommendationsPanel;
