import React, { useMemo, useState } from 'react';
import { connect } from "react-redux";
import {
	Card as MuiCard,
	CardContent,
	Box,
	TextField,
	Button as MuiIconButton,
	CircularProgress,
	Tab as MuiTab,
	Tabs,
} from "@material-ui/core";
import styled from "styled-components";
import { spacing } from "@material-ui/system";
import {
	Save as SaveIcon
} from "@material-ui/icons";
import { grey } from "@material-ui/core/colors";
import useAdviceTypes from "../../hooks/queries/useAdviceTypes";
import useAdviceTypesForUser from "../../hooks/queries/useAdviceTypesForUser";
import useAccountContracts from "../../hooks/queries/useAccountContracts";
import {
	setAccountNote,
	setAccountNoteEdited,
} from "../../redux/actions/accountActions";
import { setSnackAction } from "../../redux/actions/snackActions";
import axios from "../../plugins/axios";
import TabPanel from "../../components/TabPanel";

const Tab = styled(MuiTab)`
    min-width: 0;
`;

const IconButton = styled(MuiIconButton)`
    padding: 2px;
    margin-top: -2px;
    margin-left: 2px;
    min-width: 0;
    color: ${grey[700]};
`;

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

const Card = styled(MuiCard)(spacing);

const ClientNotesCard = ({ setSnack, setAccountNote, setAccountNoteEdited, accountState, userId }) => {

	const { data: userAdviceTypes } = useAdviceTypesForUser(userId);
	const { data: adviceTypes } = useAdviceTypes();
	const { data: contracts } = useAccountContracts(accountState.currentAccountId, true);

	const [saving, setSaving] = useState({});

	const accountId = accountState.currentAccountId;
	const account = accountState.accounts[accountId];
	const notesEdited = account.notesEdited;
	const notes = account.notes;

	const [currentTab, setCurrentTab] = useState();	

	const handleNoteChange = (text, adviceTypeId) => {
		if (!notesEdited[adviceTypeId])
			setAccountNoteEdited(true, accountId, adviceTypeId);

		setAccountNote(text, accountId, adviceTypeId);
	};

	const allowedAdviceTypes = useMemo(() => {
		if (!userAdviceTypes || !adviceTypes || !contracts)
			return [];

		let allowed = adviceTypes.filter(at => userAdviceTypes.includes(at.id) && contracts.some(c => c.adviceTypeId === at.id));

		setCurrentTab(allowed[0]?.id);

		return allowed

	}, [adviceTypes, contracts, userAdviceTypes, setCurrentTab]);	

	const saveNote = async (adviceTypeId) => {
		setSaving({...saving, [adviceTypeId]: true});
		let response = await axios.post(`${apiUrl}/updateaccountnote`, {
			accountId,
			noteText: notes[adviceTypeId],
			adviceTypeId,
		});
		if (response.status === 200) {
			setSnack("Successfully saved note!", "success");
		} else {
			setSnack("Failed to save note", "error");
		}
		setSaving({ ...saving, [adviceTypeId]: false });
		setAccountNoteEdited(false, accountId, adviceTypeId);
	};

	return (
		<Card>
			<CardContent>
				{allowedAdviceTypes.length > 1 && (
					<Tabs value={currentTab} onChange={(e, x) => setCurrentTab(x)} style={{ marginBottom: "10px" }}>
						{allowedAdviceTypes.map(at => (
							<Tab key={at.id} value={at.id} label={at.name} />
						))};			
						
					</Tabs>
				)}
				{allowedAdviceTypes.map(at => (
					<TabPanel key={at.id} value={currentTab} index={at.id} >
						<Box display="flex" justifyContent="flex-end">
							{saving[at.id] ? <CircularProgress size={21} style={{ marginLeft: '9px' }} /> : notesEdited[at.id] ? <IconButton onClick={() => saveNote(at.id)}><SaveIcon /></IconButton> : <div></div>}
						</Box>
						<TextField
							style={{ width: '100%' }}
							label="Client Notes"
							multiline
							minRows={8}
							variant="outlined"
							value={notes[at.id]}
							onChange={(e) => handleNoteChange(e.target.value, at.id)}
						/>
					</TabPanel>
				))}				
				
			</CardContent>
		</Card>
	)
}

const mapStateToProps = (state) => ({
	accountState: state.accountReducer,
	userId: state.userReducer.userProfile.userId,
});

const mapDispatchToProps = (dispatch) => ({
	setAccountNote: (text, accountId, adviceTypeId) => dispatch(setAccountNote(text, accountId, adviceTypeId)),
	setAccountNoteEdited: (edited, accountId, adviceTypeId) => dispatch(setAccountNoteEdited(edited, accountId, adviceTypeId)),
	setSnack: (message, severity) => dispatch(setSnackAction(message, severity)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClientNotesCard);

