import React from 'react';
import {
    Box,    
    makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles(() => ({
    subscriptions: {
        marginTop: '5px',
        backgroundColor: '#f2edff',
        borderRadius: '5px',
        padding: '1px 5px',
        fontSize: '0.8em'
    }
}))

const SubscriptionBox = ({ subscriptions }) => {
    const classes = useStyles();
    
    if (subscriptions.length === 0)
        return null;

    return (
        <Box className={classes.subscriptions}>
            {subscriptions.map((s) => <Box key={s.id}>{s.productName}</Box>)}
        </Box>
    );
};

export default SubscriptionBox;