import { useQuery } from "react-query";
import queryKeys from "../../constants/queryKeys";
import caseService from "../../services/caseService";

const useCaseTime = ({ caseId }) =>
    useQuery(
        [queryKeys.caseTime, caseId],
        () => caseService.getCaseTime({ caseId }),
        {
            enabled: !!caseId,
        }
    );

export default useCaseTime;
