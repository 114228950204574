import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    title: {
        fontSize: 18,
        fontWeight: 500,
    },
}));

const ReassignActivityDialog = ({ confirmOpen, handleConfirmOpen, newCaseId, handleCloseConfirmationDialog, handleReassignActivity }) => {
    const classes = useStyles();
    return (
        <Dialog open={confirmOpen} onClose={() => handleConfirmOpen(false)}>
            <DialogTitle>
                <Typography className={classes.title} color="primary" variant="body1">
                    Reassign activity?
                </Typography>
            </DialogTitle>

            <DialogContent>
                <DialogContentText>Are you sure you want to reassign this activity to case {newCaseId}?</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseConfirmationDialog} color="secondary" variant="text">
                    Cancel
                </Button>
                <Button onClick={handleReassignActivity} color="secondary" variant="text" data-cy="confirm_reassign_activity">
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ReassignActivityDialog;
