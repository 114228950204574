import axios from "../plugins/axios";

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

const addExistingContactToCase = (caseId, contactId, userId) => {
    return axios.post(
        `${apiUrl}/addexistingcontacttocase/${caseId}/${contactId}/${userId}`
    );
};

const removeContactFromCase = (caseId, contactId, userId) => {
    return axios.post(
        `${apiUrl}/removecontactfromcase/${caseId}/${contactId}/${userId}`
    );
};

const setPrimaryCaseContact = (caseId, contactId, userId) => {
    return axios.post(
        `${apiUrl}/setprimarycasecontact/${caseId}/${contactId}/${userId}`
    );
};

const caseContactService = {
    addExistingContactToCase,
    removeContactFromCase,
    setPrimaryCaseContact,
};

export default caseContactService;
