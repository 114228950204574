import { useQuery } from "react-query";
import queryKeys from "../../constants/queryKeys";
import caseService from "../../services/caseService";

const noRetryCodes = [401];

const useCaseHistoryExportStatus = ({ guid }) =>
    useQuery(
        [queryKeys.caseHistoryExport, guid],
        () => caseService.getCaseHistoryExportStatus({ guid }),
        {
            enabled: !!guid,
            retry: (_failureCount, error) => {
                if (noRetryCodes.includes(error?.response?.status))
                    return false;
                return 3;
            },
        }
    );

export default useCaseHistoryExportStatus;
