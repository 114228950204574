import { useQuery } from 'react-query';
import queryKeys from '../../constants/queryKeys';
import clientTimesheetService from '../../services/clientTimesheetService';

const useTimesheetByActivity = ({
    fromDate,
    toDate,
    accountId,
    coreServices,
    serviceTypes,
    isChargeable,
    excludeInvoiced,
    enabled,
    onSuccess,
}) =>
    useQuery(
        [queryKeys.timesheetByActivity, fromDate, toDate, accountId, coreServices, serviceTypes, isChargeable, excludeInvoiced, enabled],
        () => clientTimesheetService.getTimesheetByActivity({fromDate, toDate, accountId, coreServices, serviceTypes, isChargeable, excludeInvoiced}),
        {
            enabled: !!fromDate && !!toDate && enabled,
            onSuccess,
        }
);

export default useTimesheetByActivity;