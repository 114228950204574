import {
    alpha,
    Button,
    Divider,
    makeStyles,
    Typography,
} from "@material-ui/core";
import React from "react";
import HistoryActivityFilter from "./HistoryActivityFilter";
import HistoryChargeableFilter from "./HistoryChargeableFilter";
import HistoryDateFilter from "./HistoryDateFilter";
import HistoryDirectionFilter from "./HistoryDirectionFilter";
import HistoryUserFilter from "./HistoryUserFilter";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        padding: theme.spacing(3),
        paddingBottom: theme.spacing(10),
        border: `1px solid ${alpha(theme.palette.primary.main, 0.3)}`,
        borderRadius: 4,
        background: theme.palette.common.white,
        minWidth: 300,
        maxWidth: 300,
    },
    container: {
        width: "100%",
        display: "flex",
        alignItems: "end",
        justifyContent: "space-between",
        marginBottom: theme.spacing(3),
    },
    title: { fontSize: 18, fontWeight: 600, padding: 0, margin: 0 },
    clearAll: {
        fontSize: 15,
        fontWeight: 500,
        padding: 0,
        margin: 0,
    },
    divider: {
        width: "100%",
        margin: theme.spacing(2, 0),
    },
}));

const CaseHistoryFilter = ({
    caseId,
    dateFrom,
    dateTo,
    isLoading,
    onClearAllClick,
    onFromDateChange,
    onToDateChange,
    activitiesChecked,
    onActivityClick,
    chargeables,
    onChargeableClick,
    filters,
    directions,
    onDirectionClick,
    selectedUsers,
    onUserClick,
}) => {
    const classes = useStyles();

    const { chargeableFilters, directionFilters, userFilters } = filters || {};

    return (
        <div className={classes.root}>
            <div className={classes.container}>
                <Typography
                    variant="body1"
                    color="primary"
                    className={classes.title}
                >
                    Filter
                </Typography>
                <Button
                    variant="text"
                    color="secondary"
                    onClick={onClearAllClick}
                    className={classes.clearAll}
                >
                    Clear All
                </Button>
            </div>
            <>
                <HistoryDateFilter
                    caseId={caseId}
                    dateFrom={dateFrom}
                    dateTo={dateTo}
                    onFromDateChange={onFromDateChange}
                    onToDateChange={onToDateChange}
                />
                <Divider className={classes.divider} />
                <HistoryActivityFilter
                    activities={filters}
                    activitiesChecked={activitiesChecked}
                    onActivityClick={onActivityClick}
                    isLoading={isLoading}
                />
                <Divider className={classes.divider} />
                <HistoryUserFilter
                    users={userFilters}
                    selectedUsers={selectedUsers}
                    onUserClick={onUserClick}
                    isLoading={isLoading}
                />
                <Divider className={classes.divider} />
                <HistoryChargeableFilter
                    chargeableFilters={chargeableFilters}
                    chargeables={chargeables}
                    onChargeableClick={onChargeableClick}
                    isLoading={isLoading}
                />
                <Divider className={classes.divider} />
                <HistoryDirectionFilter
                    directions={directions}
                    directionFilters={directionFilters}
                    onDirectionClick={onDirectionClick}
                    isLoading={isLoading}
                />
            </>
        </div>
    );
};

export default CaseHistoryFilter;
