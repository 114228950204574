import axios from "../plugins/axios";
import { converterUrls } from "../constants/documentConstants";
import axios_raw from "axios";

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

const retrieveDocumentPreviewPath = async ({ reference, contentType, isForFilePreview = true, filename = null }) => {
    const isVideoContent = contentType.startsWith("video");
    const endpointUrl = isVideoContent
        ? "document/retrievevideo"
        : "document/retrieve";

    try {
        const response = await axios.get(
            `${apiUrl}/${endpointUrl}/${reference}?isForFilePreview=${isForFilePreview}&filename=${(filename || "")}`,
            {
                responseType: isVideoContent ? "stream" : "arraybuffer",
            }
        );

        if (response.status === 204)
            return null;
        
        if (isVideoContent) 
            return response.data;

        const blob = new Blob([response.data], { type: contentType });
        return window.URL.createObjectURL(blob);
    } catch (e) {
        throw new Error(e?.message || "Could not fetch document.");
    }
};

const openDocument = async (attachmentReference, isForFilePreview = true) => {
    try {
        const response = await axios.get(
            `${apiUrl}/document/retrieve/${attachmentReference}?isForFilePreview=${isForFilePreview}`,
            { responseType: "arraybuffer" }
        );
        
        if (response.status === 204)
            return null;
        
        return response.data;
    } catch (error) {
        throw error;
    }
};

const exportDocument = async ({ html, css, options, converterFileType }) => {
    return await axios_raw.post(
        converterUrls[converterFileType],
        {
            html,
            css,
            options,
        },
        { responseType: "blob" }
    );
};

const openInSharepoint = ({ blob, caseId, filename }) => {
    let formData = new FormData();
    let file = new File([blob], filename);
    formData.append("files", file);
    formData.append("caseId", caseId);

    return axios.post(
        `${apiUrl}/sharepoint/CreateSharepointDocumentFromPrecedent`,
        formData,
        {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        }
    );
};

const renameAzureFile = async (dto) => {
    try {
        const response = await axios.post(`${apiUrl}/renameAzureFile`, dto);
        return response.data;
    } catch (e) {
        console.error(e);
        return Promise.reject(e?.message || "Could not rename file.");
    }
};

const removeCaseFile = async (id) => {
    try {
        const response = await axios.delete(`${apiUrl}/removeCaseFile/${id}`);
        return response.data;
    } catch (e) {
        console.error(e);
        return Promise.reject(e?.message || "Could not remove case file.");
    }
};

const removeCaseFileFolder = async (id) => {
    try {
        const response = await axios.delete(`${apiUrl}/removeCaseFileFolder/${id}`);
        return response.data;
    } catch (e) {
        console.error(e);
        return Promise.reject(e?.message || "Could not remove folder.");
    }
}

const setCaseFileFolder = async (dto) => {
    try {
        const response = await axios.post(`${apiUrl}/setCaseFileFolder`, dto);
        return response.data;
    } catch (e) {
        console.error(e);
        return Promise.reject(e?.message || "Could not set file folder.");
    }
};

const upsertCaseFileFolder = async (dto) => {
    try {
        const response = await axios.post(`${apiUrl}/upsertCaseFileFolder`, dto);
        console.log({response})
        return response.data;
    } catch (e) {
        console.error(e);
        return Promise.reject(e?.message || "Could not rename file folder.");
    }
};

const documentService = {
    retrieveDocumentPreviewPath,
    openDocument,
    exportDocument,
    openInSharepoint,
    renameAzureFile,
    removeCaseFile,
    setCaseFileFolder,
    upsertCaseFileFolder,
    removeCaseFileFolder,
};

export default documentService;
