import React, { useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import "../vendor/perfect-scrollbar.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import { makeStyles } from "@material-ui/core/styles";
import PrecedentDialog from "./PrecedentDialog";
import { Search as SearchIcon } from "react-feather";
import {
    Typography,
    Box,
    Button as MuiIconButton,
    Collapse,
    Paper as MuiPaper,
    List,
    ListItemIcon,
    ListItem,
    ListItemText,
    TextField,
    InputAdornment,
    Avatar,
} from "@material-ui/core";
import {
    MoveToInbox as InboxIcon,
    FolderOpenRounded as FolderIcon,
    VisibilityTwoTone as PreviewIcon,
    ExpandLess,
    ExpandMore,
    ArrowBack as ArrowBackIcon,
} from "@material-ui/icons";
import { red } from "@material-ui/core/colors";
import documentService from "../services/documentService";
import { openFileViewer } from "../redux/actions/userActions";
import { useAuth } from "../contexts/authContext";
import { setSnackAction } from "../redux/actions/snackActions";
import avatarStyles from "../theme/avatars";

const IconButton = styled(MuiIconButton)`
    padding: 2px;
    margin-top: -2px;
    margin-left: 2px;
    min-width: 0;
    color: ${red[700]};
`;

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        backgroundColor: theme.palette.background.paper,
        paddingTop: 0,
        paddingBottom: 0,
    },
    nested: {
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 30,
    },
    file_nested: {
        paddingLeft: 50,
        paddingTop: 0,
        paddingBottom: 0,
    },
    sub_nested: {
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 50,
    },
    sub_file_nested: {
        paddingLeft: 70,
        paddingTop: 0,
        paddingBottom: 0,
    },
    dialog: {
        height: "calc(100% - 64px)",
    },
}));

const Paper = styled(MuiPaper)`
    padding: 10px;
    margin-bottom: 10px;
`;

const PrecedentChooser = ({ onSelect, isEmailReply = false, isAITool = false }) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const configState = useSelector(state => state.configReducer);
    const { cases, currentCaseId: caseId } = useSelector(state => state.caseReducer);
    const [precedentId, setPrecedentId] = useState(null);

    const c = cases[caseId];

    const [searchText, setSearchText] = useState("");
    const [selectedSectorIndex, setSelectedSectorIndex] = useState("");
    const [selectedCaseTypeIndex, setSelectedCaseTypeIndex] = useState("");
    const [selectedCaseTypeFolderIndex, setSelectedCaseTypeFolderIndex] = useState("");

    const { user } = useAuth();

    const avatarClasses = avatarStyles();

    const openDocument = async (attachment) => {
        try {
            const previewPath =
                await documentService.retrieveDocumentPreviewPath({
                    reference: attachment.reference,
                    contentType: attachment.contentType,
                });

            if (previewPath === null) {
                dispatch(setSnackAction("File is password protected and cannot be previewed.", "warning"));
                return;
            }

            let parsedFileName = attachment.filename.split(".");

            dispatch(
                openFileViewer(
                    {
                        type: parsedFileName[parsedFileName.length - 1],
                        path: previewPath,
                        name: attachment.filename,
                    },
                    caseId,
                    attachment
                )
            );
        } catch (e) {
            console.error(e);
        }
    };

    const usedPrecedents = useMemo(() => {
        //precedents are either docs to be filled or physical files (pdf, jog, etc).
        var totalprec = c.reply.precedents.map((p) => p.precedentId);
        var totalattach = c.reply.attachmentsLinked.map((p) => p.precedentId);

        return totalprec.concat(totalattach);
    }, [c.reply.precedents, c.reply.attachmentsLinked]);

    const filteredPrecedentTree = useMemo(() => {
        let userAdviceTypes = user.details.adviceTypes;
        let precedents = Object.values(configState.precedents)
            .filter((p) => !p.disabled && (userAdviceTypes.includes(p.adviceTypeId) || userAdviceTypes.includes(p.additionalAdviceTypeId)) && p.isAdvisor)
            .sort((s1, s2) => s1.sortIndex - s2.sortIndex);

        console.log({precedents});

        if (searchText !== "")
            precedents = precedents.filter((p) => p.name?.toLowerCase().includes(searchText.toLowerCase()));

        precedents = precedents.filter((p) => !usedPrecedents.includes(p.precedentId));

        let sects = Object.values(configState.sectors);

        let cTypes = Object.values(configState.caseTypes);

        const uniqueFolders = (ct, s) => Object.values(configState.precedentFolders)
            .filter(f => f.caseTypeId === ct.caseTypeId && f.sectorId === s.sectorId && f.disabled === false);

        const treeData = sects.map((s) => {
            let sectorPrecedents = precedents.filter(p => p.sectorId === s.sectorId);
            return {
                sectorId: s.sectorId,
                name: s.name,
                letterCount: sectorPrecedents.length,
                caseTypes: cTypes
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((ct) => {
                        let caseTypePrecedents = sectorPrecedents.filter(p => p.caseTypeId === ct.caseTypeId);                        
                        return {
                            caseTypeId: ct.caseTypeId,
                            name: ct.name,
                            letterCount: caseTypePrecedents.length,
                            folders: uniqueFolders(ct, s)
                                .map((f) => {
                                    let folderPrecedents = caseTypePrecedents.filter(p => p.precedentFolderId === f.precedentFolderId);
                                    return {
                                        precedentFolderId: f.precedentFolderId,
                                        name: f.name,
                                        letterCount: folderPrecedents.length,
                                        precedents: folderPrecedents
                                            .sort((s1, s2) => s1.sortIndex - s2.sortIndex)
                                            .map((p) => {
                                                return {
                                                    precedentId: p.precedentId,
                                                    name: p.name,
                                                    azureFileReference: p.azureFileReference,
                                                    azureFileContentType: p.azureFileContentType,
                                                    azureFileId: p.azureFileId
                                                };
                                            }),
                                    };
                                }),
                            precedents: caseTypePrecedents.filter(p => !p.precedentFolderId)
                                .sort((s1, s2) => s1.sortIndex - s2.sortIndex)
                                .map((p) => {
                                    return {
                                        precedentId: p.precedentId,
                                        name: p.name,
                                        precedentFolderId: p.precedentFolderId,
                                        azureFileReference: p.azureFileReference,
                                        azureFileContentType: p.azureFileContentType,
                                        azureFileId: p.azureFileId
                                    };
                                }),
                        };
                    }),
            };
        });    

        console.log({treeData});

        return treeData;
    }, [configState.precedents, configState.sectors, configState.caseTypes, configState.precedentFolders, searchText, user.details.adviceTypes, usedPrecedents]);    

    const handleSectorClick = (sectorIndex) => {
        if (selectedSectorIndex === sectorIndex) {
            setSelectedSectorIndex("");
        } else {
            setSelectedSectorIndex(sectorIndex);
        }
    };

    const handleCaseTypeClick = (caseTypeIndex) => {
        if (selectedCaseTypeIndex === caseTypeIndex) {
            setSelectedCaseTypeIndex("");
        } else {
            setSelectedCaseTypeIndex(caseTypeIndex);
        }
    };

    const handleCaseTypeFolderClick = (caseTypeFolderIndex) => {
        if (selectedCaseTypeFolderIndex === caseTypeFolderIndex) {
            setSelectedCaseTypeFolderIndex("");
        } else {
            setSelectedCaseTypeFolderIndex(caseTypeFolderIndex);
        }
    };

    const handleClosePreview = () => {
        setPrecedentId(null);
    };

    const triggerOpenDocument = async (staticFileToPreview) => {
        var staticFileAzureDetails = {
            reference: staticFileToPreview.azureFileReference,
            filename: staticFileToPreview.name,
            contentType: staticFileToPreview.azureFileContentType,
        };
        var previewRequest = { ...staticFileAzureDetails };
        openDocument(previewRequest);
    };

    const PrecedentItem = ({ p, nested }) => {
        let name = p.name;

        const canAdd = isEmailReply && p.azureFileReference;

        if (canAdd)
            name = "+ " + name;        

        const showAI = isAITool && !!p.azureFileReference && p.name.endsWith(".docx");

        return (
            <ListItem button={isEmailReply} className={nested ? classes.sub_file_nested : classes.file_nested}>
                {showAI && <Avatar className={avatarClasses.small} onClick={() => onSelect(p)}><ArrowBackIcon style={{cursor:'pointer'}} /></Avatar>}
                <ListItemText
                    pl={nested ? 5 : 0}
                    primaryTypographyProps={{ style: { whiteSpace: "normal" } }} 
                    title={canAdd ? "Add the highlighted item to case" : ""}
                    onClick={canAdd ? () => onSelect(p) : null}
                    primary={name}
                />
                {p.azureFileReference ? (
                    <IconButton title="Preview file">
                        {" "}
                        <PreviewIcon
                            onClick={() => triggerOpenDocument(p)}
                            style={{ color: "royalblue" }}
                        />
                    </IconButton>
                ) : (
                    <IconButton title="Preview document">
                        <PreviewIcon onClick={() => setPrecedentId(p.precedentId)} />
                    </IconButton>
                )}
            </ListItem>
        );
    };

    return (
        <Paper>
            <Box display="flex" flexDirection="column">
                <Typography variant="h6" gutterBottom>Precedent Bank</Typography>
                <TextField
                    style={{ margin: "5px" }}
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    placeholder="Search"
                    size="small"
                    variant="outlined"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
                <PerfectScrollbar style={{ maxHeight: "350px" }}>
                    {filteredPrecedentTree
                        .filter((s) => s.letterCount > 0)
                        .map((s, index) => (
                            <List key={index} component="nav">
                                <ListItem
                                    className={classes.root}
                                    key={index}
                                    button
                                    onClick={() => handleSectorClick(index)}
                                >
                                    <ListItemIcon>
                                        <InboxIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={s.name} />
                                    {index === selectedSectorIndex ? <ExpandLess /> : <ExpandMore />}
                                </ListItem>
                                <Collapse
                                    in={index === selectedSectorIndex}
                                    timeout="auto"
                                    unmountOnExit
                                >
                                    {s.caseTypes
                                        .filter((ct) => ct.letterCount > 0)
                                        .map((ct, cindex) => (
                                            <List key={cindex} component="div">
                                                <ListItem
                                                    button
                                                    className={classes.nested}
                                                    onClick={() => handleCaseTypeClick(cindex)}
                                                >
                                                    <ListItemIcon>
                                                        <FolderIcon />
                                                    </ListItemIcon>
                                                    <ListItemText primary={ct.name} />
                                                    {cindex === selectedCaseTypeIndex ? <ExpandLess /> : <ExpandMore />}
                                                </ListItem>
                                                <Collapse
                                                    in={cindex === selectedCaseTypeIndex}
                                                    timeout="auto"
                                                    unmountOnExit
                                                >
                                                    <List component="div">
                                                        {
                                                            ct.precedents
                                                                .filter(p => isEmailReply || !p.azureFileReference || p.name.endsWith(".doc") || p.name.endsWith(".docx"))
                                                                .map((p) => <PrecedentItem key={p.precedentId} p={p} />)
                                                        }
                                                    </List>
                                                    {ct.folders
                                                        .filter(f => f.letterCount > 0)
                                                        .map((f, findex) => (
                                                            <List
                                                                key={findex}
                                                                component="div"
                                                            >
                                                                <ListItem
                                                                    button
                                                                    className={classes.sub_nested}
                                                                    onClick={() => handleCaseTypeFolderClick(findex)}
                                                                >
                                                                    <ListItemIcon>
                                                                        <FolderIcon />
                                                                    </ListItemIcon>
                                                                    <ListItemText primary={f.name} />
                                                                    {findex === selectedCaseTypeFolderIndex ? <ExpandLess /> : <ExpandMore />}
                                                                </ListItem>
                                                                <Collapse
                                                                    in={findex === selectedCaseTypeFolderIndex}
                                                                    timeout="auto"
                                                                    unmountOnExit
                                                                >
                                                                    <List component="div">
                                                                        {f.precedents.map(p => <PrecedentItem key={p.precedentId} p={p} nested/>)}
                                                                    </List>
                                                                </Collapse>
                                                            </List>
                                                        ))}
                                                </Collapse>
                                            </List>
                                        ))}
                                </Collapse>
                            </List>
                        ))}
                </PerfectScrollbar>
            </Box>
            <PrecedentDialog
                open={!!precedentId}
                precedentId={precedentId}
                onClose={handleClosePreview}
                caseId={caseId}
            />
        </Paper>
    );
};

export default PrecedentChooser;
