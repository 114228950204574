import axios from "../plugins/axios";
import store from "../redux/store";

import {
    setCaseResearchSessionId,
    setCaseResearchStart,
} from "../redux/actions/caseActions";

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

const startCaseResearch = async () => {
    const { caseReducer, userReducer } = store.getState();
    const { currentCaseId } = caseReducer;
    const userId = userReducer.userProfile.userId;
    const dispatch = store.dispatch;

    if (currentCaseId && userId) {
        try {
            const response = await axios.get(
                `${apiUrl}/startcaseresearch/${currentCaseId}/${userId}`
            );
            if (!response.data.start)
                throw new Error("Unexpected research session found");
            dispatch(setCaseResearchSessionId(response.data.sessionId));
            dispatch(setCaseResearchStart(response.data.start));
        } catch (error) {
            throw error;
        }
    }
};

const endCaseResearch = async (h, m) => {
    const { caseReducer } = store.getState();
    const sessionId = caseReducer.caseResearch.sessionId;
    try {
        const response = await axios.post(`${apiUrl}/endcaseresearch`, {
            sessionId,
            hours: h,
            minutes: m,
        });
        if (!response.data) throw new Error("Missing research session");
    } catch (error) {
        throw error;
    }
};

export { startCaseResearch, endCaseResearch };
