import React, { useState } from "react";
import {
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    MenuItem,
    Button,
    makeStyles,
    TextField,
} from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import useCaseOutcomes from "../../hooks/queries/useCaseOutcomes";
import { Alert } from "@material-ui/lab";
import { isValid } from "date-fns";

const useStyles = makeStyles((theme) => ({
    formItem: {
        width: "100%",
        margin: theme.spacing(2, 0),
    },
}));

const MarkCaseClosedDialog = ({ open, eTCaseOutcomeId, isEt, handleClose, handleCloseCase, loading }) => {
    const [closureDate, setClosureDate] = useState(new Date());
    const [caseOutcomeId, setCaseOutcomeId] = useState(eTCaseOutcomeId || "");
    const outcomes = useCaseOutcomes({ isEt, forDropdown: true });
    const classes = useStyles();

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!isValid(closureDate) || !caseOutcomeId || new Date(closureDate) > new Date()) return;
        handleCloseCase({ caseOutcomeId, closureDate });
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Close case</DialogTitle>
            <form onSubmit={handleSubmit}>
                <DialogContent>
                    <DialogContentText>You will be unable to amend this case whilst it is closed.</DialogContentText>
                    <KeyboardDatePicker
                        value={closureDate}
                        onChange={(date) => setClosureDate(date)}
                        label="Closure Date"
                        format="dd/MM/yyyy"
                        disableFuture
                        inputVariant="outlined"
                        required
                        className={classes.formItem}
                    />
                    {outcomes.isLoading && <CircularProgress />}
                    {outcomes.isError && (
                        <Alert variant="outlined" severity="error">
                            Failed to load outcomes
                        </Alert>
                    )}
                    {outcomes.data && (
                        <TextField
                            value={caseOutcomeId}
                            onChange={(e) => setCaseOutcomeId(e.target.value)}
                            fullWidth
                            variant="outlined"
                            required
                            label="Outcome"
                            select
                            className={classes.formItem}
                            data-cy="case_outcomes"
                        >
                            {outcomes.data.map((x) => (
                                <MenuItem key={x.id} value={x.id} data-cy={x.id}>
                                    {x.description}
                                </MenuItem>
                            ))}
                        </TextField>
                    )}
                </DialogContent>
                <DialogActions>
                    {loading ? (
                        <CircularProgress />
                    ) : (
                        <>
                            <Button color="primary" onClick={handleClose}>
                                Cancel
                            </Button>
                            <Button type="submit" color="secondary" data-cy="close_case_final">
                                Close Case
                            </Button>
                        </>
                    )}
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default MarkCaseClosedDialog;
