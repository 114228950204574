import React, { useState } from "react";
import styled from "styled-components";
import FlexContainer from "./FlexContainer";
import FlexImage from "../images/flex.png";
import {
    Check as CheckIcon,
    Close as CloseIcon
} from "@material-ui/icons";

import {
    Drawer,
    Fab as MuiFab,
    IconButton,
    Paper
} from "@material-ui/core";

import {
    ChevronRight as ChevronRightIcon,
    ChevronLeft as ChevronLeftIcon,
} from "@material-ui/icons";

import { useAuth } from "../contexts/authContext";
import userRoles from "../constants/userRoles"

const Fab = styled(MuiFab)`
  position: fixed;
  right: ${props => props.theme.spacing(8)}px;
  bottom: ${props => props.theme.spacing(8)}px;
  z-index: 1;
`;

const sidebarWidths = [400, 600, 800];

const FlexSidebar = () => {

    const { hasRole } = useAuth();

    const [open, setOpen] = useState(true);
    const [taskSid, setTaskSid] = useState();
    const [widthIndex, setWidthIndex] = useState(0);
    
    if (!hasRole(userRoles.LEGAL_ADVISOR))
        return null;    

    const acceptTask = () => {
        if (taskSid) {
            window.frames[0].postMessage({ event: "acceptTask", sid: taskSid }, "*");
        }
    }

    const rejectTask = () => {
        if (taskSid) {
            window.frames[0].postMessage({ event: "rejectTask", sid: taskSid }, "*");
        }
    }

    const handleLess = () => {
        if (widthIndex === 0) {
            setOpen(false);
            return;
        }
        setWidthIndex(i => i - 1);
    }

    const handleMore = () => {
        setWidthIndex(i => i + 1);
    }

    return (
        <React.Fragment>
            <Fab color="secondary" aria-label="Edit" onClick={() => setOpen(true)}>
                <img height="40" alt="Flx" src={FlexImage} />
            </Fab>
            <Drawer display="Flex" style={{ width: open ? sidebarWidths[widthIndex] : 0 }} variant="persistent" anchor="right" open={open}>
                <Paper>
                    {(widthIndex < sidebarWidths.length - 1) && <IconButton onClick={handleMore}>
                        <ChevronLeftIcon />
                    </IconButton>}
                    <IconButton onClick={handleLess}>
                        <ChevronRightIcon />
                    </IconButton>
                </Paper>
                <FlexContainer
                    flexGrow={1}
                    setOpen={() => setOpen(true)}
                    setTaskSid={setTaskSid}
                    width={sidebarWidths[widthIndex]}
                />
                <div>
                    {false &&
                        <div>
                            {taskSid}
                            <div onClick={acceptTask}><CheckIcon /></div>
                            <div onClick={rejectTask}><CloseIcon  /></div>
                        </div>
                    }
                </div>                
            </Drawer>            
        </React.Fragment>
    );
}

export default FlexSidebar;