import { useQuery } from "react-query";
import queryKeys from "../../constants/queryKeys";
import userService from "../../services/userService";

const useAdviceTypesForUser = (userId) =>
    useQuery(
        [queryKeys.adviceTypes, userId],
        () => userService.getAdviceTypes(userId),
        {
            enabled: !!userId,
            staleTime: Infinity
        }
    );

export default useAdviceTypesForUser;
