import React, { useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
    Button,
    CircularProgress,
    FormControl,
    Grid,
    InputLabel,
    makeStyles,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@material-ui/core";
import useAdviserTimesheet from "../hooks/queries/useAdviserTimesheet";
import { KeyboardDatePicker } from "@material-ui/pickers";
import userRoles from "../constants/userRoles";
import { useAuth } from "../contexts/authContext";
import Headline from "../components/Headline";
import timesheetActivityTypes from "../constants/timesheetActivityTypes";
import { CSVLink } from "react-csv";
import { tableDateTime } from "../utils/dateTimeFormatters";
import useFilteredAdvisers from "../hooks/useFilteredAdvisers";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(4),
    },
    datePicker: {
        marginTop: 0,
        marginBottom: 0,
    },
    formControl: {
        minWidth: 120,
    },
    headlinesContainer: {
        display: "flex",
        gap: theme.spacing(4),
    },
    exportButton: {
        marginLeft: "auto",
    },
    csvLink: {
        visibility: "hidden",
    },
}));

const csvHeaders = [
    { label: "Time", key: "activityTime" },
    { label: "Account Name", key: "accountName" },
    { label: "Case ID", key: "caseId" },
    { label: "Case Description", key: "caseDescription" },
    { label: "Activity Type", key: "activityType" },
    { label: "Activity Description", key: "activityDescription" },
    { label: "Minutes", key: "minutes" },
];

function Timesheets() {
    const classes = useStyles();
    const { hasRole } = useAuth();
    const filteredAdvisers = useFilteredAdvisers();
    const userState = useSelector((state) => state.userReducer);
    const [adviserId, setAdviserId] = useState(userState?.userProfile.userId);
    const [startDate, setStartDate] = useState(new Date().setHours(0, 0, 0, 0));
    const [endDate, setEndDate] = useState(new Date().setHours(23, 59, 59));
    const [exportData, setExportData] = useState([]);
    const csvExportLinkRef = useRef();
    const { data, isLoading, isError, error } = useAdviserTimesheet({
        adviserId,
        startDate,
        endDate,
    });

    const handleStartDateChange = (date) => {
        if (date) {
            let fromDate = date;
            fromDate.setHours(0, 0, 0);
            setStartDate(fromDate);
        }
    };

    const handleEndDateChange = (date) => {
        if (date) {
            let toDate = date;
            toDate.setHours(23, 59, 59);
            setEndDate(toDate);
        }
    };

    const tableBody = () => {
        if (isLoading)
            return (
                <TableRow>
                    <TableCell colSpan={6}>
                        <CircularProgress size={40} />
                    </TableCell>
                </TableRow>
            );

        if (isError)
            return (
                <TableRow>
                    <TableCell colSpan={6}>
                        <Typography>
                            {error?.message || "Unable to load timesheet data."}
                        </Typography>
                    </TableCell>
                </TableRow>
            );

        if (data.length === 0)
            return (
                <TableRow>
                    <TableCell colSpan={6}>
                        <Typography>No timesheet data.</Typography>
                    </TableCell>
                </TableRow>
            );

        return data.map((entry) => (
            <TableRow>
                <TableCell>{tableDateTime(entry.activityTime)}</TableCell>
                <TableCell>{entry?.accountName || "N/A"}</TableCell>
                <TableCell>{entry?.caseId || "N/A"}</TableCell>
                <TableCell>{entry?.caseDescription || "N/A"}</TableCell>
                <TableCell>{entry.activityType}</TableCell>
                <TableCell>{entry.activityDescription}</TableCell>
                <TableCell>{entry.minutes}</TableCell>
            </TableRow>
        ));
    };

    const calculateUserActivityMinutes = useMemo(() => {
        if (!data) return 0;
        return data
            .filter(
                (x) =>
                    x.timesheetActivityType ===
                    timesheetActivityTypes.USER_ACTIVITY
            )
            .reduce((total, entry) => total + entry.minutes, 0);
    }, [data]);

    const calculateCaseActivityMinutes = useMemo(() => {
        if (!data) return 0;
        return data
            .filter(
                (x) =>
                    x.timesheetActivityType ===
                    timesheetActivityTypes.CASE_ACTIVITY
            )
            .reduce((total, entry) => total + entry.minutes, 0);
    }, [data]);

    const exportCsv = () => {
        if (!data) return;

        const content = data.map((entry) => ({
            activityTime: tableDateTime(entry.activityTime),
            accountName: entry?.accountName || "N/A",
            caseId: entry?.caseId || "N/A",
            caseDescription: entry?.caseDescription || "N/A",
            activityType: entry.activityType,
            activityDescription: entry?.activityDescription || "N/A",
            minutes: entry.minutes,
        }));

        setExportData(content);

        setTimeout(() => {
            csvExportLinkRef.current.link.click();
        });
    };

    const isActiveAdvisor = (adviser) => adviser.isActive;

    const sortByNameAscending = (a, b) =>
        a.name > b.name ? 1 : b.name > a.name ? -1 : 0;

    return (
        <Paper className={classes.paper}>
            <Grid alignItems="flex-start" container spacing={4}>
                <Grid item>
                    <FormControl className={classes.formControl}>
                        <KeyboardDatePicker
                            className={classes.datePicker}
                            format="dd/MM/yyyy"
                            label="Start Date"
                            value={startDate}
                            onChange={handleStartDateChange}
                            maxDate={endDate}
                            maxDateMessage="Date should not be after maximum date"
                        />
                    </FormControl>
                </Grid>
                <Grid item>
                    <FormControl className={classes.formControl}>
                        <KeyboardDatePicker
                            className={classes.datePicker}
                            format="dd/MM/yyyy"
                            label="End Date"
                            value={endDate}
                            onChange={handleEndDateChange}
                            minDate={startDate}
                            minDateMessage="Date should not be before minimum date"
                            maxDate={new Date()}
                        />
                    </FormControl>
                </Grid>
                {(hasRole(userRoles.LEGAL_MANAGER) ||
                    hasRole(userRoles.SUPER_USER) ||
                    hasRole(userRoles.LEGAL_ADMIN)) && (
                    <Grid item>
                        <FormControl className={classes.formControl}>
                            <InputLabel>Adviser</InputLabel>
                            <Select
                                value={adviserId}
                                onChange={(e) => setAdviserId(e.target.value)}
                            >
                                {filteredAdvisers
                                    .filter(isActiveAdvisor)
                                    .sort(sortByNameAscending)
                                    .map((u) => (
                                        <MenuItem
                                            key={u.userId}
                                            value={u.userId}
                                        >
                                            {u.name}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    </Grid>
                )}
                <Button
                    disabled={isLoading}
                    className={classes.exportButton}
                    color="primary"
                    variant="contained"
                    onClick={exportCsv}
                >
                    Export
                </Button>
                <CSVLink
                    className={classes.csvLink}
                    ref={csvExportLinkRef}
                    headers={csvHeaders}
                    data={exportData}
                    filename={`timesheet-adviser-${adviserId}.csv`}
                />
            </Grid>
            <div className={classes.headlinesContainer}>
                <Headline
                    description="Total Non Case Related Time"
                    value={calculateUserActivityMinutes}
                    isLoading={isLoading}
                />
                <Headline
                    description="Total Case Related Time"
                    value={calculateCaseActivityMinutes}
                    isLoading={isLoading}
                />
                <Headline
                    description="Total Time"
                    value={
                        calculateCaseActivityMinutes +
                        calculateUserActivityMinutes
                    }
                    isLoading={isLoading}
                />
            </div>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            {csvHeaders.map((header) => (
                                <TableCell>{header.label}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>{tableBody()}</TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
}

export default Timesheets;
