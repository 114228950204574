import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";


const CaseFilesTreeChangeDialog = ({ openTabChangeConfirmDialog, handleSkipTabChange, handleConfirmTabChange }) => {
    return (
        <Dialog open={openTabChangeConfirmDialog} onClose={handleSkipTabChange}>
            <>
                <DialogTitle>Case Files Structure</DialogTitle>
                <DialogContent>
                    <DialogContentText>It looks like you have altered the structure of the case files, switching tabs will reset these changes. Do you wish to proceed ?</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSkipTabChange} color="primary">No</Button>
                    <Button onClick={handleConfirmTabChange} color="primary" variant="contained">Yes</Button>
                </DialogActions>
            </>
        </Dialog>

    );
}

export default CaseFilesTreeChangeDialog;