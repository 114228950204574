const caseActionReminderMetrics = {
    DAYSBEFORE: {
        radioLabel: "day(s) before",
        labelSingular: "day before",
        labelPlural: "days before",
        tag: "DAYSBEFORE",
        value: 0
    },
    HOURSBEFORE: {
        radioLabel: "hour(s) before",
        labelSingular: "hour before",
        labelPlural: "hours before",
        tag: "HOURSBEFORE",
        value: 1
    },
};

export function getTabActivityType(tab) {
    return caseActionReminderMetrics[tab];
}

export default caseActionReminderMetrics;




