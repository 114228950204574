import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "../plugins/axios";
import { useSelector } from "react-redux";

import {
    CardContent,
    Card as MuiCard,
    Typography,
    Box,
    Button as MuiIconButton,
    TextField,
    CircularProgress,
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import {
    Edit as EditIcon,
    Save as SaveIcon,
    Replay as UndoIcon,
} from "@material-ui/icons";

import { spacing } from "@material-ui/system";

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

const Card = styled(MuiCard)(spacing);

const IconButton = styled(MuiIconButton)`
    padding: 2px;
    margin-top: -2px;
    margin-left: 2px;
    min-width: 0;
    color: ${grey[700]};
`;

const CaseDescriptionCard = ({ setSnackAction, setCaseDescription }) => {
    const { currentCaseId: caseId, cases } = useSelector(
        (state) => state.caseReducer
    );
    const c = cases[caseId];
    const description = c.caseSummary.description;
    const dateClosed = c.dateClosed;

    const [mode, setMode] = useState("view");
    const [localDescription, setLocalDescription] = useState("");

    useEffect(() => {
        setLocalDescription(description || "");
    }, [description]);

    const handleChangeDescription = (e) => {
        setLocalDescription(e.target.value);
    };

    const handleSave = async () => {
        setMode("saving");
        let response = await axios.post(
            `${apiUrl}/updatecasedescription?caseId=${caseId}&description=${encodeURIComponent(localDescription)}`
        );
        if (response.status === 200) {
            setCaseDescription(caseId, localDescription);
            setSnackAction("Saved!", "success");
        }
        setMode("view");
    };

    const handleReset = () => {
        setLocalDescription(description);
        setMode("view");
    };

    return (
        <Card mb={6}>
            <CardContent>
                <Box display="flex">
                    <Box flexGrow={1}>
                        <Typography variant="h6" gutterBottom>
                            Case Description
                        </Typography>
                    </Box>
                    <Box>
                        {mode === "edit" && (
                            <IconButton
                                onClick={handleSave}
                                disabled={description === localDescription || (!description && !localDescription)}
                            >
                                <SaveIcon />
                            </IconButton>
                        )}
                        {mode === "edit" && (
                            <IconButton onClick={handleReset}>
                                <UndoIcon />
                            </IconButton>
                        )}
                        {mode === "view" && !dateClosed && (
                            <IconButton onClick={() => setMode("edit")}>
                                <EditIcon />
                            </IconButton>
                        )}
                        {mode === "saving" && (
                            <CircularProgress
                                size={21}
                                style={{ marginLeft: "9px" }}
                            />
                        )}
                    </Box>
                </Box>

                <Box>
                    {mode === "view" && (
                        <Typography variant="body1">{description}</Typography>
                    )}
                    {mode === "edit" && (
                        <TextField
                            style={{ width: "100%" }}
                            value={localDescription}
                            onChange={handleChangeDescription}
                            inputProps={{ maxLength: 255 }}
                        />
                    )}
                </Box>
            </CardContent>
        </Card>
    );
};

export default CaseDescriptionCard;
