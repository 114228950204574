import { useMutation } from 'react-query';
import invoiceSearchService from '../../services/invoiceSearchService';
import queryKeys from "../../constants/queryKeys";
import reactQueryClient from '../../reactQueryClient';
import {useDispatch} from "react-redux";
import {setSnackAction} from "../../redux/actions/snackActions";

const useDeleteInvoiceSearch = () => {
    const dispatch = useDispatch();

    return useMutation({
        mutationFn: (id) => invoiceSearchService.deleteInvoiceSearch(id),
        onSettled: () => {
            reactQueryClient.invalidateQueries([queryKeys.invoiceSearchesPaged]);
        },
        onError: () => dispatch(setSnackAction("Failed to delete Invoice Search", "error"))
    });
}


export default useDeleteInvoiceSearch;
