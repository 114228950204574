import React from 'react'
import { TextField, InputAdornment } from '@material-ui/core';

export default function InputControl(props) {


    const { name, label, error = null, onChange, fieldMaxLength, disabled, inputPropsAdded } = props;
    let { value } = props;
    if (value === undefined)
        value = "";
    return (

        <TextField
            variant="outlined"
            label={label}
            name={name}
            disabled={disabled}
            value={value}
            onChange={onChange}
            inputProps={{ maxLength: fieldMaxLength }}
            InputProps={ inputPropsAdded }
            {...(error && { error: true, helperText:error })}
        />

        )
}