import React from "react";
import {
    Button,
    makeStyles,
    Grid,
} from "@material-ui/core";

import {
    CloudDownload as DownloadIcon,
} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
    diaTitle: {
        flexGrow: 1,
        display: "flex",
        alignItems: "center",
    },
    folderNameField: {
        width: "95%",
        marginLeft: theme.spacing(3),
        marginBottom: theme.spacing(4),
        marginTop: theme.spacing(1),
    },
    customNameField: {
        width: "85%",
        marginLeft: theme.spacing(3),
        marginBottom: theme.spacing(4),
        marginTop: theme.spacing(1),
    },
    resetCustomName: {
        marginTop: theme.spacing(1),
    },
    bottomMenu: {
        position: "fixed",
        bottom: "0px",
        left: "0px",
        right: "0px",
        zIndex: "2",
        backgroundColor: "white",
        padding: "12px",
        fontWeight: "500",
    },
    leftCol: {
        margin: "auto",
    },
    rightCol: {
        textAlign: "right",
    },
    buttonMobile: {
        marginLeft: theme.spacing(2),
        color: "#5c45c7",

    },
}));

export default function MobileDownloadMenu({
    handleDownload,
    countFilesSelected,
}) {
    const classes = useStyles();
    return (
        <Grid container className={classes.bottomMenu}>
            <Grid item xs={6} className={classes.leftCol}>
                {countFilesSelected > 0 ? (
                    <>{countFilesSelected} selected</>
                ): (
                    <>Select files...</>
                ) }
            </Grid>
            <Grid item xs={6} className={classes.rightCol}>
                <Button
                    color="primary"
                    startIcon={<DownloadIcon />}
                    onClick={handleDownload}
                    className={classes.buttonMobile}
                >
                    Download
                </Button>
            </Grid>
        </Grid>
    )
}