import React from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";

const ConfirmResetDialog = ({ open, handleClose, handleConfirm }) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Reset Timer</DialogTitle>
      <DialogContent>
        <DialogContentText>All unallocated time will be lost</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">Cancel</Button>
        <Button onClick={handleConfirm} color="secondary">Reset Timer</Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmResetDialog;