import {
    START_TIMING_CASE_ACTIVITY,
    STOP_CASE_TIMER,
    PAUSE_CASE_TIMER,
    RESUME_CASE_TIMER,
    ADD_UNALLOCATED_CASE_ACTIVITIES,
    FILTER_SAVED_CASE_ACTIVITIES,
    SET_REQUEST_STATUS,   
    SET_CALL_NOTE_INTERACTED,
    QUEUE_BLUR
} from '../constants';

export function stopCaseTimer() {
    return {
        type: STOP_CASE_TIMER,
    };
}

export function pauseCaseTimer() {
    return {
        type: PAUSE_CASE_TIMER,
    };
}

export function resumeCaseTimer() {
    return {
        type: RESUME_CASE_TIMER,
    };
}

export function startTimingActivity(activityType, shouldAppendCall) {
    return {
        type: START_TIMING_CASE_ACTIVITY,
        activityType,
        shouldAppendCall,
    };
}

export function addUnallocatedActivities(payload) {
    return {
        type: ADD_UNALLOCATED_CASE_ACTIVITIES,
        payload,
    };
}

export function filterSavedActivities(payload) {
    return {
        type: FILTER_SAVED_CASE_ACTIVITIES,
        payload,
    };
}

export function setRequestStatus(payload) {
    return {
        type: SET_REQUEST_STATUS,
        payload,
    };
}

export function setCallNoteInteracted(payload) {
    return {
        type: SET_CALL_NOTE_INTERACTED,
        payload
    }
}

export function queueBlur() {
    return {
        type: QUEUE_BLUR
    }
}