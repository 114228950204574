import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import clsx from "clsx";
import PropTypes from "prop-types";
import axios from "../plugins/axios";
import { useSelector, useDispatch } from "react-redux";
import { grey } from "@material-ui/core/colors";

import { withStyles, makeStyles } from "@material-ui/core/styles";
import {
    Button as MuiIconButton,
    CardContent,
    Card as MuiCard,
    Typography,
    StepConnector,
    Stepper,
    Step,
    StepLabel,
    Box,
    FormControl,
    FormControlLabel,
    Checkbox,
    FormGroup,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { Room, ExpandMore, ExpandLess, Save } from "@material-ui/icons";

import { setSnackAction } from "../redux/actions/snackActions";
import { setCompletedStageTasks } from "../redux/actions/caseActions";

const Card = styled(MuiCard)(spacing);

const IconButton = styled(MuiIconButton)`
    padding: 2px;
    margin-top: -2px;
    margin-left: 2px;
    min-width: 0;
    color: ${grey[700]};
`;

const CustomConnector = withStyles((theme) => ({
    alternativeLabel: {
        top: 22,
    },
    active: {
        "& $line": {
            backgroundImage: `linear-gradient( 95deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`,
        },
    },
    completed: {
        "& $line": {
            backgroundImage: `linear-gradient( 95deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`,
        },
    },
    line: {
        height: 3,
        border: 0,
        backgroundColor: "#eaeaf0",
        borderRadius: 1,
    },
}))(StepConnector);

const useCustomIconStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "#ccc",
        zIndex: 1,
        color: "#fff",
        width: 50,
        height: 50,
        display: "flex",
        borderRadius: "50%",
        justifyContent: "center",
        alignItems: "center",
    },
    active: {
        backgroundImage: `linear-gradient( 136deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`,
        boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
    },
    completed: {
        backgroundImage: `linear-gradient( 136deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`,
    },
}));

const CustomIcon = ({ active, completed, icon }) => {
    const classes = useCustomIconStyles();

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
                [classes.completed]: completed,
            })}
        >
            <Room />
        </div>
    );
};

CustomIcon.propTypes = {
    active: PropTypes.bool,
    completed: PropTypes.bool,
    icon: PropTypes.node,
};

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

const WorkflowCard = () => {
    const dispatch = useDispatch();

    const { currentCaseId: caseId, cases } = useSelector(state => state.caseReducer);
    const configState = useSelector(state => state.configReducer);
    const userState = useSelector(state => state.userReducer);

    const c = cases[caseId];
    const completedStageTasks = c.caseSummary.completedStageTasks;
    const caseTypeId = c.caseSummary.caseTypeId;
    const currentStageId = c.caseSummary.currentStageId;

    const [stages, setStages] = useState([]);
    const [visitedStages, setVisitedStages] = useState([]);
    const [stepsState, setStepsState] = useState([]);
    const [activeStep, setActiveStep] = useState(1);

    const [stageTasks, setStageTasks] = useState(null);
    const [showTasks, setShowTasks] = useState(false);

    const getStages = useCallback(() => {
        if (!configState.caseTypes[caseTypeId])
            return [];

        setStages(
            configState.caseTypes[caseTypeId].stages
                .slice()
                .sort((s1, s2) => s1.stageOrder - s2.stageOrder)
        );
    }, [configState.caseTypes, caseTypeId]);

    const getVisitedStages = useCallback(async () => {
        var response = await axios.get(
            `${apiUrl}/getcasestagesaudit/${caseId}`
        );
        if (response.status === 200) {
            setVisitedStages(response.data);
        }
    }, [caseId]);

    const getSteps = useCallback(() => {
        if (stages.length > 0) {
            let steps = stages.filter((s) => s.caseStageId === currentStageId);
            if (
                stages.filter((s) => s.caseStageId === currentStageId).length >
                0
            ) {
                let currentStageOrder = stages.filter(
                    (s) => s.caseStageId === currentStageId
                )[0].stageOrder;
                let foundPrev,
                    foundNext = false;

                const findPrev = (stageOrder) => {
                    while (stageOrder >= stages[0].stageOrder) {
                        let order = stageOrder;
                        if (
                            stages.filter((s) => s.stageOrder === order)
                                .length &&
                            visitedStages.includes(
                                stages.filter((s) => s.stageOrder === order)[0]
                                    .caseStageId
                            )
                        ) {
                            steps.unshift(
                                stages.filter((s) => s.stageOrder === order)[0]
                            );
                            foundPrev = true;
                            break;
                        }
                        stageOrder--;
                    }
                };

                const findNext = (stageOrder) => {
                    while (stageOrder <= stages.slice(-1)[0].stageOrder) {
                        let order = stageOrder;
                        if (
                            stages.filter((s) => s.stageOrder === order)
                                .length &&
                            !stages.filter((s) => s.stageOrder === order)[0]
                                .disabled
                        ) {
                            steps.push(
                                stages.filter((s) => s.stageOrder === order)[0]
                            );
                            foundNext = true;
                            break;
                        }
                        stageOrder++;
                    }
                };

                currentStageOrder--;
                findPrev(currentStageOrder);

                currentStageOrder =
                    stages.filter((s) => s.caseStageId === currentStageId)[0]
                        .stageOrder + 1;
                findNext(currentStageOrder);

                if (steps.length !== 3 && foundPrev) {
                    currentStageOrder = steps[0].stageOrder - 1;
                    findPrev(currentStageOrder);
                }

                if (steps.length !== 3 && foundNext) {
                    currentStageOrder = steps.slice(-1)[0].stageOrder + 1;
                    findNext(currentStageOrder);
                }
            }
            setStepsState(steps);
        }
    }, [currentStageId, stages, visitedStages]);

    const getActiveStep = useCallback(() => {
        if (stepsState.length > 0) {
            stepsState.forEach((s, index) => {
                if (s.caseStageId === currentStageId) {
                    setActiveStep(index);
                }
            });
        }
    }, [stepsState, currentStageId]);

    const getStageTasks = useCallback(() => {

        if (!configState.caseTypes[caseTypeId])
            return [];

        setStageTasks(
            configState.caseTypes[caseTypeId].stages
                .filter((cs) => cs.caseStageId === currentStageId)[0]
                ?.stageTasks.filter(
                    (t) =>
                        !(
                            t.disabled &&
                            !completedStageTasks.includes(t.stageTaskId)
                        )
                )
                .map((t) => ({
                    ...t,
                    edited: false,
                    completed: completedStageTasks.includes(t.stageTaskId)
                        ? true
                        : false,
                    disabled: t.disabled,
                }))
        );
    }, [
        caseTypeId,
        currentStageId,
        completedStageTasks,
        configState.caseTypes,
    ]);

    const toggleShowTasks = () => {
        setShowTasks((prevShow) => !prevShow);
    };

    const toggleChecked = (id) => {
        setStageTasks((prevTasks) =>
            prevTasks.map((t) =>
                t.stageTaskId === id
                    ? { ...t, completed: !t.completed, edited: true }
                    : t
            )
        );
    };

    const handleSave = async () => {
        let response = await axios.post(
            `${apiUrl}/updatestagetaskaudit/${caseId}/${userState.userProfile.userId}`,
            stageTasks
                .filter((t) => t.edited)
                .map((t) => ({
                    stageTaskId: t.stageTaskId,
                    completed: t.completed,
                }))
        );
        if (response.status === 200) {
            dispatch(
                setCompletedStageTasks(
                    caseId,
                    stageTasks.filter((t) => t.completed).map((t) => t.stageTaskId)
                )
            );
            dispatch(setSnackAction("Progress saved!", "success"));
            //setStageTasks(prevTasks => prevTasks.map(t => ({ ...t, edited: false })));
        }
    };

    useEffect(() => {
        getStages();
    }, [getStages]);

    useEffect(() => {
        getVisitedStages();
    }, [getVisitedStages, currentStageId]);

    useEffect(() => {
        getSteps();
    }, [getSteps]);

    useEffect(() => {
        getActiveStep();
    }, [getActiveStep]);

    useEffect(() => {
        getStageTasks();
    }, [getStageTasks]);

    return (
        <Card mb={6}>
            <CardContent>
                <Typography variant="h6" gutterBottom>
                    Workflow
                </Typography>
                <Stepper
                    alternativeLabel
                    activeStep={activeStep}
                    connector={<CustomConnector />}
                >
                    {stepsState.map((s) => (
                        <Step key={s.caseStageId}>
                            <StepLabel StepIconComponent={CustomIcon}>
                                {s.name}
                            </StepLabel>
                        </Step>
                    ))}
                </Stepper>
                {stageTasks && stageTasks.length ? (
                    <Box display="flex" justifyContent="flex-start">
                        <Typography variant="subtitle2">
                            Tasks Completed{" "}
                            {stageTasks.filter((t) => t.completed).length}/
                            {stageTasks.length}
                        </Typography>
                        <IconButton onClick={toggleShowTasks}>
                            {showTasks ? <ExpandLess /> : <ExpandMore />}
                        </IconButton>
                        {showTasks && (
                            <IconButton
                                onClick={handleSave}
                                disabled={
                                    !stageTasks.filter((t) => t.edited).length
                                }
                            >
                                <Save />
                            </IconButton>
                        )}
                    </Box>
                ) : (
                    <div></div>
                )}
                {stageTasks && stageTasks.length && showTasks ? (
                    <FormControl>
                        <FormGroup>
                            {stageTasks
                                .filter((t) => !t.disabled)
                                .map((t) => (
                                    <FormControlLabel
                                        key={t.stageTaskId}
                                        control={
                                            <Checkbox
                                                checked={t.completed}
                                                onChange={() =>
                                                    toggleChecked(t.stageTaskId)
                                                }
                                            />
                                        }
                                        label={t.description}
                                    />
                                ))}
                            {stageTasks
                                .filter((t) => t.disabled)
                                .map((t) => (
                                    <FormControlLabel
                                        key={t.stageTaskId}
                                        control={<Checkbox disabled checked />}
                                        label={t.description}
                                    />
                                ))}
                        </FormGroup>
                    </FormControl>
                ) : (
                    <div></div>
                )}
            </CardContent>
        </Card>
    );
};

export default WorkflowCard;
