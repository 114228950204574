import { useQuery } from "react-query";
import queryKeys from '../../constants/queryKeys'
import caseService from "../../services/caseService";

const useCaseFilesTree = (caseId) => useQuery(
    [queryKeys.caseFilesTree, caseId],
    () => caseService.fetchCaseFilesTree(caseId),
    {
        enabled: !!caseId
    })

export default useCaseFilesTree;