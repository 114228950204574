import React, { useState, useEffect } from "react";
import axios from "../plugins/axios";
import { connect } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Controls } from "../helpers/Controls";
import { useAuth } from "../contexts/authContext";
import userRoles from "../constants/userRoles";

import {
    Box,
    Collapse,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Table,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableBody,
    TablePagination,
    Tooltip,
    Typography,
    Button as MuiIconButton,
} from "@material-ui/core";

import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";

import { setSnackAction } from "../redux/actions/snackActions";
import Loader from "../components/Loader";

import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import DeleteIcon from "@material-ui/icons/DeleteForever";
import { defaultRowsPerPage } from "../constants/tableConstants";

import styled from "styled-components";

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

const useStyles = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
}));

const IconButton = styled(MuiIconButton)`
    padding: 0px;
    margin-top: 2px;
    margin-left: 0px;
    min-width: 0;
`;

function TablePaginationActions({ count, page, rowsPerPage, onPageChange }) {
    const classes = useStyles();
    const theme = useTheme();

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === "rtl" ? (
                    <LastPageIcon />
                ) : (
                    <FirstPageIcon />
                )}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === "rtl" ? (
                    <KeyboardArrowRight />
                ) : (
                    <KeyboardArrowLeft />
                )}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === "rtl" ? (
                    <KeyboardArrowLeft />
                ) : (
                    <KeyboardArrowRight />
                )}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === "rtl" ? (
                    <FirstPageIcon />
                ) : (
                    <LastPageIcon />
                )}
            </IconButton>
        </div>
    );
}

const ContactTable = ({
    eMail,
    fullName,
    phoneNumber,
    companyName,
    clickable,
    dispatch,
    handleClick,
    accountPageLoading,
}) => {
    const { hasRole } = useAuth();
    const canDelete =
        hasRole(userRoles.LEGAL_ADMIN) || hasRole(userRoles.SUPER_USER);
    const [results, setResults] = useState([]);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(0);
    const rowsPerPage = defaultRowsPerPage;
    const [loading, setLoading] = useState(false);
    const [openDeleteContactDialog, setDeleteContactDialog] = useState(false);
    const [recordForDelete, setRecordForDelete] = useState(null);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const updateContacts = async () => {
        setLoading(true);
        let response = await axios.post(`${apiUrl}/getcontacts`, {
            fullname: fullName,
            email: eMail,
            phone: phoneNumber,
            companyname: companyName,
            pageNum: page + 1,
            pageSize: rowsPerPage,
        });
        if (response.status !== 200) {
            dispatch(setSnackAction("Sorry, there was an error!", "error"));
        } else if (response.data.count === 0) {
            dispatch(setSnackAction("No results found!", "warning"));
        } else {
            setResults(response.data.contacts);
            setCount(response.data.count);
        }
        setLoading(false);
    };

    useEffect(() => {
        page === 0 ? updateContacts() : setPage(0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fullName, eMail, phoneNumber, companyName]);

    useEffect(() => {
        updateContacts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, rowsPerPage]);

    const openDeleteContactPopup = (contactId) => {
        setRecordForDelete(contactId);
        setDeleteContactDialog(true);
    };

    const handleDeleteAccountContact = async (delContactId) => {
        //update the contact enabled flag
        await axios.post(
            `${apiUrl}/deleteAccountContact/${delContactId}/0/true`
        );

        setDeleteContactDialog(false);
        updateContacts();
    };

    const handleCloseDeleteContactDialog = () => {
        setDeleteContactDialog(false);
    };

    function Row(results) {
        const { row } = results;
        const [open, setOpen] = React.useState(false);
        //const classes = useRowStyles();

        return (
            <React.Fragment>
                <TableRow
                    key={row.contactId}
                    style={{
                        cursor: clickable ? "pointer" : "default",
                        height: "100%",
                    }}
                    hover={clickable ? true : false}
                    onClick={() => clickable && setOpen(!open)}
                >
                    <TableCell>
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpen(!open)}
                        >
                            {open ? (
                                <KeyboardArrowUpIcon />
                            ) : (
                                <KeyboardArrowDownIcon />
                            )}
                        </IconButton>
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {row.name}
                    </TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell>{row.phone}</TableCell>
                    <TableCell>{row.mobile}</TableCell>
                    <TableCell>{row.position}</TableCell>
                    {canDelete && (
                        <TableCell
                            style={{
                                borderBottom: "none",
                                padding: "-3px 5px",
                            }}
                        >
                            <Tooltip title="Delete Contact">
                                <IconButton
                                    onClick={() =>
                                        openDeleteContactPopup(row.contactId)
                                    }
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        </TableCell>
                    )}
                </TableRow>
                <TableRow>
                    <TableCell
                        style={{
                            paddingBottom: 0,
                            paddingTop: 0,
                            backgroundColor: "#f9f9f9",
                        }}
                        colSpan={7}
                    >
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box margin={1}>
                                <Typography
                                    variant="h6"
                                    gutterBottom
                                    component="div"
                                >
                                    Linked Accounts
                                </Typography>
                                <Table size="small" aria-label="purchases">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Account</TableCell>
                                            <TableCell>Address</TableCell>
                                            <TableCell>Postcode</TableCell>
                                            <TableCell>Tel</TableCell>
                                            <TableCell>Partnership</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {row.accounts.map((historyRow) => (
                                            <TableRow
                                                key={historyRow.accountId}
                                                style={{
                                                    cursor: clickable
                                                        ? "pointer"
                                                        : "default",
                                                    height: "100%",
                                                }}
                                                hover={clickable ? true : false}
                                                onClick={() =>
                                                    clickable &&
                                                    handleClick(historyRow)
                                                }
                                            >
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                >
                                                    {historyRow.name}
                                                </TableCell>
                                                <TableCell>
                                                    {historyRow.address}
                                                </TableCell>
                                                <TableCell>
                                                    {historyRow.postCode}
                                                </TableCell>
                                                <TableCell>
                                                    {historyRow.phone}
                                                </TableCell>
                                                <TableCell>
                                                    {historyRow.partnershipName}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </React.Fragment>
        );
    }

    return !results || loading || accountPageLoading ? (
        <Loader />
    ) : (
        <React.Fragment>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell>Contact Name</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Phone</TableCell>
                            <TableCell>Mobile Phone</TableCell>
                            <TableCell>Position</TableCell>
                            {canDelete && <TableCell>Delete</TableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {results.map((row) => (
                            <Row key={row.name} row={row} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                ActionsComponent={TablePaginationActions}
            />
            <Dialog
                open={openDeleteContactDialog}
                onClose={handleCloseDeleteContactDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Delete Contact
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Please confirm that you wish to delete this contact.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Controls.ButtonControl
                        variant="outlined"
                        text="Cancel"
                        size="small"
                        color="primary"
                        onClick={() => handleCloseDeleteContactDialog()}
                    />
                    <Controls.ButtonControl
                        variant="outlined"
                        text="Confirm"
                        size="small"
                        color="primary"
                        onClick={() =>
                            handleDeleteAccountContact(recordForDelete)
                        }
                    />
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

export default connect()(ContactTable);
