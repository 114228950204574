import { useQuery } from "react-query";
import queryKeys from "../../constants/queryKeys";
import invoiceSearchService from "../../services/invoiceSearchService";

const useInvoiceSearchesPaged = ({ pageSize, pageNumber }) => 
    useQuery({
        queryKey: [queryKeys.invoiceSearchesPaged, pageNumber, pageSize],
        queryFn: () => invoiceSearchService.getInvoiceSearchesPaged({
            pageSize,
            pageNumber,
        }),
    });

export default useInvoiceSearchesPaged;