import React, { useEffect, useState, useMemo, useCallback } from "react";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import {
    Box,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Card,
    CardContent,
    CircularProgress,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TextField,
    InputAdornment,
    IconButton,
    Link,
    Typography,
} from "@material-ui/core";
import { Search as SearchIcon } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";

import { setSnackAction } from "../redux/actions/snackActions";
import { format } from "date-fns";
import twilioService from "../services/twilioService";
import useAdviceTypes from "../hooks/queries/useAdviceTypes";
import useAdviceTypesForUser from "../hooks/queries/useAdviceTypesForUser";
import { KeyboardDatePicker } from "@material-ui/pickers";

const formStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(3),
        minWidth: 160,
    },
    boxFill: {
        width: "100%",
    },
}));

const Calls = ({ userState, setSnack }) => {

    const { data: adviceTypes } = useAdviceTypes();
    const { data: userAdviceTypes } = useAdviceTypesForUser(userState.userProfile.userId);
    const [adviceTypeId, setAdviceTypeId] = useState("");
    const [date, setDate] = useState(new Date());
    const [caller, setCaller] = useState();
    const [twilioTask, setTwilioTask] = useState();
    const [callData, setCallData] = useState();

    const allowedAdviceTypes = useMemo(() => {
        if (!adviceTypes || !userAdviceTypes)
            return [];

        return adviceTypes.filter(at => userAdviceTypes.includes(at.id))
    }, [adviceTypes, userAdviceTypes]);

    const formClasses = formStyles();
    const [tasks, setTasks] = useState([]);
    const [tasksLoading, setTasksLoading] = useState(false);

    const adviceTypeName = (id) => id ? adviceTypes?.find(at => at.id === id).name : "Not set";

    const filteredTasks = useMemo(() => {
        if (!userAdviceTypes)
            return [];

        let filtered = tasks;

        filtered = filtered.filter(t => !t.adviceTypeId || userAdviceTypes.includes(t.adviceTypeId));

        if (adviceTypeId !== "")
            filtered = adviceTypeId === 0
                ? filtered.filter(t => !t.adviceTypeId)
                : filtered.filter(t => t.adviceTypeId === adviceTypeId);

        return filtered;
    }, [tasks, adviceTypeId, userAdviceTypes]);

    const userName = useCallback((userId) => {
        let user = userState.users[userId];
        return user?.name;
    }, [userState.users]);


    const getCallTasks = async () => {
        setTasksLoading(true);
        try {
            let response = await twilioService.getCallTasks({ date, caller });
            setTasks(response.data);
        } catch (error) {
            setSnack("Couldn't retrieve calls. If problem persists contact a member of IT support", "error");
            console.error(error);
        }

        setTasksLoading(false);
    };

    useEffect(() => {

        console.log({ date });

        getCallTasks();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [date]);

    useEffect(() => {
        if (!twilioTask)
            setCallData(null);

        const getCallData = async () => {
            let response = await twilioService.getCallData(twilioTask.twilioTaskId);
            setCallData(response.data);
        }

        if (twilioTask)
            getCallData();

    }, [twilioTask]);

    const LoadingBox = () => (
        <Box
            display="flex"
            justifyContent="center"
            className={formClasses.boxFill}
        >
            <CircularProgress />
        </Box>
    );

    const InfoBox = ({ label, content }) => (
        <Box display="flex" flexDirection="column" style={{ marginRight: '10px' }}>
            <Typography style={{ fontSize: '0.8em' }}><strong>{label}</strong></Typography>
            <Typography>{content}</Typography>
        </Box>
    )

    if (twilioTask)
        return (
            <Box>
                <Link component="button" onClick={() => setTwilioTask(null)}>Back</Link>
                <Card style={{ marginTop: '10px' }}>
                    <CardContent>
                        <Box display="flex" flexWrap="wrap">
                            <InfoBox label="Date Created" content={format(new Date(twilioTask.dateCreated), "dd/MM/yy HH:mm:ss")} />
                            <InfoBox label="Caller" content={twilioTask.caller} />
                            <InfoBox label="Called" content={twilioTask.inboundPhone} />
                            {(twilioTask.adviserId || twilioTask.targetEmail) && <InfoBox label="Target" content={userName(twilioTask.adviserId) || twilioTask.targetEmail} />}
                        </Box>
                        {twilioTask.userIds.length > 0 && <InfoBox label="Advisers" content={twilioTask.userIds.map(id => userName(id)).join(" / ")} />}
                    </CardContent>
                </Card>
                {!callData && <LoadingBox />}
                {callData && callData.map(t => (
                    <Card key={t.twilioTaskId} style={{ marginTop: '10px' }}>
                        <CardContent>
                            <Box display="flex" flexWrap="wrap">
                                <InfoBox label="Channel" content={t.taskChannel} />
                                <InfoBox label="Date Created" content={format(new Date(t.dateCreated), "dd/MM/yy HH:mm:ss")} />
                                {t.lastModified && <InfoBox label="LastModified" content={format(new Date(t.lastModified), "dd/MM/yy HH:mm:ss")} />}
                                <InfoBox label="Status" content={t.assignmentStatus} />
                                {t.reason && <InfoBox label="Reason" content={t.reason} />}
                                {(userName(t.userId) || t.workerName) && <InfoBox label="User" content={userName(t.userId) || t.workerName} />}
                            </Box>
                            {t.reservations.length > 0 && (
                                <Table size="small">
                                    <TableHead>
                                        <TableCell>Start</TableCell>
                                        <TableCell>End</TableCell>
                                        <TableCell>Status</TableCell>
                                        <TableCell>User</TableCell>
                                    </TableHead>
                                    <TableBody>
                                        {t.reservations.map(r => (
                                            <TableRow key={r.reservationId}>
                                                <TableCell>{format(new Date(r.start), "dd/MM/yy HH:mm:ss")}</TableCell>
                                                <TableCell>{r.end && format(new Date(r.end), "dd/MM/yy HH:mm:ss")}</TableCell>
                                                <TableCell>{r.status}</TableCell>
                                                <TableCell>{userName(r.userId) || r.workerName}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            )}
                        </CardContent>
                    </Card>
                ))}
            </Box>

        );

    return (
        <React.Fragment>
            <Helmet title="Twilio Calls" />
            <Box display="flex" alignItems="flex-end">
                <FormControl className={formClasses.formControl} style={{ marginBottom: '2px' }}>
                    <KeyboardDatePicker
                        format="dd/MM/yyyy"
                        margin="normal"
                        label="Date"
                        value={date}
                        onChange={setDate}
                        maxDate={new Date()}
                    />
                </FormControl>
                <TextField
                    className={formClasses.formControl}
                    value={caller || ""}
                    onChange={e => setCaller(e.target.value)}
                    inputProps={{ min: 0 }}
                    label="Caller"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment>
                                <IconButton onClick={getCallTasks}>
                                    <SearchIcon />
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
                {
                    allowedAdviceTypes.length > 1 &&
                    <FormControl className={formClasses.formControl}>
                        <InputLabel>Advice Type</InputLabel>
                        <Select
                            onChange={(e) => setAdviceTypeId(e.target.value)}
                            value={adviceTypeId}
                        >
                            <MenuItem value="">All</MenuItem>
                            <MenuItem value={0}>Not set</MenuItem>
                            {
                                allowedAdviceTypes.map((at) => <MenuItem key={at.id} value={at.id}>{at.name}</MenuItem>)
                            }
                        </Select>
                    </FormControl>
                }
            </Box>
            <Card>
                <CardContent>
                    {tasksLoading ? (
                        <LoadingBox />
                    ) : (
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Date</TableCell>
                                    <TableCell>Caller</TableCell>
                                    <TableCell>Advice Type</TableCell>
                                    <TableCell>Called</TableCell>
                                    <TableCell>Option</TableCell>
                                    <TableCell>Workflow</TableCell>
                                    <TableCell>Wait<br />Duration</TableCell>
                                    <TableCell>Call<br />Duration</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredTasks.map((t) => (
                                    <TableRow key={t.twilioTaskId} hover style={{ cursor: 'pointer' }} onClick={() => setTwilioTask(t)}>
                                        <TableCell>
                                            {format(new Date(t.dateCreated), "dd/MM/yy HH:mm")}
                                        </TableCell>
                                        <TableCell>{t.caller}</TableCell>
                                        <TableCell>{adviceTypeName(t.adviceTypeId)}</TableCell>
                                        <TableCell>{t.inboundPhone}</TableCell>
                                        <TableCell>{t.ivrOption}</TableCell>
                                        <TableCell>{t.workflowName}</TableCell>
                                        <TableCell>{t.waitDuration}</TableCell>
                                        <TableCell>{t.hasCallback ? "callback" : t.callDuration}</TableCell>                                       
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    )}
                </CardContent>
            </Card>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => ({ userState: state.userReducer });

const mapDispatchToProps = (dispatch) => ({
    setSnack: (message, severity) => dispatch(setSnackAction(message, severity)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Calls);