import React, { useState, useEffect } from "react";
import {
    Box,
    Card,
    CardContent,
    CircularProgress,
    IconButton,
    makeStyles,
    Typography,
    TextField,
} from "@material-ui/core";
import {
    Edit as EditIcon,
    Save as SaveIcon,
    Replay as UndoIcon,
} from "@material-ui/icons";
import { Alert, Skeleton } from "@material-ui/lab";
import { viewModeStates } from "../../constants/viewModeStates";
import { formatPriceGBP, validateInputPrice } from "../../helpers/formatPrice";

const useStyles = makeStyles((theme) => ({
    boxSpacing: {
        gap: theme.spacing(3),
        display: "grid",
        gridTemplateColumns: "repeat(4, 1fr)",
    },
    rightHalf: {
        display: "flex",
        justifyContent: "end",
        width: "100%",
    },
    fullGrid: {
        gridColumn: "1 / span 4",
        display: "flex",
        alignItems: "center",
        borderBottom: `solid 2px ${theme.palette.secondary.main}`,
    },
    leftHalf: {
        minWidth: "max-content",
    },
}));

export const ETCostsCard = ({
    onSave,
    data = {},
    isLoading,
    isError,
    error,
}) => {
    const classes = useStyles();
    const [estimatedCouncilFees, setEstimatedCouncilFees] = useState(0);
    const [actualCouncilFees, setActualCouncilFees] = useState(0);
    const [viewMode, setViewMode] = useState(viewModeStates.VIEWING);

    useEffect(() => {
        setEstimatedCouncilFees(data?.estimatedCouncilFees || 0);
        setActualCouncilFees(data?.actualCouncilFees || 0);
    }, [data]);

    const handleSave = () => {
        const changes = {
            estimatedCouncilFees,
            actualCouncilFees,
        };

        onSave(changes);
        setViewMode(viewModeStates.VIEWING);
    };

    const handleFeeChange = (price, inputValue, onFeeChange) => {
        const validatePrice = validateInputPrice(inputValue);
        
        if (validatePrice) onFeeChange(inputValue)
        else if (!validatePrice && price < 10) onFeeChange(0)
        else {
            onFeeChange(price)
        }
    }

    return (
        <Card>
            <CardContent>
                <div className={classes.boxSpacing}>
                    <div className={classes.fullGrid}>
                        <Typography className={classes.leftHalf} variant="h6">
                            Costs
                        </Typography>
                        <div className={classes.rightHalf}></div>
                        {viewMode === viewModeStates.VIEWING && (
                            <IconButton
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setViewMode(viewModeStates.EDITING);
                                }}
                            >
                                <EditIcon />
                            </IconButton>
                        )}
                        {viewMode === viewModeStates.EDITING && (
                            <>
                                <IconButton
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setViewMode(viewModeStates.SAVING);
                                        handleSave();
                                    }}
                                >
                                    <SaveIcon />
                                </IconButton>
                                <IconButton
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setViewMode(viewModeStates.VIEWING);
                                    }}
                                >
                                    <UndoIcon />
                                </IconButton>
                            </>
                        )}
                        {viewMode === viewModeStates.SAVING && (
                            <CircularProgress size={20} />
                        )}
                    </div>
                    {(isLoading || viewMode === viewModeStates.SAVING) && (
                        <>
                            <Skeleton height={40} variant="rect" />
                            <Skeleton height={40} variant="rect" />
                        </>
                    )}
                    {isError && (
                        <Box
                            display="flex"
                            justifyContent="center"
                            width="100%"
                        >
                            <Alert variant="outlined" severity="error">
                                {error?.message ||
                                    "Unable to load Cost details"}
                            </Alert>
                        </Box>
                    )}
                    <>
                        {viewMode === viewModeStates.VIEWING && (
                            <>
                                <div>
                                    <Typography variant="subtitle2">
                                        Estimated Counsel Fees
                                    </Typography>
                                    <Typography>
                                        {formatPriceGBP(
                                            data?.estimatedCouncilFees
                                        )}
                                    </Typography>
                                </div>
                                <div>
                                    <Typography variant="subtitle2">
                                        Actual Counsel Fees
                                    </Typography>
                                    <Typography>
                                        {formatPriceGBP(
                                            data?.actualCouncilFees
                                        )}
                                    </Typography>
                                </div>
                            </>
                        )}
                        {viewMode === viewModeStates.EDITING && (
                            <>
                                <TextField
                                    label="Estimated Counsel Fees"
                                    onChange={(e) => handleFeeChange(estimatedCouncilFees, e.target.value, setEstimatedCouncilFees)}
                                    placeholder="Estimated Counsel Fees"
                                    type="number"
                                    value={estimatedCouncilFees}
                                    variant="outlined"
                                />
                                <TextField
                                    label="Actual Counsel Fees"
                                    onChange={(e) => handleFeeChange(actualCouncilFees, e.target.value, setActualCouncilFees)}
                                    placeholder="Actual Counsel Fees"
                                    type="number"
                                    value={actualCouncilFees}
                                    variant="outlined"
                                />
                            </>
                        )}
                    </>
                </div>
            </CardContent>
        </Card>
    );
};

export default ETCostsCard;
