import axios from "../plugins/axios";

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

const getRaiseCaseRequest = async (raiseCaseRequestId) => {
    try {
        const response = await axios.get(`${apiUrl}/RaiseCaseRequest/${raiseCaseRequestId}`);
        return response.data;
    }
    catch (e) {
        console.error(e);
        return Promise.reject(e?.message || "Failed to fetch Raise Case Request");
    }
}

const raiseCaseRequestService = {
    getRaiseCaseRequest,
};

export default raiseCaseRequestService;