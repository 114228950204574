import axios from "../plugins/axios";
import { toIsoString } from "../utils/dateTimeFormatters";

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

const getOpenAndETCaseActions = async (caseId) => {
    try {
        const response = await axios.get(
            `${apiUrl}/getopenandetcaseactions/${caseId}`
        );
        return response.data;
    } catch (e) {
        console.error(e);
        return Promise.reject(e?.message || "Could not fetch case actions.");
    }
};

const getCaseActionsDashboard = async (
    pageSize,
    pageNum,
    fromDate,
    toDate,
    adviserId,
    actionTypeId,
    actionCategoryId,
    teamId,
    includeCompleted,
    isPaged
) => {
    let queryString = `?pageSize=${pageSize}&pageNum=${pageNum}`;
    if (fromDate) queryString += `&fromDate=${toIsoString(fromDate)}`;
    if (toDate) queryString += `&toDate=${toIsoString(toDate)}`;
    queryString += `&adviserId=${adviserId}`;
    queryString += `&actionTypeId=${actionTypeId}`;
    queryString += `&teamId=${teamId}`;
    queryString += `&actionCategoryId=${actionCategoryId}`;
    queryString += `&includeCompleted=${includeCompleted}`;
    queryString += `&isPaged=${isPaged}`;

    try {
        const response = await axios.get(
            `${apiUrl}/getcaseactionsdashboard${queryString}`
        );
        return response.data;
    } catch (e) {
        console.error(e);
        return Promise.reject(
            e?.message || "Could not fetch case action dashboard data."
        );
    }
};
const getAllCaseActionTypes = async () => {
    try {
        const response = await axios.get(`${apiUrl}/getallcaseactiontypes`);
        return response.data;
    } catch (e) {
        console.error(e);
        return Promise.reject(
            e?.message || "Could not fetch case action types."
        );
    }
};

const getCaseActionCategories = async () => {
    try {
        const response = await axios.get(`${apiUrl}/getcaseactioncategories`);
        return response.data;
    } catch (e) {
        console.error(e);
        return Promise.reject(
            e?.message || "Could not fetch case action categories."
        );
    }
};

const getCaseActionTypes = async (caseActionCategoryId) => {
    try {
        const response = await axios.get(
            `${apiUrl}/getcaseactiontypes/${caseActionCategoryId}`
        );
        return response.data;
    } catch (e) {
        console.error(e);
        return Promise.reject(
            e?.message || "Could not fetch case action types."
        );
    }
};

const addCaseAction = async (caseAction) => {
    try {
        const response = await axios.post(
            `${apiUrl}/addcaseaction`,
            caseAction
        );
        return response.data;
    } catch (e) {
        console.error(e);
        return Promise.reject(e?.message || "Could not add case action.");
    }
};

const updateCaseAction = async (caseAction) => {
    try {
        const response = await axios.post(
            `${apiUrl}/updatecaseaction`,
            caseAction
        );
        return response.data;
    } catch (e) {
        console.error(e);
        return Promise.reject(e?.message || "Could not update case action.");
    }
};

const caseActionService = {
    getOpenAndETCaseActions,
    getCaseActionCategories,
    getCaseActionTypes,
    addCaseAction,
    updateCaseAction,
    getAllCaseActionTypes,
    getCaseActionsDashboard,
};

export default caseActionService;
