import { Dialog, DialogTitle, DialogContent, makeStyles, TextField, FormControl, InputLabel, Select, MenuItem, InputAdornment, Button } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import useAddETClaim from '../../hooks/mutations/useAddETClaim'
import useUpdateETClaim from '../../hooks/mutations/useUpdateETClaim'
import { setSnackAction } from '../../redux/actions/snackActions'

const useStyles = makeStyles((theme) => ({
    grid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        gap: theme.spacing(3),
        paddingBottom: theme.spacing(4)
    },
    button: {
        width: 'max-content'
    },
    flex: {
        display: 'flex',
        justifyContent: 'center',
        gap: theme.spacing(3),
        marginTop: theme.spacing(6)
    }
}))

export default function AddClaimDialog({ open, setOpen, claimTypes, caseId, etCaseId, edit }) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const addETClaim = useAddETClaim()
    const updateETClaim = useUpdateETClaim()
    const [claimTypeId, setClaimTypeId] = useState()
    const [percentage, setPercentage] = useState()
    const [isCovered, setIsCovered] = useState()
    
    useEffect(() => {
        setClaimTypeId(edit?.claimTypeId ? edit?.claimTypeId : 0)
        setPercentage(edit?.percentage ? edit?.percentage : 0)
        setIsCovered(edit?.isCovered ? 1 : edit?.isCovered === false ? 2 : 0)
    }, [edit])

    function updateClaim() {
        const eTClaim = {
            eTCaseId: etCaseId,
            claimTypeId,
            isInsured: isCovered === 2 ? 0 : isCovered,
            successPercentage: percentage,
            claimId: edit?.claimId
        }
        
        updateETClaim.mutate({ eTClaim, caseId },
            {
                onError: (e) =>
                    dispatch(
                        setSnackAction(
                            e?.message ||
                                "There was an error updating ET claim",
                            "error"
                        )
                    ),
                onSuccess: () => {
                    dispatch(
                        setSnackAction("ET claim updated", "success")
                    );
                },
            }
        )

        setOpen(false)
        setIsCovered(0)
        setPercentage(0)
        setClaimTypeId(0)
    }

    function addNewClaim() {
        const eTClaim = {
            eTCaseId: etCaseId,
            claimTypeId,
            isInsured: isCovered === 2 ? 0 : isCovered,
            successPercentage: percentage
        }

        addETClaim.mutate({ eTClaim, caseId },
            {
                onError: (e) =>
                    dispatch(
                        setSnackAction(
                            e?.message ||
                                "There was an error adding new ET claim",
                            "error"
                        )
                    ),
                onSuccess: () => {
                    dispatch(
                        setSnackAction("ET claim added", "success")
                    );
                },
            }
        )

        setOpen(false)
        setIsCovered(0)
        setPercentage(0)
        setClaimTypeId(0)
    }

    function handleSave() {
        if (isCovered === 0 || claimTypeId === 0) {
            dispatch(setSnackAction("All fields are required", "error"))
            return
        }

        if (Number(percentage) > 99 || Number(percentage) < 1) {
            dispatch(setSnackAction("Percentage can only be between 1 and 99", "error"))
            return
        }

        if (edit?.claimId) {
            updateClaim()
        } else {
            addNewClaim()
        }
    }

    return (
        <Dialog 
            onClose={() => setOpen(false)} 
            open={open} 
            fullWidth
        >
            <DialogTitle>Add Claim Type</DialogTitle>
            <DialogContent>
                <div>
                    <div className={classes.grid}>
                        <FormControl variant="outlined">
                            <InputLabel>Claim Type</InputLabel>
                            <Select 
                                MenuProps={{ 
                                    getContentAnchorEl: null, 
                                    anchorOrigin: { 
                                        vertical: 'bottom', 
                                        horizontal: 'left' 
                                    } 
                                }} 
                                onChange={e => setClaimTypeId(e.target.value)}
                                label='Claim Type' 
                                labelWidth='Claim Type'
                                value={claimTypeId ? claimTypeId : 0}
                            >
                                {claimTypes && Object.entries(claimTypes).map(([key, value]) => (
                                    <MenuItem key={key} value={value.claimTypeId}>{value.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <TextField 
                            label='Percentage' 
                            type='number'
                            variant="outlined" 
                            placeholder='Percentage'
                            onChange={e => setPercentage(e.target.value)} 
                            value={percentage ? percentage : '0'} 
                            InputProps={{
                                endAdornment: <InputAdornment position='end'>%</InputAdornment>,
                                inputProps: { min: '1', max: '99' },                                
                            }}
                            onInput={e => e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,2)}
                        />
                        <FormControl variant="outlined">
                            <InputLabel>Covered by Insurance</InputLabel>
                            <Select 
                                MenuProps={{ 
                                    getContentAnchorEl: null, 
                                    anchorOrigin: { 
                                        vertical: 'bottom', 
                                        horizontal: 'left' 
                                    }
                                }} 
                                onChange={e => setIsCovered(e.target.value)}
                                value={isCovered ? isCovered : 0} 
                                label='Covered by Insurance' 
                                labelWidth='Covered by Insurance'
                            >
                                <MenuItem value={0}>Please select..</MenuItem>
                                <MenuItem value={1}>Yes</MenuItem>
                                <MenuItem value={2}>No</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div className={classes.flex}>
                        <Button
                            className={classes.button} 
                            color='primary' 
                            variant='contained'
                            onClick={() => setOpen(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            className={classes.button} 
                            color='primary' 
                            variant='contained'
                            onClick={handleSave}
                        >
                            Save
                        </Button>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    )
}