import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { setSnackAction } from "../../../redux/actions/snackActions";
import { caseFileTypes } from "../../../constants/caseFiles";
import documentService from "../../../services/documentService";
import reactQueryClient from "../../../reactQueryClient";
import queryKeys from "../../../constants/queryKeys";

import {
    Box,
    Button,
    Typography,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    TextField,
    makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    diaTitle: {
        flexGrow: 1,
        display: "flex",
        alignItems: "center",
    },
    folderNameField: {
        width: "95%",
        marginLeft: theme.spacing(3),
        marginBottom: theme.spacing(4),
        marginTop: theme.spacing(1),
    },
}));

export default function FolderNameDialog({
    folderDialogOpen,
    setFolderDialogOpen,
    folderName,
    setFolderName,
    validateFileFolder,
    folderId,
    setFolderId,
    newParent,
    caseId,
    untitledBaseName,
}) {
    const dispatch = useDispatch();
    const classes = useStyles();

    const folderNameMessage = useCallback((newFolderName) => {
        if (folderId === null) {
            dispatch(setSnackAction('Folder created', "success"));
        } else {
            dispatch(setSnackAction(`Folder renamed as '${newFolderName}'`, "success"));
        }
    }, [dispatch, folderId])
    
    const handleFolderSave = useCallback(async (e) => {
        e.preventDefault();

        console.log({ folderName, newParent, folderId});

        let newFolderName = validateFileFolder(folderName, caseFileTypes.FOLDER)
        if (!newFolderName)
            return;        

        try {
            await documentService.upsertCaseFileFolder({
                folderId,
                name: folderName,
                parentId: newParent?.folderId,
                caseId
            });

            folderNameMessage(newFolderName);
        }
        finally {
            console.log([queryKeys.caseFilesTree, caseId]);
            reactQueryClient.invalidateQueries([queryKeys.caseFilesTree, caseId]);
        }
        
        setFolderName("");
        setFolderId(null);
        setFolderDialogOpen(false);
    }, [caseId, folderId, folderName, folderNameMessage, newParent, setFolderDialogOpen, setFolderId, setFolderName, validateFileFolder]);

    const handleFolderDialogClose = useCallback(() => {
        setFolderName("");
        setFolderId(null);
        setFolderDialogOpen(false);
    }, [setFolderDialogOpen, setFolderId, setFolderName]);

    const handleFocus = (event) => event.target.select();

    return (
        <Dialog
            open={folderDialogOpen}
            onClose={handleFolderDialogClose}
            fullWidth
        >
            <DialogTitle>
                <Box display="flex" alignItems="center">
                    <Typography
                        variant="h6"
                        component="div"
                        className={classes.diaTitle}
                    >
                        {folderId > 0
                            ? "Edit Folder Name"
                            : "Create New Case File Folder"}
                    </Typography>
                </Box>
            </DialogTitle>
            <DialogContent>
                <TextField
                    variant="outlined"
                    defaultValue={folderName ? folderName : untitledBaseName}
                    autoFocus
                    onFocus={handleFocus}
                    onChange={(e) => setFolderName(e.target.value)}
                    className={classes.folderNameField}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    onClick={() => handleFolderDialogClose()}
                >
                    Cancel
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={handleFolderSave}
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    )
}