import React, { useEffect, useState, useMemo } from "react";
import Helmet from "react-helmet";
import axios from "../plugins/axios";
import { connect } from "react-redux";
import Moment from "react-moment";
import styled from "styled-components";
import { KeyboardDatePicker } from "@material-ui/pickers";
import Loader from "../components/Loader";
import Papa from "papaparse";
import {
    Typography,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Card as MuiCard,
    CardContent,
    Avatar,
    IconButton,
    CircularProgress,
    Tooltip,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Grid,
} from "@material-ui/core";
import { CloudDownload, CloudUpload } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";

import avatarStyles from "../theme/avatars";
import { spacing } from "@material-ui/system";

import { setSnackAction } from "../redux/actions/snackActions";

const Card = styled(MuiCard)(spacing);

const formStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(3),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    miniLabel: {
        fontSize: "0.7em",
        color: "#666",
    },
}));

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

const PartnerData = ({ userState, setSnack, configState }) => {
    const formClasses = formStyles();
    const avatarClasses = avatarStyles();

    const [partner, setPartner] = useState("");
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [activities, setActivities] = useState([]);
    const [loading, setLoading] = useState(false);
    const [downloading, setDownloading] = useState(false);
    const [uploading, setUploading] = useState(false);

    const partnerList = useMemo(() => {
        return Object.values(configState.partnerships).sort((a, b) =>
            a.title > b.title ? 1 : -1
        );
    }, [configState]);

    useEffect(() => {
        if (!fromDate || !toDate || !partner) {
            setActivities([]);
            return;
        }

        const getActivities = async () => {
            setLoading(true);
            let response = await axios.post(`${apiUrl}/getpartnerdata`, {
                partnerName: partner.title,
                fromDate,
                toDate,
            });
            setLoading(false);
            if (response.status === 200) setActivities(response.data);
        };

        getActivities();
    }, [partner, fromDate, toDate]);

    const handleFromDateChange = (date) => {
        if (date) {
            let fromDate = date;
            fromDate.setHours(0);
            fromDate.setMinutes(0);
            setFromDate(fromDate);
        } else {
            setFromDate(null);
        }
    };

    const handleToDateChange = (date) => {
        if (date) {
            let toDate = date;
            toDate.setHours(0);
            toDate.setMinutes(0);
            toDate.setSeconds(0);
            setToDate(toDate);
        } else {
            setToDate(null);
        }
    };

    const UserAvatar = ({ name, className }) => {
        const user = Object.values(userState.users).find(
            (x) => x.name === name && x.photo != null
        ) || { name };

        return (
            <Tooltip title={user.name}>
                <Avatar
                    alt={user.name}
                    src={user.photo}
                    className={className}
                />
            </Tooltip>
        );
    };

    const download = () => {
        setDownloading(true);

        let filename = "partnerdata.csv";
        let columns = Object.keys(activities[0]);

        let csv = Papa.unparse({ data: activities, fields: columns });
        if (csv === null) {
            setDownloading(false);
            return;
        }

        var blob = new Blob([csv]);

        var a = window.document.createElement("a");
        a.href = window.URL.createObjectURL(blob, { type: "text/plain" });
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        setDownloading(false);
    };

    const upload = async () => {
        setUploading(true);

        let response = await axios.post(`${apiUrl}/uploadpartnerdata`, {
            partnerName: partner.title,
            fromDate,
            toDate,
        });

        console.log("partner upload", response.data);

        var errorCount = Object.keys(response.data).length;

        if (errorCount === 0) setSnack("Upload complete", "success");
        else setSnack("Upload complete with errors - check console", "error");

        setUploading(false);
    };

    return (
        <React.Fragment>
            <Helmet title="Partner Data" />
            <Typography variant="h3">Partner Data</Typography>
            <Grid
                item
                style={{
                    display: "flex",
                    alignItems: "flex-end",
                    flexWrap: "wrap",
                }}
            >
                <FormControl
                    style={{ paddingBottom: "8px" }}
                    className={formClasses.formControl}
                >
                    <InputLabel>Partner</InputLabel>
                    <Select
                        value={partner}
                        onChange={(e) => setPartner(e.target.value)}
                    >
                        {partnerList.map((p) => (
                            <MenuItem key={p.id} value={p}>
                                {p.title}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl className={formClasses.formControl}>
                    <KeyboardDatePicker
                        format="dd/MM/yyyy"
                        margin="normal"
                        label="Date from"
                        value={fromDate}
                        onChange={handleFromDateChange}
                        maxDate={toDate}
                    />
                </FormControl>
                <FormControl className={formClasses.formControl}>
                    <KeyboardDatePicker
                        format="dd/MM/yyyy"
                        margin="normal"
                        label="Date to"
                        value={toDate}
                        onChange={handleToDateChange}
                        minDate={fromDate}
                    />
                </FormControl>
                {activities.length > 0 && (
                    <React.Fragment>
                        {downloading ? (
                            <CircularProgress />
                        ) : (
                            <Tooltip title="Download csv">
                                <IconButton onClick={download}>
                                    <CloudDownload />
                                </IconButton>
                            </Tooltip>
                        )}
                        {(partner?.title === "ECA" || partner?.title === "NHBF") &&
                            (uploading ? (
                                <CircularProgress />
                            ) : (
                                <Tooltip title="Upload data to partner">
                                    <IconButton onClick={upload}>
                                        <CloudUpload />
                                    </IconButton>
                                </Tooltip>
                            ))}
                    </React.Fragment>
                )}
            </Grid>
            <Card mb={6}>
                <CardContent>
                    {loading ? (
                        <Loader />
                    ) : (
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Case Id</TableCell>
                                    <TableCell>Description</TableCell>
                                    <TableCell>Date / Time</TableCell>
                                    <TableCell>Member Number</TableCell>
                                    {partner?.title === "ECA" && (
                                        <TableCell>Contract Account</TableCell>
                                    )}
                                    <TableCell>Account</TableCell>
                                    <TableCell>Duration</TableCell>
                                    <TableCell>Contact</TableCell>
                                    <TableCell>Matter Type</TableCell>
                                    <TableCell>Activity Type</TableCell>
                                    <TableCell>Adviser</TableCell>
                                    {partner?.custom1Label && (
                                        <TableCell>
                                            {partner.custom1Label ||
                                                "Custom Field"}
                                        </TableCell>
                                    )}
                                    <TableCell>Core Service</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {activities.map((a) => (
                                    <TableRow key={a.activityId}>
                                        <TableCell>{a.caseId}</TableCell>
                                        <TableCell>
                                            {a.caseDescription}
                                        </TableCell>
                                        <TableCell>
                                            <Moment format="DD/MM/YYYY HH:mm">
                                                {a.activityTime}
                                            </Moment>
                                        </TableCell>
                                        <TableCell>{a.memberNumber}</TableCell>
                                        {partner?.title === "ECA" && (
                                            <TableCell>
                                                {a.contractAccountName}
                                            </TableCell>
                                        )}
                                        <TableCell>{a.accountName}</TableCell>
                                        <TableCell>{a.duration}</TableCell>
                                        <TableCell>{a.contactName}</TableCell>
                                        <TableCell>{a.matterType}</TableCell>
                                        <TableCell>{a.activityType}</TableCell>
                                        <TableCell>
                                            <UserAvatar
                                                name={a.adviser}
                                                className={avatarClasses.small}
                                            />
                                        </TableCell>
                                        {partner?.custom1Label && (
                                            <TableCell>{a.custom1}</TableCell>
                                        )}
                                        <TableCell>{a.coreService}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    )}
                </CardContent>
            </Card>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => ({
    userState: state.userReducer,
    configState: state.configReducer,
});

const mapDispatchToProps = (dispatch) => ({
    setSnack: (message, severity) =>
        dispatch(setSnackAction(message, severity)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PartnerData);
