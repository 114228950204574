import axios from "../plugins/axios";

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

const getCalls = ({ userId, accountId }) => {
    return axios.get(`${apiUrl}/getcalls/${userId}/${accountId}`);
};

const callService = { getCalls };

export default callService;
