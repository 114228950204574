import { useMutation } from "react-query";
import queryKeys from "../../constants/queryKeys";
import caseService from "../../services/caseService";
import reactQueryClient from "../../reactQueryClient";

const useUpdateETCase = () =>
    useMutation(({ eTCase }) => caseService.updateETCase(eTCase), {
        onMutate: async ({ eTCase }) => {
            const queryKey = [queryKeys.eTCaseDetails, eTCase.caseId];
            reactQueryClient.cancelQueries(queryKey);
            const previousDetails = reactQueryClient.getQueryData(queryKey);
            reactQueryClient.setQueryData(queryKey, (prev) => ({
                ...(prev || {}),
                ...eTCase,
            }));
            return { previousDetails, queryKey };
        },
        onError: (err, newDetails, context) => {
            reactQueryClient.setQueryData(
                context.queryKey,
                context.previousDetails
            );
        },
        onSettled: (data, err, newDetails, context) => {
            reactQueryClient.invalidateQueries(context.queryKey);
        },
    });

export default useUpdateETCase;
