import React, { useState, useEffect, useCallback, useMemo } from "react";
import Helmet from "react-helmet";
import axios from "../plugins/axios";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { bindActionCreators } from "redux";
import { actionCreators } from "../redux/reducers/caseReducer";
import { setPendingEmail, setPendingRaiseCaseRequest } from "../redux/actions/flexActions";
import {
    setCreateCaseAccount,
    setActiveStep,
    setCreateCaseContact,
    resetCreateCase,
} from "../redux/actions/createCaseActions";
import ContractSelect from "../components/ContractSelect";

import {
    Avatar,
    Box,
    FormControl,
    Input,
    InputLabel,
    InputAdornment,
    Stepper,
    Step,
    StepLabel,
    Typography,
    Card,
    CircularProgress,
    CardContent,
    List,
    ListItem,
    Button,
    Table,
    TableRow,
    TableCell,
    TableBody,
    Select,
    MenuItem,
    makeStyles,
    ListSubheader,
} from "@material-ui/core";
import { Search as SearchIcon } from "react-feather";
import AccountTable from "../components/AccountTable";
import SearchFilterIcon from "@material-ui/icons/Search";
import caseActivityService from "../services/caseActivityService";
import { setSnackAction } from "../redux/actions/snackActions";
import salesforceRoles from "../constants/salesforceRoles";
import useAccountContracts from "../hooks/queries/useAccountContracts";
import AdviceTypesChips from "../components/AdviceTypesChips";
import useAdviceTypes from "../hooks/queries/useAdviceTypes";
import useAdviceTypesForUser from "../hooks/queries/useAdviceTypesForUser";

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

const useStyles = makeStyles((theme) => ({
    header: {
        paddingRight: theme.spacing(3),
    },
    stepper: {
        marginTop: theme.spacing(7),
    },
    backBtn: {
        marginBottom: theme.spacing(3),
    },
    finishStep: {
        marginLeft: theme.spacing(2),
    },
    filterAcc: {
        marginRight: theme.spacing(5),
    },
    selectedItem: {
        background: "lightgray",
    },
    noDisplay: {
        background: "none",
    },
    avatarDisplay: {
        marginRight: theme.spacing(2),
    },
    dropdownStep: {
        minWidth: "150px",
    },
    finalStep: {
        width: "300px",
    },
    iconButton: {
        width: "22px",
        height: "22px",
    },
    contactDetails: {
        display: "flex",
        flexDirection: "column",
    },
    receiveAdvice: {
        color: theme.palette.secondary.main,
    },
}));

const getSteps = () => ["Account", "Case Contact", "Contract", "Case Type", "Confirm"];

const getStepContent = (stepIndex) => {
    switch (stepIndex) {
        case 0:
            return "Select the account to be associated with this case...";
        case 1:
            return "Select a primary contact for this case...";
        case 2:
            return "Select a contract...";
        case 3:
            return "Select a case type...";
        case 4:
            return "All done! Please confirm case details";
        default:
            return "Unknown step reached" + stepIndex;
    }
};

const CreateCase = ({
    userState,
    createCaseState,
    getCase,
    flexState,
    setPendingEmail,
    setCreateCaseAccount,
    setActiveStep,
    setCreateCaseContact,
    configState,
    resetCreateCase,
    reallocateAccountCase,
    existingCaseTypeId,
    existingCaseId,
    existingAdviceTypeId,
    setOpenPopup,
}) => {
    const classes = useStyles();
    const [postCode, setPostCode] = useState("");
    const [accountName, setAccountName] = useState("");
    const [partnershipNumber, setPartnershipNumber] = useState("");
    const [postCodeQuery, setPostCodeQuery] = useState("");
    const [accountNameQuery, setAccountNameQuery] = useState("");
    const [partnershipNumberQuery, setPartnershipNumberQuery] = useState("");
    const [accountContacts, setAccountContacts] = useState([]);    
    const [caseTypeId, setCaseTypeId] = useState("");
    const [adviceTypeId, setAdviceTypeId] = useState("");   
    const [contractId, setContractId] = useState("");
    const [adviserFilter, setAdviserFilter] = useState("");
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const [loading, setLoading] = useState(false);

    const account = createCaseState.account;
    const contact = createCaseState.contact;    

    const { data: contracts } = useAccountContracts(account.accountId);
    const { data: adviceTypes } = useAdviceTypes();
    const { data: userAdviceTypes } = useAdviceTypesForUser(userState.userProfile.userId);

    let activeStep = createCaseState.activeStep;

    const steps = getSteps();

    const history = useHistory();

    const dispatch = useDispatch();

    const emptyContact = { contactId: null, name: "", position: "" };    

    const caseStageId = caseTypeId && configState.caseTypes[caseTypeId].stages[0].caseStageId;

    const handleAccountSearch = () => {
        setPostCodeQuery(postCode);
        setAccountNameQuery(accountName);
        setPartnershipNumberQuery(partnershipNumber);
    };

    const handleEnterKeyPress = (e) => {
        if (e.key === "Enter") {
            handleAccountSearch();
        }
    };

    const handleAccountClick = (a) => {
        setCreateCaseAccount({
            accountId: a.accountId,
            name: a.name,
            postCode: a.postCode,
            partnershipNumber: a.partnershipNumber,
        });
        setActiveStep(activeStep + 1);
    };

    const handleContactClick = (c) => {
        setCreateCaseContact({
            contactId: c.contactId,
            name: c.name,
            position: c.position,
            partnershipNumber: c.partnershipNumber,
        });
        setActiveStep(activeStep + 1);
    };    

    const getAccountContacts = useCallback(async () => {
        if (account.accountId) {
            //reset when account is altered then gather
            var response = await axios.get(`${apiUrl}/getaccountcontacts/${account.accountId}`);
            setAccountContacts(response.data);
            if (contact.contactId && activeStep === 1)
                setCreateCaseContact(emptyContact);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account]);  
    
    const handleSubmit = async () => {
        setLoading(true);

        //if an existingCaseTypeId is present then this is a case account reallocation
        if (existingCaseTypeId) {
            var response = await axios.post(`${apiUrl}/updatecaseaccount/${existingCaseId}/${account.accountId}/${userState.userProfile.userId}/${contact.contactId}/${contractId}/${caseTypeId}/${caseStageId}`);

            if (response.status === 200) 
                await getCase(existingCaseId, history);            

            setOpenPopup(false);
        }

        if (!existingCaseTypeId) {
            let response = await axios.post(`${apiUrl}/createcase/${account.accountId}/${userState.userProfile.userId}/${contact.contactId}/${caseTypeId}/${contractId}/${adviceTypeId}`);

            if (response.status === 200 && response.data) {
                let caseId = response.data;
                if (flexState.pendingEmail) {
                    let response2 = await axios.get(`${apiUrl}/assignemail/${caseId}/${flexState.pendingEmail}`);
                    if (response2.data.caseActivityId) {
                        window.frames[0].postMessage(
                            {
                                event: "emailAssigned",
                                taskSid: flexState.pendingEmail,
                                caseId,
                            },
                            "*"
                        );
                        setPendingEmail(null);
                    }
                }
                if (flexState.pendingRaiseCaseRequest) {
                    try {
                        await caseActivityService.addSeekAdviceActivity({
                            taskSid: flexState.pendingRaiseCaseRequest,
                            caseId,
                        });
                        window.frames[0].postMessage(
                            {
                                event: "emailAssigned",
                                taskSid: flexState.pendingRaiseCaseRequest,
                                caseId,
                            },
                            "*"
                        );
                    } catch (e) {
                        console.error(e);
                        dispatch(setSnackAction(e?.message || "Failed to add Seek Advice Activity", "error"));
                    } finally {
                        dispatch(setPendingRaiseCaseRequest(null));
                    }
                }
                await getCase(caseId, history);
            }
        }
        setLoading(false);
        resetCreateCase();
    };

    const handleCaseTypeChange = (e) => {
        let values = e.target.value.split('-');
        setCaseTypeId(values[1]);
        setAdviceTypeId(values[0]);
        setActiveStep(createCaseState.activeStep + 1);
    };    

    const selectedContract = useMemo(() => contracts?.find((c) => c.contractId === contractId), [contractId, contracts]);

    useEffect(() => {
        if (!selectedContract)
            return;

        console.log({selectedContract});

        setActiveStep(3);    
    }, [selectedContract, setActiveStep]);

    useEffect(() => {
        account && getAccountContacts();
    }, [account, getAccountContacts]);    

    const handleBackClick = () => {    
                
        if (activeStep === 3) 
            setCaseTypeId("");           

        setActiveStep(activeStep - 1);
    };

    useEffect(() => {
        setActiveStep(0);
        setCaseTypeId(existingCaseTypeId);
    }, [existingCaseTypeId, reallocateAccountCase, setActiveStep]);

   
    const coreService = selectedContract?.coreService || "";
    const isPartnership = selectedContract && selectedContract.isPartnership;

    const allCaseTypes = useMemo(() => Object.values(configState.caseTypes)
        .filter((ct) =>
            ct.isActive &&           
            ct.stages.length > 0 &&
            (ct.isAccountSpecific ? ct.accounts.map((a) => a.accountId).includes(account.accountId) : true)), [account.accountId, configState.caseTypes]);
            
    const caseTypeList = useCallback(() => {       

        if (!selectedContract)
            return [];

        let list = [];

        if (userAdviceTypes.includes(selectedContract.adviceTypeId)) {
            list.push(<ListSubheader key="MAIN">{adviceTypes.find(x => x.id === selectedContract.adviceTypeId).name}</ListSubheader>);
            allCaseTypes
                .filter(ct => (selectedContract.adviceTypeId === ct.adviceTypeId || selectedContract.adviceTypeId === ct.additionalAdviceTypeId))
                .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
                .forEach(ct => list.push(<MenuItem key={`${selectedContract.adviceTypeId}-${ct.caseTypeId}`} value={`${selectedContract.adviceTypeId}-${ct.caseTypeId}`} data-cy={ct.caseTypeId}>{ct.name}</MenuItem>));
        }
        
        if (userAdviceTypes.includes(selectedContract.additionalAdviceTypeId)) {
            list.push(<ListSubheader key="ADDITIONAL">{adviceTypes.find(x => x.id === selectedContract.additionalAdviceTypeId).name}</ListSubheader>);
            allCaseTypes
                .filter(ct => (selectedContract.additionalAdviceTypeId === ct.adviceTypeId || selectedContract.additionalAdviceTypeId === ct.additionalAdviceTypeId))
                .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
                .forEach(ct => list.push(<MenuItem key={`${selectedContract.additionalAdviceTypeId}-${ct.caseTypeId}`} value={`${selectedContract.additionalAdviceTypeId}-${ct.caseTypeId}`} data-cy={ct.caseTypeId}>{ct.name}</MenuItem>));
        }

        return list;        

    }, [adviceTypes, allCaseTypes, selectedContract]);

    return (
        <React.Fragment>
            <Helmet title="Create Case" />
            {!existingCaseTypeId && (
                <Typography variant="h3" className={classes.header}>
                    Create Case
                </Typography>
            )}
            <Stepper className={classes.stepper} activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <Box mt={6} mb={6}>
                <Typography variant="h6" className={classes.backBtn}>
                    {getStepContent(activeStep)}
                </Typography>
                {activeStep !== 0 && (
                    <Button onClick={() => handleBackClick()} variant="contained">
                        Back
                    </Button>
                )}
                {activeStep === steps.length - 1 && !loading && (
                    <Button className={classes.finishStep} onClick={handleSubmit} color="primary" variant="contained" data-cy="finish_case_creation">
                        Finish
                    </Button>
                )}
                {activeStep === steps.length - 1 && loading && <CircularProgress className={classes.finishStep} size="1.6em" />}
            </Box>
            {activeStep === 0 && (
                <React.Fragment>
                    <Box mt={6} mb={6} display="flex" justifyContent="flex-end">
                        <FormControl className={classes.filterAcc}>
                            <InputLabel>Account name</InputLabel>
                            <Input type="text" value={accountName} onChange={(e) => setAccountName(e.target.value)} onKeyPress={handleEnterKeyPress} />
                        </FormControl>
                        <FormControl className={classes.filterAcc}>
                            <InputLabel>Postcode</InputLabel>
                            <Input type="text" value={postCode} onChange={(e) => setPostCode(e.target.value)} onKeyPress={handleEnterKeyPress} />
                        </FormControl>
                        <FormControl>
                            <InputLabel>Partnership Number</InputLabel>
                            <Input
                                type="text"
                                value={partnershipNumber}
                                onChange={(e) => setPartnershipNumber(e.target.value)}
                                onKeyPress={handleEnterKeyPress}
                            />
                        </FormControl>
                        <FormControl>
                            <Button onClick={handleAccountSearch} className={classes.iconButton}>
                                <SearchIcon />
                            </Button>
                        </FormControl>
                    </Box>
                    <Card mb={6}>
                        <CardContent>
                            <AccountTable
                                postCode={postCodeQuery}
                                accountName={accountNameQuery}
                                partnershipNumber={partnershipNumberQuery}
                                clickable
                                handleClick={handleAccountClick}
                                rowsPerPage={rowsPerPage}
                                setRowsPerPage={setRowsPerPage}
                            />
                        </CardContent>
                    </Card>
                </React.Fragment>
            )}
            {activeStep === 1 && (
                <Box display="flex" justifyContent="center" mt={12}>
                    <Box>
                        <InputLabel>Primary Contact</InputLabel>
                        <Input
                            value={adviserFilter}
                            onChange={(e) => setAdviserFilter(e.target.value)}
                            data-cy="contact_search"
                            type="text"
                            startAdornment={
                                <InputAdornment position="start">
                                    <SearchFilterIcon />
                                </InputAdornment>
                            }
                        />
                        <Box display="flex" justifyContent="center">
                            <List>
                                {accountContacts
                                    .filter((u) => (u.name || "").toLowerCase().includes(adviserFilter.toLowerCase()))
                                    .map((c) => (
                                        <ListItem
                                            className={c.contactId === contact.contactId ? classes.selectedItem : classes.noDisplay}
                                            button
                                            key={c.contactId}
                                            onClick={() => handleContactClick(c)}
                                            data-cy={c.contactId}
                                        >
                                            <Avatar alt={c.name} src={c.photo} className={classes.avatarDisplay} />
                                            <div className={classes.contactDetails}>
                                                <Typography variant="body2">{c.name} - {c.position}</Typography>
                                                <AdviceTypesChips hasAdviceRole={c.clientRoles?.includes(salesforceRoles.CASENEST_RECEIVE_ADVICE)} adviceTypeIds={c.adviceTypeIds} />
                                            </div>
                                        </ListItem>
                                    ))}
                            </List>
                        </Box>
                    </Box>
                </Box>
            )}
            {activeStep === 2 && (
                <Box>
                    <Box display="flex" justifyContent="center" width="100%">                   
                        <ContractSelect
                            contracts={contracts}
                            contractId={contractId}
                            setContractId={setContractId}
                            adviceTypeId={existingAdviceTypeId}
                        />
                    </Box>
                </Box>
            )}
            {activeStep === 3 && (
                <Box display="flex" justifyContent="center" width="100%">
                    <FormControl className={classes.dropdownStep}>
                        <InputLabel>Case Type</InputLabel>                        
                        <Select value={caseTypeId ? `${adviceTypeId}-${caseTypeId}` : ""} onChange={handleCaseTypeChange} data-cy="case_type_search">
                            {caseTypeList()}                            
                        </Select>
                    </FormControl>
                </Box>
            )}
            {activeStep === 4 && (
                <Box display="flex" justifyContent="center">
                    <Table className={classes.finalStep}>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    <Typography variant="h6">Account</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="body2">
                                        {account.name}, {account.postCode}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography variant="h6">Contact</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="body2">
                                        {contact.name} - {contact.position}
                                    </Typography>
                                </TableCell>
                            </TableRow>                            
                            <TableRow>
                                <TableCell>
                                    <Typography variant="h6">Contract</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="body2">{coreService + (isPartnership ? " (Partnership)" : " (Account)")}</Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography variant="h6">Case Type</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="body2">{configState.caseTypes[caseTypeId] && configState.caseTypes[caseTypeId].name}</Typography>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Box>
            )}
        </React.Fragment>
    );
};

const mapStateToProps = (state) => ({
    userState: state.userReducer,
    flexState: state.flexReducer,
    createCaseState: state.createCaseReducer,
    configState: state.configReducer,
});

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(actionCreators, dispatch),
        setPendingEmail: (taskSid) => dispatch(setPendingEmail(taskSid)),
        setCreateCaseAccount: (account) => dispatch(setCreateCaseAccount(account)),
        setActiveStep: (step) => dispatch(setActiveStep(step)),
        setCreateCaseContact: (contact) => dispatch(setCreateCaseContact(contact)),        
        resetCreateCase: () => dispatch(resetCreateCase()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateCase);
