import React, { useEffect, useState, useCallback } from "react";
import axios from "../plugins/axios";
import { format } from "date-fns";

import { Button } from "@material-ui/core";

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

const Subscription = () => {
    const [subscriptions, setSubscriptions] = useState(null);
    const [errors, setErrors] = useState(null);

    const fetch = useCallback(async () => {
        var response = await axios.get(`${apiUrl}/subscription`);
        setSubscriptions(response.data);
    }, [setSubscriptions]);

    useEffect(() => {
        fetch();
    }, [fetch]);

    if (!subscriptions)
        return <h1>Loading...</h1>;

    const subscribe = async () => {
        setSubscriptions(null);
        try {
            var response = await axios.get(`${apiUrl}/subscribe`);
            setSubscriptions(response.data.subscriptions);
            setErrors(response.data.errors);
        } catch (e) {
            console.error(e);
        }
    };

    return (
        <React.Fragment>
            {subscriptions && subscriptions.map(s => (
                <p key={s.email}>
                    {
                        s.expiryDate == null
                            ? `Email box ${s.email} has no current subscription`
                            : `Email box ${s.email} has a subscription that expires at ${format(new Date(s.expiryDate), "Pp")}`
                    }
                </p>
            ))}
            {
                errors && errors.map((e, index) => <p key={index}>{e}</p>)
            }            
            <Button variant="contained" onClick={subscribe}>
                Create / Renew
            </Button>
        </React.Fragment>
    );
};

export default Subscription;
