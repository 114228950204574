import { makeStyles } from '@material-ui/core/styles';
import { green, red, yellow, blue, purple, teal, pink } from "@material-ui/core/colors";

const chipStyles = makeStyles(() => ({
    base: {
        width: '100%', 
        height: '20px',
        padding: '4px 0',
        fontSize: '85%',
        marginLeft: '10px',
        marginBottom: '6px'
    },
    root: {
        color: "white"
    },
    green: {
        color: "white",
        backgroundColor: green[500]
    },
    red: {
        color: "white",
        backgroundColor: red.A700
    },
    amber: {
        color: "white",
        backgroundColor: yellow.A700
    },
    blue: {
        color: "white",
        backgroundColor: blue[700]
    },
    purple: {
        color: "white",
        backgroundColor: purple[500]
    },
    teal: {
        color: "white",
        backgroundColor: teal[500]
    },
    plum: {
        color: "white",
        backgroundColor: pink[900]
    }
}));

export default chipStyles;

