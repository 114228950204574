import React from "react";
import styled, { createGlobalStyle } from "styled-components";

import { CssBaseline, Paper } from "@material-ui/core";
import Header from "../components/Header";

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: ${(props) => props.theme.body.background};
  }
`;

const Root = styled.div`
    display: flex;
    min-height: 100vh;
`;

const AppContent = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`;

const MainContent = styled(Paper)`
    flex: 1;
    background: ${(props) => props.theme.body.background};

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        flex: none;
    }

    .MuiPaper-root .MuiPaper-root {
        box-shadow: none;
    }
`;

function AuthLayout({ children }) {
    return (
        <Root>
            <CssBaseline />
            <GlobalStyle />
            <AppContent>
                <Header isMinimal />
                <MainContent>{children}</MainContent>
            </AppContent>
        </Root>
    );
}

export default AuthLayout;
