import axios from "axios";
import {setSnackAction} from "../redux/actions/snackActions";
import store from "../redux/store";

const handleResponseSuccess = (response) => {
    return response
};

const handleResponseError = (error) => {
    
    //TODO: pick up global errors such as claims exceptions
    if (error.response) {
        // server responed with an error
        if (error.response.status === 401) {
            store.dispatch(
              setSnackAction(
                `Unauthorised request to ${error.response.config.url}`,
                'error'
              )
            );
            return Promise.reject(error);
        }

        console.log('er', JSON.stringify(error.response));

        let data = error.response.data;
     
        store.dispatch(setSnackAction(typeof (data) === "string" ? data : data.title || data.message, 'error'));

        return Promise.reject(error);
    } else if (error.request) {
        // request was made but no response received
        store.dispatch(setSnackAction('No response from server', 'error'));
        return Promise.reject(error);
    } else {
        console.log('m', error.message, error.title)
        // something else happened before making the request
        store.dispatch(
          setSnackAction(error.message || 'Something went wrong.', 'error')
        );
        return Promise.reject(error);
    }
};

const instance = axios.create();

instance.interceptors.response.use(handleResponseSuccess, handleResponseError);

export const setAuthHeader = (accessToken) => {
    instance.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
    instance.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
};

export default instance;
