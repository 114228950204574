import Plugin from '@ckeditor/ckeditor5-core/src/plugin';

import { toWidget, viewToModelPositionOutsideModelElement } from '@ckeditor/ckeditor5-widget/src/utils';
import Widget from '@ckeditor/ckeditor5-widget/src/widget';

export default class DocumentLink extends Plugin {

    static get requires() {
        return [Widget];
    }

    init() {
        //console.log('DocumentLink#init() got called');

        this._defineSchema();
        this._defineConverters();        

        this.editor.editing.mapper.on(
            'viewToModelPosition',
            viewToModelPositionOutsideModelElement(this.editor.model, viewElement => viewElement.hasClass('documentlink'))
        );        
    }

    _defineSchema() {
        const schema = this.editor.model.schema;

        schema.register('documentlink', {
            allowWhere: '$text',
            isInline: true,
            isObject: true,
            allowAttributesOf: '$text',
            allowAttributes: ['precedentId','precedentName']
        });
    }

    _defineConverters() {
        const conversion = this.editor.conversion;

        const converter = (viewElement, { writer: modelWriter }) => {

            const precedentId = parseInt(viewElement.getAttribute('href').replace("{{P-", "").replace("}}", ""));
            const precedentName = viewElement.getChild(0).data;            

            return modelWriter.createElement('documentlink', { precedentId, precedentName });
        }

        conversion.for('upcast').elementToElement({
            view: {
                name: 'a',
                classes: ['documentlink']
            },
            model: converter
        });        

        conversion.for('editingDowncast').elementToElement({
            model: 'documentlink',
            view: (modelItem, { writer: viewWriter }) => {
                const widgetElement = createDocumentLinkView(modelItem, viewWriter, true);

                // Enable widget handling on a placeholder element inside the editing view.
                return toWidget(widgetElement, viewWriter);
            }
        });

        conversion.for('dataDowncast').elementToElement({
            model: 'documentlink',
            view: (modelItem, { writer: viewWriter }) => createDocumentLinkView(modelItem, viewWriter, false)
        });

        // Helper method for both downcast converters.a
        function createDocumentLinkView(modelItem, viewWriter, editing) {
            const precedentId = modelItem.getAttribute('precedentId');
            const precedentName = modelItem.getAttribute('precedentName');
            //console.log('downcast', precedentId);

            const documentLinkView = viewWriter.createContainerElement('a', {
                class: 'documentlink',
                target: "_blank",
                style: "padding: 4px 8px;margin-bottom: 4px;margin-right: 4px;background: #e1f5fe;border-radius: 2px;border-color: #d0d0d0;",
                href: editing ? null : `{{P-${precedentId}}}`
            }, {
                isAllowedInsideAttributeElement: false
            });
            
            const innerText = viewWriter.createText(precedentName);
            viewWriter.insert(viewWriter.createPositionAt(documentLinkView, 0), innerText);

            return documentLinkView;            
        }
    }
}