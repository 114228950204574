import * as types from '../constants';

const host = window.location.hostname.toString().toLowerCase();

export default function reducer(state = { currentTheme: host.indexOf("law") > -1 ? 3 : 0 }, actions) {
  switch (actions.type) {

    case types.SET_THEME:
      return {
        ...state,
        currentTheme: actions.payload
      }

    default:
      return state
  }
}
