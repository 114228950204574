import React from 'react';
import {grey} from "@material-ui/core/colors";
import {CircularProgress, makeStyles, Typography} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    headline: {
        backgroundColor: grey[100],
        borderRadius: '4px',
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(8),
        padding: theme.spacing(4),
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },
    headlineValue: {
        fontSize: '3rem',
    },
}))

function Headline({value, description, isLoading}) {
    const classes = useStyles()
    
    return (
        <div className={classes.headline}>
            {isLoading ?
                <CircularProgress />    
             : 
                <>
                    <Typography className={classes.headlineValue} variant='h1'>{value}</Typography>
                    <Typography variant='subtitle2'>{description}</Typography>
                </>
            }
        </div>
    );
}

export default Headline;