import { useMutation } from "react-query";
import queryKeys from "../../constants/queryKeys";
import caseService from "../../services/caseService";
import reactQueryClient from "../../reactQueryClient";

const useAddETClaim = () =>
    useMutation(({ caseId, eTClaim }) => caseService.addETClaim(caseId, eTClaim), {
        onMutate: async ({ eTClaim }) => {
            const queryKey = [queryKeys.eTClaims, eTClaim.eTCaseId];
            reactQueryClient.cancelQueries(queryKey);
            const previousDetails = reactQueryClient.getQueryData(queryKey);
            reactQueryClient.setQueryData(queryKey, (prev) => ([
                ...(prev || []),
                eTClaim,
            ]));
            return { previousDetails, queryKey };
        },
        onError: (err, newDetails, context) => {
            reactQueryClient.setQueryData(
                context.queryKey,
                context.previousDetails
            );
        },
        onSettled: (data, err, newDetails, context) => {
            reactQueryClient.invalidateQueries(context.queryKey);
        },
    });

export default useAddETClaim;
