import React, { useState, useMemo } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import "../vendor/perfect-scrollbar.css";
import PerfectScrollbar from "react-perfect-scrollbar";

import {
    Search as SearchIcon
} from "react-feather";

import {
    Email as EmailIcon
} from "@material-ui/icons";

import {
    Box,
    Paper as MuiPaper,
    List,
    ListItemIcon,
    ListItem,
    ListItemText,
    TextField,
    InputAdornment,
    Tooltip
} from "@material-ui/core";

const Paper = styled(MuiPaper)`
    padding: 10px;
    margin-bottom:10px;
`;

const TemplateChooser = ({ userState, onSelect }) => {
    
    const [searchText, setSearchText] = useState("");

    const filteredTemplates = useMemo(() => {
        var templates = Object.values(userState.userProfile.templates);        

        if (searchText !== "") {
            templates = templates.filter(t => t.name.toLowerCase().includes(searchText.toLowerCase()));
        }   

        return templates;
    }, [userState.userProfile.templates, searchText]);
   

    return (        
        <Paper>
            <Box display="flex" flexDirection="column">      
                <TextField
                    style={{ margin: "5px" }}
                    value={searchText}
                    onChange={e => setSearchText(e.target.value)}
                    placeholder="Search"
                    size="small"
                    variant="outlined"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        )
                    }}
                />
                <PerfectScrollbar style={{maxHeight:'152px'}}>
                    <List component="nav" dense={true}>
                        {
                            filteredTemplates.map(t =>
                                <Tooltip key={t.templateId} title={<div dangerouslySetInnerHTML={{ __html: t.htmlContent }}></div>}>
                                    <ListItem button onClick={() => { onSelect(t); }}>
                                        <ListItemIcon >
                                            <EmailIcon />
                                        </ListItemIcon>
                                        <ListItemText draggable primary={t.name} onDrop={event => console.log(event)} onDragStart={e => {e.dataTransfer.setData('html', t.htmlContent)}}/>
                                    </ListItem>
                                </Tooltip>
                                
                            )
                        }
                    </List>
                </PerfectScrollbar>                
            </Box>
        </Paper>
    );
}

export default connect(state => ({ userState: state.userReducer }))(TemplateChooser);
