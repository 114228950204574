import React, { useMemo } from "react";
import useCaseTimeEventGroupsForUser from "../hooks/queries/useCaseTimeEventGroupsForUser";
import { useSelector } from "react-redux";
import { Button } from "@material-ui/core";
import { WatchLater } from "@material-ui/icons";
import { formatTimeDigital } from "../helpers/formatTime";
import { useHistory } from "react-router-dom";

const HeldTimeButton = () => {
    const { userProfile } = useSelector((state) => state.userReducer);
    const userId = userProfile.userId;
    const heldTime = useCaseTimeEventGroupsForUser({ userId });
    const history = useHistory();

    const totalDuration = useMemo(() => {
        if (!heldTime?.data?.length) return 0;

        return heldTime.data.reduce((sum, x) => sum + x.totalDuration, 0);
    }, [heldTime.data]);

    if (!heldTime?.data?.length) return null;

    return (
        <Button
            variant="outlined"
            color="primary"
            startIcon={<WatchLater />}
            onClick={() => history.push("/heldTime")}
        >
            Held Time {formatTimeDigital(totalDuration)}
        </Button>
    );
};

export default HeldTimeButton;
