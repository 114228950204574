const caseActivityTypes = {
    CALL: "Call",
    NOTE: "Note",
    CALL_WITH_NOTE: "Call with Note",
    INTERNAL_NOTE: "InternalNote",
    EMAIL: "Email",
    MISC: "Misc",
    ACTION: "Action",
    OUTCOME: "Outcome",
    MEETING: "Meeting"
};

export default caseActivityTypes;