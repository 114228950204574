import React, { useState } from "react";
import styled from "styled-components";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import {
    CardContent,
    Card as MuiCard,
    FormControl,
    Grid,
    IconButton as MuiIconButton,
    Input,
    InputLabel,
    Typography,
    Divider as MuiDivider
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import {
    Search as SearchIcon
} from "react-feather";
import { useHistory } from "react-router-dom";
import ContactTable from "../components/ContactTable";
import { bindActionCreators } from 'redux';
import { actionCreators } from '../redux/reducers/accountReducer';

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);


const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const Contacts = ({ getAccount }) => {
    
    const [fullName, setFullName] = useState("");
    const [fullNameQuery, setFullNameQuery] = useState("");

    const [companyName, setCompanyName] = useState("");
    const [companyNameQuery, setCompanyNameQuery] = useState("");


    const [eMail, setEMail] = useState("");
    const [eMailQuery, setEMailQuery] = useState("");

    const [phoneNumber, setPhoneNumber] = useState("");
    const [phoneNumberQuery, setPhoneNumberQuery] = useState("");
    
    const [contactPageLoading, setContactPageLoading] = useState(false);  

    const history = useHistory();

    const handleContactSearch = () => {
        setFullNameQuery(fullName);
        setEMailQuery(eMail);
        setPhoneNumberQuery(phoneNumber);
        setCompanyNameQuery(companyName);
    };

    const handleClick = async account => {
        
        setContactPageLoading(true);
        //here we should expand the contact to show the linked accounts
        await getAccount(account.accountId, history);
    }

    const handleEnterKeyPress = e => {
        if (e.key === 'Enter') {
            handleContactSearch();
        }
    };   

    return (
        <React.Fragment>
            <Helmet title="Contacts" />
            <Grid container justifyContent="space-between">
                <Grid item>
                    <Typography variant="h3" gutterBottom display="inline">Contacts</Typography>
                </Grid>
                <Grid item style={{ display: "flex", alignItems: "flex-end", flexWrap: "wrap" }}>
                    <FormControl style={{ marginRight: "16px" }}>
                        <InputLabel>Contact Name</InputLabel>
                        <Input
                            type="text"
                            value={fullName}
                            onChange={e => setFullName(e.target.value)}
                            onKeyPress={handleEnterKeyPress}
                        />
                    </FormControl>
                    <FormControl style={{ marginRight: "16px" }}>
                        <InputLabel>Account Name</InputLabel>
                        <Input
                            type="text"
                            value={companyName}
                            onChange={e => setCompanyName(e.target.value)}
                            onKeyPress={handleEnterKeyPress}
                        />
                    </FormControl>
                    <FormControl style={{ marginRight: "16px" }}>
                        <InputLabel>Email</InputLabel>
                        <Input
                            type="text"
                            value={eMail}
                            onChange={e => setEMail(e.target.value)}
                            onKeyPress={handleEnterKeyPress}
                        />
                    </FormControl>
                    <FormControl>
                        <InputLabel>Phone Number</InputLabel>
                        <Input
                            type="text"
                            value={phoneNumber}
                            onChange={e => setPhoneNumber(e.target.value)}
                            onKeyPress={handleEnterKeyPress}
                        />
                    </FormControl>
                    <FormControl>
                        <IconButton onClick={handleContactSearch}>
                            <SearchIcon />
                        </IconButton>
                    </FormControl>
                </Grid>
            </Grid>
            <Divider my={6} />
            <Card mb={6}>
                <CardContent>
                    <ContactTable
                        eMail={eMailQuery}
                        fullName={fullNameQuery}
                        phoneNumber={phoneNumberQuery}
                        companyName={companyNameQuery}
                        clickable
                        handleClick={handleClick}
                        contactPageLoading={contactPageLoading}
                    />
                </CardContent>
            </Card>
        </React.Fragment>
    )
}

const mapDispatchToProps = dispatch => bindActionCreators(actionCreators, dispatch);

export default connect(null, mapDispatchToProps)(Contacts);