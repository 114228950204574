import { useQuery } from "react-query";
import queryKeys from "../../constants/queryKeys";
import axios from "../../plugins/axios";

const useAIPrompts = (accountId) =>
    useQuery([queryKeys.businessDivisions, accountId], async () => {
        let response = await axios.get(`${process.env.REACT_APP_CASENEST_API_URL}/businessDivision/getforaccount/${accountId}`);
        return response.data;
    }, {
        enabled: !!accountId,
        staleTime: 600000,
        cacheTime: 900000
    });

export default useAIPrompts;
