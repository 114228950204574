import axios from "../plugins/axios";

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

const savePrecedents = async (updatedOrder) => {
    try {
        const response = await axios.post(
            `${apiUrl}/saveprecedents/`,
            updatedOrder
        );
        return response.data;
    } catch (error) {
        throw error;
    }
};

const removePrecedent = async (precedent) => {
    try {
        const response = await axios.post(
            `${apiUrl}/removeprecedent/`,
            precedent
        );
        return response.data;
    } catch (error) {
        throw error;
    }
};

const removePrecedentFolder = async (precedentFolder) => {
    try {
        const response = await axios.post(
            `${apiUrl}/removeprecedentfolder/`,
            precedentFolder
        );
        return response.data;
    } catch (error) {
        throw error;
    }
};

const savePrecedentFolder = async (precedentDetails) => {
    try {
        const response = await axios.post(
            `${apiUrl}/saveprecedentfolder/`,
            precedentDetails
        );
        return response.data;
    } catch (error) {
        throw error;
    }
};

const uploadPrecedentDocument = async (formData) => {
    try {
        const response = await axios.post(
            `${apiUrl}/uploadprecedentdocument/`,
            formData,
            { headers: { "Content-Type": "multipart/form-data" } }
        );
        return response.data;
    } catch (error) {
        throw error;
    }
};

const savePrecedent = async (precedent) => {
    try {
        const response = await axios.post(
            `${apiUrl}/saveprecedent/`,
            precedent
        );
        return response.data;
    } catch (error) {
        throw error;
    }
};

const createPrecedentFolder = async (folderDetails) => {
    try {
        const response = await axios.post(
            `${apiUrl}/createnewprecedentfolder/`,
            folderDetails
        );
        return response.data;
    } catch (error) {
        throw error;
    }
};

const getPrecedentFolders = async () => {
    try {
        const response = await axios.post(`${apiUrl}/getprecedentfolders`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const updatePrecedentFolderOrder = async (folders) => {
    try {
        const response = await axios.post(
            `${apiUrl}/updateprecedentfolderorder/`,
            folders
        );
        return response.data;
    } catch (error) {
        throw error;
    }
};

const updatePrecedentResourceLicences = async ({ precedentId, isAdvisor, licences }) => {
    try {
        const response = await axios.post(
            `${apiUrl}/updateprecedentresourcelicences/${precedentId}/${isAdvisor}`, licences
        );
        return response.data;
    } catch (error) {
        throw error;
    }
};

const getPrecedentById = (precedentId) =>
    axios.get(`${apiUrl}/getprecedent/${precedentId}`);

const precedentService = {
    savePrecedents,
    removePrecedent,
    removePrecedentFolder,
    savePrecedentFolder,
    uploadPrecedentDocument,
    savePrecedent,
    createPrecedentFolder,
    getPrecedentFolders,
    getPrecedentById,
    updatePrecedentFolderOrder,
    updatePrecedentResourceLicences,
};

export default precedentService;
