import featureFlagService from "../services/featureFlagService";

const isRouteAllowed = (route, hasRole) => {
    return route.requiredRoles == null || route.requiredRoles.some((rr) => hasRole(rr));
};

const isRouteEnabled = async (route) => {
    if (!route.featureFlagName) return true;
    return await featureFlagService.getFeatureFlag(route.featureFlagName);
};

const filterAllowedRoutesByFeatureFlag = async (routes, hasRole) => {
    const allowedRoutes = [];
    for (const route of routes) {
        if (route.children) {
            const children = await filterAllowedRoutesByFeatureFlag(route.children, hasRole);
            if (children.length > 0) {
                allowedRoutes.push({
                    ...route,
                    children,
                });
            }
        } else if (isRouteAllowed(route, hasRole)) {
            if (route.featureFlagName) {
                const featureIsEnabled = await isRouteEnabled(route);
                if (featureIsEnabled) {
                    allowedRoutes.push(route);
                }
            } else {
                allowedRoutes.push(route);
            }
        }
    }
    return allowedRoutes;
};

const filterAllowedRoutes = (routes, hasRole) => {
    if (!routes) return null;

    const children = routes.filter((r) => isRouteAllowed(r, hasRole));

    return children.length > 0 ? children : null;
};

const routeUtils = {
    isRouteAllowed,
    filterAllowedRoutes,
    filterAllowedRoutesByFeatureFlag,
};

export default routeUtils;
