import React, { useState, useEffect }  from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    makeStyles,
    TextField,
    Typography,
} from "@material-ui/core";
import { MAX_ACTIVITY_DESCRIPTION } from "../../constants/lengthConstants";
import caseService from "../../services/caseService";
import { useDispatch } from "react-redux";
import {
    setCaseActivityDescription,
} from "../../redux/actions/caseActions";

const useStyles = makeStyles((theme) => ({
    title: {
        fontSize: 18,
        fontWeight: 500,
    },
    content: {
        width: "100%",
        [theme.breakpoints.up("sm")]: {
            width: 400,
        },
        [theme.breakpoints.up("xl")]: {
            width: 500,
        },
    },
    input: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
}));

const EditActivityDescriptionDialog = ({
    open,
    activity,
    handleClose,
    caseId,
}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [description, setDescription] = useState(activity?.activityDescription);

    useEffect(() => {
        setDescription(activity?.activityDescription);
    }, [open]);

    const handleSave = async () => {
        try {
            await caseService.setCaseActivityDescription(
                activity.caseActivityId,
                description
            );
            dispatch(
                setCaseActivityDescription(
                    description,
                    caseId,
                    activity.caseActivityId
                )
            );
            handleClose();
        } catch (e) {
            console.error(e);
        }
    };

    const handleChange = (newDescription) => {
        setDescription(newDescription);
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="edit-activity-description-dialog-title"
            aria-describedby="edit-activity-description-dialog-description"
        >
            <DialogTitle id="edit-activity-description-dialog-title">
                <Typography
                    className={classes.title}
                    color="primary"
                    variant="body1"
                >
                    Description
                </Typography>
            </DialogTitle>
            <DialogContent className={classes.content}>
                <TextField
                    className={classes.input}
                    fullWidth
                    value={description}
                    onChange={(e) => handleChange(e.target.value)}
                    multiline
                    rows={4}
                    variant="outlined"
                    inputProps={{ maxLength: MAX_ACTIVITY_DESCRIPTION }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="secondary" variant="text">
                    Cancel
                </Button>
                <Button
                    onClick={handleSave}
                    color="secondary"
                    variant="text"
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditActivityDescriptionDialog;
