import { useSelector } from "react-redux";
import useAdviceTypes from "../hooks/queries/useAdviceTypes";
import useAdviceTypesForUser from "../hooks/queries/useAdviceTypesForUser";
import AdviceTypeChip from "./AdviceTypeChip";

import {
    Box,
} from "@material-ui/core";

const AdviceTypesChips = ({ adviceTypeIds, hasAdviceRole }) => {    

    const { userProfile } = useSelector((state) => state.userReducer);
    const { data: adviceTypes } = useAdviceTypes();
    const { data: userAdviceTypes } = useAdviceTypesForUser(userProfile.userId);

    if (!adviceTypeIds || !adviceTypes || !userAdviceTypes)
        return null;  

    let types = adviceTypes.filter(at => adviceTypeIds.includes(at.id) && userAdviceTypes.includes(at.id));

    if (types.length === 0 || !hasAdviceRole)
        return <Box display="flex"><AdviceTypeChip adviceType="Unauthorised" /></Box>;

    return <Box display="flex">{types.map(t => <AdviceTypeChip key={t.name} adviceType={t.name} />)}</Box>;
};

export default AdviceTypesChips;

