import { useQuery } from "react-query";
import queryKeys from "../../constants/queryKeys";
import axios from "../../plugins/axios";

const useAccountAdvisers = (accountId) =>
    useQuery([queryKeys.accountAdvisers, accountId], async () => {
        let response = await axios.get(`${process.env.REACT_APP_CASENEST_API_URL}/getaccountadvisers/${accountId}`);
        return response.data;
    }, {
        enabled: !!accountId,
        staleTime: 600000,
        cacheTime: 900000
    });

export default useAccountAdvisers;
