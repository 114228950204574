import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { bindActionCreators } from "redux";
import { actionCreators } from "../redux/reducers/caseReducer";
import { setSnackAction } from "../redux/actions/snackActions";
import useCaseActionTypes from "../hooks/queries/useCaseActionTypesAll";
import useCaseActions from "../hooks/queries/useCaseActions";
import useCaseActionsDashboard from "../hooks/queries/useCaseActionsDashboard";
import useCaseActionCategories from "../hooks/queries/useCaseActionCategories";
import useUpdateCaseAction from "../hooks/mutations/useUpdateCaseAction";
import useTeams from "../hooks/queries/useTeams";
import Headline from "../components/Headline";
import CaseActionDialog from "../components/CaseActionDialog";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";

import {
    makeStyles,
    Paper,
    Grid,
    FormControl,
    FormControlLabel,
    InputLabel,
    Switch,
    Select,
    MenuItem,
    CircularProgress,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Box,
    Tooltip,
    Avatar,
    Button,
    IconButton,
} from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import {
    CalendarTodayRounded as CalendarIcon,
    Replay,
    PriorityHighRounded as PriorityHigh,
    Edit as EditIcon,
    TableChart as TableIcon,
} from "@material-ui/icons";
import { tableDateTime } from "../utils/dateTimeFormatters";
import { red } from "@material-ui/core/colors";
import avatarStyles from "../theme/avatars";
import useFilteredAdvisers from "../hooks/useFilteredAdvisers";
import { getDstCorrectedDateString } from "../helpers/dateHelpers";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(4),
    },
    datePicker: {
        marginTop: 0,
        marginBottom: 0,
    },
    formControl: {
        minWidth: 180,
    },
    formControlSwitch: {
        marginTop: 18,
    },
    resetButton: {
        marginTop: theme.spacing(2),
        marginLeft: "auto",
        marginRight: theme.spacing(1),
    },
    miniLabel: {
        fontSize: "0.7em",
        color: "#666",
    },
    importantRow: {
        backgroundColor: "#FFCCCB",
    },
    completeRow: {
        backgroundColor: "#EEF6EF",
    },
    importantIcon: {
        color: red[800],
    },
    headlinesContainer: {
        display: "flex",
        gap: theme.spacing(4),
    },
    viewButton: {
        marginTop: theme.spacing(2),
        marginRight: theme.spacing(1),
    },
    eventSummary: {
        margin: theme.spacing(1),
    },
}));

const tableHeaders = [
    { id: "category", label: "Category" },
    { id: "actiontype", label: "ActionType" },
    { id: "summary", label: "Summary" },
    { id: "due", label: "Due Date" },
    { id: "adviser_team", label: "Adviser / Team" },
    { id: "case_description", label: "Case Description" },
    { id: "caseid", label: "Case Number" },
    { id: "edit", label: "Edit" },
];

function ActionsDashboard({ getCase, setSnack }) {
    const classes = useStyles();
    const history = useHistory();
    const avatarClasses = avatarStyles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const userState = useSelector((state) => state.userReducer);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [includeCompleted, setIncludeCompleted] = useState(false);
    const [adviserId, setAdviserId] = useState(0);
    const [actionTypeId, setActionTypeId] = useState(0);
    const [actionCategoryId, setActionCategoryId] = useState(0);
    const [teamId, setTeamId] = useState(0);
    const [activeAction, setActiveAction] = useState({});
    const [open, setOpen] = useState(false);
    const { data: caseActions } = useCaseActions({
        caseId: activeAction.caseId,
    });
    const [displayTable, setDisplayTable] = useState(true);
    const filteredAdvisers = useFilteredAdvisers();

    const {
        data: actionTypes,
        isLoading: isActionTypesLoading,
        isError: isActionTypesError,
        error: actionTypesError,
    } = useCaseActionTypes();

    const {
        data: actionCategories,
        isLoading: isActionCategoriesLoading,
        isError: isActionCategoriesError,
        error: actionCategoriesError,
    } = useCaseActionCategories({ dialogOpen: true });

    const {
        data: teams,
        isLoading: isTeamsLoading,
        isError: isTeamsError,
        error: teamsError,
    } = useTeams({ dialogOpen: true });

    const { data, isLoading, isError, error } = useCaseActionsDashboard({
        pageSize: rowsPerPage,
        pageNum: page + 1,
        fromDate,
        toDate,
        adviserId,
        actionTypeId,
        actionCategoryId,
        teamId,
        includeCompleted,
        isPaged: displayTable,
    });

    const {
        mutate: updateCaseAction,
        isError: isUpdateCaseActionError,
        error: updateCaseActionError,
        isSuccess: isUpdateCaseActionSuccess,
    } = useUpdateCaseAction();

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const handleFromDateChange = (date) => {
        if (date == null) setFromDate(null);
        if (date) {
            let fromDate = date;
            fromDate.setHours(0, 0, 0);
            setFromDate(fromDate);
        }
    };

    const handleToDateChange = (date) => {
        if (date == null) setToDate(null);
        if (date) {
            let toDate = date;
            toDate.setHours(23, 59, 59);
            setToDate(toDate);
        }
    };

    const handleChangeAdviserTeam = (adviserId, teamId) => {
        setAdviserId(adviserId);
        setTeamId(teamId);
    };

    const handleUpdateFromDragDrop = (updateData) => {
        let actionToSave = {
            ...updateData.event.extendedProps.entry,
            due: getDstCorrectedDateString(new Date(updateData.event.start)),
        };

        updateCaseAction({ caseAction: actionToSave });
    };

    useEffect(() => {
        if (isUpdateCaseActionError)
            setSnack(
                `There was an error updating the action: ${updateCaseActionError?.message}`,
                "error"
            );
    }, [isUpdateCaseActionError]);

    useEffect(() => {
        if (isUpdateCaseActionSuccess) {
            setSnack("Successfully moved the action!", "success");
        }
    }, [isUpdateCaseActionSuccess]);

    const resetFilters = async () => {
        setFromDate(null);
        setToDate(null);
        setIncludeCompleted(false);
        setAdviserId(0);
        setActionTypeId(0);
        setActionCategoryId(0);
        setTeamId(0);
    };

    const ActionFor = (entry) => {
        const hasAdviser = entry.caseAction.adviserIds?.length == 1;
        const hasAdvisers = entry.caseAction.adviserIds?.length > 1;
        const hasTeam =
            entry.caseAction.teamUserIds &&
            entry.caseAction.teamUserIds.length > 0;
        return (
            <Box display="flex" flexDirection="column">
                {hasAdviser && (
                    <Typography className={classes.miniLabel}>
                        Adviser
                    </Typography>
                )}
                {hasAdviser && (
                    <UserAvatar
                        id={entry.caseAction.adviserIds[0]}
                        className={avatarClasses.small}
                    />
                )}
                {hasAdvisers && (
                    <Typography className={classes.miniLabel}>
                        Advisers
                    </Typography>
                )}
                {hasAdvisers && (
                    <Box display="flex" flexDirection="row">
                        {entry.caseAction.adviserIds.map((id) => (
                            <UserAvatar
                                id={id}
                                key={id}
                                className={avatarClasses.small}
                            />
                        ))}
                    </Box>
                )}
                {hasTeam && (
                    <Typography className={classes.miniLabel}>Team</Typography>
                )}
                {hasTeam && (
                    <Box display="flex" flexDirection="row">
                        {entry.caseAction.teamUserIds.map((id) => (
                            <UserAvatar
                                id={id}
                                key={id}
                                className={avatarClasses.small}
                            />
                        ))}
                    </Box>
                )}
            </Box>
        );
    };

    const UserAvatar = ({ id, className }) => {
        const user = userState.users[id] || { name: "???" };

        return (
            <Tooltip title={user.name}>
                <Avatar
                    alt={user.name}
                    src={user.photo}
                    className={className}
                />
            </Tooltip>
        );
    };

    const selectCase = async (caseId) => {
        await getCase(caseId, history);
    };

    const handleClickEditAction = (action) => {
        setActiveAction(action);
        setOpen(true);
    };

    const tableBody = () => {
        if (isLoading)
            return (
                <TableRow>
                    <TableCell colSpan={6}>
                        <CircularProgress size={40} />
                    </TableCell>
                </TableRow>
            );

        if (isError)
            return (
                <TableRow>
                    <TableCell colSpan={6}>
                        <Typography>
                            {error?.message ||
                                "Unable to load case action data."}
                        </Typography>
                    </TableCell>
                </TableRow>
            );

        if (data.caseActions.length === 0)
            return (
                <TableRow>
                    <TableCell colSpan={6}>
                        <Typography>No Case Action data.</Typography>
                    </TableCell>
                </TableRow>
            );

        return data.caseActions.map((entry) => (
            <TableRow
                key={entry.id}
                hover
                className={entry.completedOn ? classes.completeRow : null}
            >
                <TableCell>
                    <Box display="flex" flexDirection="row">
                        {entry.important && (
                            <PriorityHigh className={classes.importantIcon} />
                        )}
                        {entry.category}
                    </Box>
                </TableCell>
                <TableCell>{entry.type}</TableCell>
                <TableCell>{entry.summary}</TableCell>
                <TableCell>{tableDateTime(entry.due)}</TableCell>
                <TableCell>
                    <ActionFor caseAction={entry} />
                </TableCell>
                <TableCell>{entry?.caseDescription || "N/A"}</TableCell>
                <TableCell>
                    <Button onClick={() => selectCase(entry.caseId)}>
                        {entry?.caseId || "N/A"}
                    </Button>
                </TableCell>
                <TableCell>
                    {!entry.completedOn && (
                        <IconButton
                            onClick={() => {
                                handleClickEditAction(entry);
                            }}
                        >
                            <EditIcon />
                        </IconButton>
                    )}
                </TableCell>
            </TableRow>
        ));
    };

    const handleViewChange = (view) => {
        if (view) {
            setFromDate(null);
            setToDate(null);
            setIncludeCompleted(false);
        }
        setDisplayTable((view) => !view);
    };

    const handleEventClick = ({ event }) => {
        setActiveAction(event.extendedProps.entry);
        setOpen(true);
    };

    return (
        <Paper className={classes.paper}>
            <Grid container alignItems="flex-start" spacing={2}>
                {displayTable && (
                    <>
                        <Grid item>
                            <FormControl>
                                <KeyboardDatePicker
                                    className={classes.datePicker}
                                    label="Action Date From"
                                    value={fromDate}
                                    onChange={handleFromDateChange}
                                    maxDate={toDate}
                                    maxDateMessage="From Date should not be after End Date selected"
                                    format="dd/MM/yyyy"
                                />
                            </FormControl>
                        </Grid>

                        <Grid item>
                            <FormControl>
                                <KeyboardDatePicker
                                    className={classes.datePicker}
                                    label="Action Date To"
                                    value={toDate}
                                    onChange={handleToDateChange}
                                    minDate={fromDate}
                                    minDateMessage="To Date should not be before From Date selected"
                                    format="dd/MM/yyyy"
                                />
                            </FormControl>
                        </Grid>
                    </>
                )}

                {isActionCategoriesLoading && <CircularProgress />}
                {isActionCategoriesError && (
                    <Typography>
                        {actionCategoriesError?.message ||
                            "Failed to load action categories"}
                    </Typography>
                )}
                <Grid item>
                    <FormControl className={classes.formControl}>
                        <InputLabel>Action Category</InputLabel>
                        {data && actionCategories && (
                            <Select
                                value={actionCategoryId}
                                onChange={(e) =>
                                    setActionCategoryId(e.target.value)
                                }
                            >
                                <MenuItem value="0">Any</MenuItem>

                                {actionCategories
                                    .filter((x) =>
                                        data.filteredCategoryIds.includes(x.id)
                                    )
                                    .map((t) => (
                                        <MenuItem value={t.id} key={t.id}>
                                            {t.description}
                                        </MenuItem>
                                    ))}
                            </Select>
                        )}
                    </FormControl>
                </Grid>

                {isActionTypesLoading && <CircularProgress />}
                {isActionTypesError && (
                    <Typography>
                        {actionTypesError?.message ||
                            "Failed to load action types"}
                    </Typography>
                )}
                <Grid item>
                    <FormControl className={classes.formControl}>
                        <InputLabel>Action Type</InputLabel>
                        {data && actionTypes && (
                            <Select
                                value={actionTypeId}
                                onChange={(e) =>
                                    setActionTypeId(e.target.value)
                                }
                            >
                                <MenuItem value="0">Any</MenuItem>

                                {actionTypes
                                    .filter((x) =>
                                        data.filteredActionTypeIds.includes(
                                            x.id
                                        )
                                    )
                                    .map((t) => (
                                        <MenuItem value={t.id} key={t.id}>
                                            {t.description}
                                        </MenuItem>
                                    ))}
                            </Select>
                        )}
                    </FormControl>
                </Grid>

                {isTeamsLoading && <CircularProgress />}
                {isTeamsError && (
                    <Typography>
                        {teamsError?.message || "Failed to load Teams"}
                    </Typography>
                )}
                <Grid item>
                    <FormControl className={classes.formControl}>
                        <InputLabel>Team</InputLabel>
                        {data && teams && (
                            <Select
                                value={teamId}
                                onChange={(e) =>
                                    handleChangeAdviserTeam(0, e.target.value)
                                }
                            >
                                <MenuItem value="0">Any</MenuItem>

                                {teams
                                    .filter((x) =>
                                        data.filteredTeamIds.includes(x.teamId)
                                    )
                                    .map((t) => (
                                        <MenuItem
                                            value={t.teamId}
                                            key={t.teamId}
                                        >
                                            {t.name}
                                        </MenuItem>
                                    ))}
                            </Select>
                        )}
                    </FormControl>
                </Grid>

                <Grid item>
                    <FormControl className={classes.formControl}>
                        <InputLabel>Adviser</InputLabel>
                        {data && (
                            <Select
                                value={adviserId}
                                onChange={(e) =>
                                    handleChangeAdviserTeam(e.target.value, 0)
                                }
                            >
                                <MenuItem value="0">Any</MenuItem>
                                {filteredAdvisers
                                    .filter((x) =>
                                        data.filteredAdviserIds.includes(
                                            x.userId
                                        )
                                    )
                                    .sort((a, b) =>
                                        a.name > b.name
                                            ? 1
                                            : b.name > a.name
                                            ? -1
                                            : 0
                                    )
                                    .map((u) => (
                                        <MenuItem
                                            key={u.userId}
                                            value={u.userId}
                                        >
                                            {u.name}
                                        </MenuItem>
                                    ))}
                            </Select>
                        )}
                    </FormControl>
                </Grid>

                {displayTable && (
                    <Grid item>
                        <FormControlLabel
                            className={classes.formControlSwitch}
                            control={
                                <Switch
                                    size="small"
                                    checked={includeCompleted}
                                    onChange={(e) =>
                                        setIncludeCompleted(e.target.checked)
                                    }
                                />
                            }
                            labelPlacement="start"
                            color="primary"
                            label="Include Completed Actions"
                        />
                    </Grid>
                )}
            </Grid>
            <div className={classes.headlinesContainer}>
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    mx={1}
                >
                    <Box textAlign="center">
                        <Button
                            fullWidth
                            startIcon={<Replay />}
                            className={classes.viewButton}
                            variant="contained"
                            color="secondary"
                            onClick={resetFilters}
                        >
                            Reset All Filters
                        </Button>
                    </Box>
                    <Box textAlign="center">
                        <Button
                            fullWidth
                            startIcon={
                                displayTable ? <CalendarIcon /> : <TableIcon />
                            }
                            className={classes.viewButton}
                            variant="contained"
                            color="secondary"
                            onClick={() => handleViewChange(displayTable)}
                        >
                            {displayTable ? "Calendar View" : "Grid/Table View"}
                        </Button>
                    </Box>
                </Box>

                <Headline
                    description="Actions Due in the next week"
                    value={data && data.dueNextWeekCount}
                    isLoading={isLoading}
                />
                <Headline
                    description="Actions Due today"
                    value={data && data.dueTodayCount}
                    isLoading={isLoading}
                />
                <Headline
                    description="Actions overdue"
                    value={data && data.overdueCount}
                    isLoading={isLoading}
                />
            </div>
            {!displayTable && data ? (
                <FullCalendar
                    plugins={[dayGridPlugin, interactionPlugin]}
                    dayMaxEvents={0}
                    moreLinkContent={function (args) {
                        return args.num;
                    }}
                    firstDay={1}
                    eventClick={handleEventClick}
                    eventTimeFormat={{
                        hour: "numeric",
                        minute: "2-digit",
                        meridiem: "short",
                    }}
                    events={data.caseActions.map((entry) => ({
                        id: entry.id,
                        title: "Case #" + entry.caseId + " - " + entry.summary,
                        editable: true,
                        display: "block",
                        backgroundColor: entry.important
                            ? "#D22B2B"
                            : "#3788d8",
                        start: entry.due,
                        end: entry.due,
                        allDay: false,
                        clickable: true,
                        overlap: true,
                        extendedProps: { entry },
                    }))}
                    eventDrop={(e) => handleUpdateFromDragDrop(e)}
                />
            ) : (
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {tableHeaders.map((header) => (
                                    <TableCell key={header.id}>
                                        {header.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>{tableBody()}</TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[20, 50, 100]}
                        component="div"
                        count={(data && data.actionCount) || 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </TableContainer>
            )}
            <CaseActionDialog
                open={open}
                setOpen={setOpen}
                caseId={activeAction.caseId}
                action={activeAction}
                setAction={setActiveAction}
                caseActions={caseActions}
            />
        </Paper>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(actionCreators, dispatch),
        setSnack: (message, severity) =>
            dispatch(setSnackAction(message, severity)),
    };
};

export default connect(null, mapDispatchToProps)(ActionsDashboard);
