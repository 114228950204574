import React from "react";
import { 
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button, 
    makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    dialogActions: {
        padding: theme.spacing(2, 3),
    },
}));

export default function SecurityTimeoutDialog({ open, handleStayLoggedIn, logout }) {
    const classes = useStyles();
    
    const fifteenMinutes = 15 * 60 * 1000;

    return (
        <Dialog open={open}>
            <DialogTitle>Security Timeout</DialogTitle>
            <DialogContent>
                Your session will automatically log out at <strong>{new Date(Date.now() + fifteenMinutes).toLocaleTimeString()}</strong>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleStayLoggedIn}
                >
                    Stay Logged In
                </Button>
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={logout}
                >
                    Log Out
                </Button>
            </DialogActions>
        </Dialog>
    )
}