import axios from "../plugins/axios";

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

const saveDraft = (emailDraft) =>
    axios.post(
        `${apiUrl}/emaildraft/save`,
        buildSaveDraftFormData(emailDraft),
        {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        }
    );

const getSummary = (caseId) =>
    axios.get(`${apiUrl}/emaildraft/summary/${caseId}`);
    
const getById = (draftId) =>
    axios.get(`${apiUrl}/emaildraft/${draftId}`);

const removeDraft = (draftId) =>
    axios.delete(`${apiUrl}/emaildraft/remove/${draftId}`);

const removeDraftAttachment = (azureFileId, emailDraftId) =>
    axios.delete(`${apiUrl}/emaildraft/attachment/remove/${azureFileId}/${emailDraftId}`);

const buildSaveDraftFormData = (emailDraft) => {
    const formData = new FormData();
    formData.append("caseId", emailDraft.caseId);
    formData.append("content", emailDraft.content);
    formData.append("replyContactsJson", emailDraft.replyContactsJson);
    formData.append("replyContactsNotKnownJson", emailDraft.replyContactsNotKnownJson);
    formData.append("subject", emailDraft.subject);
    formData.append("ccContactsJson", emailDraft.ccContactsJson);
    formData.append("ccNonContactsJson", emailDraft.ccNonContactsJson);
    formData.append("ccUserIdsJson", emailDraft.ccUserIdsJson);
    formData.append("description", emailDraft.description);
    formData.append("precedentsJson", JSON.stringify(emailDraft.precedents));

    formData.append("stringifiedFiles", JSON.stringify(emailDraft.attachments.map(file => ({ reference: file.id, name: file.file.name, type: file.file.type, size: file.file.size, azureFileId: file.azureFileId }))));

    for (const azureFileId of emailDraft.azureFileIds) {
        formData.append("azureFileIds", azureFileId);
    }

    for (const liveCaseDocumentId of emailDraft.liveCaseDocumentIds) {
        formData.append("liveCaseDocumentIds", liveCaseDocumentId);
    }
    
    if (emailDraft.draftEmailId)
        formData.append("draftEmailId", emailDraft.draftEmailId);

    return formData;
};

const emailDraftService = {
    saveDraft,
    removeDraft,
    removeDraftAttachment,
    getSummary,
    getById,
};

export default emailDraftService;
