import { useMutation } from "react-query";
import queryKeys from "../../constants/queryKeys";
import accountService from "../../services/accountService";
import reactQueryClient from "../../reactQueryClient";

const useAddAccountFile = () =>
    useMutation(
        ({ formData, accountId, fileType, documentType }) =>
            accountService.uploadAccountFile(formData, accountId, fileType, documentType)
        ,
        {
            onSuccess: (data) => {
                const queryKey = [queryKeys.accountFiles, data.fileType, data.documentType, data.accountId];
                reactQueryClient.setQueryData(queryKey, (old) => [...(old || []), data]);
            },
        }
    );

export default useAddAccountFile;
