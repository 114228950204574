import React from 'react'
import { Button, makeStyles } from '@material-ui/core';



const useStyles = makeStyles(theme => ({
    root: {
        margin: theme.spacing(0.5)
    },
    label: {
        textTransform:'none'
    }
}))


export default function ButtonControl(props) {


    const { text, size, color, variant, onClick, name, disabled, ...other } = props;
    const classes = useStyles();

    return (
        <Button
            //where the variant is not passed assume contained
            variant={variant || "contained"}
            size={size || "large"}
            color={color || "primary"}
            onClick={onClick}
            name={name}
            disabled={disabled}
            {...other}
            classes={{ root: classes.root, label: classes.label }}>

            {text}
        </Button>
    )
}