import React from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    dialogTitle: {
        color: theme.palette.primary.main,
    },
    dialogContent: {
        minWidth: 300,
    },
}));

const ConfirmRemoveBudgetDialog = ({ open, onClose, onRemove }) => {
    const classes = useStyles();
    return (
        <Dialog open={open} onClose={() => onClose(false)}>
            <DialogTitle className={classes.dialogTitle}>
                Remove Budget?
            </DialogTitle>
            <DialogContent className={classes.dialogContent} />
            <DialogActions>
                <Button
                    color="secondary"
                    variant="text"
                    onClick={() => onClose(false)}
                >
                    Cancel
                </Button>
                <Button color="secondary" variant="text" onClick={onRemove}>
                    Remove
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmRemoveBudgetDialog;
