import { useQuery } from "react-query";
import queryKeys from "../../constants/queryKeys";
import caseActionService from "../../services/caseActionService";

const useCaseActionTypesAll = () => useQuery(
    [queryKeys.caseActionTypesAll],
    () => caseActionService.getAllCaseActionTypes(),
    {
        staleTime: Infinity
    }
)

export default useCaseActionTypesAll