import React, { useState, useEffect, useMemo } from "react";
import Helmet from "react-helmet";
import styled from "styled-components";
import axios from "../plugins/axios";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { setCreateContactActiveStep, setCreateContactAccount, resetCreateContact } from "../redux/actions/createContactActions";
import { setSnackAction } from "../redux/actions/snackActions";
import { bindActionCreators } from "redux";
import { actionCreators } from "../redux/reducers/accountReducer";
import { spacing } from "@material-ui/system";
import { Controls } from "../helpers/Controls";
import {
    Box,
    Collapse,
    FormControl,
    Checkbox,
    FormControlLabel,
    Input,
    InputLabel,
    InputAdornment,
    IconButton as MuiIconButton,
    Stepper,
    Step,
    StepLabel,
    Typography,
    Card,
    CircularProgress,
    CardContent,
    Button,
    TableHead,
    Table,
    TableRow,
    TableCell,
    TableContainer,
    TableBody,
    Grid,
    TextField as MuiTextField,
    FormLabel,
} from "@material-ui/core";
import { Search as SearchIcon } from "react-feather";
import AccountContactTable from "../components/AccountContactTable";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import DupeMatchIcon from "@material-ui/icons/ReportProblemRounded";
import UpdateRecordIcon from "@material-ui/icons/Undo";
import { setCaseContacts, setReplyContactsAction } from "../redux/actions/caseActions";
import { emailIsValid } from "../utils/validators";
import contactService from "../services/contactService";
import anonymousNumber from "../constants/anonymousNumber";
import useAdviceTypesForUser from "../hooks/queries/useAdviceTypesForUser";
import useAccountContracts from "../hooks/queries/useAccountContracts";
import { useSelector } from "react-redux";
import useAdviceTypes from "../hooks/queries/useAdviceTypes";
import AdviceTypeChip from "../components/AdviceTypeChip";

const IconButton = styled(MuiIconButton)`
    svg {
        width: 22px;
        height: 22px;
    }
`;

const TextField = styled(MuiTextField)(spacing);

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

const getSteps = () => ["Account", "Contact Details", "Confirm"];

const getStepContent = (stepIndex) => {
    switch (stepIndex) {
        case 0:
            return "Select the account associated with this contact...";
        case 1:
            return "Enter contact details...";
        case 2:
            return "All done! Please confirm contact details";
        default:
            return "Unknown step reached";
    }
};

const CreateContactWizard = ({
    createContactState,
    getAccount,
    setCreateContactActiveStep,
    setCreateContactAccount,
    resetCreateContact,
    setCaseContacts,
    caseState,
    setReplyContacts,
}) => {

    const [postCode, setPostCode] = useState("");
    const [accountName, setAccountName] = useState("");
    const [contactName, setContactName] = useState("");
    const [partnershipNumber, setPartnershipNumber] = useState("");
    const [postCodeQuery, setPostCodeQuery] = useState("");
    const [accountNameQuery, setAccountNameQuery] = useState("");
    const [contactNameQuery, setContactNameQuery] = useState("");
    const [partnershipNumberQuery, setPartnershipNumberQuery] = useState("");

    const [loading, setLoading] = useState(false);

    const [contactId, setContactId] = useState(null);
    const [externalId, setExternalId] = useState(null);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [position, setPosition] = useState("");
    const [mobile, setMobile] = useState("");
    const [email2, setEmail2] = useState("");
    const [emailOptOut, setEmailOptOut] = useState(false);
    const [adviceTypeIds, setAdviceTypeIds] = useState([]);

    const toggleAdviceTypeId = (id) => {
        let ids = adviceTypeIds.includes(id)
            ? adviceTypeIds.filter(x => x !== id)
            : [...adviceTypeIds, id];

        setAdviceTypeIds(ids);     
    }

    const activeStep = createContactState.activeStep;
    const setActiveStep = setCreateContactActiveStep;

    //as replace by :
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");

    const account = createContactState.account;

    const { userProfile } = useSelector(state => state.userReducer);
    const { data: userAdviceTypes } = useAdviceTypesForUser(userProfile.userId);
    const { data: adviceTypes } = useAdviceTypes();
    const { data: contracts } = useAccountContracts(account.accountId);

    const allowedAdviceTypes = useMemo(() => {
        if (!userAdviceTypes || !contracts)
            return [];

        let value = adviceTypes;

        value = value.filter(at => userAdviceTypes.includes(at.id));

        let contractAdviceTypeIds = contracts.map(c => c.adviceTypeId);

        value = value.filter(at => contractAdviceTypeIds.includes(at.id));

        return value;
    }, [userAdviceTypes, contracts, adviceTypes]);

    const [isFormInvalid, setIsFormInvalid] = useState(false);

    const steps = getSteps();

    const history = useHistory();

    const handleAccountSearch = () => {
        setPostCodeQuery(postCode);
        setAccountNameQuery(accountName);
        setPartnershipNumberQuery(partnershipNumber);
        setContactNameQuery(contactName);
    };

    const handleEnterKeyPress = e => {
        if (e.key === "Enter") {
            handleAccountSearch();
        }
    };

    const handleBackClick = () => {
        if (contactId) setCheckForDupes(false);

        //if we are going back to the account chooser then dupes can be rechecked
        if (activeStep === 1) {
            setDupeRecs(null);
            setCheckForDupes(true);
            setContactId(null);
            setExternalId(null);
        }

        setActiveStep(activeStep - 1);
    };

    const handleNextClick = () => {
        if (!validateForm()) {
            setIsFormInvalid(true);
            return;
        }
        setActiveStep(activeStep + 1);
    };

    const validateForm = () => {
        var nameLetterCount = firstName.length + lastName.length;
        if (firstName === "") return false;
        if (lastName === "") return false;
        if (phone && !/^[+]*(?=.*\d)[\d ]+$/.test(phone)) return false;
        if (mobile && !/^[+]*(?=.*\d)[\d ]+$/.test(mobile)) return false;
        if (
            (phone || mobile) &&
            (phone == anonymousNumber || mobile == anonymousNumber)
        )
            return false;
        if (email && !emailIsValid(email.trim())) return false;
        if (email2 && !emailIsValid(email2.trim())) return false;
        if (nameLetterCount > 121) return false;

        return true;
    };

    const [checkForDupes, setCheckForDupes] = useState(true);
    const [dupeRecs, setDupeRecs] = useState();
    const [submitLinkClicked, setSubmitLinkClicked] = useState(false);

    const handleSubmitLink = async (contactId) => {
        //disable both of the submit buttons
        setSubmitLinkClicked(true);
        createLink(contactId);
    };

    const createLink = async (contactId) => {
        await axios.post(
            `${apiUrl}/createaccountcontactlink`,
            {
                contactId: contactId,
                accountId: account.accountId,
            }
        );

        let response = await axios.get(`${apiUrl}/getaccount/${account.accountId}`);

        if (response.status === 200) {
            setCreateContactAccount(response.data);
        }

        window.frames[0].postMessage({ event: "refresh" }, "*");
        resetCreateContact();
        getAccount(account.accountId, history);
    };

    const updateCaseContacts = (contact) => {
        for (const [caseId, c] of Object.entries(caseState.cases)) {
            if (c.account.accountId === contact.accountId)
                setCaseContacts(caseId, [
                    ...c.contacts.filter(
                        (cc) => cc.contactId !== contact.contactId
                    ),
                    {
                        contactId: contact.contactId,
                        accountId: contact.accountId,
                        email: contact.email || "",
                        email2: contact.email2 || "",
                        name: `${contact.firstName} ${contact.lastName}`,
                        firstName: contact.firstName || "",
                        lastName: contact.lastName || "",
                        emailOptOut: contact.emailOptOut,
                        isPrimary: c.contacts.filter(cc => cc.contactId === contact.contactId)[0].isPrimary ? true : false,
                        adviceTypeIds: contact.adviceTypeIds
                    },
                ]);

            setReplyContacts(
                caseId,
                c.reply.contacts.map((rc) =>
                    rc.contactId === contact.contactId
                        ? {
                            ...contact,
                            name: `${contact.firstName} ${contact.lastName}`,
                        }
                        : rc
                )
            );
        }
    };

    const handleSubmit = async () => {
        setLoading(true);
        const contact = {
            contactId: contactId,
            accountId: account.accountId,
            firstName,
            lastName,
            position,
            phone,
            mobile,
            email,
            email2,
            emailOptOut,
            externalId,
            accountExternalId: account.externalId,
            adviceTypeIds
        };

        if (!contactId) {
            try {
                console.log("pos5", contact, account);
                await contactService.createAccountContact({ contact });
            } catch (e) {
                setSnackAction("There was an error adding the contact", "error");
                console.error(e);
            }
        } else {
            try {
                await axios.post(`${apiUrl}/editexistingaccountcontact`, contact);
            } catch (e) {
                setSnackAction("There was an error editing the contact", "error");
                console.error(e);
            }
        }

        updateCaseContacts(contact);

        let response = await axios.get(
            `${apiUrl}/getaccount/${account.accountId}`
        );
        if (response.status === 200) {
            setCreateContactAccount(response.data);
        }

        window.frames[0].postMessage({ event: "refresh" }, "*");
        resetCreateContact();
        getAccount(account.accountId, history);
    };

    const handleDupeCheck = async () => {
        const contact = {
            accountId: account.accountId,
            firstName,
            lastName,
            position,
            phone,
            mobile,
            email,
            email2,
            emailOptOut,
        };

        if (checkForDupes) {
            let response = await axios.post(`${apiUrl}/getdupecontacts`, {
                firstName: contact.firstName,
                lastName: contact.lastName,
                email: contact.email,
                phone: contact.phone,
            });
            if (response.data.count === 0) {
                // no dupes found so the record is created in legal/sf
                handleNextClick();
            } else {
                setCheckForDupes(false);
                setDupeRecs(response.data.contacts);
            }
        } else {
            setCheckForDupes(true);
            setDupeRecs(null);
            handleNextClick();
        }
    };

    const handleExistingAccountContactClick = async (account, existingContact) => {

        console.log({ existingContact });

        await setCreateContactAccount(account);

        //if the contact selected already exists then populate fields
        if (existingContact) {
            setContactId(existingContact.contactId);
            setExternalId(existingContact.externalId);
            setCheckForDupes(false);
        }

        setFirstName(existingContact ? existingContact.firstName : "");
        setLastName(existingContact ? existingContact.lastName : "");
        setEmail(
            existingContact
                ? existingContact.email
                : createContactState.contact.email
                    ? createContactState.contact.email
                    : ""
        );
        setEmail2(existingContact ? existingContact.email2 : "");
        setPhone(
            existingContact
                ? existingContact.phone
                : createContactState.contact.phone
                    ? createContactState.contact.phone
                    : ""
        );
        setMobile(existingContact ? existingContact.mobile : "");
        setPosition(existingContact ? existingContact.position : "");
        setEmailOptOut(existingContact ? existingContact.emailOptOut : false);
        setAdviceTypeIds(existingContact?.adviceTypeIds || []);

        setActiveStep(activeStep + 1);
    };

    useEffect(() => {
        setPartnershipNumber(account.partnershipNumber);

        setPostCode(account.postCode);
        setAccountName(account.name);
        setPartnershipNumberQuery(account.partnershipNumber);
        setPostCodeQuery(account.postCode);
        setAccountNameQuery(account.name);
        setAccountNameQuery(account.name);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account]);

    //this is the row for the duplicate 'Link' display table
    function Row(results) {
        const { row } = results;
        const [open, setOpen] = React.useState(false);

        const accountIds = row.accounts.map(a => a.accountId);
        const linkExists = accountIds.includes(createContactState.account.accountId);

        return (
            <React.Fragment>
                <TableRow
                    key={row.contactId}
                    style={{ cursor: "pointer", height: "100%" }}
                    hover
                    onClick={() => setOpen(!open)}
                >
                    <TableCell>
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpen(!open)}
                        >
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </TableCell>
                    <TableCell component="th" scope="row">{row.name}</TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell>{row.phone}</TableCell>
                    <TableCell>{row.mobile}</TableCell>
                    <TableCell>
                        <Controls.ButtonControl
                            onClick={() => handleSubmitLink(row.contactId)}
                            name="submitLinkBtn"
                            size="small"
                            disabled={linkExists || submitLinkClicked}
                            text={linkExists ? "Linked" : "Link"}
                        />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell
                        style={{
                            paddingBottom: 0,
                            paddingTop: 0,
                            backgroundColor: "#f9f9f9",
                        }}
                        colSpan={6}
                    >
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box margin={1}>
                                <Typography
                                    variant="h6"
                                    gutterBottom
                                    component="div"
                                >
                                    Currently Linked Accounts
                                </Typography>
                                <Table size="small" aria-label="purchases">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Company Name</TableCell>
                                            <TableCell>Address</TableCell>
                                            <TableCell>Postcode</TableCell>
                                            <TableCell>Tel</TableCell>
                                            <TableCell>Partnership</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {row.accounts.map((historyRow) => (
                                            <TableRow
                                                key={historyRow.accountId}
                                                hover
                                            >
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                >
                                                    {historyRow.name}
                                                </TableCell>
                                                <TableCell>{historyRow.address}</TableCell>
                                                <TableCell>{historyRow.postCode}</TableCell>
                                                <TableCell>{historyRow.phone}</TableCell>
                                                <TableCell>{historyRow.partnershipName}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <Helmet title="Create Amend Contact" />
            <Typography variant="h3" style={{ paddingRight: "10px" }}>
                Create/Amend Contact
            </Typography>
            <Stepper
                style={{ marginTop: "24px" }}
                activeStep={activeStep}
                alternativeLabel
            >
                {steps.map(label =>
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                )}
            </Stepper>
            <Box mt={6} mb={6}>
                <Typography variant="h6" style={{ marginBottom: "12px" }}>
                    {getStepContent(activeStep)}
                </Typography>
                <Box display="flex" justifyContent="space-between">
                    {activeStep !== 0 && (
                        <Button
                            onClick={handleBackClick}
                            variant="contained"
                        >
                            Back
                        </Button>
                    )}

                    {activeStep === steps.length - 1 && !loading && (
                        <Button
                            style={{ marginLeft: "8px" }}
                            onClick={handleSubmit}
                            color="primary"
                            variant="contained"
                        >
                            Finish
                        </Button>
                    )}
                    {activeStep === steps.length - 1 && loading && (
                        <CircularProgress
                            style={{ marginLeft: "8px" }}
                            size="1.6em"
                        />
                    )}
                    {activeStep === 1 && (
                        <Button
                            onClick={handleDupeCheck}
                            variant="contained"
                            color="primary"
                        >
                            Next
                        </Button>
                    )}
                </Box>
            </Box>
            {
                activeStep === 0 &&
                <React.Fragment>
                    <Box mt={6} mb={6} display="flex" justifyContent="flex-end">
                        <FormControl style={{ marginRight: "16px" }}>
                            <InputLabel>Contact name</InputLabel>
                            <Input
                                type="text"
                                value={contactName}
                                onChange={e => setContactName(e.target.value)}
                                onKeyPress={handleEnterKeyPress}
                            />
                        </FormControl>
                        <FormControl style={{ marginRight: "16px" }}>
                            <InputLabel>Account name</InputLabel>
                            <Input
                                type="text"
                                value={accountName}
                                onChange={e => setAccountName(e.target.value)}
                                onKeyPress={handleEnterKeyPress}
                            />
                        </FormControl>
                        <FormControl style={{ marginRight: "16px" }}>
                            <InputLabel>Postcode</InputLabel>
                            <Input
                                type="text"
                                value={postCode}
                                onChange={e => setPostCode(e.target.value)}
                                onKeyPress={handleEnterKeyPress}
                            />
                        </FormControl>
                        <FormControl>
                            <InputLabel>Partnership Number</InputLabel>
                            <Input
                                type="text"
                                value={partnershipNumber || ""}
                                onChange={e => setPartnershipNumber(e.target.value)}
                                onKeyPress={handleEnterKeyPress}
                            />
                        </FormControl>
                        <FormControl>
                            <IconButton onClick={handleAccountSearch}>
                                <SearchIcon />
                            </IconButton>
                        </FormControl>
                    </Box>
                    <Card mb={6}>
                        <CardContent>
                            <AccountContactTable
                                postCode={postCodeQuery}
                                accountName={accountNameQuery}
                                contactName={contactNameQuery}
                                partnershipNumber={partnershipNumberQuery}
                                clickable
                                handleClick={handleExistingAccountContactClick}
                            />
                        </CardContent>
                    </Card>
                </React.Fragment>
            }
            {
                activeStep === 1 &&
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <Box display="flex" justifyContent="flex-end">
                            <FormControl style={{ width: "400px" }}>
                                <TextField
                                    error={firstName === "" && isFormInvalid}
                                    helperText={
                                        isFormInvalid && firstName === ""
                                            ? "Field is required"
                                            : ""
                                    }
                                    my={1}
                                    label="First Name"
                                    variant="outlined"
                                    value={firstName}
                                    onChange={e => setFirstName(e.target.value)}
                                />
                                <TextField
                                    error={lastName === "" && isFormInvalid}
                                    helperText={
                                        isFormInvalid && lastName === ""
                                            ? "Field is required"
                                            : ""
                                    }
                                    my={1}
                                    label="Last Name"
                                    variant="outlined"
                                    value={lastName}
                                    onChange={e => setLastName(e.target.value)}
                                />
                                <TextField
                                    my={1}
                                    label="Job Title"
                                    inputProps={{ maxLength: 128 }}
                                    variant="outlined"
                                    value={position}
                                    onChange={e => setPosition(e.target.value)}
                                />
                                <TextField
                                    error={
                                        phone !== "" &&
                                        isFormInvalid &&
                                        !/^(?=.*\d)[\d ]+$/.test(phone)
                                    }
                                    helperText={
                                        phone &&
                                            isFormInvalid &&
                                            !/^(?=.*\d)[\d ]+$/.test(phone)
                                            ? "Invalid phone number"
                                            : ""
                                    }
                                    my={1}
                                    label="Phone"
                                    variant="outlined"
                                    inputProps={{ maxLength: 50 }}
                                    value={phone}
                                    onChange={e => setPhone(e.target.value)}
                                    InputLabelProps={
                                        createContactState.contact.phone &&
                                            contactId
                                            ? { shrink: true }
                                            : {}
                                    }
                                    InputProps={
                                        createContactState.contact.phone &&
                                            contactId
                                            ? {
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            size="small"
                                                            onClick={() => setPhone(createContactState.contact.phone)}
                                                        >
                                                            <UpdateRecordIcon size="small" />
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }
                                            : {}
                                    }
                                />
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box display="flex" justifyContent="flex-start">
                            <FormControl style={{ width: "400px" }}>
                                <TextField
                                    error={
                                        mobile !== "" &&
                                        isFormInvalid &&
                                        !/^(?=.*\d)[\d ]+$/.test(mobile)
                                    }
                                    helperText={
                                        mobile &&
                                            isFormInvalid &&
                                            !/^(?=.*\d)[\d ]+$/.test(mobile)
                                            ? "Invalid mobile number"
                                            : ""
                                    }
                                    my={1}
                                    label="Mobile"
                                    inputProps={{ maxLength: 50 }}
                                    variant="outlined"
                                    value={mobile}
                                    onChange={(e) => setMobile(e.target.value)}
                                    InputLabelProps={
                                        createContactState.contact.phone &&
                                            contactId
                                            ? { shrink: true }
                                            : {}
                                    }
                                    InputProps={
                                        createContactState.contact.phone &&
                                            contactId
                                            ? {
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            size="small"
                                                            onClick={() => setMobile(createContactState.contact.phone)}
                                                        >
                                                            <UpdateRecordIcon size="small" />
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }
                                            : {}
                                    }
                                />

                                <TextField
                                    error={
                                        email !== "" &&
                                        isFormInvalid &&
                                        !emailIsValid(email)
                                    }
                                    helperText={
                                        email &&
                                            isFormInvalid &&
                                            !emailIsValid(email)
                                            ? "Invalid email address"
                                            : ""
                                    }
                                    my={1}
                                    label="Email"
                                    variant="outlined"
                                    inputProps={{ maxLength: 320 }}
                                    value={email && email.trim()}
                                    onChange={e => setEmail(e.target.value)}
                                    InputLabelProps={
                                        createContactState.contact.email &&
                                            contactId
                                            ? { shrink: true }
                                            : {}
                                    }
                                    InputProps={
                                        createContactState.contact.email &&
                                            contactId
                                            ? {
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            size="small"
                                                            onClick={() => setEmail(createContactState.contact.email)}
                                                        >
                                                            <UpdateRecordIcon size="small" />
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }
                                            : {}
                                    }
                                />
                                <TextField
                                    error={
                                        email2 !== "" &&
                                        isFormInvalid &&
                                        !emailIsValid(email2)
                                    }
                                    helperText={
                                        email2 &&
                                            isFormInvalid &&
                                            !emailIsValid(email2)
                                            ? "Invalid email address"
                                            : ""
                                    }
                                    my={1}
                                    label="Second Email"
                                    variant="outlined"
                                    inputProps={{ maxLength: 320 }}
                                    value={email2 && email2.trim()}
                                    onChange={e => setEmail2(e.target.value)}
                                    InputLabelProps={
                                        createContactState.contact.email &&
                                            contactId
                                            ? { shrink: true }
                                            : {}
                                    }
                                    InputProps={
                                        createContactState.contact.email &&
                                            contactId
                                            ? {
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            size="small"
                                                            onClick={() => setEmail2(createContactState.contact.email)}
                                                        >
                                                            <UpdateRecordIcon size="small" />
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }
                                            : {}
                                    }
                                />
                                {
                                    allowedAdviceTypes.length > 0 &&
                                    <FormControl>
                                        <FormLabel style={{ marginBottom: '5px' }}>Can Receive Advice Types</FormLabel>
                                                <Box display="flex">
                                                    {allowedAdviceTypes.map(at =>
                                                        <AdviceTypeChip onClick={() => toggleAdviceTypeId(at.id)}
                                                            type="button"
                                                            notSelected={!adviceTypeIds.includes(at.id)}
                                                            key={at.id}
                                                            adviceType={at.name}
                                                        />
                                                    )}
                                                </Box>
                                    </FormControl>
                                }

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="emailOptOut"
                                            checked={emailOptOut}
                                            onChange={e => setEmailOptOut(e.target.checked)}
                                        />
                                    }
                                    label="Tick this box if the contact does not consent to email marketing."
                                />
                            </FormControl>
                        </Box>
                    </Grid>
                </Grid>
            }
            {
                activeStep === 1 &&
                createContactState.contact.phone &&
                contactId &&
                !dupeRecs && (
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Card>
                                <CardContent>
                                    <Box display="flex">
                                        <Box
                                            display="flex"
                                            flexGrow={1}
                                            flexDirection="column"
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <Typography variant="h6">
                                                Click on the replace icon above <UpdateRecordIcon size="small" /> to update with new value shown below
                                            </Typography>
                                            <Typography variant="h6">{createContactState.contact.phone} </Typography>
                                        </Box>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                )
            }
            {
                activeStep === 1 && createContactState.contact.email && contactId && !dupeRecs &&
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Card>
                            <CardContent>
                                <Box display="flex">
                                    <Box
                                        display="flex"
                                        flexGrow={1}
                                        flexDirection="column"
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <Typography variant="h6">
                                            Click on the replace icon above <UpdateRecordIcon size="small" /> to update with new value shown below
                                        </Typography>
                                        <Typography variant="h6">{createContactState.contact.email} </Typography>
                                    </Box>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            }
            {
                dupeRecs && dupeRecs.length > 0 &&
                <Card>
                    <CardContent>
                        <Box display="flex">
                            <Box>
                                <DupeMatchIcon size="small" />
                            </Box>
                            <Box
                                display="flex"
                                flexGrow={1}
                                flexDirection="column"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Typography variant="h6">Possible record already exists warning, matching records are listed below</Typography>
                            </Box>
                            <Box>
                                <DupeMatchIcon size="small" />
                            </Box>
                        </Box>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell>Contact Name</TableCell>
                                        <TableCell>Email</TableCell>
                                        <TableCell>Phone</TableCell>
                                        <TableCell>Mobile Phone</TableCell>
                                        <TableCell>Link</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {dupeRecs.map(row => <Row key={row.contactId} row={row} />)}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </CardContent>
                </Card>
            }
            {
                activeStep === 2 &&
                <Box display="flex" justifyContent="center">
                    <Table style={{ width: "300px" }}>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    <Typography variant="h6">Account</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="body2">{account.name}, {account.postCode}</Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography variant="h6">First Name</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="body2">{firstName}</Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography variant="h6">Last Name</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="body2">{lastName}</Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography variant="h6">Job Title</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="body2">{position}</Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography variant="h6">Phone</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="body2">{phone}</Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography variant="h6">Mobile</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="body2">{mobile}</Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography variant="h6">Email</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="body2">{email}</Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography variant="h6">Second Email</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="body2">{email2}</Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography variant="h6">Email Opt-out</Typography>
                                </TableCell>
                                <TableCell>
                                    <Checkbox
                                        name="emailOptOutConfirmation"
                                        checked={emailOptOut}
                                        disabled
                                    />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Box>
            }
        </React.Fragment>
    );
};

const mapStateToProps = (state) => ({
    userState: state.userReducer,
    flexState: state.flexReducer,
    createContactState: state.createContactReducer,
    configState: state.configReducer,
    caseState: state.caseReducer,
});

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(actionCreators, dispatch),
        setSnack: (message, severity) => dispatch(setSnackAction(message, severity)),
        setCreateContactActiveStep: (step) => dispatch(setCreateContactActiveStep(step)),
        setCreateContactAccount: (account) => dispatch(setCreateContactAccount(account)),
        resetCreateContact: () => dispatch(resetCreateContact()),
        setCaseContacts: (caseId, contacts) => dispatch(setCaseContacts(caseId, contacts)),
        setReplyContacts: (caseId, contacts) => dispatch(setReplyContactsAction(caseId, contacts)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateContactWizard);
