import axios from "../plugins/axios";

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

const getContacts = ({
    fullName,
    email,
    phone,
    companyName,
    pageNum,
    pageSize,
    accountId,
}) => {
    return axios.post(`${apiUrl}/getcontacts`, {
        fullName,
        email,
        phone,
        companyName,
        pageNum,
        pageSize,
        accountId,
    });
};

const createAccountContact = ({ contact }) =>
    axios.post(`${apiUrl}/createaccountcontact`, contact);

const contactService = { getContacts, createAccountContact };

export default contactService;
