const emptyCall = {
    callId: null,
    start: null,
    phone: '',
    accountName: '',
    contactName: '',
    direction: null,
};

const emptyCallNote = {
    issue: '',
    advice: '',
    action: '',
};

const emptyEmail = {
    emailId: '',
    taskSid: '',
    createdOn: null,
    subject: '',
    caseActivityIds: [],
}

export { emptyCall, emptyCallNote, emptyEmail };