import React from "react";
import styled from "styled-components";
import Moment from "react-moment";

import {
    Card as MuiCard,
    CardContent,
    Typography as MuiTypography,
    List,
    ListItem,
    ListItemText,
    ListItemAvatar,
    Avatar,
    Button
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import {
    Image,
    Videocam,
    InsertDriveFile
} from '@material-ui/icons';
import documentService from "../services/documentService";
import {useDispatch} from "react-redux";
import {setSnackAction} from "../redux/actions/snackActions";

const Card = styled(MuiCard)(spacing);

const Typography = styled(MuiTypography)(spacing);

const fileIcons = {
    image: <Image />,
    video: <Videocam />,
    other: <InsertDriveFile />
};

const EvidenceCard = ({ files, openFileViewer }) => {
    const dispatch = useDispatch();
    const openDocument = async file => {
        try {
            const previewPath = await documentService.retrieveDocumentPreviewPath({
                reference: file.reference,
                contentType: file.contentType
            })

            if (previewPath === null) {
                dispatch(setSnackAction("File is password protected and cannot be previewed. Download from the Case Files tab to view locally.", "warning"));
                return;
            }

            const parsedFileName = file.filename.split(".");
            const attachment = {
                reference: file.reference,
                filename: file.filename,
                contentType: file.contentType
            }

            openFileViewer({
                type: parsedFileName[parsedFileName.length - 1],
                path: previewPath,
                name: file.filename
            }, null, attachment);    
            
        } catch (e) {
            console.error(e)
        }
    }

    return (
        <Card mb={3}>
            <CardContent>
                <Typography variant="h6" mb={6}>Evidence</Typography>
                <List>
                    {files.map(f =>
                        <ListItem key={f.reference}>
                            <ListItemAvatar>
                                <Avatar>
                                    {f.contentType.startsWith("image") ? fileIcons.image : f.contentType.startsWith("video") ? fileIcons.video : fileIcons.other}
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={<Button onClick={() => openDocument(f)} color="primary">{f.filename}</Button>}
                                secondary={f.uploadedByName ?
                                    <span>Uploaded by {f.uploadedByName} on <Moment format="DD/MM/YYYY HH:mm">{f.uploadedOn}</Moment></span>
                                    :
                                    <span>Uploaded on <Moment format="DD/MM/YYYY HH:mm">{f.uploadedOn}</Moment></span>}
                            />
                        </ListItem>
                    )}
                </List>
            </CardContent>
        </Card>
    );
};

export default EvidenceCard;