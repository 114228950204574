import React, { useState, useCallback, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import Moment from "react-moment";
import axios from "../plugins/axios";
import Helmet from "react-helmet";
import {
    Grid,
    Divider as MuiDivider,
    Typography as MuiTypography,
    FormControl,
    IconButton,
} from "@material-ui/core";
import { Replay } from "@material-ui/icons";
import { spacing } from "@material-ui/system";
import { KeyboardDatePicker } from "@material-ui/pickers";
//import InboundQueriesCard from "../cards/InboundQueriesCard";
import MyRecentCasesCard from "../cards/MyRecentCasesCard";
import UserOpenCasesCard from "../cards/UserOpenCasesCard";
import { useAuth } from "../contexts/authContext";
import UserSLACard from "../cards/UserSLACard";
import UserStatsCard from "../cards/UserStatsCard";
import userRoles from "../constants/userRoles";


const Divider = styled(MuiDivider)(spacing);

const Typography = styled(MuiTypography)(spacing);

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

const Dashboard = ({ userState }) => {
    const { user, hasRole } = useAuth();
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [userStats, setUserStats] = useState(null);

    const seeCaseData = useMemo(() => hasRole(userRoles.LEGAL_ADVISOR) || hasRole(userRoles.HRC_ASSOCIATE), [hasRole]);

    const handleReset = () => {
        setFromDate(null);
        setToDate(null);
    };

    const getUserStats = useCallback(async () => {
        if (
            (!fromDate && toDate) ||
            (fromDate && !toDate) ||
            !userState.userProfile.userId
        )
            return;

        let response = await axios.post(`${apiUrl}/getteaminfo`, {
            fromDate,
            toDate,
            userId: userState.userProfile.userId,
        });
        setUserStats(response.data);
    }, [userState.userProfile.userId, fromDate, toDate]);

    useEffect(() => {
        if (seeCaseData)
            getUserStats();
    }, [getUserStats, seeCaseData]);

    return (
        <React.Fragment>
            <Helmet title="Default Dashboard" />
            <Grid justifyContent="space-between" container spacing={6}>
                <Grid item>
                    <Typography
                        variant="h3"
                        display="inline"
                    >{`Welcome back, ${user.details.firstName} ${user.details.lastName}`}</Typography>
                    <Typography variant="body2" ml={2} display="inline">
                        <Moment format="dddd, Do MMMM YYYY" />
                    </Typography>
                </Grid>
                <Grid item>
                    <FormControl>
                        <KeyboardDatePicker
                            value={fromDate}
                            onChange={(date) => setFromDate(date)}
                            label="From date"
                            format="dd/MM/yyyy"
                            maxDate={toDate}
                        />
                    </FormControl>
                    <FormControl style={{ marginLeft: "8px" }}>
                        <KeyboardDatePicker
                            value={toDate}
                            onChange={(date) => setToDate(date)}
                            label="To date"
                            format="dd/MM/yyyy"
                            minDate={fromDate}
                        />
                    </FormControl>
                    <IconButton
                        style={{ marginTop: "8px" }}
                        onClick={handleReset}
                    >
                        <Replay />
                    </IconButton>
                </Grid>
            </Grid>
            <Divider my={6} />
            {seeCaseData && (
                <React.Fragment>
                    <Grid container spacing={6}>
                        <Grid item xs={12} md={6}>
                            <UserOpenCasesCard
                                userProfile={userState.userProfile}
                                isMyData="true"
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <UserSLACard
                                userProfile={userState.userProfile}
                                fromDate={fromDate}
                                toDate={toDate}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={6}>
                        {/*<Grid item xs={12} md={6}>*/}
                        {/*    <InboundQueriesCard*/}
                        {/*        userProfile={userState.userProfile}*/}
                        {/*        fromDate={fromDate}*/}
                        {/*        toDate={toDate}*/}
                        {/*    />*/}
                        {/*</Grid>*/}
                        <Grid item xs={12} md={6}>
                            {userStats && (
                                <UserStatsCard
                                    u={userState.userProfile}
                                    activities={userStats[0].activities}
                                    totalActivities={userStats[0].totalActivities || 0}
                                    totalActivityMinutes={
                                        userStats[0].totalActivityDuration
                                            ? userStats[0].totalActivityDuration / 60
                                            : 0
                                    }
                                    isDashboard
                                    fromDate={fromDate}
                                    toDate={toDate}
                                />
                            )}
                        </Grid>
                    </Grid>
                    <Grid container spacing={6}>
                        <Grid item xs={12}>
                            <MyRecentCasesCard
                                adviserId={userState.userProfile.userId}
                                adviserName={`${user.details.firstName} ${user.details.lastName}`}
                            />
                        </Grid>
                    </Grid>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default connect((state) => ({ userState: state.userReducer }))(Dashboard);
