import React, { useMemo, useState } from "react";
import { alpha, Avatar, Checkbox, IconButton, makeStyles, Typography, Button, Tooltip } from "@material-ui/core";
import { red, teal } from "@material-ui/core/colors";
import {
    ArrowDownward,
    ArrowUpward,
    Attachment,
    MoreVert,
    SpeakerNotes as TranscriptIcon,
    OfflineBolt as AIIcon,
} from "@material-ui/icons";
import { format } from "date-fns";
import caseActivityTypes from "../constants/caseActivityTypes";
import { formatTimeDigital } from "../helpers/formatTime";
import { getInitials } from "../helpers/nameHelpers";
import { getUnitEnding } from "../helpers/unitTimeHelpers";
import { MiscActivityCategory } from "../constants/miscActivityCategory";
import StatusChip from "../components/StatusChip";
import { invoiceStatus } from "../constants/invoiceStatus";
import MeetingTranscriptDialog from "../components/dialogs/MeetingTranscriptDialog";

const useStyles = makeStyles((theme) => ({
    container: {
        background: theme.palette.common.white,
        border: `1px solid ${alpha(theme.palette.primary.main, 0.3)}`,
        borderRadius: 4,
        padding: theme.spacing(3),
        margin: theme.spacing(3, 0),
        cursor: (props) => (props.cardIsClickable ? "pointer" : "default"),
    },
    topSection: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: theme.spacing(3),
    },
    user: {
        display: "flex",
    },
    userImg: {
        width: theme.spacing(10),
        height: theme.spacing(10),
        marginRight: theme.spacing(2),
    },
    userInfo: {
        display: "flex",
        flexDirection: "column",
    },
    activityDate: {
        fontSize: 12,
        color: theme.palette.primary.main,
    },
    activityDescription: {
        fontSize: 13,
        color: theme.palette.primary.main,
        marginTop: theme.spacing(2),
        flexWrap: "wrap",
    },
    activityDescriptionButton: {
        marginTop: theme.spacing(2),
    },
    middleSection: {
        display: "flex",
        flexDirection: "column",
    },
    audio: {
        marginTop: theme.spacing(2),
        width: "100%",
        height: 40,
    },
    video: {
        marginTop: theme.spacing(2),
        width: "100%"
    },
    bottomSection: {
        display: "flex",
        marginTop: theme.spacing(3),
        [theme.breakpoints.up("md")]: {
            flexDirection: "row",
        },
    },
    direction: {
        padding: theme.spacing(1),
        borderRadius: 4,
        display: "flex",
        alignItems: "center",
        background: (props) => (props.direction === 1 ? theme.palette.secondary.light : alpha(theme.palette.primary.main, 0.04)),
        marginRight: theme.spacing(2),
    },
    time: {
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(1),
        borderRadius: 4,
        background: alpha(theme.palette.primary.main, 0.04),
        marginRight: theme.spacing(2),
    },
    attachments: {
        padding: theme.spacing(1),
        borderRadius: 4,
        display: "flex",
        alignItems: "center",
        background: alpha(theme.palette.primary.main, 0.04),
        marginRight: theme.spacing(2),
    },
    chargeable: {
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(0.5, 2),
        borderRadius: 4,
        background: teal[500],
        color: theme.palette.common.white,
    },
    excluded: {
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(0.5, 2),
        borderRadius: 4,
        background: 'red',
        color: theme.palette.common.white,
    },
    downloadCheckbox: {
        padding: theme.spacing(1),
        display: "flex",
        alignItems: "center",
    },
    arrowIcon: {
        fontSize: 14,
        marginRight: theme.spacing(1),
    },
    transcriptIcon: {
        fontSize: 14,
        marginRight: theme.spacing(1),
        cursor: "pointer",
    },
    precedentIcon: {
        fontSize: 14,
        color: red[800],
        transform: "rotate(270deg)",
    },
    attachmentIcon: {
        fontSize: 14,
        transform: "rotate(270deg)",
    },
    iconButton: {
        padding: 0,
    },
    textBold: {
        fontSize: 13,
        fontWeight: 600,
        wordBreak: "break-all",
    },
    chargeableText: {
        fontSize: 15,
    },
    invoiceStatusChip: {
        marginLeft: "8px",
    },
}));

const { ACTION, CALL, OUTCOME, MISC, EMAIL, MEETING, CALL_WITH_NOTE } = caseActivityTypes;

const summaryMode = {
    DOWNLOAD: "download",
    VIEW: "view",
};

const direction = {
    INBOUND: 0,
    OUTBOUND: 1,
};

const CaseHistoryItem = ({
    caseEvent,
    mode = summaryMode.VIEW,
    miscActivityTypes = {},
    internalUsers,
    downloadCaseActivityIds,
    handleMenuClick,
    handlePreviewClick,
    handleEventChecked,
    isUnitisedTime = false,
    onEditDescription,
}) => {

    const [transcriptCaseActivityId, setTranscriptCaseActivityId] = useState(null);

    const cardIsClickable = useMemo(() => !(caseEvent.itemType === ACTION || caseEvent.itemType === CALL || caseEvent.itemType === MEETING || caseEvent.itemType === OUTCOME), [caseEvent]);

    const classes = useStyles({
        direction: caseEvent?.direction,
        cardIsClickable,
    });

    const onCardClick = () => {
        if (!cardIsClickable) return;
        return handlePreviewClick(caseEvent);
    };

    const onEditDescriptionClick = (e) => {
        e.stopPropagation();
        return onEditDescription(caseEvent);
    };

    const handleSideMenuClick = (e) => {
        e.stopPropagation();
        return handleMenuClick(caseEvent, e);
    };

    const userPhoto = useMemo(() => {
        var user = internalUsers[caseEvent.adviserId];
        return user && user.photo;
    }, [caseEvent.adviserId, internalUsers]);

    const { category: miscActivityCategory, description: miscActivityTypeDescription } = miscActivityTypes[caseEvent?.miscActivityTypeId] || {};

    const displayTitle = useMemo(() => {
        switch (caseEvent.itemType) {
            case MISC:
                if (miscActivityCategory === MiscActivityCategory.CustomerNote || miscActivityCategory === MiscActivityCategory.SeekAdvice)
                    return miscActivityTypeDescription;
                return `Misc Activity - ${miscActivityTypeDescription}`;
            case ACTION:
                return `${caseEvent?.actionCategory} Action - Completed `;
            default:
                return caseEvent.itemType;
        }
    }, [caseEvent?.actionCategory, caseEvent.itemType, miscActivityCategory, miscActivityTypeDescription]);

    const hasSideMenuAccess = useMemo(
        () =>
            handleMenuClick &&
            caseEvent.itemType !== OUTCOME &&
            caseEvent.itemType !== ACTION &&
            miscActivityCategory !== MiscActivityCategory.CustomerNote &&
            !caseEvent.isLockedForInvoicing,
        [caseEvent.isLockedForInvoicing, caseEvent.itemType, handleMenuClick, miscActivityCategory]
    );

    const displayDuration = useMemo(() => {
        const duration = caseEvent?.duration ?? 0;
        if (!isUnitisedTime) return formatTimeDigital(duration);
        let units = caseEvent?.units ?? Math.ceil(duration / 60 / 6);
        let minutes = caseEvent?.unitisedMinutes ?? units * 6;
        const formatTime = formatTimeDigital(minutes * 60);
        const formatUnits = `(${units} ${getUnitEnding(units)})`;
        return `${formatTime} ${formatUnits}`;
    }, [caseEvent, isUnitisedTime]);

    return (
        <div className={classes.container} onClick={onCardClick} data-testid="case-history-item" data-cy="case_activity_item">
            <div className={classes.topSection} data-id={caseEvent.caseActivityId}>
                <div className={classes.user}>
                    <div className={classes.userImg}>
                        <Avatar alt={caseEvent.person || "UNKNOWN"} src={caseEvent.direction === direction.INBOUND ? null : userPhoto} className={classes.userImg}>
                            {!!caseEvent.direction ||
                            caseEvent.miscActivityCategory === MiscActivityCategory.CustomerNote ||
                            caseEvent.miscActivityCategory === MiscActivityCategory.SeekAdvice
                                ? getInitials(caseEvent.adviserName ?? caseEvent.person)
                                : null}
                        </Avatar>
                    </div>
                    <div className={classes.userInfo}>
                        <Typography color="primary" variant="body2" className={classes.textBold}>
                            {caseEvent.person || "UNKNOWN"}
                        </Typography>
                        <Typography variant="body2" className={classes.activityDate}>
                            {format(new Date(caseEvent.eventTime), "dd/MM/yy HH:mm")}
                        </Typography>
                    </div>
                </div>
                <div className={classes.menu}>
                    {hasSideMenuAccess && (
                        <IconButton
                            className={classes.iconButton}
                            disabled={mode !== summaryMode.VIEW}
                            onClick={handleSideMenuClick}
                            data-cy="case_activity_menu"
                        >
                            <MoreVert />
                        </IconButton>
                    )}
                </div>
            </div>
            <div className={classes.middleSection}>
                <Typography color="primary" variant="body2" className={classes.textBold}>
                    {displayTitle}
                </Typography>
                {caseEvent.recordingUrl && (caseEvent.itemType === CALL || caseEvent.itemType === CALL_WITH_NOTE) && (
                    <div>
                        <audio key={caseEvent.recordingUrl} controls className={classes.audio}>
                            <source src={caseEvent.recordingUrl} type="audio/wav" />
                            Your browser does not support the audio element.
                        </audio>
                    </div>
                )}
                {caseEvent.recordingUrl && caseEvent.itemType === MEETING && (
                    <div>
                        <video key={caseEvent.recordingUrl} controls className={classes.video}>
                            <source src={caseEvent.recordingUrl} type="video/mp4" />
                        </video>
                    </div>
                )}
                {caseEvent?.itemType === ACTION ? (
                    <Typography variant="body2" className={classes.activityDescription}>
                        {caseEvent?.actionType} <br /> {caseEvent?.actionSummary}{" "}
                    </Typography>
                ) : caseEvent?.itemType === EMAIL && caseEvent?.direction === 0 ? (
                    <>
                        <Typography variant="body2" className={classes.activityDescription}>
                            {caseEvent?.activityDescription || caseEvent?.text || "No description added"}
                        </Typography>
                        <div>
                            {!caseEvent?.activityDescription && (
                                <Button onClick={onEditDescriptionClick} color="secondary" className={classes.activityDescriptionButton}>
                                    Add description
                                </Button>
                            )}
                        </div>
                    </>
                ) : (
                    <Typography variant="body2" className={classes.activityDescription} data-cy="case_activity_description">
                        {caseEvent?.activityDescription || caseEvent?.text}
                    </Typography>
                )}
            </div>
            {!!caseEvent.caseActivityId && (
                <>
                    <div className={classes.bottomSection}>
                        {caseEvent?.isAssisted && (
                            <div className={classes.attachments}>
                                <AIIcon className={classes.arrowIcon} />
                            </div>
                        )}
                        {caseEvent?.direction === 0 && (
                            <div className={classes.direction}>
                                <ArrowDownward className={classes.arrowIcon} />
                                <Typography color="primary" variant="body2" className={classes.textBold}>
                                    Inbound
                                </Typography>
                            </div>
                        )}
                        {caseEvent?.direction === 1 && (
                            <div className={classes.direction}>
                                <ArrowUpward className={classes.arrowIcon} />
                                <Typography color="primary" variant="body2" className={classes.textBold}>
                                    Outbound
                                </Typography>
                            </div>
                        )}
                        {!!caseEvent?.duration && (
                            <div className={classes.time}>
                                <Typography color="primary" variant="body2" className={classes.textBold}>
                                    {displayDuration}
                                </Typography>
                            </div>
                        )}
                        {caseEvent?.hasPrecedents && (
                            <div className={classes.attachments}>
                                <Attachment className={classes.precedentIcon} />
                            </div>
                        )}
                        {caseEvent?.hasTranscripts && (
                            <div className={classes.attachments}>
                                <TranscriptIcon className={classes.transcriptIcon} onClick={() => setTranscriptCaseActivityId(caseEvent.caseActivityId)} />
                            </div>
                        )}
                        {caseEvent?.attachments && caseEvent?.attachments.length > 0 && (
                            <div className={classes.attachments}>
                                <Attachment className={classes.attachmentIcon} />
                            </div>
                        )}
                        {caseEvent?.isChargeable && (
                            <div className={classes.chargeable}>
                                <Typography variant="body2" className={classes.chargeableText}>
                                    £
                                </Typography>
                            </div>
                        )}
                        {caseEvent?.isExcluded && (
                            <div className={classes.chargeable}>
                                <Tooltip title="Non-Billable">
                                    <Typography variant="body2">
                                        FREE
                                    </Typography>
                                </Tooltip>
                                
                            </div>
                        )}
                        {mode === summaryMode.DOWNLOAD && (
                            <Checkbox
                                className={classes.downloadCheckbox}
                                checked={downloadCaseActivityIds.includes(caseEvent.caseActivityId)}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleEventChecked(caseEvent.caseActivityId, e.target.checked);
                                }}
                            />
                        )}
                        {caseEvent?.isLockedForInvoicing && (
                            <div className={classes.invoiceStatusChip}>
                                <StatusChip
                                    classes={classes.invoiceStatusChip}
                                    label={caseEvent.invoiceStatus === invoiceStatus.ReadyForInvoicing ? "Ready for invoicing" : "Invoiced"}
                                    variant={caseEvent.invoiceStatus === invoiceStatus.ReadyForInvoicing ? "info" : "success"}
                                />
                            </div>
                        )}
                    </div>
                </>
            )}
            {transcriptCaseActivityId &&
                <MeetingTranscriptDialog
                    caseActivityId={transcriptCaseActivityId}
                    close={() => setTranscriptCaseActivityId(null)}
                />
            }
        </div>
    );
};

export default CaseHistoryItem;
