import React from "react";
import styled from "styled-components";

import {
    Box,
    Button as MuiIconButton
} from "@material-ui/core";
import {
    Add as AddIcon,
    Delete as DeleteIcon,
    Edit as EditIcon,
    Save as SaveIcon,
    ArrowDownward,
    ArrowUpward,
    Replay
} from "@material-ui/icons";

const IconButton = styled(MuiIconButton)`
    padding: 2px;
    margin-top: -2px;
    margin-left: 2px;
    min-width: 0;
`;

const AdminToolbar = ({ items, itemId, handleSetAdd, handleSetEdit, handleDelete, handleSave, handleMove, handleReset, changed, selectedItem, movable }) => {
    return (
        <Box display="flex" justifyContent="space-between">
            <Box display="flex" justifyContent="flex-start">
                <IconButton onClick={handleSetAdd} disabled={items.filter(i => i.toEdit).length > 0}><AddIcon /></IconButton>
                <IconButton onClick={handleSetEdit} disabled={!selectedItem}><EditIcon /></IconButton>
                <IconButton onClick={handleSave} disabled={!changed}><SaveIcon /></IconButton>
                <IconButton onClick={handleDelete} disabled={!selectedItem}><DeleteIcon /></IconButton>
            </Box>
            <Box display="flex" justifyContent="flex-end">
                <IconButton onClick={handleReset} disabled={!changed}><Replay /></IconButton>
                {movable &&
                    <React.Fragment>
                        <IconButton onClick={() => handleMove(1)} disabled={!selectedItem || items.slice(-1)[0][itemId] === selectedItem}><ArrowDownward /></IconButton>
                        <IconButton onClick={() => handleMove(-1)} disabled={!selectedItem || items.slice(0, 1)[0][itemId] === selectedItem}><ArrowUpward /></IconButton>
                    </React.Fragment>}
            </Box>
        </Box>
    );
};

export default AdminToolbar;