import React, { useCallback } from "react";
import { caseFileTypes } from "../../../constants/caseFiles";
import documentService from "../../../services/documentService";
import reactQueryClient from "../../../reactQueryClient";
import queryKeys from "../../../constants/queryKeys";

import {
    Box,
    Button,
    Typography,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    makeStyles,
    TextField,
    Tooltip,
    IconButton,
} from "@material-ui/core";

import {
    Undo as UndoIcon,
} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
    diaTitle: {
        flexGrow: 1,
        display: "flex",
        alignItems: "center",
    },
    folderNameField: {
        width: "95%",
        marginLeft: theme.spacing(3),
        marginBottom: theme.spacing(4),
        marginTop: theme.spacing(1),
    },
    customNameField: {
        width: "85%",
        marginLeft: theme.spacing(3),
        marginBottom: theme.spacing(4),
        marginTop: theme.spacing(1),
    },
    resetCustomName: {
        marginTop: theme.spacing(1),
    },
}));

export default function FileRenameDialog({
    treeData,
    setTreeData,
    fileRenameDialogOpen,
    setFileRenameDialogOpen,
    customFileName,
    setCustomFileName,
    validateFileFolder,
    fileInfo,
    caseId,
}) {
    const classes = useStyles();

    const handleFileRenameDialogClose = useCallback(() => {
        setCustomFileName("");
        setFileRenameDialogOpen(false);
    }, [setCustomFileName, setFileRenameDialogOpen]);

    const updateFileNameRecursively = useCallback((node, newFileName, fileInfo) => {
        if (node.docType === "File") {
            let isEditedFile = node.azureFileReference === fileInfo.azureFileReference;
            return isEditedFile ? { ...node, customName: newFileName } : node;
        } else if (node.children) {
            return {
                ...node,
                children: node.children.map((child) => updateFileNameRecursively(child, newFileName, fileInfo)),
            };
        }
        return node;
    }, []);

    const handleFileRenameSave = useCallback((e) => {
        e.preventDefault();

        let newFileName = validateFileFolder(customFileName.trim(), caseFileTypes.FILE)
        if (newFileName === false)
            return;
        
        const updatedTreeData = treeData.map((node) => updateFileNameRecursively(node, newFileName, fileInfo));
        setTreeData(updatedTreeData);

        try {
            documentService.renameAzureFile({
                azureFileReference: fileInfo.azureFileReference,
                customName: newFileName
            });
        }
        catch {
            reactQueryClient.invalidateQueries([queryKeys.caseFilesTree, caseId]);
        }

        setCustomFileName("");
        setFileRenameDialogOpen(false);
    }, [fileInfo, validateFileFolder, customFileName, treeData, setTreeData, setCustomFileName, setFileRenameDialogOpen, updateFileNameRecursively, caseId]);

    return (
        <Dialog
            open={fileRenameDialogOpen}
            onClose={handleFileRenameDialogClose}
            fullWidth
        >
            <DialogTitle>
                <Box display="flex" alignItems="center">
                    <Typography
                        variant="h6"
                        component="div"
                        className={classes.diaTitle}
                    >
                        Edit File Name
                    </Typography>
                </Box>
            </DialogTitle>
            <DialogContent>
                <TextField
                    variant="outlined"
                    label="Original File Name"
                    value={fileInfo?.title}
                    className={classes.folderNameField}
                />
                <TextField
                    variant="outlined"
                    label="New File Name"
                    value={customFileName || ""}
                    onChange={(e) => setCustomFileName(e.target.value)}
                    className={
                        fileInfo?.customName?.length > 0
                            ? classes.customNameField
                            : classes.folderNameField
                    }
                />
                {fileInfo?.customName?.length > 0 && (
                    <Tooltip title="Reset custom name">
                        <IconButton
                            className={classes.resetCustomName}
                            onClick={() => setCustomFileName("")}
                        >
                            <UndoIcon />
                        </IconButton>
                    </Tooltip>
                )}
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    onClick={() => handleFileRenameDialogClose(false)}
                >
                    Cancel
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={handleFileRenameSave}
                >
                    Save Custom Name
                </Button>
            </DialogActions>
        </Dialog>
    )
}