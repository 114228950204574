import React from 'react';
import { connect } from "react-redux";
import {
	Card as MuiCard,
	CardContent,
	Typography as MuiTypography,
	Box,
	Grid,
	Avatar,
} from "@material-ui/core";
import styled from "styled-components";
import { spacing } from "@material-ui/system";
import useBusinessDivisions from "../../hooks/queries/useBusinessDivisions";
import avatarStyles from "../../theme/avatars";

const Card = styled(MuiCard)(spacing);
const Typography = styled(MuiTypography)(spacing);

const DivisionCards = ({ accountId, users }) => {

	const { data: divisions } = useBusinessDivisions(accountId);
	const avatarClasses = avatarStyles();

	const divisionName = d => {
		let name = d.divisionName;

		if (d.accountName)
			name += ` (${d.accountName})`

		return name;
	};

	const avatar = m => {
		
		if (m.userId) {
			let user = users[m.userId];
			m.name = user?.name ?? "--";
			m.photo = user?.photo
		}

		return (
			<Box display="flex" flexDirection="column" alignItems="center" key={m.role} mb={6} ml={6} mr={6}>
				<Avatar className={avatarClasses.xlarge} src={m.photo} alt={m.name} />
				<Typography variant="subtitle2">{m.role}</Typography>
				<Typography variant="caption">{m.name}</Typography>
			</Box>
		);
	}

	if (!divisions || divisions.length === 0)
		return null;

	return (
		<React.Fragment>
			{
				divisions.map(d => (
					<Grid container spacing={6} key={d.id}>
						<Grid item xs={12}>
							<Card mb={3} style={{ height: '100%' }}>
								<CardContent>
									<Typography variant="h6" mb={6}>{divisionName(d)}</Typography>
									<Box display="flex">
										<Box display="flex" flexDirection="column" gridGap="10px">
											<Box>
												<Typography variant="subtitle2">Risk Level</Typography>
												<Typography variant="caption">{d.risk || "Not set"}</Typography>
											</Box>
											<Box>
												<Typography variant="subtitle2">Key Account</Typography>
												<Typography variant="caption">{d.keyAccount ? "Yes" : "No"}</Typography>
											</Box>
										</Box>
										<Box display="flex" justifyContent="space-around" flexWrap="wrap">
											{d.members.map(m => avatar(m))}
										</Box>
									</Box>
								</CardContent>
							</Card>
						</Grid>
					</Grid>
				))}
		</React.Fragment>	
	)
}

const mapStateToProps = (state) => ({ users: state.userReducer.users });

export default connect(mapStateToProps)(DivisionCards);