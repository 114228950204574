import { useMutation } from "react-query";
import queryKeys from "../../constants/queryKeys";
import caseActionService from "../../services/caseActionService";
import reactQueryClient from "../../reactQueryClient";

const useAddCaseAction = () =>
    useMutation(
        ({ caseAction }) =>
            caseActionService.addCaseAction(caseAction),
        {
            onSuccess: (data) => {
                const queryKey = [queryKeys.caseActions, data.caseId];
                reactQueryClient.setQueryData(queryKey, (old) => [...(old || []), data]);
            },
        }
    );

export default useAddCaseAction;
